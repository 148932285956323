'use strict';

angular.module('app.application', []);
angular.module('app.dashboard', [
// 'mgcrea.ngStrap.datepicker'
]);
angular.module('app.order', []);
angular.module('app.estimate', []);
angular.module('app.login', []);
angular.module('app.agreements', []);
angular.module('app.epay', []);
angular.module('app.training', []);
angular.module('app.dashboardAgreement', []);
angular.module('app.missingInfo', []);

//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
angular.module('app.dirPagination',[]);
//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends

var proxyBaseURL;
/*VAN-258 - Migrate Session Manager to Docker -Starts*/
proxyBaseURL = sessionManagerURL + '/smgr';
//proxyBaseURL = '/SessionManager';
/*VAN-258 - Migrate Session Manager to Docker -Ends*/

// dev: 'http://52.5.248.106' <<-- This is old dev url. New is 'http://54.208.184.207'
// qa: 'https://test-awp.acceptancenow.com'
// Declare app level module which depends on filters, and services
var myApp = angular.module('myApp', [//  'ngMockE2E'
'ngRoute',
//'myApp.services',
'ngResource', 'ngAnimate', 'ngSanitize', 'ngCookies', 'ngIdle', 'mgcrea.ngStrap.datepicker','pascalprecht.translate',
// 'mgcrea.ngStrap.modal',
//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
'ui.mask', 'ui.bootstrap', 'LocalStorageModule', 'app.login', 'app.dashboard', 'app.application', 'app.order', 'app.estimate', 'app.training', 'app.dashboardAgreement', 'app.missingInfo','app.dirPagination'])
//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
// DEV: 'rac-van-svcs-dd-dev.cloudhub.io'
// QA: 'vanesb.cloudhub.io'
// UAT: 'vanesb-uat.cloudhub.io'
.constant('API_DOMAIN', 'vanesb.cloudhub.io')
// used by route security
.constant('SECURED_ROUTES', {})

/**
 * URL for proxy actions.
 * Example: http://52.5.248.106/SessionManager/sm/proxyaction
 */.constant('PROXY_ACTION_URL', proxyBaseURL + '/sm/proxyaction')

/**
 * Creates an Okta session. Should return a user object from Okta
 * Example: http://52.5.248.106/SessionManager/sm/sessionproxy?partner=191316&storeid=9222
 * Headers required:
 * - oktaUsername (09042@acceptancenow.net)
 * - oktaPassword (12345Test)
 */
 .constant('PROXY_SESSION_URL', proxyBaseURL + '/sm/sessionproxy')
 .constant('loginRedirectPath', '/login')
 .constant('languageSettings', [
		{ id: 'en', label: 'English', countryFlag:'us'},
		{ id: 'es', label: 'Spanish', countryFlag:'pr'}
	])
 .constant("AgreementSigningStatus", {
 		"cancel": "Declined",
		"decline": "Declined",
		"exception": "Pending",
		"fax_pending": "Pending",
		"id_check_failed": "Pending",
		"session_timeout": " Pending ",
		"signing_complete": "Accepted",
		"ttl_expired": "Pending",
		/*PRB0042777 - handle viewing_complete signer status in AWP*/
		"viewing_complete": "Accepted"
 	})
 .config(["$routeProvider", "loginRedirectPath", "SECURED_ROUTES", function($routeProvider, loginRedirectPath, SECURED_ROUTES) {
		$routeProvider.whenAuthenticated = function(path, route) {
			$routeProvider.when(path, route);
			SECURED_ROUTES[path] = true;
			return $routeProvider;
		};
	}])
 .config(["$routeProvider", "$compileProvider", "$idleProvider", "$keepaliveProvider", "loginRedirectPath", "$translateProvider", function($routeProvider, $compileProvider, $idleProvider, $keepaliveProvider, loginRedirectPath, $translateProvider) {
		// Prevents angular issue when opening a new tab
		$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|data):/);
		$routeProvider.when('/', {
			redirectTo : loginRedirectPath
		}).when('/login', {
			templateUrl : 'partials/login.html',
			controller : 'Login',
			controllerAs : 'login',
			title : 'Login'
		}).whenAuthenticated('/dashboard', {
			templateUrl : 'partials/dashboard.html',
			controller : 'Dashboard',
			controllerAs : 'dashboard',
			title : 'Dashboard'
		}).whenAuthenticated('/training', {
			templateUrl : 'partials/training.html',
			controller : 'Training',
			controllerAs : 'training',
			title : 'Training'
		}).whenAuthenticated('/dashboardAgreement', {
			templateUrl : 'partials/dashboardAgreement.html',
			controller : 'DashboardAgreement',
			controllerAs : 'dashboardAgreement',
			title : 'DashboardAgreement'
		}).whenAuthenticated('/dashboard/:engagementId', {
			templateUrl : 'partials/dashboard.html',
			controller : 'Dashboard',
			controllerAs : 'dashboard',
			title : 'Dashboard'
		}).whenAuthenticated('/estimate', {
			templateUrl : 'partials/estimate.html',
			controller : 'Estimate',
			controllerAs : 'estimate',
			title : 'Estimate'
		}).whenAuthenticated('/estimate/:engagementId', {
			templateUrl : 'partials/estimate.html',
			controller : 'Estimate',
			controllerAs : 'estimate',
			title : 'Estimate'
		}).whenAuthenticated('/agreement', {
			templateUrl : 'partials/agreement.html',
			title : 'Agreements'
		}).whenAuthenticated('/agreement/:engagementId', {
			templateUrl : 'partials/agreement.html',
			controllerAs : 'vm',
			title : 'Agreements'
		}).whenAuthenticated('/digitalAgreement', {
			templateUrl : 'partials/digitalAgreement.html',
			controller : 'digitalAgreement',
			title : 'Agreements'
		}).whenAuthenticated('/digitalAgreement/:engagementId', {
			templateUrl : 'partials/digitalAgreement.html',
			controllerAs : 'vm',
			title : 'Agreements'
		}).whenAuthenticated('/application', {
			templateUrl : 'partials/application.html',
			controller : 'Application',
			title : 'Application'
		}).whenAuthenticated('/application/:engagementId', {
			templateUrl : 'partials/application.html',
			controller : 'Application',
			title : 'Application'
		}).whenAuthenticated('/getmissinginfo', {
			templateUrl : 'partials/getMissingInfo.html',
			controller : 'MissingInfo',
			controllerAs : 'missingInfo',
			title : 'Application Info'
		}).whenAuthenticated('/ordersummary', {
			templateUrl : 'partials/ordersummary.html',
			controllerAs : 'orderSummary',
			title : 'Order'
		}).whenAuthenticated('/ordersummary/:engagementId', {
			templateUrl : 'partials/ordersummary.html',
			controller : 'OrderSummary',
			controllerAs : 'orderSummary',
			title : 'Order'
		}).whenAuthenticated('/epay', {
			templateUrl : 'partials/epay.html'
		}).whenAuthenticated('/epay/:engagementId', {
			templateUrl : 'partials/epay.html',
			controllerAs : 'vm'
		}).otherwise({
			redirectTo : loginRedirectPath
		});

		/* The session timeout duration is set to 4 mins */
		$idleProvider.idleDuration(240);
		
		$idleProvider.warningDuration(10);
		$keepaliveProvider.interval(10);
	}])
 .config(["$datepickerProvider", function($datepickerProvider) {
			angular.extend($datepickerProvider.defaults, {
				dateFormat : 'MM/dd/yyyy',
				startWeek : 1,
				autoclose : true,
				minDate : 'today'
			});
	}]);

 myApp.config(["$translateProvider", "languageSettings", function ($translateProvider, languageSettings) {
	  $translateProvider.translations(languageSettings[0].id, en); //en.js
	  $translateProvider.translations(languageSettings[1].id, es); //es.js
	  
	  // load default language 'en' on startup
	  $translateProvider.preferredLanguage(languageSettings[0].id);
}]);

myApp.run(["authentication", "$rootScope", "$location", "$route", "$window", "$translate", "$idle", "$timeout", "modalService", "languageSettings", "SECURED_ROUTES", "AgreementSigningStatus", "loginRedirectPath", "$cookies", "ServiceAgent", "getTrainingLibraryFactory", "SharedDataSvc", "paymentFactory", function(authentication, $rootScope, $location, $route, $window, $translate, $idle, $timeout, modalService, languageSettings, SECURED_ROUTES, AgreementSigningStatus, loginRedirectPath, $cookies, ServiceAgent, getTrainingLibraryFactory, SharedDataSvc, paymentFactory) {
	$rootScope.associatePassPrompt = false;
	$rootScope.languageSettings = languageSettings;
	$rootScope.selectedLanguage = sessionStorage.getItem('selectedLanguage') || $rootScope.languageSettings[0].id;
	$translate.use($rootScope.selectedLanguage);

	$rootScope.setLanguageLabel = function(){
		var option = _.find($rootScope.languageSettings, {
			id: $rootScope.selectedLanguage
		});
		return option.label;
	};

	$rootScope.setLanguageClass = function(){
		var option = _.find($rootScope.languageSettings, {
			id: $rootScope.selectedLanguage
		});
		return option.countryFlag;
	};

	$rootScope.changeLocale = function(val){
		$rootScope.showLanguageSettings = $cookies.getObject('isSpanishStore') || false;
		sessionStorage.removeItem('selectedLanguage');
		$rootScope.selectedLanguage = val;
		sessionStorage.setItem('selectedLanguage', $rootScope.selectedLanguage);
		var currentURL = $route.current.$$route.originalPath;
		$translate.use(val);
		if(currentURL == "/training"){
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Starts*/
			//$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? SharedDataSvc.trainLibraryDocs.documents_PR : SharedDataSvc.trainLibraryDocs.documents;
			var documentList_Spa =[];
			var documentList_Eng =[];
			if($rootScope.state=="PR"){
				if(SharedDataSvc.trainLibraryDocs.documents_PR){
					for(var j=0;j<SharedDataSvc.trainLibraryDocs.documents_PR.length;j++){
						if(SharedDataSvc.trainLibraryDocs.documents_PR[j].language=="SPA"){
							documentList_Spa.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}else{
							documentList_Eng.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}
					}
					$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? documentList_Spa: documentList_Eng;
				}
			}
			else{
				$rootScope.documentList = SharedDataSvc.trainLibraryDocs.documents;
			}
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Ends*/
		}
	};

	function authRequired(path) {
		return SECURED_ROUTES.hasOwnProperty(path);
	}

	$rootScope.getParameterByName = function(name, url) {
	    if (!url) url = window.location.href;
	    name = name.replace(/[\[\]]/g, "\\$&");
	    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
	        results = regex.exec(url);
	    if (!results) return null;
	    if (!results[2]) return '';
	    return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	function checkAuth(path) {
		void 0;
		if ($cookies.getObject('navigatingToDocusign')) {
			authentication.isAuthenticated = true;
			var event = $rootScope.getParameterByName('signer_status') ? $rootScope.getParameterByName('signer_status').toLowerCase() : '';
			var isCoCustomerSignerPending = $cookies.getObject("isCoCustomerSignerPending");
			var agreementSigningStatus = AgreementSigningStatus[event];
			
			$cookies.putObject('navigatingToDocusign', null);
			$cookies.putObject('docusignSignerStatus', event);
	
			if(event == 'cancel'){
				$location.url('dashboard');
				modalService.open({
					windowClass: 'smallModals',
					templateUrl: 'partials/modal_afterDocusignSigningFailure.html',
					backdrop: 'static'
				});
			} else if(event == 'decline'){
				$location.url('dashboard');
			} else if(event == 'signing_complete' || event == 'null' || event == '' || event == 'viewing_complete'){
				if(isCoCustomerSignerPending){
					var engagementId = $rootScope.getParameterByName('engagementId');
					$location.url('/digitalAgreement/' + engagementId);
				} else {
					$location.url('dashboard');
					var isHTCustomer = $cookies.getObject('isHTCustomer');
					if(isHTCustomer){
						$cookies.putObject("isHTCustomer", null);
						$rootScope.successMsgDocusign = $rootScope.selectedLanguage == 'es' ? es.modal_afterDocusignSigningSuccess_desc3 : en.modal_afterDocusignSigningSuccess_desc3;
						modalService.open({
							windowClass: '',
							templateUrl: 'partials/modal_afterDocusignSigningSuccess.html',
							//backdrop: 'static'
						});
					}
				}
			} else {
				var engagementId = $rootScope.getParameterByName('engagementId');
				$location.url('/digitalAgreement/' + engagementId);
			}

			/*if (window.parent.location.href.match(/tag=/)){
			    if (typeof (history.pushState) != "undefined") {
			        var obj = { Title: document.title, Url: window.parent.location.pathname };
			        history.pushState(obj, obj.Title, obj.Url);
			    } else {
			        window.parent.location = window.parent.location.pathname;
			    }
			}*/
			//PRB0043039 - trigger update signer service on Viewing complete event
			//if(event == 'signing_complete' || event == 'decline'){
			if(event == 'signing_complete' || event == 'decline' || event == 'viewing_complete'){
				var signerData = {
					"agreementSigningStatus": agreementSigningStatus,
					"customerNumber": $rootScope.getParameterByName('signer_id')
				};
				SharedDataSvc.updateSignerData = signerData;		
				$timeout(function(){
					paymentFactory.updateSignerStatus(signerData).then(function(response) {
						//$window.location.reload();
					}, function(error) {
						SharedDataSvc.reportRaygun(error);
						void 0;
					})
				}, 2000);
			}						
		} else {
			if (authRequired(path) && !authentication.isAuthenticated) {
				$location.url(loginRedirectPath);
			}
		}
	}

	function timeoutModal() {
		var timeoutModal = modalService.open({
			title : 'Page Timeout',
			templateUrl : 'partials/modal_continueSession.html',
			backdrop : 'static',
			size : 'sm',
			scope : $rootScope
		});
		modalService.lock(true);
	}

	var currentURL = $location.url();

	if (authentication.isLoggedIn()) {
		$location.url(currentURL);
	}

	$rootScope.$on('$routeChangeStart', function(event, current, previous) {
		void 0;
		modalService.dismiss();
		//var path = current.$$route.templateUrl.split('/');
		//current.$$route.templateUrl = path[0] + '/' + $rootScope.selectedLanguage + '/' + path[2];
		//console.log(current.$$route.templateUrl);
		checkAuth(current.$$route.originalPath);
		ServiceAgent.cancelRequests();
		removeSurvey();
	});

	$rootScope.$on('$routeChangeSuccess', function(event, current, previous) {
		$rootScope.title = current.$$route.title || 'Customer';
		window.scrollTo(0, 0);
		if (authentication.isAuthenticated) {
			$idle.watch();
		}
		if ($cookies.getObject('auth_timeout')) {
			if (authRequired(current.$$route.originalPath)) {
				timeoutModal();
			} else {
				modalService.lock(false);
				modalService.dismiss();
			}
		}
		$rootScope.showLanguageSettings = $cookies.getObject('isSpanishStore') || false;
		$rootScope.selectedLanguage = sessionStorage.getItem('selectedLanguage') || $rootScope.languageSettings[0].id;
		$translate.use($rootScope.selectedLanguage);
		$rootScope.associatePassPrompt = $cookies.getObject('auth_timeout');
		removeSurvey();
	});

	$rootScope.$on('$routeChangeError', function(e, next, prev, err) {
		checkAuth(next.$$route.originalPath);
	});

	$rootScope.$on('$idleStart', function() {
		void 0;
		if (!authentication.isAuthenticated || $rootScope.associatePassPrompt) {
			return;
		}
		var timeoutWarningModal = modalService.open({
			title : 'Page Timeout',
			templateUrl : 'partials/alert_idleWarning.tpl.html',
			backdrop : 'static',
			size : 'sm',
			scope : $rootScope
		});

		timeoutWarningModal.result.then(function() {
			// VAN-2945 reloading agreement page to prevent submitting of undefined agreement values
			var currentURL = $location.url();
			if ((currentURL.indexOf('digitalAgreement') > -1) || (currentURL.indexOf('agreement') > -1)) {
				//$route.reload();
				$window.location.reload(); // to reload the whole state
			}
		}, function() {
			void 0;
		});
	});

	$rootScope.$on('$idleTimeout', function() {
		void 0;
		if (!authentication.isAuthenticated || $rootScope.associatePassPrompt) {
			return;
		}
		$rootScope.associatePassFail = false;
		$rootScope.associatePassPrompt = true;
		$cookies.putObject('auth_timeout', true);
		timeoutModal();
	});

	$rootScope.logoutUser = function() {
		void 0;
		var logoutUserConfirmationModal = modalService.open({
			title : 'Log out',
			templateUrl : 'partials/confirm_logout.tpl.html',
			size : 'sm',
			backdrop : 'static',
			scope : $rootScope
		});
	};

	$rootScope.logoutUserConfirm = function() {
		void 0;
		authentication.logoutUser();
		modalService.dismiss();
		$rootScope.associatePassFail = false;
		$cookies.putObject('auth_timeout', null);
		$location.url(loginRedirectPath);
	};

	$rootScope.verifyEmployeePass = function(pass) {
		
		//var auth = window.atob($cookies.getObject('store_nick_name'));
		//Defect 24783 - VAN-WEB- In Unmanned stores,the application is not taking any login after session timesout ' - start
		//var auth = window.atob($cookies.getObject('PP'));
		var auth = SharedDataSvc.base64.decode($cookies.getObject('PP'));
		//Defect 24783 - VAN-WEB- In Unmanned stores,the application is not taking any login after session timesout ' - end
		auth = auth.toLowerCase();
		pass = pass.toLowerCase();
		
		void 0;
		void 0;
		
		var currentURL = $location.url();
		if (auth === pass) {
			$cookies.putObject('auth_timeout', null);
			$rootScope.associatePassPrompt = false;
			modalService.lock(false);
			modalService.dismiss();

			$rootScope.showLanguageSettings = $cookies.getObject('isSpanishStore') || false;
			$rootScope.selectedLanguage = sessionStorage.getItem('selectedLanguage') || $rootScope.languageSettings[0].id;
		
			// VAN-2945 reloading agreement page to prevent submitting of undefined agreement values

			//ACDefect 24566 - reloading new customer popup to prevent existing customers from submitting application with different SSN
			if ((currentURL.indexOf('digitalAgreement') > -1) || (currentURL.indexOf('agreement') > -1) || (currentURL.indexOf('application') > -1) ) {
				//$route.reload();		   // only reloads controller
				$window.location.reload(); // to reload the whole state
			}

		} else {
			void 0;
			$rootScope.associatePassFail = true;
		}
	};

	//AC 25967 changes starts
	$rootScope.checkForDashboardNavigation = function() {
		if($location.path() == '/application' && SharedDataSvc.globalVars.genericError){
			SharedDataSvc.globalVars.genericError = false;
			$location.path('dashboard');
		}
		//VANTIV-Should reloads during Error 700 on payment-Starts
		else if($location.path().indexOf('epay') > -1){			
			
			document.getElementById("eProtect-iframe").src += '';
        }
		//VANTIV-Should reloads during Error 700 on payment-Ends
	};//AC 25967 changes ends

	$rootScope.loginTrainingLibrary = function() {
		var trainingModal = modalService.open({
			title : 'New Customer',
			templateUrl : 'partials/alert_enterNameToAccess.tpl.html',
			size : 'sm',
			backdrop : 'static',
			scope : $rootScope
		});
	};

	$rootScope.submitUserNameForTL = function(userName) {
		if (userName) {
			$rootScope.noUserName = false;
			getTrainingLibraryFactory.submitUserNameAndContinue(userName).then(function(response) {
				modalService.dismiss();
				SharedDataSvc.trainLibraryDocs = response;
				sessionStorage.setItem('trainLibraryDocs', JSON.stringify(response));
				$location.path('/training');
			}, function(error){
				SharedDataSvc.reportRaygun(error);
				void 0;
			});
		} else {
			void 0;
			$rootScope.noUserName = true;
		}
	};

	$rootScope.handleButtonClick = function(route) {
		var currentURL = $location.url();
		if (currentURL.indexOf("/application") > -1 || currentURL.indexOf("/ordersummary") > -1) {
			var saveAppModalForTraining = modalService.open({
				title : 'Save Changes',
				templateUrl : 'partials/confirm_saveAppChanges.tpl.html',
				size : 'sm',
				backdrop : 'static',
				scope : $rootScope
			});
			saveAppModalForTraining.result.then(function(action) {
				if (action) {
					$rootScope.$broadcast('saveAppData', route);
				} else {
					if (route.indexOf("training") > -1) {
						$rootScope.loginTrainingLibrary();
					} else {
						$location.path("/dashboardAgreement");
					}
				}
			});
		} else {
			if (route.indexOf("training") > -1) {
				$rootScope.loginTrainingLibrary();
			} else {
				$location.path("/dashboardAgreement");
			}

		}
	};

	function removeSurvey() {
		void 0
		if ($rootScope.storeInfo && $rootScope.storeInfo.state != 'PR') {
			var crazyEggSurveyTag = document.querySelector('crazyegg-survey');
			var existingScrip = document.querySelector('script[src="https://script.crazyegg.com/pages/scripts/0074/6210.js"]');
			var scripts = document.getElementsByTagName('script');
			var existingSurvey;

			for (var i = 0; i < scripts.length; i++) {
				if (scripts[i].textContent.includes('CE2.showSurvey("4375f353-36ee-4ad4-85c8-4de0f67d5a42")')) {
					existingSurvey = scripts[i];
					break;
				}
			}

			if (crazyEggSurveyTag) {
				crazyEggSurveyTag.remove();
			}

			if (existingScrip) {
				existingScrip.remove();
			}

			if (existingSurvey) {
				existingSurvey.remove();
			}

			var crazyeggScripts = document.querySelectorAll('script[src^="https://script.crazyegg.com"]');
			for (var i = 0; i < crazyeggScripts.length; i++) {
				crazyeggScripts[i].remove();
			}
		}
	}

}]);
/*
 myApp.config(['$httpProvider', function($httpProvider) {
 $httpProvider.defaults.useXDomain = true;
 delete $httpProvider.defaults.headers.common['X-Requested-With'];

 $httpProvider.defaults.headers.common.Authorization = 'Basic dGVzdDp0ZXN0';
 }]);
 */

myApp.run(["$http", function($http) {
	//$http.defaults.useXDomain = true;
	delete $http.defaults.headers.common['X-Requested-With'];
	//$http.defaults.headers.common['Authorization'] = 'Basic dGVzdDp0ZXN0';
	//$http.defaults.headers.common['Content-Type'] = 'text/plain';
}]);


(function() {
	'use strict';

	/**
	 * Generic factory for creating modals, tracking the active one and dismissing any that are open
	 * Not useful for tracking individual instances, such as with a singleton.
	 */
	myApp
		.factory('modalService', modalService);

	/* @ngInject */
	function modalService($modal, $rootScope) {
		var allowEscapeKey = false;
		var modalLock = false;
		var activeModal,
			service = {
			open: open,
			dismiss: dismiss,
			processing: processingModal,
			error: errorModal,
			lockError: lockErrorModal,//AC13233 & AC12058 Code changes
			activeModal: activeModal,
			lock: lock,
			openNew: openNew//AC23434 code change
		};
		return service;

		////////////////

		function open(modalOptions) {
			if (modalLock) return;
			if (typeof activeModal !== 'undefined') {
				dismiss();
			}
			modalOptions.keyboard = allowEscapeKey;
			activeModal = $modal.open(modalOptions);
			return activeModal;
		}
		
		//AC23434 code changes start
		function openNew(modalOptions) {
			if (modalLock) return;
			modalOptions.keyboard = allowEscapeKey;
			var activeModal = $modal.open(modalOptions);
			return activeModal;
		}
		//AC23434 code changes end

		function dismiss() {
			if (modalLock) return;
			if (typeof activeModal === 'undefined') return;
			activeModal.dismiss();
			activeModal = undefined;
		}

		function processingModal(title) {
			$rootScope.modalTitle = title || 'Processing';
			open({
				windowClass: 'processing-modal',
				templateUrl: 'partials/modal_Processing.html',
				size: 'sm',
				backdrop: 'static'
			});
		};

		function errorModal (message) {
			$rootScope.modalMessage = message || 'An error has occured';
			open({
				templateUrl: 'partials/alert_serviceError.tpl.html',
				scope: $rootScope //AC 25967 changes
			});
		}
		
		//Start - AC13233 & AC12058 Code changes
		function lockErrorModal (message) {
			$rootScope.modalMessage = message || 'An error has occured';
			open({				
				templateUrl: 'partials/alert_serviceLockError.tpl.html',
				scope: angular.element($('.agreement')).scope(), //AC 14120 code changes
				backdrop: 'static'
			});
		}
		//End - AC13233 & AC12058 Code changes
		
		function lock (lockStatus) {
			modalLock = lockStatus;
		}
	}
	modalService.$inject = ["$modal", "$rootScope"];
})();

(function() {
	'use strict';

	myApp
		.factory('orderService', orderService);

	/* @ngInject */
	function orderService(
		$rootScope,
		$location,
		$q,
		modalService,
		SharedDataSvc,
		updateEngagementItemFactory,
		productFactory
	) {
		var service = {
			buildItemData: buildItemData,
			addToOrder: addToOrder,
			addToSet: addToSet,
			addEditedItem: addEditedItem,
			save: saveOrder,
			confirm: confirmOrder,
			setDisplayDependencyFlag:setDisplayDependencyFlag,
			evalSetItemDependencyFlag:evalSetItemDependencyFlag
		};
		return service;

		////////////////
		
		/**
		* Check here for the dependencies and set the show Message flag for each item.
		*/
		function setDisplayDependencyFlag(orderData){
			void 0;
			
			/* Check if there is some order */
			if(orderData.length > 0){
				
				/*Create an array of all the item category present to use it for compare and check the presence of dependency */
				var currentCategoryArr = [];
				//AC 11916 code changes starts
				for(var k = 0; k<orderData.length; k++){
					if((_.isArray(orderData[k].setItems)) && (orderData[k].setItems.length > 0)) {
						for (var l = 0; l < orderData[k].setItems.length; l++) {
							//PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
							//currentCategoryArr.push(orderData[k].setItems[l].category);
							currentCategoryArr.push(orderData[k].setItems[l].dependencyMapping);
						}
					} else {
						//currentCategoryArr.push(orderData[k].category);
						currentCategoryArr.push(orderData[k].dependencyMapping);
					}

				}
				//AC 11916 code changes ends
			//	console.log("currentCategory Array", currentCategoryArr);
				
				/*Loop through each order item json */
				for(var i=0; i<orderData.length; i++){
					
					/* Added this condition to avoid issue because of old data coming without dependentOn key */
					if( _.isArray(orderData[i].dependentOn) ){

						/* Creating an array to store the status of the flag for comparison on next steps */
						var dependencyMessageStatusArr = [];
						
						/* If there is any dependency there will be something in array otherwise length will be 0 */
						if( orderData[i].dependentOn.length > 0){
							
							/*Loop through each item which is a dependency and check whether any of those is present in above category Array */
							for(var j=0; j<orderData[i].dependentOn.length; j++){
								
							//	console.log("Dependent On", orderData[i].dependentOn[j].dependentProduct);
							//	console.log(currentCategoryArr.indexOf(orderData[i].dependentOn[j].dependentProduct) );
								
								/* If the dependency is not present set the showDependencyMessage to true else false */
								if( currentCategoryArr.indexOf(orderData[i].dependentOn[j].dependentProduct) < 0){
									dependencyMessageStatusArr.push("true");
		
								}else{
									dependencyMessageStatusArr.push("false");
								}
							}
							
							//	console.log("dependencyMessageStatusArr", dependencyMessageStatusArr);
							/* If the dependencyMessageStatusArr array has even a single value as false don't show message */
							if(dependencyMessageStatusArr.indexOf("false") < 0){
								orderData[i].showDependencyMessage = "true";
							}else{
								orderData[i].showDependencyMessage = "false";
							}
							//AC 11916 code changes starts
						} else if((_.isArray(orderData[i].setItems)) && (orderData[i].setItems.length > 0)) {
							for (var j = 0; j<orderData[i].setItems.length; j++) {
								if((orderData[i].setItems[j].dependentOn) && (orderData[i].setItems[j].dependentOn.length > 0)) {
									for(var k = 0; k < orderData[i].setItems[j].dependentOn.length; k++) {
										if( currentCategoryArr.indexOf(orderData[i].setItems[j].dependentOn[k].dependentProduct) < 0){
											dependencyMessageStatusArr.push("true");

										}else{
											dependencyMessageStatusArr.push("false");
										}
									}
									if(dependencyMessageStatusArr.indexOf("false") < 0){
										orderData[i].setItems[j].showDependencyMessage = "true";
									}else{
										orderData[i].setItems[j].showDependencyMessage = "false";
									}
									//AC 11916 code changes ends
								}
							}
						}
						
					}
	
				}	
			}
			
			/* Check whether to enable or disable the buttons on order Summary page */
			var showDependencyMessageArr = [];
			for( var v=0; v < orderData.length; v++ ){

				//AC 11916 code changes starts
				if((_.isArray(orderData[v].setItems)) && (orderData[v].setItems.length > 0)) {
					for (var u = 0; u < orderData[v].setItems.length; u++) {
						/* Creating the array of values of showDependency to check later if any of the dependency is not met */
						showDependencyMessageArr.push(orderData[v].setItems[u].showDependencyMessage);
					}
				} else {
					/* Creating the array of values of showDependency to check later if any of the dependency is not met */
					showDependencyMessageArr.push(orderData[v].showDependencyMessage);
				}
				//AC 11916 code changes ends
			}
			void 0;
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if( showDependencyMessageArr.indexOf("true") == -1 ){
				SharedDataSvc.globalVars.disableOrderButtons[0] = false;
			}else{
				SharedDataSvc.globalVars.disableOrderButtons[0] = true;
			}*/
			SharedDataSvc.globalVars.disableOrderButtons[0] = false;
			//VAN-2881-Remove ALL Item Dependencies -Ends
			void 0;
				
		}
		
		
		function evalSetItemDependencyFlag(val){
			
			void 0;
			void 0;
			
			if(val.length > 0){
				
				var buttonStatusArr = [];
				for( var x =0; x < val.length; x++ ){	
					
					if ( _.isArray(val[x].setItems) ) {
			
						void 0;
						if(val[x].setItems.length > 0){
							buttonStatusArr.push("true");
							//SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent = ["false"];
						}else{
							//buttonStatusArr.push("false");
						}
						
					}else{
						void 0;
						buttonStatusArr.push("false");
					}
				}
				
				if(buttonStatusArr.indexOf("false") ==  -1 ){
					SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent = ["false"];
					void 0;
				}else{
					SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent = ["true"];
					void 0;
				}
				
			}
			
			
			void 0;
		}
		
		

		/**
		* Save the order to services
		* @param {object} selectionData - Contains data objects (from product categories JSON) that will be used to build the item
		* @returns {object} orderItem - Order item built for compatibility with the services
		*/
		function buildItemData(selectionData) {
			var isAccessory = _(selectionData).has('selectedAccessoryItem'),
				isItem = _(selectionData).has('selectedItemItem'),
				isBedItem = _(selectionData).has('selectedBedSizeItem');//PRB0041531 – Agreement stuck in processing

			void 0;
			// Pick most nested item level selected, by priority
			var finalTier = selectionData.selectedCategoryItem;
			if (isItem) finalTier = selectionData.selectedItemItem;
			if (isAccessory) finalTier = selectionData.selectedAccessoryItem;
			if (isBedItem) finalTier = selectionData.selectedBedSizeItem;//PRB0041531 – Agreement stuck in processing
			void 0;

			var itemAttributes = []
			,	itemBrand = ''
			,	itemCategory = ''//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow
			, 	dependentItem=''//PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
			,	itemColor = ''
			,	itemDescription = ''
			,	itemIcon = ''
			,	itemMaterial = ''
			,	itemModel = ''
			,	itemPrice = parseFloat(selectionData.itemPrice) || 0
			,	itemPriceBuckets = []
			,	itemQuantity = 1
			,	itemSize = ''
			,	itemSubType = ''
			,	itemUnitType = ''
			,	setItems = []
			,	dependentOn = []
			,   dependencyMessage = ''
			,   showDependencyMessage = 'false'
			;
			void 0;

			// SUB TYPE CODE
			itemSubType = ''; //productFactory.getProductSubType(selectionData, finalTier.iconURL);

			// UNIT TYPE CODE
			itemUnitType =  '';// productFactory.getProductUnitType(selectionData, finalTier);

			// Set category to parent if descendant of "category" tier, otherwise use category name
			// if (!isSet && (isAccessory || isItem)) {
			// 	itemCategory = finalTier.parent;
			// }
			// CUSTOM ATTRIBUTES
			if (_(selectionData).has('customAttributes')) {
				itemAttributes = selectionData.customAttributes;
			}

			// QUANTITY - set to 1 as default
			if (_(selectionData).has('quantity')) {
				itemQuantity =  parseInt(selectionData.quantity);
			}
			
			
			// Set Items Array - set to empty array as default
			if (finalTier.set !== 0) {
				setItems = finalTier.set;
			}

			if(isItem){
				//GET DEPENDENT ON
				if (_(selectionData.selectedItemItem).has('dependencies')) {
					dependentOn = selectionData.selectedItemItem.dependencies;
					void 0;
				}
				//VAN-2881-Remove ALL Item Dependencies -Starts
				//GET DEPENDENCY MSG
	/*			if (_(selectionData.selectedItemItem).has('dependencyAlertMessageEn')) {
					//PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
					if($rootScope.selectedLanguage == 'es'){
					dependencyMessage = selectionData.selectedItemItem.dependencyAlertMessageEs;
					console.log("This msg in spanish", dependencyMessage);
					}
					else{
					dependencyMessage = selectionData.selectedItemItem.dependencyAlertMessageEn;
					console.log("This msg in English", dependencyMessage);
				}
			}*/
				//VAN-2881-Remove ALL Item Dependencies -Ends
			}
			
			// GET PRICEBUCKETS
			if (_(finalTier).has('priceBuckets')) {
				itemPriceBuckets = finalTier.priceBuckets;
			}
			
			//Item Name
			//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Starts
			//VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Starts 
			if (_(selectionData).has('selectedAccessoryItem') && selectionData.selectedAccessoryItem != null && selectionData.selectedBedSizeItem == undefined) {
				if($rootScope.selectedLanguage == 'es'){
					itemCategory = selectionData.selectedAccessoryItem.labelEs;
				}
				else{
					itemCategory = selectionData.selectedAccessoryItem.labelEn;
				}
			}//VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Ends
			else if (_(selectionData).has('selectedItemItem') && selectionData.selectedItemItem != null && selectionData.selectedBedSizeItem == undefined) {
				if($rootScope.selectedLanguage == 'es'){
					itemCategory = selectionData.selectedItemItem.labelEs;
				}
				else{
					itemCategory = selectionData.selectedItemItem.labelEn;
				}
			}
			//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Starts
			// SELECTED BED SIZE
			if (_(selectionData).has('selectedBedSizeItem') && selectionData.selectedBedSizeItem != null) {
				//itemSize = selectionData.selectedBedSizeItem.labelEn;
				//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Ends
				if($rootScope.selectedLanguage == 'es'){
					itemCategory = selectionData.selectedBedSizeItem.labelEs;
				}
				else{
					itemCategory = selectionData.selectedBedSizeItem.labelEn; //PRB0041531 – Agreement stuck in processing
				}
				//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Ends
			}
			
			// Selected dependent item -- //PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
			if (_(selectionData).has('selectedItemItem') && selectionData.selectedItemItem != null && selectionData.selectedBedSizeItem == undefined) {
				dependentItem = selectionData.selectedItemItem.labelEn;
				void 0;
			}

			// SELECTED BRAND
			if (_(selectionData).has('selectedBrandItem') && selectionData.selectedBrandItem != null) {
				itemBrand = selectionData.selectedBrandItem.label;
			}

			// MATERIAL
			if (_(selectionData).has('selectedMaterialsItem') && selectionData.selectedMaterialsItem != null) {
				itemMaterial = selectionData.selectedMaterialsItem.labelEn;
			}

			// COLOR
			// Start VAN-2501
			if (_(selectionData).has('selectedColorItem') && selectionData.selectedColorItem != null) {
				itemColor = selectionData.selectedColorItem.labelEn;
			}
			// End

			// ITEM MODEL NUMBER
			if (_(selectionData).has('itemModelNumber')) {
				itemModel = selectionData.itemModelNumber;
			}

			// Icon URL
			if (_(finalTier).has('iconURL')) {
				itemIcon = finalTier.iconURL;
			}
			
			var orderItem = {
				attributes: itemAttributes,
				brand: itemBrand,
				category: itemCategory,
				dependencyMapping: dependentItem, //PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
				color: itemColor,
				iconURL: itemIcon,
				itemPrice: itemPrice,
				material: itemMaterial,
				model: itemModel,
				quantity: itemQuantity,
				setItems: setItems,
				size: itemSize,
				subType: itemSubType,
				unitType: itemUnitType,
				dependentOn: dependentOn,
				dependencyMessage: dependencyMessage,
				showDependencyMessage:showDependencyMessage
			};
			orderItem.itemDescription = buildDescription(orderItem);
			// remove keys with an empty string
			orderItem = _.omit(orderItem, function(val, key){
				if (key === 'model') return false;
				return val === '';
			});
			return orderItem;
		}

		/**
		 * Builds the description that will be saved with the item to services.
		 * @param {object} itemData - Order item data that will be used to build description.
		 * @return {string} description - The built description string.
		 */
		function buildDescription(itemData) {
			var description;
			// Quantity
			description = isNaN(itemData.quantity) || itemData.quantity === 1 ? '' : itemData.quantity + ' x ';
			description += itemData.brand !== '' ? itemData.brand +' ' : '';
			description += itemData.category+' ';
			description += itemData.color !== '' ? itemData.color+' ' : '';
			description += itemData.material !== '' ? itemData.material : '';
			description += itemData.size !== '' ? '- '+itemData.size : '';
			description = description.replace(/['"]+/g, '');
			// remove end-of-line whitespace
			description = description.replace(/\s+$/g, '');
			return description;
		}

		/**
		 * Adds item to the overall order.
		 * @param {object} data - Item data that will be used to build an item and add to order.
		 * @return {number} index - Index of the order item that was just added.
		 */
		function addToOrder(data) {
			var pushObj = buildItemData(data);
			var itemsLength = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length;
			
			//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Starts
			//Feature 9500 - VAN - Business Demo Changes - Starts
			var AWPitemgroupmessage;
			//Feature 9500 - VAN - Business Demo Changes - Ends
			var vendorSetEnable=false;
			if(itemsLength>=1){
				for(var i=0;i<SharedDataSvc.globalVars.itemGroupVendorSet.length;i++){
					if(SharedDataSvc.globalVars.itemGroupVendorSet[i].vendor.indexOf("|" + SharedDataSvc.globalVars.storeCode +"|")>=0){
						//Feature 9500 - VAN - Business Demo Changes - Starts
						AWPitemgroupmessage = SharedDataSvc.globalVars.itemGroupVendorSet[i].awpItemGroupMessage;
						//Feature 9500 - VAN - Business Demo Changes - Ends
						for(var j=0;j<SharedDataSvc.globalVars.itemGroupVendorSet[i].itemsList.length;j++){
							if((SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[0].iconURL)==(SharedDataSvc.globalVars.itemGroupVendorSet[i].itemsList[j].iconURL))
							{							
								var firstItemGroupName=SharedDataSvc.globalVars.itemGroupVendorSet[i].itemGroup;
								var firstItemFlagValue=SharedDataSvc.globalVars.itemGroupVendorSet[i].isAllowedWithNonGroup;								
							}
							if(data.selectedItemItem.iconURL==SharedDataSvc.globalVars.itemGroupVendorSet[i].itemsList[j].iconURL)
							{								
								var currentItemGroupName=SharedDataSvc.globalVars.itemGroupVendorSet[i].itemGroup;
								var currentItemFlagValue=SharedDataSvc.globalVars.itemGroupVendorSet[i].isAllowedWithNonGroup;								
							}
						}
					}
				}

				if(firstItemGroupName==currentItemGroupName)
				{
					vendorSetEnable=true;
				}
				else
					if(firstItemFlagValue=='Y' || currentItemFlagValue=='Y' ){
					if(firstItemGroupName==undefined || currentItemGroupName==undefined){
						vendorSetEnable=true;
					}
				}
				if(data.selectedItemItem.set>0 && vendorSetEnable == false)
				{
				$rootScope.setModeForGroup=false;
				}
		//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes

			/*	if(!vendorSetEnable && itemsLength>=1)
					{
					//Feature 9500 - VAN - Business Demo Changes - Starts
						var openAlert = modalService.open({
							template: AWPitemgroupmessage,
							backdrop: 'static'
						});
						//Feature 9500 - VAN - Business Demo Changes - Ends
						return  SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length-1;
					}*/
		//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes

				}
		//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Ends
			
			if (itemsLength >= 0) {
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.push(pushObj);
			} else {
				// nothing in order, nullify orderItems
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems = [];
				builder.orderSubtotal = 0;
			}
			$rootScope.$broadcast('orderUpdated');
			
			setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			
			// return index of added item
			return SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length-1;
		}
		
		/**
		 * Adds item to the setItems of an order item.
		 * @param {object} item - The item object to be added to specfied set.
		 * @param {number} index - Index of the order item that will receive the set item.
		 * @returns {number} index - Returns the index of changed order item.
		 */
		function addToSet(item, index) {
			var orderSetItems = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index].setItems;
			var setItem = buildItemData(item, true);

			if (!_.isArray(orderSetItems)) {
				orderSetItems = new Array(setItem);
			} else {
				orderSetItems.push(setItem);
			}
			
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index].setItems = orderSetItems;

			setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);//AC 11916 code changes
			evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			
			return index;
		}

		/**
		 * Edits the indicated order item by merging edit data onto existing order data.
		 * @param {object} editData - The item edit data that will be used for the edit.
		 * @param {number} index - Index of the order item that will be edited.
		 */
		function addEditedItem(editData, index) {
			var preEditItem,
				editedItem;
			if (index.length > 1) {
				preEditItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index[0]].setItems[index[1]];
			} else {
				preEditItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index];
			}
			// AC-13373 Start
			var selectedCustomAttributesArr = [];
			var selectedCustomAttributes = [];
			if(editData.customAttributes != undefined){				
				_.forEach(editData.customAttributes, function(customAttributeGroup){
					_.forEach(customAttributeGroup.customAttributes, function(customAttributes, index){
						if(customAttributes.fieldType == "radio"){
							selectedCustomAttributes={
									customAttribute:customAttributes.labelEn,
									value: editData.selectedRadioOption 
							};
						}else if(customAttributes.fieldType == "checkBox"){
							selectedCustomAttributes={
									customAttribute: customAttributes.labelEn,
									value: editData.selectedCheckBoxOption[index] ? '1' : editData.selectedCheckBoxOption[index] == false ? '0' : undefined
							};
						}else{
							selectedCustomAttributes={
									customAttribute: customAttributes.labelEn,
									value: customAttributes.selectedTextAttribute
							};
						}
						selectedCustomAttributesArr.push(selectedCustomAttributes);
					});
				});
			}			
			editData.customAttributes = selectedCustomAttributesArr;
			// AC-13373 End
			var editedItem = buildItemData(editData);
			if (preEditItem.setItems.length > 0) {
				editedItem.setItems = preEditItem.setItems;
			}
			editedItem = _.merge(preEditItem, editedItem);
		}

		/**
		* Save the order to services
		* @param {object} order - Built item data that will be saved to services
		* @param {string} orderStatus - (optional) Status to set the order to
		* @param {string} navigateTo - (optional) Where to navigate after successful save
		*/
		function saveOrder(order, orderStatus, navigateTo) {
			orderStatus = orderStatus || 'InProgress';
			navigateTo = navigateTo || false;
			
			var orderPut = {}
			,	engagementData = SharedDataSvc.globalVars.order.currentEngagement
			,	orderData = order.currentEngagementOrder
			,	filteredItems = []
			;
			
			if(engagementData.applicationStatus === undefined){
				engagementData = SharedDataSvc.globalVars.orderSummary.currentEngagement;
			}

			if(orderData.orderContent.extendedServiceAmount){
				orderData.orderContent.extendedServiceAmount = Number(orderData.orderContent.extendedServiceAmount);
			}

			if(orderData.orderContent.deliveryFee){
				orderData.orderContent.deliveryFee = Number(orderData.orderContent.deliveryFee);
			}

			// save order -
			//  - get SharedDataSvc.globalVars.order.currentEngagementId and prepare a PUSH url
			//  - update order status in currentEngagement
			// order.currentEngagement.orderStatus = orderStatus;

			SharedDataSvc.globalVars.order.currentEngagement.orderStatus = orderStatus;
			SharedDataSvc.globalVars.order.currentEngagementOrder = order.currentEngagementOrder;

			/*//VAN 3052: Aakash Changes: Changing Agreement Status to Pending if order is edited once agreement is submitted
			console.log("Editorder flag value:", SharedDataSvc.editOrder);
			if(engagementData.applicationStatus.toUpperCase() === "ACCEPTED" && engagementData.agreementStatus.toUpperCase() === "ACCEPTED" && SharedDataSvc.editOrder) {
				SharedDataSvc.editOrder = false;
				SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = 'InProgress';
			}*/

			// map the globalVars.order version to the PUT version:
			//AC21459 code changes starts
			if(SharedDataSvc.globalVars.estimateFlag){
				orderPut.estimateFlag = true;
			}//AC21459 code changes ends
			orderPut.orderStatus = orderStatus;
			var orderDetails = [
				'agreementStatus',
				'applicationStatus',
				'customerId',
				'customerHtID',
				'deliveryStatus',
				'engagementId',
				'paymentStatus',
				'salesStatus',
				'vanStoreId',
				'isIdConfirmed',
				'invoiceNum',
				'salesAssociateID',
				'invoiceReductionPaymentAmount'
			];
			/*VAN-4036 -AWP - order screen invoice reduction payment changes
			Fetching the amount from the odersummary and adding it to the payload for the customer engagement put api call(save order) */
			
			if(SharedDataSvc.invoiceReductionPayment) {
				void 0;
				orderDetails.push(order.currentEngagement.invoiceReductionPaymentAmount);
			}
			_.forEach(orderDetails, function(detailName) {
				orderPut[detailName] = engagementData[detailName];
			});
			_.forEach(orderData.orderContent.orderItems, function(val) {
				filteredItems.push(_.omit(val, ['unitType', 'subType']));
			});

			orderData.orderContent.deliveryFeeOneTime = true;
			if(SharedDataSvc.globalVars.orderEstimatePut.feeArray && SharedDataSvc.globalVars.orderEstimatePut.feeArray[1].feeAcrossTerm){
				orderData.orderContent.deliveryFeeOneTime = false;
			}

			orderData.orderContent.orderItems = filteredItems;
			// encode the orderContent portion and PUT
			var preEncode = angular.toJson(orderData.orderContent);
			var encodedData = SharedDataSvc.base64.encodeAlt(preEncode);
			orderPut.orderContent = encodedData;

			void 0;

			var deferred = $q.defer();
			
			/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/ 
			if(SharedDataSvc.globalVars.paymentModeSelected!=undefined){
				orderPut.paymentMode = SharedDataSvc.globalVars.paymentModeSelected;
			}
			/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/
			
			// return;
			updateEngagementItemFactory.putData(orderPut)
			.then(
				function(response) {
					SharedDataSvc.globalmessage = 'Order Saved.';
					SharedDataSvc.globalmessageShow = true;
					$rootScope.$broadcast('engagementUpdated');
					SharedDataSvc.globalVars.estimateFlag = false;//AC21459 code changes
					if (navigateTo) $location.path(navigateTo);
					deferred.resolve(response);
				}, function(response) {
					void 0;
					SharedDataSvc.globalmessage = 'An error has occurred.  Please retry your request.';
					SharedDataSvc.globalmessageShow = true;
					SharedDataSvc.globalVars.estimateFlag = false;//AC21459 code changes
					deferred.reject(response);
				}
			);
			
			return deferred.promise;
		}


		function confirmOrder() {
			// mark that order as confirmed
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderStatus = 'Confirmed';
			SharedDataSvc.globalmessage = 'Order Confirmed';
			SharedDataSvc.globalmessageShow = true;
			
			var orderDetails = [
				'applicationStatus',
				'orderContent',
				'engagementId',
				'customerHtID',
				'salesStatus',
				'paymentStatus',
				'customerId',
				'deliveryStatus',
				'agreementStatus',
				'vanStoreId',
				'orderStatus',
				'invoiceReductionPaymentAmount'
			];

			/*VAN-4036 -AWP - order screen invoice reduction payment changes
			Fetching the amount from the ordersummary screen and adding it to the payload for the customer engagement put api call(confirm order) */

			if(SharedDataSvc.invoiceReductionPayment) {
				if(Number(orderSummary.SharedDataSvc.globalVars.orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > Number(orderSummary.SharedDataSvc.globalVars.orderSummary.approvalAmount)) {
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =  Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
					orderDetails.push(Number(SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount).toFixed(2));
				}
				else {
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = 0;
					orderDetails.push(SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount);
				}
			}
			
			_.forEach(orderDetails, function(detailName) {
				SharedDataSvc.globalVars.orderConfirmPut[detailName] = SharedDataSvc.globalVars.order.currentEngagementOrder[detailName];
			});
			// map the globalVars.order version to the PUT version:
			SharedDataSvc.globalVars.orderConfirmPut.orderContent.extendedServiceAmount = orderSummary.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount;
			SharedDataSvc.globalVars.orderConfirmPut.orderContent.deliveryFee = orderSummary.globalVars.order.currentEngagementOrder.orderContent.deliveryFee;

			// encode the orderContent portion and PUT
			var preEncode = angular.toJson(SharedDataSvc.globalVars.orderConfirmPut.orderContent);
			var encodedData = SharedDataSvc.base64.encodeAlt(preEncode);
			SharedDataSvc.globalVars.orderConfirmPut.orderContent = encodedData;
			SharedDataSvc.globalVars.orderConfirmPut.orderStatus = 'Confirmed';
			var pushData = SharedDataSvc.globalVars.orderConfirmPut;
			void 0;
			updateEngagementItemFactory.putData(pushData).then(
				function() {
					void 0;
					$rootScope.$broadcast('engagementUpdated');
					$location.url('/dashboard');
				},
				function(statusText) {
					void 0;
					//$location.url('/dashboard');
					//$rootScope.$broadcast('engagementUpdated');
				}
			);
		}

	}
	orderService.$inject = ["$rootScope", "$location", "$q", "modalService", "SharedDataSvc", "updateEngagementItemFactory", "productFactory"];
})();
(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('agreementFactory', agreement);


	function agreement($http, $q, $log, orderEstimatePutFactory, SharedDataSvc, API_DOMAIN, PROXY_ACTION_URL, authentication, ServiceAgent) {
		var agreement = this,
				quotes = [],
				quoteAsPerSelection = '',
				formatMoney = function (money) {
					money = money + "";
					if (money.split(".")[1] && money.split(".")[1].length == 1) {
						money = money + "0";
					}
					return money;
				},
				checkInitialPayment = function (obj) {
					if (obj.initialPaymentWTax) {
        		obj.initialPaymentWTax = formatMoney(obj.initialPaymentWTax);
        	}
        	return obj;
				};

		return {
			quotes: quotes,
			setQuotes: setQuotes,
			setQuotesAsPerSelection:setQuotesAsPerSelection,
			createAgreement: createAgreement,
			retrieveAgreementsDashboard: retrieveAgreementsDashboard,
			agreementInventory: agreementInventory,
			agreementVerification: agreementVerification,
			updateAgreement: updateAgreement,
			getDisputeReasons: getDisputeReasons,
			searchAgreement: searchAgreement,
			validatePassPhrase: validatePassPhrase
		};

		function createAgreement(agreementData, keys) {
			void 0;
			var deferred = $q.defer();
			var aData = angular.toJson(agreementData);
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreement/create',
				'sessionToken' : authentication.getCurrentSessionId()
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				agreementData, 
				true
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function retrieveAgreementsDashboard() {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/retrieveAgreementsDashboard',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				doEncrypt
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}


		function agreementInventory(agreementId) {
			void 0;
			var deferred = $q.defer();
			var doEncrypt = true;
			if (!agreementId) {
				void 0;
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?agreementId=' + agreementId;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/agreementInventoryItems',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				doEncrypt
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function agreementVerification(engagementId) {
			void 0;
			var deferred = $q.defer();
			var doEncrypt = true;
			if (!engagementId) {
				void 0;
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?engagementId=' + engagementId;
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/notifyPendingAgreement',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					null,
					doEncrypt
			).then(function (response) {
						void 0;
						deferred.resolve(response);
					},
					function (response) {
						void 0;
						deferred.reject(response);
					});

			return deferred.promise;
		}


		function updateAgreement(updateData) {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/updateAgreement',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID' //Story 26289 code changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				updateData, 
				doEncrypt
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function getDisputeReasons() {
			var deferred = $q.defer();
			var headers = {
				'endTarget': 'site/json/agreementDisputeReason.json',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
			};
			ServiceAgent.httpCall(
			PROXY_ACTION_URL,
			'POST',
			headers,
			null,
			false
			).then(function (response) {
				void 0;
			  	deferred.resolve(response);
			},
			function (response) {
			  	void 0;
			    deferred.reject(response);
			});

			return deferred.promise;
		}

		function searchAgreement(searchParameter, searchParameterValue) {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL + '?' + searchParameter + '=' + searchParameterValue;
			var headers = {
				'endTarget': 'rentacentervrto/rest/agreementsPayable/searchAgreement',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				doEncrypt
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function setQuotesAsPerSelection(orderData){
			var deferred = $q.defer();
			orderEstimatePutFactory.putData(orderData, false) 
	          .then(function(response){
	          	void 0;
	          	quoteAsPerSelection = checkInitialPayment(response);
	          	deferred.resolve(response);
	        },function(response){
	        	void 0;
	        	deferred.reject(response);
	        });
	        return deferred.promise;
		}
		

		function setQuotes(orderData) {
	      void 0;
	      return $q.all([
	        orderEstimatePutFactory.putData(orderData[0], false) //26894 - Modify attribute isDraft
	          .then(function(response){
	          	quotes[0] = checkInitialPayment(response);
	          }),
	        orderEstimatePutFactory.putData(orderData[1], false) //26894 - Modify attribute isDraft
	          .then(function(response){
	            quotes[1] = checkInitialPayment(response);
	          }),
	        orderEstimatePutFactory.putData(orderData[2], false) //26894 - Modify attribute isDraft
	          .then(function(response){
	            quotes[2] = checkInitialPayment(response);
	          })
	      ])
	      .then(function(data){
	        void 0;
	      });
		}

		function validatePassPhrase(value, path) {
			var deferred = $q.defer();
			var doEncrypt = true;
			var url = PROXY_ACTION_URL;
			if(path === 'epay') {
				url = PROXY_ACTION_URL + '?validateRequest=promoPayment';
			}else if( path === 'mastercode'){
				url = PROXY_ACTION_URL + '?validateRequest=phoneConfirmationOverride';
			}
			var headers = {
				'endTarget': 'rentacentervrto/rest/store/validateLogin',
				'sessionToken' : authentication.getCurrentSessionId(),
				'PASSPHRASE' : value,
				'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE,PASSPHRASE'
			};
			ServiceAgent.httpCall(
				url, 
				'POST',
				headers,
				null, 
				false
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

	}
	agreement.$inject = ["$http", "$q", "$log", "orderEstimatePutFactory", "SharedDataSvc", "API_DOMAIN", "PROXY_ACTION_URL", "authentication", "ServiceAgent"];
})();

// application (exiting)
myApp.factory('applicationService', ["$http", "$q", "SharedDataSvc", "API_DOMAIN", "$cookies", "PROXY_ACTION_URL", "authentication", "ServiceAgent", function ($http, $q, SharedDataSvc, API_DOMAIN, $cookies, PROXY_ACTION_URL, authentication, ServiceAgent) {
	return {
		saveApplication : function (mData) {
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/create';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				mData.entryPointName = entryPointFromCookie;
				void 0;
			}
			
			void 0;
			
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				mData,
				true
		    ).then(function (response) {
		    	SharedDataSvc.cutomerInfoForMasterCode = response;
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });
			return deferred.promise;
		},
		/*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
		getApplicationByEngagement: function (engagement) {
			void 0;
			var deferred = $q.defer();
			var params = '&lastName=' + engagement.customerLastName;
				params += '&firstName=' + engagement.customerFirstName;
				params += '&areaCode=' + engagement.customerPhoneNum.substr(0,3);
				params += '&phoneNumber=' + engagement.customerPhoneNum.substr(3,10);
				params += '&engagementId=' + engagement.engagementId;
				
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				void 0;
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
	    		deferred.resolve(successData);
			},
			function (response) {
				void 0;
				deferred.reject(response);
			});

			return deferred.promise;
		},
		/*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
		getApplication: function (personDetails) {
			void 0;
			var deferred = $q.defer();
			var params = '&lastName=' + personDetails.lastName;
				params += '&firstName=' + personDetails.firstName;
				params += '&areaCode=' + personDetails.phone.substr(0,3);
				params += '&phoneNumber=' + personDetails.phone.substr(3,10);
				//AC14937 code change starts
				if (personDetails.engagementId)	{
					params += '&engagementId=' + personDetails.engagementId;
				}
				//AC14937 code change ends
			if (personDetails.ssn) {
				params += '&ssn=' + personDetails.ssn;
			}
			//Story 33452 changes starts
			if(personDetails.disableHT){
				params += '&disableHT=' + personDetails.disableHT;
			}
			//Story 33452 changes ends
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
	    		deferred.resolve(successData);
			},
			function (response) {
				void 0;
				//TODO: remove this when proxy is functioning properly
				//response.data.status_details = 'No Customers Match Search Criteria';
				deferred.reject(response);
			});

			return deferred.promise;
		},
		getApplicationByAgreementId: function (agreementId) {
			void 0;
			var deferred = $q.defer();
			var params = '&agreementHTId=' + agreementId
			           + '&disableHT=true'
			           + '&suppressDbCreate=true';
			void 0;
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				void 0;
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
				deferred.resolve(successData);
			},
			function (response) {
				void 0;
				//TODO: remove this when proxy is functioning properly
				//response.data.status_details = 'No Customers Match Search Criteria';
				deferred.reject(response);
			});

			return deferred.promise;
		},
		processApp : function (mData) {
			void 0;
			var deferred = $q.defer();
			// sending object instead of encrypted JOSN
			//var encryptParams = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			//Feature 8275 added client originator and client source id
			var headers = {
				'endTarget': 'rentacentervrto/rest/approval',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,CLIENT-SOURCE-ID,CLIENT-ORIGINATOR',
				'CLIENT-SOURCE-ID':'3',
				'CLIENT-ORIGINATOR':'15'
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				mData,
				true //encrypt mode changed to true as per Service team requirement
		    ).then(function (response) {
			void 0;
			deferred.resolve(response);
		    },
		    function (response) {
			void 0;
			deferred.reject(response);
		    });

		    return deferred.promise;
		},
		processApp : function (mData) {
			void 0;
			var deferred = $q.defer();
			// sending object instead of encrypted JOSN
			//var encryptParams = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			//Feature 8275 added client originator and client source id
			var headers = {
				'endTarget': 'rentacentervrto/rest/approval',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,CLIENT-SOURCE-ID,CLIENT-ORIGINATOR',
				'CLIENT-SOURCE-ID':'3',
				'CLIENT-ORIGINATOR':'15'
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				mData,
				true //encrypt mode changed to true as per Service team requirement
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		},
		getNewCustId : function(eData){
			void 0;
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/db/customerReference';
			headers.sessionToken = authentication.getCurrentSessionId();
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			return $http({
				method: 'POST',
				url: PROXY_ACTION_URL,
				headers: headers,
				data: eData
			}).then(function(response){
				void 0;
				deferred.resolve(response.data);
			}).catch(function (response) {
				void 0;
				deferred.reject(response.data);
			});;
			return deferred.promise;
		},
		validatePhone : function(Data){
			void 0;
			var deferred = $q.defer();

			var params = '&toNumber=' + Data.toNumber;
			params += '&languagePreference=' + Data.languagePreference;
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			var doEncrypt = false;
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/validatePhone',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
			};
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					Data,
					doEncrypt
					// false
			).then(function (response) {
						void 0;
						var successData = response;
						deferred.resolve(successData);
					},
					function (response) {
						void 0;
						//TODO: remove this when proxy is functioning properly
						//response.data.status_details = 'No Customers Match Search Criteria';
						deferred.reject(response);
					});

			return deferred.promise;
		},
		publishOverrideDataMasterCode : function(Data){
			void 0;
			var deferred = $q.defer();
			var doEncrypt = false;

			var headers = {
				'endTarget': 'rentacentervrto/rest/application/publishEvent',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Event-Type' : 'Bus-Event',
				'Event-SubType' : 'OVERRIDE',
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE,Event-Type,Event-SubType'
			};
			
			var url = PROXY_ACTION_URL;
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					Data,
					doEncrypt
					// false
			).then(function (response) {
						void 0;
						var successData = response;
						deferred.resolve(successData);
					},
					function (response) {
						void 0;
						//TODO: remove this when proxy is functioning properly
						//response.data.status_details = 'No Customers Match Search Criteria';
						deferred.reject(response);
					});

			return deferred.promise;
		},
		validateBankingInfo : function(Data){
			void 0;
			var deferred = $q.defer();

			var doEncrypt = false;
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/payment/achPaymentVerification';
			headers.sessionToken = authentication.getCurrentSessionId();
			var url = PROXY_ACTION_URL;

			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					Data,
					doEncrypt
					// false
			).then(function (response) {
					void 0;
					deferred.resolve(response);
				},
				function (response) {
					void 0;
					deferred.reject(response);
				});

			return deferred.promise;
		},
		updateCustomer : function(data){
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/update';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			/*
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				data.entryPointName = entryPointFromCookie;
			}*/			
			void 0;
			
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				data,
				true
		    ).then(function (response) {
		    	SharedDataSvc.cutomerInfoForMasterCode = response;
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });
			return deferred.promise;
		},//VAN-510 - Electronic Consent moved to app flow - Ends
		getCustomerConsent: function (personDetails) {
			void 0;
			var deferred = $q.defer();
			var params = '&lastName=' + personDetails.lastName;
				params += '&firstName=' + personDetails.firstName;
				params += '&phoneNumber=' + personDetails.phone;
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);//ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/getCustomerConsent',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'//Story 26289 code changes
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				null,
				doEncrypt
				// false
			).then(function (response) {
				void 0;
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
	    		deferred.resolve(successData);
			},
			function (response) {
				void 0;
				//TODO: remove this when proxy is functioning properly
				//response.data.status_details = 'No Customers Match Search Criteria';
				deferred.reject(response);
			});

			return deferred.promise;
		},
		updateCustomerConsent : function(data){
			var deferred = $q.defer(); 
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/updateCustomerConsent';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			//var newCrypt = SharedDataSvc.encrypt(mData, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			/*
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				data.entryPointName = entryPointFromCookie;
			}*/			
			void 0;
			
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				data,
				true
		    ).then(function (response) {
		    	SharedDataSvc.cutomerInfoForMasterCode = response;
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });
			return deferred.promise;
		},
		//VAN-510 - Electronic Consent moved to app flow - Ends
		
		//VAN-3402-Re-engagement with Code - AWP - Start
		searchApplicationByID: function (data) {
			void 0;
			var deferred = $q.defer();
			SharedDataSvc.searchApp = true;
			var params = '&approvalId=' + data;
			params += '&suppressDbCreate=true';
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);
			var headers = {
					'endTarget': 'rentacentervrto/rest/customer/searchApplicationByID',
					'sessionToken' : authentication.getCurrentSessionId(),
					'params': encryptParams,
					'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID'
			};
			var url = PROXY_ACTION_URL;
			var doEncrypt = true;
			ServiceAgent.httpCall(
					url,
					'POST',
					headers,
					null,
					doEncrypt
					// false
			).then(function (response) {
				void 0;
				var successData = (typeof response !== "object") ? JSON.parse(response) : response;
				deferred.resolve(successData);
			},
			function (response) {
				void 0;
				deferred.reject(response);
			});

			return deferred.promise;
		}
		//VAN-3402-Re-engagement with Code - AWP - End
	};
}]);

myApp.factory('authentication', ["$rootScope", "$http", "$q", "$window", "$cookies", "PROXY_SESSION_URL", "SharedDataSvc", "localStorageService", function ($rootScope, $http, $q, $window, $cookies, PROXY_SESSION_URL, SharedDataSvc, localStorageService) {

  /**
   * URL for authentication
   */
  var oktaAuthURL = 'https://rentacenter-admin.oktapreview.com';

  /**
   * API Key
   */
  var APIKey = '00j2sR-yEYrC7TWx-wib-uRDolF_69CNfd0tSpA-1d';

  /**
   * Current session ID
   */
  var currentSessionId = null;

  /**
   * Okta cookie store session id name
   */
  var OKTA_SESSION_ID = "okta_session_id";

  /**
   * Okta cookie store nick name. This is actually the associate password.
   */
  var STORE_NICK_NAME = "store_nick_name";
  var STORE_FIRST_NAME = "store_first_name";

  /**
   * Log in to get auth token. Format for POST url:
   * {{url}}/api/v1/sessions?additionalFields=cookieToken
   * @param user (String) Username string
   * @param pass (String) Password string
   * @return (Object) Successful return will resemble this:
   *  {
        "id": "s01hzzzwtfkRfus5junx0jKkg",
        "userId": "00u3o1grw6g1PGlrm0h7",
        "mfaActive": false,
        "cookieToken": "00-4MgvWg_h8lbcX3Ts8YHCe7Cf4DfWO-3dfVg5r8m"
      }
   */
  var createSessionWithCredentials = function (user) {
    var defer = $q.defer(),
        requestObj = {
          "url": PROXY_SESSION_URL,
          "method": "POST",
          "data": {
            "username" : user
          },
          "withCredentials": true,
          "headers": { 
            'Access-Control-Allow-Origin': 'endTarget,sessionToken,oktaUsername,RAC-CORRELATION-ID',//AC 27054 code Changes
        	/*'Access-Control-Allow-Origin': 'endTarget,sessionToken,oktaUsername,RAC-CORRELATION-ID,https://dev-awp.acceptancenow.com,*',*/
            'Content-Type': 'text/plain',
            'Accept': 'application/json',
            "oktaUsername" : user
          }
        };

    $http(requestObj).
      then(function (response) {
        var data = response.data;
        var status = response.status;
        var headers = response.headers;
        var config = response.config;
        void 0;
        authServiceObject.isAuthenticated = true;
        $cookies.putObject(OKTA_SESSION_ID, headers('sessionToken'));
        currentSessionId = headers('sessionToken');
        defer.resolve(data);
      }).
      catch(function (response) {
        var data = response.data;
        void 0;
        defer.reject(data);
      });

      return defer.promise;
  };

  /**
   * Get user data for current user ['Steak', 'Rice', 'Beans', 'Cheese']
   * @return (Object) 
   */
  var getUserData = function (userId) {
    var defer = $q.defer(),
        requestObj = {
          "url": oktaAuthURL + '/api/v1/users/' + userId,
          "method": "GET",
          "headers": { 
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'SSWS ' + APIKey 
          }
        };

    $http(requestObj).
      then(function (response) {
        var data = response.data;
        void 0;
        defer.resolve(data);
      }).
      catch(function (response) {
        var data = response.data;
        void 0;
        defer.reject(data);
      });

      return defer.promise;
  };

  /**
   * The object returned which represents this service
   */
  var authServiceObject = {

    /**
     * Boolean for auth status. Default is false.
     */
    isAuthenticated: false,

    /**
     * User data. It should resemble this:
     * {
          "id": "00u3o1grw6g1PGlrm0h7",
          "status": "ACTIVE",
          "created": "2015-03-31T02:18:40.000Z",
          "activated": "2015-03-31T02:18:41.000Z",
          "statusChanged": "2015-03-31T19:56:31.000Z",
          "lastLogin": "2015-04-07T22:07:12.000Z",
          "lastUpdated": "2015-03-31T19:56:31.000Z",
          "passwordChanged": "2015-03-31T19:56:31.000Z",
          "profile": {
            "email": "christmoore@deloitte.com",
            "firstName": "Chris",
            "lastName": "Moore",
            "login": "christmoore@deloitte.com",
            "mobilePhone": null,
            "secondEmail": "",
            "nickName": "Chris"
          }
       }
     */
    user: null,

    /**
     * Store password. Used for log in to store kiosk. Comes from getUserData service
     */
    storePass: null,

    /**
     * Agreement Acknowledgement PIN
     */
    agreementPin: null,

    /**
     * Get the current session id, if it exists
     * @return (String) 
     */
    getCurrentSessionId : function () {
      return currentSessionId;
    },

    /**
     * Logs out a user.
     * DELETE {{url}}/api/v1/sessions/{{sessionId}}
     * @return (Object) 
     */
    logoutUser : function () {
      var defer = $q.defer();
        
      authServiceObject.isAuthenticated = false;
      currentSessionId = null;
      $cookies.putObject(OKTA_SESSION_ID, null);
      $cookies.putObject('PP', null);
      $cookies.putObject('isHybrid', null);
      $cookies.putObject('operationMode', null);
      $cookies.putObject('POS-TYPE', null);	  
      $cookies.putObject('isHybridMode', null);
      $cookies.putObject('isMannedMode', null);
      $cookies.putObject('helpdeskNo', null);
      $cookies.putObject('isSpanishStore', null);
      sessionStorage.removeItem('selectedLanguage');
      $rootScope.selectedLanguage = $rootScope.languageSettings[0].id;
      // hard refresh and clearing local storage when user logs out AC-10831
      $window.location.reload(true);
      localStorageService.clearAll();
      defer.resolve({});

      return defer.promise;
    },

    /**
     * Checks if a user is logged in already. Only looks for session cookie
     * @return (Object) A promise Object 
     */
    isLoggedIn : function () {
      var storedSessionId = $cookies.getObject(OKTA_SESSION_ID);
      var storedNickNamePass;
		
	  $rootScope.isHybridMode = $cookies.getObject('isHybridMode');
      $rootScope.isMannedMode = $cookies.getObject('isMannedMode');
	  $rootScope.helpdeskNo = $cookies.getObject('helpdeskNo');

      if(typeof $cookies.getObject(STORE_NICK_NAME) !== "undefined" && $cookies.getObject(STORE_NICK_NAME) !== '') {
        storedNickNamePass = SharedDataSvc.base64.decode($cookies.getObject(STORE_NICK_NAME));
      }

      if (storedSessionId && storedNickNamePass) {
        void 0;
        authServiceObject.isAuthenticated = true;
        currentSessionId = storedSessionId;
        authServiceObject.storePass = storedNickNamePass;
        return true;
      } else {
        void 0;
        return false;
      }
    },

    /**
     * Log in to get auth token. Format for POST:
     * {{url}}/api/v1/sessions?additionalFields=cookieToken
     * @param user (String) Username string
     * @param pass (String) Password string
     * @return (Object) Successful return will resemble this:
     *  {
          "id": "s01hzzzwtfkRfus5junx0jKkg",
          "userId": "00u3o1grw6g1PGlrm0h7",
          "mfaActive": false,
          "cookieToken": "00-4MgvWg_h8lbcX3Ts8YHCe7Cf4DfWO-3dfVg5r8m"
        }
     */
    loginUser : function (user) {
      //clearing local storage when user first time login AC-10831
      localStorageService.clearAll();
      if (!user) {
        void 0;
        return;
      }
      var defer = $q.defer();

      createSessionWithCredentials(user).then(function sessSuccess (sessData) {
        void 0;
        var storeId, userProfile;
        if (sessData.UserInfo && sessData.UserInfo.profile) {
          userProfile = sessData.UserInfo.profile;
          storeId = userProfile.lastName;
          $cookies.putObject(STORE_NICK_NAME, SharedDataSvc.base64.encode(userProfile.nickName));
          $cookies.putObject(STORE_FIRST_NAME, SharedDataSvc.base64.encode(userProfile.firstName));
          $cookies.putObject('PP', SharedDataSvc.base64.encode(sessData.passPhrase));
          
          localStorageService.remove('trainingLibraryDocs');
          authServiceObject.storePass = userProfile.nickName;
          authServiceObject.agreementPin = userProfile.firstName;
        } else {
          void 0;
          var atIndex = user.indexOf('@');
          storeId = user.substring(1, atIndex);
          authServiceObject.storePass = 'test';
        }
        void 0;
        $cookies.putObject('storeId', storeId);
        SharedDataSvc.setStoreId(storeId);
        defer.resolve(sessData);
      }, function sessFail (data) {
        void 0;
        defer.reject(data);
      });

      return defer.promise;
    },
    
  	resetAll: function(){
  	  authServiceObject.isAuthenticated = false;
      currentSessionId = null;
      $cookies.putObject(OKTA_SESSION_ID, null);
      $cookies.putObject('PP', null);
      $cookies.putObject('isHybrid', null);
      $cookies.putObject('operationMode', null);
      $cookies.putObject('POS-TYPE', null);
      localStorageService.clearAll();
  	}
  };

  return authServiceObject;
}]);

myApp.factory("base64", function() {
	var a = {
		alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
		lookup: null,
		ie: /MSIE /.test(navigator.userAgent),
		ieo: /MSIE [67]/.test(navigator.userAgent),
		encode: function(b) {
			var c, d, e, f = a.toUtf8(b),
				g = -1,
				h = f.length,
				i = [, , , ];
			if (a.ie) {
				for (var j = []; ++g < h;) c = f[g], d = f[++g], i[0] = c >> 2, i[1] = (3 & c) << 4 | d >> 4, isNaN(d) ? i[2] = i[3] = 64 : (e = f[++g], i[2] = (15 & d) << 2 | e >> 6, i[3] = isNaN(e) ? 64 : 63 & e), j.push(a.alphabet.charAt(i[0]), a.alphabet.charAt(i[1]), a.alphabet.charAt(i[2]), a.alphabet.charAt(i[3]));
				return j.join("")
			}
			for (var j = ""; ++g < h;) c = f[g], d = f[++g], i[0] = c >> 2, i[1] = (3 & c) << 4 | d >> 4, isNaN(d) ? i[2] = i[3] = 64 : (e = f[++g], i[2] = (15 & d) << 2 | e >> 6, i[3] = isNaN(e) ? 64 : 63 & e), j += a.alphabet[i[0]] + a.alphabet[i[1]] + a.alphabet[i[2]] + a.alphabet[i[3]];
			return j
		},
		decode: function(b) {
			if (b.length % 4) throw new Error("InvalidCharacterError: 'B64.decode' failed: The string to be decoded is not correctly encoded.");
			var c = a.fromUtf8(b),
				d = 0,
				e = c.length;
			if (a.ieo) {
				for (var f = []; e > d;) f.push(c[d] < 128 ? String.fromCharCode(c[d++]) : c[d] > 191 && c[d] < 224 ? String.fromCharCode((31 & c[d++]) << 6 | 63 & c[d++]) : String.fromCharCode((15 & c[d++]) << 12 | (63 & c[d++]) << 6 | 63 & c[d++]));
				return f.join("")
			}
			for (var f = ""; e > d;) f += String.fromCharCode(c[d] < 128 ? c[d++] : c[d] > 191 && c[d] < 224 ? (31 & c[d++]) << 6 | 63 & c[d++] : (15 & c[d++]) << 12 | (63 & c[d++]) << 6 | 63 & c[d++]);
			return f
		},
		toUtf8: function(a) {
			var b, c = -1,
				d = a.length,
				e = [];
			if (/^[\x00-\x7f]*$/.test(a))
				for (; ++c < d;) e.push(a.charCodeAt(c));
			else
				for (; ++c < d;) b = a.charCodeAt(c), 128 > b ? e.push(b) : 2048 > b ? e.push(b >> 6 | 192, 63 & b | 128) : e.push(b >> 12 | 224, b >> 6 & 63 | 128, 63 & b | 128);
			return e
		},
		fromUtf8: function(b) {
			var c, d = -1,
				e = [],
				f = [, , , ];
			if (!a.lookup) {
				for (c = a.alphabet.length, a.lookup = {}; ++d < c;) a.lookup[a.alphabet.charAt(d)] = d;
				d = -1
			}
			for (c = b.length; ++d < c && (f[0] = a.lookup[b.charAt(d)], f[1] = a.lookup[b.charAt(++d)], e.push(f[0] << 2 | f[1] >> 4), f[2] = a.lookup[b.charAt(++d)], 64 != f[2]) && (e.push((15 & f[1]) << 4 | f[2] >> 2), f[3] = a.lookup[b.charAt(++d)], 64 != f[3]);) e.push((3 & f[2]) << 6 | f[3]);
			return e
		}
	};
	return {
		decode: a.decode,
		encode: a.encode
	}
});
(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('customerDataFactory', customerDataFactory);

	/* @ngInject */
	function customerDataFactory(
		$q,
		$http,
		SharedDataSvc,
		API_DOMAIN,
		PROXY_ACTION_URL,
		authentication,
		ServiceAgent
	) {
		var service = {
			findByEngagementId: findByEngagementId
		};
		return service;

		////////////////

		function findByEngagementId (engagementId, useHT) {
			useHT = useHT || false;
			void 0;
			var deferred = $q.defer();
			if (!engagementId) {
				void 0;
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL;
			var params = '&disableHT='+!useHT+'&engagementId=' + engagementId;
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey); //ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID' //Story 26289 code changes
			};

			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				null,
				true
		    ).then(function (response) {
		    	var successData = (typeof response !== "object") ? JSON.parse(response) : response;
		    	deferred.resolve(successData);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}
	}
	customerDataFactory.$inject = ["$q", "$http", "SharedDataSvc", "API_DOMAIN", "PROXY_ACTION_URL", "authentication", "ServiceAgent"];
})();
(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('datesFactory', dates);


	function dates($log, $q) {
		var quoteDates = [],
				monthNames = [ "January", "February", "March", "April", "May", "June",
    									 "July", "August", "September", "October", "November", "December" ];

		return {
			quoteDates: quoteDates,
			getCurrentDate: getCurrentDate,
			getFirstPaymentDate: getFirstPaymentDate,
			setQuoteDates: setQuoteDates,
			getDateByDaysFromNow:getDateByDaysFromNow
		};
		
		function getDateByDaysFromNow(val){
			var deferred = $q.defer();
			var theDate = moment().add(val,'days');
			
			deferred.resolve(theDate);
			
			return deferred.promise;
		}

		function getCurrentDate() {
	      var today = moment().format('YYYY-MM-DD HH:MM:SS');
	      return today;
		}

		function getMonthAsNumber(date) {
			date = moment(date);
			if(date.month) {
				return date.month();
			}
		}

		function getDateArray(date) {
			if(date) {
				var year = parseInt(date.substr(0,4)),
						month = parseInt(date.substr(5,2)) - 1,
						day = parseInt(date.substr(8,2));

				return [year, month, day];
			}
		}

		function getDayAsNumber(date) {
			var day = parseInt(date.substr(8,2));
			return day;
		}

		function getFirstPaymentDate(currentDate) {
			currentDate = currentDate || getCurrentDate();

			// console.log('current date', currentDate);
			// currentDate = moment('2015-04-01').format('YYYY-MM-DD HH:MM:SS');
			// console.log('current date', currentDate);

			if(currentDate) {
				var dateArray = getDateArray(currentDate),
						currentDate = moment(dateArray),
						roundedDate = moment(currentDate.add(14, 'd')._d).format('YYYY-MM-DD HH:MM:SS'),
						roundedDay = roundedDate.substr(8,2),
						currentMonth = getMonthAsNumber(getCurrentDate()),
						roundedMonth = getMonthAsNumber(roundedDate),
						month = currentMonth === roundedMonth ? currentMonth : roundedMonth;
						/*firstOfNextMonthDate = moment([dateArray[0], month + 1, 1]).format('YYYY-MM-DD HH:MM:SS'),
						fifteenthOfNextMonthDate = moment([dateArray[0], month , 15]).format('YYYY-MM-DD HH:MM:SS');*/
				
				/* Fix when current date is in month of December */
				if(currentMonth === 11){
					var firstOfNextMonthDate = moment([dateArray[0] + 1, 0, 1]).format('YYYY-MM-DD HH:MM:SS'),
					fifteenthOfNextMonthDate = moment([dateArray[0] + 1, 0, 15]).format('YYYY-MM-DD HH:MM:SS');
				}else{
					var firstOfNextMonthDate = moment([dateArray[0], month + 1, 1]).format('YYYY-MM-DD HH:MM:SS');
					fifteenthOfNextMonthDate = moment([dateArray[0], month , 15]).format('YYYY-MM-DD HH:MM:SS');
				}
				

				// console.log('rounded date', roundedDate);
				// console.log('rounded day', roundedDay);
				// console.log('rounded month', roundedMonth);
				// console.log('current month', currentMonth);
				// console.log('first of next month', firstOfNextMonthDate);
				// console.log('fifteen', fifteenthOfNextMonthDate);

				if((roundedDay === '01' || Number(roundedDay) < 15) && currentMonth < 11) {
					// console.log('DATE RETURNED', moment([dateArray[0], month, 15]).format('YYYY-MM-DD HH:MM:SS'));
					return moment([dateArray[0], month, 15]).format('YYYY-MM-DD HH:MM:SS');
				} else {
					return Number(roundedDay) >= 15 ? firstOfNextMonthDate : fifteenthOfNextMonthDate;
				}
			}
		}

		function setQuoteDates(currentDate) {
			if(currentDate) {
				var firstPaymentDate = getFirstPaymentDate(currentDate),
						dateArray = getDateArray(firstPaymentDate),
						secondPaymentDate = moment(getNextPaymentDate(firstPaymentDate)._d).format('YYYY-MM-DD HH:MM:SS'),
						thirdPaymentDate = moment(getNextPaymentDate(secondPaymentDate)._d).format('YYYY-MM-DD HH:MM:SS'),
						fourthPaymenDate = moment(getNextPaymentDate(thirdPaymentDate)._d).format('YYYY-MM-DD HH:MM:SS');

				return [{'date': firstPaymentDate, 'nextDate': firstPaymentDate,
								'day' : getDayAsNumber(firstPaymentDate), 'month' : monthNames[getMonthAsNumber(firstPaymentDate)]
								},
							  {'date': secondPaymentDate, 'nextDate': secondPaymentDate,
							  'day' : getDayAsNumber(secondPaymentDate), 'month' : monthNames[getMonthAsNumber(secondPaymentDate)]
								},
								{'date': thirdPaymentDate, 'nextDate': thirdPaymentDate,
							  'day' : getDayAsNumber(thirdPaymentDate), 'month' : monthNames[getMonthAsNumber(thirdPaymentDate)]
								}];
			}
		}

		function getNextPaymentDate(paymentDate) {
			var dateArray = getDateArray(paymentDate),
					month = dateArray[2] < 15 ? dateArray[1] : dateArray[1] + 1,
					nextPaymentDate = dateArray[2] >= 15 ? moment([dateArray[0], month, 1]) : moment([dateArray[0], dateArray[1], 15]);

			return nextPaymentDate;
		}

	}
	dates.$inject = ["$log", "$q"];
})();
myApp.factory('updateEngagementItemFactory', ["$http", "$q", "SharedDataSvc", "API_DOMAIN", "PROXY_ACTION_URL", "authentication", "ServiceAgent", function ($http, $q, SharedDataSvc, API_DOMAIN, PROXY_ACTION_URL, authentication, ServiceAgent) {
	return {
		getData : function (engagementId) {
			void 0;
			SharedDataSvc.globalVars.engagementId =engagementId;// AC 27054 Code changes
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/db/customerEngagement';
			headers.sessionToken = authentication.getCurrentSessionId();
			ServiceAgent.httpCall(
				PROXY_ACTION_URL + '?engagementId=' + engagementId,
				'POST',
				headers,
				null,
				false
			).then(
				function (response) {
					void 0;
					deferred.resolve(response);
				},
				function (response) {
					void 0;
					deferred.reject(response);
				}
			);
			return deferred.promise;
		},
		putData : function (dData) {
			void 0;
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/db/customerEngagement-PUT';
			headers.sessionToken = authentication.getCurrentSessionId();
			delete dData.status;

			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				dData,
				false
			).then(
				function (response) {
					void 0;
					deferred.resolve(response);
				},
				function (response) {
					void 0;
					deferred.reject(response);
				}
			);
			return deferred.promise;
		}
	}
}]);
// dashboard
myApp.factory('getInitialDashboard', getInitialDashboard);

function getInitialDashboard(
	$http,
	$q,
	$cookies,
	SharedDataSvc,
	API_DOMAIN,
	ServiceAgent,
	authentication,
	PROXY_ACTION_URL
) {
	var activeStoreId;
	return {
		getData: getData,
		sendAgreements: sendAgreements
	}

	//https://rac-van-svcs-dd-dev.cloudhub.io/rentacentervrto/rest/email/resendAgreements?customerId=97322&engagementId=96881&emailSendTo=STORE
	function sendAgreements (customerId, engagementId, toCustomer, toStore, custEmail) {
		void 0;

		var deferred = $q.defer();
		var sendTo = toCustomer && toStore ? 'BOTH' : (toCustomer ? 'CUSTOMER' : 'STORE');
		var headers = {
			'endTarget': 'rentacentervrto/rest/email/resendAgreements',
			'sessionToken' : authentication.getCurrentSessionId()
		};
		var url = PROXY_ACTION_URL + '?customerId=' + customerId + '&engagementId=' + engagementId + '&emailSendTo=' + sendTo;
		if(custEmail){
			url = url + '&custEmail=' + custEmail;
		}
		ServiceAgent.httpCall(
			url, 
			'POST', 
			headers, 
			null, 
			false
	    ).then(function (response) {
	    	void 0;
	    	deferred.resolve(response);
	    },
	    function (response) {
	    	void 0;
	      	deferred.reject(response);
	    });

	    return deferred.promise;
	}

	function getData() {
		var deferred = $q.defer();
	    var doEncrypt = true;
		if ($cookies.getObject('storeId')) {
			activeStoreId = $cookies.getObject('storeId');
		} else {
			activeStoreId = SharedDataSvc.vanStoreId;
		}

		var headers = {
			'endTarget': 'rentacentervrto/rest/db/retrieveDashboard',
			'sessionToken' : authentication.getCurrentSessionId()
		};
		var url = PROXY_ACTION_URL + '?vanStoreId=' + activeStoreId;
		void 0;
	    ServiceAgent.httpCall(
	      url, 
	      'POST', 
	      headers, 
	      null, 
	      doEncrypt
	    ).then(function (response) {
	    	if (!doEncrypt) {
	    		deferred.resolve(response);
	    	} else {
	    		var successData = JSON.parse(response);
				deferred.resolve(successData);
	    	}
	    },
	    function (response) {
	      deferred.reject(response);
	    });

	    return deferred.promise;
	}
}
getInitialDashboard.$inject = ["$http", "$q", "$cookies", "SharedDataSvc", "API_DOMAIN", "ServiceAgent", "authentication", "PROXY_ACTION_URL"];

(function(){

	angular
			.module('myApp')
			.factory('productCategoriesFactory', productCategories);

	function productCategories(
			$http,
			$q,
			localStorageService,
			API_DOMAIN,
			ServiceAgent,
			SharedDataSvc,
			PROXY_ACTION_URL,
			authentication
	) {
		return {
			getData: getData
		}

		function getData () {
			void 0;
			var deferred = $q.defer();
			var headers = {
				'endTarget': 'site/json/productCategories.json',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
			};
			ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST',
					headers,
					null,
					false
			).then(function (response) {
						void 0;
						deferred.resolve(response);
					},
					function (response) {
						void 0;
						deferred.reject(response);
					});
			return deferred.promise;
		}
	}
	productCategories.$inject = ["$http", "$q", "localStorageService", "API_DOMAIN", "ServiceAgent", "SharedDataSvc", "PROXY_ACTION_URL", "authentication"];
})();
// OrderSummary
(function() {

	myApp
	.factory('orderEstimatePutFactory', orderEstimatePutFactory);

	function orderEstimatePutFactory(
		$http,
		$q,
		$cookies,
		SharedDataSvc,
		datesFactory,
		productFactory,
		API_DOMAIN,
		PROXY_ACTION_URL,
		authentication,
		ServiceAgent
	) {
		var service = {
			compileData: compileData,
			compileQuickQuoteData: compileQuickQuoteData,
			putData: putData,
			getProcessingFee: getProcessingFee,
			checkApprovalAmt : checkApprovalAmt
		}
		return service;

		////////////////

		function compileData(order, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, prodCat, paymentMode, selectedLanguage) {//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
			// var time1 = performance.now();
			var estimateData = {},
				currentEngagement = order.currentEngagement,
				currentEngagementOrder = order.currentEngagementOrder;
				void 0;

			// Hard-coded for Associate Web Portal
			estimateData.vndSalesPerson = "AWP";
			estimateData.employeeName = "AWP";
			estimateData.employeeId = "168045";
			estimateData.orderIdentifier = "";

			// Request Date
			estimateData.requestDte = datesFactory.getCurrentDate();
			// Confirmed order?
			estimateData.confirmed = currentEngagementOrder.orderContent.confirmed;
			// Other amounts
			estimateData.otherAmt = currentEngagementOrder.orderContent.otherFee;
			// VAN Store ID (make sure to add leading 0)
			estimateData.store = currentEngagement.vanStore.storeId;
			// Order identifier (exists if order has been previous saved to engagement)
			estimateData.orderIdentifier = currentEngagementOrder.orderId;
			// Use next possible payment date
			estimateData.nextRenewDte = datesFactory.getFirstPaymentDate(datesFactory.getCurrentDate());
			// Delivery and extended warranty fees, parsed into float; set to 0 if NaN.
			//estimateData.deliveryAmt = parseFloat(currentEngagementOrder.orderContent.deliveryFee) || 0;
			//estimateData.extendService = parseFloat(currentEngagementOrder.orderContent.extendedServiceAmount) || 0;

			// EngagementID
			estimateData.engagementId = order.currentEngagement.engagementId;

			// Inventory List
			estimateData.inventoryList = [];
			void 0;

			_.forEach(order.currentEngagementOrder.orderContent.orderItems, function(item, index) {
				var orderItem = {
					"racBrand" : "ACP", // 'ESV' for extended
					"itemModelNumber" : "", // 'ExtSvc' for extended
					"racModelNumber" : "",
					"depreciableLifeInMonths" : 24,
					"itemOptions" : "",
					"itemOriginalCost" : 0
				};

				orderItem.racBrand = "ACP";
				orderItem.itemModelNumber = "";
				orderItem.racModelNumber = productFactory.getRacModelNumber(prodCat, item, selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				orderItem.depreciableLifeInMonths = 24;
				orderItem.itemOptions = productFactory.buildItemOptions(item);
				orderItem.itemOriginalCost = SharedDataSvc.formatItemPrice(item.itemPrice);
				void 0;
				estimateData.inventoryList.push(orderItem);
			});
			//PRB0042514 - starts
			if(order.currentEngagementOrder.orderContent.extendedServiceAmount > 0) {
					var extendedWarranty = {
						"racBrand" : "ACP",
						"itemModelNumber" : "ExtSvc",
						"racModelNumber" : "ESVPLAN-1",
						"depreciableLifeInMonths" : 24,
						"racRateCode" : "ESV396",
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(order.currentEngagementOrder.orderContent.extendedServiceAmount)
					};
					estimateData.inventoryList.push(extendedWarranty);
				}
				//PRB0042514 - Ends

			// Delivery and extended warranty fees, parsed into float; set to 0 if NaN.			
			estimateData.feeArray = [
				{
					"feeType": "ESV",
					"feeAmount": parseFloat(currentEngagementOrder.orderContent.extendedServiceAmount) || 0,
					"feeAcrossTerm": false
				},
				{
					"feeType": "Delivery",
					"feeAmount": parseFloat(currentEngagementOrder.orderContent.deliveryFee) || 0,
					"feeAcrossTerm": false
				}
			]

			if(esvdelFeePaymentOptionFlagValue) {
				if(currentEngagementOrder.orderContent.extendedServiceAmount > 0 && currentEngagementOrder.orderContent.deliveryFee <= 0) {
					estimateData.feeArray[0].feeAcrossTerm = true;
				} else if(currentEngagementOrder.orderContent.extendedServiceAmount <= 0 && currentEngagementOrder.orderContent.deliveryFee > 0 && delFeePaymentOptionFlagValue && paymentMode === "monthly") {
					estimateData.feeArray[1].feeAcrossTerm = true;
				} else if(currentEngagementOrder.orderContent.extendedServiceAmount > 0 && currentEngagementOrder.orderContent.deliveryFee > 0) {
					estimateData.feeArray[0].feeAcrossTerm = true;
					if(paymentMode === "monthly" && delFeePaymentOptionFlagValue) {
						estimateData.feeArray[1].feeAcrossTerm = true;
					}
				}
			}

			// customer info
			estimateData.customer = {
				title: "",
				lastName: currentEngagement.customerLastName,
				firstName: currentEngagement.customerFirstName,
				contactPhone1: '999-999-9999'
			}

			estimateData.quoteItem = [];
			for (var i = 0; i < currentEngagementOrder.orderContent.orderItems.length; i++) {
				var item = currentEngagementOrder.orderContent.orderItems[i];
				var newObj = {
					subType: item.subType || '',
					itemId: i.toString(),
					unitType: item.unitType,
					itemDesc: (item.itemDescription.slice(0, 39)).replace('"', ' ').replace('\\', ''),
					qty: item.quantity,
					price: Number(item.itemPrice)
				};
				estimateData.quoteItem.push(newObj);
			}

			void 0;

			// var encodedContent = SharedDataSvc.globalVars.orderEstimatePut;
			// console.log(performance.now() - time1);
			return estimateData;
		}

		function compileQuickQuoteData (storeNo, inputValue, productCategories) {
			void 0;
			if(inputValue.deliveryFee)
			void 0;
			var quickQuoteData = {};
			quickQuoteData.store = storeNo;
			quickQuoteData.quickQuote = true;
			quickQuoteData.inventoryList = [];
			quickQuoteData.feeArray = [];

			var orderItem = {
				"racBrand" : "ACP", // 'ESV' for extended
				"itemModelNumber" : "", // 'ExtSvc' for extended
				"depreciableLifeInMonths" : 24,
				"itemOptions" : "",
				"itemOriginalCost" : inputValue.merchandiseTotal * 100
			};

			if(inputValue.selectedProduct){
				orderItem["racModelNumber"] = getRacModelNumber(productCategories, inputValue.selectedProduct.id, inputValue.merchandiseTotal);
			} else {
				orderItem["racModelNumber"] = getRacModelNumber(productCategories, "LRM", inputValue.merchandiseTotal);
			}

			quickQuoteData.inventoryList.push(orderItem);

			if(inputValue.deliveryFee > 0) {
				var deliveryItem = {
					"feeAmount" : inputValue.deliveryFee,
					"feeAcrossTerm" : false,
					"feeType" : "Delivery"
				};

				quickQuoteData.feeArray.push(deliveryItem);
			}

			if(inputValue.extendedServiceAmount > 0) {
				var extendedService = {
					"racBrand" : "ACP",
					"itemModelNumber" : "ExtSvc",
					"racModelNumber" : "ESVPLAN-1",
					"depreciableLifeInMonths" : 24,
					"itemOriginalCost" : inputValue.extendedServiceAmount * 100
				};

				quickQuoteData.inventoryList.push(extendedService);
			}


			void 0;
			return quickQuoteData;
		}

		function getRacModelNumber (productCategories, htSubTypeVal, itemPrice) {
			var priceBucket = _.where(productCategories, { 'htSubTypeCode': htSubTypeVal })[0].priceBuckets,
				buckets = productFactory.sortBuckets(priceBucket),
				productSubType = htSubTypeVal,
				selectedBucket,
				i = 0,
          		len = buckets.length;

			    while(i < len) {
			      if(buckets[i] < itemPrice) {
			        selectedBucket = buckets[i - 1];
			        break;
			      }
			        i++;
			    }

			    if(!selectedBucket) {
			      selectedBucket = _.last(buckets);
			    }
			    void 0;
			    return String(productSubType) + String(selectedBucket);
		}

		function putData (pData, isDraft, quoteMode) {
			void 0;
			var data = pData || SharedDataSvc.globalVars.orderEstimatePut;
			var deferred = $q.defer();
			var url = PROXY_ACTION_URL;
			var headers = {
				'endTarget': 'rentacentervrto/rest/quoteV2',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'quote-service': SharedDataSvc.quoteService,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,quote-service'
			};

			if(quoteMode) {
				var headers = {
					'endTarget': 'rentacentervrto/rest/quoteV2',
					'sessionToken' : authentication.getCurrentSessionId(),
					'quote-service': 'HT',
					'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
					'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,quote-service'
				};
			}

			data.isDraft = isDraft || false;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				data,
				false
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		// Get the store processing fee using a fake order
		function getProcessingFee() {
			var deferred = $q.defer();

			var quickQuoteData = {};
			quickQuoteData.store = $cookies.getObject('storeId');
			quickQuoteData.quickQuote = true;
			quickQuoteData.inventoryList = [];
			
			var orderStub = {
				"racBrand" : "ACP", // 'ESV' for extended
				"itemModelNumber" : "", // 'ExtSvc' for extended
				"racModelNumber" : "LRM900",
				"depreciableLifeInMonths" : 24,
				"itemOptions" : "",
				"itemOriginalCost" : 40000
			};
			quickQuoteData.inventoryList.push(orderStub);
			putData(quickQuoteData, false, 'HT')
				.then(
					// Success
					function (response) {
						void 0;
						deferred.resolve(response.processingFee);
					},
					// Error
					function (error) {
						deferred.reject(error);
					}
				);

			return deferred.promise;
		}

		function checkApprovalAmt(pData) {
			void 0;
			var data = pData;
			var deferred = $q.defer();
			var url = PROXY_ACTION_URL;
			var activeStoreId = $cookies.getObject('storeId') ? $cookies.getObject('storeId') : SharedDataSvc.vanStoreId;
			var headers = {
				'RAC-KIOSK-LOCATION-ID' : activeStoreId,
				'endTarget': 'rentacentervrto/rest/checkApprovalAmount',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'quote-service': SharedDataSvc.quoteService,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,quote-service'
			};

			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				data,
				false
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}
	}
	orderEstimatePutFactory.$inject = ["$http", "$q", "$cookies", "SharedDataSvc", "datesFactory", "productFactory", "API_DOMAIN", "PROXY_ACTION_URL", "authentication", "ServiceAgent"];
})();

(function() {
  'use strict';
 
  angular
    .module('myApp')
    .factory('paymentFactory', payment);

  payment.$inject = ["$q", "$log", "$http", "SharedDataSvc", "API_DOMAIN", "authentication", "ServiceAgent", "PROXY_ACTION_URL"];

  function payment ($q, $log, $http, SharedDataSvc, API_DOMAIN, authentication, ServiceAgent, PROXY_ACTION_URL) {

    return {
      calculatePayment: calculatePayment,
      createEpayAccount: createEpayAccount,
      epayLogin: epayLogin,
      processCashPayment: processCashPayment,
      processCreditPayment: processCreditPayment,
      processAutoPayment: processAutoPayment,
      processBenefits: processBenefits,
      pdfSign: pdfSign,
      docuSign: docuSign,
      sendEmailEML: sendEmailEML,//VAN-441 - VAN Docusign - Remote signing documents on personal device
      updateSignerStatus: updateSignerStatus,
      getCardTypeDtl: getCardTypeDtl,
      enrollAutopayWithEcomplish: enrollAutopayWithEcomplish,//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
      postAutopayToHT: postAutopayToHT,//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
      initialPayAndAutoPay: initialPayAndAutoPay,//VAN-2012-VAN-AutoPay Issue
      postCashAndAutoPay: postCashAndAutoPay//VAN-2012-VAN-AutoPay Issue
    };

    function makeServiceCall (data, endTarget) {
      void 0;
      var deferred = $q.defer();
      var headers = {
        'endTarget': endTarget,
        'sessionToken' : authentication.getCurrentSessionId()
      };
      ServiceAgent.httpCall(
        PROXY_ACTION_URL,
        'POST',
        headers,
        data,
        true
        ).then(function (response) {
          void 0;
          var successData = response;
          deferred.resolve(successData);
        },
        function (response) {
          void 0;
          deferred.reject(response);
        });

        return deferred.promise;
    }

    function createEpayAccount (epayAccountData) {
      $log.log('[paymentFactory.createEpayAccount] ' + epayAccountData);
      return makeServiceCall(epayAccountData, 'rentacentervrto/rest/EpayService/createCustomerAccount');
    }

    function epayLogin (epayLogin) {
      $log.log('[paymentFactory.epayLogin] ' + epayLogin);
      return makeServiceCall(epayLogin, 'rentacentervrto/rest/EpayService/verifyAccount');
    }
    
    function pdfSign(signatureData){
    	$log.log('[paymentFactory.pdfSignerService] ' + signatureData);
    	return makeServiceCall(signatureData, 'rentacentervrto/rest/pdfSignerService');
    }

    function docuSign(signatureData, useRAUpdateService){
      $log.log('[paymentFactory.docuSign] ' + signatureData);
      var deferred = $q.defer();
      var endTarget = 'rentacentervrto/rest/digitalSigning/' +
        (useRAUpdateService ? 'getAgreementForSigning' : 'getSigningUrl');

      var headers = {
        'endTarget': endTarget,
        'sessionToken' : authentication.getCurrentSessionId(),
        'CLIENT-SOURCE-ID':'3'
      };
      var url = PROXY_ACTION_URL;
      ServiceAgent.httpCall(
        url,
        'POST',
        headers,
        signatureData,
        true
      ).then(function (response) {
          void 0;
          var successData = (typeof response !== "object") ? JSON.parse(response) : response;
          deferred.resolve(successData);
      },
      function (response) {
          void 0;
          deferred.reject(response);
      });

      return deferred.promise;
    }
    //VAN-441 - VAN Docusign - Remote signing documents on personal device - Starts
    function sendEmailEML(signatureData){
    	$log.log('[paymentFactory.docuSign] ' + signatureData);
    	var deferred = $q.defer();

    	var headers = {
    			'endTarget': 'rentacentervrto/rest/digitalSigning/emailDocusignToRemote',
    			'sessionToken' : authentication.getCurrentSessionId(),
    			'CLIENT-SOURCE-ID':'3'
    	};
    	var url = PROXY_ACTION_URL;
    	ServiceAgent.httpCall(
    			url,
    			'POST',
    			headers,
    			signatureData,
    			true
    	).then(function (response) {
    		void 0;
    		var successData = (typeof response !== "object") ? JSON.parse(response) : response;
    		deferred.resolve(successData);
    	},
    	function (response) {
    		void 0;
    		deferred.reject(response);
    	});

    	return deferred.promise;
    }
    //VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends
    function updateSignerStatus(signerData){
      $log.log('[paymentFactory.updateSignerStatus] ' + signerData);
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'rentacentervrto/rest/db/updateSignerStatus',
        'sessionToken' : authentication.getCurrentSessionId()
      };
      var url = PROXY_ACTION_URL;
      ServiceAgent.httpCall(
        url,
        'POST',
        headers,
        signerData,
        false
      ).then(function (response) {
          void 0;
          var successData = (typeof response !== "object") ? JSON.parse(response) : response;
          deferred.resolve(successData);
      },
      function (response) {
          void 0;
          deferred.reject(response);
      });

      return deferred.promise;
    }

    function calculatePayment (paymentSpec) {
      $log.log('[paymentFactory.calculatePayment] ' + paymentSpec);
      return makeServiceCall(paymentSpec, 'rentacentervrto/rest/payment/calculatePayment');
    }

    function processCashPayment (cashPaymentSpec) {
      $log.log('[paymentFactory.processCashPayment] ' + cashPaymentSpec);
      return makeServiceCall(cashPaymentSpec, 'rentacentervrto/rest/payment/postInitialCashPayment');
    }

    function processCreditPayment (cashPaymentSpec) {
      $log.log('[paymentFactory.processCreditPayment] ' + cashPaymentSpec);
//      return makeServiceCall(cashPaymentSpec, 'rentacentervrto/rest/payment/initial');
      	return makeServiceCall(cashPaymentSpec, 'rentacentervrto/rest/payment/vantivInitialPayment');//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }

    function processAutoPayment (autoPaymentSpec) {
      $log.log('[paymentFactory.processAutoPayment] ' + autoPaymentSpec);
      return makeServiceCall(autoPaymentSpec, 'rentacentervrto/rest/EpayService/enrollAutopay');
    }

    function processBenefits (benefitsSpec) {
      $log.log('[paymentFactory.processBenefits] ' + benefitsSpec);
      return makeServiceCall(benefitsSpec, 'rentacentervrto/rest/benefitsPlus');
    }

    function getCardTypeDtl(){
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'site/json/globalSettings.json',
        'sessionToken' : authentication.getCurrentSessionId(),
        'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
      };
      ServiceAgent.httpCall(
      PROXY_ACTION_URL,
      'POST',
      headers,
      null,
      false
      ).then(function (response) {
        void 0;
          deferred.resolve(response);
      },
      function (error) {
          void 0;
          deferred.reject(error);
      });

      return deferred.promise;
    }
    
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    function enrollAutopayWithEcomplish (autoPaySpec) {
    	$log.log('[paymentFactory.enrollAutopayWithEcomplish] ' + autoPaySpec);
//    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/enrollAutopayWithEcomplish');
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/vantivEnrollAutopay');//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }
    function postAutopayToHT (autoPaySpec) {
    	$log.log('[paymentFactory.postAutopayToHT] ' + autoPaySpec);
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/postAutopayToHT');
    }
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
    

    //VAN-2012-VAN-AutoPay Issue - Starts
    function initialPayAndAutoPay (autoPaySpec) {
    	$log.log('[paymentFactory.initialPayAndAutoPay] ' + autoPaySpec);
//    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/initialPaymentAndSetupAutoPay');
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/vantivInitialAutopayPayment');//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }
   
    function postCashAndAutoPay (autoPaySpec) {
    	$log.log('[paymentFactory.processCashAndAutoPay] ' + autoPaySpec);
//    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/payment/postInitialCashAndAutoPay');
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/payment/initialCashPaymentAutopayHT');//VAN-3330 -replace eComplish iframe with VANTIV iframe - AWP
    }
    //VAN-2012-VAN-AutoPay Issue - Ends
  }
})();
(function() {
  angular
    .module('myApp')
    .factory('productFactory', product);


  /* @ngInject */
  function product(
    $q,
    $log,
    $http,
    SharedDataSvc,
    localStorageService,
    productCategoriesFactory,
    API_DOMAIN
  ) {

    return {
      getProductModelInfo: getProductModelInfo,
      getProductUnitType: getProductUnitType,
      getProductInfo: getProductInfo,
      getProductCategories: getProductCategories,
      getRacModelNumber: getRacModelNumber,
      buildItemOptions: buildItemOptions,
      sortBuckets: sortBuckets
    };

    function getProductCategories() {
      return productCategoriesFactory.getData()
        .then(function(response) {
          return response;
        });
    }

    function getProductModelInfo(productCategories, item, selectedLanguage) {//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
      var matching,
          filtered,
          subType,
          priceBuckets,
          bedItem;
          
    //   VAN-3239-PRB0043451 - AWP-Brand/Model not found on model master - Start
    if(selectedLanguage == 'en'){
      matching = _.where(productCategories, { 'labelEn': item.category });}
 
      else{
          matching = _.where(productCategories, { 'labelEs': item.category });
      }
    //   matching = _.where(productCategories, { 'labelEn': item.category });
    // VAN-3239-PRB0043451 - AWP-Brand/Model not found on model master - End

     
      // Need to find correct product for beds, this is a special case
      if(item.iconURL === 'Bed') {
        filtered = [];
        bedItem = _.findWhere(productCategories, { 'labelEn' : item.category }); //PRB0041531 – Agreement stuck in processing
        filtered.push(bedItem);
       
        // $log.log('matching beds', filtered);
        //VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Starts
      } else if(item.category === 'TV Accessories') {
    	  matching = _.where(productCategories, { 'iconURL': item.iconURL });
    	  filtered = _.filter(matching, function(val) {
    		  return _.has(val, 'htSubTypeCode');
    	  });	 
      }else {//VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Ends 
        // Filter only the matches that have a subtype code
    	
    	filtered = _.filter(matching, function(val) {
    		
    		return _.has(val, 'htSubTypeCode');
        });	
        
      }
     
      if(!_.isEmpty(matching) && item.iconURL !== 'Bed') {
        priceBuckets = _.last(matching).priceBuckets;
      } else {

        if(filtered[0]){
            priceBuckets = filtered[0].priceBuckets;
        }
        
      }
      
      // Item with subtype wasn't found, now search by custom attributes
      
      void 0;
//      alert(JSON.stringify(filtered.length+'--'+ item.attributes.length));
      if (filtered.length < 1 && item.attributes.length > 0) {
    	  
    	 
        var attributeObject;
        for (var i = 0; i < item.attributes.length; i++) {
          // find the attribute object to check if it has a subtype
        	//VAN-693 - PRB0042959 - PR stores Spanish order issues - Starts
//      	  alert(JSON.stringify(subType));
        	if(selectedLanguage == 'en'){
        		attributeObject = findDeep(_.last(matching).customAttributeGroups, {labelEn: item.attributes[i].value});}
        	else{
        		attributeObject = findDeep(_.last(matching).customAttributeGroups, {labelEs: item.attributes[i].value});
        	}
        	//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage - Ends
        	if (typeof attributeObject !== 'undefined' && attributeObject.htSubTypeCode) {
            subType = attributeObject.htSubTypeCode;
            if (priceBuckets.length < 1) priceBuckets = attributeObject.priceBuckets;
            break;
          }
        }
      } else {
    	 
    	
     	  subType = _.pluck(filtered, 'htSubTypeCode');
    	  
    	  subType = typeof subType[0] !== 'undefined' ? subType[0] : '';

     
      }

      void 0;
      void 0;

      return {
        subType: subType,
        priceBuckets: priceBuckets
      };
    }

    function getProductUnitType(productCategories, product) {
      var parentLabel = _.has(product, 'parent') ? product.parent : false,
          parent = _.findWhere(productCategories, { 'labelEn': parentLabel });

      if(product.htUnitTypeCode) {
        return product.htUnitTypeCode;
      }

      if(parent.htUnitTypeCode) {
        return parent.htUnitTypeCode;
      }

      // Call again if no htUnitTypeCode has been found
      if(!product.htUnitTypeCode || !parent.htUnitTypeCode) {
        return getProductUnitType(productCategories, parent);
      }
    }

    function getProductInfo(orderProduct) {
      // console.log('ORDER PRODUCT', orderProduct);
      var deferred = $q.defer();
      getProductCategories()
        .then(function(response){
          var product = null,
              returnObj = { 'subType' : null, 'unitType' : null };

          if(orderProduct.iconURL) {
            product = _.findWhere(response.productCategories, { 'iconURL': orderProduct.iconURL });
          } else {
            product = _.findWhere(response.productCategories, { 'iconURL': orderProduct.category });
          }

          product.subType = product.htSubTypeCode;
          var unitType = getProductUnitType(response.productCategories, product);
          returnObj.subType = product.subType;
          returnObj.unitType = unitType;
          deferred.resolve(returnObj);

          // $log.log('PRODUCT SUBTYPE', product.subType);
          // $log.log('PRODUCT', product);
          // $log.log('HT UNIT TYPE', unitType);
        });
      return deferred.promise;
    }

    function buildItemOptions(item) {
      var props = ['material', 'color', 'quantity', 'other'],
          options = [];

      _.forEach(props, function(prop, i) {
        if(_.has(item, prop)) {
          options.push(item[prop]);
        }
      });

      return options.join(' ');
    }

    function sortBuckets(buckets) {
      var buckets;

      buckets = _.sortBy(_.pluck(buckets, 'value'), function(num) {
                  return num * -1;
                });
      return buckets;
    }

    function getRacModelNumber(productCategories, item, selectedLanguage) {//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
      var product = getProductModelInfo(productCategories, item, selectedLanguage),//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
          bucket = product.priceBuckets,
          productSubType = product.subType,
          buckets = sortBuckets(bucket),
          selectedBucket,
          i = 0,
          len = buckets.length;

          void 0;
      // console.log('BUCKET', bucket);
      // console.log('BUCKETs', buckets);
      // console.log('item', item);
      // console.log('product', product);
      // console.log('subType', product.prod.htSubTypeCode);
_
      while(i < len) {
        if(buckets[i] < item.itemPrice) {
          selectedBucket = buckets[i - 1];
          break;
        }
        i++;
      }
   if(!selectedBucket) {
        selectedBucket = _.last(buckets);
      }
      // console.log('SELECT BUCKET', selectedBucket);
      // console.log('MODEL NUMBER', product.prod.htSubTypeCode + selectedBucket);
      void 0;
      return String(productSubType) + String(selectedBucket);
    }
  }
  product.$inject = ["$q", "$log", "$http", "SharedDataSvc", "localStorageService", "productCategoriesFactory", "API_DOMAIN"];

  function findDeep(items, attrs) {
    function match(value) {
      for (var key in attrs) {
        if(!_.isUndefined(value)) {
          if (attrs[key] !== value[key]) {
            return false;
          }
        }
      }
      return true;
    }
    function traverse(value) {
      var result;
      _.forEach(value, function (val) {
        if (match(val)) {
          result = val;
          return false;
        }

    if (_.isObject(val) || _.isArray(val)) {
          result = traverse(val);
        }

        if (result) {
          return false;
        }
      });
      return result;
    }
    return traverse(items);
  }
})();
'use strict';
//Defect 28266 - VAN: References getting wiped off in HT - Added $rootScope
myApp.service('SharedDataSvc', ["$cookies", "$rootScope", function ($cookies,$rootScope) {

    //this.orderSub = [];
    //this.orderSub.quoteItem = [];
	//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
	this.dashboardAgreementItems="";
	// Feature 9467 - AWP-Services: Paginate Invoice Dashboard-Activity code changes -Starts
	this.order="";
	// Feature 9467 - AWP-Services: Paginate Invoice Dashboard-Activity code changes -Ends
	//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
    this.globalmessage = '';
    this.globalmessageShow = false;
    this.addItemForm = [];
    this.sessionVars = [];
    this.sessionVars.customerEntity = {};
    this.hasNCmodalRun = false;
    this.hasECmodalRun = false;
    this.hasCCmodalRun = false;
    this.hasPAmodalRun = false;
    this.hasADmodalRun = false;
    this.hasBImodalRun = false;
    this.hasAAmodalRun = false;
    this.hasDAmodalRun = false;
    this.hasMImodalRun = false;
    this.customerInfo = {};
    this.customerPrivate = {};
    this.customerInfo.phones = [];
    this.customerInfo.incomeSources = [];
    this.customerInfo.personalReferences = [];
    this.modalOpen = false;
    this.globalVars = {};
    this.deliveryTempId = '';
    this.itemDeleteTempIdx = 0;
    this.itemEditTempIdx = 0;
    this.vanStoreId = $cookies.getObject('storeId');
    this.viewLoading = false;
    this.versionNo = angular.element('.version-number').html();
    this.raygunKey;
    this.editOrder = false;
    this.initialPaymentValue;
    this.quoteService;
    this.vccBarcodeGenerateAttempts = 0;
    this.validatePassPhraseAttempts = 0;
    this.validateMasterCodePassPhraseAttempts = 0;
    this.currentEngagementData;
    this.regex;
    this.initialPaymentAmount;
    this.eligiblePromos = [];
    //Feature 7872 Integration of Logz code changes starts
    this.logzApiKey;
    this.isLogzEnabled;
    this.racCorrelatioId;
    this.searchValue;
    //Feature 7872 Integration of Logz code changes ends
    this.invoiceReductionPayment;
    this.invoiceReductionPaymentAmount;
    
    this.commonHeaders = {
        'Access-Control-Allow-Headers': 'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName, orderOriginator,RAC-CORRELATION-ID',//AC 27054 code Changes
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type': 'text/plain'
    };
    this.commonHeadersJSON = {
        'Access-Control-Allow-Headers': 'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName, orderOriginator,RAC-CORRELATION-ID', //Story 26289 code changes
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type': 'text/plain',
        'Public-Key':'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB'
    };
    this.commonHeadersENCRYPT = {
        'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName,RAC-CORRELATION-ID',//AC 27054 code Changes
        'Authorization':'Basic dGVzdDp0ZXN0',
        'Public-Key':'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB',
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type':'application/json'
    };
    this.agreementHeadersENCRYPT = {
        'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName,RAC-CORRELATION-ID',//AC 27054 code Changes
        'Authorization':'Basic dGVzdDp0ZXN0',
        'Public-Key':'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB',
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type':'application/json'
    };
    void 0;

    this.headerAuth = {
        'Authorization': 'Basic dGVzdDp0ZXN0'
    };

    var headerPubKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB';
	
    this.globalVars.order =
    {
        customerId: "",
        processingFee: 25,
        deliveryFee: 0,
        languagePreference: "ENGLISH",
        calculatedTax:0,
        orderSubtotal:0,
        initialPayment:0,
        monthlyPayment:0,
        deliveryDate: "",
        currentEngagementId: null,
        currentEngagement: {
            engagementId: null,
            orderContent: null,
            customerFirstName: "",
            customerLastName: "",
            customerId: 0,
            vanStoreId: null,
            modifiedDateStamp: "",
            orderStatus: "",
            deliveryStatus: "",
            agreementStatus: "",
            applicationStatus: "",
            approvalAmount: 0,
            salesStatus: "",
            estimatedDeliveryDate: null, // 2014-05-24 00:00
            paymentStatus: "",
            invoiceNum: "",
            salesAssociateID: ""//
        },
        currentEngagementOrder: {
            customerId: 0,
            dateStamp: "",
            modifiedDateStamp: "",
            engagementId: null,
            agreementHtId: null,
            vanStoreId: "",
            orderStatus: "",
            deliveryStatus: "",
            paymentStatus: "",
            salesStatus: "",
            agreementStatus: "",
            reconcilationStatus: null,
            orderContent: {
                extendedServiceAmount: 0.00,
                confirmed: 0,
                otherFee: 0,
                installationFee: 0,
                deliveryFee: 0,
                orderIdentifier: "",
                orderItems: [ /*
                    {
                    itemPrice: 0.00,
                    brand: "", //"Maytag",
                    itemDescription: "", //"Maytag Top Load Dryer Bisque Electric",
                    category: "", // "Top Load Dryer",
                    quantity: 1,
                    color: "", //"Bisque",
                    setItems: [],
                    attributes: [
                        {
                        customAttribute: "Type",
                        value: "" // "Electric"
                        }
                      ],
                    iconURL: "", // "TopLoadDryer_Gas",
                    model: "",
                    priceBuckets: [],
                    unitType: "",
                    subType: ""
                    }
                */]
            },
            applicationStatus: "",
            customerHtID: "", // HT2110000276
            customerHtStoreId: null,
            estimatedDeliveryDate: null,
            orderId: null,
            orderOriginator: "AWP"
        }
    };

    this.globalVars.estimateReturn = {

    };
	
	this.globalVars.disableOrderButtons = ["false"];
	
	this.globalVars.disableOrderButtonsOnSetAbsent = ["false"];

    this.globalVars.status = {
        agreement: {
            eaNoticeSeen: false,
            agreementBlock1: {},// agreement
            agreementBlock2: {},// benefits plus
            agreementBlock3: {},// ldw
            agreementBlock4: {},
            declineRadioResetGroup: "",
            signedId: "",
            currentAgreementToSign: "",
            signingAgreeDecline: {},
            agreeCheck: false,
            agreementStatus: "Not Started",
            agreementStatusId: 0,
            possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "Agreement Declined",
                cssClass: "process-problem",
                editable: false
              },
              {
                id:2,
                title: "Agreements Accepted",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Agreements Pending",
                cssClass: "process-yellow",
                editable: false
              }
            ]
        },
        epay:{
            username:"",
            password:"",
            passwordVerify: "",
            loggedIntoEpay: false,
            newOrExistingAcct: true, // true=new, false=existing
            securityQuestion:[
                {
                  name: "What street did you grow up on?",
                  value: "What street did you grow up on?"
                },
                {
                  name: "What is your mother's maiden name?",
                  value: "What is your mother's maiden name?"
                },
                {
                  name: "What was the name of your elementary / primary school?",
                  value: "What was the name of your elementary / primary school?"
                },
                {
                  name: "What is your first pet's name?",
                  value: "What is your first pet's name?"
                },
                {
                  name: "What is your father's middle name?",
                  value: "What is your father's middle name?"
                },
                {
                  name: "What are the last 5 digits of your driver's license number?",
                  value: "What are the last 5 digits of your driver's license number?"
                },
                {
                  name: "In what city did you meet your spouse/significant other?",
                  value: "In what city did you meet your spouse/significant other?"
                },
                {
                  name: "What is the street number of the house you grew up in?",
                  value: "What is the street number of the house you grew up in?"
                }
            ],
            securityAnswer:"",
            initialPayment:{
                creditCard:{
                    cc1: "",
                    cc2: "",
                    cc3: "",
                    cc4: "",
                    nameOnCard:"",
                    expDate:"",
                    securityCode:"",
                    billingAddress1: "",
                    billingAddress2: "",
                    billingCity: "",
                    billingState: "",
                    billingZipCode: "",
                    billingEmail: "",
                    billingPhone: ""
                }
            },
            autoPayment: {
                useSameAcctAsInitial: false,
                existingBankOrCredit: "existing", // 'existing', 'credit', 'other'
                routingNumber:"",
                accountNumber:"",
                accountType:"",
                existingAcctNumber:"1234",
                nameOnAccount:"",
                creditCard:{
                    cc1: "",
                    cc2: "",
                    cc3: "",
                    cc4: "",
                    nameOnCard:"",
                    expDate:"",
                    securityCode:"",
                    billingAddress1: "",
                    billingAddress2: "",
                    billingCity: "",
                    billingState: "",
                    billingZipCode: "",
                    billingEmail: "",
                    billingPhone: ""
                }
            },
            epayStatus: "Not Started",
            epayStatusId: 0,
            possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Completed",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Pending",
                cssClass: "process-yellow",
                editable: false
              },
              {
                id:4,
                title: "Declined",
                cssClass: "process-problem",
                editable: false
              }
            ]
        },
        order:{
          orderStatus: "Not Started",
          orderStatusId: 0,
          possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Pending Confirmation",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:3,
                title: "Confirmed",
                cssClass: "process-complete",
                editable: false
              }
            ]
        },
        delivery: {
          deliveryStatus: "Not Scheduled",
          deliveryStatusId: 0,
          possibleStatuses: [
              {
                id:0,
                title: "Not Scheduled",
                cssClass: "process-problem",
                editable: true
              },
              {
                id:1,
                title: "Scheduled for ",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Confirmed",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Pickup",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:4,
                title: "Pending",
                cssClass: "process-complete",
                editable: false
              }
            ]
        },
        application: {
          applicationStatus: "Not Started",
          applicationStatusId: 0,
          possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Approved for ",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Declined",
                cssClass: "process-problem",
                editable: false
              },
              {
                id:4,
                title: "Outside of Service Area",
                cssClass: "process-problem",
                editable: false
              },
              {
                id:5,
                title: "Manual Review",
                cssClass: "process-yellow",
                editable: false
              },
              {
                id:6,
                title: "Confirmed",
                cssClass: "process-complete",
                editable: false
              }
            ]
        },
        dashboard: {
          mainDashboardStatus: "",
          mainDashboardStatusId: 0
        },
        store: {
          storeStatus: "Not Started",
          storeStatusId: 0,
          possibleStatuses: [
            {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
            },
            {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
            },
            {
                id:2,
                title: "Pending",
                cssClass: "process-standby",
                editable: true
            },
            {
                id:3,
                title: "Complete",
                cssClass: "process-complete",
                editable: true
            },
            {
                id:4,
                title: "Declined",
                cssClass: "process-problem",
                editable: false
            }
          ]
        }
    };

    this.globalVars.customer = {
        firstName:"",
        lastName:"",
        middleInitial: "",
        fullName:"",
        email:"",
        dob:{
            month:0,
            year:0,
            day:0
        },
        ssn:{
            ssn1:"",
            ssn2:"",
            ssn3:""
        }
    };

    this.globalVars.agreements = [
      {
          index: 0,
          id: "agreementBlock1",
          name: "Rental-Purchase Agreement",
          pdfLink: "",
          agreed: false,
          signed: false
      },
      {
          index: 1,
          id: "agreementBlock2",
          name: "Optional Liability Damage Waiver",
          pdfLink: "",
          agreed: false,
          signed: false
      },
      {
          index: 2,
          id: "agreementBlock3",
          name: "Benefits Plus Form",
          pdfLink: "",
          agreed: false,
          signed: false
      },
      {
          index: 3,
          id: "agreementBlock4",
          name: "Arbitration Agreement",
          pdfLink: "",
          agreed: false,
          signed: false
      }
    ];

    this.globalVars.dialogs = {
        confirm: [
          {
              id: 86,
              name: "Cancel Confirmation",
              title: "Are you sure you want to discard these changes?",
              leftBtnText: "Cancel",
              leftBtnClass: "btn-default",
              leftBtnFn: "SharedDataSvc.sessionVars.dialogs.cancel();",
              rightBtnText: "Confirm",
              rightBtnClass: "btn-primary",
              rightBtnFn: "SharedDataSvc.navigateTo('/#!/dashboard');"
          },
          {
              id: 75,
              name: "Decline Contract Notification",
              title: "This order cannot be completed if this agreement is declined.",
              leftBtnText: "Cancel",
              leftBtnClass: "btn-default",
              leftBtnFn: "SharedDataSvc.sessionVars.dialogs.cancel();",
              rightBtnText: "Confirm",
              rightBtnClass: "btn-primary",
              rightBtnFn: ""
          }
        ],
        alert: [
          {
              id: 70,
              name: "Manual Review Notification",
              title: "Submitted for Manual Review",
              paragraph: "AcceptanceNow will contact the customer soon.  For support and questions call 1-866-317-2011.",
              btnText: "Ok",
              btnClass:"btn-primary",
              btnFn: "SharedDataSvc.navigateTo('/#!/dashboard');"
          },
          {
              id: 66,
              name: "Zip Not Covered Notification",
              title: "This zip code is outside of the service area.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: ""
          },
          {
              id: 67,
              name: "Declined Notification",
              title: "Application Denied",
              paragraph: "An explanatory letter has been emailed to the customer.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: "SharedDataSvc.navigateTo('/#!/dashboard');"
          },
          {
              id: 68,
              name: "Approved Notification",
              title: "Application Approved",
              paragraph: "Approved for $XXX worth of merchandise.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: "updateDashboardAppStatus();"
          },
          {
              id: 69,
              name: "Approved Notification - Order Exceeds Approval",
              title: "Application Approved",
              paragraph: "Approved for $XXX worth of merchandise.<br><br>Existing order exceeds approval amount.  Please edit the order.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: "SharedDataSvc.navigateTo('/#!/ordersummary');"
          }
        ],
        cancel: function(modal){
          // close modal

        }
    };

    this.globalVars.orderConfirmPut = {
        applicationStatus: "", // "Accepted",
        orderContent: { // gets base64 encoded prior to PUT
            extendedServiceAmount:0,
            confirmed:0,
            otherFee:0,
            installationFee:0,
            deliveryFee:0,
            orderIdentifier: "", // "17F93C1E-220A-4F7C-98B2-7E027AF9C1DF",
            orderItems:[]
        },
        engagementId:null,
        customerHtID:"",
        salesStatus:"Pending",
        paymentStatus:"Pending",
        customerId:0,
        deliveryStatus:"Pending",
        orderStatus:"Pending",
        agreementStatus:"Pending",
        vanStoreId:"",
        orderOriginator: "AWP",
        exitReason: null
    };

    this.globalVars.orderEstimatePut =
    {
       "password":"88138",
       "extendService":"45.00",
       "requestDte":"2015-04-05 18:30:30",
       "confirmed":0,
       "otherAmt":0,
       "employeeId":"168045",
       "username":"88138",
       "store":"09057",
       "quoteItem":[],
       "orderIdentifier":"374DA808-53A3-4FED-BBF0-67B2BCDC43A4",
       "employeeName":"VAN WEB PORTAL",
       "deliveryAmt":"90.00",
       "customer":{
          "title":"",
          "lastName":"Tester",
          "firstName":"Marvin",
          "contactPhone1":"999-9999999",
       },
       "address":{
          "address2":"",
          "state":"NC",
          "address1":"2112 Burke Meadows Road Apt F1106336",
          "zip":"27103",
          "city":"Winston-Salem",
          "county":""
       },
       "nextRenewDte":"2015-03-01 18:30:30",
       "sessionId":"1112081",
       "vndSalesPerson":""
    };

    this.globalVars.newApplicationPut = {
      sessionId: "", // "123456",
      salesResourceId: "", // "000001",
      customerId:1, // 1
      approvalFlowType: "", // "TELECHECK_ONLY",
      bankingInfo: {
          routingNumber: "", // "12345678",
          accountNumber: "", // "010987654321"
      },
      idInfo:{
          idIssuingState: "", // "GA",
          idNumber: "", // "308494386",
          idSSN: "", // "666425600",
          idDOB: "", // "12251975",
          idLastName: "", // "SMITH",
          idFirstName: "", // "JOHN",
          idMiddleName: "", // "K",
          idPhone: "", // "2024324321",
          idAddressNumber: "", // "123",
          idAddressStreetName: "", // "FAKE",
          idAddressStreetType: "", // "ST",
          idAddressApt: "",
          idCity: "", // "PLANO",
          idState: "", // "TX",
          idZip: "" // "75010"
      },
      employmentInfo: {
          employer: "Deloitte", // "Deloitte",
          employerAddress: "",
          employerCity: "",
          employerState: "",
          employerZip: "",
          employerDept: "",
          monthlySalary: ""
      },
      referenceInfo:[
          {
            firstName: "John",
            lastName: "Smith",
            phone: "513-123-5432",
            relationship: "PARENT"
          },
          {
            firstName: "John",
            lastName: "Smith2",
            phone: "513-123-5432",
            relationship: "COUSIN"
          },
          {
            firstName: "John",
            lastName: "Smith3",
            phone: "513-123-5432",
            relationship: "COWORK"
          }
      ]
    };

    this.globalVars.agreementOrder = {
       "password":"88138",
       //"extendService":"45.00",
       "requestDte":"2015-04-05 18:30:30",
       "confirmed":0,
       "otherAmt":0,
       "employeeId":"168045",
       "username":"88138",
       "store": null,
       "quoteItem":[],
       "orderIdentifier": null,
       "employeeName": null,
       //"deliveryAmt": null,
       "customer":{
          "lastName": null,
          "firstName": null,
          "contactPhone1": null,
       },
       "nextRenewDte":"2015-03-01 18:30:30",
       "sessionId":"1112081",
       "vndSalesPerson":"AWP"
    };

    this.globalVars.agreementPrePut = {
        "customer": {
            "id": 3667,
            "engagementId": "3861",
            "languagePreference": "ENGLISH"
        },
        "initialPaymentDate": "20150301",
        "deliveryFee": 2000,
        "emailUnsignedAgreements": true,
        "selectedPromoOptions":null,// VAN-672 - Low Down Initial Payment should be displayed on RA
        "inventoryList":[
          // {
          //   "racBrand": "ACP", // 'ESV' for extended
          //   "itemModelNumber": "", // 'ExtSvc' for extended
          //   "racModelNumber": "PMA600",
          //   "depreciableLifeInMonths": 24,
          //   "itemOptions" : "",
          //   "itemOriginalCost": 13587
          // },
          // {
          //   "racBrand": "ESV",
          //   "itemModelNumber": "ExtSvc",
          //   "racModelNumber": "CNS-SRV-1",
          //   "depreciableLifeInMonths": 24,
          //   "racRateCode": "WAR396",
          //   "itemOriginalCost": 0  // Update this if there is an extended warranty
          // }
        ]
    };

    this.globalVars.dashboardListCache = [];

    // this becomes the object 'customerApp'
    this.globalVars.ApplicationSave = { // APPROVAL/APPLICATION PUT/SAVE
      sessionId: "", // "123456",
      salesResourceId: "", // "000001",
      customerId: 0, // 1
      engagementId:"",
      approvalFlowType: "", // "MANNED",
      bankingInfo:{
          routingNumber: "", // "12345678",
          accountNumber: "" // "010987654321"
      },
      idInfo:{
          idIssuingState: "", // "CO",
          idNumber: "", // "21111111",
          idSSN: "", // "123121234",
          idDOB: "", // "12251975",
          idLastName: "", // "SMITH",
          idFirstName: "",// "JOHN",
          idMiddleName: "", // "K",
          idPhone: "", // "2024324321",
          idAddress1: "", // "123 FAKE ST",
          idAddress2: "", // "",
          idCity: "", // "PLANO",
          idState: "", // "TX",
          idZip:  "" //"75010"
      },
      employmentInfo:{
          employer: "Deloitte", // "Deloitte",
          employerAddress:"",
          employerCity:"",
          employerState:"",
          employerZip:"",
          employerDept:"",
          monthlySalary:""
      },
      referenceInfo:[
          {
            firstName: "", //"John",
            lastName: "", //"Smith",
            phone: "", //"513-123-5432",
            relationship: "" //"PARENT"
          },
          {
            firstName: "", //"John",
            lastName: "", //"Smith2",
            phone: "", //"513-123-5432",
            relationship: "" // "COUSIN"
          },
          {
            firstName: "", //"John",
            lastName: "", //"Smith3",
            phone: "", //"513-123-5432",
            relationship: "" //"COWORK"
          }
      ]
    };

    this.globalVars.engagementPOST = {  // for NEW customers SHORT information ONLY!!!!
        customerId: 0,
        languagePreference: "ENGLISH",
        engagementId: null,
        orderContent: null,
        customerFirstName: "",
        customerLastName: "",
        vanStoreId: null,
        modifiedDateStamp: "", // "2014-05-12 00:00"
        orderStatus: "",
        deliveryStatus: "",
        agreementStatus: "",
        applicationStatus: "",
        estimatedDeliveryDate: null, // 2014-05-24 00:00
        paymentStatus: "", //
        salesStatus: "",
        reconcilationStatus: null,
        customerHtID: "", // HT2110000276
        customerHtStoreId: null,
        orderId: null,
        orderOriginator: "AWP",
        exitReason: null
    };


    // this comes back after you:  GET /rentacentervrto/rest/storeInfo
    this.globalVars.storeSetup = {
        htStoreName: "", // S FREEWAY 1240
        // brand: "HHGREGG",
        htStoreNumber: "", // 003
        vanStoreId: "",// 6841
        latitude: 0, // 40.33
        longitude: 0, // -103.2352
        serverIconPath: "", // /site/png/Ashley.png
        canScanId: false,
        isManned: false,
        taxRate: 6.5,
        processingFee: 2500,
        excludedBrands: [], // ["GE", "LG"],
        excludedCategories: [], // ["Appliances"],
        allowExtendedWarranty: true,
        allowedRentalRadiusMiles: 3000,
        serverPublicRSAKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwiCeRISLA97us3bmyQZVlEzK2TcETSc5r7NWIZ/xaCp0bi7SKPh1p5Xmtjpnt6vF+mOzExl8Hk1Y0mbqplmSvUhetVwbfE2+LSK3sKEFQsc3PwKyiugtaiCVkURZPKhUwNnQmZof6m7bB7BXykqGxEFqHoF+F7D11Q8CHicUDfmPwGCXaBKlTp8HBOJuhfB9a6r8hBC1OHbpsInnQzEODBTYUIetFv/1fv4tqyTD1su+VZa1hQsCNxeECll921dtktzJDcGunYFVupl2Mpjj5BQ/zdOrDVQyGiUmHDTzm89pm7cUegsWxt0F3au7WXIRvRA4oThlZ5b7I4tJB8QIYwIDAQAB"//
    };

    this.globalVars.paymentCalcSetup = {
       "customerEngagementId": null,
       "paymentToDate": "20150301", // initial payment date
       "id": null, // ht store id
       "customerId": null,
       /*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
       /*"clubSwitch":this.clubStatus,
       "waiverSwitch":this.waiverStatus,*/
       /*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
       "hoCustId": null, // customer ht id
       "agreementNumber": null
    };
    
    /* Removed following data of pdfSign from globalVars.paymentCalcSetup object to separate out pdfSigner and calcPayment call 
           "pdfSignatures": {
          "arbitrationSignaturePNG": null,
          "ldwSignaturePNG": null,
          "benefitsPlusPayFrequency": "MONTHLY",
          "benefitsPlusSignature": null,
          "rentalAgreementSignaturePNG": null,
          "benefitsPlusSignaturePNG": null,
          "ldwSignature": null,
          "rentalAgreementSignature": null,
          "arbitrationSignature": null,
          "language": "english"
       }
     */

	this.globalVars.signatureTemplate = {
		"customerId":null,
        "engagementId":null,
        "language": "english",
		"signatures": []
	};

    this.globalVars.navigateToDashboard = false;

    this.cashInitialPayment = {
      "empin": null, // 1515
      "id": null, // 2647
      "hoCustId": null,
      "paymentToDate": null,
      "agreementNumber": null,
      "clubSwitch": "Y",
      "amtOwed": null,
      "amtTendered": null,
      "changeAmt": null,
      "customerEngagementId": null,
      "customerLogin": null,
      "lastFourDigit": null,
      "autoPayAgreementInfo": [{
          "agreementNumber": null,
          "agreementType": null,
          "monthlyAmount": null
      }]
      };

    this.creditInitialPayment = {
       "agreement":{
          "id": null,
          "customer":{
             "lastName": null,
             "firstName": null,
             "login": null,
             "email": null,
             "phoneNumber": null
          },
          "engagement":{
             "id":31385,
             "hoCustName": null
          }
       },
       "payment":{
          "billingCity": null,
          "billingZipCode": null,
          "cvv": null,
          "creditCardNumber": null,
          "billingLastName": null,
          "expirationDate": null,
          "billingState": null,
          "billingAddressLine": null,
          "paymentAmountInCents": null,
          "billingFirstName": null
       },
       "paymentType": null
    };

    this.bankPaymentInitialPayment = {
       "agreement":{
          "id": null,
          "customer":{
             "lastName": null,
             "firstName": null,
             "login": null,
             "email": null,
             "phoneNumber": null
          },
          "engagement":{
             "id":31385,
             "hoCustName": null
          }
       },
       "payment":{
          "billingCity": null,
          "billingZipCode": null,
          "cvv": null,
          "creditCardNumber": null,
          "billingLastName": null,
          "expirationDate": null,
          "billingState": null,
          "billingAddressLine": null,
          "paymentAmountInCents": null,
          "billingFirstName": null
       },
       "bankingInfo":{
           "bankRoutingNumber": null,
           "bankAccountNumber": null,
           "bankAccountType": null
       },
       "paymentType": null
    };

    this.autoPaymentBenefits = {
        "SendFulfillment": 3,
        "ClientMemberId": "06418001334",
        "EmailAddress": "mahashel@gmail.com",
        "FirstName": "Irv",
        "HomePhone": "9702146363",
        "Gender": "M",
        "ProgramId": "10239",
        "Birthdate": "01\/02\/1960",
        "EffectiveDate": "12-08-2014",
        "LastName": "Machesky",
        "ShippingAddress": {
            "PostalCode": "37743",
            "City": "Greeneville",
            "State": "TN",
            "StreetAddress1": "1445 Allens Bridge"
        },
        "ActionCode":"A"
    };

    this.autoPaymentData = {
        "customerLogin": null,
        "customerPassword": null,
        "customerFirstName": null,
        "customerLastName": null,
        "engagementId": null,
        "emailAddress": null,
        "customerAddress": null,
        "customerCity": null,
        "customerState": null,
        "customerZip": null,
        "customerPhone": null,
        "paymentType": null,
        "creditCardInfo": {
            "creditCardNumber": null,
            "expirationMonth": null,
            "expirationYear": null,
            "cvvCode": null
        },
        "bankingInfo": {
            // "bankRoutingNumber": null,
            // "bankAccountNumber": null,
            // "bankAccountType": null // Checking, Savings or Business
        },
        "agreementList": [{
            "agreementNumber": null,
            "agreementType": null,
            "monthlyAmount": null
        }],
        "payRequest":{
        	"promoCode": null,
        	"promoDesc": null,
        	"promoAmount": null,
        	"promoAmtUsed": null,
        	"totalDue": null,
        	"passPhrase": null,
        	"hoCustName": null,
            "billingAddressLine": null,
            "billingCity": null,
            "billingState": null,
            "billingZipCode": null,
            'paymentAmountInCents': null
        }
    };

    this.customerData = {};
    this.agreementData = {};
    this.manualOverrideData = {
        engagementId: null,
        state: undefined
    };
    this.activeModal = undefined;
    this.manualOverrideMessage = "";
    this.invoiceDeliveryData = {
        deliveryDate: null,
        pickupDate: null,
        date: null,
        associateName: null,
        invoiceNumber: null
    };
    this.quoteCalculator = {
        date: null,
        selectedDate: null
    };
    this.deliveryData = {
      deliveryStatus: null,
      salesAssociateID: null,
      invoiceNum: null,
      estimatedDeliveryDate: null,
      applicationStatus: null,
      customerId: null,
      orderStatus: null,
      salesStatus: null,
      paymentStatus: null,
      agreementStatus: null,
      vanStoreId: null,
      customerHtID: null,
      engagementId: null,
      orderContent: null
    };

    this.generatedkeys = [];
    this.cleankeys = [];
    
    this.missingInfoData = { };
    
    this.isBlankOrNull = function(value){
			if(value == null || value == ""){
				return true;
			}else{
				return false;
			}
	};
	var isBlankOrNull = this.isBlankOrNull;
	
	this.convertZerosToBlank = function(customerData){
		void 0;
        
        if(customerData.phones[0].areaCode == "000"){customerData.phones[0].areaCode = "";}
		if(customerData.phones[1].areaCode == "000"){customerData.phones[1].areaCode =  "";}
		if(customerData.personalReferences[0].phone.areaCode == "000"){customerData.personalReferences[0].phone.areaCode =  "";}
		if(customerData.personalReferences[1].phone.areaCode == "000"){customerData.personalReferences[1].phone.areaCode =  "";}
		if(customerData.personalReferences[2].phone.areaCode == "000"){customerData.personalReferences[2].phone.areaCode =  "";}
		
		if(customerData.phones[0].number == "0000000"){customerData.phones[0].number =  ""; }
		if(customerData.phones[1].number == "0000000"){customerData.phones[1].number =  ""; }
		if(customerData.personalReferences[0].phone.number == "0000000"){customerData.personalReferences[0].phone.number =  ""; }
		if(customerData.personalReferences[1].phone.number == "0000000"){customerData.personalReferences[1].phone.number =  ""; }
		if(customerData.personalReferences[2].phone.number == "0000000"){customerData.personalReferences[2].phone.number =  ""; }
		
        if($rootScope.isReferenceRequired && $rootScope.state == 'PR'){
            if(customerData.personalReferences[3] && customerData.personalReferences[3].phone && customerData.personalReferences[3].phone.areaCode == "000"){
                customerData.personalReferences[3].phone.areaCode =  "";
            }
            if(customerData.personalReferences[3] && customerData.personalReferences[3].phone && customerData.personalReferences[3].phone.number == "0000000"){
                customerData.personalReferences[3].phone.number =  ""; 
            }
        }

		return customerData;
	};
	var convertZerosToBlank = this.convertZerosToBlank;
	

	this.checkMissingInfo = function(customerData, callback ){
		void 0;
		var isReferenceRequired = this.globalVars.storeSetup.isReferenceRequired;

		/* We are checking for missing info only if customer is approved 
		if(customerData.VanEngagement.applicationStatus.toLocaleLowerCase() !== "accepted"){
			return callback(true);
		}*/
		
		/* We will get overallApprovalStatus key only when approval engine is run for customer(Not a save draft case).
		 * If approval engine is not run we are not checking missing info for it.
		 * If approval engine is run and overallApprovalStatus is equal to accept then only go for missing info check.
		 */

		//VAN-438 - US stores navigate to missinginfo and PR stores never navigate to missing info
		if ( $rootScope.state == 'PR' && customerData.VanEngagement && customerData.VanEngagement.customer.customerOriginator && customerData.VanEngagement.customer.customerOriginator != "SLI" && customerData.VanEngagement.agreementOriginator != 'HighTouch'){//VAN-2401-PR stores to use SLI- AWP re-engagement
			 return callback(true);
		}
		
		 // For HT approved customer with agreement,by pass missing flow 
        /*if(customerData.agreement !== undefined && customerData.agreement.agreementStatus !== undefined && customerData.agreement.agreementStatus === 'PENDING' && (customerData.VanEngagement.agreementOriginator && customerData.VanEngagement.agreementOriginator.toLocaleLowerCase() === 'hightouch')) {
            return callback(true);
        }*/		

		//Defect 28266 - VAN: References getting wiped off in HT - Starts
		if (!$rootScope.orderLink && ($rootScope.startAppFlow || $rootScope.createOrderFlow) && isReferenceRequired) {
    		if(customerData.VanEngagement && customerData.VanEngagement.customer.customerOriginator && customerData.VanEngagement.applicationStatus && 
    				(customerData.VanEngagement.customer.customerOriginator == "SLI" || customerData.VanEngagement.customer.customerOriginator == "HighTouch") // VAN-813 New originator for HighTouch for HT originated approvals
    				&& customerData.VanEngagement.orderStatus == "NotStarted" && customerData.VanEngagement.applicationStatus == "Accepted")
    		{
    		  $rootScope.VANEngagementId = customerData.VANEngagementId;
    		  return callback(false);
    		} 
		}
		//Defect 28266 - VAN: References getting wiped off in HT - Ends
		
		if(customerData.overallApprovalStatus){	
			if(customerData.overallApprovalStatus != "ACCEPT"){
				void 0;
				return callback(true);	
			}	
		}else{
			void 0;
			return callback(true);
		}
		
		
		/* We are converting zeros to blank for phone no fields below
		 * because HT will return "000" and "0000000" when this info is blank. 
		 * And here we are checking afterwards only blank or null.(Zeros should not be treated as info found)
		 * */
		customerData = convertZerosToBlank(customerData);
		
		// Test code customerData.personalReferences[1].firstName = "";
		void 0;
		if( isBlankOrNull(customerData.firstName) || isBlankOrNull(customerData.lastName)
			|| isBlankOrNull(customerData.address[0].addrLine1) 
			|| isBlankOrNull(customerData.address[0].city)
			|| isBlankOrNull(customerData.address[0].state)
			|| isBlankOrNull(customerData.address[0].zipCode)
			|| isBlankOrNull(customerData.address[0].state)
			|| isBlankOrNull(customerData.address[0].state)
			|| isBlankOrNull(customerData.social)
			|| isBlankOrNull(customerData.driversLicense.birthDate)
			|| isBlankOrNull(customerData.driversLicense.id)
			|| isBlankOrNull(customerData.phones[0].areaCode)
			|| isBlankOrNull(customerData.phones[0].number)
			|| isBlankOrNull(customerData.phones[0].phoneType)
			|| isBlankOrNull(customerData.email)
			
			|| isBlankOrNull(customerData.incomeSources[0].incomeType)
            || ((customerData.driversLicense.idType == '1' || customerData.driversLicense.idType == '2') && isBlankOrNull(customerData.driversLicense.issuer))
            || ((customerData.incomeSources[0].incomeType == 'EMPLOYED') && ((isBlankOrNull(customerData.incomeSources[0].sourceName) || customerData.incomeSources[0].sourceName == '0') || (isBlankOrNull(customerData.incomeSources[0].sourcePhone.number) || customerData.incomeSources[0].sourcePhone.number == '0000000') || isBlankOrNull(customerData.incomeSources[0].monthlyIncome) || isBlankOrNull(customerData.incomeSources[0].sourceStartDate)))
            || ((customerData.incomeSources[0].incomeType == 'SELFEMPLOYED') && ((isBlankOrNull(customerData.incomeSources[0].sourceName) || customerData.incomeSources[0].sourceName == '0') || isBlankOrNull(customerData.incomeSources[0].monthlyIncome)))
		){
			callback(false);	
		} else if(isReferenceRequired !== false && (this.globalVars.storeSetup.state == 'PR') 
            && (isBlankOrNull(customerData.personalReferences[0].firstName)
            || isBlankOrNull(customerData.personalReferences[0].lastName)
            || isBlankOrNull(customerData.personalReferences[0].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[0].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[0].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[1].firstName)
            || isBlankOrNull(customerData.personalReferences[1].lastName)
            || isBlankOrNull(customerData.personalReferences[1].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[1].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[1].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[2].firstName)
            || isBlankOrNull(customerData.personalReferences[2].lastName)
            || isBlankOrNull(customerData.personalReferences[2].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[2].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[2].phone.number)

            || isBlankOrNull(customerData.personalReferences[3].firstName)
            || isBlankOrNull(customerData.personalReferences[3].lastName)
            || isBlankOrNull(customerData.personalReferences[3].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[3].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[3].phone.number))) {

            callback(false);
        } else if((isReferenceRequired !== false) && (isBlankOrNull(customerData.personalReferences[0].firstName)
            || isBlankOrNull(customerData.personalReferences[0].lastName)
            || isBlankOrNull(customerData.personalReferences[0].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[0].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[0].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[1].firstName)
            || isBlankOrNull(customerData.personalReferences[1].lastName)
            || isBlankOrNull(customerData.personalReferences[1].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[1].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[1].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[2].firstName)
            || isBlankOrNull(customerData.personalReferences[2].lastName)
            || isBlankOrNull(customerData.personalReferences[2].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[2].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[2].phone.number))
        ){
            callback(false);

        } else {
			
			/* Income Source is self employed or employed */
			if(customerData.incomeSources[0].incomeType == "SELFEMPLOYED" || customerData.incomeSources[0].incomeType == "EMPLOYED" ){
				
				/* Income Source is self employed just check for sourceName is present. 
				 * Later we may need to check monthly Income as well*/
				if(customerData.incomeSources[0].incomeType == "SELFEMPLOYED"){
			 		if(isBlankOrNull(customerData.incomeSources[0].sourceName) ){
			 			callback(false);
			 		}else{
			 			callback(true);
			 		}			 		
			 		
			 	}else if(customerData.incomeSources[0].incomeType == "EMPLOYED"){
			 		
			 		/* Income Source is emplyed just check for sourceName, phone number, length of employment is present. 
			 		 *  Later we may need to check monthly Income as well*/
			 		if( isBlankOrNull(customerData.incomeSources[0].sourceName)
			 			|| isBlankOrNull(customerData.incomeSources[0].sourcePhone.number) 
			 			|| isBlankOrNull(customerData.incomeSources[0].sourcePhone.areaCode) 
			 			|| isBlankOrNull(customerData.incomeSources[0].sourceStartDate) ){
			 				
			 			callback(false);
			 			
			 		}else{
			 			callback(true);
			 		}	
			 	}
			}else{
			 		
			 		/* For all other income Source/Type don't check anything. 
			 		 * Later we may need to check monthly Income by adding a condition here */
			 		callback(true);
			}	
			
		}
		
	};

    // reporting Raygun for the error
    this.reportRaygun = function(response) {
    	//Feature 7872 Integration of Logz code changes starts
    	if(response && this.logzApiKey && this.isLogzEnabled){
    		var logger = new LogzioLogger(this.logzApiKey);
    		if(this.racCorrelatioId){
    			logger.log(this.racCorrelatioId + " - " + response);
            	return true;
    		} else {
    			return false;
    		}
    	} else {
    		return false;
    	}
    	//Feature 7872 Integration of Logz code changes ends
        /*if (typeof(Raygun) === "object") {
            var raygunKey = this.raygunKey;
            var versionNo = this.versionNo;
            var storeId = this.vanStoreId;
            if (response && raygunKey) {
                Raygun.init(raygunKey).attach().setUser(storeId).setVersion(versionNo);
                var formatResponse = JSON.stringify(response);
                try {
                  throw new Error(formatResponse);
                }
                catch(e) {
                  var customMsg = "Service Error Details: "+e.message;
                  e.message = customMsg;
                  e.name = "Service Error";
                  Raygun.send(e);
                }
            }else {
                return false;
            }
        }
        else{
            return false;
        }*/
    };

    this.createPdfBlob = function(base64Data) {
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64Data.replace(/\s/g, ''));
        // get binary length
        var len = binary.length;
        // create ArrayBuffer with binary length
        var buffer = new ArrayBuffer(len);
        // create 8-bit Array
        var view = new Uint8Array(buffer);
        // save unicode of binary data into 8-bit Array
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        // create the blob object with content-type "application/pdf"               
        var blob = new Blob( [view], { type: "application/pdf" });
        return blob;
    };

    this.clearPrintSection = function() {
        var printConatiner = document.getElementById('printSection');
        $("body").removeClass("printView");
        if(printConatiner !== null) {
            printConatiner.innerHTML = '';
        }
    }

    this.formatItemPrice = function(price) {
        var formattedPrice;
        if (String(price).indexOf('.') > -1) {
            price = String(price).split('.');
            if (price[1].length === 2) {
                formattedPrice = Number(price[0] + price[1]);
            } else {
                formattedPrice = Number(price[0] + price[1] + '0');
            }
        } else {
            formattedPrice = Number(price + '00');
        }
        return formattedPrice;
    }

    this.setStoreId = function(storeId) {
        var headers = [
            'commonHeaders',
            'commonHeadersJSON',
            'commonHeadersENCRYPT',
            'agreementHeadersENCRYPT'
        ];
        for (var i = 0; i < headers.length; i++) {
            this[headers[i]]['RAC-KIOSK-LOCATION-ID'] = storeId;
        }
        this.vanStoreId = storeId;
    };

    // AES/RSA FUNCTIONS
    var randomString = function(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    };

    this.encrypt = function(plaintext, rsa_key) {
        // console.log('840plaintext=', plaintext);
        var string_key = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        var string_iv = randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        var key = CryptoJS.enc.Utf8.parse(string_key);
        var iv = CryptoJS.enc.Utf8.parse(string_iv);
        var encrypted = CryptoJS.AES.encrypt(plaintext, key, {iv: iv});

        //RSA encryption
        var rsa_encryption = new JSEncrypt();
/* n */ rsa_encryption.setPublicKey(rsa_key);
        var encrypted_session_key = window.atob(rsa_encryption.encrypt(encrypted.key.toString(CryptoJS.enc.Latin1)));

        //pack all that stuff
        var encoding;
        var array_size = 4 + encrypted_session_key.length + 4 + encrypted.iv.sigBytes + encrypted.ciphertext.sigBytes;
        var ab = new ArrayBuffer(array_size);
        var dv = new jDataView(ab);
        dv.writeUint32(encrypted_session_key.length, false);
        dv.writeString(encrypted_session_key, encoding = 'binary');
        dv.writeUint32(encrypted.iv.sigBytes, false);
        dv.writeString(encrypted.iv.toString(CryptoJS.enc.Latin1), encoding = 'binary');
        dv.writeString(encrypted.ciphertext.toString(CryptoJS.enc.Latin1), encoding = 'binary');
        var byte_stream = dv.getString(array_size, 0, encoding = 'binary');
        //console.log('cryptoENcrypt '+ window.btoa(byte_stream));
        return window.btoa(byte_stream);
    };

    this.decrypt = function(encoded_byte_stream, private_key) {
        var buffer = window.atob(encoded_byte_stream);
        var dv = new jDataView(buffer);
        var key_size = dv.getUint32(0, false);
        var encrypted_session_key = dv.getString(key_size, 4);
        var iv_size  = dv.getUint32(key_size + 4, false);
        var iv = CryptoJS.enc.Utf8.parse(dv.getString(iv_size, key_size + 8));
        var ciphertext = dv.getString(buffer.length - key_size - iv_size - 8, key_size + iv_size + 8);

        //unwrapp the key
        var rsa_encryption = new JSEncrypt();
        rsa_encryption.setPrivateKey(private_key);
        var session_key = rsa_encryption.decrypt(window.btoa(encrypted_session_key));
        var parsed_session_key = CryptoJS.enc.Latin1.parse(session_key);
        //decrypt using AES key;
        void 0;
        return CryptoJS.AES.decrypt({ciphertext: CryptoJS.enc.Latin1.parse(ciphertext)}, parsed_session_key, {iv: iv}).toString(CryptoJS.enc.Utf8);
    };

    this.generatekeys = function() {
        var crypt = new JSEncrypt({default_key_size: 2048});
        crypt.getKey();
        var private_key = crypt.getPrivateKey().replace(/(-----(\w+) RSA PRIVATE KEY-----|\r\n|\n|\r)/gm,'');
        var public_key = crypt.getPublicKey().replace(/(-----(\w+) PUBLIC KEY-----|\r\n|\n|\r)/gm,'');
        return [private_key, public_key];
        //console.log('keys generated...'+ SharedDataSvc.generatedkeys);
    };

    this.base64 = {};
    this.base64.encode = function(str){
        return window.btoa(encodeURIComponent(str));
        // window.btoa(unescape(encodeURIComponent( str )));
    };
    this.base64.encodeAlt = function(str){
        return window.btoa(unescape(encodeURIComponent(str)));
        // window.btoa(unescape(encodeURIComponent( str )));
    };
    this.base64.decode = function(str){
        return decodeURIComponent(window.atob(str));
    };
    this.detectCardType = function(cardNum) {
        if(this.regex){
            var cardTypes = {
                visa : new RegExp(this.regex.visa),
                mastercard : new RegExp(this.regex.masterCard),
                amex : new RegExp(this.regex.amex),
                diners : new RegExp(this.regex.diner),
                discover : new RegExp(this.regex.discover)
            };
        }        

		for ( var key in cardTypes) {
            if(key == "mastercard" && (cardTypes[key].test(cardNum.substr(0,4)))) {
                return key;
            } 
            if (cardTypes[key].test(cardNum)) {
				return key;
			}
		}
		return false;
    }
}]);

// application (entering)
myApp.factory('getSpecificApplication', ["$http", "$q", "SharedDataSvc", "$cookies", "$rootScope", "API_DOMAIN", "PROXY_ACTION_URL", "authentication", "ServiceAgent", function ($http, $q, SharedDataSvc, $cookies, $rootScope, API_DOMAIN, PROXY_ACTION_URL, authentication, ServiceAgent) {
	return {
		searchHT : function(mData){
			
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/create';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			
			var entryPointFromCookie = $cookies.getObject('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				mData.entryPointName = entryPointFromCookie;
				void 0;
			}
			
			void 0;
			
			// NEW CUSTOMERS
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				mData,
				true
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });
			return deferred.promise;
		}
	};
}]);

(function() {
	'use strict';

	myApp.factory('getStoreInfoFactory', getStoreInfoFactory);

	/* @ngInject */
	function getStoreInfoFactory($http, $q, $cookies, $location, $rootScope, SharedDataSvc, localStorageService, API_DOMAIN, authentication, PROXY_ACTION_URL, ServiceAgent) {
		var activeStoreId;
		var service = {
			getData : getData,
			getStoreState : getStoreState,
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 */
			getPRZipcodeData : getPRZipcodeData,
			getLatLngFromZip : getLatLngFromZip,
			getCustomerDistance : getCustomerDistance,
			isCustomerInRadius : isCustomerInRadius,
			isCustomerInUS : isCustomerInUS
		};
		return service;

		////////////////
		
		/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Starts*/
		 function getPRZipcodeData() {
		      void 0;
		      var deferred = $q.defer();
		      var headers = {
		        'endTarget': 'site/json/prZipCodes.json',
		        'sessionToken' : authentication.getCurrentSessionId(),
		        'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
		      };
		      ServiceAgent.httpCall(
		        PROXY_ACTION_URL,
		        'POST',
		        headers,
		        null,
		        false
		        ).then(function (response) {
		          void 0;
		          deferred.resolve(response);
		        },
		        function (response) {
		          void 0;
		            deferred.reject(response);
		        });

		        return deferred.promise;
		    }
		 /*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Ends*/ 
		 
		function getData(param) {
			var deferred = $q.defer();
			var pp = '';
			if ($cookies.getObject('storeId')) {
				activeStoreId = $cookies.getObject('storeId');
			} else {
				activeStoreId = SharedDataSvc.vanStoreId;
			}
			if (param) {
				var pp = param;
			}

			if (localStorageService.get('storeInfo') && localStorageService.get('storeInfo').vanStoreId === activeStoreId) {
				void 0;
				//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
				if($rootScope.xpartnerLogin == undefined){
					$rootScope.xpartnerLogin = localStorageService.get('xparterLogin');}
				if($rootScope.xpartnerLogin){
					localStorageService.set('xparterLogin',true);
				}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
				SharedDataSvc.raygunKey = localStorageService.get('storeInfo').raygunApiKey;
				SharedDataSvc.quoteService = localStorageService.get('storeInfo').quoteService;
				SharedDataSvc.operationMode = localStorageService.get('storeInfo').operationMode;//Added Feature 7855 code changes
				//Feature 7872 Integration of Logz code changes starts
				SharedDataSvc.logzApiKey = localStorageService.get('storeInfo').logzApiToken;
				SharedDataSvc.isLogzEnabled = localStorageService.get('storeInfo').logzEnableFlag;
				//Feature 7872 Integration of Logz code changes ends
				SharedDataSvc.invoiceReductionPayment = localStorageService.get('storeInfo').invoiceReductionPayment;
				SharedDataSvc.globalVars.storeSetup = localStorageService.get('storeInfo');
				deferred.resolve(localStorageService.get('storeInfo'));
			} else {
				
				if($cookies.getObject('OPERATION-MODE')){
					var opMode = $cookies.getObject('OPERATION-MODE');
				}else{
					var opMode = null;
				}

				var headers = {
					'RAC-KIOSK-LOCATION-ID' : activeStoreId,
					'endTarget' : 'rentacentervrto/rest/storeInfo',
					'sessionToken' : authentication.getCurrentSessionId(),
					'PASSPHRASE' : pp,
					'orderOriginator' : 'AWP',
					'OPERATION-MODE': opMode,
					'Encrypt-Mode' : false,
					'Content-Type' : 'text/plain',
					'Access-Control-Allow-Origin' : 'PASSPHRASE, endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID, orderOriginator'
				};
				void 0;
				$http({
					method : 'POST',
					url : PROXY_ACTION_URL + '?vanStoreId=' + activeStoreId,
					headers : headers,
					// send empty data to prevent CORS error
					data: {}
				}).then(function(response) {
                                        response = response.data;
					void 0;
					//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
					if($rootScope.xpartnerLogin){
						response.isManned = false;
						response.operationMode = 'UNMANNED';
						response.achVerification = true;
						response.allowAch = false;
						response.isReferenceRequired = false;
					}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
					ServiceAgent.setServerPublicKey(response.serverPublicRSAKey);
					ServiceAgent.setRaygunKey(response.raygunApiKey);
					//Feature 7872 Integration of Logz code changes starts
					ServiceAgent.setLogzApiToken(response.logzApiToken);
					ServiceAgent.setlogzEnableFlag(response.logzEnableFlag);
					ServiceAgent.setInvRedFlag(response.invoiceReductionPayment);
					//Feature 7872 Integration of Logz code changes ends
					localStorageService.set('storeInfo', response);
					SharedDataSvc.globalVars.storeSetup = response;
					deferred.resolve(response);
				}).catch(function(response) {
					void 0;
					deferred.reject(response.data);
				});
			}

			return deferred.promise;
		}

		function getStoreState(storeInfo) {
			if (storeInfo) {
				void 0;
				return storeInfo.state || '';
			}
		}

		function getLatLngFromZip(zip) {
			//console.log("Application environment -------------- ", window.location.hostname);
			/*var host = $location.absUrl();
			var googleAPIUrl ="https://maps.googleapis.com/maps/api/geocode/json?address=" + zip;
			//29561 code changes starts
			var googleApiKeyProd = SharedDataSvc.globalVars.storeSetup.googleApiProdKey;
			var googleApiKeyLle = SharedDataSvc.globalVars.storeSetup.googleApiNonProdKey;
			//29561 code changes starts
			if(host.substr(8, 3).toUpperCase() !== 'AWP'){
				googleAPIUrl = googleAPIUrl + '&key=' + googleApiKeyLle;
			}else{
				googleAPIUrl = googleAPIUrl + '&key=' + googleApiKeyProd;
			}
			return $http({
				url : googleAPIUrl,
				method : 'GET'
			}).then(function(response) {
				return response.data;
			}, function(response) {
				console.log('Error getting lat/lng from customer zip', response);
			});*/
			var deferred = $q.defer();
			var geocoder = new google.maps.Geocoder;
			/*VAN-914 - PRB0043073 - AWP-Invalid zipcode issue*/
			geocoder.geocode( {address:zip,componentRestrictions:{country:'US'}}, function(results, status) {
				results.status = status;
				deferred.resolve(results);
			});
			return deferred.promise;
		}

		function getCustomerDistance(store, customer) {
			void 0;
			void 0;
			var point1, point2, distance; //IE Fix
			point1 = new google.maps.LatLng(store.lat, store.lng);
		    point2 = new google.maps.LatLng(customer.lat, customer.lng);
		    distance = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);

			// convert to miles
			distance = distance * 0.000621371;

			return distance;
		}

		function isCustomerInRadius(storeRadius, miles) {
			if (miles <= storeRadius) {
				return true;
			} else {
				return false;
			}
		}

		function isCustomerInUS(location) {
			var obj = _.where(location, {
				'types' : ["country", "political"]
			}) || null;

			if (obj[0] && obj[0].short_name === 'US') {
				return true;
			} else {
				return false;
			}
		}

	}
	getStoreInfoFactory.$inject = ["$http", "$q", "$cookies", "$location", "$rootScope", "SharedDataSvc", "localStorageService", "API_DOMAIN", "authentication", "PROXY_ACTION_URL", "ServiceAgent"];

})(); 

(function() {
  'use strict';

  angular
    .module('myApp')
    .factory('manualOverrideFactory', manualOverrideFactory);


  function manualOverrideFactory ($log, $http, $q, SharedDataSvc, API_DOMAIN, productCategoriesFactory, PROXY_ACTION_URL, ServiceAgent, authentication) {
    var approvalModel = {
      requestedAmount: null,
      employeeName: null,
      rightSelling: false,
      verifyIncome: false,
      verifyEmployment: false,
      leaseOrder: false,
      decline: false,
      accepted: true,
      difference: null,
      outOfRange: false,
      tierIndex: undefined,
      employeePass: undefined,
      declineReason: null,
      otherReason: null
    };

    var associateReview = {
      checkedDl: false,
      confirmDl: false,
      calledLandlord: false,
      confirmUtility: false,
      other: false,
      verifyExplain: "",
      verifyIncome: false,
      employeeName: null,
      employeePass: undefined,
      employeePassSubmitted: false,
      verifyEmployment: null,
      verifyReference: null,
      verifyAddress: null,
      notVerified: false,
      summary: null,
      customMessage: undefined,
      address: {
        noReason: false,
        nothingSelected: false
      },
      income: {
        notCompleted: false
      }
    };

    var errorModel = {
      message: undefined,
      error: false,
      alertClass: ''
    };

    return {
      approvalModel: approvalModel,
      errorModel: errorModel,
      associateReview: associateReview,
      isApplicationApproved: isApplicationApproved,
      isStoreManned: isStoreManned,
      getManualOverrideData: getManualOverrideData,
      getProductCategories: getProductCategories,
      submitOverride: submitOverride,
      getOverrideTier: getOverrideTier
    };

    function isApplicationApproved(status) {

    }

    function isStoreManned(store) {

    }

    function getManualOverrideData() {
      return SharedDataSvc.manualOverrideData;
    }

    function getProductCategories() {
      return productCategoriesFactory.getData()
        .then(function(response) {
          $log.log('[manualOverrideFactory.getProductCategories] ProductCat json', response);
          return response;
        }, function(response) {
          $log.log('[manualOverrideFactory.getProductCategories] Unable to get productCategories');
          return response.data;
        });
    }

    function getOverrideTier(optionTiers, requestedAmount, originalAmount) {
      var selectedTier,
          tierIndex,
          max,
          i = 0,
          len = optionTiers.length,
          difference = requestedAmount - originalAmount;

      void 0;
      void 0;

      if(requestedAmount > Number(optionTiers[0].approvalMaximum) && requestedAmount < Number(optionTiers[1].approvalMaximum)) {
        selectedTier = optionTiers[1];
      } else if(requestedAmount < Number(optionTiers[0].approvalMaximum)) {
        selectedTier = optionTiers[0];
      } else {
        selectedTier = optionTiers[2];
      }

      while(i < len) {
        tierIndex = i;
        max = typeof optionTiers[i].approvalMaximum === 'number' ? optionTiers[i].approvalMaximum : Number(optionTiers[i].approvalMaximum);
        // if(difference > optionTiers[i].increaseMaximum_2) {
        //   tierIndex = 2;
        //   break;
        // }
        // if(requestedAmount <= max) {
        //   // selectedTier = optionTiers[i];
        //   break;
        // } 
        if(difference > Number(selectedTier.increaseMaximum_1) && difference <= Number(selectedTier.increaseMaximum_2)) {
          tierIndex = 2;
          break;
        } else if(difference < Number(selectedTier.increaseMaximum_1)) {
          tierIndex = 0;
          break;
        } else {
          tierIndex = 3;
          break;
        }

        i++;
      }

      // _.forEach(optionTiers, function(tier, i){
      //   tierIndex = i;
      //   max = typeof tier.approvalMaximum === 'number' ? tier.approvalMaximum : Number(tier.approvalMaximum);
      //   if(requestedAmount <= max) {
      //     selectedTier = tier;
      //     return false;
      //   }
      // });
      void 0;
      return { 'tier': selectedTier, 'tierIndex': tierIndex };
    }

    function submitOverride (overrideData) {
      void 0;
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'rentacentervrto/rest/kioskOverride',
        'sessionToken' : authentication.getCurrentSessionId()
      };
      ServiceAgent.httpCall(
        PROXY_ACTION_URL, 
        'POST', 
        headers, 
        overrideData, 
        false
        ).then(function (response) {
          void 0;
          deferred.resolve(response);
        },
        function (response) {
          void 0;
            deferred.reject(response);
        });

        return deferred.promise;
    }

  }
  manualOverrideFactory.$inject = ["$log", "$http", "$q", "SharedDataSvc", "API_DOMAIN", "productCategoriesFactory", "PROXY_ACTION_URL", "ServiceAgent", "authentication"];
})();
(function() {
  angular
    .module('myApp')
    .factory('productFactory', product);


  /* @ngInject */
  function product(
    $q,
    $log,
    $http,
    SharedDataSvc,
    localStorageService,
    productCategoriesFactory,
    API_DOMAIN
  ) {

    return {
      getProductModelInfo: getProductModelInfo,
      getProductUnitType: getProductUnitType,
      getProductInfo: getProductInfo,
      getProductCategories: getProductCategories,
      getRacModelNumber: getRacModelNumber,
      buildItemOptions: buildItemOptions,
      sortBuckets: sortBuckets
    };

    function getProductCategories() {
      return productCategoriesFactory.getData()
        .then(function(response) {
          return response;
        });
    }

    function getProductModelInfo(productCategories, item, selectedLanguage) {//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
      var matching,
          filtered,
          subType,
          priceBuckets,
          bedItem;
          
    //   VAN-3239-PRB0043451 - AWP-Brand/Model not found on model master - Start
    if(selectedLanguage == 'en'){
      matching = _.where(productCategories, { 'labelEn': item.category });}
 
      else{
          matching = _.where(productCategories, { 'labelEs': item.category });
      }
    //   matching = _.where(productCategories, { 'labelEn': item.category });
    // VAN-3239-PRB0043451 - AWP-Brand/Model not found on model master - End

     
      // Need to find correct product for beds, this is a special case
      if(item.iconURL === 'Bed') {
        filtered = [];
        bedItem = _.findWhere(productCategories, { 'labelEn' : item.category }); //PRB0041531 – Agreement stuck in processing
        filtered.push(bedItem);
       
        // $log.log('matching beds', filtered);
        //VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Starts
      } else if(item.category === 'TV Accessories') {
    	  matching = _.where(productCategories, { 'iconURL': item.iconURL });
    	  filtered = _.filter(matching, function(val) {
    		  return _.has(val, 'htSubTypeCode');
    	  });	 
      }else {//VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Ends 
        // Filter only the matches that have a subtype code
    	
    	filtered = _.filter(matching, function(val) {
    		
    		return _.has(val, 'htSubTypeCode');
        });	
        
      }
     
      if(!_.isEmpty(matching) && item.iconURL !== 'Bed') {
        priceBuckets = _.last(matching).priceBuckets;
      } else {

        if(filtered[0]){
            priceBuckets = filtered[0].priceBuckets;
        }
        
      }
      
      // Item with subtype wasn't found, now search by custom attributes
      
      void 0;
//      alert(JSON.stringify(filtered.length+'--'+ item.attributes.length));
      if (filtered.length < 1 && item.attributes.length > 0) {
    	  
    	 
        var attributeObject;
        for (var i = 0; i < item.attributes.length; i++) {
          // find the attribute object to check if it has a subtype
        	//VAN-693 - PRB0042959 - PR stores Spanish order issues - Starts
//      	  alert(JSON.stringify(subType));
        	if(selectedLanguage == 'en'){
        		attributeObject = findDeep(_.last(matching).customAttributeGroups, {labelEn: item.attributes[i].value});}
        	else{
        		attributeObject = findDeep(_.last(matching).customAttributeGroups, {labelEs: item.attributes[i].value});
        	}
        	//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage - Ends
        	if (typeof attributeObject !== 'undefined' && attributeObject.htSubTypeCode) {
            subType = attributeObject.htSubTypeCode;
            if (priceBuckets.length < 1) priceBuckets = attributeObject.priceBuckets;
            break;
          }
        }
      } else {
    	 
    	
     	  subType = _.pluck(filtered, 'htSubTypeCode');
    	  
    	  subType = typeof subType[0] !== 'undefined' ? subType[0] : '';

     
      }

      void 0;
      void 0;

      return {
        subType: subType,
        priceBuckets: priceBuckets
      };
    }

    function getProductUnitType(productCategories, product) {
      var parentLabel = _.has(product, 'parent') ? product.parent : false,
          parent = _.findWhere(productCategories, { 'labelEn': parentLabel });

      if(product.htUnitTypeCode) {
        return product.htUnitTypeCode;
      }

      if(parent.htUnitTypeCode) {
        return parent.htUnitTypeCode;
      }

      // Call again if no htUnitTypeCode has been found
      if(!product.htUnitTypeCode || !parent.htUnitTypeCode) {
        return getProductUnitType(productCategories, parent);
      }
    }

    function getProductInfo(orderProduct) {
      // console.log('ORDER PRODUCT', orderProduct);
      var deferred = $q.defer();
      getProductCategories()
        .then(function(response){
          var product = null,
              returnObj = { 'subType' : null, 'unitType' : null };

          if(orderProduct.iconURL) {
            product = _.findWhere(response.productCategories, { 'iconURL': orderProduct.iconURL });
          } else {
            product = _.findWhere(response.productCategories, { 'iconURL': orderProduct.category });
          }

          product.subType = product.htSubTypeCode;
          var unitType = getProductUnitType(response.productCategories, product);
          returnObj.subType = product.subType;
          returnObj.unitType = unitType;
          deferred.resolve(returnObj);

          // $log.log('PRODUCT SUBTYPE', product.subType);
          // $log.log('PRODUCT', product);
          // $log.log('HT UNIT TYPE', unitType);
        });
      return deferred.promise;
    }

    function buildItemOptions(item) {
      var props = ['material', 'color', 'quantity', 'other'],
          options = [];

      _.forEach(props, function(prop, i) {
        if(_.has(item, prop)) {
          options.push(item[prop]);
        }
      });

      return options.join(' ');
    }

    function sortBuckets(buckets) {
      var buckets;

      buckets = _.sortBy(_.pluck(buckets, 'value'), function(num) {
                  return num * -1;
                });
      return buckets;
    }

    function getRacModelNumber(productCategories, item, selectedLanguage) {//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
      var product = getProductModelInfo(productCategories, item, selectedLanguage),//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
          bucket = product.priceBuckets,
          productSubType = product.subType,
          buckets = sortBuckets(bucket),
          selectedBucket,
          i = 0,
          len = buckets.length;

          void 0;
      // console.log('BUCKET', bucket);
      // console.log('BUCKETs', buckets);
      // console.log('item', item);
      // console.log('product', product);
      // console.log('subType', product.prod.htSubTypeCode);
_
      while(i < len) {
        if(buckets[i] < item.itemPrice) {
          selectedBucket = buckets[i - 1];
          break;
        }
        i++;
      }
   if(!selectedBucket) {
        selectedBucket = _.last(buckets);
      }
      // console.log('SELECT BUCKET', selectedBucket);
      // console.log('MODEL NUMBER', product.prod.htSubTypeCode + selectedBucket);
      void 0;
      return String(productSubType) + String(selectedBucket);
    }
  }
  product.$inject = ["$q", "$log", "$http", "SharedDataSvc", "localStorageService", "productCategoriesFactory", "API_DOMAIN"];

  function findDeep(items, attrs) {
    function match(value) {
      for (var key in attrs) {
        if(!_.isUndefined(value)) {
          if (attrs[key] !== value[key]) {
            return false;
          }
        }
      }
      return true;
    }
    function traverse(value) {
      var result;
      _.forEach(value, function (val) {
        if (match(val)) {
          result = val;
          return false;
        }

    if (_.isObject(val) || _.isArray(val)) {
          result = traverse(val);
        }

        if (result) {
          return false;
        }
      });
      return result;
    }
    return traverse(items);
  }
})();
(function() {
  'use strict';

  angular
    .module('myApp')
    .factory('stateRestrictFactory', stateRestrictions);

  function stateRestrictions($http, $log, $q, API_DOMAIN, PROXY_ACTION_URL, ServiceAgent, authentication, SharedDataSvc) {
    var stateRestrictions,
        restrictions = {
          skipLdw: null,
          skipBp: null,
          bpNotContingentOnLdw: null,
          skipEpo: null,
          hideDeliveryFee: null,
          hideExtendedService: null,
          dontSignDeclineBp: null,
          isUnsupportedState: null, 
          state: null
        }

    return {
      stateRestrictions: stateRestrictions,
      storeRestrictions: restrictions,
      getStateRestrictions: getStateRestrictions,
      getAllStoreRestrictions: getAllStoreRestrictions
    };

    function skipLdw(state) {
      if(stateRestrictions && state) {
        var skipLdw = stateRestrictions.skipLDW.split(",") || '';
        return _.indexOf(skipLdw, state) > -1 ? true : false;
      }
    }

    function skipBp(state) {
      if(stateRestrictions && state) {
        var skipBp = stateRestrictions.skipBP.split(",") || '';
        return _.indexOf(skipBp, state) > -1 ? true : false;
      }
    }

    function bpNotContingentOnLdw(state) {
      if(stateRestrictions && state) {
        var bp = stateRestrictions.bpNotContingentOnLDW.split(",") || '';
        return _.indexOf(bp, state) > -1 ? true : false;
      }
    }

    function skipEpo(state) {
      if(stateRestrictions && state) {
        var skipEpo = stateRestrictions.skipEPO.split(",") || '';
        return _.indexOf(skipEpo, state) > -1 ? true : false;
      }
    }

    function hideDeliveryFee(state) {
      if(stateRestrictions && state) {
        var hdf = stateRestrictions.hideDeliveryFee.split(",") || '';
        return _.indexOf(hdf, state) > -1 ? true : false;
      }
    }

    function hideExtendedService(state) {
      if(stateRestrictions && state) {
        var hes = stateRestrictions.hideExtendedService.split(",") || '';
        return _.indexOf(hes, state) > -1 ? true : false;
      }
    }

    function dontSignDeclineBp(state) {
      if(stateRestrictions && state) {
        var dsbp = stateRestrictions.dontSignDeclineBenefitsPlus.split(",") || '';
        return _.indexOf(dsbp, state) > -1 ? true : false;
      }
    }

    function isUnsupportedState(state) {
      if(stateRestrictions && state) {
        var us = stateRestrictions.unsupportedStates.split(",") || '';
        return _.indexOf(us, state) > -1 ? true : false;
      }
    }

    function setAllRestrictions(state) {
      restrictions.skipLdw = skipLdw(state);
      restrictions.skipBp = skipBp(state);
      restrictions.bpNotContingentOnLdw = bpNotContingentOnLdw(state);
      restrictions.skipEpo = skipEpo(state);
      restrictions.hideDeliveryFee = hideDeliveryFee(state);
      restrictions.hideExtendedService = hideExtendedService(state);
      restrictions.dontSignDeclineBp = dontSignDeclineBp(state);
      restrictions.isUnsupportedState = isUnsupportedState(state);
      restrictions.state = state;
    }

    function getAllStoreRestrictions(state) {
      var deferred = $q.defer();

      if(!stateRestrictions) {
        getStateRestrictions()
          .then(function(response) {
            stateRestrictions = response.stateRestrictions[0];
            void 0;
            setAllRestrictions(state);
            deferred.resolve(restrictions);
          });
      } else {
        setAllRestrictions(state);
        deferred.resolve(restrictions);
      }

      return deferred.promise;
    }

    function getStateRestrictions() {
      void 0;
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'site/json/stateRestrictions.json',
        'sessionToken' : authentication.getCurrentSessionId(),
        'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
      };
      ServiceAgent.httpCall(
        PROXY_ACTION_URL,
        'POST',
        headers,
        null,
        false
        ).then(function (response) {
          void 0;
          deferred.resolve(response);
        },
        function (response) {
          void 0;
            deferred.reject(response);
        });

        return deferred.promise;
    }

  }
  stateRestrictions.$inject = ["$http", "$log", "$q", "API_DOMAIN", "PROXY_ACTION_URL", "ServiceAgent", "authentication", "SharedDataSvc"];

})();
myApp.factory('ServiceAgent', ["$http", "$q", "$cookies", "$location", "SharedDataSvc", "authentication", "$timeout", "modalService", "$rootScope", function ($http, $q, $cookies, $location, SharedDataSvc, authentication, $timeout, modalService,$rootScope) { //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE

  //--------------------------------------------------
  // PRIVATE PROPERTIES
  //--------------------------------------------------

  /**
   * Authorization header
   */
  var authHeader = 'Basic dGVzdDp0ZXN0';

  /**
   * This is the keys object. It holds the keys and can retrieve PEMs
   */
  var forgeKeys = null;

  /**
   * Server public key. Use for encrypting payloads sent TO the server
   */
  var serverPublicKey = '';

  /**
   * Holds active, unresolved requests
   */
  var activeRequests = [];

  /**
   * Time in seconds until service timeout modal appears
   */
  var timeoutSeconds = 120;
//AC-28614 Changes- Start
  /*var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;  
  *//**
   * Dictionary for service errors
   *//*
  var serviceErrors = {
    generic: "We're sorry, we've encountered an error and may need to close. For support please call "+servicePhoneNumber+". [700]",
    timeout: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+".  [782]",
    skipStolen: "The AcceptanceNOW approval service experienced a network problem. For support please call "+servicePhoneNumber+". [791]",
    approvalFailure: "We're sorry, we've encountered a service error and cannot continue this transaction. For support please call "+servicePhoneNumber+". [785]",
    internetFailure: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+". [780]",
   	recordLocked: "We are currently updating your customer record. Please see an AcceptanceNow sales associate. [789]" +servicePhoneNumber //14120 message change as per business. 
  };*/
  //AC-28614 Changes - End

  //--------------------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------------------

  /**
   * Status code-based error handling for request failures
   * @param status (Number) Status code passed back from the response
   * @return (null)
   */
  var checkServiceErrors = function (data, status,serviceErrors) {//AC-28614 Changes
    void 0;
    if (status === 500) {
	      // sending error to Raygun
	      SharedDataSvc.reportRaygun(data);
	      
	      if (data.status_code === 'ENG-A4001-500') {
	      	
	        modalService.error(serviceErrors.approvalFailure);
	        
	      }else if (data.status_code === 'ENG-111-500') {
	      	
	        modalService.error(serviceErrors.skipStolen);
	        
	      /* Adding all the valid scenarios identified, below so that all other unexpected codes can be gracefully handled by generic pop up. */  
	      //blocked generic modal in case of 500 response for epay payment failure, VCC error and manual override scenarios
      	  }else if (data.status_code === 'ENG-A3001-500') { // AC 12058 code changes - on credit card pay and bank acc pay
      		modalService.error(serviceErrors.recordLocked); //AC 14120 code changes
      	  }else if (data.status_code === 'ENG-E3004-500') { //AC 14134 code changes - on auto pay - credit card pay and bank acc pay
      		modalService.lockError(serviceErrors.recordLocked);  
      	  }else if(data.status_code !== 'ENG-A1004-500' && data.status_code !== 'ENG-A2001-500' && data.status_code !== 'ENG-S2001-500' 
	      		&& data.status_code !== 'ENG-E1005-500' && data.status_code !== 'ENG-E3004-500' && data.status_code !== 'ENG-E2012-500' 
	      		&& data.status_code !== 'ENG-E3005-500' && data.status_code !== 'ENG-E3003-500' && data.status_code !== 'ENG-E2012-500' 
	      		&& data.status_code !== 'ENG-S5001-500' && data.status_code !== 'ENG-S4001-500' && data.status_code !== 'ENG-V107-500' 
	      		&& data.status_code !== 'ENG-B102-500' && data.status_code !== 'ENG-V101-500' && data.status_code !== "ENG-K1004-500" 
	      		&& data.status_code !== "ENG-K1005-500" && data.status_code !== "ENG-K1006-500" && data.status_code !== "ENG-A1010-500" && data.status_code !== "ENG-S000-500") {
	      			
      		  		SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
	        		modalService.error(serviceErrors.generic);
	        
	      }
    }else
    
    /* The http status below will show a generic pop up for Bad Gateway, Service Unavailable, Gateway timeout scenarios */
    if(status == 502 || status == 503 || status == 504){
    	modalService.error(serviceErrors.timeout);
    }else
    
    /* This conditions will handle all other 400 http response/Client Error/Bad request from FE 
     other the hardcoded scenarios below */
    if(status == 400){
    	
    	/* Checking for record lock scenario while creating customer, below */
        /* Adding condition in status message to check T2202 to avoid epay pin invalid issue please look VAN-2957*/
    	/* PRB0042786	Production Defect	VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment */
    	if(data.status_message == "Internal customer number record locked" 
    		|| data.status_message.indexOf('T2238') > -1
    		|| data.status_message.indexOf('the store is updating your customer record.') > -1){ // AC 12058 code changes - on submit of cash payment
   			modalService.error(serviceErrors.recordLocked);
   		}else if((data.status_code == "ENG-115-400") && (data.status_message == "no Rental update record found")) {
        modalService.error(serviceErrors.noEngagement);
       }
       else if( (data.status_message !== "Employee pin is not valid") && (data.status_message.indexOf("A completed agreement already exists") < 0)
    			&& (data.status_message !== "Brand/Model not found on model master.") && (data.status_code!== "ENG-E2002-400")
          && (data.status_message.indexOf('T2202') < 0 ) && (data.status_code !== "ENG-A1012-400") && (data.status_code !== "ENG-E2002-400") && (data.status_code !== "ENG-114-400") && (data.status_code !== 'ENG-S011-400')) {
   			SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
    		modalService.error(serviceErrors.generic);
    	}else if(data.status_message == "Customer Record locked") { //AC 13189 code changes starts
    		modalService.lockError(serviceErrors.recordLocked);  //AC 14120 code changes
    	}//AC 13189 code changes ends
   		
    }else
    
    /* The conditions below will handle all other 404 responses/Not-Found scenarios except the hardcoded scenarios below */
   	if(status == 404){//VAN-3402-Re-engagement with Code - AWP 
   		 if(SharedDataSvc.searchApp && data.status_code === "ENG-111-404" && data.status_message === "Error while preparing response"){
   			modalService.lockError(serviceErrors.approvalError);
   		}else if(data.status_details !== "No Customers Match Search Criteria" && data.status_details !== "SSN Supplied Not Found" && data.status_code !== "ENG-V116-404"){
   			SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
   			modalService.error(serviceErrors.generic);
   		}
   	}else
   	
   	/* The condition below will handle all other 409 responses/Conflicts due to concurrent multiples calls except the hardcoded ones */ 
   	if(status == 409){
   		if(data.status_message == "Internal customer number record locked"){
   			modalService.lockError(serviceErrors.recordLocked);
   		}else{
   			SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
   			modalService.error(serviceErrors.generic);
   		}
   		
   	}else
   	
    /*The Condition below checks for network failure/ internet failure */
    if(status == 0 && !navigator.onLine){
      modalService.error(serviceErrors.internetFailure);
    }else
    
    if (status === 401) {
      void 0;
      void 0;
      
      $location.path('login');
      authentication.logoutUser();
      $timeout(function(){
        modalService.open({
          templateUrl: 'partials/alert_authTimeout.html',
          windowClass: 'modal-small'
        });
      }, 500);
    }else 
    
    /* The condition below will handle all the unexpected responses left from above other than OK/200 and 303/server redirection */
    /* Added check for status not 0 to avoid multiple service call in limited time in that case API returns no Data so status = 0 which is no Error*/
    if(status !== 200 && status !== 303 && status!== 0){
    	SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
    	modalService.error(serviceErrors.generic);
    }
    
    
    
  };

  /**
   * Utility for random string creation, used in encrypt/decrypt
   * @return (String)
   */
  var randomString = function (length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
  };

  /**
   * Get the store id from service or cookie
   * @return (String)
   */
  var getStoreId = function () {
    if (SharedDataSvc.vanStoreId) {
      return SharedDataSvc.vanStoreId;
    } else if ($cookies.getObject('storeId')) {
      return $cookies.getObject('storeId');
    } else {
      void 0;
      return '0';
    }
  };

  /**
   * Get a base unencrypted header
   * @return (Object)
   */
  var getBaseUnencryptedHeader = function () {
    var baseHeaderUnencrypted = {
      'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,RAC-CORRELATION-ID', //changed uniqueid to RAC-CORRELATION-ID,
      'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
      'Encrypt-Mode': false,
      'RAC-KIOSK-LOCATION-ID': getStoreId(),
      'Content-Type': 'text/plain',
      'sessionToken' : authentication.getCurrentSessionId(),
      'RAC-CORRELATION-ID' : getRacCorrelationId(), //Story 26289 Code changes updated uniqueId to RAC-CORRELATION-ID
      'OPERATION-MODE': $cookies.getObject('operationMode'),
      'POS-TYPE': $cookies.getObject('POS-TYPE')
    };
    return baseHeaderUnencrypted;
  };

  /**
   * Get an encrypted header object
   * @return (Object)
   */
  var getBaseEncryptedHeader = function () {
    var baseHeaderEncrypted = {
      'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID', //changed uniqueId to RAC-CORRELATION-ID
      /*'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,https://dev-awp.acceptancenow.com,*',*/
      'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
      'Encrypt-Mode': true,
      'Public-Key': getPublicPEM(true),
      'RAC-KIOSK-LOCATION-ID': getStoreId(),
      'Content-Type': 'application/json',
      'sessionToken' : authentication.getCurrentSessionId(),
      'RAC-CORRELATION-ID' : getRacCorrelationId(), //Story 26289 Code changes updated uniqueID to RAC-CORRELATION-ID
      'OPERATION-MODE': $cookies.getObject('operationMode'),
      'POS-TYPE': $cookies.getObject('POS-TYPE')
	};
    return baseHeaderEncrypted;
  };
  
  //Story 26289 - Code changes start
  var getRacCorrelationId = function () {
	  var entryPoint = "AWP-"+getStoreId(),
	  randomUniqueStr = Math.random().toString(36).slice(2),
	  engagementId = SharedDataSvc.globalVars.engagementId?SharedDataSvc.globalVars.engagementId:"000000",
	  endTargetService = SharedDataSvc.endTargetService.slice(20), //AC 27054 code changes
	  racCorrelationId = entryPoint + " - " + engagementId + " - " + randomUniqueStr + " - " + endTargetService;
	  SharedDataSvc.racCorrelatioId = racCorrelationId;//Feature 7872 Integration of Logz code changes
	  return racCorrelationId;
  }
  //Story 26289 - Code changes end

  /**
   * Get the PEM from forge keys
   * @return (String)
   */
  var getPublicPEM = function (removeHeader) {
    if (!forgeKeys) {
      var keys = generateKeys();
    }
    if (removeHeader) {
      return forge.pki.publicKeyToPem(forgeKeys.publicKey).replace(/(-----(\w+) PUBLIC KEY-----|\r\n|\n|\r)/gm,'');
    } else {
      return forge.pki.publicKeyToPem(forgeKeys.publicKey);
    }
  };

  /**
   * Get the PEM from forge keys
   * @return (String)
   */
  var getPrivatePEM = function (removeHeader) {
    if (!forgeKeys) {
      var keys = generateKeys();
    }
    if (removeHeader) {
      return forge.pki.privateKeyToPem(forgeKeys.privateKey).replace(/(-----(\w+) RSA PRIVATE KEY-----|\r\n|\n|\r)/gm,'');
    } else {
      return forge.pki.privateKeyToPem(forgeKeys.privateKey);
    }
  };

  /**
   * Ported from Rafael's encrypt code. Encrypts text data using a key
   * @param plaintext (String) Text to be encrypted.
   * @return (String) Encrypted String. This is encrypted and base 64 encoded
   */
  var encrypt = function (plaintext, rsa_key) {

    // using old encryption for now
    return SharedDataSvc.encrypt(plaintext, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);

    var string_key = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var string_iv = randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var key = CryptoJS.enc.Utf8.parse(string_key);
    var iv = CryptoJS.enc.Utf8.parse(string_iv);
    var encrypted = CryptoJS.AES.encrypt(plaintext, key, {iv: iv});
    if (!forgeKeys) {
      generateKeys();
    }

    //RSA encryption. Using JSEncrypt because this is what works server side. Forge is used for decryption
    var rsa_encryption = new JSEncrypt();
    rsa_encryption.setPublicKey(rsa_key);
    var encrypted_session_key = window.atob(rsa_encryption.encrypt(encrypted.key.toString(CryptoJS.enc.Latin1)));

    //pack all that stuff
    var encoding;
    var array_size = 4 + encrypted_session_key.length + 4 + encrypted.iv.sigBytes + encrypted.ciphertext.sigBytes;
    var ab = new ArrayBuffer(array_size);
    var dv = new jDataView(ab);
    dv.writeUint32(encrypted_session_key.length, false);
    dv.writeString(encrypted_session_key, encoding = 'binary');
    dv.writeUint32(encrypted.iv.sigBytes, false);
    dv.writeString(encrypted.iv.toString(CryptoJS.enc.Latin1), encoding = 'binary');
    dv.writeString(encrypted.ciphertext.toString(CryptoJS.enc.Latin1), encoding = 'binary');
    var byte_stream = dv.getString(array_size, 0, encoding = 'binary');
    return window.btoa(byte_stream);
  };

  /**
   * Decrypt data using a key and base 64 decoding
   * @param encoded_byte_stream (String) Encrypted data
   * @return (String) Decrypted data.
   */
  var decrypt = function (encoded_byte_stream) {
    try {
      // This fails if there is no encoding on the data. Usually means data is not encrypted
      var buffer = window.atob(encoded_byte_stream);
    } catch (e) {
      void 0;
      return encoded_byte_stream;
    }

    var dv = new jDataView(buffer);
    var key_size = dv.getUint32(0, false);
    var encoded_session_key = dv.getString(key_size, 4);
    var unencoded_session_key = encoded_session_key;
    var iv_size  = dv.getUint32(key_size + 4, false);
    var iv = CryptoJS.enc.Latin1.parse(dv.getString(iv_size, key_size + 8));
    var ciphertext = dv.getString(buffer.length - key_size - iv_size - 8, key_size + iv_size + 8);

    //unwrap the key
    var session_key = forgeKeys.privateKey.decrypt(unencoded_session_key);
    var parsed_session_key = CryptoJS.enc.Latin1.parse(session_key);

    return CryptoJS.AES.decrypt({ciphertext: CryptoJS.enc.Latin1.parse(ciphertext)}, parsed_session_key, {iv: iv}).toString(CryptoJS.enc.Utf8);
  };

  /**
   * Generate keys
   * @return (Object) The returned public and private keys in an Object
   */
  var generateKeys = function () {
    if (!forgeKeys) {
      forgeKeys = forge.pki.rsa.generateKeyPair(2048);
    }
    return forgeKeys;
  };

  /**
   * The object returned which represents this service
   */
  var serviceAgentObj = {

    //--------------------------------------------------
    // PUBLIC PROPERTIES
    //--------------------------------------------------

    activeRequests: activeRequests,

    //--------------------------------------------------
    // PUBLIC FUNCTIONS
    //--------------------------------------------------

    /**
     * Sets the public key. Should be a certain length and a string.
     * @param key (String)
     * @return (null)
     */
    setServerPublicKey : function (key) {
      if (key && key.length > 10) {
        serverPublicKey = key;
      } else {
        void 0;
      }
    },

    /**
     * Sets the raygun key. Should not be blank and a string.
     * @param key (String)
     * @return (null)
     */
    setRaygunKey : function (key) {
      if (key) {
        SharedDataSvc.raygunKey = key;
      } else {
        void 0;
      }
    },
    
  //Feature 7872 Integration of Logz code changes starts
    setLogzApiToken : function (key) {
    	if(key){
    		SharedDataSvc.logzApiKey = key;
    	} else {
    		void 0;
    	}
    },
    
    setlogzEnableFlag : function(key) {
    	if(key){
    		SharedDataSvc.isLogzEnabled
    	} else {
    		void 0;
    	}
    },
  //Feature 7872 Integration of Logz code changes ends

    setInvRedFlag : function(key) {
      if(key){
        SharedDataSvc.invoiceReductionPayment
      } else {
        void 0;
      }
    },

    setInvRedPayAmt : function(key) {
      if(key){
        SharedDataSvc.invoiceReductionPaymentAmount
      } else {
        void 0;
      }
    },

    /**
     * Encrypt some data and return it
     * @param data (String) Data to be encrypted
     * @return (String) Encrypted data
     */
    encryptData : function (data) {
      return encrypt(data, serverPublicKey);
    },

    /**
     * GET for http request
     * @param url (String) REQUIRED The url to make the request
     * @param method (String) GET, POST, PUT, DELETE, etc. Defaults to GET if unspecified
     * @param addHeaders (Object) Additional headers to add to call
     * @param body (Object) The body of data to send
     * @param doEncrypt (Object) Should the data be encrypted
     * @return (Object) Promise object
     */
    httpCall : function (url, method, addHeaders, body, doEncrypt) {
    	//AC-28614 Changes - Start
    	var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
      if($rootScope.selectedLanguage == 'es'){
           var serviceErrors = {
            generic: "Lo sentimos, hemos encontrado un error y es posible que la sesión tenga que cerrar.Para obtener asistencia, por favor llamar al "+servicePhoneNumber+". [700]",
            timeout: "En estos momentos AcceptanceNOW no puede acceder al registro del cliente. Por favor, inténtelo de nuevo y póngase en contacto con su representante de ventas para obtener asistencia. "+servicePhoneNumber+".  [782]",
            skipStolen: "TEl servicio de la aprobación de AcceptanceNOW experimentó un problema de la conexión.Por favor, póngase en contacto con su representante de ventas o llame al "+servicePhoneNumber+". [791]",
            approvalFailure: "Lo sentimos, hemos encontrado un error de servicio y no podemos continuar con esta transacción. Para obtener asistencia por favor llamar al "+servicePhoneNumber+". [785]",
            internetFailure: "En estos momentos AcceptanceNOW no puede acceder al registro del cliente. Por favor, inténtelo de nuevo y póngase en contacto con su representante de ventas para obtener asistencia. "+servicePhoneNumber+". [780]",
            recordLocked: "Actualmente estamos actualizando su registro de cliente. Consulta a un empleado de ventas AcceptanceNow.  [789]", //14120 message change as per business. 
            approvalError: "¡¡¡Lo siento!!! No podemos encontrar su ID de aprobación en nuestros registros. Por favor, introduzca un ID diferente.", //VAN-3402-Re-engagement with Code - AWP
            noEngagement: "No existe ese number de contrato de renta." //error handling message for No rental update records found  in spanish
           };
      }else{
         var serviceErrors = {
            generic: "We're sorry, we've encountered an error and may need to close. For support please call "+servicePhoneNumber+". [700]",
            timeout: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+".  [782]",
            skipStolen: "The AcceptanceNOW approval service experienced a network problem. For support please call "+servicePhoneNumber+". [791]",
            approvalFailure: "We're sorry, we've encountered a service error and cannot continue this transaction. For support please call "+servicePhoneNumber+". [785]",
            internetFailure: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+". [780]",
            recordLocked: "We are currently updating your customer record. Please see an AcceptanceNow sales associate. [789]", //14120 message change as per business. 
            approvalError: "Sorry!!! We are not able to find this Id in our records. Please enter a different Id.", //VAN-3402-Re-engagement with Code - AWP
            noEngagement:"No rental update record found." //error handling message for No rental update record found in english
          };
      }
       
      //AC-28614 Changes - end
   //   var endTargetHere = addHeaders.endTarget;
    //  console.log("This header", addHeaders);
      SharedDataSvc.endTargetService = addHeaders.endTarget; //AC-26289 - Logging: Session Unique Identifier - phaseII
      var defer = $q.defer(),
      canceller = $q.defer(),
     // formatBody = JSON.stringify(body),
      formatBody = doEncrypt ? encrypt(JSON.stringify(body), serverPublicKey) : body,
      baseHeaders = doEncrypt ? getBaseEncryptedHeader() : getBaseUnencryptedHeader(),
      headers = addHeaders ? _.merge(baseHeaders, addHeaders) : baseHeaders,
      requestObj = {
        "url": url,
        "method": method || 'GET',
        "data": formatBody,
        "headers": headers,
        "timeout": canceller.promise,
        // parse JSON-like responses as JSON
        "transformResponse": [function(response) {
          var start = /^\s*(\[|\{[^\{])/;
          var end = /[\}\]]\s*$/;
          if (start.test(response) && end.test(response)) {
            return angular.fromJson(response);
          }
          return response;
        }]
      },
      // sets a service timeout that will cancel all requests after specific time and pop modal
      serviceTimeout = $timeout(function(){
        canceller.resolve();
        modalService.error(serviceErrors.timeout);
      }, timeoutSeconds*1000);
      
   /*  if(endTargetHere == "rentacentervrto/rest/pdfSignerService"){
     	formatBody = body;
     }*/

      activeRequests.push(canceller);
      $http(requestObj).
        then(function (response) {
          void 0;
          var data = response.data;
          var status = response.status;
          var returnData;
          try {
            returnData = (doEncrypt && data) ? decrypt(data) : data;//VAN 4199-Update the Spanish disclaimer in AWP
          } catch (e) {
            void 0;
            returnData = data;
          }
          try {
            returnData.status = status;
          } catch (e) {
            // error
          } 
          
          _.pull(activeRequests, canceller);
          $timeout.cancel(serviceTimeout);
          defer.resolve(returnData);
        }).
        catch(function (response) {
          var data = response.data;
          var status = response.status;
          void 0;
          void 0;
          void 0;
          _.pull(activeRequests, canceller);
          $timeout.cancel(serviceTimeout);
          defer.reject({
            'data': {
              'errorData': data
            },
            'status': status
          });

          //serviceAgentObj.reportRaygun(data);
          checkServiceErrors(data, status,serviceErrors); //AC-28614 Changes
        });

      return defer.promise;
    },

    /**
     * Cancel active page requests via canceller promises passed into http
     * @return (null)
     */
    cancelRequests: function() {
      for (var i = 0; i < activeRequests.length; i++) {
        activeRequests[i].resolve('Cancelled Request');
      }
    }
  }

  return serviceAgentObj;
}]);




(function() {
	'use strict';

	myApp
	.factory('getTrainingLibraryFactory', getTrainingLibraryFactory);

	function getTrainingLibraryFactory (
		$http,
		$q,
		$cookies,
		$rootScope,
		SharedDataSvc,
		localStorageService,
		authentication,
		ServiceAgent,
		PROXY_ACTION_URL
	){
		var service = {
			getData: getData,
			getPdfData: getPdfData,
			getNewDocuments: getNewDocuments,
			submitUserNameAndContinue:submitUserNameAndContinue,
			getPdfDataFromS3: getPdfDataFromS3
		};

		return service;

		function getData () {
			var deferred = $q.defer();
			var activeStoreId;

			if ($cookies.getObject('storeId')) {
				activeStoreId = $cookies.getObject('storeId');
			} 

			if (localStorageService.get('trainingLibraryDocs')) {
				deferred.resolve(localStorageService.get('trainingLibraryDocs'));
			} else {
				var headers = SharedDataSvc.commonHeadersJSON;
				headers.endTarget = 'rentacentervrto/rest/trainingLibrary/downloadIndex';
				headers.sessionToken = authentication.getCurrentSessionId();

				ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST',
					headers,
					null,
					false
				).then(
					function(response) {
						var documentsArray = response.documents;
						var isManned = SharedDataSvc.globalVars.storeSetup.isManned;
						// var isManned = false;
						// for (var i = documentsArray.length - 1; i >= 0; i--) {
						// 	console.log(documentsArray[i].manned);
						// }
						var cleaned = _.remove(documentsArray, function(obj) {
							if (isManned && obj.manned) {
								return obj;
							}
							else if(!isManned && obj.unmanned)
							{
								return obj;
							}
						});
						var sorted = _.sortBy(cleaned, ['isNew','publishDate']);
						void 0;
						$rootScope.trainingCount = getNewDocuments(cleaned);
						
						sorted.reverse();
						void 0;
						localStorageService.set('trainingLibraryDocs', sorted);
						deferred.resolve(sorted);
					}, 
					function (response) {
						//TODO: handle error here for downloading training library
						void 0;
						deferred.reject(response);
					}
				);
			}

			return deferred.promise;
		}

		function getNewDocuments (trainingLibraryDocs) {
			var counter = 0;
			if(trainingLibraryDocs)
			{

				for (var i = trainingLibraryDocs.length - 1; i >= 0; i--) {
					if(trainingLibraryDocs[i].isNew)
						counter++;
				};
			}
			return counter;
		}

		function getDocumentCount (documents) {
			return documents.length;
		}

		function submitUserNameAndContinue (username) {
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
				headers.endTarget = 'rentacentervrto/rest/trainingLibrary/downloadIndex';
				headers.sessionToken = authentication.getCurrentSessionId();
				headers.COMMENTS = username;
				
				ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST',
					headers,
					null,
					false
				).then(
					function (response) {
						void 0;			
						deferred.resolve(response);
					},
					function (response) {
						void 0;
						deferred.reject(response);
					}
				);
			return deferred.promise;
		}

		function getPdfData (contentUrl) {
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers['Access-Control-Allow-Headers'] = 'Encrypt-Mode, endTarget, sessionToken, RAC-KIOSK-LOCATION-ID, Content-Type, filename';
			headers['Access-Control-Allow-Origin'] = 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,filename,RAC-CORRELATION-ID';//AC 27054 code Changes
			headers['Accept'] = 'application/pdf';
			headers.endTarget = 'rentacentervrto/rest/trainingLibrary/download';
			// 
			headers.filename = contentUrl.split('?')[1];
			headers.sessionToken = authentication.getCurrentSessionId();

			ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST', 
					headers, 
					null, 
					false
			).then(
				// Success
				function(response) {
					// console.log(response);
					deferred.resolve(response);
				},
				// Error
				function(error) {
					deferred.reject(response);
				}
			);

			return deferred.promise;
		}
		
		//Feature 8407 - Move training library docs to S3 bucket
		function getPdfDataFromS3(contentUrl){
			var deferred = $q.defer();
			var headerData = SharedDataSvc.commonHeadersJSON;
			headerData['Content-type'] = 'application/pdf';
			
			$http({
	            url : contentUrl,
	            method : 'GET',
	            headers : headerData,
	            responseType : 'arraybuffer'
	        }).then(
				function(response) {
					deferred.resolve(response);
				},
				function(error) {
					deferred.reject(response);
				}
			);

			return deferred.promise;
			
		}

	}
	getTrainingLibraryFactory.$inject = ["$http", "$q", "$cookies", "$rootScope", "SharedDataSvc", "localStorageService", "authentication", "ServiceAgent", "PROXY_ACTION_URL"];

})();

(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('dashboardServices', dashboardServices);

	/* @ngInject */
	function dashboardServices(
		$q,
		$http,
		SharedDataSvc,
		API_DOMAIN,
		PROXY_ACTION_URL,
		authentication,
		ServiceAgent
	) {
		var service = {
			getCreditCardDetails: getCreditCardDetails,
			getCustomerReview: getCustomerReview,
			getAdditionalInfo: getAdditionalInfo,
			setAdditionalInfo: setAdditionalInfo,
			viewApplication: viewApplication

		};
		return service;

		////////////////

		function getCreditCardDetails (engagementId) {
			void 0;
			var deferred = $q.defer();
			if (!engagementId) {
				void 0;
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?engagementId=' + engagementId;
			//var params = '?engagementId=' + engagementId;
			//var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey); //ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/payment/getcreditcarddetails',
				'sessionToken' : authentication.getCurrentSessionId(),
				//'params' : encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};

			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				null,
				true
		    ).then(function (response) {
		    	void 0;
		    	var successData = (typeof response !== "object") ? JSON.parse(response) : response;
		    	deferred.resolve(successData);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		function getCustomerReview(customerId){
            var deferred = $q.defer();
            var headers = {
              'endTarget': 'rentacentervrto/rest/user/getCustomerReview',
              'sessionToken' : authentication.getCurrentSessionId(),
              'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//Story 26289 code changes
            };
            var url = PROXY_ACTION_URL  + '?customerId=' + customerId;
            ServiceAgent.httpCall(
	            url,
	            'POST',
	            headers,
	            null,
	            true
            ).then(function (response) {
            	void 0;
                var successData = (typeof response !== "object") ? JSON.parse(response) : response;
            	deferred.resolve(successData);
            },
            function (response) {
                void 0;
                deferred.reject(response);
            });

            return deferred.promise;
		}

		function getAdditionalInfo(engagementId){
            var deferred = $q.defer();
            var headers = {
              'endTarget': 'rentacentervrto/rest/customer/customerQuestions',
              'sessionToken' : authentication.getCurrentSessionId(),
              'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//Story 26289 code changes
            };
            var url = PROXY_ACTION_URL  + '?engagementId=' + engagementId;
            ServiceAgent.httpCall(
	            url,
	            'POST',
	            headers,
	            null,
	            true
            ).then(function (response) {
            	void 0;
                var successData = (typeof response !== "object") ? JSON.parse(response) : response;
            	deferred.resolve(successData);
            },
            function (response) {
                void 0;
                deferred.reject(response);
            });

            return deferred.promise;
		}


		function setAdditionalInfo(data){
			 var deferred = $q.defer();
            var headers = {
              'endTarget': 'rentacentervrto/rest/customer/update',
              'sessionToken' : authentication.getCurrentSessionId(),
              'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
              //'Access-Control-Allow-Origin':'Public-Key,endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,RAC-CORRELATION-ID'
            };
            var url = PROXY_ACTION_URL;
            ServiceAgent.httpCall(
	            url,
	            'POST',
	            headers,
	            data,
	            false
            ).then(function (response) {
            	void 0;
                var successData = (typeof response !== "object") ? JSON.parse(response) : response;
            	deferred.resolve(successData);
            },
            function (response) {
                void 0;
                deferred.reject(response);
            });

            return deferred.promise;
		}

		function viewApplication(customerId) {
			void 0;
			var deferred = $q.defer();
			if (!customerId) {
				void 0;
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL + '?customerId=' + customerId;
			var headers = {
				'endTarget': 'rentacentervrto/rest/application/viewApplication',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID'//AC 27054 code Changes
			};

			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				null,
				true
		    ).then(function (response) {
		    	void 0;
		    	var successData = (typeof response !== "object") ? JSON.parse(response) : response;
		    	deferred.resolve(successData);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

	}
	dashboardServices.$inject = ["$q", "$http", "SharedDataSvc", "API_DOMAIN", "PROXY_ACTION_URL", "authentication", "ServiceAgent"];
})();
'use strict';

// define custom submit directive
myApp
.directive('ddSubmit', ["$parse", function ($parse) {
    return {
        restrict: 'A',
        require: ['ddSubmit', '?form'],
        controller: ["$scope", function ($scope) {
            this.attempted = false;

            var formController = null;

            this.setAttempted = function() {
                this.attempted = true;
            };

            this.setFormController = function(controller) {
              formController = controller;
            };

            this.needsAttention = function (fieldModelController) {
                if (!formController) return false;

                if (fieldModelController) {
                    return fieldModelController.$invalid && (fieldModelController.$dirty || this.attempted);
                } else {
                    return formController && formController.$invalid && (formController.$dirty || this.attempted);
                }
            };
        }],
        compile: function(cElement, cAttributes, transclude) {
            return {
                pre: function(scope, formElement, attributes, controllers) {
                    var submitController = controllers[0];
                    var formController = (controllers.length > 1) ? controllers[1] : null;
                    submitController.setFormController(formController);
                    scope.dd = scope.dd || {};
                    scope.dd[attributes.name] = submitController;
                },
                post: function(scope, formElement, attributes, controllers) {
                    var submitController = controllers[0];
                    var formController = (controllers.length > 1) ? controllers[1] : null;
                    var fn = $parse(attributes.ddSubmit);
                    formElement.bind('submit', function () {
                        submitController.setAttempted();
                        if (!scope.$$phase) scope.$apply();
                        if (!formController.$valid) return false;
                        scope.$apply(function() {
                            fn(scope, {$event:event});
                        });
                    });
                }
            };
        }
    };
}]);

'use strict';

myApp
.directive('editorderitem', ["$location", function($location) {
  return function ( scope, element, attrs ) {
    void 0;
    var thisIdx;
    jQuery('#addItem').on('show.bs.modal', function(){
        jQuery('#addItem').addClass('editmode_idx'+thisIdx);
    });
    attrs.$observe('editorderitem', function (id) {
      thisIdx = id;
      element.bind( 'click', function () {
          scope.$apply( function () {
            void 0;
          });
      });
    });
  };
}])
'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('focusme', ["$parse", "$timeout", function($parse, $timeout) {
  return {
    link: function(scope, element, attrs) {
      var model = $parse(attrs.focusme);
      scope.$watch(model, function(value) {
          $timeout(function() {
            element[0].focus();
          },100);
      });
      //element.bind('blur', function() {
        //scope.$apply(model.assign(scope, false));
      //});
    }
  }
}]);

'use strict';

myApp
.directive('globalMessage', ["SharedDataSvc", function(SharedDataSvc) {
  return {
    restrict: 'E',
    //controller: '',
    replace: true,
    //transclude: true,
    link: function (scope, element, attrs) {
      scope.message = SharedDataSvc.globalmessage;
      scope.cssClass = 'alert-warning';
      scope.showthis = SharedDataSvc.globalmessageShow;
    },
    template: '<div class="alert {{cssClass}}" ng-show="{{showthis}}">{{message}}</div>' //  ng-show="globalmessageShow"
  };
}]);
'use strict';

// use maxlength, and auto-focus to next field
myApp
.directive('maxlforward', function() {
    return function(scope, element, attr) {
      element.on('focus', function(event) {
          var myMax = parseInt(element.attr('maxlength'));
          var currKey;
          element.on('keyup', focusNext);
          function focusNext(event) {
            var key = event.keyCode;
            // if key entered is numeric (0-9, either top row keys or numpad)
            if ((key > 47 && key < 58) || (key > 95 && key < 106)) {
              currKey = element.val().length;
              if (currKey == myMax) {
                  // remove event from input
                  element.off('keyup', focusNext);
                  element.parent('div').next('div').children('input').focus();
              }
            }
          }
      });
    }
});

'use strict';

myApp
.directive('myLoadingSpinner', function() {
    return {
      restrict: 'A',
      replace: true,
      transclude: true,
      scope: {
        loading: '=myLoadingSpinner',
        setCenter: '=center'
      },
      templateUrl: 'partials/spinner.html',
      // template: '<div class="spinnerBox" ng-class="{center: setCenter}"><div ng-show="loading" class="my-loading-spinner-container"><img src="img/spinner-rac.svg" /></div><div ng-hide="loading" ng-transclude></div></div>',
      link: function(scope, element, attrs) {
        // var spinner = new Spinner().spin();
        setTimeout(function(){
            // var loadingContainer = element.find('.my-loading-spinner-container')[0];
            // loadingContainer.appendChild(spinner.el);
        }, 200);
      }
    };
});

'use strict';

// for obfuscation on blur
myApp
.directive('obfuscator', function() {
    return function(scope, element, attr) {
        element.on('focus', function(event) {
            element.attr('type','text');
        });
        element.on('blur', function(event) {
            element.attr('type','password');
        });
    }
})
'use strict';

// for obfuscation on blur
myApp
.directive('prefixer', function() {
	return {
		restrict: 'A',
		link: function(scope, element, attrs) {
			element
				.on('focus', function(e) {
					var n = element.val();
					if (n.indexOf("$") != 0) {
						n = n.replace(/[$a-zA-Z]/g, "");
						n = "$" + n;
						scope.$apply(function() {
							element.val(n);
						});
					}
				})
				.on('keydown', function(e) {
					var key = e.keyCode;
					// // if ((key > 47 && key < 58) || (key > 95 && key < 106) || key === 190 || key === 110) {
					//  return true;
					// } else {
					//  return false;
					// }
				})
				.on('keyup', function(e) {
					var n = element.val();
					if (isNaN(n) && n.indexOf("$") == 0) {
						n = n.replace(/[a-zA-Z]/g, "");
						element.val(n);
					} else {
						if (n.indexOf("$") != 0) {
							n = n.replace(/[$a-zA-Z]/g, "");
							n = "$" + n;
						}
						scope.$apply(function() {
							element.val(n);
						});
					}
				});
		}
	}
});
myApp
.directive('numbersOnly', function(){
   return {
     require: 'ngModel',
     link: function(scope, element, attrs, modelCtrl) {
       modelCtrl.$parsers.push(function (inputValue) {
           // this next if is necessary for when using ng-required on your input.
           // In such cases, when a letter is typed first, this parser will be called
           // again, and the 2nd time, the value will be undefined
           if (inputValue == undefined) return ''
           var transformedInput = inputValue.replace(/[^0-9+.]/g, '');
           if (transformedInput!=inputValue) {
              modelCtrl.$setViewValue(transformedInput);
              modelCtrl.$render();
           }

           return transformedInput;
       });
     }
   };
});
angular
    .module('myApp')
    .directive('calendar', calendar);

function calendar() {
    var directive = {
        link: link,
        template: '<div class="calendar" datepicker ng-model="calendar.dt" ng-change="calendar.calChange();" max-date="calendar.twoMonthsOut" min-date="calendar.today" show-weeks="dateOptions.showWeeks"></div>',
        restrict: 'EA',
        controller: CalendarController,
        controllerAs: 'calendar'
    };

    return directive;

    function link(scope, element, attrs) {

    }
}

CalendarController.$inject = ['$scope', 'SharedDataSvc'];

function CalendarController($scope, SharedDataSvc) {
    var vm = this;
    vm.dt;
    vm.today = moment().format('YYYY-MM-DDTHH:mm:ss');
    //vm.twoMonthsOut = moment(vm.today).add(1, 'M').endOf('month')._d;
    vm.twoMonthsOut = moment(vm.today).add(90, 'd');//VAN-3545-van delivery date-Updated to 90 days
    vm.twoMonthsOut = moment(vm.twoMonthsOut).format('YYYY-MM-DDTHH:mm:ss');

    vm.calChange = calChange;

    function calChange() {
        SharedDataSvc.invoiceDeliveryData.date = vm.dt
        void 0;
    }
}
angular
    .module('myApp')
    .directive('datepickerpopup', datepickerpopup);

function datepickerpopup() {
    var directive = {
        link: link,
        template: '<p class="input-group"><input type="text" class="form-control" datepicker-popup="{{datepickerpopup.format}}" show-button-bar="false" ng-model="datepickerpopup.dt" is-open="datepickerpopup.opened" min-date="datepickerpopup.ipMinDate" max-date="datepickerpopup.ipMaxDate" datepicker-options="datepickerpopup.dateOptions" date-disabled="disabled(date, mode)" ng-required="true" close-text="Close" ng-change="datepickerpopup.getSelectedDate(datepickerpopup.dt)" /><span class="input-group-btn"><button type="button" class="btn btn-default" ng-click="datepickerpopup.dateChange($event)"><i class="glyphicon glyphicon-calendar"></i></button></span></p>',
        restrict: 'EA',
        controller: DatePickerPopupController,
        controllerAs: 'datepickerpopup'
    };

    return directive;

    function link(scope, element, attrs) {

    }
}

DatePickerPopupController.$inject = ['$scope', 'SharedDataSvc','datesFactory', '$cookies'];

function DatePickerPopupController($scope, SharedDataSvc, datesFactory, $cookies) {
    var vm = this;
    $scope.isSpanishStore = $cookies.getObject('isSpanishStore');
    vm.isDateSelected = SharedDataSvc.quoteCalculator.selectedDate;
    vm.isAdjustablePayment = false;
    vm.arrAdjustablePayment = SharedDataSvc.globalVars.storeSetup.adjustableInitPayment.split(",");
    vm.isAdjustablePayment = parseInt(vm.arrAdjustablePayment[0]);

    if (vm.isAdjustablePayment) {
        var start = parseInt(vm.arrAdjustablePayment[1]);
        var end = parseInt(vm.arrAdjustablePayment[2]);
        void 0;
        
        datesFactory.getDateByDaysFromNow(start).then(function(response) {
            vm.ipMinDate = response;
            //vm.dt = vm.ipMinDate._d;
            if(vm.isDateSelected !== null) {
                vm.dt = SharedDataSvc.quoteCalculator.selectedDate;
            } 
            datesFactory.getDateByDaysFromNow(end).then(function(responseData) {
                vm.ipMaxDate = responseData;
            });
        });
    }

    vm.formats = ['dd-MMMM-yyyy', 'MMMM-dd-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[1];
    if($scope.isSpanishStore)
        vm.format = vm.formats[0];
    vm.dateOptions = {
        formatYear: 'yy',
        startingDay: 0,
        showWeeks: false
    };

    vm.dateChange = dateChange;
    vm.getSelectedDate = getSelectedDate;

    function dateChange($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.opened = true;
    }

    function getSelectedDate(selectedDate) {
        SharedDataSvc.quoteCalculator.selectedDate = selectedDate;
        SharedDataSvc.quoteCalculator.date = moment(selectedDate).format('YYYY-MM-DD HH:MM:SS');
        void 0;
    }
}

'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('originator', function() {
  return {
    template: '<span class="glyphicon glyphicon-info-sign" title="Customer created via:" data-trigger="hover " data-container="body" data-toggle="popover" data-placement="top" data-content="{{created}}"></span>',
    restrict: 'A',
    scope: {
      created:"="
    },
    link: function(scope, element, attrs) {
      $(function () {
        $('[data-toggle="popover"]').popover();
      });
    }
  }
});

'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('tooltip', function() {
  return {
    template: '<span class="glyphicon glyphicon-info-sign" data-original-title="{{title}}" data-trigger="hover" data-container="body" data-toggle="popover" data-placement="bottom" data-content="{{created}}"></span>',
    restrict: 'A',
    scope: {
      created:"=",
      title:"@heading"
    },
    link: function(scope, element, attrs) {
      $(function () {
        $('[data-toggle="popover"]').popover();
      });
    }
  }
});
angular
    .module('myApp')
    .directive('ngPrint', printDirective);

function printDirective() {
    var directive = {
        link: link,
        restrict: 'A'
    };

    var printSection = document.getElementById('printSection');
    // if there is no printing section, create one
    if (!printSection) {
        printSection = document.createElement('div');
        printSection.id = 'printSection';
        document.body.appendChild(printSection);
    }

    return directive;

    function link(scope, element, attrs) {
        var elemToPrintButton;

        element.on('click', function () {
            elemToPrintButton = document.getElementById(attrs.id);
            var elemToPrint = $(elemToPrintButton).parents(".modal-content").find("iframe");
            if (elemToPrint) {
                printElement(elemToPrint, elemToPrintButton);
                $("body").addClass("printView");
            }
        });
    }
    function printElement(elem, elemButton) {
        // clones the element you want to print
        printSection.innerHTML = '';
        $(elem).clone().appendTo(printSection);
        var iframeName = elem.attr('name');
        window.frames[iframeName].focus();
        window.frames[iframeName].print();
        printSection.innerHTML = '';
        $("body").removeClass("printView");
    }
}
myApp
.directive('ccDate', function() {
   return {
     require: 'ngModel',
     link: function(scope, element, attrs, modelCtrl) {
          element.bind("keypress", function(event){
            var target = event.target.value;
            void 0;
            void 0;

            if(target.length === 1) {
              void 0;
              element.context.value = target + '/';
            }

            // if(target.length === 2 && event.keyCode === 8 || target.length === 3 && event.keyCode === 46) {
            //   console.log('REMOVE SLASH');
            //   element.context.value = target.replace('/', '');
            // }


          });
     }
   };
});
(function() {
	'use strict';

	angular
	.module('app.order')
	.directive('customAttributes', customAttributes);

	/* @ngInject */
	/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
	function customAttributes ($rootScope) {
		/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
		/*
		Usage: Use product data to build out attribute entry section that returns data properly formatted for sending to services
		Creates: Various types of input fields
		*/
		var directive = {
			require: 'ngModel',
			restrict: 'A',
			scope: {
				attributeData: '=customAttributes'
			},
			templateUrl: 'partials/directive_customAttributes.html',
			link: link
		};
		return directive;

		function link(scope, element, attrs, ngModelController) {
			var normalizedTypes = {
				'integer': 'number',
				'quantity': 'number',
				'checkBox': 'checkbox',
				'text': 'text',
				'radio': 'radio'
			};
			var localAttributesModel;

			/* Take the attribute data and build out the attributes section piece by piece
			*/
			function prepareAttributes(attributeData) {
				// initialize by clearing existing data
				$(element).empty();
				localAttributesModel = [];
				updateAttributesModel()

				if (typeof attributeData === 'undefined') return;
				// Loop through attribute groups
				for(var i = 0; i < attributeData.length; i++) {
					var attributeGroup = attributeData[i];
					var $groupDiv = $('<div>', {
						class: 'form-group attribute-group'
					}).append('<hr/>');
					// Loop through individual attributes
					for (var ii = 0; ii < attributeGroup.customAttributes.length; ii++) {
						var attribute = attributeGroup.customAttributes[ii],
							attributeType = normalizedTypes[attribute.fieldType],
							attributeId = attribute.htCode ? 'att-'+attribute.htCode : '';
						var $label, $input, $inputDiv;
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
						var data = $rootScope.selectedLanguage=="es"?attribute.labelEs:attribute.labelEn;
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
						$label = $('<label>', {
							class: 'attribute-'+attributeType+'-label',
							for: attributeId
						})
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
						.text(data);
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
						$input = buildInput({
								type: attributeType,
								name: data.replace(/\r?\n|\r/g,'')
						});

						$inputDiv = $('<div>', {
							class: 'attribute-field'
						})
						.append($label);

						if (attribute.customAttributeOptions) {
							// Set form validity since these options are always required, for now
							ngModelController.$setValidity('required', false);
							// Loop through individual attribute options (typically a radio button)
							for(var iii = 0; iii < attribute.customAttributeOptions.length; iii++) {
								var attributeOption = attribute.customAttributeOptions[iii];
								var $radioOptionDiv, $optionInput, $optionLabel;

								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
								var dataOption = $rootScope.selectedLanguage=="es"? attributeOption.labelEs:attributeOption.labelEn;
								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
								
								$radioOptionDiv = $('<div>', {
									class: 'attribute-radio-option'
								});

								$optionInput = $input.clone(true).attr({ // clone with event attached
									id: 'attr-'+attributeOption.htCode,
									value: dataOption
								});

								$optionLabel = $label.clone().attr({
									for: 'attr-'+attributeOption.htCode,
									class: 'attribute-radio-option-label'
								})
								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
								.text(dataOption);
								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
								// Add label and input to it's own radio option div
								$radioOptionDiv.append($optionInput, $optionLabel);
								$inputDiv.append($radioOptionDiv);
							}
						} else {
							$input.attr({
								id: attributeId,
								placeholder: attribute.placeHolderEn
							});
							// AC-11985, AC-13473 Code changes Start
							if($input.attr('type')=="number"){
								$input.attr({
									"min": "0",
									"ng-pattern": "0+\.[0-9]*[1-9][0-9]*$",
									"onkeypress": "return event.charCode >=48 && event.charCode <= 57;"
									});
							// AC-11985, AC-13473 Codes changes end
							}
							$inputDiv.append($input);
						}

						// Add each item to the local data model
						localAttributesModel.push(createAttributeObject(attribute));
						// Start adding the items to each other
						$groupDiv.append($inputDiv);
						$(element).append($groupDiv);
					}
				}
				updateAttributesModel();
			}

			/* Build the attribute object that will be added to model array
			*/
			function createAttributeObject(attribute) {
				var attributeType,
					attributeValue;
				
				/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
				var attributeLan = $rootScope.selectedLanguage=="es"?attribute.labelEs:attribute.labelEn;
				/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
				
				switch(normalizedTypes[attribute.fieldType]) {
					case 'checkbox':
						attributeValue = "0";
						break;
					default:
						attributeValue = '';
						break;
				}
				return {
					customAttribute: attributeLan,
					value: attributeValue
				}
			}

			/* Update the named attribute in the local data model
			*/
			function updateAttributeValue(name, value) {
				var attributeIndex = _.findIndex(localAttributesModel, {customAttribute: name});
				localAttributesModel[attributeIndex].value = value.toString();
				updateAttributesModel();
			}

			/* Give us a new input element
			*/
			function buildInput(inputParams) {
				var $input = $('<input>', inputParams)
				.on('change', handleAttributeChange);
				return $input;
			}

			/*  Special event handling for attribute value changes
			*/
			function handleAttributeChange(event) {
				var target = event.target,
						targetValue = target.value;

				switch(target.type) {
					case 'checkbox':
						targetValue = $(event.target).prop('checked') ? "1" : "0";
						break;
					case 'radio':
						ngModelController.$setValidity('required', true);
						break;
				}
				updateAttributeValue(target.name, targetValue);
			}


			function updateAttributesModel() {
				var newData = localAttributesModel.length > 0 ? localAttributesModel : undefined;
				//AC 15028 code changes starts -- only if new data has value update it to view value
				if(newData){
					ngModelController.$setViewValue(newData);
				} else {
					ngModelController.$setValidity('required', true);
				}
				//AC 15028 code changes ends	
			}

			scope.$watch('attributeData', prepareAttributes);
		}
	}
	customAttributes.$inject = ["$rootScope"];
})();
'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('mannedStore', ["SharedDataSvc", function (SharedDataSvc) { //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
  return {
    template: '<span>{{phone}}</span>',
    restrict: 'A',
    link: function(scope, element, attrs) {},
    controller: ["$scope", "SharedDataSvc", function ($scope, SharedDataSvc) { //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
      $scope.manned = SharedDataSvc.globalVars.storeSetup.isManned;
      $scope.$watch(function () {
        return SharedDataSvc.globalVars.storeSetup.isManned;
      }, function (isManned) {
        void 0;
        $scope.phone = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
      });
    }]
  }
}]);

'use strict';
angular.module('myApp')
.directive('emailAgreement', ["getInitialDashboard", "modalService", "customerDataFactory", function (getInitialDashboard, modalService, customerDataFactory) {
  return {
    template: '<a class="glyphicon glyphicon-envelope"></a>',
    restrict: 'A',
    scope: {
      customer: "=",
      sendObj:"&"
    },
    link: function (scope, element, attrs) {
      var eventName = attrs.eventName || 'click';
      element.on(eventName, function(){
        scope.activeAgreements = [];
        scope.activeAgreements = scope.customer.activeAgrList;
        scope.isHybrid = scope.$root.isHybrid;
        scope.showError = false;
        if(scope.$root.returnToScreen){
          scope.$root.returnToScreen = false;
          scope.customerInfo.email = scope.$root.editedEmail;
          scope.$root.editedEmail = undefined;
          var modalEmailAgreement = modalService.open({
            templateUrl: '/partials/modal_emailAgreement.html',
            scope: scope,
            backdrop: 'static'
          });
        } else {
          /* VAN-340 changes */
          //if(scope.isStoreManned && !scope.isHybrid){
            customerDataFactory.findByEngagementId(scope.customer.engagementId, false).then(function(response) {
                scope.customerInfo = response.customer;
                var modalEmailAgreement = modalService.open({
                  templateUrl: '/partials/modal_emailAgreement.html',
                  scope: scope,
                  backdrop: 'static'
                });
            }, function(error){
                void 0;
                return false;
            });
          /*} else {
              var modalEmailAgreement = modalService.open({
                templateUrl: '/partials/modal_emailAgreement.html',
                scope: scope,
                backdrop: 'static'
              });
          }  */  
          /* VAN-340 changes */      
        }
      });
    },
    controller: ["$scope", "$element", "$rootScope", "$q", "getInitialDashboard", "modalService", "SharedDataSvc", "customerDataFactory", "applicationService", function ($scope, $element, $rootScope, $q, getInitialDashboard, modalService, SharedDataSvc, customerDataFactory, applicationService) {
      $scope.sendObj = {
        store: false,
        customer: true
      };
      $scope.isStoreManned = SharedDataSvc.globalVars.storeSetup.isManned;
      $scope.sendEmails = function () {
        var engagementId = [], emailAgreementList = "";
        angular.forEach($scope.activeAgreements, function(item){
          var el = angular.element("#"+item.agrNum);
          if(el[0].checked && engagementId.indexOf(item.engagementId) < 0){
            engagementId.push(item.engagementId);
          }
        });
    	  SharedDataSvc.globalVars.engagementId = $scope.customer.engagementId; //story 26289 code changes reviewed by Theenmathi
        emailAgreementList = engagementId.toString();
        if(emailAgreementList == ""){
           $scope.showError = true;
           return false;
        }
        getInitialDashboard.sendAgreements($scope.customer.customerId, emailAgreementList, $scope.sendObj.customer, $scope.sendObj.store, ($scope.customerInfo ? $scope.customerInfo.email : undefined));
        modalService.dismiss();
      };

      $scope.confirmAndEditEmail = function(){
        $scope.emailAddress = $scope.customerInfo.email; 
        var confirmAndEditEmailModal = modalService.open({
          title: "Email Verification",
          templateUrl: 'partials/modal_confirmAndEditEmail.html',
          scope: $scope,
          backdrop: 'static',
          controller: 'confirmAndEditEmailCtrl'
        });
        confirmAndEditEmailModal.result.then(function(email) {
          $rootScope.editedEmail = email;
          $rootScope.returnToScreen = true;
          $element.trigger('click');
        });    
      }; 
    }]
  }
}]);
/**
 * Created by samirshah on 12/3/15.
 */
myApp
    .directive('decimalNumbersOnly', function(){
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, modelCtrl) {
                modelCtrl.$parsers.push(function (inputValue) {
                    // this next if is necessary for when using ng-required on your input.
                    // In such cases, when a letter is typed first, this parser will be called
                    // again, and the 2nd time, the value will be undefined
                    if (inputValue == undefined) return ''
                    var transformedInput = inputValue.replace(/[^0-9+.]/g, '');
                    var index = inputValue.indexOf( '.' );
                    if ( index > -1 ) {
                        transformedInput = inputValue.substr( 0, index + 1 ) +
                            inputValue.slice( index ).replace( /[^0-9+]/g, '' );
                    }
                    if (transformedInput!=inputValue) {
                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                    }
                    return transformedInput;
                });
            }
        };
    });

'use strict';

myApp.directive('customFooter', function() {
  return {
    restrict: 'EA',
    templateUrl: 'partials/customFooter.html',
    scope: true,
    replace: true,
    controller: 'customFooterController'
  }
});
'use strict';

myApp.directive('dynamicContent', ["$compile", function($compile) {
    return {
        restrict: 'EA',
        replace: true,
        link: function (scope, element, attrs) {
            scope.$watch(attrs.dynamic, function() {
                $compile(element.contents())(scope);
            });
        }
    };
}]);
myApp
.directive('onlyDigits', function(){
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                if (inputValue == undefined) return '';
                var transformedInput = inputValue.replace(/[^0-9]/g, '');
                if (transformedInput !== inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }
                return transformedInput;
            });
        }
    };
});
'use strict';

myApp.filter('interpretInputType', function(){
	return function(input) {
        var inputType = input.toLowerCase();

        switch(input) {
            case 'quantity':
            case 'integer':
                inputType = 'number';
                break;
        }

        return inputType;
	}
});

myApp.filter('momentFormat', function(){
    return function(input, format) {
        if (!input || !input.length) { return; }
        return moment(input).format(format);
    }
});

myApp.filter('payments', ["$filter", function($filter) {
    return function(input) {
        if (isNaN(input)) {
            return input;
        }
        return $filter('currency')(input, "$ ", 2);
    }
}]);

myApp.filter('formatNextRenewDate', ["$filter", function($filter) {
    return function(input) {
        if (isNaN(input)) {
            return input;
        }

        var input = moment(input).format('MMM') +" "+ moment(input).format('D');
        return input;
    }
}]);

myApp.filter('formatInitialPaymentDate', ["$filter", function($filter) {
    return function(input) {
        if (isNaN(input)) {
            return input;
        }

        var input = moment(input).format('MMM') +" "+ moment(input).format('D') +", "+ moment(input).format('YYYY');
        return input;
    }
}]);

myApp.filter('telephone', function () {
    return function (tel) {
        if (!tel) { return ''; }

        var value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if (country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + " (" + city + ") " + number).trim();
    };
});

myApp.filter('dateSuffix', ["$filter", function($filter) {
  var suffixes = ["st", "nd", "rd", "th"];
  return function(input) {
    if(!input) {
        return '';
    }
    var day = input.substr(input.length-1, 1);
    switch (day)
    {
       case '1': 
        if(input == '11'){
            return input + 'th';
        } 
        else {
            return input + 'st';
        }
        break;
    
       case '2': 
        if(input == '12'){
            return input + 'th';
        } 
        else {
            return input + 'nd';
        }
        break;
    
       case '3': 
        if(input == '13'){
            return input + 'th';
        } 
        else {
            return input + 'rd';
        }
        break;
    
       default:  return input + 'th';
    }
  };
}]);

myApp.filter('decimalFilter', ["$filter", function($filter) {
  return function(input, param) {
    var val = parseFloat(Math.round(input)/100).toFixed(param);
    return val;
  };
}]);

myApp.filter('camelCase', function (){ // for one word at a time
    var camelCaseFilter = function (input){
         for ( var i = 0; i < input.length; i++ ) 
             input = input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
         return input;
     };
    return camelCaseFilter;
});
///////////////////////////////////////////
//////////////////////////////////////////
///   LOGIN CONTROLLER   ////////////////
////////////////////////////////////////
///////////////////////////////////////
(
	function() {

		'use strict';

		angular.module('app.login').factory('MemoryCache', ["$cacheFactory", function($cacheFactory) {
			return $cacheFactory('memoryCache');
		}]).controller('Login', Login);

		/* @ngInject */
		function Login($scope, $rootScope, MemoryCache, $http, $location, authentication, SharedDataSvc, $timeout, $cookies, getStoreInfoFactory, modalService) {
			/*jshint validthis: true */
			var login = this;

			///////////

			login.showLoginError = false;
			login.showReset = false;
			login.loginError = '';
			login.viewLoading = false;
			login.globalmessage = SharedDataSvc.globalmessage;
			login.globalmessageShow = SharedDataSvc.globalmessageShow;
			login.enter = enter;
			login.showMPError = false;//Added Feature 7855 code changes
			$rootScope.xpartnerLogin = false;//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers
			//login.truePassphrase = "";

			/**
			 * TODO:
			 * - Hook up to actual authentication service
			 */
			function enter() {
				void 0;
				if (!login.passphrase) {
					void 0;
					login.showLoginError = true;
					return;
				}
				login.showLoginError = false;
				login.showMPError = false;//Added Feature 7855 code changes
				$scope.viewLoading = true;
				//9464 - Dispute/hide disable button in invoice dashboard code changes starts
				$rootScope.passphrase = login.passphrase;
				//9464 - Dispute/hide disable button in invoice dashboard code changes ends
				authentication.loginUser(login.passphrase).then(function success(data) {
					void 0;
					MemoryCache.removeAll();
					//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
					var passPhrase = login.passphrase.toLowerCase();
					//if(passPhrase.includes("xpartner")){
					if(passPhrase.indexOf("xpartner")!=-1){	//AWP Application not working in IE
						$rootScope.xpartnerLogin = true;
					}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
					getStoreInfoFactory.getData(login.passphrase).then(function(data) {
						//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
						if($rootScope.xpartnerLogin && data.isManned){
							data.isManned = false;
							data.operationMode = 'UNMANNED';
							data.achVerification = true;
							data.allowAch = false;
							data.isReferenceRequired = false;
						}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
						$rootScope.storeInfo = data;
						var lang = $rootScope.selectedLanguage ? $rootScope.selectedLanguage : $rootScope.languageSettings[1].id;
						if(data.state == 'PR'){
							if($cookies.getObject('isSpanishStore') && $rootScope.selectedLanguage != $rootScope.languageSettings[1].id){
								lang = $rootScope.selectedLanguage;
							} else {
								lang = $rootScope.languageSettings[1].id;
							}
							$rootScope.showLanguageSettings = true;
							$cookies.putObject('isSpanishStore', true);
							$rootScope.changeLocale(lang);
						} else {
							$rootScope.showLanguageSettings = false;
							$cookies.putObject('setSpanishStore', false);
							$rootScope.changeLocale(lang);
						}
						if(data.posType){
							$cookies.putObject('POS-TYPE', data.posType);
						} else {
							$cookies.putObject('POS-TYPE', null);
						}
						if(data.operationMode){
							
							$cookies.putObject('operationMode', data.operationMode);
							
							$rootScope.helpdeskNo = data.vipPhoneNumber;
							if(data.isManned && data.operationMode == 'HYBRID'){
								$rootScope.isHybridMode = true;
								$rootScope.isMannedMode = false;
							} else if (data.isManned && data.operationMode == 'MANNED'){
								$rootScope.isHybridMode = false;
								$rootScope.isMannedMode = true;
							} else {
								$rootScope.isHybridMode = false;
								$rootScope.isMannedMode = false;
							}

							$cookies.putObject('isHybridMode', $rootScope.isHybridMode);
							$cookies.putObject('isMannedMode', $rootScope.isMannedMode);
							$cookies.putObject('helpdeskNo', $rootScope.helpdeskNo);
							
							if(data.operationMode == "HYBRID"){
								$cookies.putObject('isHybrid', true);	
								SharedDataSvc.globalVars.storeSetup = data;
								void 0;
								$cookies.putObject('auth_timeout', null);
								modalService.lock(false);
								$scope.viewLoading = false;
								$location.path('/dashboard');
								//Feature 7855 Code changes starts
							}else if(data.operationMode == "MANUALPAYABLENOTALLOWED"){
								login.showMPError = true;
								authentication.resetAll();
							}else if(data.operationMode == "MANUALPAYABLE"){
								$cookies.putObject('isHybrid', false);
								SharedDataSvc.globalVars.storeSetup = data;
								void 0;
								$cookies.putObject('auth_timeout', null);
								modalService.lock(false);
								$scope.viewLoading = false;
								$location.path("/dashboardAgreement");
							//Feature 7855 Code changes ends
							}else if(data.operationMode == "UNKNOWN"){
								/* We can hardcode the isHybrid cookie value to true below,
								 * just for development purpose, if storeInfo service fails and 
								 * is giving operationMode as "UNKNOWN" always.
								 */
								login.showLoginError = true;
								authentication.resetAll();
							}else{
								/* Execution will come here only if it is 
								 * manned on unmanned operation mode.
								 */
								$cookies.putObject('isHybrid', false);
								SharedDataSvc.globalVars.storeSetup = data;
								void 0;
								$cookies.putObject('auth_timeout', null);
								modalService.lock(false);
								$scope.viewLoading = false;
								$location.path('/dashboard');
							}
							
						}else{
							/* If api fails by any reason and do not provides operation mode, by the script below, we
							 * are going in the old manner and are hardcoding the hybrid flag as false, by default.
							 * Once, storeInfo changes are stable we can uncomment the logout script and comment
							 * all other lines below. Ideally, we should not allow login if operation mode is absent.
							 */
							/*
							console.log("OperationMode is absent in storeInfo response, hence going with old way.");
							SharedDataSvc.globalVars.storeSetup = data;
							console.log("[Login.enter] store setup: ", data);
							$cookies.putObject('auth_timeout', null);
							modalService.lock(false);
							$scope.viewLoading = false;
							$location.path('/dashboard');
							*/
							
							void 0;
							login.showLoginError = true;
							authentication.resetAll();
						}	
					}, function(data) {
						$scope.showenterstoreError = true;
						angular.forEach(data.processingErrors, function(value, key) {
							this.push(key + ': ' + value);
						}, SharedDataSvc.globalmessage);
						$scope.viewLoading = false;
						//this.globalmessageShow = true;
						login.passphrase = '';
						//login.truePassphrase = '';
						MemoryCache.removeAll();
					});
				}, function fail(data){
					void 0;
					$scope.viewLoading = false;
					login.showLoginError = true;
					//login.truePassphrase = '';
					login.passphrase = '';
					MemoryCache.removeAll();
				});
			};

			$rootScope.hideMenuButtons = true;
		}
		Login.$inject = ["$scope", "$rootScope", "MemoryCache", "$http", "$location", "authentication", "SharedDataSvc", "$timeout", "$cookies", "getStoreInfoFactory", "modalService"];

	})(); 

///////////////////////////////////////////
//////////////////////////////////////////
///   DASHBOARD CONTROLLER   ////////////
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
		.module('app.dashboard')
		.controller('Dashboard', Dashboard);

	/* @ngInject */
	function Dashboard(
		$scope,
		$rootScope,
		$location,
		$route,
		$idle,
		$sce,
		$routeParams,
		$anchorScroll,
		$timeout,
		$window,//VAN-441 - VAN Docusign - Remote signing documents on personal device
		$cookies,
		$translate,
		modalService,
		authentication,
		SharedDataSvc,
		getInitialDashboard,
		applicationService,
		updateEngagementItemFactory,
		paymentFactory,//VAN-441 - VAN Docusign - Remote signing documents on personal device
		getStoreInfoFactory,
		manualOverrideFactory,
		getTrainingLibraryFactory,
		dashboardServices,
		datesFactory, //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		customerDataFactory,
		stateRestrictFactory,
		orderEstimatePutFactory,
		productFactory
	) {

		/*jshint validthis: true */
		var dashboard = this;

		/*
		 TODO:
		 - Change icons for Dashboard progress
		 - Fix status order
		 */

		// DEFINE VARS
		$rootScope.hideDashboardButton = true;
		$rootScope.hideMenuButtons = false;
		$rootScope.hideTrainingLibButton = false;
		$rootScope.hideDashboardAgreementButton = false;
		$rootScope.agreementAmount;
		$rootScope.creditAmount;
		$rootScope.creditCardNo;
		$rootScope.creditCardExp;
		$rootScope.creditCardCVV;
		$rootScope.servicePhoneNumber = '';
		$scope.searchValue = SharedDataSvc.searchValue;
		
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Starts
		$scope.remoteEmail={};
		$scope.remoteEmail.lanType ='1';
		$rootScope.showDocusign = false;
		dashboard.isDocusign = isDocusign;
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends
		
		// used by modal for DocuSigning rewritten agreements
		$scope.raUpdate = {};

		dashboard.openModal = false;
		dashboard.noSearchValue = true;
		dashboard.getInitialDashboard = [];
		dashboard.globalVars = SharedDataSvc.globalVars;
		dashboard.showButtons = false;
		dashboard.order = 'Date';
		dashboard.sortingOrder = '';
		dashboard.loadingDashboard = true;
		dashboard.globalmessage = SharedDataSvc.globalmessage;
		dashboard.globalmessageShow = SharedDataSvc.globalmessageShow;
		dashboard.deliveryTempId = '';
		dashboard.transformedOrderStatus = [];
		SharedDataSvc.hasECmodalRun = false;
		dashboard.isStoreManned;
		//VAN - 1430 - IE Performance starts
		$scope.detailsView = true;
		$scope.val;
		//VAN - 1430 - IE Performance ends
		/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Starts*/ 		
		dashboard.estimatedInitialPaymentQq;
		/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Ends*/
		
		
		dashboard.engagementId;
		dashboard.employeeId;
		dashboard.noAssociatePass = false;
		dashboard.invalidAssociatePass = false;
		dashboard.creditCardDetail;
		//AC 25996 code changes starts
		dashboard.currentEngagement;
		dashboard.agreementHtId;
		//AC 25996 code changes ends
		dashboard.restrictions;
		dashboard.prodCat;
		dashboard.quickQuoteEstimate;
		dashboard.quoteInput = {
			deliveryMaxAmount : 500.00,
			ESVMaxAmount : 999.99
		};
		dashboard.itemMinimumPrice;
		dashboard.itemMaximumPrice;
		dashboard.initialRentalFee;
		dashboard.monthlyRentalFee;
		dashboard.quickQuoteInitialInfo;
		//dashboard.quickQuoteDisclaimer;
		dashboard.letsGetStarted = true;
		dashboard.isQuickQuoteEnabled;
		dashboard.nextRenewDte = [];
		//VAN-353 code changes
		dashboard.noEsvNoDel;
		dashboard.onlyEsvNoDel;
		dashboard.noESVonlyDel;
		dashboard.withEsvDel;
		dashboard.selectionFlag;

		// PUBLIC FUNCTIONS
		dashboard.orderStatusVerbiage = orderStatusVerbiage;
		dashboard.setOrderStatusClass = setOrderStatusClass;
		dashboard.agreementStatusVerbiage = agreementStatusVerbiage;
		dashboard.setAgreementStatusClass = setAgreementStatusClass;
		dashboard.deliveryStatusVerbiage = deliveryStatusVerbiage;
		dashboard.setDeliveryStatusClass = setDeliveryStatusClass;
		dashboard.applicationStatusVerbiage = applicationStatusVerbiage;
		dashboard.setApplicationStatusClass = setApplicationStatusClass;
		dashboard.paymentStatusVerbiage = paymentStatusVerbiage;
		dashboard.setPaymentStatusClass = setPaymentStatusClass;
		dashboard.saleStatusVerbiage = saleStatusVerbiage;
		dashboard.setSaleStatusClass = setSaleStatusClass;
		dashboard.summaryStatus = summaryStatus;
		dashboard.summaryStatusForSortingFn = summaryStatusForSortingFn;
		dashboard.editApplication = editApplication;
		dashboard.editDelivery = editDelivery;
		dashboard.showDeliveryModal = showDeliveryModal;
		dashboard.statusIcon = statusIcon;
		dashboard.navigateTo = navigateTo;
		dashboard.showConfirmOrderBtn = showConfirmOrderBtn;
		dashboard.showAddInfoBtn = showAddInfoBtn;
		dashboard.getManualInterventionStatus = getManualInterventionStatus;
		dashboard.isApplicationEditable = isApplicationEditable;
		dashboard.showScheduleDeliveryCta = showScheduleDeliveryCta;
		//dashboard.showGenerateCreditCardBtn = showGenerateCreditCardBtn;
		dashboard.showViewPrintVccBtn = showViewPrintVccBtn;
		dashboard.showModalCreditCard = showModalCreditCard;
		dashboard.showModal = showModal;
		//dashboard.confirmGenerateCreditCard = confirmGenerateCreditCard;
		dashboard.viewVccBarcodeDetails = viewVccBarcodeDetails;
		dashboard.getAdditionalDetails = getAdditionalDetails;
		dashboard.setAdditionalDetails = setAdditionalDetails;
		//dashboard.generateCreditCardCheck = generateCreditCardCheck;
		//dashboard.generateCreditCard = generateCreditCard;
		dashboard.summaryStatusSortFn = summaryStatusSortFn;
		dashboard.showSearchIndicator = showSearchIndicator;
		dashboard.showModalSearchIndicator = showModalSearchIndicator;
		//AC 25996 code changes starts
		dashboard.modifyOrderSummary = modifyOrderSummary;
		dashboard.checkForAgreementData = checkForAgreementData;
		dashboard.declineAgreementModalOptions = declineAgreementModalOptions;
		dashboard.declineAgreement = declineAgreement;
		//AC 25996 code changes ends
		dashboard.ssnCheckForAgreements = ssnCheckForAgreements;
		dashboard.continueToAgreement = continueToAgreement;//AC24649 code changes
		dashboard.getQuoteModal = getQuoteModal;
		dashboard.getRAUpdateSearchModal = getRAUpdateSearchModal;
		dashboard.inStoreRAUpdateDocuSign = inStoreRAUpdateDocuSign;
		dashboard.remoteRAUpdateDocuSign = remoteRAUpdateDocuSign;
		dashboard.quickQuoteCalculation = quickQuoteCalculation;
		dashboard.quoteModalReset = quoteModalReset;
		dashboard.feeCheck = feeCheck;
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - starts*/
		dashboard.resetQQEPO = resetQQEPO;
		dashboard.resetQQWhenCatChange = resetQQWhenCatChange;
		/* VAN-975 - AWP : Quick Quote Screen - Payment Details were not getting cleared on relaunch*/
		dashboard.showPaymentSectionForBiweekly = true;
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
		dashboard.viewApplication = viewApplication;
		dashboard.verifyInfo = verifyInfo;
		dashboard.resetSearchResults = resetSearchResults;
		dashboard.navigateToAgreement = navigateToAgreement;
		dashboard.filterByVerifyInfo = filterByVerifyInfo;
		dashboard.remoteSignFromDashboard = remoteSignFromDashboard;//VAN-441 - VAN Docusign - Remote signing documents on personal device
		dashboard.verifyInfoCount = 0;
		dashboard.isVerified = isVerified;
		dashboard.isVeriFiltered = false;
		dashboard.allData = [];
		//VAN - 1430 - IE Performance starts
		dashboard.loadMore = loadMore;
		dashboard.detailsView = detailsView;
		//VAN - 1430 - IE Performance ends
		dashboard.processingModal;//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date
		var itemStatus = {
			application: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				manualIntervention: 'Manual',
				outOfServiceArea: 'OutOfArea',
				associateReview: 'AssocRvw'
			},
			order: {
				notStarted: 'NotStarted',
				inProgress: 'InProgress',
				pendingConfirm: 'Pending',
				confirmed: 'Confirmed',
				reconfirm: 'Reconfirm'  /*Fixed defect 24952 by handling the newly added 'Reconfirm' as order status.*/
			},
			agreement: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				voided: 'Voided'  //AC 25996 updated code changes
			},
			payment: {
				pendingConfirm: 'Pending',
				inProgress: 'InProgress',
				complete: 'Complete'
			},
			delivery: {
				notStarted: 'NotStarted',
				pendingPayment: 'Pending',
				complete: 'Complete',
				scheduled: 'Scheduled',
				pickup: 'Pickup',
				confirmed: 'Confirmed',
				readyToSchedule: 'ReadySched'
			},
			sales: {
				pending: 'Pending',
				complete: 'Complete',
				ready: 'Ready',
				submitted: 'Submitted'
			}
		};
		dashboard.itemStatus = itemStatus;

		$scope.stepsListEn = [
			'Application and Order (either can be first)',
			'Order Confirmation',
			'Agreements',
			'Payment',
			'Invoice & Delivery or Generate Credit Card (either can be first)'
		];
		$scope.stepsListEs = [
			"Aplicación y orden (Cualquiera puede ser primero)",
			"Confirmación del pedido",
			"Acuerdos",
			"Pago",
			"Factura y entrega o genere la tarjeta de crédito (Cualquiera puede ser primero)"	
		];

		var excludedCategories = [];

		init();

		function init () {
			if($scope.searchValue !== undefined && $scope.searchValue.length > 0) {
				//$scope.filterByName = $scope.searchValue;
				$scope.filterByName = {
					customerFullName : $scope.searchValue
				}
			}
			// Remove url param
			$location.search('data', null);//Defect 28266 - VAN: References getting wiped off in HT
			if($location.$$search.confirm) {
				$location.search('confirm', null);
			}

			SharedDataSvc.clearPrintSection();
			SharedDataSvc.globalVars.navigateToDashboard = false;
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');

			// get storeInfo data
			getStoreInfoFactory.getData()
				.then(function(response){
					dashboard.storeInfo = response;
					$rootScope.storeInfo = response;
					dashboard.enableRemoteForDocusign = response.enableRemoteForDocusign;//VAN-441 - Remote signing documents on personal device
					SharedDataSvc.storeInfo = response;
					$scope.vccPrint = response.vccPrintAWP;
					if(!response.quickQuoteProductList){
						dashboard.hideQQProductList = true;
					} else if(response.quickQuoteProductList.product_en.length == 1) {
						dashboard.quoteInput.selectedProduct = response.quickQuoteProductList.product_en[0];
						dashboard.hideQQProductList = true;
					} else {
						dashboard.hideQQProductList = false;
					}
					// Set store info in global service
					SharedDataSvc.globalVars.storeSetup = response;
					dashboard.isStoreManned = SharedDataSvc.globalVars.storeSetup.isManned;
					dashboard.isHybrid = SharedDataSvc.globalVars.storeSetup.hybridEnabled;
					
					/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Starts*/
					dashboard.estimatedInitialPaymentQq = SharedDataSvc.globalVars.storeSetup.estimatedInitialPaymentQq;
					/*VAN 238 - VAN - AWP/Kiosk QQ - Deliver monthly payment and number of months w/o initial payment - Ends*/
					
					dashboard.overrideOption = response.overrideOption;
					dashboard.itemMinimumPrice = response.minItemAmount;
					dashboard.itemMaximumPrice = response.maxItemPrice;
					dashboard.isQuickQuoteEnabled = response.isQuickQuoteEnabled;
					dashboard.allowExtendedWarranty = response.allowExtendedWarranty;
					dashboard.arrAdjustablePayment = SharedDataSvc.globalVars.storeSetup.adjustableInitPayment.split(",");
					dashboard.isAdjustablePayment = parseInt(dashboard.arrAdjustablePayment[0]);
					if(!dashboard.isAdjustablePayment) {
						var currentDate = datesFactory.getCurrentDate();
						dashboard.nextRenewDteOptions = datesFactory.setQuoteDates(currentDate);
						_.forEach(dashboard.nextRenewDteOptions, function(item, index) {
							if($scope.isSpanishStore) {
								dashboard.nextRenewDte[index] = {
									id: index,
									option: item.day + ' ' + item.month,
									formattedDate: item.nextDate
								}
							} else {
								dashboard.nextRenewDte[index] = {
									id: index,
									option: item.month + ' ' + item.day,
									formattedDate: item.nextDate
								}
							}
 						})
						void 0;
					}

					if (response.excludedCategories) {
						excludedCategories = response.excludedCategories;
					}
					if(response.immediateInvoicing == false){
						/*$scope.stepsList = [
							$translate.instant('modal_enforceOrder_desc2'),
							$translate.instant('modal_enforceOrder_desc3'),
							$translate.instant('modal_enforceOrder_desc4'),
							$translate.instant('modal_enforceOrder_desc5'),
							$translate.instant('modal_enforceOrder_desc6')							
						];*/
						$scope.stepsListEn = [
							'Application and Order (either can be first)',
							'Order Confirmation',
							'Agreements',
							'Payment',
							'Invoice & Delivery or Generate Credit Card (either can be first)'
						];
						$scope.stepsListEs = [
							"Aplicación y orden (Cualquiera puede ser primero)",
							"Confirmación del pedido",
							"Acuerdos",
							"Pago",
							"Factura y entrega o genere la tarjeta de crédito (Cualquiera puede ser primero)"	
						];
					}

					//ITIN flag
					$rootScope.itinAllowed = SharedDataSvc.globalVars.storeSetup.itinAllowed;
					$rootScope.invoiceMethod = response.invoiceMethod;
					$rootScope.vccPrint = response.vccPrintAWP;
					if(response.vanStoreId){
						$rootScope.storeNo = response.vanStoreId;
						$rootScope.state = response.state;
						
						/*VAN 501 - PR: Lines 2-12, Associate review should not display for PR store*/
						$scope.showLinesBtn = $rootScope.state!="PR" ? $scope.showLinesBtn=true : $scope.showLinesBtn=false;
						
						$rootScope.isHybrid = $cookies.getObject("isHybrid");

						/* Generating random string below as per VAN-2783 */
						var randomString = "";
			    		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			    		for( var i=0; i < 9; i++ ){
			    			randomString += possible.charAt(Math.floor(Math.random() * possible.length));
			    		}

			    		var awpEntryPointName = "AWP-"+response.vanStoreId+"-"+randomString;
			        	void 0;

			        	var crrCookie = $cookies.getObject('AwpEntryPoint');

			        	if(typeof crrCookie === "undefined"){
			        		var curDate = new Date();
			        		var curYear = curDate.getFullYear();
			        		var expYear = parseInt(curYear) + 10;
			        		var curMonth = curDate.getMonth();
			        		var curDay = curDate.getDate();

			        		var expDate = new Date(expYear, curMonth, curDay);
			        		void 0;

			        		$cookies.putObject('AwpEntryPoint', awpEntryPointName, {'expires': expDate} );
			        	}else{
			        		void 0;
			        	}

					}

					stateRestrictFactory.getAllStoreRestrictions(response.state)
					.then(function(response) {
						dashboard.restrictions = response;
						void 0;
					});
				}, function(response){
					void 0;
				});
			// get the data!
			getInitialDashboard.getData().then(function (data) {
					//console.log("[dashboard.init] success ", data);
					// DECRYPT this data
					dashboard.loadingDashboard = false;
					dashboard.dashboardData = data;
					$scope.originalDashboardData = angular.copy(dashboard.dashboardData);
					//Adding element to subset arrays of response data for Search functionality
					var infoCount = 0;
					$scope.dashboard.dashboardData = angular.forEach(dashboard.dashboardData, function(value, key) {
						var customerFullName = value.customerFirstName + ' ' + value.customerLastName;
						$scope.dashboard.dashboardData[key].customerFullName = customerFullName;
						
						if($scope.dashboard.dashboardData[key].verifyInfoAssociateName == undefined || $scope.dashboard.dashboardData[key].verifyInfoAssociateName.length <= 0){
							if($scope.dashboard.dashboardData[key].applicationStatus === "Accepted"){
								infoCount++;
							}
							
						}
						if($scope.dashboard.dashboardData[key].agreementOriginator == undefined){
							/*VAN-277 - VAN - Change VAN/AWP to Kiosk/AWP on i button on dashboard - Starts*/
							/*$scope.dashboard.dashboardData[key].agreementOriginator = "AWP/VAN";*/
							$scope.dashboard.dashboardData[key].agreementOriginator = "AWP/Kiosk";
							/*VAN-277 - VAN - Change VAN/AWP to Kiosk/AWP on i button on dashboard - Ends*/
						}else if($scope.dashboard.dashboardData[key].agreementOriginator == "HighTouch"){
							$scope.dashboard.dashboardData[key].agreementOriginator = "Point of Sale";
						}
						//VAN-441 - VAN Docusign - Remote signing documents on personal device - Starts
						if(dashboard.enableRemoteForDocusign){
							if($scope.dashboard.dashboardData[key].applicationStatus  === 'Accepted' && $scope.dashboard.dashboardData[key].orderStatus  === 'Confirmed' && $scope.dashboard.dashboardData[key].agreementStatus === 'Pending' 
								&& $scope.dashboard.dashboardData[key].agreementId !== 'null'/* && $scope.dashboard.dashboardData[key].agreementOriginator === 'Point of Sale' */){//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
								$rootScope.showDocusign = true;
							}
						}
						//VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends

					});
					dashboard.verifyInfoCount = infoCount;
					void 0;
					SharedDataSvc.globalVars.dashboardListCache = data;
					var routeEngId = $routeParams.engagementId;
					if (routeEngId) {
						$timeout(function() {
							//VAN - 1430 - IE Performance starts
							var old = $location.hash();
							$location.hash('engagementId_' + routeEngId);
							$anchorScroll();
							$location.hash(old);
							//VAN - 1430 - IE Performance starts
							$("#hide_btn_" + routeEngId).removeClass("collapsed");
							$("#panel_" + routeEngId).addClass("in");
							$("#panel_" + routeEngId).css("height", "auto");
							$scope.val = parseInt(routeEngId);
						}, 1000);
					}
				},
				function (data) {
					void 0;
				});
			getTrainingLibraryFactory.getData().then(function(response){
				void 0;
				$rootScope.trainingCount = getTrainingLibraryFactory.getNewDocuments(response);
			});

			//get product category data
			productFactory.getProductCategories().then(function(response) {
				dashboard.prodCat = response.productCategories;
			});

		}
		//VAN-441 Legal/Compliance - Remote signing documents on personal device - Starts
		function isDocusign(item){
			if(dashboard.enableRemoteForDocusign){
				if(item.applicationStatus  === 'Accepted' && item.orderStatus  === 'Confirmed' && item.agreementStatus === 'Pending' 
					&& item.agreementId !== null && item.agreementId !== undefined /*&& item.agreementOriginator === 'Point of Sale' */){//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
					return true;
				}
				else{
					return false;
				}
			}else{
				return false;}
		}
		//VAN-441 Legal/Compliance - Remote signing documents on personal device - Ends
		function isVerified(item){
			if($scope.isSpanishStore){
				if(item.verifyInfoAssociateName == undefined || item.verifyInfoAssociateName.length <= 0){
					if(item.applicationStatus === "Accepted"){
							return true;
					}
				}else{
					return false;
				}
			}else{
				return false;
			}
			
		}

		function filterByVerifyInfo(){
			if(dashboard.isVeriFiltered === false){
				var verifyDashboardData = angular.copy($scope.dashboard.dashboardData);
				dashboard.allData = jQuery.extend(true, {}, verifyDashboardData);
				dashboard.isVeriFiltered  = true;
				if(dashboard.isVeriFiltered){
					var filtered = [];
					angular.forEach(dashboard.dashboardData, function(value, key) {
						if($scope.dashboard.dashboardData[key].verifyInfoAssociateName == undefined || $scope.dashboard.dashboardData[key].verifyInfoAssociateName.length <= 0){
							if($scope.dashboard.dashboardData[key].applicationStatus === "Accepted"){
								filtered.push($scope.dashboard.dashboardData[key]);
							}
						}
					});
				    void 0;
				    $scope.dashboard.dashboardData = filtered;
			    }
			}else{
				 dashboard.dashboardData = $scope.originalDashboardData;
				 dashboard.isVeriFiltered = false;
			}
		}

		function resetSearchResults() {
			var data = $scope.dashboard.dashboardData;
			SharedDataSvc.searchValue = "";
			$scope.searchValue = "";
			$scope.filterByName = {};
			$scope.filterByVerifyInfo = {};
			dashboard.loadingDashboard = true;
			dashboard.noSearchValue = false;
			$scope.dashboard.dashboardData = "";
			$timeout(function() {
				$scope.dashboard.dashboardData = data;
				dashboard.loadingDashboard = false;
				dashboard.noSearchValue = true;
			}, 100);
		}
		
		function provideSSN(target, item){
			var confirmCustomerWithSSN = modalService.open({
				title: 'Customer Verification',
				templateUrl: 'partials/modal_confirmCustomerWithSSN.html',
				scope: $scope,
				backdrop: 'static'
			});
			confirmCustomerWithSSN.result
				.then(function(data) {
					var person = $scope.personData;				
					person.ssn = data.ssn1 + data.ssn2 + data.ssn3;
					var modalTitle = "Confirming customer";
					modalService.processing(modalTitle);
					verifySSN(target,item, person);
			});
		}
		
		function verifySSN(target, item, personData){
			applicationService.getApplication(personData).then(function(successData){
				SharedDataSvc.globalVars.engagementId = item.engagementId; //story 26289 code changes
				target += '/'+item.engagementId;
				$location.path(target);
			}, function(failData){
				//modalService.lock(false);
				modalService.dismiss();
				void 0;
				$scope.ssnInvalid = true;
				provideSSN(target, item);
			});
		}
		
		function ssnCheckForAgreements(target, item){
			$scope.ssnInvalid = false;
			customerDataFactory.findByEngagementId(item.engagementId).then(function(customerData){
				var personData = {
					firstName: customerData.customer.fName,
					lastName: customerData.customer.lName,
					phone: customerData.customer.phNum
				};
				
				$scope.itinFlag = customerData.customer.itinFlag;
				$scope.personData = personData;
				provideSSN(target, item);					
			}, function(failData){
				void 0;
				modalService.errorModal();
			});
	
		}		
		

		////////////////////////////////////////
		/// STATUS ICONS/TEXT /////////////////
		//////////////////////////////////////

		// APPLICATION STATUS
		// ===============================================================================

		function getManualInterventionStatus(engagement, isManned, isHybrid) {
			if(isManned && !isHybrid) {
				if(engagement.overallApprovalReason.indexOf('Skip Stolen') > -1) {
					return 'S/S Inquiry';
				} else if(engagement.overallApprovalReason.indexOf('Conditional Approval') > -1) {
					return 'Verification Required';
				} else if(engagement.overallApprovalReason.indexOf('Address') > -1 && engagement.overallApprovalReason.indexOf('income') > -1) {
					return 'Income and Address Verification';
				} else if(engagement.overallApprovalReason.indexOf('Address') > -1) {
					return 'Address Verification';
				//AC23434 code changes start
				} else if(engagement.overallApprovalReason.indexOf('Outstanding balance with AcceptanceNow or Rent-A-Center') > -1) {
					return 'Outstanding balance verification';
				//AC23434 code changes end
				} else {
					return 'Income Verification';
				}
			} else {
				if(isHybrid) {
					return 'See AcceptanceNOW representative';
				}
				return 'Contact Support Center';
			}
		}

		function applicationStatusVerbiage(item) { //Story 30557 code change
			var status = item.applicationStatus, outstandingBalance = item.outstandingBalance;
			var applicationStatus = itemStatus.application;
			switch (status) {
				case applicationStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_1");
					return translatedString;
				case applicationStatus.declined:
					if(item.isCoWorkerDeclined){
						var translatedString = $translate.instant("dashboard_span_applicationStatus_7");
						return translatedString;
					} else {						
						var translatedString = $translate.instant("dashboard_span_applicationStatus_2");
						return translatedString;
					}
				case applicationStatus.accepted:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_3");
					return translatedString;
				case applicationStatus.outOfServiceArea:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_4");
					return translatedString;
				case applicationStatus.manualIntervention:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_5");
					return translatedString;
				case applicationStatus.associateReview:			
					var translatedString = $translate.instant("dashboard_span_applicationStatus_1");
					return outstandingBalance && outstandingBalance >0 ? translatedString :''; //Story 30557 code change
				case applicationStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_6");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_applicationStatus_1");
					return translatedString;
			}
		}

		function setApplicationStatusClass(status) {
			var applicationStatus = itemStatus.application;
			switch (status) {
				case applicationStatus.notStarted:
					return "process-standby"; // gray
				case applicationStatus.inProgress:
					return "process-yellow"; // yellow
				case applicationStatus.declined:
					return "process-problem itemNoEdit"; // red
				case applicationStatus.accepted:
					return "process-complete"; //green
				case applicationStatus.manualIntervention:
				case applicationStatus.associateReview:
					return "process-yellow"; // yellow
				case applicationStatus.outOfServiceArea:
					return "process-problem"; // red
				default:
					return "process-standby"; // gray
			}
		}

		function isApplicationEditable (item) {
			if (dashboard.isStoreManned && item.applicationStatus !== 'Declined') {
				//VAN-2861 Start - Disable Application edit icon when Current Available Amount is 0 or lesser than 0
				if(item.applicationStatus === 'Accepted' && item.approvalAmount <= 0 && !$scope.isSpanishStore){
					return false;
				}else{
					return true;
				}
				//VAN-2861 End
				return true;
			} else {
				if (item.applicationStatus === 'Accepted' || item.applicationStatus === 'Declined' || item.applicationStatus === 'AssocRvw' && !dashboard.overrideOption) {
					return false;
				}
				return true;
			}
		}

		// ORDER STATUS
		// ===============================================================================

		function orderStatusVerbiage(status) {
			var orderStatus = itemStatus.order;
			switch(status) {
				case orderStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_orderStatus_1");
					return translatedString;
				case orderStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_orderStatus_2");
					return translatedString;
				case orderStatus.pendingConfirm:
					var translatedString = $translate.instant("dashboard_span_orderStatus_3");
					return translatedString;
				case orderStatus.confirmed:
					var translatedString = $translate.instant("dashboard_span_orderStatus_4");
					return translatedString;
				case orderStatus.reconfirm:
					var translatedString = $translate.instant("dashboard_span_orderStatus_4");
					return translatedString;      
				default:
					var translatedString = $translate.instant("dashboard_span_orderStatus_1");
					return translatedString;      
			}
		}

		function setOrderStatusClass(status) {
			var orderStatus = itemStatus.order;
			switch(status) {
				case orderStatus.notStarted:
					return "process-standby";
				case orderStatus.inProgress:
					return "process-yellow";
				case orderStatus.pendingConfirm:
					return "process-problem";
				case orderStatus.confirmed:
					return "process-complete";
				default:
					return "process-standby";
			}
		}

		function showConfirmOrderBtn(item) {
			var currentOrderStatus = item.orderStatus,
				currentApplicationStatus = item.applicationStatus;
			if (currentOrderStatus === dashboard.itemStatus.order.pendingConfirm &&
				currentApplicationStatus === dashboard.itemStatus.application.accepted) {
				return true;
			}
			return false;
		}

		function showAddInfoBtn(item){
			var currentPaymentStatus = item.paymentStatus;
			if(dashboard.isStoreManned && !dashboard.isHybrid && item.isAdditionalDetailsNeeded && currentPaymentStatus == 'Complete' && 
				(item.deliveryStatus === dashboard.itemStatus.delivery.scheduled || item.deliveryStatus === dashboard.itemStatus.delivery.pickup)){
				return true
			}
			return false;
		}

		// AGREEMENTS STATUS
		// ===============================================================================

		function agreementStatusVerbiage(status) {
			var agreementStatus = itemStatus.agreement;
			switch(status) {
				case agreementStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_1");
					return translatedString;
				case agreementStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_2");
					return translatedString;
				case agreementStatus.accepted:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_3");
					return translatedString;
				case agreementStatus.declined:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_4");
					return translatedString;
				case agreementStatus.voided: 
					var translatedString = $translate.instant("dashboard_span_agreementStatus_5");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_agreementStatus_1");
					return translatedString;
			}
		}

		function setAgreementStatusClass(status) {
			var agreementStatus = itemStatus.agreement;
			switch(status) {
				case agreementStatus.notStarted:
					return "process-standby"; //gray
				case agreementStatus.inProgress:
					return "process-yellow"; //yellow
				case agreementStatus.accepted:
					return "process-complete itemNoEdit"; //green
				case agreementStatus.declined:
					return "process-problem itemNoEdit"; //red
				default:
					return "process-standby"; //gray
			}
		}

		// PAYMENT STATUS
		// ===============================================================================

		function paymentStatusVerbiage(status) {
			var paymentStatus = itemStatus.payment;
			switch (status) {
				case paymentStatus.pendingConfirm:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_1");
					return translatedString;
				case paymentStatus.inProgress:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_2");
					return translatedString;
				case paymentStatus.complete:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_3");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_paymentStatus_1");
					return translatedString;
			}
		}

		function setPaymentStatusClass(status) {
			var paymentStatus = itemStatus.payment;
			switch (status) {
				case paymentStatus.pendingConfirm:
					return "process-standby";
				case paymentStatus.inProgress:
					return "process-yellow";
				case paymentStatus.complete:
					return "process-complete";
			}
		}

		// DELIVERY STATUS
		// ===============================================================================

		function deliveryStatusVerbiage(status, date) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_1");
					return translatedString;
				case deliveryStatus.pendingPayment:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_2");
					return translatedString;
				case deliveryStatus.complete:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_3");
					return translatedString;
				case deliveryStatus.scheduled:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_4");
					return translatedString + moment(date).format('MM/DD/YYYY');
				case deliveryStatus.pickup:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_5");
					return translatedString + moment(date).format('MM/DD/YYYY');
				case deliveryStatus.confirmed:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_6");
					return translatedString;
				case deliveryStatus.readyToSchedule:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_7");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_deliveryStatus_8");
					return translatedString;
			}
		}

		function setDeliveryStatusClass(status) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
				case deliveryStatus.pendingPayment:
				case deliveryStatus.complete:
					return "process-standby";
				case deliveryStatus.pickup:
				case deliveryStatus.scheduled:
					return "process-yellow itemNoEdit";
				case deliveryStatus.readyToSchedule:
					return "process-problem";
				case deliveryStatus.confirmed:
					return "process-complete itemNoEdit";
				default:
			}
		}

		// SALE STATUS
		// ===============================================================================

		function saleStatusVerbiage(status) {
			var salesStatus = itemStatus.sales;
			switch(status) {
				case salesStatus.pending:
					var translatedString = $translate.instant("dashboard_span_saleStatus_1");
					return translatedString;
				case salesStatus.complete:
					var translatedString = $translate.instant("dashboard_span_saleStatus_2");
					return translatedString;
				case salesStatus.ready:
					var translatedString = $translate.instant("dashboard_span_saleStatus_3");
					return translatedString;
				case salesStatus.submitted:
					var translatedString = $translate.instant("dashboard_span_saleStatus_4");
					return translatedString;
				default:
					var translatedString = $translate.instant("dashboard_span_saleStatus_5");
					return translatedString;
			}
		}

		function setSaleStatusClass(status) {
			var salesStatus = itemStatus.sales;
			switch (status) {
				case salesStatus.pending:
					return "process-standby itemNoEdit"; //gray
				case salesStatus.complete:
					return "process-standby itemNoEdit"; //gray
				case salesStatus.ready:
					return "process-problem itemNoEdit"; //yellow				default:
				case salesStatus.submitted:
					return "process-complete itemNoEdit"; //green
				default:
					return "process-standby itemNoEdit"; //gray
			}
		}

		// OVERALL STATUS
		// ===============================================================================

		function summaryStatus(engagement) {
			// console.log(engagement.deliveryDate);
			if (engagement.applicationStatus === itemStatus.application.declined) {
				if(engagement.isCoWorkerDeclined){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_18");
					return translatedString;
				} else {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_1");
					return translatedString;
				}
			} else if (engagement.agreementStatus === itemStatus.agreement.declined) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_2");
				return translatedString;
			} else if (engagement.agreementStatus  === itemStatus.agreement.voided) { 
				var translatedString = $translate.instant("dashboard_span_summaryStatus_3");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.associateReview) {
				if (dashboard.isStoreManned) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_4");
					return translatedString;
				}
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.manualIntervention) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_6");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.outOfServiceArea) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_7");
				return translatedString;
			} else if (engagement.orderStatus === 'Pending') {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_8");
				return translatedString;
			} else if ((engagement.orderStatus === itemStatus.order.confirmed && engagement.estimatedDeliveryDate === undefined) || (engagement.estimatedDeliveryDate !== undefined && engagement.paymentStatus !== itemStatus.payment.complete)) {
				if(engagement.agreementStatus === itemStatus.agreement.notStarted){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_9");
					return translatedString;
				}else if(engagement.agreementStatus  === itemStatus.agreement.inProgress){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_10");
					return translatedString;
				}else if (engagement.paymentStatus !== itemStatus.payment.complete) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_11");
					return translatedString;
				}
				var translatedString = $translate.instant("dashboard_span_summaryStatus_12");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.confirmed) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_13");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.scheduled) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_14");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.pickup) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_15");
				return translatedString;
			} else {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			}
		}

		function summaryStatusForSortingFn(engagement) {
			if (engagement.applicationStatus === itemStatus.application.declined) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_1");
				return translatedString;
			} else if (engagement.agreementStatus === itemStatus.agreement.declined) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_2");
				return translatedString;
			} else if (engagement.agreementStatus  === itemStatus.agreement.voided) { 
				var translatedString = $translate.instant("dashboard_span_summaryStatus_3");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.associateReview) {
				if (dashboard.isStoreManned) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_4");
					return translatedString;
				}
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.manualIntervention) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_6");
				return translatedString;
			} else if (engagement.applicationStatus === itemStatus.application.outOfServiceArea) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_7");
				return translatedString;
			} else if (engagement.orderStatus === 'Pending' && engagement.applicationStatus == itemStatus.application.accepted) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_8");
				return translatedString;
			} else if (engagement.orderStatus === itemStatus.order.confirmed && engagement.estimatedDeliveryDate === undefined) {
				if (engagement.paymentStatus !== itemStatus.payment.complete) {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_11");
					return translatedString;
				}
				/*if(showGenerateCreditCardBtn(engagement)){
					return 'Generate Credit Card';
				}else*/ if(showScheduleDeliveryCta(engagement)){
					var translatedString = $translate.instant("dashboard_span_summaryStatus_16");
					return translatedString;
				}
				else {
					var translatedString = $translate.instant("dashboard_span_summaryStatus_12");
					return translatedString;
				}
			} else if (engagement.deliveryStatus === itemStatus.delivery.confirmed) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_17");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.scheduled) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_14");
				return translatedString;
			} else if (engagement.deliveryStatus === itemStatus.delivery.pickup) {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_15");
				return translatedString;
			} else {
				var translatedString = $translate.instant("dashboard_span_summaryStatus_5");
				return translatedString;
			}
		}

		function statusIcon(engagement) {
			if (engagement.applicationStatus === itemStatus.application.declined
				|| engagement.agreementStatus === itemStatus.agreement.declined
				|| engagement.agreementStatus === itemStatus.agreement.voided) {//AC 25996 updated code changes
				return 'icon-declined';
			} else if (engagement.applicationStatus === itemStatus.application.manualIntervention
				|| engagement.applicationStatus === itemStatus.application.associateReview) {
				return 'icon-manualintervention';
			} else if (engagement.applicationStatus === itemStatus.application.outOfServiceArea) {
				return 'icon-outofservicearea';
			} else if (engagement.orderStatus === itemStatus.order.pendingConfirm) {
				return 'icon-confirm';
			} else if (engagement.orderStatus === itemStatus.order.confirmed
				&& engagement.estimatedDeliveryDate !== undefined) {
				return 'icon-delivery';
			} else if (engagement.deliveryStatus === itemStatus.delivery.confirmed) {
				return 'icon-complete';
			} else if (engagement.deliveryStatus === itemStatus.delivery.scheduled) {
				return 'icon-delivery';
			} else if (engagement.deliveryStatus === itemStatus.delivery.pickup) {
				return 'icon-delivery';
			} else {
				return '';
			}
		}

		function summaryStatusSortFn(item){
			var status = summaryStatus(item); //summaryStatusForSortingFn(item);
			var sortMap = {
				en : {
					'Pending Order Confirmation': 1,
					'Associate Review': 2,
					'Generate Credit Card': 3,
					'Schedule Delivery':4,
					'Pending Delivery Confirmation': 5,
					'Pending Pickup Confirmation': 6,
					'Pending Delivery Date': 7,
					'Pending Payment': 8,
					'Manual Review': 9,
					'Out of Service Area': 10,
					'In Progress': 11,
					'Complete': 12,
					'Agreement Declined': 13,
					'Declined': 14
				},
				es : {
					'Confirmación de pedido pendiente': 1,
					'Revisión del Asociado': 2,
					'Genere la tarjeta de crédito': 3,
					'Fecha de entrega':4,
					'Confirmación de entrega pendiente': 5,
					'Confirmación de recogida pendiente': 6,
					'Fecha de entrega pendiente': 7,
					'Pago pendiente': 8,
					'Revisión manual': 9,
					'Fuera del área de servicio': 10,
					'En progreso': 11,
					'Completo': 12,
					'El Contrato ha sido rechazado': 13,
					'Rechazada': 14
				}
			};
			var val = $rootScope.selectedLanguage == 'es' ? sortMap.es[status] : sortMap.en[status];
			return val;
		}


		////////////////////////////////////////////////
		//  METHODS  ///////////////////////////////////
		////////////////////////////////////////////////
		//VAN - 1430 - IE Performance starts
		window.onscroll = function() {loadMore()};
		$scope.totalDisplayed = 20;

		function loadMore() {
			if(dashboard.dashboardData !== undefined) {
				if ($scope.totalDisplayed < dashboard.dashboardData.length) {
					$scope.totalDisplayed += 20;  
				}
			}
		};

		function detailsView(item) {
			if($scope.detailsView === true && $scope.val !== undefined && $scope.val === item)
			{
				$scope.detailsView = false;
			}
			else
			{
				$scope.detailsView = true;			
			}
			$scope.val= item;
		}
			//VAN - 1430 - IE Performance ends
		function navigateTo(target, engagement) {
			void 0;
			var allowContinue = false,
				completeStr = itemStatus.payment.complete;
			engagement = engagement || {};
			if (engagement.engagementId) {
				storeCurrentEngagement(engagement.engagementId);
			}
			//AC 25996 code changes starts
			if (target === 'agreement' || target === 'digitalAgreement') {
				//AC 22565 code changes  - Added showAgreementsPage flag for agreement status voided and agreementhtid null
				if (engagement.applicationStatus === itemStatus.application.accepted && engagement.orderStatus === itemStatus.order.confirmed && engagement.paymentStatus != completeStr && (dashboard.showAgreementsPage || ( engagement.agreementStatus != itemStatus.agreement.voided && engagement.agreementStatus != itemStatus.agreement.declined))) {//AC 25996 updated code changes
					if(engagement.agreementStatus === itemStatus.agreement.accepted && engagement.agreementOriginator && engagement.agreementOriginator.toLowerCase() == 'point of sale'){
						$rootScope.successMsgDocusign = $rootScope.selectedLanguage == 'es' ? es.modal_afterDocusignSigningSuccess_desc3 : en.modal_afterDocusignSigningSuccess_desc3;
						modalService.open({
							windowClass: '',
							templateUrl: 'partials/modal_afterDocusignSigningSuccess.html',
							//backdrop: 'static'
						});
						return false;
					}
					allowContinue = true;
				} else if((engagement.orderStatus === itemStatus.order.confirmed && engagement.paymentStatus == completeStr)||engagement.orderStatus !== itemStatus.order.confirmed || engagement.applicationStatus !== itemStatus.application.accepted){
					allowContinue = false;
				} else {
					if($rootScope.xpartnerLogin){//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					//VAN-3209-Use customers zip code on agreement creation - Starts
					if(engagement && engagement.customerState && engagement.agreementOriginator != "Point of Sale" && (engagement.applicationStatus  != itemStatus.application.inProgress || engagement.applicationStatus  != itemStatus.application.declined)){
						if(engagement.customerState != dashboard.storeInfo.state){
							$scope.invalidState = modalService.open({
								title: 'State is invalid',
								templateUrl: 'partials/alert_invalidStateError.tpl.html',
								backdrop: 'static',
								size: 'sm',
								scope: $scope
							});
							return;
						}
					}
					//VAN-3209-Use customers zip code on agreement creation - Ends
					}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					checkForAgreementData(target, engagement);
					return;
				} //AC 25996 code changes ends
			} else if (target === 'epay') {
				if (engagement.applicationStatus === itemStatus.application.accepted && engagement.orderStatus === itemStatus.order.confirmed && engagement.agreementStatus === itemStatus.agreement.accepted && engagement.paymentStatus != completeStr) {
					if(engagement.agreementOriginator && engagement.agreementOriginator.toLowerCase() == 'point of sale'){
						$rootScope.successMsgDocusign = $rootScope.selectedLanguage == 'es' ? es.modal_afterDocusignSigningSuccess_desc4 : en.modal_afterDocusignSigningSuccess_desc4;
						modalService.open({
							windowClass: '',
							templateUrl: 'partials/modal_afterDocusignSigningSuccess.html',
							//backdrop: 'static'
						});
						return false;
					}
					allowContinue = true;
				}
			} else if (target === 'delivery') {
				if (engagement.paymentStatus === completeStr || (!SharedDataSvc.globalVars.storeSetup.immediateInvoicing && (engagement.deliveryStatus.toLowerCase() == 'scheduled' || engagement.deliveryStatus.toLowerCase() == 'pickup') && engagement.orderStatus.toLowerCase() == 'confirmed')) {
				//Comment - Delivery Confirmation - PR override changes
					/*if($scope.isSpanishStore && (engagement.verifyInfoAssociateName === undefined || engagement.verifyInfoAssociateName.length <= 0)) {
						showVerifyInfoAlert();
					}
					else {*/
						showDeliveryModal(engagement);
//					}
					return;
				}
			} else if (target === "ordersummary") {
				//AC 25996 code changes starts
				if((dashboard.showOrderSummary) || (engagement.paymentStatus != itemStatus.payment.complete && (engagement.agreementStatus === itemStatus.agreement.declined || engagement.agreementStatus === itemStatus.agreement.voided)) || (engagement.applicationStatus === itemStatus.application.inProgress)){//AC 25996 & AC 14458 updated code changes
					allowContinue = true;
				} else if((engagement.orderStatus === itemStatus.order.confirmed && engagement.paymentStatus == completeStr) || (engagement.applicationStatus === itemStatus.application.declined) || (engagement.applicationStatus === itemStatus.application.outOfServiceArea)){
					allowContinue = false;
				} else {
					if($rootScope.xpartnerLogin){//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					//VAN-3209-Use customers zip code on agreement creation - Starts
					if(engagement && engagement.customerState && engagement.agreementOriginator != "Point of Sale" && (engagement.applicationStatus  != itemStatus.application.inProgress || engagement.applicationStatus  != itemStatus.application.declined)){
						if(engagement.customerState != dashboard.storeInfo.state){
							$scope.invalidState = modalService.open({
								title: 'State is invalid',
								templateUrl: 'partials/alert_invalidStateError.tpl.html',
								backdrop: 'static',
								size: 'sm',
								scope: $scope
							});
							return;
						}
					}
					//VAN-3209-Use customers zip code on agreement creation - Ends
					}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
					checkForSLICustomer(target, engagement);
					return;
					//AC 25996 code changes ends
				}
			} else if (target === "dashboard") {
				$route.reload();
				return;
			}
			else {
				allowContinue = true;
			}

			if(allowContinue){
				$rootScope.hideDashboardButton = true;
				$rootScope.hideTrainingLibButton = false;
				$rootScope.hideDashboardAgreementButton = false;
				$rootScope.invoiceMethod = SharedDataSvc.globalVars.storeSetup.invoiceMethod;
				if($rootScope.xpartnerLogin){//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				//VAN-3209-Use customers zip code on agreement creation - Starts
				if(engagement && engagement.customerState && engagement.agreementOriginator != "Point of Sale" && (engagement.applicationStatus  != itemStatus.application.inProgress || engagement.applicationStatus  != itemStatus.application.declined)){
					if(engagement.customerState != dashboard.storeInfo.state){
						$scope.invalidState = modalService.open({
							title: 'State is invalid',
							templateUrl: 'partials/alert_invalidStateError.tpl.html',
							backdrop: 'static',
							size: 'sm',
							scope: $scope
						});
						return;
					}
				}
				//VAN-3209-Use customers zip code on agreement creation - Ends
				}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				
				if((target == "agreement" || target == 'digitalAgreement') && (!dashboard.isStoreManned || $cookies.getObject("isHybrid"))){
					void 0;
					ssnCheckForAgreements(target, engagement);	
				}else{
					if (engagement.engagementId) {
						SharedDataSvc.globalVars.engagementId = engagement.engagementId; //story 26289 code changes
						target += '/'+engagement.engagementId;
					}
					$location.path(target);
				}
			}else{
				modalService.open({
					templateUrl: 'partials/modal_enforceOrder.html',
					windowClass: 'enforce-order',
					scope: $scope
				});
			}
		}

		$scope.sortByClick = function () {
			if(dashboard.order == 'Date') {
				dashboard.sortingOrder = ['-modifiedDateStamp'];
			}else if(dashboard.order == 'Name') {
				dashboard.sortingOrder = ['customerLastName', 'customerFirstName', '-modifiedDateStamp'];
			} else{
				dashboard.sortingOrder = [dashboard.summaryStatusSortFn, '-modifiedDateStamp'];
			}
		}

		$scope.sortByClick(dashboard.order);

		//show schedule delivery associate pass check modal
		function showModalDeliveryPass(name, windowClass) {
	      	windowClass = windowClass || '';
	      	modalService.open({
		        templateUrl: '/partials/modal_' + name + '.html',
		        backdrop: 'static',
		        windowClass: windowClass,
		        controller: 'delivery',
		        controllerAs: 'delivery',
		        scope: $scope
		   	});
	    }

		//Story 33452 changes starts
		function checkForSLICustomer(target,engagement){
			var personData={};
			personData.firstName = engagement.customerFirstName;
			personData.lastName = engagement.customerLastName,
			personData.phone = engagement.customerPhoneNum,
			personData.disableHT = true;
			applicationService.getApplication(personData)
			.then(function(response){
				void 0;
				//Defect 28370	- From AWP/Kiosk order link is not clickable - Starts
				if(response.agreement){
					SharedDataSvc.globalVars.htAgreementStatus = response.agreement.agreementStatus;
					void 0;}
				//Defect 28370	- From AWP/Kiosk order link is not clickable - Ends
				//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Starts
				if (SharedDataSvc.globalVars.htAgreementStatus != null && SharedDataSvc.globalVars.htAgreementStatus != undefined && SharedDataSvc.globalVars.htAgreementStatus === 'ACTIVE'){
					void 0;
					var wrongvendor = modalService.open({
						show: true,
						templateUrl: 'partials/alert_activeAgreementErrorAtOrder.tpl.html',
						backdrop: 'static'         
					});
					return;
				}
				//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Ends
				if(response.VanEngagement && response.VanEngagement.customer){
					if(response.VanEngagement.customer.customerOriginator && 
							response.VanEngagement.customer.customerOriginator == "SLI" || 
							response.VanEngagement.customer.customerOriginator == "PreApprove" || //VAN-510 - Electronic Consent moved to app flow -Allow NEw/Existing Customer Popup for VA customers.
							response.VanEngagement.customer.customerOriginator == "HighTouch")  // VAN-813 New originator for HighTouch for HT originated approvals
					{
						if(response.VanEngagement.orderContent){
							checkForAgreementData(target,engagement);
						}else{
							//show new/existing customer popup without start appln and email
							SharedDataSvc.globalVars.isApplicationFromOrder = true;
							SharedDataSvc.globalVars.currentSLICustEngId = response.VanEngagement.engagementId;
							$location.path('application/');
						}
					}  
					else {
						checkForAgreementData(target,engagement);
					}
				} else {
					checkForAgreementData(target,engagement);
				}
			});
		}
		//Story 33452 changes ends
		//AC 25996 code changes starts
		function checkForAgreementData(target, engagement) {
			var customerData;
			customerDataFactory.findByEngagementId(engagement.engagementId)
					.then(function(response){
						customerData = response;
						if(target == "ordersummary") {
							void 0;
							if(customerData.agreementHTId == null && engagement.paymentStatus != itemStatus.payment.complete && (engagement.agreementStatus === itemStatus.agreement.notStarted || engagement.agreementStatus === itemStatus.agreement.inProgress)){
								dashboard.showOrderSummary = true;
								navigateTo('ordersummary',engagement);
							}else if (customerData.orderStatus === itemStatus.order.confirmed && (customerData.agreementStatus !== itemStatus.agreement.declined && customerData.agreementStatus !== itemStatus.agreement.voided) && customerData.agreementHTId != undefined && customerData.agreementHTId != "" && customerData.paymentStatus != itemStatus.payment.complete) {//AC 25996 updated code changes
								dashboard.engagement = {};
								var engagementProps = [
									'engagementId',
									'orderStatus',
									'deliveryStatus',
									'paymentStatus',
									'salesStatus',
									'agreementStatus',
									'applicationStatus',
									'orderOriginator'
								];
								// cherry pick the needed properties for a proper engagement
								for (var i = 0; i < engagementProps.length; i++) {
									dashboard.engagement[engagementProps[i]] = response[engagementProps[i]];
								}
								dashboard.engagement['customerId'] = response.customer.customerId;
								dashboard.engagement['customerHtID'] = response.customerHTId;
								dashboard.engagement['customerHtStoreId'] = response.customerHTStoreId;
								dashboard.engagement['vanStoreId'] = response.vanStore.storeId;
								dashboard.declineAgreementModal = modalService.open(declineAgreementModalOptions(engagement));
								}

						} else if(target == "agreement" || target == 'digitalAgreement') {
							dashboard.agreementHtId = customerData.agreementHTId;
							//AC 25996 code changes
							if(engagement.agreementStatus === itemStatus.agreement.declined || engagement.agreementStatus === itemStatus.agreement.voided){//AC 25996 updated code changes
								dashboard.currentEngagement = engagement;
								modalService.open({
									templateUrl: 'partials/modal_modifyOrder.html',
									scope: $scope,
									backdrop: 'static'// AC 25996 updated code changes
								})
							}
							else {
								dashboard.showAgreementsPage = true; // AC 22565 code changes
								navigateTo(target, engagement);
							}
						}
						//Defect 28370	- From AWP/Kiosk order link is not clickable - Starts
						if(SharedDataSvc.globalVars.htAgreementStatus === 'ACTIVE' && engagement.agreementStatus === itemStatus.agreement.accepted && customerData.paymentStatus == itemStatus.payment.complete){
							void 0;
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_activeAgreementError.tpl.html',
								backdrop: 'static'         
							});    
							navigateTo('dashboard');
						}
						//Defect 28370	- From AWP/Kiosk order link is not clickable - Ends
					}).catch(function(response){
				void 0;
			});
		}
//AC24649 code changes
		function continueToAgreement(){
			dashboard.engagement = {};
			var engagementProps = [
				'engagementId',
				'orderStatus',
				'deliveryStatus',
				'paymentStatus',
				'salesStatus',
				'agreementStatus',
				'applicationStatus',
				'orderOriginator',
				'vanStoreId',
				'customerId'
			];

			for (var i = 0; i < engagementProps.length; i++) {
				dashboard.engagement[engagementProps[i]] = dashboard.currentEngagement[engagementProps[i]];
			}

			dashboard.modalTitle = 'Processing';
			dashboard.processingModal = modalService.processing(dashboard.modalTitle);
			dashboard.engagement.agreementStatus = 'Pending';
			updateEngagementItemFactory.putData(dashboard.engagement)
				.then(function(response) {
					void 0;
					modalService.dismiss();
					navigateToAgreement(dashboard.engagement);
					//navigateTo('agreement',dashboard.engagement);
				}, function(response){
					void 0;
					modalService.dismiss();
				});
		}//AC24649 code changes ends
		function modifyOrderSummary() {
			navigateTo('ordersummary',dashboard.currentEngagement);
		}

		//Defect 28370	- From AWP/Kiosk order link is not clickable - Starts
		function activeAgreementErrorAlert(engagement) {
			return {
				templateUrl: 'partials/alert_activeAgreementError.tpl.html',
				//controller: '',
				scope: $scope,
				backdrop: 'static'//AC 25996 updated code changes
			}
		}
		function activeAgreementError(engagement){
			void 0;
			dashboard.modalTitle = 'Processing';
			dashboard.processingModal = modalService.processing(dashboard.modalTitle);
			engagement.paymentStatus = 'Complete';
			updateEngagementItemFactory.putData(engagement)
					.then(function(response) {
						void 0;
						modalService.dismiss();
						navigateTo('dashboard');
					}, function(response){
						void 0;
						modalService.dismiss();
					});
		
		}
		//Defect 28370	- From AWP/Kiosk order link is not clickable - Ends
		
		function declineAgreementModalOptions(engagement) {
			return {
				templateUrl: 'partials/modal_agreementDecline_editOrder.html',
				//controller: '',
				scope: $scope,
				backdrop: 'static'//AC 25996 updated code changes
			}
		}

		//Story 29237 code changes starts
		function declineAgreement(engagement){
			SharedDataSvc.globalVars.voidAgreementData = engagement;
			dashboard.modalTitle = 'Processing';
			dashboard.processingModal = modalService.processing(dashboard.modalTitle);
			var engagement = SharedDataSvc.globalVars.voidAgreementData;
			engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(engagement)
					.then(function(response) {
						void 0;
						modalService.dismiss();
						dashboard.showOrderSummary = true;
						navigateTo('ordersummary',engagement);
					}, function(response){
						void 0;
						modalService.dismiss();
					});
		
		}

		function cancelAgreementDecline() {
			navigateTo('dashboard');
		}

	    //handle modal as per delivery or pickup
		function showDeliveryModal(engagement) {
			SharedDataSvc.deliveryTempId = engagement.engagementId;
			dashboard.deliveryTempId = engagement.engagementId;
			SharedDataSvc.invoiceDeliveryData.engagement = engagement;
			if(SharedDataSvc.globalVars.storeSetup.immediateInvoicing) {
				dashboard.myDeliveryModal = modalService.open(deliveryModalOptions(engagement));
				dashboard.myDeliveryModal
					.result.then(
					function (result) {
						//console.log('[Delivery Modal complete]', result);
						if (result && result.status === 'confirm') {
							showDeliveryConfirmation(result.data);
							void 0;
						} else if(result && result.status === 'pickup') {
						//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
							if($rootScope.xpartnerLogin)
							{
							setDeliveryDate();
							}
							else
								{
							showModalDeliveryPass('scheduleDeliveryPass');
							void 0;
								}
						//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
						}
					},
					function (result) {
						void 0;
					}
				)
			} else {
				dashboard.myDeliveryModal = modalService.open(deliveryModalOptions(engagement, "OnConfirmOrder", "no-Invoice-Modal"));
			}
		}

		//address confirm modal for schedule delivery
		function showDeliveryConfirmation(engagement) {
			var deliveryConfirmModal = modalService.open(deliveryModalOptions(engagement, 'Confirm'));
			deliveryConfirmModal
				.result.then(
				function (result) {
					if (result && result.status === 'confirm') {
					//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
						if($rootScope.xpartnerLogin)
							{
							setDeliveryDate();
							}
						else
							{
						showModalDeliveryPass('scheduleDeliveryPass');
						void 0;
							}
					//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
					}					
				},
				// dismissed
				function (result) {
					if (result && result.status === 'declined') {
						modalService.open({
							templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html',
							scope: $scope
						});
					}
				}
			)
		}

		function deliveryModalOptions(engagement, deliveryPart, windowClass) {
			deliveryPart = deliveryPart || '';
			windowClass = windowClass || '';
			return {
				templateUrl: 'partials/modal_invoiceAndDelivery'+deliveryPart+'.html',
				controller: 'delivery',
				controllerAs: 'delivery',
				windowClass : windowClass,
				scope: $scope
			}
		}

		function showModal(name, windowClass, controller, controllerAs) {
			windowClass = windowClass || '';
			var modal = modalService.open({
				// title: 'Schedule Delivery',
				templateUrl: '/partials/modal_' + name + '.html',
				scope: $scope,
				backdrop: 'static',
				controller: controller || 'manualOverride',
				controllerAs: controllerAs || 'override',
				windowClass: windowClass || 'override-initial-modal',
				show: true
			});
			SharedDataSvc.activeModal = modal;
		}

		function setDeliveryDate() {
			showProcessingModal();//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date
			dashboard.viewLoading = true;
			var thisId = SharedDataSvc.deliveryTempId;
			SharedDataSvc.globalVars.order.currentEngagement.engagementId = thisId;
			SharedDataSvc.globalVars.order.deliveryDate=moment(SharedDataSvc.invoiceDeliveryData.date).format('YYYY-MM-DD') + ' 00:00';//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date
			//SharedDataSvc.globalVars.order.deliveryDate = moment(dashboard.dashboardData.estimatedDeliveryDate).format('YYYY-MM-DD') + ' 00:00';
			//console.log('557 delivery date='+ SharedDataSvc.globalVars.order.deliveryDate);
			// SAVE delivery date
			var preO = _.where(SharedDataSvc.globalVars.dashboardListCache, {
				engagementId: Number(thisId)
			});
			//var oData = _.pluck(preO, '');
			//var oData = ;
			// go grab engagement data + orderItems
			updateEngagementItemFactory.getData(SharedDataSvc.globalVars.order.currentEngagement.engagementId).then(function (response) {
				// console.log('564_gottenData='+angular.toJson(response));
				// response.engagementId
				// response.estimatedDeliveryDate
				// SharedDataSvc.globalVars.order.currentEngagement = response.data;
				// re-64 the order items
				// add new deliveryDate
				dashboard.tempEngagementData = response;
				dashboard.tempEngagementData.estimatedDeliveryDate = SharedDataSvc.globalVars.order.deliveryDate;
				dashboard.tempEngagementData.deliveryStatus = 'Scheduled';
				//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
				SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
			      if(SharedDataSvc.invoiceDeliveryData.type === 'pickup') {
			        
			    	dashboard.tempEngagementData.deliveryStatus = "Pickup";
			        SharedDataSvc.invoiceDeliveryData.deliveryType = "Pickup";
			      } else {
			    	  dashboard.tempEngagementData.deliveryStatus = "Scheduled";
			        SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
			      }
			      if(SharedDataSvc.globalVars.storeSetup.immediateInvoicing) {
			    	  dashboard.tempEngagementData.salesStatus = "Submitted";
			    	  dashboard.tempEngagementData.invoiceNum = SharedDataSvc.invoiceDeliveryData.invoiceNum;
			    	  dashboard.tempEngagementData.salesAssociateID = SharedDataSvc.invoiceDeliveryData.associateId;  
			      }  
			    //VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
			      var oData = dashboard.tempEngagementData;
				// console.log('engagement data',oData);
				updateEngagementItemFactory.putData(oData).then(function (response) {
					//console.log('574_oData PUT='+angular.toJson(oData));
					//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
					void 0;
		            SharedDataSvc.globalVars.order.currentEngagement.deliveryStatus = SharedDataSvc.invoiceDeliveryData.deliveryType;
		            if(dashboard.immediateInvoicing) {
		              SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
		            }
		            if(response.estimatedDeliveryDate) {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.globalVars.order.deliveryDate;
		            }
		            //AC 32805 code changes starts
		            if(response.invoiceNum){
		            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceNum;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
		            }
		            //AC 32805 code changes ends
		            //dashboard.viewLoading = false;
		            dashboard.processingModal.dismiss();
		            dashboard.myDeliveryModal.dismiss();
		            //VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
				}, function (response) {
					void 0;
				});
				// change delivery status to 'Scheduled'
			}).error(function(response) {
				void 0;
				dashboard.viewLoading = false;
			});

			SharedDataSvc.globalVars.order.currentEngagement.engagementId = thisId;
		};

		function getApplication(engagement) {
			var person = {
				firstName: engagement.customerFirstName,
				lastName: engagement.customerLastName,
				phone: engagement.customerPhoneNum
			};
			var personData = window.btoa(JSON.stringify(person));
			$location.path('application/'+engagement.engagementId).search({data: personData});
		}
	//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Starts
		function showProcessingModal () {
	        $rootScope.modalTitle = 'Processing';
	        dashboard.processingModal = modalService.open({
	          templateUrl: '/partials/modal_Processing.html',
	          title: $scope.title,
	          backdrop: 'static',
	          windowClass: 'processing-modal',
	          show: true
	        });
	    }
	//VAN-3229-AWP - Remove associate password popup for user login with XPARTNER during  entering estimated delivery date-Ends
	    
		function storeCurrentEngagement(itemID) {
			void 0;
			var selectedEngagement = _.find(dashboard.dashboardData, {
				engagementId: itemID
			});
			void 0;
			SharedDataSvc.globalVars.order.currentEngagement = selectedEngagement;
			SharedDataSvc.globalVars.order.currentEngagementId = itemID;
			SharedDataSvc.globalVars.order.currentEngagement.vanStoreId = SharedDataSvc.vanStoreId;
			//console.log('539_sds currEng = '+angular.toJson(SharedDataSvc.globalVars.order.currentEngagement));
		};
		// item.customerFirstName, item.customerLastName, item.customerPhoneNum, item.engagementId
		// Skip Stolen Record Found
		function editApplication(engagement) {
			//engagement.overallApprovalReason = "Conditional Approval;";
			void 0;
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			void 0;
			SharedDataSvc.manualOverrideData = engagement;
			
			/* VAN 456 -Add customer's name to manual intervention verification title*/
			$scope.customerName = SharedDataSvc.manualOverrideData.customerFullName;
			resetManualOverrideData();
			if(engagement.applicationStatus === 'Accepted' && dashboard.isStoreManned && storeInfo.overrideOption) {
				/*VAN 272 - VAN - Associate Manual Override screen for PR - Starts*/
				$scope.showOverridePRCosChange = true;
				if(storeInfo.state=="PR" && SharedDataSvc.manualOverrideData.approvalAmount==0){
					$scope.showOverridePRCosChange = false;
				}
				/*VAN 272 - VAN - Associate Manual Override screen for PR - Ends*/
				showModal('overrideInitial');
			} else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Address Verification did not match') > -1) {
				showModal('reviewAddress', 'associate-review-modal');
				return;
			} else if(engagement.overallApprovalReason && 
				(engagement.overallApprovalReason.indexOf('Reported income outside of allowable range') > -1) && 
				!(engagement.overallApprovalReason.indexOf('Skip Stolen') > -1 ) &&
				!(engagement.overallApprovalReason.indexOf('Conditional Approval') > -1 )) {
					
				showModal('reviewIncome', 'associate-review-modal');
				return;
				
			}	else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Skip Stolen') > -1) {
				if(engagement.applicationStatus !== 'Declined') {
					showModal('skipStolen', 'associate-review-modal');
				}
				return;
			}	else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Conditional Approval') > -1) {
				if(engagement.applicationStatus !== 'Declined') {

					/*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
					$scope.declineReasonsList =[];
					if( _(engagement).has('verifyReasons')){
							if((engagement.verifyReasons).indexOf("Verify References (Min. 2)") > -1){
								modalService.processing(dashboard.modalTitle);
								applicationService.getApplicationByEngagement(engagement)
								.then(function(response){
									modalService.dismiss();
									void 0;
									conditionalApprovalAssocReviewData(engagement,response);
								},function(failData){
									modalService.dismiss();
									$rootScope.modalMessage = failData.data.errorData.status_message;
									var modal = modalService.open({
										templateUrl : '../../../partials/alert_serviceError.tpl.html',
										backdrop : 'static',
										scope : $scope
									});
								});
							}else{
								conditionalApprovalAssocReviewData(engagement,"");
							}
					}else{
						showModal('conditionalApproval', 'associate-review-modal');
					}
					/*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
					return;
				}
			}
			//AC23434 code changes start
			else if(engagement.overallApprovalReason && engagement.overallApprovalReason.indexOf('Outstanding balance with AcceptanceNow or Rent-A-Center') > -1) {
				if(engagement.applicationStatus !== 'Declined') {
					showModal('outstandingBalance', 'associate-review-modal');
				}
				return;
			}
			//AC23434 code changes end
			else {
				if(engagement.applicationStatus !== 'Declined' && engagement.applicationStatus !== 'Accepted') {
					$rootScope.applicationLink = true//PRB0042346 - Application stuck inprocess due to different phone number
					getApplication(engagement);
				}
			}
		}
		
		/*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
		function conditionalApprovalAssocReviewData(engagement,response){
			$scope.showCustAddress = false;
			$scope.showCustReferences= false;
			if(engagement.verifyReasons.length>0){
				for(var i=0;i<engagement.verifyReasons.length;i++){
				if(engagement.verifyReasons[i].indexOf("Verify Address") > -1){
					$scope.showCustAddress = true;
					$scope.verifyAddress = engagement.verifyReasons[i];
					$scope.customerAddress1 =engagement.customerAddress1;
					$scope.customerAddress2 = engagement.customerCity+","+engagement.customerState+","+engagement.customerZipCode;
				}else if(engagement.verifyReasons[i].indexOf("Verify References (Min. 2)") > -1){
						$scope.showCustReferences= true;
						$scope.verifyReference = engagement.verifyReasons[i];
						$scope.referenceList =[];
						if(response!=undefined && response!=""){
							for(var r=0;r<response.personalReferences.length;r++){
								/*MI screen showing empty reference value for two reference objects- Starts*/
								if(response.personalReferences[r].firstName!="" && response.personalReferences[r].lastName!="" &&
										response.personalReferences[r].phone.areaCode!="" && response.personalReferences[r].phone.number!=""){
								var custPersonalReferences = response.personalReferences[r].firstName+" "+response.personalReferences[r].lastName+","
								+"             "+'('+response.personalReferences[r].phone.areaCode+')'+'-'+response.personalReferences[r].phone.number;
								$scope.referenceList.push(custPersonalReferences);
								}
								/*MI screen showing empty reference value for two reference objects - Ends*/
							}
						}
				}
				else{
					var tempObj = {
							"isSelected": false,
							"reason": engagement.verifyReasons[i]
					}
					$scope.declineReasonsList.push(tempObj);
				}
			}
			showModal('conditionalApprovalAssociateReview', 'associate-review-modal');
			}
		}
		/*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
		
		function showSearchIndicator(engagement){
			SharedDataSvc.manualOverrideData = engagement;
			$rootScope.searchIndicatorDetails = {};
			modalService.processing($translate.instant("common_label_processing")); 
			dashboardServices.getCustomerReview(engagement.customerId)
	        .then(function(response){
	        	modalService.dismiss();
				$rootScope.searchIndicatorDetails = {
					searchDetails: response.van41SearchDetail,
					searchDetailsInactive: response.inactiveAgreements,
					//searchDetailsInactive: response.van41SearchDetail,
					getLossInfo: response.getLossInfo,
					orderStatus : summaryStatus(engagement),
					paymentStatus: engagement.paymentStatus,
					overallApprovalReason : engagement.overallApprovalReason
				};
				if($rootScope.searchIndicatorDetails.orderStatus == 'Declined' || $rootScope.searchIndicatorDetails.orderStatus == 'Declined by Coworker'){
					$rootScope.searchIndicatorDetails.hideDecline = true;
				} /*else if(!$rootScope.searchIndicatorDetails.searchDetails || 
					$rootScope.searchIndicatorDetails.searchDetails.length <= 0){
					$rootScope.searchIndicatorDetails.hideDecline = true;
				} */
				else {
					$rootScope.searchIndicatorDetails.hideDecline = false;
				}
				showModalSearchIndicator('searchIndicatorDetails', 'associate-review-modal');
			}, function(error){
				void 0;
			});
		}

		function resetManualOverrideData() {
			// Reset override amount
			manualOverrideFactory.approvalModel.requestedAmount = null;
			// Reset skip stolen fields
			manualOverrideFactory.associateReview.employeeName = '';
			manualOverrideFactory.associateReview.employeePass = '';
			manualOverrideFactory.associateReview.verifyExplain = '';
			manualOverrideFactory.associateReview.verifyEmployment = false;
			manualOverrideFactory.associateReview.verifyReference = false;
			manualOverrideFactory.associateReview.verifyAddress = false;
			manualOverrideFactory.approvalModel.employeeName = '';
			manualOverrideFactory.approvalModel.rightSelling = false;
			manualOverrideFactory.approvalModel.verifyLease = false;
			// Reset associate review
			manualOverrideFactory.associateReviewverifyEmployment = null;
			manualOverrideFactory.associateReview.verifyReference = null;
			manualOverrideFactory.associateReviewverifyAddress = null;
			manualOverrideFactory.associateReview.notVerified = false;
			manualOverrideFactory.associateReview.summary = null;
			manualOverrideFactory.associateReview.checkedDl = false;
			manualOverrideFactory.associateReview.confirmDl = false;
			manualOverrideFactory.associateReview.calledLandlord = false;
			manualOverrideFactory.associateReview.confirmUtility = false;
			manualOverrideFactory.associateReview.other = false;
			manualOverrideFactory.associateReview.verifyExplain = "";
			manualOverrideFactory.associateReview.verifyIncome = false;
			manualOverrideFactory.associateReview.employeeName = null;
			manualOverrideFactory.associateReview.employeePass = undefined;
			manualOverrideFactory.associateReview.employeePassSubmitted = false;
		}

		function editDelivery(engagement) {
			void 0;
			SharedDataSvc.manualOverrideData = engagement;
			showModal('invoiceAndDelivery');
			// showScheduleDeliveryCta(engagement);
		}

		function showScheduleDeliveryCta(engagement) {
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			if(storeInfo.invoiceMethod !== undefined && storeInfo.invoiceMethod.toLowerCase() === 'creditcardonpayment') {
				if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.paymentStatus == 'Complete') {
					if(engagement.vccTransactionId !== undefined && engagement.vccTransactionId.trim() !== "") {
						if(engagement.deliveryStatus == 'Pending' || engagement.deliveryStatus == 'ReadySched') {
							return true;
						}
					}
				}
			} else if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.paymentStatus == 'Complete') {
				if(engagement.deliveryStatus == 'Pending' || engagement.deliveryStatus == 'ReadySched') {
					return true;
				}
			}
			
			return false;
			// item.deliveryStatus === 'Scheduled' || item.deliveryStatus === 'Pickup'
		}

		// format Date
		function formatDateGenerateCard (date){
			return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
		}

		// check for displaying Generate Credit Card button
		/*function showGenerateCreditCardBtn(engagement) {
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			if(!storeInfo.isBarcodeEnabled && storeInfo.invoiceMethod !== undefined && storeInfo.invoiceMethod.toLowerCase() === 'creditcardonpayment') {
				if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.paymentStatus == 'Complete' && (engagement.deliveryStatus.toLowerCase() == 'scheduled' || engagement.deliveryStatus.toLowerCase() == 'pickup')) {
					return true;
				}
			}
			return false;
		}*/

		// check for displaying View/Print button for VCC details
		function showViewPrintVccBtn (engagement) {
			var storeInfo = SharedDataSvc.globalVars.storeSetup;
			if(storeInfo.isBarcodeEnabled && storeInfo.invoiceMethod !== undefined && !engagement.barcodeExpired) {
				if(engagement.orderStatus === dashboard.itemStatus.order.confirmed && 
					engagement.paymentStatus == dashboard.itemStatus.payment.complete && 
					(engagement.deliveryStatus.toLowerCase() == 'scheduled' || engagement.deliveryStatus.toLowerCase() == 'pickup')) {
					return true;
				}
			}
		}

		// generic modal for Generate Credit Card flow
		function showModalCreditCard(name, windowClass) {
			windowClass = windowClass || '';
			dashboard.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope
			});
		}

		function showModalSearchIndicator(name, windowClass) {
			windowClass = windowClass || '';
			dashboard.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				controller: 'manualOverride',
				controllerAs: 'override',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope
			});
		}

		// confirmation for generating credit card
		/*function confirmGenerateCreditCard(engagement) {
			dashboard.engagementId = engagement.engagementId;
			$rootScope.agreementAmount = engagement.totalAgreementAmount;
			$rootScope.creditAmount = engagement.totalCreditAmount;
			showModalCreditCard('confirmGenerateCreditCard');
		}*/

		function viewVccBarcodeDetails(engagementID) {
			void 0;
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			dashboardServices.getCreditCardDetails(engagementID)
	        .then(function(response){
	          void 0;

	          var pdfBlob = SharedDataSvc.createPdfBlob(response.vccInvoicePdf);
	          if (navigator.msSaveOrOpenBlob) {
	            navigator.msSaveOrOpenBlob(pdfBlob);
	            dashboard.modal.dismiss();
	          } else {
	            var objectURL = URL.createObjectURL(pdfBlob);
	            $rootScope.securePdf = $sce.trustAsResourceUrl(objectURL);
	          }
	          if($rootScope.securePdf) {
	            var vccPrintDetailModal = modalService.open({
	              templateUrl: '/partials/modal_vccPrintReceipt.html',
	              backdrop: 'static',
	              show:true
	            });
	            vccPrintDetailModal.result.then(function(){
	              SharedDataSvc.clearPrintSection();
	              //navigateTo('dashboard');
	            });
	          }

	        },function(response){
	          	void 0;
		        if(response.data.errorData.status_code === 'ENG-V116-404' || response.data.errorData.status_code === 'ENG-V101-500') {
		            $rootScope.modalMessage = response.data.errorData.status_message;  
		            var errorModal = modalService.open({
			            templateUrl: '/partials/modal_vccPrintReceiptError.html',
			            backdrop: 'static',
			            show:true
			        });
			        errorModal.result.then(function(){
			            //navigateTo('dashboard');
			        });        
		        }
	        });
		}

		//HT2-12 additional details modal
		function getAdditionalDetails(item){
			modalService.processing($translate.instant("common_label_processing"));
			dashboardServices.getAdditionalInfo(item)
	        .then(function(response){
	        	modalService.dismiss();

	        	var locale;
				if($rootScope.selectedLanguage == 'es'){
					locale = 'spanish';
				}else{
					locale = 'english';
				}

	        	$rootScope.additionalInfoQue = {
					infoDetails: response.questions,
					engagementId : item,
					locale: locale
				};
				
				for(var z=0; z < response.questions.length ; z++){
					response.questions[z].language = locale;
					if(locale == 'english'){
						if(response.questions[z].possibleValues_en){
							response.questions[z].possibleValues_en = getAddCheckOptions(response.questions[z].possibleValues_en);
						}
					}else if(locale == 'spanish'){
						if(response.questions[z].possibleValues_es){
							response.questions[z].possibleValues_es = getAddCheckOptions(response.questions[z].possibleValues_es);
						}
					}	
				}
				
				var quickQuoteModal = modalService.open({
		            templateUrl: '/partials/modal_getAdditionalDetails.html',
		            backdrop: 'static',
		            show:true,
		            scope: $scope,
		            windowClass: 'quick-quote'
	       		 });
			}, function(error){
				void 0;
			});
		}

		// funtion checkbox value string into object
		function getAddCheckOptions(options){
			var checkOptions = [];
			options.replace(/^\[(.+)\]$/,'');
			options = options.split(',');

			options.forEach(function(entry) {
   				var obj = {
   					name : entry,
   					isSelected : false
   				 }
   				checkOptions.push(obj);
			});
			return checkOptions;
		}

		// funtion to format selected checkbox value into comma seperated single string	
		function formatCheckOptions(options){
			var optionList = [];
				for(var z=0; z < options.length ; z++){
					if(options[z].isSelected){
						optionList.push(options[z].name);
					}
				}
				return optionList.join(',');
		}


		function setAdditionalDetails(){
			modalService.processing($translate.instant("common_label_processing"));
			void 0;
			var infoList = $rootScope.additionalInfoQue.infoDetails;
			var infoData = {};
			infoData.engagementId =  $rootScope.additionalInfoQue.engagementId.toString();
				for(var z=0; z < infoList.length ; z++){
					if(infoList[z].inputType == "checkbox"){
						if(infoList[z].language === "english"){
							var formatList = formatCheckOptions(infoList[z].possibleValues_en);
							infoList[z].value = formatList;
						}else{
							var formatList = formatCheckOptions(infoList[z].possibleValues_es);
							infoList[z].value = formatList;
						}
					}
					if(infoList[z].value && infoList[z].inputType == "text"){
						infoData[infoList[z].key] = infoList[z].value;
					}
					if(infoList[z].value && infoList[z].inputType == "dropdown"){
						infoData[infoList[z].key] = infoList[z].value.name;
					}
				}
				void 0;

				dashboardServices.setAdditionalInfo(infoData)
	        		.then(function(response){
	        			modalService.dismiss();
	        			navigateTo('dashboard');
	        	}, function(error){
	        		modalService.dismiss();
				void 0;
			});
		}

		//Show quick quote modal
		function getQuoteModal(selectedProduct) {	
			if(selectedProduct){
				dashboard.quoteInput.selectedProduct = selectedProduct; 
			} else {
				dashboard.quoteInput.selectedProduct = undefined; 
			}	
			/*VAN 492 - PR-AWP: Categories in Quick quote are in English even though language selected is Spanish - starts*/
			if($scope.dashboard.storeInfo.quickQuoteProductList){
				$scope.dashboard.storeInfo.quickQuoteProductList.product = $rootScope.selectedLanguage=="es" ? dashboard.storeInfo.quickQuoteProductList.product_es: dashboard.storeInfo.quickQuoteProductList.product_en;
			}
			/*VAN 492 - PR-AWP: Categories in Quick quote are in English even though language selected is Spanish - Ends*/
			
			var quickQuoteModal = modalService.open({
	            templateUrl: '/partials/modal_quickQuoteCalculator.html',
	            backdrop: 'static',
	            show:true,
	            scope: $scope,
	            windowClass: 'quick-quote'
	        });
	        quickQuoteModal.result.then(function(){
	            //navigateTo('dashboard');
	        });
		}

		// show RA update agreement search modal
		function getRAUpdateSearchModal(invalidAgreementError) {
			if (invalidAgreementError) {
				$scope.raUpdate.invalidAgreementNo = true;
			} else {
				resetDocuSign();
			}
			var docSignModal = modalService.open({
				templateUrl: '/partials/modal_raUpdateSearch.html',
				backdrop: 'static',
				show:true,
				scope: $scope,
				windowClass: 'quick-quote'
			});
			docSignModal.result.then(function(){
				showProcessingModal();
				// lock modals so we can use our own error handling
				modalService.lock(true);
				applicationService.getApplicationByAgreementId($scope.raUpdate.agreementNo).then(function(engagement) {
					modalService.lock(false);
					getRAUpdateResultModal(engagement);
				}, function(error) {
					modalService.lock(false);
					if (error.data.errorData.status_message === 'Engagement not found') {
						getRAUpdateSearchModal(true);
					}
					void 0;
				});
			});
		}

		// show RA update agreement search results modal
		function getRAUpdateResultModal(engagement) {
			$rootScope.engagementInfo = {
				VanEngagement: engagement,
			};
			$scope.raUpdate.results = {
				agreementNo: engagement.agreementHTId,
				firstName: engagement.customer.fName,
				lastName: engagement.customer.lName,
				customerId: engagement.customer.customerId,
				engagementId: engagement.engagementId,
				email: engagement.customer.email,
			};
			$scope.docuSignResultModal = modalService.open({
				templateUrl: '/partials/modal_raUpdateResult.html',
				backdrop: 'static',
				show:true,
				scope: $scope,
				windowClass: 'smallModals',
			});
		}

		// RA update in-store DocuSign
		function inStoreRAUpdateDocuSign(engagement) {
			showProcessingModal();
			var payload = {
		              "customerId" : engagement.customerId,
		              "engagementId" : String(engagement.engagementId),
		              "signersPresent" : 'APPLICANT',
		              "paymentToDate" : "",
		              "coapplicantFirstName" : "",
		              "coapplicantLastName" : "",
			};

			var storedSessionId = $cookies.getObject("okta_session_id");

			var domain = $window.location;
			var destination = domain.hash.split('/')[1];

			payload.returnURL = domain.origin + '/#/login' +
								"?Session=" + storedSessionId + "&engagementId=" + engagement.engagementId;
			void 0;

			$cookies.putObject("isCoCustomerSignerPending", null);

			void 0;
			$cookies.putObject("navigatingToDocusign", true);

			paymentFactory.docuSign(payload, true).then(function(response) {
				// normally signingStatus is undefined for
				// in-store, but it is 'Accepted' if the
				// document was already signed remotely
				if (response.signingStatus === 'Accepted') {
					if($rootScope.selectedLanguage == 'es') {
						modalService.error('El documento de actualización de renta ya está firmado.');
					}
					else {
						modalService.error('Rental update document is already signed.');
					}
					return;
				}

				$window.location.href = response.signatureURL;
			}, function(error) {
				$cookies.putObject("navigatingToDocusign", false);
				$cookies.putObject("isCoCustomerSignerPending", null);
				//$location.url('dashboard');
				void 0;
			});
		}

		// RA update remote DocuSign
		function remoteRAUpdateDocuSign(engagementInfo) {
			void 0;
			$scope.emailAddress = engagementInfo.email;
			var remoteSignModal = modalService.open({
				title: "Customer Email Verification for RemoteSign in Dashboard",
				templateUrl: 'partials/modal_remoteEmailConfirm.tpl.html',
				scope: $scope,
				backdrop: 'static',
				controller: 'emailAgreementRemoteCtrl'
			});
			remoteSignModal.result.then(function(email) {
				//$scope.email.language = $scope.remoteEmail.lanType;
				$scope.confirmedEmail = email;
				emailRAUpdateToPersonalDevice();//function to call Create Envelope service or EML service
			});
		}

		function emailRAUpdateToPersonalDevice() {
			var engagement = $rootScope.engagementInfo;
			//call create envelope service with remoteconfirmation emailID
			void 0;
			if (engagement.VanEngagement.agreementHTId != null){
				showProcessingModal();
				var storedSessionId = $cookies.getObject("okta_session_id");
				if (engagement.VanEngagement.payToDate == null) {
					engagement.VanEngagement.payToDate = engagement.VanEngagement.nextPaymentDate;
				}
				if (engagement.VanEngagement.payToDate != null) {
					var isValid = moment(engagement.VanEngagement.payToDate, 'YYYYMMDD',true).isValid();
					if (isValid) {
						var payDate = engagement.VanEngagement.payToDate;
					} else {
						var payDate = moment(engagement.VanEngagement.payToDate).format('YYYYMMDD');
					}
				}
				var payload = {
						"customerId" : engagement.VanEngagement.customer.customerId,
						"engagementId" : engagement.VanEngagement.engagementId,
						"signersPresent" : "APPLICANT",
						"clubSwitch" : engagement.VanEngagement.clubSelection,
						"waiverSwitch" : engagement.VanEngagement.waiverSelection,
						"paymentToDate" : payDate,
						"coapplicantFirstName" : null,
						"coapplicantLastName" : null,
						"isRemoteSigningOpted": "1",
						"remoteEmailAddress" : $scope.confirmedEmail,
						"language":$scope.remoteEmail.lanType
				};
				var domain = $window.location;
				var destination = domain.hash.split('/')[1];
				payload.returnURL = domain.origin + '/#/login' +
										"?Session=" + storedSessionId + "&engagementId=" + engagement.VanEngagement.engagementId;
				void 0;
				void 0;
				paymentFactory.docuSign(payload, true).then(function(response) {
					void 0;
					if (response.signingStatus === 'Accepted') {
						if($rootScope.selectedLanguage == 'es') {
							modalService.error('El documento de actualización de alquiler ya está firmado. No se enviará un correo electrónico.');
						}
						else {
							modalService.error('Rental update document is already signed. An email will not be sent.');
						}
						return;
					}

					modalService.dismiss();
					$route.reload();
				}, function(error) {
					void 0;
				});
			}
		}

		function quickQuoteCalculation(inputValue) {
			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk*/
			dashboard.resetQQPaymentEstimate = false;
			dashboard.showPaymentSectionForBiweekly = true;//VAN-3705 - QuickQuote - When service called for more than once the response doesn't get loaded as expected
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			void 0;
			if(inputValue.deliveryFee)
				void 0;
			if(dashboard.hideQQProductList && dashboard.storeInfo.quickQuoteProductList && dashboard.storeInfo.quickQuoteProductList.product_en.length == 1){
				inputValue.selectedProduct = dashboard.storeInfo.quickQuoteProductList.product_en[0];
			}
			var quickQuoteData = orderEstimatePutFactory.compileQuickQuoteData(SharedDataSvc.globalVars.storeSetup.vanStoreId ,inputValue, dashboard.prodCat);
			if(dashboard.storeInfo.isQQCalendarRequired) {
				quickQuoteData.nextRenewDte = dashboard.isAdjustablePayment ? SharedDataSvc.quoteCalculator.date : dashboard.nextRenewDte[inputValue.quoteDate].formattedDate;
			}

			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
			if(dashboard.storeInfo.lowInitialPaymentEnabled && dashboard.storeInfo.paymentModeEnabled!=undefined){
			quickQuoteData.paymentMode = 'Biweekly';
			orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
			.then(function(biweeklyResponse) {
				dashboard.quickQuoteBiweeklyEstimate = biweeklyResponse;
				quickQuoteData.paymentMode = 'FOURWEEK';
				orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
				.then(function(fourWeekResponse) {
					dashboard.quickQuoteFourWeekEstimate = fourWeekResponse;
					quickQuoteData.paymentMode = 'Monthly';
					/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
					orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
					.then(function(response) {
						dashboard.letsGetStarted = false;
						dashboard.quoteInput.merchandiseTotal = inputValue.merchandiseTotal;
						dashboard.quoteInput.deliveryFee = inputValue.deliveryFee;
						if(dashboard.storeInfo.isQQCalendarRequired) {
							dashboard.quoteInput.quoteDate = dashboard.nextRenewDte[inputValue.quoteDate];
						}
						getQuoteModal(inputValue.selectedProduct);
						void 0;
						dashboard.quickQuoteEstimate = response;
					    //VAN-3691 - Total cost of lease to be added in Quick Quote and review estimate - Starts
						if(dashboard.quickQuoteBiweeklyEstimate.totalLeasePurchasePrice!=undefined && dashboard.quickQuoteFourWeekEstimate.totalLeasePurchasePrice!=undefined
								&& dashboard.quickQuoteEstimate.totalLeasePurchasePrice!=undefined){
							void 0;
						dashboard.quickQuoteEstimate.totalLeasePurchasePrice = Math.max(dashboard.quickQuoteBiweeklyEstimate.totalLeasePurchasePrice, 
								dashboard.quickQuoteFourWeekEstimate.totalLeasePurchasePrice,dashboard.quickQuoteEstimate.totalLeasePurchasePrice);
						}
						//VAN-3691 - Total cost of lease to be added in Quick Quote and review estimate - Ends
						var deliveryData = [];

						dashboard.initialRentalFee = dashboard.quickQuoteEstimate.initialPaymentWTax;
						/*var fixedAmounts = dashboard.quickQuoteEstimate.initialPaymentWTax +
					 dashboard.quickQuoteEstimate.ldwAmountInitial +
					  dashboard.quickQuoteEstimate.ldwTaxInitial +
					   dashboard.quickQuoteEstimate.bplusAmountInitial +
					    dashboard.quickQuoteEstimate.bplusTaxInitial;


					if(inputValue.deliveryFee > 0){
						for (var item in dashboard.quickQuoteEstimate.feeArray) {
	  						if (dashboard.quickQuoteEstimate.feeArray[item].feeType == "Delivery") {
	    							deliveryData.push(dashboard.quickQuoteEstimate.feeArray[item]);
	 						 }
						}
					}else{
						var noDevly = {
							"feeInitialPayment": 0,
							"feeInitialTax": 0
						}
						deliveryData.push(noDevly);
					}*/


						if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0 ) {
							//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+ deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc1 : en.modal_quickQuoteCalculator_FeesDesc1; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ', a delivery charge of $'+Number(inputValue.deliveryFee) + ', and an extended services of $'+ Number(inputValue.extendedServiceAmount);
						}else if((inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
							//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc4 : en.modal_quickQuoteCalculator_FeesDesc4; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ' and a delivery charge of $'+Number(inputValue.deliveryFee);

						}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
							//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc5 : en.modal_quickQuoteCalculator_FeesDesc5; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee) + " and an extended services of $" + Number(inputValue.extendedServiceAmount);

						}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
							//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc6 : en.modal_quickQuoteCalculator_FeesDesc6; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee);

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0){
							//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc2 : en.modal_quickQuoteCalculator_FeesDesc2; 
							dashboard.quickQuoteDisclaimer = ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc7 : en.modal_quickQuoteCalculator_FeesDesc7)  + dashboard.quickQuoteEstimate.processingFee + ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc8 : en.modal_quickQuoteCalculator_FeesDesc8 ) + Number(inputValue.extendedServiceAmount);

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
							//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc3 : en.modal_quickQuoteCalculator_FeesDesc3; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee;

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
							//dashboard.initialRentalFee = fixedAmounts
							dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc9 : en.modal_quickQuoteCalculator_FeesDesc9; 
							//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your extended services of $' + Number(inputValue.extendedServiceAmount);

						}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
							//	dashboard.initialRentalFee = fixedAmounts
							dashboard.quickQuoteInitialInfo = "";
							//dashboard.quickQuoteDisclaimer = "";
						}

						if(dashboard.quickQuoteEstimate !== undefined && 
								dashboard.quickQuoteEstimate.epoPaymentArray !== undefined) {
							var epoSchedule = dashboard.quickQuoteEstimate.epoPaymentArray;
							if (epoSchedule === undefined || epoSchedule.length === 0) {
								dashboard.epoSchedule = [];
								return;
							}
							// set to scope
							dashboard.epoSchedule = epoSchedule;
							var firstPaymentMonth = dashboard.epoSchedule[0].epoAfterPaymentNumber;
							//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
							/*if (firstPaymentMonth > 3) {
								var days = (firstPaymentMonth - 1) * 30;
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											epoCashPrice: days + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}
							} else if (firstPaymentMonth == 3) {
								var days = firstPaymentMonth * 30;
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											epoCashPrice: days + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}
							}*/
							//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
							//VAN-1415-Bob's - Change EPO array to 120 days - Starts
							if (firstPaymentMonth >= 3) {
								if(dashboard.storeInfo != undefined && dashboard.storeInfo.epoOptionDays != undefined ){
									if(dashboard.storeInfo.epoOptionDays === '120'){
										for (var i = firstPaymentMonth - 1; i > 0; i--) {
											var epoEntry = {
													epoAfterPaymentNumber: i,
													//VAN-1390 - EPO showing incorrectly in review estimate
													epoCashPrice: "120" + " Days EPO"
											}
											dashboard.epoSchedule.unshift(epoEntry);
										}
									}else if(dashboard.storeInfo.epoOptionDays === '100'){
										for (var i = firstPaymentMonth - 1; i > 0; i--) {
											var epoEntry = {
													epoAfterPaymentNumber: i,
													epoCashPrice: "100" + " Days EPO"
											}
											dashboard.epoSchedule.unshift(epoEntry);
										}
									}else if(dashboard.storeInfo.epoOptionDays === '90'){
										for (var i = firstPaymentMonth - 1; i > 0; i--) {
											var epoEntry = {
													epoAfterPaymentNumber: i,
													epoCashPrice: "90" + " Days EPO"
											}
											dashboard.epoSchedule.unshift(epoEntry);
										}	
									}
								}else{
									for (var i = firstPaymentMonth - 1; i > 0; i--) {
										var epoEntry = {
												epoAfterPaymentNumber: i,
												epoCashPrice: "90" + " Days EPO"
										}
										dashboard.epoSchedule.unshift(epoEntry);
									}
								}
								//VAN-1415-Bob's - Change EPO array to 120 days - Ends
							}
						}

						dashboard.selectionFlag = !dashboard.quickQuoteEstimate.feeArray[1].feeAcrossTerm && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0;

						dashboard.noEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
						dashboard.onlyEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
						dashboard.noESVonlyDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);
						dashboard.withEsvDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);

					}, function(response){
						dashboard.letsGetStarted = true;
						void 0;
					});
					/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
				}, function(fourWeekResponse){
					dashboard.letsGetStarted = true;
					void 0;
				});
			}, function(biweeklyResponse){
				dashboard.letsGetStarted = true;
				void 0;
			});
		}else if(!dashboard.storeInfo.lowInitialPaymentEnabled || dashboard.storeInfo.paymentModeEnabled==undefined){
			/*quickQuoteData.paymentMode = '';*/
			orderEstimatePutFactory.putData(quickQuoteData, false, 'HT')
			.then(function(response) {
				dashboard.letsGetStarted = false;
				dashboard.quoteInput.merchandiseTotal = inputValue.merchandiseTotal;
				dashboard.quoteInput.deliveryFee = inputValue.deliveryFee;
				if(dashboard.storeInfo.isQQCalendarRequired) {
					dashboard.quoteInput.quoteDate = dashboard.nextRenewDte[inputValue.quoteDate];
				}
				getQuoteModal(inputValue.selectedProduct);
				void 0;
				dashboard.quickQuoteEstimate = response;
				var deliveryData = [];

				dashboard.initialRentalFee = dashboard.quickQuoteEstimate.initialPaymentWTax;
				/*var fixedAmounts = dashboard.quickQuoteEstimate.initialPaymentWTax +
			 dashboard.quickQuoteEstimate.ldwAmountInitial +
			  dashboard.quickQuoteEstimate.ldwTaxInitial +
			   dashboard.quickQuoteEstimate.bplusAmountInitial +
			    dashboard.quickQuoteEstimate.bplusTaxInitial;


			if(inputValue.deliveryFee > 0){
				for (var item in dashboard.quickQuoteEstimate.feeArray) {
						if (dashboard.quickQuoteEstimate.feeArray[item].feeType == "Delivery") {
							deliveryData.push(dashboard.quickQuoteEstimate.feeArray[item]);
						 }
				}
			}else{
				var noDevly = {
					"feeInitialPayment": 0,
					"feeInitialTax": 0
				}
				deliveryData.push(noDevly);
			}*/


				if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0 ) {
					//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+ deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc1 : en.modal_quickQuoteCalculator_FeesDesc1; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ', a delivery charge of $'+Number(inputValue.deliveryFee) + ', and an extended services of $'+ Number(inputValue.extendedServiceAmount);
				}else if((inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
					//dashboard.initialRentalFee = fixedAmounts + deliveryData[0].feeInitialPayment+deliveryData[0].feeInitialTax + dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;

					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc4 : en.modal_quickQuoteCalculator_FeesDesc4; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee+ ' and a delivery charge of $'+Number(inputValue.deliveryFee);

				}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
					//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc5 : en.modal_quickQuoteCalculator_FeesDesc5; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee) + " and an extended services of $" + Number(inputValue.extendedServiceAmount);

				}else if(Number(inputValue.deliveryFee) > 0 && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
					//dashboard.initialRentalFee = fixedAmounts +  deliveryData[0].feeInitialPayment + deliveryData[0].feeInitialTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc6 : en.modal_quickQuoteCalculator_FeesDesc6; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your delivery charge of $' + Number(inputValue.deliveryFee);

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && inputValue.extendedServiceAmount > 0){
					//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc2 : en.modal_quickQuoteCalculator_FeesDesc2; 
					dashboard.quickQuoteDisclaimer = ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc7 : en.modal_quickQuoteCalculator_FeesDesc7)  + dashboard.quickQuoteEstimate.processingFee + ($rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc8 : en.modal_quickQuoteCalculator_FeesDesc8 ) + Number(inputValue.extendedServiceAmount);

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee > 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)){
					//dashboard.initialRentalFee = fixedAmounts +  dashboard.quickQuoteEstimate.processingFee + dashboard.quickQuoteEstimate.processingFeeTax;
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc3 : en.modal_quickQuoteCalculator_FeesDesc3; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your processing fee of $' + dashboard.quickQuoteEstimate.processingFee;

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && inputValue.extendedServiceAmount > 0) {
					//dashboard.initialRentalFee = fixedAmounts
					dashboard.quickQuoteInitialInfo = $rootScope.selectedLanguage == 'es' ? es.modal_quickQuoteCalculator_FeesDesc9 : en.modal_quickQuoteCalculator_FeesDesc9; 
					//dashboard.quickQuoteDisclaimer = 'The "Estimated Initial Payment" includes your extended services of $' + Number(inputValue.extendedServiceAmount);

				}else if((inputValue.deliveryFee === undefined || Number(inputValue.deliveryFee) <= 0) && dashboard.quickQuoteEstimate.processingFee <= 0 && (inputValue.extendedServiceAmount === undefined || Number(inputValue.extendedServiceAmount) <= 0)) {
					//	dashboard.initialRentalFee = fixedAmounts
					dashboard.quickQuoteInitialInfo = "";
					//dashboard.quickQuoteDisclaimer = "";
				}

				if(dashboard.quickQuoteEstimate !== undefined && 
						dashboard.quickQuoteEstimate.epoPaymentArray !== undefined) {
					var epoSchedule = dashboard.quickQuoteEstimate.epoPaymentArray;
					if (epoSchedule === undefined || epoSchedule.length === 0) {
						dashboard.epoSchedule = [];
						return;
					}
					// set to scope
					dashboard.epoSchedule = epoSchedule;
					var firstPaymentMonth = dashboard.epoSchedule[0].epoAfterPaymentNumber;
					//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
					/*if (firstPaymentMonth > 3) {
						var days = (firstPaymentMonth - 1) * 30;
						for (var i = firstPaymentMonth - 1; i > 0; i--) {
							var epoEntry = {
									epoAfterPaymentNumber: i,
									epoCashPrice: days + " Days EPO"
							}
							dashboard.epoSchedule.unshift(epoEntry);
						}
					} else if (firstPaymentMonth == 3) {
						var days = firstPaymentMonth * 30;
						for (var i = firstPaymentMonth - 1; i > 0; i--) {
							var epoEntry = {
									epoAfterPaymentNumber: i,
									epoCashPrice: days + " Days EPO"
							}
							dashboard.epoSchedule.unshift(epoEntry);
						}
					}*/
					//Commented as part of VAN-1415-Bob's - Change EPO array to 120 days
					//VAN-1415-Bob's - Change EPO array to 120 days - Starts
					if (firstPaymentMonth >= 3) {
						if(dashboard.storeInfo != undefined && dashboard.storeInfo.epoOptionDays != undefined ){
							if(dashboard.storeInfo.epoOptionDays === '120'){
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											//VAN-1390 - EPO showing incorrectly in review estimate
											epoCashPrice: "120" + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}
							}else if(dashboard.storeInfo.epoOptionDays === '90'){
								for (var i = firstPaymentMonth - 1; i > 0; i--) {
									var epoEntry = {
											epoAfterPaymentNumber: i,
											epoCashPrice: "90" + " Days EPO"
									}
									dashboard.epoSchedule.unshift(epoEntry);
								}	
							}
						}else{
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										epoCashPrice: "90" + " Days EPO"
								}
								dashboard.epoSchedule.unshift(epoEntry);
							}
						}
					}
					//VAN-1415-Bob's - Change EPO array to 120 days - Ends
				}

				dashboard.selectionFlag = !dashboard.quickQuoteEstimate.feeArray[1].feeAcrossTerm && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0;

				dashboard.noEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
				dashboard.onlyEsvNoDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount <= 0);
				dashboard.noESVonlyDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount <= 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);
				dashboard.withEsvDel = (dashboard.quickQuoteEstimate.feeArray[0].feeAmount > 0 && dashboard.quickQuoteEstimate.feeArray[1].feeAmount > 0);

			}, function(response){
				dashboard.letsGetStarted = true;
				void 0;
			});
		}
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
	}

		function quoteModalReset() {
			//console.log("Quick quote lets get started button state", dashboard.letsGetStarted);
			SharedDataSvc.quoteCalculator.selectedDate = null;
			dashboard.quoteInput.merchandiseTotal = undefined;
			dashboard.quoteInput.deliveryFee = undefined;
			dashboard.quoteInput.extendedServiceAmount = undefined;
			dashboard.quickQuoteEstimate = "";
			dashboard.initialRentalFee = undefined;
			dashboard.quickQuoteInitialInfo = "";
			//dashboard.quickQuoteDisclaimer = "";
			dashboard.letsGetStarted = true;
			dashboard.epoSchedule = "";
			/*VAN-975 - AWP : Quick Quote Screen - Payment Details were not getting cleared on relaunch*/
			dashboard.showPaymentSectionForBiweekly = false;
		}

		function feeCheck(value, name) {
			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
			dashboard.epoSchedule = undefined;
			/*VAN-976 - AWP: Quick Quote Screen - On change of Delivery Charge/Extended Services system reloads incorrect payment details - Starts*/
			dashboard.quickQuoteBiweeklyEstimate = undefined;
			dashboard.quickQuoteFourWeekEstimate = undefined;
			/*VAN-976 - AWP: Quick Quote Screen - On change of Delivery Charge/Extended Services system reloads incorrect payment details - Ends*/
			dashboard.resetQQPaymentEstimate = true;
			dashboard.letsGetStarted = true;
			/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/
			if (value && String(value).indexOf('.') > -1) {
				var values = String(value).split('.');
				if (values[1].length > 2) { 
					if(name == 'extendedServiceAmount') {
						dashboard.quoteInput.extendedServiceAmount = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					} else if (name == 'deliveryFee'){
						dashboard.quoteInput.deliveryFee = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					}			
				}
			}
		}
		
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Starts*/
		function resetQQEPO(){
			/*SharedDataSvc.quoteCalculator.selectedDate = null;*/
			dashboard.epoSchedule = undefined;
			dashboard.quickQuoteEstimate = undefined;
			dashboard.quickQuoteBiweeklyEstimate = undefined;
			dashboard.quickQuoteFourWeekEstimate = undefined;
			dashboard.quoteInput.deliveryFee = undefined;
			dashboard.quoteInput.extendedServiceAmount = undefined;
			dashboard.resetQQPaymentEstimate = true;
			dashboard.letsGetStarted = true;
			dashboard.letsGetStarted = true;
		}
		
		function resetQQWhenCatChange(){
			SharedDataSvc.quoteCalculator.selectedDate = null;
			dashboard.epoSchedule = undefined;
			dashboard.quickQuoteBiweeklyEstimate = undefined;
			dashboard.quickQuoteFourWeekEstimate = undefined;
			dashboard.quoteInput.merchandiseTotal = undefined;
			dashboard.quoteInput.deliveryFee = undefined;
			dashboard.quoteInput.extendedServiceAmount = undefined;
			dashboard.resetQQPaymentEstimate = true;
		}
		/*VAN-934 - Quick Quote – Add biweekly and 4weeks quote, along with monthly, to both AWP and Kiosk - Ends*/

		function showVerifyInfoAlert() {
			var showVerifyInfoAlertModal = modalService.open({
              templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
              backdrop: 'static',
              show:true,
              scope: $scope
            });
		}

		function viewApplication(engagement) {
			void 0;
			resetManualOverrideData();
			dashboard.verifyInfoEngagement = engagement;
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			dashboardServices.viewApplication(engagement.customerId)
				.then(function(response) {
					void 0;

					var pdfBlob = SharedDataSvc.createPdfBlob(response.leaseOrderPdf);
		          	if (navigator.msSaveOrOpenBlob) {
		            	navigator.msSaveOrOpenBlob(pdfBlob);
		            	dashboard.modal.dismiss();
		          	} else {
		            	var objectURL = URL.createObjectURL(pdfBlob);
		            	$rootScope.securePdf = $sce.trustAsResourceUrl(objectURL);
		          	}
		          	if($rootScope.securePdf) {
			            //vm.processingModal.dismiss();
			            var viewApplicationModal = modalService.open({
			              templateUrl: '/partials/modal_viewApplication.html',
			              backdrop: 'static',
			              show:true,
			              scope: $scope
			            });
			            viewApplicationModal.result.then(function(){
			              SharedDataSvc.clearPrintSection();
			            });
		          	}
				}, function(response) {
					SharedDataSvc.clearPrintSection();
				})
		}

		function verifyInfo(engagement) {
			SharedDataSvc.manualOverrideData = engagement;
			resetManualOverrideData();
			if(engagement.applicationStatus !== 'Declined') {
				showModal('conditionalApproval', 'associate-review-modal');
			}
		}
		
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Start
		function remoteSignFromDashboard(engagement){
			//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements - Enabled the HT flag and changed request payload.
			modalService.processing('Processing');//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
			customerDataFactory.findByEngagementId(engagement.engagementId, true).then(function(response) {
				modalService.dismiss();//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes
				$rootScope.engagementInfo = response;
				$scope.emailAddress = response.VanEngagement.customer.email;
				//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements
				if(response.VanEngagement.agreementStatus == 'Pending'){
					//Hide language option while calling EML Service
					if(response.VanEngagement.envelopId !== null){
						$scope.hideLanguageOption = true;}
					var remoteSignModal = modalService.open({
						title: "Customer Email Verification for RemoteSign in Dashboard",
						templateUrl: 'partials/modal_remoteEmailConfirm.tpl.html',
						scope: $scope,
						backdrop: 'static',
						controller: 'emailAgreementRemoteCtrl'
					});
					remoteSignModal.result.then(function(email) {
						//$scope.email.language = $scope.remoteEmail.lanType;
						$scope.confirmedEmail = email;
						emailAgrToPersonalDevice();//function to call Create Envelope service or EML service
					}); 
				}
				//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements - Starts
				else{
					$location.path('dashboard/'+response.VanEngagement.engagementId);
				}
				//VAN-587 - AWP/KIOSK: User able to sign the agreements using remote docusign for VOIDED/ChargedOFF agreements - Ends
			}, function(error){
				void 0;
				return false;
			});
		}

		//function to call Create Envelope service or EML service
		function emailAgrToPersonalDevice(){
			var engagement = $rootScope.engagementInfo;
			//Call EML Service with email id
			//VAN-779 - HT/AWP - Customer is not receiving agreement email after selecting agreement link (did not complete docusign) and trying to send email through Remote Docusign
			if(engagement.VanEngagement.agreementHTId != null && engagement.VanEngagement.envelopId != null && engagement.VanEngagement.agreementStatus == itemStatus.agreement.notStarted && engagement.VanEngagement.isRemoteSigningOpted == "1"){
				void 0;
				dashboard.modalTitle = 'Processing';
				dashboard.processingModal = modalService.processing(dashboard.modalTitle);
				var storedSessionId = $cookies.getObject("okta_session_id");
				var payload = {
						"envelopeId" : engagement.VanEngagement.envelopId,     
						"agreementId" : engagement.VanEngagement.agreementHTId,  
						"customerFirstName" : engagement.VanEngagement.customer.fName,
						"customerLastName": engagement.VanEngagement.customer.lName,   
						"emailAddress" : $scope.confirmedEmail,
						"customerId" : engagement.VanEngagement.customer.customerId,
						"engagementId" : engagement.VanEngagement.engagementId,
						"isRemoteSigningOpted" : "1",
						"language":$scope.remoteEmail.lanType
				};
				void 0;

				paymentFactory.sendEmailEML(payload).then(function(response) {
					void 0;
					modalService.dismiss();
					$route.reload();
				}, function(error) {
					showModal('agreementError');
					modalService.dismiss();
					void 0;
				});
			}
			else{
				//call create envelope service with remoteconfirmation emailID
				void 0;
				if(engagement.VanEngagement.agreementHTId != null){
					dashboard.modalTitle = 'Processing';
					dashboard.processingModal = modalService.processing(dashboard.modalTitle);
					var storedSessionId = $cookies.getObject("okta_session_id");
					//VAN-676 - PRB0042928 - Remote Signing throwing error for Charge off
					//VAN-3210-Initial Payment break down is not displaying in payment screen when we select beyond 15 days by using Xpartner Login - Starts
					if(engagement.VanEngagement.payToDate == null){
						engagement.VanEngagement.payToDate = engagement.VanEngagement.nextPaymentDate;
					}//VAN-3210-Initial Payment break down is not displaying in payment screen when we select beyond 15 days by using Xpartner Login - Ends
					if(engagement.VanEngagement.payToDate != null){
						//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes - Starts
						var isValid = moment(engagement.VanEngagement.payToDate, 'YYYYMMDD',true).isValid();
						if(isValid){
							var payDate = engagement.VanEngagement.payToDate;
						}else{
							var payDate = moment(engagement.VanEngagement.payToDate).format('YYYYMMDD');
						}//VAN-3189-Enable remote signing option for AWP generated agreements- xteam changes- xteam changes - Ends
					}
					var payload = {
							"customerId" : engagement.VanEngagement.customer.customerId,     
							"engagementId" : engagement.VanEngagement.engagementId,  
							"signersPresent" : "APPLICANT",  
							"clubSwitch" : engagement.VanEngagement.clubSelection,   
							"waiverSwitch" : engagement.VanEngagement.waiverSelection,
							//"paymentToDate" : engagement.payToDate,
							"paymentToDate" : payDate, //VAN-676 - PRB0042928 - Remote Signing throwing error for Charge off
							"coapplicantFirstName" : null,
							"coapplicantLastName" : null,
							"isRemoteSigningOpted": "1",
							"remoteEmailAddress" : $scope.confirmedEmail,
							"language":$scope.remoteEmail.lanType
					};
					var domain = $window.location;
					var destination = domain.hash.split('/')[1];
					payload.returnURL = domain.origin + '/#!/login' + 
											"?Session=" + storedSessionId + "&engagementId=" + engagement.VanEngagement.engagementId;
					void 0;
					void 0;
					paymentFactory.docuSign(payload).then(function(response) {
						void 0;
						modalService.dismiss();
						$route.reload();
					}, function(error) {
						showModal('agreementError');
						modalService.dismiss();
						void 0;
					});
				}
			}
		}
		//VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends

		function navigateToAgreement(engagement) {
			//VAN-969-Unable to create new agreement-Agreement status changed from accepted to Pending - Starts
			if(engagement.applicationStatus === dashboard.itemStatus.application.accepted && engagement.orderStatus === dashboard.itemStatus.order.confirmed && engagement.agreementStatus === dashboard.itemStatus.agreement.accepted && engagement.paymentStatus === dashboard.itemStatus.payment.pendingConfirm){
				verifyActiveAgreement(engagement);
			}
			else if(dashboard.storeInfo.isDigitalSigningEnabled){
				dashboard.navigateTo('digitalAgreement', engagement);
			} else {
				dashboard.navigateTo('agreement', engagement);
			}
		}

		function verifyActiveAgreement(engagement){
			modalService.processing("Processing");
			var findData = { 
					'firstName' : engagement.customerFirstName,
					'lastName' : engagement.customerLastName,
					'phone' : engagement.customerPhoneNum,
					'engagementId' :engagement.engagementId
			};
			applicationService.getApplication(findData).then(function(successData){
				void 0;
				if(successData.agreement !== undefined && successData.agreement.agreementStatus === 'ACTIVE'){
					var engagementData = {
							'customerId' : successData.VANCustomerID,
							'engagementId' : successData.VANEngagementId,
							'paymentStatus' : dashboard.itemStatus.payment.complete,
							'vanStoreId' : successData.VANEngagementId,
							'customerHtID' : successData.VanEngagement.customerHTId
					};
					updateEngagementItemFactory.putData(engagementData)
					.then(function(response) {
						void 0;
						modalService.dismiss();
						navigateTo('dashboard', engagement);
					}, function(response){
						void 0;
						modalService.dismiss();
					});	
				}
				else if(dashboard.storeInfo.isDigitalSigningEnabled){
					modalService.dismiss();
					dashboard.navigateTo('digitalAgreement', engagement);
				} else {
					modalService.dismiss();
					dashboard.navigateTo('agreement', engagement);
				}
			}, function(failData){
				modalService.dismiss();
				void 0;
			});
		}
		//VAN-969-Unable to create new agreement-Agreement status changed from accepted to Pending - Ends

		function resetDocuSign() {
			$scope.raUpdate.agreementNo = '';
			$scope.raUpdate.results = {};
			$scope.raUpdate.invalidAgreementNo = false;
		}

		// check for associate test for generating credit card 
		/*function generateCreditCardCheck(pass){
			if (pass) {
				dashboard.noAssociatePass = false;
				var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
				if(auth === pass) {
					dashboard.employeeId = '';
					dashboard.invalidAssociatePass = false;
					dashboard.noAssociatePass = false;
					generateCreditCard(dashboard.engagementId);
				} else {
					console.log('invalid associate pin', dashboard.invalidAssociatePass);
					dashboard.invalidAssociatePass = true;
				}
			}else{
				console.log('no associate pin', dashboard.noAssociatePass);
				dashboard.noAssociatePass = true;
			}
		}*/

		// formatting credit card no received from services
		/*function formatCreditCardNo(num){
			var num1 = String(num).substring(0, 4),
				num2 = String(num).substring(4, 8),
				num3 = String(num).substring(8, 12),
				num4 = String(num).substring(12, 16),
				formattedNo = num1 + '-' + num2 + '-' + num3 + '-' + num4;

			return(formattedNo);
		}*/

		// formatting date received from services
		/*function formatDate(date) {
			var year = String(date).substring(2, 4),
				month = String(date).substring(4, 6),
				day = String(date).substring(6, 8),
				formattedDate = month + '/' + year;

			return moment(formattedDate, 'MM-YY').format('MM/YY');
		}*/

		// generate credit card service call
		/*function generateCreditCard(engagementID){
			console.log('Associate engagementID',engagementID);
			showModalCreditCard($translate.instant("common_label_processing"), 'processing-modal');
			$rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
			dashboardServices.getCreditCardDetails(engagementID)
				.then(function(response){
					console.log('generate credit card details',response);
					dashboard.creditCardDetail = response;
					$rootScope.creditCardNo = formatCreditCardNo(dashboard.creditCardDetail.creditCardNumber);
					$rootScope.creditCardExp = formatDate(dashboard.creditCardDetail.expiryDate);
					$rootScope.creditCardCVV = dashboard.creditCardDetail.cvv;
					showModalCreditCard('creditCardDetails');
				},function(response){
					console.log('generate credit card details failure',response);
					showModalCreditCard('creditCardDetailsFailure');
				});
		}*/

	}
	Dashboard.$inject = ["$scope", "$rootScope", "$location", "$route", "$idle", "$sce", "$routeParams", "$anchorScroll", "$timeout", "$window", "$cookies", "$translate", "modalService", "authentication", "SharedDataSvc", "getInitialDashboard", "applicationService", "updateEngagementItemFactory", "paymentFactory", "getStoreInfoFactory", "manualOverrideFactory", "getTrainingLibraryFactory", "dashboardServices", "datesFactory", "customerDataFactory", "stateRestrictFactory", "orderEstimatePutFactory", "productFactory"];; // end of DASHBOARD controller
})();



///////////////////////////////////////////
//////////////////////////////////////////
///   DASHBOARD AGREEMENT CONTROLLER   //
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
	.module('app.dashboardAgreement')
	.controller('DashboardAgreement', DashboardAgreement);

	/* @ngInject */
	function DashboardAgreement(
		$scope,
		$rootScope,
		$location,
		$route,
		$idle,
		$routeParams,
		$log,
		$sce,
		$cookies,
		modalService,
		authentication,
		SharedDataSvc,
		agreementFactory,
		getStoreInfoFactory,
		getTrainingLibraryFactory,
		getInitialDashboard
		) {

		/*jshint validthis: true */
		var dashboardAgreement = this;

		// DEFINE VARS
		dashboardAgreement.loadingAgreements = true;
		dashboardAgreement.invalidAgreementPin = false;
		dashboardAgreement.duplicateInvoiceId = false;//Story - 28842 code change
		dashboardAgreement.disputeAmountLimitExceed = false;
		dashboardAgreement.disputeAmountMaxPrice = 9999.99;

		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
		dashboardAgreement.dashboardAgreementItems = SharedDataSvc.dashboardAgreementItems?SharedDataSvc.dashboardAgreementItems:'5';
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
		dashboardAgreement.order = 'Date';
		dashboardAgreement.searchIcon = 'Plus';
 		dashboardAgreement.sortingOrder = '';
		dashboardAgreement.acknowledgementDetails = {};
		dashboardAgreement.disputeDetails = {};
		dashboardAgreement.disputeReasons;
		dashboardAgreement.agreementInventoryItems = null;
		dashboardAgreement.agreementCount;
		dashboardAgreement.initialDashboardData;
		dashboardAgreement.searchIconElement;
		$scope.nameValidation = /^[a-zA-Z\s\-\']+$/; // Added for name validation 2542 & 2543. It will allows only letters

		dashboardAgreement.navigateTo = navigateTo;
		dashboardAgreement.showModal = showModal;
		dashboardAgreement.showAcknowledgeAgreementBtn = showAcknowledgeAgreementBtn;
		
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
		dashboardAgreement.showitemsperpage = showitemsperpage;
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
		
		dashboardAgreement.showdisputetBtn = showdisputetBtn;
		//9464 -Disable/hide dispute button in invoice dashboard  code changes starts
		dashboardAgreement.disableDispute = disableDispute;
		//9464 -Disable/hide dispute button in invoice dashboard  code changes ends
		dashboardAgreement.acknowledgeAgreement = acknowledgeAgreement;
		dashboardAgreement.confirmAcknowledgementItems = confirmAcknowledgementItems;
		dashboardAgreement.disputeAgreement = disputeAgreement;
		dashboardAgreement.confirmDispute = confirmDispute;
		dashboardAgreement.agreementPinValidate = agreementPinValidate;
		dashboardAgreement.summaryStatusSortFn = summaryStatusSortFn;
		dashboardAgreement.summaryStatusForSortingFn = summaryStatusForSortingFn;
		dashboardAgreement.onInputChangeDisable = onInputChangeDisable;
		dashboardAgreement.customSearch = customSearch;
		dashboardAgreement.resetSearchResults = resetSearchResults;
		dashboardAgreement.resetFormData = resetFormData;
		dashboardAgreement.showDeliveryModal = showDeliveryModal;
		dashboardAgreement.deliveryStatusVerbiage = deliveryStatusVerbiage;
		dashboardAgreement.setDeliveryStatusClass = setDeliveryStatusClass;
		//dashboardAgreement.checkDisputeAmount = checkDisputeAmount;
		//9464 -Disable/hide dispute button in invoice dashboard  code changes -AC Defect- 2091 starts
		var passphrase = (SharedDataSvc.base64.decode($cookies.getObject('PP'))).substring(5);
		//9464 -Disable/hide dispute button in invoice dashboard  code changes -AC Defect- 2091 ends
		
		var itemStatus = {
			application: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				manualIntervention: 'Manual',
				outOfServiceArea: 'OutOfArea',
				associateReview: 'AssocRvw'
			},
			order: {
				notStarted: 'NotStarted',
				inProgress: 'InProgress',
				pendingConfirm: 'Pending',
				confirmed: 'Confirmed'
			},
			agreement: {
				notStarted: 'Pending',
				inProgress: 'InProgress',
				declined: 'Declined',
				accepted: 'Accepted',
				voided: 'Voided'  //AC 25996 updated code changes
			},
			payment: {
				pendingConfirm: 'Pending',
				inProgress: 'InProgress',
				complete: 'Complete'
			},
			delivery: {
				notStarted: 'NotStarted',
				pendingPayment: 'Pending',
				scheduled: 'Scheduled',
				pickup: 'Pickup',
				confirmed: 'Confirmed',
				readyToSchedule: 'ReadySched'
			},
			sales: {
				pending: 'Pending',
				ready: 'Ready',
				submitted: 'Submitted'
			}
		};

		//PUBLIC VARIABLES
		$scope.isSearched = false;
		$scope.searchError = false;
		$scope.master = {};
		$scope.searchedValue = "Search";
		$scope.years = [];
		$scope.disputeDetailsData = {
			invoiceId: null
		};
		$scope.agreementAcknowledgementDetails = {
			invoiceNumber: null
		};
		$scope.acknowledge = {};//Story - 28842 code change
		$scope.dispute = {};//Story - 28842 code change
		
		// PUBLIC FUNCTIONS
		// Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Activity code changes -Starts
		$scope.sortByClick = function (selectedData) {
			SharedDataSvc.order=selectedData;
			dashboardAgreement.order=SharedDataSvc.order;
			if(dashboardAgreement.order == 'Name') {
				dashboardAgreement.order = 'Name';
				dashboardAgreement.sortingOrder = ['lastName', 'firstName', '-modifiedDate'];
			} if(dashboardAgreement.order == 'Date') {
				dashboardAgreement.sortingOrder = ['-modifiedDate'];
			}	else if(dashboardAgreement.order == 'Activity'){
				dashboardAgreement.order = 'Activity';
				dashboardAgreement.sortingOrder = [dashboardAgreement.summaryStatusSortFn, '-modifiedDate'];
			}
		}
		// Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Activity code changes -Ends
		
		$scope.toggleSearchIcon = function () {
			dashboardAgreement.searchIconElement = angular.element("span.search-button");
			if(dashboardAgreement.searchIconElement.hasClass("glyphicon-minus")) {
				dashboardAgreement.searchIconElement.removeClass("glyphicon-minus active");
				dashboardAgreement.searchIcon = "Plus";
				$scope.searchedValue = "Search";
			} else {
				dashboardAgreement.searchIconElement.addClass("glyphicon-minus active");
				dashboardAgreement.searchIcon = "Minus";
				$scope.searchedValue = "Search only one criteria at a time";
			}
		}

		init();
		function init()
		{
			$rootScope.hideDashboardButton = false;
			$rootScope.hideTrainingLibButton = false;
			$rootScope.hideDashboardAgreementButton = true;
			$rootScope.storeNo = SharedDataSvc.vanStoreId;
			$scope.master = angular.copy($scope.searchParametersValue);

			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
			$scope.currentPage = 1;
			$scope.pageSize = 5;
			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
			
			addYears();
			agreementFactory.retrieveAgreementsDashboard()
			.then(function(data) {
				dashboardAgreement.loadingAgreements = false;
				dashboardAgreement.dashboardAgreementData = JSON.parse(data);
				//dashboardAgreement.dashboardAgreementData =data;
				$scope.dashboardAgreement.dashboardAgreementData = angular.forEach(dashboardAgreement.dashboardAgreementData, function(value, key) {
					var customerFullName = value.firstName + ' ' + value.lastName;
					$scope.dashboardAgreement.dashboardAgreementData[key].customerFullName = customerFullName;
				});
				void 0;
				dashboardAgreement.initialDashboardData = dashboardAgreement.dashboardAgreementData;
			}, function(data) {
				$log.log('error getting agreement dashboard data', data);
				dashboardAgreement.loadingAgreements = false;
			});

			getStoreInfoFactory.getData()
				.then(function(response){
					$rootScope.invoiceMethod = response.invoiceMethod;
					//9464 - Dispute/hide disable button in invoice dashboard code changes starts
					$rootScope.isBarcodeEnabled = response.isBarcodeEnabled;
					//9464 - Dispute/hide disable button in invoice dashboard code changes ends
					dashboardAgreement.storeInvoiceMethod = response.invoiceMethod;//AC32101 code changes
			});
			
			//Feature 7855 Code changes starts
			if(SharedDataSvc.operationMode && SharedDataSvc.operationMode == "MANUALPAYABLE") {
				$rootScope.hideMenuButtons = false;
				$rootScope.hideDashboardButton = true;
				$rootScope.hideTrainingLibButton = true;
				}	
			//Feature 7855 Code changes ends

			agreementFactory.getDisputeReasons().then(function(response) {
				dashboardAgreement.disputeReasons = response.disputeReasonList;
				void 0;
			});

			getTrainingLibraryFactory.getData().then(function(response){
				void 0;
				$rootScope.trainingCount = getTrainingLibraryFactory.getNewDocuments(response);
			});

			$scope.sortByClick(dashboardAgreement.order);
			
			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
			$scope.sortByItems = function(selectedValue){
				if(selectedValue){
				SharedDataSvc.dashboardAgreementItems=selectedValue;
				dashboardAgreement.dashboardAgreementItems=selectedValue;
				$scope.pageSize=selectedValue;
				return dashboardAgreement.dashboardAgreementItems;
				}
			};
			$scope.sortByItems(SharedDataSvc.dashboardAgreementItems);
			//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends

		}

		function addYears() {
			for (var i = 0; i < 50; i++) {
				$scope.years.push(moment().year() - i);
			};
		}

		function navigateTo(target, engagement) {
			engagement = engagement || {};
			if (engagement.engagementId) {
				storeCurrentEngagement(engagement.engagementId);
			}else{
			//AC 32101 code changes starts
				SharedDataSvc.globalVars.order.currentEngagement = engagement;
				SharedDataSvc.globalVars.order.currentEngagement.vanStoreId = SharedDataSvc.vanStoreId;
				SharedDataSvc.globalVars.invoiceAndDeliveryModal = true;
				//AC 32101 code changes ends
			}

			if (target === 'delivery') {
				showDeliveryModal(engagement);
				return;
			}else {
				modalService.dismiss();
				$location.path(target);
			}
		}

		function storeCurrentEngagement(itemID) {
			void 0;
			var selectedEngagement = _.find(dashboardAgreement.dashboardAgreementData, {
				engagementId: itemID
			});
			void 0;
			SharedDataSvc.globalVars.order.currentEngagement = selectedEngagement;
			SharedDataSvc.globalVars.order.currentEngagementId = itemID;
			SharedDataSvc.globalVars.order.currentEngagement.vanStoreId = SharedDataSvc.vanStoreId;
			//console.log('539_sds currEng = '+angular.toJson(SharedDataSvc.globalVars.order.currentEngagement));
		};

		//handle modal as per delivery or pickup
		function showDeliveryModal(engagement) {
			SharedDataSvc.deliveryTempId = engagement.engagementId;
			dashboardAgreement.deliveryTempId = engagement.engagementId;
			SharedDataSvc.invoiceDeliveryData.engagement = engagement;
			dashboardAgreement.myDeliveryModal = modalService.open(deliveryModalOptions(engagement, "ForInvoiceDashboard"));

			dashboardAgreement.myDeliveryModal
					.result.then(
					function (result) {
						//console.log('[Delivery Modal complete]', result);
						if (result && result.status === 'confirm') {
						//AC 32101 code changes starts
							if(engagement.engagementId){
								showDeliveryConfirmation(result.data);
								void 0;
							} else {
								showModalDeliveryPass('scheduleDeliveryPinCheck');
								void 0;
							}
							//AC 32101 code changes ends
						} else if(result && result.status === 'pickup') {
							showModalDeliveryPass('scheduleDeliveryPinCheck');
							void 0;
						}
					},
					function (result) {
						void 0;
					}
			)
		}

		function deliveryModalOptions(engagement, deliveryPart) {
			deliveryPart = deliveryPart || '';
			return {
				templateUrl: 'partials/modal_invoiceAndDelivery'+deliveryPart+'.html',
				controller: 'delivery',
				controllerAs: 'delivery',
				scope: $scope
			}
		}

		//address confirm modal for schedule delivery
		function showDeliveryConfirmation(engagement) {
			var deliveryConfirmModal = modalService.open(deliveryModalOptions(engagement, 'Confirm'));
			deliveryConfirmModal
					.result.then(
					function (result) {
						if (result && result.status === 'confirm') {
							showModalDeliveryPass('scheduleDeliveryPinCheck');
							void 0;
						}
					},
					// dismissed
					function (result) {
						if (result && result.status === 'declined') {
							modalService.open({
								templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html',
								scope: $scope
							});
						}
					}
			)
		}

		//show schedule delivery associate pass check modal
		function showModalDeliveryPass(name, windowClass) {
			windowClass = windowClass || '';
			modalService.open({
				templateUrl: '/partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				controller: 'delivery',
				controllerAs: 'delivery',
				scope: $scope
			});
		}

		function deliveryStatusVerbiage(status, date) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
					return 'Not Scheduled';
				case deliveryStatus.pendingPayment:
					return 'Pending Payment Completion';
				case deliveryStatus.scheduled:
					return 'Scheduled for ' + moment(date).format('MM/DD/YY');
				case deliveryStatus.pickup:
					return 'Pickup on ' + moment(date).format('MM/DD/YY');
				case deliveryStatus.confirmed:
					return 'Delivery Confirmed';
				case deliveryStatus.readyToSchedule:
					return 'Ready to Schedule';
				default:
					return 'Error retrieving status'
			}
		}

		function setDeliveryStatusClass(status) {
			var deliveryStatus = itemStatus.delivery;
			switch(status) {
				case deliveryStatus.notStarted:
				case deliveryStatus.pendingPayment:
					return "process-standby";
				case deliveryStatus.pickup:
				case deliveryStatus.scheduled:
					return "process-yellow itemNoEdit";
				case deliveryStatus.readyToSchedule:
					return "process-problem";
				case deliveryStatus.confirmed:
					return "process-complete itemNoEdit";
				default:
			}
		}

		function scheduleDeliveryPinCheck(pin){

		}

		function showModal(name, windowClass) {
			windowClass = windowClass || '';

			dashboardAgreement.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope
			});
		}

		function showAcknowledgeAgreementBtn (engagement) {
			if(engagement.customerAgreementStatus.toLowerCase() === 'pending' && $rootScope.invoiceMethod.toLowerCase() === "manualpayable") {
				return true;
			}
			return false;
		}
		
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
		function showitemsperpage(){
			if(dashboardAgreement.dashboardAgreementData!=undefined && dashboardAgreement.dashboardAgreementData.length<5){
				return false;
			}
			return true;
		}
		//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
		
        function showdisputetBtn (engagement) {
			//9464 - Dispute/hide disable button in invoice dashboard code changes starts
        	if(engagement.customerAgreementStatus.toLowerCase() === 'payment sent' || engagement.customerAgreementStatus.toLowerCase() === 'payment processing'){
		    	if(passphrase == 'xteam' && $rootScope.isBarcodeEnabled == true){
		    		return false;
		    	}else{
		    		return true;
		    	}
			}
        	return false;
			//9464 - Dispute/hide disable button in invoice dashboard code changes ends
		}
		function acknowledgeAgreement(engagementData) {
			/*console.log("[Engagement Data]", engagementData);
			console.log("[Engagement Data]", engagementData.agreementId);*/
			showModal('Processing', 'processing-modal');
			agreementFactory.agreementInventory(engagementData.agreementId)
			.then(function(data) {
				dashboardAgreement.agreementInventoryItems = JSON.parse(data);
				showModal("agreementItems","dashboard-agreement");
				void 0;
				$scope.agreementAcknowledgementDetails.invoiceNumber = dashboardAgreement.agreementInventoryItems.invoiceId;
				$scope.disputeDetailsData.invoiceId = dashboardAgreement.agreementInventoryItems.invoiceId;
			}, function(data) {
				$log.log('error getting agreement inventory itmes', data);
			});
		}
        //9464 - Disable/hide dispute button in invoice dashboard code changes starts
		function disableDispute() {
			if(passphrase == 'xteam' && $rootScope.isBarcodeEnabled == true) {
				return false;
			}
			return true;
		}
		 //9464 - Disable/hide dispute button in invoice dashboard code changes ends
		
		function agreementPinValidate(data){
			var pin = data.agreementPin;
			var auth = SharedDataSvc.base64.decode($cookies.getObject('store_first_name'));
			if(auth === pin) {
				dashboardAgreement.invalidAgreementPin = false;
				if(data.invoiceNumber !== undefined){
					//Story - 28842 Code changes start
					//showModal('Processing', 'processing-modal');
					$scope.acknowledge.acknowledgeAgreementDetails.invoiceNumber.$setValidity('invoiceNumber', false);	
					//Story - 28842 Code changes end 
					dashboardAgreement.confirmAcknowledgementItems(data);
				} else{
					//Story - 28842 Code changes start
					//showModal('Processing', 'processing-modal');
					$scope.dispute.disputeDetails.invoiceId.$setValidity('invoiceId', false);	
					//Story - 28842 Code changes end 
					dashboardAgreement.confirmDispute(data);
				}
			} else {
				void 0;
				dashboardAgreement.invalidAgreementPin = true;
			}
		}

		function confirmAcknowledgementItems(data){
			void 0;
			void 0;

			dashboardAgreement.acknowledgementDetails['agreementId'] = dashboardAgreement.agreementInventoryItems.agreementId;
			dashboardAgreement.acknowledgementDetails['invoiceId'] = data.invoiceNumber;
			dashboardAgreement.acknowledgementDetails['salesPerson'] = data.salesPerson;
			dashboardAgreement.acknowledgementDetails['payableStatus'] = 'CONFIRMED';
			dashboardAgreement.acknowledgementDetails['disputeReason'] = null;
			dashboardAgreement.acknowledgementDetails['disputeComment'] = null;
			dashboardAgreement.disputeDetails['disputedAmount'] = null;
			void 0;

			agreementFactory.updateAgreement(dashboardAgreement.acknowledgementDetails)
			.then(function(respone){
				void 0;
				//Story - 28842 Code changes start
				$scope.acknowledge.acknowledgeAgreementDetails.invoiceNumber.$setValidity('invoiceNumber', true);
				if (respone.status_message === "Duplicate Invoice id found"	&& respone.status_details === "ERROR") {
					dashboardAgreement.duplicateInvoiceId = true;
				} else {
					$route.reload();
					}
				//Story - 28842 Code changes end
			},
			function(respone){
				$log.log('Error Updating Agreement Acknowledgement Details', respone);
			});
						
		}

		function disputeAgreement(data) {
			void 0;
			showModal("agreementDisputeDetails");
			$scope.disputeDetailsData.invoiceId = data.invoiceId;
			dashboardAgreement.agreementInventoryItems = data;
		}

		/*function checkDisputeAmount (val) {
			if(val.indexOf('$') > -1){
				val = val.split('$')[1];
			}
			if(val > dashboardAgreement.disputeAmountMaxPrice) {
				dashboardAgreement.disputeAmountLimitExceed = true;
				$scope.dispute.disputeDetails.disputeAmount.$setValidity('disputeAmount', false);
				return false;
			}else{
				dashboardAgreement.disputeAmountLimitExceed = false;
				$scope.dispute.disputeDetails.disputeAmount.$setValidity('disputeAmount', true);
			}
		}
*/
		function confirmDispute(data){
			void 0;
			void 0;

			//AC 24784 code changes - removed indexof check
			/*if(data.disputeAmount.indexOf('$') > -1){
				data.disputeAmount = data.disputeAmount.split('$')[1];
			}*/

			dashboardAgreement.disputeDetails['agreementId'] = dashboardAgreement.agreementInventoryItems.agreementId;
			dashboardAgreement.disputeDetails['invoiceId'] = data.invoiceId;;
			dashboardAgreement.disputeDetails['salesPerson'] = null;
			dashboardAgreement.disputeDetails['payableStatus'] = 'DISPUTED';
			dashboardAgreement.disputeDetails['disputeReason'] = data.disputeReason.disputeOption;
			dashboardAgreement.disputeDetails['disputeComment'] = data.disputeComments;
			dashboardAgreement.disputeDetails['disputedAmount'] = data.disputeAmount;
			void 0;

			agreementFactory.updateAgreement(dashboardAgreement.disputeDetails)
			.then(function(respone){
				void 0;
				//Story - 28842 Code changes start
				$scope.dispute.disputeDetails.invoiceId.$setValidity('invoiceId', true);
				if (respone.status_message === "Duplicate Invoice id found"	&& respone.status_details === "ERROR") {
					dashboardAgreement.duplicateInvoiceId = true;
				} else {
					$route.reload();
					}
				//Story - 28842 Code changes end
			},
			function(respone){
				$log.log('Error Agreement Acknowledgement Disputed', respone);
			});			
		}

		function resetFormData() {
			$scope.disputeDetailsData = {};
			$scope.agreementAcknowledgementDetails = {};
			dashboardAgreement.duplicateInvoiceId = false;//Story - 28842 code change
		}	

		function summaryStatusSortFn(item) {
			var status = summaryStatusForSortingFn(item);
			var sortMap = {
				'Pending': 1,
				'Payment Processing': 2,
				'Payment Sent': 3,
				'Dispute Claimed': 4,
				//Feature - 8023 code change starts
				'Conflict':5,
				'Inactive':6
				//Feature - 8023 code change end 
			}
			return sortMap[status];
		}

		
		function summaryStatusForSortingFn(engagement) {
			if (engagement.customerAgreementStatus.toUpperCase() === "PENDING") {
				return 'Pending';
			}else if (engagement.customerAgreementStatus.toUpperCase() === "PAYMENT PROCESSING") {
				return 'Payment Processing';
			} else if (engagement.customerAgreementStatus.toUpperCase() === "PAYMENT SENT") {
				return 'Payment Sent';
			} else if (engagement.customerAgreementStatus.toUpperCase() === "DISPUTE CLAIMED") {
				return 'Dispute Claimed';
			//Feature - 8023 code change starts
			} else if (engagement.customerAgreementStatus.toUpperCase() === "CONFLICT") {
				return 'Conflict';
			} else if (engagement.customerAgreementStatus.toUpperCase() === "INACTIVE") {
				return 'Inactive';
			//Feature - 8023 code change end
			}
		}

		function generateDays(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.days = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.days.push(day);
				day++;
			}
		}

		function searchSuccess () {
			$scope.isSearched = true;
			$scope.searchError = false;
			dashboardAgreement.searchIconElement.removeClass("glyphicon-minus active");
			dashboardAgreement.searchIcon = "Plus";
		}

		function onInputChangeDisable(input, inputText) {
			if(inputText !== undefined  && inputText.length <= 0) {
				return false;
			}
			void 0;
			$scope.searchParameters.$pristine = false;
			if(input === 'agreementDate') {
				angular.element(".search-parameter input").prop('disabled', true);
				$scope.searchParameters.dateOfBirth.month.$dirty = true;

			}else {
				angular.element(".search-parameter input, .search-parameter select").prop('disabled', true);
				angular.element("#"+ input).prop('disabled', false).focus();
			}		
		}

		function resetSearchResults(action) {
			$scope.dashboardAgreement.dashboardAgreementData = dashboardAgreement.initialDashboardData;
			$scope.searchParametersValue = angular.copy($scope.master);
			$scope.isSearched = false;
			$scope.searchError = false;
			dashboardAgreement.agreementCount = 0;
			if(action !== "reset"){
				$scope.searchedValue = "Search";
				$route.reload();
			}
			$scope.searchParameters.$pristine = true;
			$scope.searchParameters.dateOfBirth.month.$dirty = false;
			angular.element(".search-container .search-box").prop('disabled', true);
			angular.element(".search-parameter input, .search-parameter select").prop('disabled', false);
			angular.element(".search-parameter select[name='day']").prop('disabled', true);
		}


		function customSearch(searchParams) {
			void 0;
			
			// Service based search for serach parameter as Agreement Number 
			if(searchParams.agreementNo !== undefined && searchParams.agreementNo !== "") {
				showModal('Processing', 'processing-modal');//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
				$scope.searchedValue = searchParams.agreementNo;
				searchSuccess();
				agreementFactory.searchAgreement('agreementNumber', searchParams.agreementNo)
				.then(function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = JSON.parse(data);
					void 0;
					if($scope.dashboardAgreement.dashboardAgreementData !== null) {
						dashboardAgreement.agreementCount = $scope.dashboardAgreement.dashboardAgreementData.length;
					} else {
						dashboardAgreement.agreementCount = 0;
					}				
				}, function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = "";
					$log.log('Error Getting Search Result For Agreement Number', data);
				});
			}

			// Service based search for serach parameter as Reference/Invoice Number 
			else if (searchParams.referenceNo !== undefined && searchParams.referenceNo !== "") {
				showModal('Processing', 'processing-modal');//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
				$scope.searchedValue = searchParams.referenceNo;
				searchSuccess();
				agreementFactory.searchAgreement('invoice', searchParams.referenceNo)
				.then(function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = JSON.parse(data);
					void 0;
					if($scope.dashboardAgreement.dashboardAgreementData !== null) {
						dashboardAgreement.agreementCount = $scope.dashboardAgreement.dashboardAgreementData.length;
					} else {
						dashboardAgreement.agreementCount = 0;
					}
				}, function(data) {
					modalService.dismiss();//VAN-2993-Merchant payable - single OKTA login for 47 stores , to see all agreements in dashboard and confirm
					$scope.dashboardAgreement.dashboardAgreementData = "";
					$log.log('Error Getting Search Result For Reference Number', data);
				});
			}

			// FE based search as per Customer Name
			else if(searchParams.name !== undefined && searchParams.name !== "") {
				searchSuccess();
				$scope.searchedValue = searchParams.name;
				var output = [];
				angular.forEach(dashboardAgreement.initialDashboardData, function(data) {
					if(data.customerFullName.toUpperCase().indexOf(searchParams.name.toUpperCase()) > -1) {
						output.push(data);
					}
				});
				$scope.dashboardAgreement.dashboardAgreementData = output;
				dashboardAgreement.agreementCount = output.length;
			}

			// FE based search as per Agreement Date
			else if(searchParams.dob !== undefined && searchParams.dob !== "") {
				searchSuccess();
				void 0;
				var date = searchParams.dob.month + '/' + searchParams.dob.day + '/' + searchParams.dob.year;
				var formattedDate = moment(date).format('MM/DD/YY');
				$scope.searchedValue = formattedDate;
				var output = [];
				angular.forEach(dashboardAgreement.initialDashboardData, function(data) {
					if(data.agreementOpenDate.indexOf(formattedDate) > -1) {
						output.push(data);
					}
				});
				$scope.dashboardAgreement.dashboardAgreementData = output;
				dashboardAgreement.agreementCount = output.length;
			}

			else {
				$scope.isSearched = false;
				$scope.searchError = true;
				dashboardAgreement.searchIconElement.addClass("glyphicon-minus active");
				dashboardAgreement.searchIcon = "Minus";
			}
		}

		////////////////////////////////////////////////
		//  WATCHERS  //////////////////////
		////////////////////////////////////////////////

		$scope.$watch('searchParametersValue.dob.month', generateDays);
	}
	DashboardAgreement.$inject = ["$scope", "$rootScope", "$location", "$route", "$idle", "$routeParams", "$log", "$sce", "$cookies", "modalService", "authentication", "SharedDataSvc", "agreementFactory", "getStoreInfoFactory", "getTrainingLibraryFactory", "getInitialDashboard"];
})();

///////////////////////////////////////////
//////////////////////////////////////////
///   ESTIMATE CONTROLLER   /////////////
////////////////////////////////////////
///////////////////////////////////////
(function () {

	'use strict';

	angular
		.module('app.estimate')
		.controller('Estimate', Estimate)

	/* @ngInject */
	function Estimate(
		$q,
		$scope,
		$rootScope,
		$cookies,
		$http,
		$routeParams,
		$location,
		orderEstimatePutFactory,
		agreementFactory,
		modalService,
		authentication,
		SharedDataSvc,
		orderService,
		datesFactory,
		stateRestrictFactory,
		updateEngagementItemFactory,
		getStoreInfoFactory,
		productFactory,
		customerDataFactory,
		applicationService
	) {
		/*jshint validthis: true */
		var estimate = this;
		var monthNames = ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
		];

		$rootScope.hideMenuButtons = false;

		estimate.engagementId = $routeParams.engagementId;
		estimate.estimateSub = {};
		estimate.estimatePut = {};
		estimate.sessionVars = [];
		estimate.orderDataArray = [];
		estimate.routeParam = $routeParams.engagementId;
		estimate.globalVars = {};
		estimate.globalVars.order = [];
		estimate.globalVars.order = SharedDataSvc.globalVars.order;
		estimate.globalmessage = SharedDataSvc.globalmessage;
		estimate.globalmessageShow = SharedDataSvc.globalmessageShow;
		estimate.storeInfo;
		estimate.restrictions;
		estimate.paymentModeInput;
		estimate.prodCat;
		estimate.engagement;
		$scope.deliveryFeePaymentValue = {
			feePaymentMode: SharedDataSvc.globalVars.estimateDeliveryOption
		}
		$scope.parseFloat = function (value) {
			return parseFloat(value);
		}

		$scope.noEsvNoDel;
		$scope.onlyEsvNoDel;
		$scope.noESVonlyDel;
		$scope.withEsvDel;
		$scope.selectionFlag;

		estimate.estimatePut = SharedDataSvc.globalVars.orderEstimatePut;
		estimate.estimateSub = SharedDataSvc.globalVars.estimateReturn;
		estimate.estimateAll = SharedDataSvc.globalVars.estimateReturnAll;
		estimate.confirmId = confirmId;
		estimate.associatePassFail = false;
		estimate.invalidPassPhrase = false;
		estimate.empPassPhrase;
		estimate.errorMessage = '';
		
		/*VAN-774 -  Biweekly renewal payments Long Term - Starts*/
		estimate.paymentModeSelected = 'Monthly';
		estimate.estimateSubMonthly = SharedDataSvc.globalVars.estimateReturnMonthly;
		estimate.estimateSubBiweekly = SharedDataSvc.globalVars.estimateReturnBiweekly;
		/*VAN-774 -  Biweekly renewal payments Long Term - Ends*/
		
		/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
		estimate.estimateSubQuarterly = SharedDataSvc.globalVars.estimateReturnQuarterly;
		estimate.changePaymentMode = changePaymentMode;
		/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
		
		//Public Methods
		estimate.showModal = showModal;
		estimate.getOrderEstimate = getOrderEstimate;
		estimate.confirmOrder = confirmOrder;
		estimate.proceedOrderConfirmation = proceedOrderConfirmation;
		estimate.submitInvoiceAndSalesDetails = submitInvoiceAndSalesDetails;
		$scope.submitEmployeePassWithOutSaveOrder = submitEmployeePassWithOutSaveOrder;
		$scope.submitEmployeePass = submitEmployeePass;
		
		estimate.invoiceNumber = '';
		estimate.associate = '';
		estimate.errors = {
			invoiceNumber: false,
			associate: false
		};

		estimate.navigateTo = navigateTo;
		estimate.verifyEmployeePassPhrase = verifyEmployeePassPhrase;
		// LOAD EXISTING RECORD?
		if (typeof estimate.routeParam !== "undefined") {
			// existing record
			void 0;
		} else {
			// new record
		}

		estimate.showDiscountPrompt = false;
		estimate.discountMsg = '';

		init();

		function init() {

			if (estimate.engagementId === null) {
				estimate.navigateTo('/dashboard');
			}
			
			getStoreInfoFactory.getData()
			.then(function(response) {
				void 0;
				estimate.storeInfo = response;
				
				/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
				if(estimate.storeInfo.paymentModeEnabled!=undefined){
					$scope.biWeeklyEnabled =  estimate.storeInfo.paymentModeEnabled[0].biweekly;
					$scope.quadEnabled =  estimate.storeInfo.paymentModeEnabled[0].quad;
				}
				/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
				
				// if there is no estimate data coming from order summary, redirect to estimate or dashboard
				/*VAN - 774 -  Biweekly renewal payments Long Term - Starts*/
				/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
				if(estimate.storeInfo.lowInitialPaymentEnabled && estimate.storeInfo.paymentModeEnabled!=undefined){
					if((_.isEmpty(estimate.estimateSubMonthly)) && (_.isEmpty(estimate.estimateSubBiweekly)) && (_.isEmpty(estimate.estimateSubQuarterly))){
						estimateRedirect();
					}
				/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
				}else{
					if ((_.isEmpty(estimate.estimateSub))){
						estimateRedirect();
					}
				}
				function estimateRedirect(){
					var target = (estimate.engagementId) ? '/ordersummary/' + estimate.engagementId : '/dashboard';
					estimate.navigateTo(target);
					return;
				}
				/*VAN - 774-  Biweekly renewal payments Long Term - Ends*/
				
				if(estimate.storeInfo.infoPrompts){
					estimate.showDiscountPrompt = true;
					estimate.discountMsg =  ($rootScope.selectedLanguage =='en' ? estimate.storeInfo.infoPrompts.discount.msg_en : estimate.storeInfo.infoPrompts.discount.msg_es);
				}
				checkEpoSchedule();//VAN-1415-Bob's - Change EPO array to 120 days
				$scope.agreementAmount = response.minAgreementAmount;
				stateRestrictFactory.getAllStoreRestrictions(estimate.storeInfo.state)
				.then(function(response) {
					void 0;
					estimate.restrictions = response;
				});
			});
			
			//get product category list
			productFactory.getProductCategories().then(function(response) {
				estimate.prodCat = response.productCategories;
			});

			//converting milliseconds to date : defect : AC - 10770
			/*VAN - 774 -  Biweekly renewal payments Long Term - Starts*/
			if(estimate.estimateAll!=undefined){
				for (var i = 0, len = estimate.estimateAll.length; i < len; i++) {
					var date = new Date(estimate.estimateAll[i].paymentDueDate);
					estimate.estimateAll[i].paymentDueDateString = monthNames[date.getMonth()] + ' ' + date.getDate();
				}
			}
			/*VAN - 774 -  Biweekly renewal payments Long Term - Ends*/
			//checkEpoSchedule();//Commented as part of //VAN-1415-Bob's - Change EPO array to 120 days
			void 0;
			void 0;

			$scope.selectionFlag = !estimate.estimatePut.feeArray[1].feeAcrossTerm && estimate.estimatePut.feeArray[1].feeAmount > 0;

			$scope.noEsvNoDel = (estimate.estimatePut.feeArray[0].feeAmount <= 0 && estimate.estimatePut.feeArray[1].feeAmount <= 0);
			$scope.onlyEsvNoDel = (estimate.estimatePut.feeArray[0].feeAmount > 0 && estimate.estimatePut.feeArray[1].feeAmount <= 0);
			$scope.noESVonlyDel = (estimate.estimatePut.feeArray[0].feeAmount <= 0 && estimate.estimatePut.feeArray[1].feeAmount > 0);
			$scope.withEsvDel = (estimate.estimatePut.feeArray[0].feeAmount > 0 && estimate.estimatePut.feeArray[1].feeAmount > 0);
		}

		function checkEpoSchedule() {
			/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/
			if(SharedDataSvc.globalVars.estimateReturn.epoPaymentArray!=undefined){
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturn.epoPaymentArray;
				if (SharedDataSvc.globalVars.epoSchedule === undefined || SharedDataSvc.globalVars.epoSchedule.length === 0) {
					estimate.epoSchedule = [];
					return;
				}
			}else{
				if(SharedDataSvc.globalVars.estimateReturnMonthly.epoPaymentArray!=undefined)
					SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnMonthly.epoPaymentArray;
			}
			// set to scope
			estimate.epoSchedule = SharedDataSvc.globalVars.epoSchedule;
			formatEpoSchedule();
			/*else{
				showModal('orderError');
			}*/
			/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/
		}
		
		function formatEpoSchedule(){
			if(estimate.epoSchedule !=undefined && estimate.epoSchedule.length!=0){
				var firstPaymentMonth = estimate.epoSchedule[0].epoAfterPaymentNumber;
				/*VAN-1390 - EPO showing incorrectly in review estimate*/
				if (firstPaymentMonth >= 3) {
					var days = (firstPaymentMonth - 1) * 30;
					//VAN-1415-Bob's - Change EPO array to 120 days - Starts
					if(estimate.storeInfo != undefined && estimate.storeInfo.epoOptionDays != undefined ){
						if(estimate.storeInfo.epoOptionDays === '120'){
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										//VAN-1390 - EPO showing incorrectly in review estimate
										epoCashPrice: "120" + " Days EPO"
								}
								estimate.epoSchedule.unshift(epoEntry);
							}
						}else if(estimate.storeInfo.epoOptionDays === '90'){
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										//VAN-1390 - EPO showing incorrectly in review estimate
										epoCashPrice: "90" + " Days EPO"
								}
								estimate.epoSchedule.unshift(epoEntry);
							}	
						}
						else if(estimate.storeInfo.epoOptionDays === '100'){
							for (var i = firstPaymentMonth - 1; i > 0; i--) {
								var epoEntry = {
										epoAfterPaymentNumber: i,
										epoCashPrice: "100" + " Days EPO"
								}
								estimate.epoSchedule.unshift(epoEntry);
							}	
						}
					}else{
						for (var i = firstPaymentMonth - 1; i > 0; i--) {
							var epoEntry = {
									epoAfterPaymentNumber: i,
									//VAN-1390 - EPO showing incorrectly in review estimate
									epoCashPrice: "90" + " Days EPO"
							}
							estimate.epoSchedule.unshift(epoEntry);
						}
					}
					//VAN-1415-Bob's - Change EPO array to 120 days - Ends
				}
			}
		}
		
		/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/
		function changePaymentMode(selectedPayment){
			if(selectedPayment=='Monthly'){
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnMonthly.epoPaymentArray;
			}else if(selectedPayment=='Biweekly'){
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnBiweekly.epoPaymentArray;
			}else{
				SharedDataSvc.globalVars.epoSchedule = SharedDataSvc.globalVars.estimateReturnQuarterly.epoPaymentArray;
			}
			estimate.epoSchedule = SharedDataSvc.globalVars.epoSchedule;
			formatEpoSchedule();
		}
		/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/

		function navigateTo(target) {
			$location.path(target);
		}

		function getOrderEstimate(paymentMode) {
			if (paymentMode !== estimate.paymentModeInput) {
				void 0;
				estimate.paymentModeInput = paymentMode;

				if (paymentMode === "upfront") {
					$scope.selectionFlag = true;
				} else {
					$scope.selectionFlag = false;
				}

				var delFeePaymentOptionFlagValue = estimate.storeInfo.deliveryFeePaymentOption;
				var esvdelFeePaymentOptionFlagValue = estimate.storeInfo.esvTermOption;

				var orderData = orderEstimatePutFactory.compileData(SharedDataSvc.globalVars.orderSummary, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, estimate.prodCat, paymentMode, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				void 0;
				SharedDataSvc.globalVars.orderEstimatePut = orderData;

				// to calculate quotes on review estimate
				var currentDate = datesFactory.getCurrentDate();
				var quoteDates = datesFactory.setQuoteDates(currentDate);

				estimate.orderDataArray = [_.clone(orderData),
				_.clone(orderData),
				_.clone(orderData)];

				_.forEach(quoteDates, function (paymentDate, i) {
					estimate.orderDataArray[i].requestDte = currentDate;
					estimate.orderDataArray[i].nextRenewDte = paymentDate.date;
				});

				agreementFactory.setQuotes(estimate.orderDataArray)
					.then(function () {
						estimate.quotes = agreementFactory.quotes;
						_.forEach(quoteDates, function (paymentDate, i) {
							estimate.quotes[i].paymentDueDate = moment(paymentDate.date);
						});
						//var target = '/estimate/' + $routeParams.engagementId;
						SharedDataSvc.globalVars.estimateReturn = estimate.quotes[0];
						estimate.estimateSub = estimate.quotes[0];

						SharedDataSvc.globalVars.estimateReturnAll = estimate.quotes;
						estimate.estimateAll = estimate.quotes;

					}, function (response) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(response);
						estimate.navigateTo('/dashboard');
					});
			}
		}

		

		function confirmOrder(paymentModeSelected) {
			/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/
			/*VAN-858 - Quad Weekly Payment Frequency */
			if(!estimate.storeInfo.lowInitialPaymentEnabled || estimate.storeInfo.paymentModeEnabled==undefined){
				paymentModeSelected ="";
			}else{
				SharedDataSvc.globalVars.paymentModeSelected=paymentModeSelected;
			}
			/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/

			$scope.noEmpPass = false;
			$scope.errEmpPass = false;
			if (!checkMinMax()) return;

			if (SharedDataSvc.globalVars.orderSummary.hasItems === false) {
				// alert 'you need to complete order'
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_confirmOrderModal.tpl.html',
					backdrop: 'static'
				});
			} else {
				if(estimate.showDiscountPrompt) {
					var discountPromptModal = modalService.open({
						scope: $scope,
						size: 'xs',
						templateUrl: 'partials/alert_discountPromotion.html',
						backdrop: 'static'
					});

					discountPromptModal.result.then(function (result) {
						proceedOrderConfirmation();
					});
				} else {
					proceedOrderConfirmation();
				}				
			}			
		}

		function proceedOrderConfirmation() {
			// confirm 'are you sure you want to confirm?'
			if (!SharedDataSvc.globalVars.orderSummary.storeInfo.isManned || $cookies.getObject("isHybrid")) {
				var confirmOrderModal = modalService.open({
					title: 'Confirm ID',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/modal_checkCustomerId.html',
					backdrop: 'static'
				});
			} else {					
				if (SharedDataSvc.globalVars.orderSummary.storeInfo.invoiceAtOrderconfirmation == 1) {
					// set ID confirm to 0
					SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
					var confirmOrderModal = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
						templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
						backdrop: 'static'
					});
				}
				else {
					// set ID confirm to 0
					SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
					if(SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing || (SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing === undefined)) {
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							//templateUrl: 'partials/modal_confirmOrderLoginCheck.html',
							backdrop: 'static'
						});
					}else {
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							templateUrl: 'partials/modal_confirmOrderLoginCheck.html',
							backdrop: 'static'
						});
					}
				}
			}
		}

		function submitInvoiceAndSalesDetails() {
			void 0;
			SharedDataSvc.globalVars.orderSummary.errors.invoiceNumber = false;
			SharedDataSvc.globalVars.orderSummary.errors.associate = false;

			if (estimate.invoiceNumber == '') {
				SharedDataSvc.globalVars.orderSummary.errors.invoiceNumber = true;
			}
			if (estimate.associate == '') {
				SharedDataSvc.globalVars.orderSummary.errors.associate = true;
			}
			if (!SharedDataSvc.globalVars.orderSummary.errors.invoiceNumber && !SharedDataSvc.globalVars.orderSummary.errors.associate) {
				SharedDataSvc.globalVars.order.currentEngagement.invoiceNum = estimate.invoiceNumber;
				SharedDataSvc.globalVars.order.currentEngagement.salesAssociateID = estimate.associate;
				//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - starts
				updateEngagementItemFactory.getData(estimate.engagementId).then(function (response) {
					SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = response.agreementStatus;
					saveOrder('Confirmed', '/dashboard');
				}, function (response) {
					// error
					void 0;
				});
				//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - ends
			}
		}

		function deliveryModalOptions(engagement, deliveryPart, windowClass) {
			deliveryPart = deliveryPart || '';
			windowClass = windowClass || '';
			return {
				templateUrl: 'partials/modal_invoiceAndDelivery' + deliveryPart + '.html',
				backdrop: 'static',
				controller: 'delivery',
				controllerAs: 'delivery',
				windowClass : windowClass,
				scope: $scope
			}
		}

		function verifyEmployeePassPhrase(val) {
			if(val) {
				agreementFactory.validatePassPhrase(val).then(function(response) {
						void 0;
						estimate.associatePassFail = false;
						saveOrder('Confirmed');
					}, function (response) {
						void 0;
						SharedDataSvc.validatePassPhraseAttempts = SharedDataSvc.validatePassPhraseAttempts + 1;
						void 0;
						if(SharedDataSvc.validatePassPhraseAttempts === 3) {
							SharedDataSvc.validatePassPhraseAttempts = 0;
							navigateTo('dashboard');
							estimate.invalidPassPhrase = false;
							estimate.associatePassFail = false;
						} else {
							estimate.errorMessage = response.data.errorData.status_message;
							estimate.invalidPassPhrase = true;
							estimate.associatePassFail = true;
						}
				});

			}else {
				estimate.associatePassFail = true;
				estimate.invalidPassPhrase = false;
			}

		}

		function submitEmployeePass(pass) {
			$scope.noEmpPass = false;
			$scope.errEmpPass = false;

			if (pass) {
				var auth = authentication.storePass;

				if (auth === pass) {
					void 0;
					//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - starts
					updateEngagementItemFactory.getData(estimate.engagementId).then(function (response) {
						//SharedDataSvc.globalVars.order.currentEngagement = response;
						SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = response.agreementStatus;
						saveOrder('Confirmed', '/dashboard');
					}, function (response) {
						// error
						void 0;
					});
					//AC21230 - Web - Agreement status remains in voided status after editing and confirming the order - ends
				} else {
					$scope.errEmpPass = true;
					void 0;
				}
			} else {
				$scope.noEmpPass = true;
				void 0;
			}
		}

		function submitEmployeePassWithOutSaveOrder(pass) {
			$scope.noEmpPass = false;
			$scope.errEmpPass = false;
			if (pass) {
				var auth = authentication.storePass;
				if (auth === pass) {
					void 0;
					//modalService.dismiss();
					var modal_invoiceAndSales = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/modal_invoiceAndDeliveryDetailsEstimate.html',
						backdrop: 'static'
					});
				} else {
					$scope.errEmpPass = true;
					void 0;
				}
			} else {
				$scope.noEmpPass = true;
				void 0;
			}
		}

		function checkMinMax() {
			void 0;
			void 0;

			if (!SharedDataSvc.globalVars.orderSummary.orderSubtotal || SharedDataSvc.globalVars.orderSummary.orderSubtotal < $scope.agreementAmount) {
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'estimate',
					templateUrl: 'partials/alert_minOrderModal.tpl.html',
					backdrop: 'static'
				});
				return false;
			}
			if (SharedDataSvc.globalVars.orderSummary.currentEngagement.applicationStatus != 'Accepted') {
				$scope.approvalAmount = SharedDataSvc.globalVars.orderSummary.approvalAmount;
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'estimate',
					templateUrl: 'partials/alert_manualOrderConfirmError.tpl.html',
					backdrop: 'static'
				});
				confirmOrderModal.result.then(function(action){
					if(action){
						saveOrder('InProgress', '/dashboard');
					}
				});
				return false;
			}

			/*var totalOrderAmount = SharedDataSvc.globalVars.orderSummary.orderSubtotal + 
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.extendedServiceAmount;

			if(totalOrderAmount > SharedDataSvc.globalVars.orderSummary.approvalAmount){
				var totalOrderAmountErrorModal = modalService.open({
					scope: $scope,
					templateUrl: 'partials/alert_totalOrderAmountError.html',
					backdrop: 'static'
				});
				return false;
			}*/
			
			/*VAN-4036 -AWP - order screen invoice reduction payment changes
			hide the max order alert in the order screen if the flag is enabled */

			if (SharedDataSvc.globalVars.orderSummary.orderSubtotal > SharedDataSvc.globalVars.orderSummary.approvalAmount) {
				if( !SharedDataSvc.invoiceReductionPayment) {
					$scope.approvalAmount = SharedDataSvc.globalVars.orderSummary.approvalAmount;
					var confirmOrderModal = modalService.open({
						show: true,
						scope: $scope,
						controllerAs: 'estimate',
						templateUrl: 'partials/alert_maxOrderModal.tpl.html',
						backdrop: 'static'
					});
					return false;
				}
			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - Start
//			if (SharedDataSvc.globalVars.orderSummary.orderSubtotal > 3000) {
//				$scope.approvalAmount = 3000;
//				var confirmOrderModal = modalService.open({
//					show: true,
//					scope: $scope,
//					controllerAs: 'estimate',
//					templateUrl: 'partials/alert_maxOrderModal.tpl.html',
//					backdrop: 'static'
//				});
//				return false;
//			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - End
			return true;
		}

		function confirmId() {
			void 0;
			SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 1;
			//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
			if($rootScope.xpartnerLogin){
				updateEngagementItemFactory.getData(estimate.engagementId).then(function (response) {
					//SharedDataSvc.globalVars.order.currentEngagement = response;
					SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = response.agreementStatus;
					saveOrder('Confirmed', '/dashboard');
				}, function (response) {
					// error
					void 0;
				});
				//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
			}else if(estimate.storeInfo.invoiceAtOrderconfirmation == 1){
				// set ID confirm to 0
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
					backdrop: 'static'
				});
			}
			else {
				if(SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing || (SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing === undefined)) {
					var confirmOrderModal = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
						backdrop: 'static'
					});
				} else {
					var confirmOrderModal = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/modal_confirmOrderLoginCheck.html',
						backdrop: 'static'
					});
				}
			}
		}

		function saveOrder(orderStatus, navigateTo) {
			// check for unneeded param
			$location.search('confirm', null);
			// Start VAN 3066
			if(SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.deliveryFee == ''){
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.deliveryFee = 0;
			}
			if(SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.extendedServiceAmount == ''){
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent.extendedServiceAmount = 0;
			}
			// End
			//SharedDataSvc.globalVars.orderSummary.order.currentEngagementOrder.orderContent = SharedDataSvc.globalVars.orderSummary.currentEngagementOrder.orderContent;
			void 0;

			orderService.save(SharedDataSvc.globalVars.orderSummary.order, orderStatus, navigateTo).then(function(){
				void 0;
				//Opening the schedule delivery pop up here
				if(!SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing && (SharedDataSvc.globalVars.orderSummary.storeInfo.immediateInvoicing !== undefined) && (SharedDataSvc.globalVars.orderSummary.order.currentEngagement.applicationStatus.toLocaleLowerCase() === "accepted") && (estimate.storeInfo.invoiceAtOrderconfirmation !== 1)) {
					estimate.onConfirmOrderSelectDeliveryModal = modalService.open(deliveryModalOptions(estimate.engagementId, "OnConfirmOrder", "no-Invoice-Modal"));
				}				
			}, function(){
				void 0;
			});
		};

		function showModal(name, windowClass) {
			windowClass = windowClass || '';
	
			estimate.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				scope: $scope,
				show: true
			});
		}

	}
	Estimate.$inject = ["$q", "$scope", "$rootScope", "$cookies", "$http", "$routeParams", "$location", "orderEstimatePutFactory", "agreementFactory", "modalService", "authentication", "SharedDataSvc", "orderService", "datesFactory", "stateRestrictFactory", "updateEngagementItemFactory", "getStoreInfoFactory", "productFactory", "customerDataFactory", "applicationService"];
})();

// The Agreement controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function() {
	'use strict';

	angular.module('myApp').controller('Agreement', Agreement);

	Agreement.$inject = ["$scope", "$log", "$rootScope", "$routeParams", "$q", "$translate", "modalService", "$location", "agreementFactory", "getStoreInfoFactory", "customerDataFactory", "orderEstimatePutFactory", "datesFactory", "paymentFactory", "updateEngagementItemFactory", "productFactory", "SharedDataSvc", "base64", "stateRestrictFactory", "applicationService", "$cookies"];
	function Agreement($scope, $log, $rootScope, $routeParams, $q, $translate, modalService, $location, agreementFactory, getStoreInfoFactory, customerDataFactory, orderEstimatePutFactory, datesFactory, paymentFactory, updateEngagementItemFactory, productFactory, SharedDataSvc, base64, stateRestrictFactory, applicationService, $cookies) {
		/*jshint validthis: true */
		var vm = this,
		    keys;
		//AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		// set scope variable for processing modal
		$scope.modalTitle = 'Processing';

		// Models
		vm.customer = {};
		vm.engagement;
		vm.agreementData;
		vm.storeInfo;
		vm.stateRestrictions;
		vm.restrictions;
		vm.agreements = [{
			name : {
				en : en.agreement_header_RPA,
				es : es.agreement_header_RPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			// type: 'Kw7N2pTLkRsh202k2wKUdeAbHsosOoIcruqDUqo3idnw2YBrw2mFAAJ2dWQVGef4Y'
			type : 'RA',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_SA,
				es : es.agreement_header_SA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : undefined,
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_RAA,
				es : es.agreement_header_RAA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ACK',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_EPOA,
				es : es.agreement_header_EPOA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'EPO90',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_LDW,
				es : es.agreement_header_LDW
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'LDW',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_BPA,
				es : es.agreement_header_BPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'CLB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_AA,
				es : es.agreement_header_AA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ARB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_WA,
				es : es.agreement_header_WA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'FEE',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_DFA,
				es : es.agreement_header_DFA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'dlf',
			eSign : false,
			signature : null
		}];

		vm.agreementsSpanish = [{
			name : "Acuerdo de Alquiler con opción a compra",

		}, {
			name : "Acuerdo suplementario (s)",
		}, {
			name : "Reconocimiento del contrato de alquiler",

		}, {
			name : "Enmienda de opción de compra temprana",

		}, {
			name : "Acuerdo de exención de daños de responsabilidad",

		}, {
			name : "Acuerdo de Benefits Plus",

		}, {
			name : "Acuerdo de Arbitraje",

		}, {
			name : "Contrato de garantía",
		}, {
			name : "Acuerdo de entrega",
		}];

		vm.suppAgreementsName = {

			"CAM" : "Camcorder HD Supplement acknowledgement",
			"COP" : "Computer Rental Supplement acknowledgement",
			"CRB" : "Crib Waiver acknowledgement",
			"CYC" : "E-Cycle acknowledgement",
			"GAS" : "Gas Dryer Waiver acknowledgement",
			"LCD" : "Large Screen TV Supplement acknowledgement",
			"RAN" : "Range Waiver acknowledgement",
			"SOC" : "Summary of Cost acknowledgement",
			"VID" : "Electronic Device Rental Supplement acknowledgement"

		};

		vm.suppAgreementsSpanishName = {

			"CAM" : "Videocámara HD suplementaria",
			"COP" : "Arrendamiento de computadora suplementario",
			"CRB" : "Crib Waiver",
			"CYC" : "Reconocimiento  de  E-Cycle",
			"GAS" : "Reconocimiento  de Secadora de gas",
			"LCD" : "Reconocimiento  de TV de pantalla grande",
			"RAN" : "Reconocimiento  de renuncia  de Cocina económica",
			"SOC" : "Reconocimiento  de Resumen de Costo",
			"VID" : "Reconocimiento  de Dispositivo electronico"

		};

		vm.orderData = [_.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder)];

		vm.orderDataForAdjustablePayment = _.clone(SharedDataSvc.globalVars.agreementOrder);

		// Variables
		vm.quote;
		vm.firstPayment = 0;
		vm.showPaymentOptionsModal = false;
		vm.paymentInfo = [];
		vm.showConsentAgree = false;
		vm.quoteDates = datesFactory.quoteDates;
		vm.quotes = agreementFactory.quotes;
		vm.engagementId = $routeParams.engagementId;
		vm.pdfLinks = [];
		vm.unsignedAgreement;
		vm.activeAgreementIndex = 0;
		vm.activeAgreement = vm.agreements[vm.activeAgreementIndex];
		vm.modal;
		vm.orderContent;
		vm.orderArray = [];
		vm.declineBpModalShown = false;
		vm.languagePreference = true;
		vm.hideBp;
		vm.customerInRadius = true;
		vm.isZipValid = true;
		vm.storeLatLng;
		vm.customerDistance;
		vm.storeRadius;
		vm.customerInStoreRadius;
		vm.term1 = false;
		vm.term2 = false;
		vm.term3 = false;
		vm.term4 = false;
		vm.term5 = false;
		vm.term6 = false;
		vm.term7 = false;
		vm.isAnyUnchecked = true;
		vm.inputInitials = "";
		vm.employeeId;
		vm.employeePinError;
		vm.pinAttempts = 0;
		vm.initialPaymentStatus = false;
		vm.initialPaymentErrorClass = "";
		vm.noEmpPin = false;
		vm.processingFee;
		vm.isEPOAmendmentEnabled = false;
		vm.suppCheck = [];
		vm.isAllSuppChecked = false;
		vm.showSupp = false;
		vm.showWarrantyAgreement = false;
		vm.showDelWarrantyAgreement = false;
		vm.adjustableDateSelection = "";
		vm.ipMinDate = "";
		vm.ipMaxDate = "";
		vm.arrAdjustablePayment = "";
		vm.isAdjustablePayment = false;
		vm.quoteGotAsPerSelection = "";
		vm.prodCat;
		$scope.isSpanishStore;

		// Controller Functions
		vm.submitAgreements = submitAgreements;
		vm.createAgreement = createAgreement;
		vm.signAgreement = signAgreement;
		vm.declineAgreement = declineAgreement;
		vm.openAgreementPdf = openAgreementPdf;
		vm.navigateTo = navigateTo;
		vm.signLdw = signLdw;
		vm.showModal = showModal;
		vm.setFirstPaymentDate = setFirstPaymentDate;
		vm.handleRentalAgreement = handleRentalAgreement;
		vm.handleRentalAgreementDecline = handleRentalAgreementDecline;
		vm.handleRentalAcknowledgement = handleRentalAcknowledgement;
		vm.handleLdwDecline = handleLdwDecline;
		vm.handleEsvAgreement = handleEsvAgreement;
		vm.handleEsvDelAgreement = handleEsvDelAgreement;
		vm.handleBpDecline = handleBpDecline;
		vm.handleEpoDecline = handleEpoDecline;
		vm.handleSupAgreement = handleSupAgreement;
		vm.handleSupAgreementDecline = handleSupAgreementDecline;
		vm.handleSkipBp = handleSkipBp;
		vm.getFirstPaymentOptions = getFirstPaymentOptions;
		vm.setLang = setLang;
		vm.declineRental = declineRental;
		vm.setAgreementSignature = setAgreementSignature;
		vm.declineConsent = declineConsent;
		vm.hideBPWhenLDWisDeclined = hideBPWhenLDWisDeclined;
		vm.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		vm.navigateToApplication = navigateToApplication;
		vm.declineBp = declineBp;
		vm.declineBpNew = declineBpNew;
		vm.doIfChecked = doIfChecked;
		vm.doIfSuppChecked = doIfSuppChecked;
		vm.handleInitialsStateCheck = handleInitialsStateCheck;
		vm.setEmployeeId = setEmployeeId;
		vm.checkStore = checkStore;
		vm.validateAssociatePasswordCash = validateAssociatePasswordCash;
		vm.checkAssociatePin = checkAssociatePin;
		vm.createInventoryList = createInventoryList;
		vm.isSkipButtonEnable = false;
		vm.agreementHTID = "";
		//AC 25996 code changes
		//AC13303 code changes starts
		vm.voidAgreement = voidAgreement;
		vm.agreementStatus = "";
		//AC13303 code changes ends
		vm.dateSelectionDone = dateSelectionDone;
		vm.setOrderDateAsPerSelection = setOrderDateAsPerSelection;
		vm.openfirstPaymentSelectionCalendar = openfirstPaymentSelectionCalendar;
		//AC24649 Code changes starts
		vm.agreementCodeVerify = agreementCodeVerify;
		vm.reselectNextPaymentError = reselectNextPaymentError;
		$scope.showVerification = true;
		//AC24649 Code changes ends
		//7573 feature
		$scope.verifyAgreement = {};
		$scope.verifyAgreement.textCode = '';
		$scope.verifyAgreement.textCodeAttemptsNumber = 0;
		$scope.verifyAgreement.textCodeError = false;
		$scope.verifyAgreement.agrVerificationNumber = '';
		$scope.submitTextCode = submitTextCode;

		vm.getRAInitials = null;
		vm.setHTML = setHTML;
		vm.cancelAndNavigate = cancelAndNavigate;
		$scope.navigateTo = navigateTo;
		
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		$scope.isPromoSelected= false;
		vm.selectedPromo;
		vm.showPaymentInitial = showPaymentInitial;
		vm.showInitialPaymentOptions = showInitialPaymentOptions;
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
		
		init();

		function init() {

			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');

			//setting processing fee
			//vm.processingFee = SharedDataSvc.globalVars.estimateReturn.processingFee;

			var currentDate = datesFactory.getCurrentDate(),
			    quoteDates = datesFactory.setQuoteDates(currentDate),
			    findData = {
				'firstName' : null,
				'lastName' : null,
				'phone' : null
			},
			    engagement;
			void 0;
			vm.quoteDates = quoteDates;
			setOrderDates(quoteDates);

			// Default language to English
			SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';

			// reset inventory
			SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];

			void 0;

			showModal('Processing', 'processing-modal');

			getStoreInfoFactory.getData().then(function(response) {
				$log.log('[Agreement.init] STORE INFO', response);
				vm.storeInfo = response;
				SharedDataSvc.globalVars.storeSetup = response;
				if(response.vanStoreId) {
					$rootScope.storeNo = response.vanStoreId;
 					$rootScope.state = response.state;
 				}
				$rootScope.invoiceMethod = response.invoiceMethod;
				setSkipButtonVisibility(vm.storeInfo.state);
				vm.isEPOAmendmentEnabled = vm.storeInfo.isEPOAmendmentEnabled;

				vm.arrAdjustablePayment = vm.storeInfo.adjustableInitPayment.split(",");
				vm.isAdjustablePayment = parseInt(vm.arrAdjustablePayment[0]);

				stateRestrictFactory.getStateRestrictions().then(function (response) {
					vm.stateRestrictions = response.stateRestrictions[0];
					vm.getRAInitials = _.find(vm.stateRestrictions.allowRAInitials, {'state': vm.storeInfo.state}); 
				});

				stateRestrictFactory.getAllStoreRestrictions(vm.storeInfo.state).then(function(response) {
					vm.restrictions = response;
					vm.storeLatLng = {
						'lat' : vm.storeInfo.latitude,
						'lng' : vm.storeInfo.longitude
					};
					vm.storeRadius = response.allowedRentalRadiusMiles;
					storeExclusions();
					$log.log('[Agreement.init] STORE RESTRICTIONS', response);
				});
			});

			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				$log.log('[Agreement.init] customerDataFactory.findByEngagementId response: ', response);
				vm.orderContent = (response.orderContent !== null && response.orderContent !== '') ? JSON.parse(response.orderContent) : null;
				vm.engagement = response;

				$log.log('[Agreement.init] engagement Order', vm.orderContent);
				$log.log('[Agreement.init] engagement', vm.engagement);

				vm.customer.customerFirstName = findData.firstName = vm.engagement.customer.fName;
				vm.customer.customerLastName = findData.lastName = vm.engagement.customer.lName;
				findData.phone = vm.engagement.customer.phNum;
				findData.engagementId = vm.engagement.engagementId;//AC14937 code changes added

				vm.engagement = {};
				var engagementProps = ['engagementId', 'orderStatus', 'deliveryStatus', 'paymentStatus', 'salesStatus', 'agreementStatus', 'applicationStatus', 'orderOriginator'];
				// cherry pick the needed properties for a proper engagement
				for (var i = 0; i < engagementProps.length; i++) {
					vm.engagement[engagementProps[i]] = response[engagementProps[i]];
				}
				vm.engagement['customerId'] = response.customer.customerId;
				vm.engagement['customerHtID'] = response.customerHTId;
				vm.engagement['customerHtStoreId'] = response.customerHTStoreId;
				vm.engagement['vanStoreId'] = response.vanStore.storeId;
				void 0;

				applicationService.getApplication(findData).then(function(response) {
					void 0;
					vm.customerFind = ( typeof response !== "object") ? JSON.parse(response) : response;
					//VAN-672 - Low Down Initial Payment should be displayed on RA
					vm.promoSelectedAtAgreement = (vm.customerFind.VanEngagement.promoSelectedAtAgreement !== null && vm.customerFind.VanEngagement.promoSelectedAtAgreement !== '') ? JSON.parse(vm.customerFind.VanEngagement.promoSelectedAtAgreement) : null;
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
					} else {
						vm.languagePreference = false;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
					}

					if (!response.VanEngagement) {
						void 0;
						showModal('customerNotFound');
					} else {
						void 0;
						/* Setting the agreement HTID from findCustomer response */
						vm.agreementHTID = response.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if((zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								void 0;
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					}

				}, function(response) {
					//modalService.dismiss();
					vm.customerFind = response.data.errorData;

					if (!response.data.errorData.VanEngagement) {
						void 0;
						if(response.data.errorData.status_code == "ENG-A4001-400"){
							showModal('agreementAlreadyExist');
						}else{
							showModal('customerNotFound');
						}
					} else {
						void 0;
						vm.agreementHTID = response.data.errorData.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if((zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					}
				});

			}).catch(function(response) {
				$log.log('[Agreement.init] could not get customer data', response);
			});

			//get processing fee
			orderEstimatePutFactory.getProcessingFee().then(function(processingFee) {
				vm.processingFee = processingFee;
			});

			//get product category list
			productFactory.getProductCategories().then(function(response) {
				vm.prodCat = response.productCategories;
			});
		}

		function doIfChecked() {
			vm.isAnyUnchecked = !(vm.term1 && vm.term2 && vm.term3 && vm.term4 && vm.term5 && vm.term6 && vm.term7);
		}

		function doIfSuppChecked() {
			//	console.log("[Supp Check]", vm.suppCheck);
			var suppAgreeLen = vm.agreements[1].type.length;
			if (vm.agreements[1].type.indexOf("CYC") > -1) {
				suppAgreeLen = suppAgreeLen - 1;
			}
			var currSuppCheckLen = vm.suppCheck.length;

			if ((suppAgreeLen === currSuppCheckLen)) {
				vm.isAllSuppChecked = true;
				for (var z = 0; z < suppAgreeLen; z++) {
					if (vm.suppCheck[z] == false || vm.suppCheck[z] == undefined) {
						vm.isAllSuppChecked = false;
					}
				}
			} else {
				vm.isAllSuppChecked = false;
			}

			//console.log(vm.isAllSuppChecked);
		}

		function setLang(currentLang) {
			if (!currentLang) {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
			} else {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
			}
			void 0;
		}

		function storeExclusions() {
			var bp = _.where(vm.agreements, {
				type : 'CLB'
			}),
			    ldw = _.where(vm.agreements, {
				type : 'LDW'
			});

			// console.log('skipBp', vm.restrictions.skipBp);
			// console.log('skipldw', vm.restrictions.skipLdw);

			if (vm.restrictions.skipBp) {
				delete vm.agreements[5];
				//Have changed here
				vm.hideBp = true;
			}

			if (vm.restrictions.skipLdw) {
				delete vm.agreements[4];
				//Have changed here
			}

			if (!vm.isEPOAmendmentEnabled) {
				delete vm.agreements[3];
				//Have changed here
			}

			void 0;
		}

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		};

		function handleCustomerOutOfRadius() {
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.applicationStatus = 'OutOfArea';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Successfully updated engagement');
				navigateTo('dashboard');
			}, function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Updating engagement failed');
				modalService.dismiss();
			});
		}

		function navigateToApplication() {
			$log.log(vm.engagement);
			customerDataFactory.findByEngagementId(vm.engagement.engagementId).then(function(response) {
				$log.log('[Agreement.getCustomer] customer', response);
				var person = {
					firstName : response.customer.fName,
					lastName : response.customer.lName,
					phone : response.customer.phNum,
					hasEngagementFromAgreement : true
				};
				var personData = window.btoa(JSON.stringify(person));
				$location.path('application/' + vm.engagement.engagementId).search({
					data : personData
				});
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function dateSelectionDone() {
			vm.showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.setOrderDateAsPerSelection(vm.adjustableDateSelection);

			/*var paymentModal = modalService.open({ //changes for 9619, VAN-365:VAN: Same payment option for all on same agreement put on hold
				scope: $scope,
				templateUrl: 'partials/modal_paymentOptions.html',
				backdrop: 'static',
				windowClass: 'paymentOptionsModal'
			});
			paymentModal.result.then(function(){
				vm.orderDataForAdjustablePayment.paymentOption = vm.paymentOption;*/
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
					void 0;
					vm.quoteGotAsPerSelection = response;

					vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');

				}, function(response) {
					void 0;
					modalService.dismiss();
				});
			/*});	*/	
		}

		function getFirstPaymentOptions() {

			void 0;

			if (vm.orderContent.orderItems.length > 0) {
				showModal($translate.instant("common_label_processing"), 'processing-modal');

				_.forEach(vm.orderContent.orderItems, function(order, i) {
					productFactory.getProductInfo(vm.orderContent.orderItems[i]).then(function(response) {
						$log.log('product info ' + i, response);
						vm.orderArray.push(response);

						if (i === vm.orderContent.orderItems.length - 1) {

							if (vm.isAdjustablePayment) {
								setOrderDataForAdjustablePayment();
								if (vm.orderDataForAdjustablePayment.feeArray && vm.orderDataForAdjustablePayment.feeArray.length > 1) {
									if (vm.orderContent.deliveryFeeOneTime) {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = false;
									} else {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = true;
									}
								}
							} else {
								setOrderData();
								_.forEach(vm.orderData, function(orderData) {
									if (orderData.feeArray && orderData.feeArray.length > 1) {
										if (vm.orderContent.deliveryFeeOneTime) {
											orderData.feeArray[1].feeAcrossTerm = false;
										} else {
											orderData.feeArray[1].feeAcrossTerm = true;
										}

									}
								});
							}

							if (vm.isAdjustablePayment) {
								var start = parseInt(vm.arrAdjustablePayment[1]);
								var end = parseInt(vm.arrAdjustablePayment[2]);
								void 0;
								
								datesFactory.getDateByDaysFromNow(start).then(function(response) {
									vm.ipMinDate = response;
									vm.adjustableDateSelection = response;
									datesFactory.getDateByDaysFromNow(end).then(function(responseData) {
										vm.ipMaxDate = responseData;
										vm.openfirstPaymentSelectionCalendar();
									});
								});

							} else {
								agreementFactory.setQuotes(vm.orderData).then(function() {
									vm.quotes = agreementFactory.quotes;
									vm.showModal('firstPayment', 'first-payment-options-modal');

									// $log.log('Quotes', agreementFactory.quotes);
									// $log.log('quote dates', qouteDates);
									$log.log('[Agreement.getFirstPaymentOptions] store info', vm.storeInfo);
								});
							}
						}
					});
				});

			} else {
				void 0;
			}
		}

		function openfirstPaymentSelectionCalendar() {
			vm.showModal('adjustablePayment', 'first-adjustable-payment-modal');
		}

		function handleRentalAgreement() {
			if (!vm.agreements[0].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('eAgreementNotice');
			}
		}

		function handleRentalAgreementDecline() {
			if (!vm.agreements[0].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineRental');
			}
		}

		function handleSupAgreement() {
			if (!vm.agreements[1].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('signSupAgree');
			}
		}

		function handleSupAgreementDecline() {
			if (!vm.agreements[1].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineSupp');
			}
		}

		function handleLdwDecline(index) {
			if (!vm.agreements[4].viewed) {//Have changed here
				showModal('viewPdfBeforeSigning');
			} else {
				vm.agreements[index].eSign = false;
				showModal('declineLdw');
			}
		}

		function handleEsvAgreement() {
			if (!vm.agreements[7].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineEsv');
			}
		}

		function handleEsvDelAgreement() {
			if (!vm.agreements[8].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineEsvDel');
			}
		}

		function handleSkipBp() {
			if (!vm.agreements[4].viewed) {
				showModal('viewPdfBeforeSigning');
			} else {
				vm.declineBpNew();
			}
		}

		function handleBpDecline() {
			if (!vm.agreements[5].viewed) {//Have changed here
				showModal('viewPdfBeforeSigning');
			} else {
				vm.declineBpNew();
			}
		}

		function handleRentalAcknowledgement() {
			if (!vm.agreements[2].viewed) {//Added new function here
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('agreementAcknowledge');
			}

		}

		function handleEpoDecline() {
			if (!vm.agreements[3].viewed) {//Added new function here
				showModal('viewPdfBeforeSigning');
			} else {
				showModal('declineEpo');
			}
		}

		function handleInitialsStateCheck() {
			if(vm.getRAInitials){
				vm.initialInstructions = vm.getRAInitials.initialInstructions;
				showModal('agreementRAInitials');
			}
			/*if (vm.storeInfo.state == "FL") {
				showModal('agreementInitials');
			}*/ 
			else {
				showModal('eSignAgreement');
			}
		}

		function setSkipButtonVisibility(state) {
			if (state == "FL" || state == "CA") {
				vm.isSkipButtonEnable = true;
			}
		}

		function showModal(name, windowClass) {
			windowClass = windowClass || '';
			modalService.open({
				templateUrl : '../../../partials/modal_' + name + '.html',
				backdrop : 'static',
				windowClass : windowClass,
				scope : $scope
			});
		}

		function signLdw() {
			if (!vm.agreements[4].agree) {//Have changed me
				showModal('declineLdw', 'decline-ldw-modal');
			}
		}

		function showCorrectOrder(activeAgreementIndex) {
			if (vm.agreements[5] && vm.agreements[4]) {//Have changed here
				if (vm.activeAgreementIndex === 5 && !vm.agreements[4].agree && !vm.bpNotContingentOnLDW) {//Have changed here
					// console.log('LDW has been declined');
					vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
					//Have changed here
					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else if (vm.activeAgreementIndex === 6 && !vm.agreements[5].agree && !vm.bpNotContingentOnLDW && vm.agreements[4].agree !== 'DECLINED') {//Have changed here
					// console.log('BP is not signed');
					if (!vm.agreements[4].agree) {//Have changed here
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
						//Have changed here
					} else {
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[5].name.es : vm.agreements[5].name.en;
						//Have changed here
					}

					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}

		}

		function signAgreement(index) {
			void 0;

			if (showCorrectOrder(index)) {
				if (vm.agreements[vm.activeAgreementIndex].agree === 'SIGNED') {
					// vm.agreements[vm.activeAgreementIndex].agree = undefined;
				} else {
					if (hasAgreementBeenViewed(index)) {
						/* The line below will reset the esign pop up check box always to none */
						vm.agreements[index].eSign = false;
						showModal('eSignAgreement');
						// vm.agreements[vm.activeAgreementIndex].agree = 'SIGNED';
					} else {
						showModal('viewPdfBeforeSigning');
					}
				}
			}

		}

		function hideBPWhenLDWisDeclined(index) {
			void 0;
			if (!vm.restrictions.bpNotContingentOnLdw && index === 4) {//Have changed here
				if (vm.agreements[4].agree === 'DECLINED') {//Have changed here
					void 0;
					vm.hideBp = true;
				} else {
					void 0;
					vm.hideBp = false;
				}
			}
		}

		function declineRental() {
			//Story 29237 code changes starts
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.agreementStatus = 'Declined';
			updateEngagementItemFactory.putData(vm.engagement)
		          .then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement failed');
				modalService.dismiss();
			});
		}

		//Start - AC-13303 - to update agreementStatus in VAN
		function voidAgreement() {
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement with agreement status voided');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement for agreement status voided failed');
				modalService.dismiss();
			});
		}

		//End - AC-13303 - to update agreementStatus in VAN

		function declineBpNew() {
			if (vm.storeInfo.state == "FL") {
				showModal('declineBenefitsPlus');
			} else {
				showModal('benefitPlusDeclineConfirm');
			}
		}

		function declineBp(index) {
			void 0;
			if (vm.restrictions.dontSignDeclineBp) {
				showModal('declineBenefitsPlus');
			} else {
				/* The line below will reset the esign pop up check box always to none */
				vm.agreements[vm.activeAgreementIndex].eSign = false;
				showModal('eDeclineAgreement');
			}
		}

		function declineAgreement(index) {
			var sign = $translate.instant('agreement_label_declineDesc') +'\n';
			sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
			sign += datesFactory.getCurrentDate();

			vm.agreements[index].signature = sign;

			// console.log('decline', index);
			if (vm.agreements[index].agree === 'DECLINE') {
				if (vm.agreements[index].agree !== 1) {
					vm.agreements[index].agree = 'DECLINED';
					// setAgreementSignature('DECLINE');
				} else {
					showModal('declineLdw');
				}
				// Unsign BP if LDW is declined
				if (vm.agreements[index] === 4) {//Have changed here
					declineAgreement(5);
					//Have changed here
				}
			} else {
				vm.agreements[index].agree = 'DECLINED';
				// setAgreementSignature('DECLINE');
			}
		}

		function getCustomer(engagement) {
			$log.log('[Agreement.getCustomer] engagement: ', engagement);
			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				customer = response;
				vm.customer = response;
				$log.log('[Agreement.getCustomer] customer', customer);
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function setOrderDates(paymentDates) {
			var requestDate = datesFactory.getCurrentDate();
			void 0;
			_.forEach(paymentDates, function(paymentDate, i) {
				vm.orderData[i].requestDte = requestDate;
				vm.orderData[i].nextRenewDte = paymentDate.date;
			});
		}

		function setOrderDateAsPerSelection(selectedDate) {
			var requestDate = datesFactory.getCurrentDate();
			void 0;

			vm.orderDataForAdjustablePayment.requestDte = requestDate;
			vm.orderDataForAdjustablePayment.nextRenewDte = moment(selectedDate).format('YYYY-MM-DD HH:MM:SS');

		}

		function generateKeys() {
			SharedDataSvc.generatedkeys = SharedDataSvc.generatekeys();
			var my_keys = SharedDataSvc.generatedkeys;
			// clean the BS out of the keys
			var cleanKeys = my_keys[0].replace('-----BEGIN RSA PRIVATE KEY-----\n', '');
			my_keys[0] = cleanKeys.replace('\n-----END RSA PRIVATE KEY-----', '');
			// $scope.my_keys[0] = cleanKeys.replace('=', '');
			var cleanKeys3 = my_keys[1].replace('-----BEGIN PUBLIC KEY-----\n', '');
			my_keys[1] = cleanKeys3.replace('\n-----END PUBLIC KEY-----', '');
			SharedDataSvc.cleankeys = my_keys;
			// SharedDataSvc.agreementHeadersENCRYPT['Public-Key'] = my_keys[1];
			// $scope.my_keys[0] = cleanKeys.replace('=', '');
		}

		function formatItemPrice(price) {
			var formattedPrice;
			if (String(price).indexOf('.') > -1) {
				price = String(price).split('.');
				if (price[1].length === 2) {
					formattedPrice = Number(price[0] + price[1]);
				} else {
					formattedPrice = Number(price[0] + price[1] + '0');
				}
			} else {
				formattedPrice = Number(price + '00');
			}
			return formattedPrice;
		}

		//checking if Associate password in case of max associate pin attempts
		function validateAssociatePasswordCash(pass) {
			var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
			if (auth === pass) {
				vm.employeePinError = false;
				vm.employeeId = '';
				showModal('agreementEmployeePin');
			} else {
				vm.invalidAssociatePass = true;
			}
		}

		//asking for Associate PIN if it manned store
		function checkStore() {
			/*if (vm.storeInfo.isManned == true && (!$cookies.getObject('isHybrid'))) {
				showModal('agreementEmployeePin');
			} else {
				setFirstPaymentDate();
				createAgreement();
			}*/
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
			if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos && vm.selectedPromo === 'AutoPayRequired') {
				showPaymentInitial(vm.selectedPromo);
			}
			else{
				setFirstPaymentDate();
				createAgreement();
			}
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
		}
		
		//AC24649 Code changes starts
		function agreementCodeVerify() {
			if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
					($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
			void 0;
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
			agreementFactory.agreementVerification(vm.engagementId).then(
					function (response) {
						modalService.dismiss();
						$scope.textCode = response;
						var submitTextCodeModal = modalService.open({
							title: 'Confirm Email',
							templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
							scope: $scope,
							backdrop: 'static'
						});
					},
					// Error
					function (error) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(error);
						void 0;
					});
			} 
		}
		
		function reselectNextPaymentError() {
			modalService.open({
				templateUrl : 'partials/modal_confirmInitialPaymentInfo.html',
				backdrop : 'static',
				scope : $scope
			});
		}
		//AC24649 Code changes ends
		
		//checking for Associate PIN if entered or not
		function checkAssociatePin(pin) {
			if (pin) {
				setFirstPaymentDate();
				createAgreement();
			} else {
				$scope.noEmpPin = true;
				void 0;
			}
		}

		function setFirstPaymentDate() {
			if (vm.isAdjustablePayment) {
				var selectedDate = moment(vm.adjustableDateSelection).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				void 0;
			} else {
				var selectedDate = moment(vm.quoteDates[vm.firstPayment].date).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				void 0;
			}
		}

		//sending Associate PIN through post
		function setEmployeeId(employeeId) {
			SharedDataSvc.globalVars.agreementPrePut.empin = employeeId;
		}

		function processAgreementData() {
			//modalService.dismiss();
			if (vm.isAdjustablePayment) {
				setCustomerOrderDataForAdjustablePayment(vm.customerFind);
			} else {
				setCustomerOrderData(vm.customerFind);
			}

			//AC 25996 code changes
			if (isSameDayAgreement() && (vm.agreementHTID !== undefined && vm.agreementHTID !== null)) {
				void 0;
				handleSameDayAgreements();
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Starts
			else if (!isSameDayAgreement()&& vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && vm.customerFind.agreement.agreementStatus === 'ACTIVE' && vm.customerFind.VanEngagement.agreementStatus === 'Pending'){
				void 0;
				modalService.dismiss();
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Ends 
			else {
				showModal('langOption', 'modal-lang-option');
			}
		}
		
		function showSameDayAgreementModal(){
			void 0;		
			var processingModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_sameDayAgree.html',
				backdrop: 'static'
			});
		}

		function createAgreement() {
			void 0;
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			var agreementData = SharedDataSvc.globalVars.agreementPrePut,
			    prodCat,
			    extendedWarranty = {
				"racBrand" : "ACP",
				"itemModelNumber" : "ExtSvc",
				"racModelNumber" : "ESVPLAN-1",
				"depreciableLifeInMonths" : 24,
				"racRateCode" : "ESV396",
				"itemOriginalCost" : formatItemPrice(vm.orderContent.extendedServiceAmount)//AC21269 code change
			};

			// Set agreement data to send before creating the agreement
			void 0;
			agreementData.customer['id'] = vm.engagement.customerId;
			agreementData.customer.engagementId = Number(vm.engagementId);
			agreementData.customer.languagePreference = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference;
			agreementData.deliveryFee = formatItemPrice(vm.orderContent.deliveryFee);//AC23321 code change 
			agreementData.processingFee = (vm.processingFee * 100);
			agreementData.emailUnsignedAgreements = false;

			void 0;

			productFactory.getProductCategories().then(function(response) {
				prodCat = response.productCategories;

				void 0;
				_.forEach(vm.orderContent.orderItems, function(item, index) {
					var orderItem = {
						"racBrand" : "ACP", // 'ESV' for extended
						"itemModelNumber" : "", // 'ExtSvc' for extended
						"racModelNumber" : "",
						"depreciableLifeInMonths" : 24,
						"itemOptions" : "",
						"itemOriginalCost" : 0
					};

					orderItem.depreciableLifeInMonths = 24;
					orderItem.itemModelNumber = "";
					orderItem.itemOptions = productFactory.buildItemOptions(item);
					orderItem.itemOriginalCost = formatItemPrice(item.itemPrice);
					orderItem.racBrand = "ACP";
					orderItem.racModelNumber = productFactory.getRacModelNumber(prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
					void 0;
					agreementData.inventoryList.push(orderItem);
				});

				if (vm.orderContent.extendedServiceAmount > 0) {
					agreementData.inventoryList.push(extendedWarranty);
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
				if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos) {
					if(vm.selectedPromo === 'AutoPayRequired'){
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[0];
					}
					else {
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[1];
					}
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
				$log.log('[Agreement.createAgreement] PRE CREATE AGREEMENT', agreementData);

				agreementFactory.createAgreement(agreementData, keys).then(function(response) {
					response = JSON.parse(response);
					$log.log('[Agreement.createAgreement] AGREEMENT DATA', response);
					vm.agreementData = response;
					if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
							($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
						void 0;
						$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
						agreementFactory.agreementVerification(vm.engagementId).then(
								function (response) {
									modalService.dismiss();
									$scope.textCode = response;
									var submitTextCodeModal = modalService.open({
										title: 'Confirm Email',
										templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
										scope: $scope,
										backdrop: 'static'
									});
								},
								// Error
								function (error) {
									// sending error to Raygun
									SharedDataSvc.reportRaygun(error);
									void 0;
								});
					}else{
						modalService.dismiss();
						//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
					}
					setPdfData(response.documentsLst);
				}).catch(function(response) {
					modalService.dismiss();
					// reset inventory
					SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];
					
					/* Same day agreement scenario check */
					if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
						showSameDayAgreementModal();
						return;
					}

					//time out scenario, record Locked scenario 409 and brand model not found scenario
					if (response.status === 502 || response.data.errorData.status_code === "ENG-A4001-409" || response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {

						/* Showing modal only for one scenario. Rest all are handled in service-agent.js */
						if (response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {
							showModal('agreementError');
						}
						$log.log('[Agreement.createAgreement] could not create agreements', response);
					}else {
						$log.log('Error processing create agreement', response);
						modalService.dismiss();
						//var status_details = JSON.parse(response.data.errorData.status_details);
						void 0;
						//console.log('STATUS', status_details);
						//error handling when PIN is incorrect
						if (response.data.errorData.status_message.indexOf('Employee pin is not valid') > -1) {
							vm.pinAttempts = vm.pinAttempts + 1;
							vm.initialPaymentStatus = false;
							vm.initialPaymentErrorClass = '';
							vm.employeeId = '';
							void 0;
							if (vm.pinAttempts === 3) {
								vm.pinAttempts = 0;
								vm.invalidAssociatePass = false;
								showModal('tooManyPinAttemptsAgreement');
							} else {
								vm.employeePinError = true;
								showModal('agreementEmployeePin');
							}
						} else {
							vm.initialPaymentStatus = true;
							vm.initialPaymentErrorClass = 'alert-danger';
							vm.initialPaymentMessage = 'Payment Failed';
						}
					}
				});
			});
		}

		function submitTextCode(){
			$scope.verifyAgreement.textCodeError = false;
			$scope.verifyAgreement.textCodeAttemptsNumber += 1;
			if($scope.textCode == $scope.verifyAgreement.textCode && $scope.verifyAgreement.textCodeAttemptsNumber <= 3){
				modalService.dismiss();
				//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
			}else if($scope.verifyAgreement.textCodeAttemptsNumber == 3){
				$scope.verifyAgreement.textCodeAttemptsNumber = 0;
				$scope.verifyAgreement.textCode = '';
				navigateTo('dashboard');
			}
			else{
				$scope.verifyAgreement.textCodeError = true;
				$scope.verifyAgreement.textCode = '';
			}
		}

		function handleBpForAllAgreements() {
			if (vm.agreementData && vm.agreementData.documentsLst[6]) {
				$log.log('new agreements');
				vm.hideBp = false;
			} else {
				$log.log('old agreements');
				vm.hideBp = true;
			}
		}

		function isAllAgreementsSigned() {

			if (vm.agreements[0].agree === "SIGNED" && vm.agreements[2].agree === "SIGNED" && vm.agreements[6].agree === "SIGNED") {

				//If EPO is there
				if (vm.agreements[3]) {

					if (!(vm.agreements[3].agree === "SIGNED" || vm.agreements[3].agree === "DECLINED")) {
						return false;
					}
				}

				//If Supplementary is there
				if (vm.agreements[1].pdf.length > 0) {
					if (vm.agreements[1].agree !== "SIGNED") {
						return false;
					}
				}

				// If Warranty Agreement is there
				if (vm.agreements[7]) {
					if (!(vm.agreements[7].agree === 'SIGNED' || vm.agreements[7].agree === 'DECLINED')) {
						return false;
					}
				}

				// If Delivery fee Agreement is there
				if (vm.agreements[8]) {
					if (!(vm.agreements[8].agree === 'SIGNED' || vm.agreements[8].agree === 'DECLINED')) {
						return false;
					}
				}

				//If LDW and BP is there
				if (vm.agreements[4] && vm.agreements[5]) {

					//If LDW is signed
					if (vm.agreements[4].agree === "SIGNED") {

						//If BP is signed or declined
						if (vm.agreements[5].agree === "SIGNED" || vm.agreements[5].agree === "DECLINED") {
							return true;
						} else {
							return false;
						}

					} else {

						//If LDW is declined
						if (vm.agreements[4].agree === "DECLINED") {
							return true;
						} else {
							return false;
						}

					}

				} else {

					//If LDW and BP is not there
					if (!vm.agreements[4] && !vm.agreements[5]) {
						return true;
					} else {

						// If LDW is there and BP is not there
						if (vm.agreements[4] && !vm.agreements[5]) {

							//If LDW is either signed or declined
							if (vm.agreements[4].agree == 'SIGNED' || vm.agreements[4].agree == 'DECLINED') {
								return true;
							} else {
								return false;
							}

						} else {

							//If BP is either signed or declined
							if (vm.agreements[5].agree == 'SIGNED' || vm.agreements[5].agree == 'DECLINED') {
								return true;
							} else {
								return false;
							}

						}
					}
				}

			} else {

				return false;

			}

		}

		function submitAgreements() {
			void 0;
			var agree;
			// TODO - add check for states that do not include all agreements
			/*if(!vm.agreements[2] && !vm.agreements[3]) {					//Have changed here
			 agree = ['SIGNED', 'SIGNED'];
			 } else {
			 agree = _.pluck(vm.agreements, 'agree');
			 }*/

			if (isAllAgreementsSigned()) {
				showModal($translate.instant("common_label_processing"), 'processing-modal');
				// $log.log('all agreements signed');
				var promise = setCalcPaymentSpec();
				setSignatureData();

				promise.then(function() {

					void 0;
					void 0;

					paymentFactory.pdfSign(SharedDataSvc.globalVars.signatureTemplate).then(function(response) {
						void 0;

						paymentFactory.calculatePayment(SharedDataSvc.globalVars.paymentCalcSetup).then(function(response) {
							$log.log('AGREEMENTS COMPLETE', response);
							setAgreementStatus('Accepted');

							//AC 25996 code changes starts
							//if( !isSameDayAgreement()){ //On Sameday, we can have multiple agreements if order is edited. Commenting this condition because reading AgreementHTId from customerFind was overwriting agreementHTID column in db with previous agreement value.
							if (vm.agreementData !== undefined) {
								vm.engagement.agreementHtId = vm.agreementData.agreement.raNumber;
							} else {
								vm.engagement.agreementHtId = vm.customerFind.VanEngagement.agreementHTId;
							}
							//AC 25996 code changes ends

							void 0;
							updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
								$log.log('Successfully updated engagement');
								modalService.dismiss();
								navigateTo('dashboard');
							}, function(response) {
								$log.log('Updating engagement failed');
								// sending error to Raygun
								SharedDataSvc.reportRaygun(response);
								modalService.dismiss();
							});

							/* Resetting the globalVars for signature and paymentCalcSetup below */
							SharedDataSvc.globalVars.signatureTemplate = {
								"customerId" : null,
								"engagementId" : null,
								"language" : "english",
								"signatures" : []
							};

							SharedDataSvc.globalVars.paymentCalcSetup = {
								"customerEngagementId" : null,
								"paymentToDate" : "20150301", // initial payment date
								"id" : null, // ht store id
								"customerId" : null,
								"clubSwitch" : "N",
								"waiverSwitch" : "DECLINE",
								"hoCustId" : null, // customer ht id
								"agreementNumber" : null
							};

						}, function(response) {
							$log.log('Submit agreements failed');

							void 0;
							//AC13233 & AC12058 code changes start - on submit agreement
							if (response.status === 400 && response.data.errorData.status_details.indexOf('T2238') > -1) {
								$rootScope.modalMessage = $translate.instant("agreement_label_errorInUpdate")+"[789]";
								//14120 message change as per business.
								vm.modal = modalService.open({
									templateUrl : '../../../partials/alert_serviceError.tpl.html',
									backdrop : 'static',
									scope : $scope
								});
							} else {
								showModal('agreementError');
							}
							//AC13233 & AC12058 code changes end

							// sending error to Raygun
							SharedDataSvc.reportRaygun(response);
						});

					}, function(response) {

						void 0;
						showModal('agreementError');

					});
				});

			} else {
				$log.log('unsigned agreements');
				modalService.dismiss();

				showModal('unsignedAgreements', 'unsigned-agreements-modal');
			}
		}

		function setPdfData(documentsLst) {
			// console.log('set PDF data');
			vm.hideBp = true;
			vm.showWarrantyAgreement = false;
			vm.showDelWarrantyAgreement = false;

			vm.agreements[1].pdf = [];
			vm.agreements[1].type = [];

			_.forEach(documentsLst, function(agreement) {
				// console.log('DOCUMENTSLIST', agreement);
				if (agreement.documentType === 'RA') {
					vm.agreements[0].pdf = agreement.data;
				} else if (agreement.documentType === 'ACK') {
					vm.agreements[2].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'EPO90') {
					vm.agreements[3].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'LDW') {
					vm.agreements[4].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'ARB') {
					vm.agreements[6].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'FEE') {
					vm.showWarrantyAgreement = true;
					vm.agreements[7].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'dlf') {
					vm.showDelWarrantyAgreement = true;
					vm.agreements[8].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CLB') {
					vm.hideBp = false;
					vm.agreements[5].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CAM' || agreement.documentType === 'COP' || agreement.documentType === 'CRB' || agreement.documentType === 'CYC' || agreement.documentType === 'GAS' || agreement.documentType === 'LCD' || agreement.documentType === 'RAN' || agreement.documentType === 'SOC' || agreement.documentType === 'VID') {
					vm.agreements[1].pdf.push(agreement.data);
					vm.agreements[1].type.push(agreement.documentType);
					vm.showSupp = true;
				}

			});

			if (vm.agreements[7].pdf == undefined) {
				delete vm.agreements[7];
			}

			if (vm.agreements[8].pdf == undefined) {
				delete vm.agreements[8];
			}

			void 0;

			if (vm.hideBp && vm.agreements[5]) {
				vm.agreements[5].agree = 'SIGNED';
				//Have changed here
				vm.agreements[5].viewed = true;
				//Have changed here
				void 0;
			}
			void 0;
		}

		function setAgreementViewed(index) {
			vm.agreements[index].viewed = true;
		}

		function setAgreementUnviewed(index) {
			vm.agreements[index].viewed = false;
		}

		function hasAgreementBeenViewed(index) {
			return vm.agreements[index].viewed;
		}

		function openAgreementPdf(index) {
			setAgreementViewed(index);

			if (index == 1) {

				var supPdfArr = [];
				for (var x = 0; x < vm.agreements[index].pdf.length; x++) {
					var pdfBlob = SharedDataSvc.createPdfBlob(vm.agreements[index].pdf[x]);
					supPdfArr.push(pdfBlob);

					if (navigator.msSaveOrOpenBlob) {
						navigator.msSaveOrOpenBlob(pdfBlob);
						var returnVal = true;
					} else {
						var objectURL = window.URL.createObjectURL(pdfBlob);
						var returnVal = window.open(objectURL, "_blank");
					}

				}

				if (!returnVal) {
					setAgreementUnviewed(index);
					void 0;
				} else {
					setAgreementViewed(index);
				}

			} else {
				var pdfBlob = SharedDataSvc.createPdfBlob(vm.agreements[index].pdf);
				if (navigator.msSaveOrOpenBlob) {
					navigator.msSaveOrOpenBlob(pdfBlob);
				} else {
					var objectURL = window.URL.createObjectURL(pdfBlob);
					window.open(objectURL);
				}
			}

		}

		function setAgreementSignature(status) {
			
			if(SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == "ENGLISH"){
				var sign = 'Signed via click-through AcceptanceNOW web portal.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}else{
				var sign = 'Firmado vía virtual a través del sitio web AcceptanceNow.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}
			
			

			void 0;
			void 0;
			vm.agreements[vm.activeAgreementIndex].agree = status;
			vm.agreements[vm.activeAgreementIndex].signature = sign;

			void 0;
			void 0;
			//AC13194 Code changes start
			if (vm.agreements[5] && vm.agreements[5].pdf !== undefined && vm.agreements[5].pdf !== null) {
				hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
			}
			//AC13194 Code changes end
		}

		function setSignatureData() {

			var signatureData = SharedDataSvc.globalVars.signatureTemplate;

			signatureData.customerId = vm.engagement.customerId;
			signatureData.engagementId = Number(vm.engagementId);
			signatureData.language = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference.toLowerCase();
			//	signatureData.pdfProfileName = vm.storeInfo.state;

			if (vm.agreements[1] && vm.agreements[1].agree === "SIGNED") {

				signatureData.signatures = [];
				var typeLen = vm.agreements[1].type.length;

				for (var b = 0; b < typeLen; b++) {
					var signData = {
						"docType" : vm.agreements[1].type[b],
						"signature" : vm.agreements[1].signature
					};

					signatureData.signatures.push(signData);
				}

			}
			void 0;

			if (vm.agreements[2] && vm.agreements[2].agree === "SIGNED") {
				var ackSignObj = {
					"signature" : vm.agreements[2].signature,
					"docType" : vm.agreements[2].type
				};

				signatureData.signatures.push(ackSignObj);
			}

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here

				var ldwSignObj = {
					"formSwitch" : true,
					"signature" : vm.agreements[4].signature,
					"docType" : vm.agreements[4].type
				};

			} else {

				if (vm.agreements[4]) {
					var ldwSignObj = {
						"formSwitch" : false,
						"signature" : vm.agreements[4].signature,
						"docType" : vm.agreements[4].type
					};

				}

			}
			if (ldwSignObj !== undefined) {
				signatureData.signatures.push(ldwSignObj);
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED') {//Have changed here

				var bPlusObj = {
					"formSwitch" : true,
					"frequency" : "MONTHLY",
					"signature" : "",
					"docType" : vm.agreements[5].type
				};

			} else {

				if (vm.agreements[5]) {

					var bPlusObj = {
						"formSwitch" : false,
						"frequency" : "MONTHLY",
						"signature" : "",
						"docType" : vm.agreements[5].type
					};

				}

			}

			if (!vm.hideBp && vm.agreements[5]) {

				bPlusObj.signature = vm.agreements[5].signature;

			} else {

				if (vm.agreements[5]) {

					vm.agreements[5].agree = "";
					//Have changed here
					bPlusObj = undefined;

				}

			}
			if (bPlusObj !== undefined) {
				signatureData.signatures.push(bPlusObj);
			}

			if (vm.agreements[3] && vm.isEPOAmendmentEnabled) {

				var epoSignObj = {

					"signature" : vm.agreements[3].signature,
					"docType" : vm.agreements[3].type

				};

			}
			if (epoSignObj !== undefined) {
				signatureData.signatures.push(epoSignObj);
			}

			var rentalAgreeObj = {
				"signature" : vm.agreements[0].signature,
				"docType" : vm.agreements[0].type,
				"initial" : vm.inputInitials
			};
			signatureData.signatures.push(rentalAgreeObj);

			var arbSignObj = {
				"signature" : vm.agreements[6].signature,
				"docType" : vm.agreements[6].type

			};
			signatureData.signatures.push(arbSignObj);

			//ESV Agreement
			if (vm.agreements[7] && vm.agreements[7].agree === "SIGNED") {
				var esvSignObj = {
					"signature" : vm.agreements[7].signature,
					"docType" : vm.agreements[7].type
				};
				signatureData.signatures.push(esvSignObj);
			}

			//ESV DEL Agreement
			if (vm.agreements[8] && vm.agreements[8].agree === "SIGNED") {
				var esvSignObj = {
					"signature" : vm.agreements[8].signature,
					"docType" : vm.agreements[8].type
				};
				signatureData.signatures.push(esvSignObj);
			}

			SharedDataSvc.globalVars.signatureTemplate = signatureData;

		}

		// TODO - need to add processing loading modal
		function setCalcPaymentSpec() {
			void 0;

			var deferred = $q.defer();

			var paymentSpec = SharedDataSvc.globalVars.paymentCalcSetup;

			setFirstPaymentDate();

			paymentSpec.customerEngagementId = Number(vm.engagementId);
			paymentSpec.id = vm.engagement.customerHtStoreId;

			paymentSpec.customerId = vm.engagement.customerId;
			paymentSpec.clubSwitch = 'N';
			paymentSpec.waiverSwitch = 'DECLINE';
			paymentSpec.hoCustId = vm.customer.customerHtId;

			if (vm.agreementData !== undefined) {//AC 25996 code changes
				paymentSpec.agreementNumber = vm.agreementData.agreement.raNumber;
				paymentSpec.paymentToDate = SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate;
			} else {
				paymentSpec.agreementNumber = vm.customerFind.VanEngagement.agreementHTId;
				paymentSpec.paymentToDate = vm.customerFind.VanEngagement.nextPaymentDate;
			}

			// console.log('waiver switch', vm.agreements[1].agree);
			// console.log('club switch', vm.agreements[2].agree);

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here
				paymentSpec.waiverSwitch = 'ACCEPT';
			} else {
				paymentSpec.waiverSwitch = 'DECLINE';
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED' && !vm.hideBp) {//Have changed here
				paymentSpec.clubSwitch = 'Y';
			} else if (vm.agreements[5] && vm.agreements[5].agree === 'DECLINED') {
				paymentSpec.clubSwitch = 'N';
			} else {
				paymentSpec.clubSwitch = 'N';
			}

			SharedDataSvc.globalVars.paymentCalcSetup = paymentSpec;

			if (SharedDataSvc.globalVars.paymentCalcSetup && SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate) {
				deferred.resolve("PaymentCalcSetup and initialPaymentDate has been set");
			} else {
				deferred.reject("PaymentCalcSetup or intialPaymentDate is not set yet");
			}

			return deferred.promise;

		}

		function setAgreementStatus(status) {
			vm.engagement.agreementStatus = status;
		}

		function declineConsent() {
			showModal('Processing', 'processing-modal');
			setAgreementStatus('Declined');
			void 0;
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement failed');
			});
		}
		
		function updateEngagement() {
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
			}, function(response) {
				$log.log('Updating engagement failed');
			});
		}

		function formatAgreementDate(date) {
			var year = String(date).substring(0, 4),
			    month = String(date).substring(4, 6),
			    day = String(date).substring(6, 8);

			// 20140825
			void 0;
			return month + '-' + day + '-' + year;
		}

		function isSameDayAgreement() {
			var today = datesFactory.getCurrentDate(),
			    agreementsExist = vm.customerFind.agreement ? true : false,
			    agreementDate = vm.customerFind.agreement ? formatAgreementDate(vm.customerFind.agreement.agreementDate) : false,
			    isSameDay = moment(agreementDate).isSame(today, 'day') ? true : false;
			// Set line below to today to check for same day agreements
			// isSameDay = moment('4-27-2015').isSame(today, 'day') ? true : false;

			return isSameDay;
		}

		function handleSameDayAgreements() {
			vm.agreementDataForClauseUse = vm.customerFind.agreement;
			if (vm.customerFind.agreement && isSameDayAgreement() && (vm.agreementHTID !== undefined && vm.agreementHTId !== null)) {//AC 25996 code changes
				//Start - AC13303 - Error 700 on submit agreements
				if (vm.customerFind.agreement && (vm.customerFind.agreement.agreementStatus === "INACTIVE")) {
					vm.agreementStatus = vm.customerFind.agreement.agreementStatus;
					var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
					//AC-28614 Changes
					//Commented out the Active check since customers will be allowed to docusign after payment complete in HT
//					if (vm.customerFind.agreement.agreementStatus === "ACTIVE") {
//						$rootScope.modalMessage = $translate.instant('agreement_label_errorReceiveAgreement') + servicePhoneNumber + '. [99]';
//					} else {
						$rootScope.modalMessage = $translate.instant('agreement_label_errorDeclineAgreement') + ' [44]';
//					}
					modalService.open({
						templateUrl : 'partials/modal_agreementStatusNotinPending.html',
						backdrop : 'static',
						scope : $scope
					});
					return false;
				} else {
					//End - AC13303 - Error 700 on submit agreements
					var agreements = vm.customerFind.agreement;
					vm.agreements[1].pdf = [];
					vm.agreements[1].type = [];

					void 0;

					for (var key in agreements) {

						if (key === 'UnsignedCAM' || key === 'UnsignedCOP' || key === 'UnsignedCRB' || key === 'CYC' || key === 'UnsignedGAS' || key === 'UnsignedLCD' || key === 'UnsignedRAN' || key === 'UnsignedSOC' || key === 'UnsignedVID') {

							vm.agreements[1].pdf.push(agreements[key]);
							var keyForSigning = key.replace("Unsigned", "");
							vm.agreements[1].type.push(keyForSigning);
							vm.showSupp = true;

						}

					}
					//  vm.agreements[1].pdf = 'data:application/pdf;base64,' + escape(agreements.UnsignedSAC);
					vm.agreements[2].pdf = agreements.UnsignedRAAck;
					//Have changed here
					vm.agreements[0].pdf = agreements.UnsignedRA;

					if (agreements.EpoAmendment) {
						vm.agreements[3].pdf = agreements.EpoAmendment;
						//Have changed here
					}

					if (agreements.UnsignedLDW) {
						vm.agreements[4].pdf = agreements.UnsignedLDW;
						//Have changed here
					}

					if (agreements.CLB) {
						vm.agreements[5].pdf = agreements.CLB;
						//Have changed here
					}

					vm.agreements[6].pdf = agreements.UnsignedARB;
					//Added a new line here

					if (agreements.UnsignedFEE) {
						vm.agreements[7].pdf = agreements.UnsignedFEE;
						vm.showWarrantyAgreement = true;
					} else {
						delete vm.agreements[7];
					}

					if (agreements.UnsignedDLF) {
						vm.agreements[8].pdf = agreements.UnsignedDLF;
						vm.showDelWarrantyAgreement = true;
					} else {
						delete vm.agreements[8];
					}
				} //AC13303 - Error 700 on submit agreements
				//AC24649 Code changes starts
				if(vm.isAdjustablePayment){
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
					} else {
						vm.languagePreference = false;
					}
					var requestDate = datesFactory.getCurrentDate();				
					vm.orderDataForAdjustablePayment.requestDte = requestDate;
					vm.orderDataForAdjustablePayment.nextRenewDte = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("YYYY-MM-DD HH:mm:ss");
					//vm.orderDataForAdjustablePayment.nextRenewDte = "2016-09-16 23:59:59";
					setOrderDataForAdjustablePayment();
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
						void 0;
						vm.quoteGotAsPerSelection = response;
						vm.adjustableDateSelection = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("MMM DD, YYYY");
						vm.showModal('samedayInitialPaymentInfo', 'initial-payment-info-modal');
					}, function(response) {
						void 0;
					});
					
				}else {
				
					agreementCodeVerify();
				}//AC24649 Code changes ends
			}

		}

		function setOrderData() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});
			vm.orderData[0].quoteItem = orderItems;
			vm.orderData[1].quoteItem = orderItems;
			vm.orderData[2].quoteItem = orderItems;

			vm.orderData[0].inventoryList = vm.createInventoryList();
			vm.orderData[1].inventoryList = vm.createInventoryList();
			vm.orderData[2].inventoryList = vm.createInventoryList();

			vm.orderData[0].engagementId = vm.engagement.engagementId;			
			vm.orderData[1].engagementId = vm.engagement.engagementId;			
			vm.orderData[2].engagementId = vm.engagement.engagementId;

			void 0;
			// SharedDataSvc.globalVars.orderEstimatePut.quoteItem
		}

		function setOrderDataForAdjustablePayment() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});

			vm.orderDataForAdjustablePayment.quoteItem = orderItems;
			vm.orderDataForAdjustablePayment.inventoryList = vm.createInventoryList();
			vm.orderDataForAdjustablePayment.engagementId = vm.engagement.engagementId;
			void 0;

		}

		function isZipInStoreRadius(zip) {
			void 0;
			var deferred = $q.defer();
			// Google API Implementation - Starts
			getStoreInfoFactory.getLatLngFromZip(zip).then(function(results) {
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
					    distance = getStoreInfoFactory.getCustomerDistance(vm.storeLatLng, customer),
					    isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius(vm.storeInfo.allowedRentalRadiusMiles, distance),
					    isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

					void 0;
					// console.log('Is in US', isCustomerInUS);
					void 0;
					vm.customerDistance = Math.round(distance);

					if (isCustomerInUS && isInAllowedRadius) {
						vm.customerInStoreRadius = true;
						deferred.resolve(true);
					} else {
						vm.customerInStoreRadius = false;
						deferred.resolve(false);
					}
				} else {
					vm.isZipValid = false;
					deferred.resolve(false);
				}
			});
			return deferred.promise;
		}

		function setCustomerOrderData(customer) {
			vm.orderData[0].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[0].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[0].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[0].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[0].employeeName = 'AWP';
			vm.orderData[0].store = vm.storeInfo.vanStoreId;

			vm.orderData[1].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[1].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[1].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[1].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[1].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[1].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[1].employeeName = 'AWP';
			vm.orderData[1].store = vm.storeInfo.vanStoreId;

			vm.orderData[2].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[2].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[2].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[2].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[2].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[2].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[2].employeeName = 'AWP';
			vm.orderData[2].store = vm.storeInfo.vanStoreId;
		}

		function setCustomerOrderDataForAdjustablePayment(customer) {
			vm.orderDataForAdjustablePayment.customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderDataForAdjustablePayment.customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderDataForAdjustablePayment.orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderDataForAdjustablePayment.feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderDataForAdjustablePayment.employeeName = 'AWP';
			vm.orderDataForAdjustablePayment.store = vm.storeInfo.vanStoreId;
		}

		function createInventoryList() {
			var inventoryList = [];

			void 0;
			_.forEach(vm.orderContent.orderItems, function(item, index) {
				var orderItem = {
					"racBrand" : "ACP", // 'ESV' for extended
					"itemModelNumber" : "", // 'ExtSvc' for extended
					"racModelNumber" : "",
					"depreciableLifeInMonths" : 24,
					"itemOptions" : "",
					"itemOriginalCost" : 0
				};

				orderItem.racBrand = "ACP";
				orderItem.itemModelNumber = "";
				orderItem.racModelNumber = productFactory.getRacModelNumber(vm.prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				orderItem.depreciableLifeInMonths = 24;
				orderItem.itemOptions = productFactory.buildItemOptions(item);
				orderItem.itemOriginalCost = SharedDataSvc.formatItemPrice(item.itemPrice);
				void 0;
				inventoryList.push(orderItem);

				if(vm.orderContent.extendedServiceAmount > 0) {
					var extendedWarranty = {
						"racBrand" : "ACP",
						"itemModelNumber" : "ExtSvc",
						"racModelNumber" : "ESVPLAN-1",
						"depreciableLifeInMonths" : 24,
						"racRateCode" : "ESV396",
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(vm.orderContent.extendedServiceAmount)
					};
					inventoryList.push(extendedWarranty);
				}
			});

			return inventoryList;
		}

		function setHTML(template) {
			$scope.agreementInfo = vm.agreementData ? vm.agreementData.agreement : vm.agreementDataForClauseUse;
			var str = template.replace(/{{/g, '{{agreementInfo.');
			var filteredStr = str.replace(/}}/g, ' | decimalFilter:2 }}');
			return filteredStr;
		}

		function cancelAndNavigate(){
			if(vm.storeInfo.isDigitalSigningEnabled && (vm.isHTCustomer || (vm.hideBp && vm.restrictions.skipLdw))) {
				navigateTo('dashboard');
			}  else {
				modalService.dismiss();
			}
		}
		
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		function showPaymentInitial(){
			$rootScope.selectedLang = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference === 'ENGLISH' ? true : false;
			if(vm.selectedPromo === 'AutoPayRequired'){
				$scope.autoPayRequired = modalService.open({
					title: 'Open AutopayRequired popup for $49 Promo',
					templateUrl: 'partials/modal_showAutopayRequired.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
			else
			{
				checkStore();
			}
		}
		function showInitialPaymentOptions(){
			vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
		}
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
	}

})();

///////////////////////////////////////////
//////////////////////////////////////////
///   APPLICATION CONTROLLER   //////////
////////////////////////////////////////
/////////////////////////////////////// 
(function () {

	'use strict';

	angular
		.module('app.application')
		.controller('Application', Application);

	/* @ngInject */
	function Application(
		$scope,
		$rootScope,
		$http,
		$cookies,
		$log,
		$timeout,
		$modal,
		$location,
		$anchorScroll, // Added for Scroll up VAN 10736
		$routeParams,
		$q,
		$idle,
		$translate,
		getSpecificApplication,
		SharedDataSvc,
		modalService,
		authentication,
		applicationService,
		agreementFactory,
		base64,
		getStoreInfoFactory,
		stateRestrictFactory,
		datesFactory, //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		updateEngagementItemFactory,
		customerDataFactory,
		productCategoriesFactory
	) {
		var createOrder,
			existingOrderTotal = 0;

		// CUSTOMER INFORMATION
		$scope.customerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {},
			phone2: {},
			incomeSources: {
				nextPayDate: {}
			},
			personalReferences: []
		};
		$scope.customerPrivate = {
			dob: {},
			ssn: {
				ssnType: 'SSN'
			},
			verifyssn: {
				ssnType: 'SSN'
			},
			verificationType: 'PText',
			mastercode:{
				employeeName: '',
				employeeId: '',
				comments: '',
				masterTextCode: '',
				mastertextCodeAttemptsNumber: 0,
				associatePass: '',
				noAssociatePass: true,
				invalidAssociatePass: false, 
				masterCodeCheckbox: false
			}
		};

		// CUSTOMER INFORMATION
		$scope.coCustomerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {},
			phone2: {},
			incomeSources: {
				nextPayDate: {}
			},
			personalReferences: [],
			dob: {}
		};

		$scope.coCustomerPrivate = {
			dob: {},
			ssn: {
				ssnType: 'SSN'
			}
		};

		$scope.custAdditionalInfo = {
			previousAddress : {},
			moveInDate : {},
			auto: {},
			rent: {},
			own: {},
			leaseEndDate: {},
			ownRentInfo: {},
			noCocustomer: false,
			noCocustomerIncome: false
		}

		$scope.custAdditionalInfo.auto.noCar = false;
		$scope.previousAddress = {
			moveInDate: {}

		}
		
		$scope.verifySsnMismatch = false;
		$scope.customerPrivate.TextCode = '';
		$scope.customerPrivate.textCodeAttemptsNumber = 0;
		$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
		$scope.bankingInfoFormForACH = {
			routingNumber: '',
			accountNumber: ''
		}
		$scope.newReferences = {
			ref1: {},
			ref2: {},
			ref3: {},
			ref4: {}
		};
		$scope.appForm = [];
		$scope.customerInfoModal = {};
		$scope.customerId;
		$scope.engagementId = $routeParams.engagementId;
		$scope.customerFirstName;
		$scope.approvalExpiryDays;
		$scope.approvalAmount;
		$scope.servicePhoneNumber = '';
		$scope.showVerification = false;
		$scope.showVerficationWarning = false;
		$scope.showAddSecondaryPhoneDetails = false;
		$scope.customerName;

		// BOOLS
		$scope.customerExistsInHT = false; // true
		$scope.ssnSubmitted = false;
		$rootScope.hideMenuButtons = false;
		$scope.formSubmitted = false;
		$scope.draftSaved = false;
		$scope.amountBelowOrderTotal = false;
		$scope.secondaryNoTypeRequired = false;
		$scope.newCustomer = false;
		$scope.custAdditionalInfo.noCocustomer = false;
		$scope.custAdditionalInfo.noCocustomerIncome = false;
		//VAN-510 - Electronic Consent moved to app flow - Starts
		$scope.newCustomerElectronicConsent = newCustomerElectronicConsent;
		$scope.startApplication = startApplication;
		$scope.updateCustomerConstent = updateCustomerConstent;
		$scope.isConsentAgreed = false;
		$rootScope.isEnglishStore;
		$scope.createOrderApp=createOrderApp;
		//VAN-510 - Electronic Consent moved to app flow - Ends
		
		// VALIDATION REGEXES
		$scope.zipCodeVal = /^[0-9]{5}$/;
		$scope.onlyNumbers = /^\d+$/;
		$scope.ssnValidation1 = /^(?!666|000|9\d{2})\d{3}$/;
		$scope.ssnValidation2 = /^(?!00)\d{2}$/;
		$scope.ssnValidation3 = /^(?!0{4})\d{4}$/;
		$scope.ccssnValidation1 = /^(?!666|000|9\d{2})\d{3}|\*\*\*$/;
		$scope.ccssnValidation2 = /^(?!00)\d{2}|\*\*$/;
		$scope.itinValidation1 = /^(9\d{2})$/;
		$scope.itinValidation2 = /^((7\d{1})|(8[^9]{1})|(9[^3]{1}))$/;
		$scope.itinValidation3 = /^(\d{4})$/;
		$scope.phoneValidation = /^\(\d{3}\)[-.\s]?\d{3}[-.]?\d{4}$/;
		//$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[BbOoXx])[-a-zA-Z\d .,\/]*$/;
		//$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[Bb]\.? ?[Oo]\.? ?[Xx]\.?)[-a-zA-Z\d .,#\/]*$/; //AC-10778
		$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[Bb]\.? ?[Oo]\.? ?[Xx]\.?)[-a-zA-Z\d\\\/ .,#\/]*$/;//VAN-913 Added to allow escape sequence character
		$scope.nameValidation = /^[a-zA-Z\s\-\']+$/; // Added for name validation 2542 & 2543. It will allows only letters
		$scope.lastNameValidation = /^\d*[a-zA-Z0-9\d\s\-\']*$/; // Added for name validation 2542 & 2543. It will allow letters, dashes, and apostrophes.
		$scope.phoneNumberValidation = /^(\({0,1}[2-9]{1}[0-9]{2}\){1} {1}[2-9]{1}[0-9]{2}-{0,1}[0-9]{0,4})/;  //AC-10616 - Code change to handle all zero phone number
		$scope.incomeValidation = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/;
		$scope.routingValidation = /^([0][1-9]|[1][0-2]|[2][1-9]|[3][0-2]|[6][1-9]|[7][0-2])([0-9]{7})$/;
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.salaryValidation = /[1-9]\d*/; // AC13186 code changes 
		$scope.idValidation=/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;//VAN-3402-Re-engagement with Code - AWP

		// BANKING INFO MODAL
		$scope.challenge = {
			bankRoutingNo: '',
			bankAccountNo: '',
			approvedAmt: 0,
			originalApprovedAmt: 0,
			outstandingAmt: 0
		};

		// DATE OF BIRTH INFO
		$scope.dobvalid = {
			day: moment().date(),
			month: moment().month() + 1,
			year: moment().year() - 18
		};
		$scope.birthYears = [];
		$scope.leaseYears = [];
		$scope.moveInYears = [];

		// customer zip and store radius
		$scope.isZipInStoreRadius = isZipInStoreRadius;
		$scope.resetZipData = resetZipData;
		$scope.zipValidation = zipValidation;
		$scope.zipSubmitFalse = zipSubmitFalse;
		$scope.customerInRadius = true;
		$scope.isZipValid = true;
		$scope.allowTextVerificationFlag = false;
		$scope.storeInfo;
		$scope.storeLatLng;
		$scope.isSpanishStore;
		$scope.customerDistance;
		$scope.storeRadius;
		$scope.restrictions;
		$scope.stateRestrictions;
		$scope.engagementDetails;
		$scope.ssnNumber;
		$scope.customerEngagementInfo;//story 25996 code changes
		$scope.declineAgreement = declineAgreement;//story 25996 code changes
		$scope.cancelAgreementDecline = cancelAgreementDecline;//story 25996 code changes
		$scope.createNewEngagement = createNewEngagement;
		// Validate date of birth
		$scope.validateDOB = validateDOB;
		// Check if selected address state is unsupported
		$scope.checkState = checkState;
		$scope.navigateTo = navigateTo;
		$scope.saveDraft = saveDraft;
		$scope.saveAndProcess = saveAndProcess;
		$scope.cancelCustomerEntry = cancelCustomerEntry;
		$scope.validateReferenceName = validateReferenceName;
		$scope.validateReferencePhone = validateReferencePhone;
		$scope.validateSecondaryPhone = validateSecondaryPhone;
		$scope.doPhoneValidation = doPhoneValidation; //AC-10616 - Code change to handle all zero phone number
		$scope.handleSaveAndExit = handleSaveAndExit;

		$scope.clearSSN = clearSSN;
		$scope.clearITIN = clearITIN;
		$scope.clearSecondary = clearSecondary;
		$scope.showModal = showModal;

		$scope.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		$scope.navigateToApplication = navigateToApplication;
		$scope.submitTextCode = submitTextCode;
		$scope.masterCodeModal = masterCodeModal;
		$scope.resetPromoPayment = resetPromoPayment;
		$scope.verifyEmployeePassPhrase = verifyEmployeePassPhrase;
		$scope.verifyPassWordEmployee = verifyPassWordEmployee;
		$scope.cancelTextCode = cancelTextCode;
		$scope.generateTextCode = generateTextCode;
		$scope.processVerification = processVerification;
		$scope.addSecondaryPhoneDetails = addSecondaryPhoneDetails;
		//Story 30445 - AWP, Kiosk: Add email verification edit box - start
		$scope.compareEmailVerify = compareEmailVerify;
		$scope.verifyEmail = true;
		$scope.newform = {};
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end
		$scope.saveSecondaryPhoneDetails = saveSecondaryPhoneDetails;
		//$scope.showSameDayAgreementModal = showSameDayAgreementModal;
		$scope.submitBankingInfoForACH = submitBankingInfoForACH;
		$scope.submitMasterTextCode = submitMasterTextCode;
		$scope.publishOverrideData = publishOverrideData;
		//Story 33452 changes starts
		$scope.hideEmailAndStartAppln = false;
		$rootScope.isSLICustomer = false;
		$scope.custCreateData = {};
		$scope.applicationCheck = applicationCheck;
		$scope.proceedSaveApplication = proceedSaveApplication;
		$scope.confirmAndEditEmail = confirmAndEditEmail;
		$scope.categoryApprovalList = [];

		$scope.inventoryInfo = [];
		$scope.inventoryInfo.productCategories = [];
		var excludedCategories = [];
		$scope.approvalByCatList = [];
		$scope.submittingApplication = false;
		//$scope.getApprovalbyCat =  getApprovalbyCat;
		$scope.customerInfo.approvalByCatList = [];
		$scope.newEngagementWithCat = newEngagementWithCat;
		$scope.updateApprovalByCatList = updateApprovalByCatList;
		$scope.updateAutoFields = updateAutoFields;
		$scope.disableCatContinue = true;
		$scope.isApprovalByCatSelected = false;
		$scope.categoryCollected = false;
		//Story 33452 changes ends

		//PR flow new additions
		$scope.setPreviousAdd = setPreviousAdd;
		$scope.showPreviousAdd = false;
		$scope.updateCoIncome = updateCoIncome;
		$scope.updateCocustomerIncome = updateCocustomerIncome;
		$scope.validateLeaseYear = validateLeaseYear;
		$scope.validateCCSSN = validateCCSSN;
		$scope.stateValidation = stateValidation;//VAN-3209-Use customers zip code on agreement creation 
		$scope.isStateValid = true;//VAN-3209-Use customers zip code on agreement creation 
		//VAN-3402-Re-engagement with Code - AWP - Start
		$scope.searchSelectionForm = {};
		$scope.searchOptions = [{"labelEn": "Search by Approval ID", "labelEs": "Buscar por ID de aprobación", "value": "Id"}, {"labelEn": "Search by Name", "labelEs": "Buscar por nombre", "value": "Name"}];
		$scope.validateSelectedSrchOpt = validateSelectedSrchOpt;
		$scope.findCustApplication = findCustApplication;
		$scope.searchSelection = searchSelection;
		$scope.validateApprovalId = validateApprovalId;
		$scope.resetValidation = resetValidation;
		$scope.searchByIdForm = {};
		$scope.searchByIdForm.approvalId = '';
		$scope.showSubmit = false;
		$scope.showApprovalError = false;
		$scope.enableSearch = true;
		$scope.showSrchClose = false;
		$rootScope.isAppSrch=false;
		$scope.openConsentModal = openConsentModal;
		$scope.custApp = '';
		//VAN-3402-Re-engagement with Code - AWP - End
		//VAN-2844-Capture clients IPAddress for Virtual approvals and AWP-Starts
		$scope.ipaddress=null;
		
		getIpaddress();
		function getIpaddress()
        {                    
               $http.get("https://api.ipify.org/").then( function(ipdata){
                   $scope.ipaddress=ipdata.data;
               });
        }
		//VAN-2844-Capture clients IPAddress for Virtual approvals and AWP-Ends

		$scope.incomeEnums = [
			{
				type: 'EMPLOYED',
				label: {
	                en : en.application_select_empOpt1,
	                es : es.application_select_empOpt1
	            }
			},
			{
				type: 'SELFEMPLOYED',
				label: {
	                en : en.application_select_empOpt2,
	                es : es.application_select_empOpt2
	            }
			},
			{
				type: 'SOCIAL',
				label: {
	                en : en.application_select_empOpt3,
	                es : es.application_select_empOpt3
	            }
			},
			{
				type: 'RETIRED',
				label: {
	                en : en.application_select_empOpt4,
	                es : es.application_select_empOpt4
	            }
			},
			{
				type: 'PENSION',
				label: {
	                en : en.application_select_empOpt5,
	                es : es.application_select_empOpt5
	            }
			},
			{
				type: 'DISABILITY',
				label: {
	                en : en.application_select_empOpt6,
	                es : es.application_select_empOpt6
	            }
			},
		];

		$scope.payFrequencyEnums = [
			{
				type: 'MONTHLY',
				label: {
					en : en.application_select_payFreq1,
					es : es.application_select_payFreq1
				}
			},
			{
				type: 'TWICE-MONTHLY',
				label: {
					en : en.application_select_payFreq2,
					es : es.application_select_payFreq2
				}
			},
			{
				type: 'BI-WEEKLY',
				label: {
					en : en.application_select_payFreq3,
					es : es.application_select_payFreq3
				}
			},
			{
				type: 'WEEKLY',
				label: {
					en : en.application_select_payFreq4,
					es : es.application_select_payFreq4
				}
			}
		];


		// set default income source
		$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
		var refType = ["PARENT", "SBLING", "GRANDP", "COUSIN", "ANTUNC", "NIENEP", "FRIEND", "COWORK", "OTHER"];   //AC22760 code changes                      		
		//save Data before navigating to AP dashboard or training library
		$scope.$on("saveAppData", function (event, path) {
			void 0;
			//check if secondary number is filled then secondary phone type is valid
			if ($scope.customerInfoForm.PhoneType2.$valid) {
				saveCustomerInfo().then(function () {
					if (path.indexOf("training") > -1) {
						$rootScope.loginTrainingLibrary();
					} else {
						$location.path("/dashboardAgreement");
					}
					return true;
				});
			}
			else {
				$scope.draftSaved = true;
				$scope.formSubmitted = false;
			}
		});

		/*
		Check if person data has been passed from the dashboard
		We'll use this to find their application data
		*/
		if ($routeParams.data) {
			var decoded, personData;
			decoded = decodeURIComponent($routeParams.data);
			decoded = window.atob(decoded);
			$scope.personData = JSON.parse(decoded);
			retrieveApplication($scope.personData);
			

		} else {
			// NEW CUSTOMER
			//Story 33452 changes starts
			if(SharedDataSvc.globalVars.isApplicationFromOrder){
				$scope.hideEmailAndStartAppln = true;
				SharedDataSvc.globalVars.isApplicationFromOrder = false;
				$rootScope.isSLICustomer = true;
				$rootScope.orderLink = true;//Defect 28266 - VAN: References getting wiped off in HT
			}
			$rootScope.isEnglishStore = $rootScope.selectedLanguage == "en" ? true : false;//VAN-510 - Electronic Consent moved to app flow
			//Story 33452 changes ends
			if($rootScope.orderLink && $rootScope.isSLICustomer){//VAN-3402-Re-engagement with Code - AWP 
				$scope.showSrchClose = false;
				var newCustomerModal = modalService.open({
					title: 'New Customer',
					templateUrl: 'partials/newCustomerModal.tpl.html',
					size: 'sm',
					backdrop: 'static',
					windowClass: 'newCustomerModal',
					scope: $scope
				});
				
				newCustomerModal.result.then(function (result) {
					/*VAN 719 - Make consent form required to read */
					newCustomerModalConsentCheck(result);
				});//VAN-510 - Electronic Consent moved to app flow - Ends
			}else{//VAN-3402-Re-engagement with Code - AWP - Start
				$scope.showSrchClose = true;
				searchSelection();
			}
			//VAN-3402-Re-engagement with Code - AWP - End
			$scope.orderSub = [];
		}
		/*VAN 719 - Make consent form required to read - Starts*/
		function newCustomerModalConsentCheck(result){
			var personData = {
				firstName: result.firstName,
				lastName: result.lastName,
				phone: result.primaryPhone,
				istcpaflag: false
			};
			$scope.personData = personData;
			void 0;
			$scope.customerInfoForm.firstName.$pristine = false;
			$scope.customerInfoForm.lastName.$pristine = false;
			$rootScope.createOrderConsent = result.createOrder;
			//VAN-510 - Electronic Consent moved to app flow - Starts
			modalService.processing('Processing');
			applicationService.getCustomerConsent(personData)
			.then(function (response) {
				$log.log('[Application.handleCustomerOutOfRadius] Successfully updated engagement');
				$scope.isConsentResponse = response.isCosentAgreed ;//my fix
				modalService.dismiss();
				if(response.status_code == "200" && (response.isCosentAgreed == "false" || response.isCosentAgreed == "null")){
					/*var newCustomerModal = modalService.open({
							title: 'New Customer',
							templateUrl: 'partials/newCustomerModal.tpl.html',
							size: 'sm',
							backdrop: 'static',
							windowClass: 'newCustomerModal',
							scope: $scope
						});
						$scope.isConsentAgreed = true;
						$scope.customerInfoModal.firstName = result.firstName;
						$scope.customerInfoModal.middleInitial = result.middleInitial;
						$scope.customerInfoModal.lastName = result.lastName;
						$scope.customerInfoModal.primaryPhone = result.primaryPhone;
						$scope.customerInfoModal.primaryPhoneType = result.primaryPhoneType;
						$scope.customerInfoModal.emailAddress = result.emailAddress;
						$scope.customerInfoModal.emailVerify = result.emailVerify;*/
					/*VAN 719 - Make consent form required to read - Starts*/
					$scope.myModalInstance = $modal.open({
						templateUrl: 'partials/modal_eAgreementNotice_StartApp.html',
						show: true,
						backdrop: 'static',
						scope: $scope
					});
					/*VAN 719 - Make consent form required to read - Ends*/
				}
				else if($rootScope.createOrderConsent){
					createOrderApp(personData);
				}
				else{
					startApplication(personData);
				}
			}, function (response) {
				$log.log('[Application.handleCustomerOutOfRadius] Updating engagement failed');
				modalService.dismiss();
			});
		}
		
		$scope.closeConsent =function(){
			var newCustomerModal = modalService.open({
				title: 'New Customer',
				templateUrl: 'partials/newCustomerModal.tpl.html',
				size: 'sm',
				backdrop: 'static',
				windowClass: 'newCustomerModal',
				scope: $scope
			});
			newCustomerModal.result.then(function (result) {
				newCustomerModalConsentCheck(result);
			});
		};
		/*VAN 719 - Make consent form required to read - Ends*/
		
		///////////////////////////////////////////////
		//  METHODS  //////////////////////////////////
		///////////////////////////////////////////////

		function init() {

			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			$rootScope.isHybrid = $cookies.getObject('isHybrid');
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');
			
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000*/
			$scope.prZipValidation = true;
			
			addYears();

			getStoreInfoFactory.getData()
				.then(function (response) {
					$scope.storeInfo = response;
					
					/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Starts*/
					$scope.isPRState = false;
					if(response.state=="PR"){
						$scope.isPRState = true;
					}
					/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Ends*/
					
					if (response.excludedCategories) {
						excludedCategories = response.excludedCategories;
					}
					$scope.customerPrivate.masterTextCode = response.masterCodeForOverride;
					SharedDataSvc.globalVars.storeSetup = response;
					if (response.vanStoreId) {
						$rootScope.storeNo = response.vanStoreId;
						$rootScope.state = response.state;
						$rootScope.isReferenceRequired = response.isReferenceRequired;
					}
					$rootScope.invoiceMethod = response.invoiceMethod;
					$scope.storeLatLng = { 'lat': $scope.storeInfo.latitude, 'lng': $scope.storeInfo.longitude };
					$scope.storeRadius = response.allowedRentalRadiusMiles;
					$scope.agreementAmount = response.minAgreementAmount;
					$scope.allowTextVerificationFlag = response.allowTextVerification;

					if ($scope.allowTextVerificationFlag) {
						$scope.customerInfoModal = {
							primaryPhoneType: "CELL"
						};
					}

					$scope.itinAllowed = response.itinAllowed;
					stateRestrictFactory.getStateRestrictions()
						.then(function (response) {
							$scope.stateRestrictions = response.stateRestrictions[0];
						});

					stateRestrictFactory.getAllStoreRestrictions($scope.storeInfo.state)
						.then(function (response) {
							$scope.restrictions = response;
						});
				});

				productCategoriesFactory.getData().then(function(response) {
				$scope.inventoryInfo = response;
				//console.log(builder.inventoryInfo);
				var productCategories = _.clone($scope.inventoryInfo.productCategories);
				// APPEND Children to their parent objects; basically re-map the JSON data
				_(productCategories).forEachRight(function(obj, index, collection) {
					if (obj.parent) {
						// find the parent object
						var parent = _.find(collection, {
							labelEn: obj.parent
						});
						// check if it already has a collection started
						if (!parent.items) {
							parent.items = [obj];
						} else {
							parent.items.push(obj);
						}
						// remove object from main collection, for organization
						_.pull(collection, obj);
					}
					if (excludedCategories.indexOf(obj.labelEn) > -1) {
						_.pull(collection, obj);
					}
				});

				/* Filtering the ProductCategories items as well i.e. on more level deep to filter out excluded categories present there */
				var productCat = productCategories;
				void 0;
				for(var z=0; z < productCategories.length; z++){

					if( _.isArray(productCategories[z].items) ){
						
						var filteredItem = [];
						for(var x = 0; x < productCategories[z].items.length; x++){
							
							if( excludedCategories.indexOf(productCategories[z].items[x].labelEn) == -1 ){
								filteredItem.push(productCategories[z].items[x]);
							}	
						}
						productCat[z].items = filteredItem;	
					}	
				}


				void 0;
				$scope.productList = productCat;
				void 0;
				for(var z=0; z < productCat.length ; z++){
					var tempObj = {
						"isSelected": false,
						"category": productCat[z].labelEn
					}

					$scope.approvalByCatList.push(tempObj);	
				}

				void 0;
				
			}); 

		}


		init();

		function addYears() {
			for (var i = 18; i < 100; i++) {
				$scope.birthYears.push(moment().year() - i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.leaseYears.push(moment().year() + i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.moveInYears.push(moment().year() - i);
			};

		}

		function getFullName(first, last) {
			if (first && last) {
				return first.toLowerCase() + ' ' + last.toLowerCase();
			}
			return undefined;
		}

		function getReferenceErrors(index) {
			return ['ref' + index + 'FirstName', 'ref' + index + 'LastName'];
		}

		function referenceNameSameAsCustomer(refName) {
			var custName = $scope.customerInfo.firstName + ' ' + $scope.customerInfo.lastName;
			if (refName && custName) {
				return refName.toLowerCase() === custName.toLowerCase() ? true : false;
			}
		}

		function referencePhoneSameAsCustomer(refPhone) {
			var custPhone = $scope.customerInfo.phone1.number;
			if (refPhone) {
				return refPhone === custPhone ? true : false;
			}
		}

		function clearSSN() {
			$scope.customerPrivate.ssn.ssn1 = '';
			$scope.customerPrivate.ssn.ssn2 = '';
			$scope.customerPrivate.ssn.ssn3 = '';
			//clear verify ssn
			$scope.customerPrivate.verifyssn.ssn1 = '';
			$scope.customerPrivate.verifyssn.ssn2 = '';
			$scope.customerPrivate.verifyssn.ssn3 = '';
		}

		function clearITIN() {
			if(!$scope.customerPrivate.itin){
				return false;
			}
			$scope.customerPrivate.itin.itin1 = '';
			$scope.customerPrivate.itin.itin2 = '';
			$scope.customerPrivate.itin.itin3 = '';
			//clear verify ssn
			$scope.customerPrivate.verifyitin.itin1 = '';
			$scope.customerPrivate.verifyitin.itin2 = '';
			$scope.customerPrivate.verifyitin.itin3 = '';
		}

		function clearSecondary(primaryPhoneType, secondaryPhoneNumber) {
			void 0;
			if ($scope.allowTextVerificationFlag) {
				if ($scope.newCustomer) {
					if (primaryPhoneType === 'HOME') {
						$scope.customerInfo.phone2 = {
							number: secondaryPhoneNumber,
							phoneType: "CELL"
						};
					} else {
						$scope.customerInfo.phone2 = {
							number: secondaryPhoneNumber,
							phoneType: ""
						}
					}
				} else if (primaryPhoneType === 'CELL' && SharedDataSvc.customerInfo.phones[1].areaCode === "000" && SharedDataSvc.customerInfo.phones[1].phoneType === "") {
					$scope.customerInfo.phone2 = {
						phoneType: ""
					}
				} else if (primaryPhoneType === 'HOME' && SharedDataSvc.customerInfo.phones[1].areaCode === "000" && SharedDataSvc.customerInfo.phones[1].phoneType === "") {
					$scope.customerInfo.phone2 = {
						phoneType: "CELL"
					};
				}
			}
		}

		function validateReferenceName(index) {
			$scope.ref = [{
				first: $scope.customerInfoForm.newRefs.ref1FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref1LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref1FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref1LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref2FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref2LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref2FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref2LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref3FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref3LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref3FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
			}];

			if($scope.customerInfoForm.newRefs.ref4FirstName && $scope.customerInfoForm.newRefs.ref4LastName){
				$scope.ref.push({
					first: $scope.customerInfoForm.newRefs.ref4FirstName.$modelValue,
					last: $scope.customerInfoForm.newRefs.ref4LastName.$modelValue,
					full: getFullName($scope.customerInfoForm.newRefs.ref4FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
				});
			};

			var currentRef = _.at($scope.ref, index);
			var fullName = getFullName($scope.customerInfoForm.newRefs['ref' + index + 'FirstName'].$modelValue, $scope.customerInfoForm.newRefs['ref' + index + 'LastName'].$modelValue);
			var matches = _.pluck(_.where($scope.ref, { 'full': fullName }), 'full');
			var errorProp = 'ref' + index + 'NameSubmitted';
			var propNames = getReferenceErrors(index);
			var first = propNames[0];
			var last = propNames[1];

			matches = _.filter(matches, function (item) {
				if (!_.isUndefined(item)) return item;
			});

			var propFirstName = 'ref' + index + 'FirstName';
			var propLastName = 'ref' + index + 'LastName';

			if (matches.length > 1 || referenceNameSameAsCustomer(fullName)) {
				$scope.newRefs[first].$setValidity(first, false);
				$scope.newRefs[last].$setValidity(last, false);
				$scope[errorProp] = true;
			} else {
				$scope.newRefs[first].$setValidity(first, true);
				$scope.newRefs[last].$setValidity(last, true);
				$scope[errorProp] = false;
			}
		}

		// secondary phone number duplicate check
		function validateSecondaryPhone(index) {
			$scope.phoneNumbers = [
				{ 'number': $scope.customerInfoForm.phoneSecondary.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref3Number.$modelValue },
				{ 'number': ($scope.customerInfoForm.newRefs.ref4Number ? $scope.customerInfoForm.newRefs.ref4Number.$modelValue : '')}
			];


			var currentNum = $scope.phoneNumbers[index - 1].number;
			var matches = _.pluck(_.where($scope.phoneNumbers, { 'number': currentNum }), 'number');
			var errorProp = 'phone' + index + 'NumberSubmitted';

			if (currentNum !== undefined) {
				if (currentNum.length === 10) {
					$scope.secondaryNoTypeRequired = true;
				} else {
					$scope.secondaryNoTypeRequired = false;
				}
			}

			matches = _.filter(matches, function (item) {
				if (!_.isUndefined(item)) return item;
			});

			void 0;

			if (matches.length > 1 || referencePhoneSameAsCustomer(currentNum)) {
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Starts*/
				$scope.customerInfoForm.$invalid = true;
				$scope[errorProp] = true;
				$scope.phone1NumberSubmitted = true ;
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Ends*/
			} else {
				$scope[errorProp] = false;
			}
		}

		function validateReferencePhone(index, event) {
			doPhoneValidation(event); // AC 10616 Changes
			$scope.refPhones = [
				{ 'number': $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
				{ 'number': $scope.customerInfoForm.newRefs.ref3Number.$modelValue }
			];
			if($scope.customerInfoForm.newRefs.ref4Number){
				$scope.refPhones.push({ 'number': $scope.customerInfoForm.newRefs.ref4Number.$modelValue });
			}

			var currentRef = $scope.refPhones[index - 1].number;
			var propName = 'ref' + index + 'Number';
			var matches = _.pluck(_.where($scope.refPhones, { 'number': currentRef }), 'number');
			var errorProp = 'ref' + index + 'NumberSubmitted';

			matches = _.filter(matches, function (item) {
				if (!_.isUndefined(item)) return item;
			});

			void 0;
			void 0;
			if (matches.length > 1 || referencePhoneSameAsCustomer(currentRef)) {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, false);
				$scope[errorProp] = true;
			} else {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, true);
				$scope[errorProp] = false;
			}
		}

		//Start AC-10616 - Code change to handle all zero phone number
		function doPhoneValidation(event) {
			var phoneNumber = event.target.value;
			var propName = event.target.name;
			var errorProp = event.target.name + 'Invalid';
			if (event.target.value == "(___) ___-____") {
				return false;
			}
			if ($scope.phoneNumberValidation.test(phoneNumber)) {
				$scope[errorProp] = false;
				if ($scope.customerInfoForm.newRefs && $scope.customerInfoForm.newRefs[propName]) {
					$scope.customerInfoForm.newRefs[propName].$setValidity(propName, true);
				}
				/*if($scope.newform.newCustomerCheckForm.primaryPhone){
					$scope.newform.newCustomerCheckForm.primaryPhone.$setValidity(propName, true);
				}*/
				if(propName == 'primaryPhone'){
					$scope.customerInfoForm.primaryPhone.$setValidity(propName,true);
				}

				if(propName == 'ccprimaryPhone'){
					$scope.customerInfoForm.coCustomerForm.ccprimaryPhone.$setValidity(propName, true);
				}

				if(propName == 'mortgageCompanyNum'){
					$scope.customerInfoForm.mortgageCompanyNum.$setValidity(propName, true);
				}

				if(propName == 'phoneSecondary'){
					$scope.customerInfoForm.phoneSecondary.$setValidity(propName, true);
				}
			} else {
				$scope[errorProp] = true;
				if ($scope.customerInfoForm.newRefs && $scope.customerInfoForm.newRefs[propName]) {
					$scope.customerInfoForm.newRefs[propName].$setValidity(propName, false);
					$scope.customerInfoForm.$invalid = true;
				}else{
					$scope.customerInfoForm.$invalid = true;
				}

				if(propName == 'primaryPhone'){
					$scope.customerInfoForm.primaryPhone.$setValidity(propName,false);
				}

				if(propName == 'ccprimaryPhone'){
					$scope.customerInfoForm.coCustomerForm.ccprimaryPhone.$setValidity(propName, false);
				}

				if(propName == 'mortgageCompanyNum'){
					$scope.customerInfoForm.mortgageCompanyNum.$setValidity(propName, false);
				}
				if(propName == 'phoneSecondary'){
					$scope.customerInfoForm.phoneSecondary.$setValidity(propName, false);
				}

				/*if($scope.newform.newCustomerCheckForm.primaryPhone){
					$scope.newform.newCustomerCheckForm.primaryPhone.$setValidity(propName, false);
					$scope.customerInfoForm.$invalid = true;
				}*/
			}
		}
		//End AC-10616 - Code change to handle all zero phone number

		function validateDOB(dateOfBirth) {
			void 0;
			if (dateOfBirth.year && dateOfBirth.month && dateOfBirth.day) {
				var isValid;
				var isDateValidOnLeapYear; // VAN-2842 Changes
				if ((dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month > $scope.dobvalid.month) || (dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month == $scope.dobvalid.month && dateOfBirth.day > $scope.dobvalid.day)) {
					isValid = false;
				} else {
					isValid = true;
				}
				// VAN-2842 Changes Starts
				if (moment(dateOfBirth.month + "/" + dateOfBirth.day + "/" + dateOfBirth.year + "", 'MM/DD/YYYY', true).isValid()) {
					isDateValidOnLeapYear = true;
				} else {
					isDateValidOnLeapYear = false;
				}
				$scope.customerInfoForm.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				// VAN-2842 Changes Ends
				$scope.customerInfoForm.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('validAge', isValid);
			}
		}

		function zipValidation(zip) {
			//var pattern = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Starts*/
			if($rootScope.state=="PR"){
				getStoreInfoFactory.getPRZipcodeData().then(function(response) {
					void 0;
					if(response.zipCodes.length!=undefined){
						for(var z=0;z<response.zipCodes.length;z++){
							if("00"+(response.zipCodes[z])== zip){
								$scope.prZipValidation = true;
								$scope.customerInfoForm.zip.$setValidity('zip', true);
								break;
							}else{
								$scope.prZipValidation=false;
								$scope.customerInfoForm.zip.$setValidity('zip', false);
							}
						}
					}
				});
			}else{
				if ($scope.zipCodeVal.test(zip)) {//Defect 26474 code change
					/*VAN-850 -  Getting Error 700 while we provide Invalid zip code “00000”- Starts*/
					if($scope.customerInfo.address.zipCode != "00000"){
						isZipInStoreRadius(zip);
					}else{
						void 0;
						$scope.isZipValid = false;
					}
					/*VAN-850 -  Getting Error 700 while we provide Invalid zip code “00000”- Ends*/ 
				} else {
					void 0;
					$scope.isZipValid = false;
					void 0;
					$scope.customerInfoForm.zip.$setValidity('zip', false);
				}
			}
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Ends*/
		}

		function resetZipData() {
			$scope.customerInRadius = true;
			$scope.isZipValid = true;
			$scope.customerInfoForm.zip.$setValidity('zip', true);
		}

		function zipSubmitFalse(event) {
			if (event.keyCode === 13) {
				event.preventDefault();
				return false;
			}
		}

		function isZipInStoreRadius(zip, getFn) {
			getFn = getFn || function () { };
			getStoreInfoFactory.getLatLngFromZip(zip)
				.then(function (results) {
					void 0;
			// Google API Implementation - Starts
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
							distance = getStoreInfoFactory.getCustomerDistance($scope.storeLatLng, customer),
							isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius($scope.storeInfo.allowedRentalRadiusMiles, distance),
							isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

						void 0;
						void 0;
						void 0;
						$scope.customerDistance = Math.round(distance);

						if (isCustomerInUS && isInAllowedRadius) {
							$scope.customerInRadius = true;
							$scope.customerInfoForm.zip.$setValidity('zip', true);
							getFn("true");
						} else {
							$scope.customerInRadius = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
							getFn("false");
						}

					} else {
						void 0;
						$scope.isZipValid = false;
						$scope.customerInfoForm.zip.$setValidity('zip', false);
						getFn("invalidZip");
					}
				}).
			catch(function (response) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(response);
				});
		}

		function checkState(state) {
			// console.log('test', state);
			var unsupportedStates = $scope.stateRestrictions.unsupportedStates.split(",") || '';
			if (unsupportedStates.indexOf(state) > -1) {
				$scope.invalidState = modalService.open({
					title: 'State Not Supported',
					templateUrl: 'partials/alert_stateUnsupported.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
			if($rootScope.xpartnerLogin)//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				{
			stateValidation(state);//VAN-3209-Use customers zip code on agreement creation 
				}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
		}

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		}

		function validateCCSSN() {
			if((!$scope.custAdditionalInfo.noCocustomer) && ($scope.coCustomerPrivate.ssn.ssn1 && $scope.coCustomerPrivate.ssn.ssn1 == $scope.customerPrivate.ssn.ssn1) && 
                ($scope.coCustomerPrivate.ssn.ssn2 && $scope.coCustomerPrivate.ssn.ssn2 == $scope.customerPrivate.ssn.ssn2) && 
                ($scope.coCustomerPrivate.ssn.ssn3 && $scope.coCustomerPrivate.ssn.ssn3 == $scope.customerPrivate.ssn.ssn3)){
				$scope.customerInfoForm.$invalid = true;
				$scope.saveSameSSNError = true;
			} else {
				$scope.saveSameSSNError = false;
			}
		}

		function saveDraft() {
			
			if((!$scope.custAdditionalInfo.noCocustomer) && 
				($scope.coCustomerPrivate.ssn.ssn1 && $scope.customerPrivate.ssn.ssn1 && 
					$scope.coCustomerPrivate.ssn.ssn1 == $scope.customerPrivate.ssn.ssn1) && 
                ($scope.coCustomerPrivate.ssn.ssn2 && $scope.customerPrivate.ssn.ssn2 && 
                	$scope.coCustomerPrivate.ssn.ssn2 == $scope.customerPrivate.ssn.ssn2) && 
                ($scope.coCustomerPrivate.ssn.ssn3 && $scope.customerPrivate.ssn.ssn3 && 
                	$scope.coCustomerPrivate.ssn.ssn3 == $scope.customerPrivate.ssn.ssn3)){
				$scope.customerInfoForm.$invalid = true;
				$scope.saveSameSSNError = true;
				$scope.scrollToTop("body");
				return false;
			} else {
				$scope.saveSameSSNError = false;
			}
			//check if secondary number is filled then secondary phone type is valid
			if (($scope.customerInfoForm.PhoneType2.$valid) && (!$scope.primaryPhoneInvalid)) {
				saveCustomerInfo()
					.then(function () {
						$location.path('dashboard');
					});
			}
			else {
				$scope.draftSaved = true;
				$scope.formSubmitted = false;
			}
		}

		function saveAndProcess() {
			$scope.formSubmitted = true;
			$scope.draftSaved = false;		
			// Defect 28142
			if ($scope.customerPrivate.ssn.ssnType == 'SSN' && 
				($scope.customerPrivate.ssn.ssn1 !== $scope.customerPrivate.verifyssn.ssn1
				|| $scope.customerPrivate
				.ssn.ssn2 !== $scope.customerPrivate.verifyssn.ssn2
				|| $scope.customerPrivate.ssn.ssn3 !== $scope.customerPrivate.verifyssn.ssn3)) {
				$scope.scrollToTop("body");
				return false;
			} else if($scope.customerPrivate.ssn.ssnType == 'ITIN' && 
				($scope.customerPrivate.itin.itin1 !== $scope.customerPrivate.verifyitin.itin1
				|| $scope.customerPrivate.itin.itin2 !== $scope.customerPrivate.verifyitin.itin2
				|| $scope.customerPrivate.itin.itin3 !== $scope.customerPrivate.verifyitin.itin3)){
				$scope.scrollToTop("body");
				return false;
			} 
			
			if((!$scope.custAdditionalInfo.noCocustomer) && ($scope.coCustomerPrivate.ssn.ssn1 && ($scope.coCustomerPrivate.ssn.ssn1 == $scope.customerPrivate.ssn.ssn1)) && 
                ($scope.coCustomerPrivate.ssn.ssn2 && ($scope.coCustomerPrivate.ssn.ssn2 == $scope.customerPrivate.ssn.ssn2)) && 
                ($scope.coCustomerPrivate.ssn.ssn3 && ($scope.coCustomerPrivate.ssn.ssn3 == $scope.customerPrivate.ssn.ssn3))){
				$scope.customerInfoForm.$invalid = true;
				$scope.scrollToTop("body");
				return false;
			}	
			proceedSaveApplication();	
		}

		function confirmAndEditEmail(){
		  var deferred = $q.defer();
		  $scope.emailAddress = $scope.customerInfo.email;

		    var confirmAndEditEmailModal = modalService.open({
		      title: "Customer Verification",
		      templateUrl: 'partials/modal_confirmAndEditEmail.html',
		      scope: $scope,
		      backdrop: 'static',
		      controller: 'confirmAndEditEmailCtrl'
		    });
		    confirmAndEditEmailModal.result.then(function(email) {
		      $scope.customerInfo.email = email;
		      $scope.customerInfo.emailVerify = email;
		      saveCustomerInfo().then(function (result) {
		        if(result == 'success') {
		          modalService.dismiss();
		          deferred.resolve(result);
		        }         
		      }, function (error) {
		        SharedDataSvc.reportRaygun(error);
		        deferred.reject(error);
		      });       
		    });
		  		      
		  return deferred.promise;
		};  

		function proceedSaveApplication(){
			if(!$scope.isSpanishStore) {
				var zipCode = $scope.customerInfo.address.zipCode ? $scope.customerInfo.address.zipCode.substring(0,5) : "";//Defect 26474 code changes
				isZipInStoreRadius(zipCode, function (status) {
					void 0;
					if (status === "true") {
						$scope.customerInRadius = true;
						$scope.isZipValid = true;
						saveAndProcessAppData();
					} else {
						if (status === "false") {
							$scope.customerInRadius = false;
							$scope.isZipValid = true;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						} else {
							$scope.customerInRadius = true;
							$scope.isZipValid = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						}
					}
				});
			} else {
				saveAndProcessAppData();
			}
		}

		function saveAndProcessAppData() {
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Starts*/
				if($scope.customerInfoForm.primaryPhone.$modelValue == $scope.customerInfoForm.phoneSecondary.$modelValue){
					$scope.phone1NumberSubmitted = true;
				}
				if ($scope.customerInfoForm.$valid && !$scope.phone1NumberSubmitted && $scope.isStateValid) {//VAN-3209-Use customers zip code on agreement creation
				/*VAN-367 AWP - User is allowed to submit an application with same phone number in priamry and secondary phone number field - Ends*/
				if($scope.submittingApplication) {
					/* VAN-340 changes */
					//$scope.confirmAndEditEmail().then(function(response) { 
						modalService.dismiss();
						$scope.submittingApplication = false;
					  	if ($scope.storeInfo.allowTextVerification) {
								$scope.showVerification = true;
								//$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
								//saveCustomerInfo().then(confirmEmail);
								return false;
							} else {
								saveCustomerInfo().then(processCustomerApp);
							}				  
					/*}, function(error){
					    SharedDataSvc.reportRaygun(error);
					    return false;
					});*/
					/* VAN-340 changes */
				} /*else {
					saveCustomerInfo().then(processCustomerApp);	
				}*/
			} else {
				void 0;
				var error = $scope.customerInfoForm.$error;
				var invalidFields = [];
				angular.forEach(error.required, function (field) {
					if (field.$invalid) {
						var fieldName = field.$name;
						invalidFields.push(fieldName);
					}
				});
				if(invalidFields[0] == 'newRefs' && !$scope.storeInfo.isReferenceRequired){ //Defect	28721	:AWP - Not able to submit application in IE due to reference ng-required check
					$scope.customerInfoForm.$invalid = false;
					$scope.customerInfoForm.$valid = true;
					if($scope.submittingApplication) {
						$scope.confirmAndEditEmail().then(function(response) {
							modalService.dismiss();
							$scope.submittingApplication = false;
							if ($scope.storeInfo.allowTextVerification) {
									$scope.showVerification = true;
									//$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
									//saveCustomerInfo().then(confirmEmail);
									return false;
								} else {
									saveCustomerInfo().then(processCustomerApp);
								}				  
						}, function(error){
							SharedDataSvc.reportRaygun(error);
							return false;
						});
					} else {
						return true;
					}							
				}		
				$scope.scrollToTop("body"); // Added for scroll up VAN-10736
				void 0;
				void 0;
			}
		}

		function masterCodeModal(){
			void 0;
			if($scope.storeInfo.isManned && !$scope.storeInfo.hybridEnabled){
				showModal('confirmPasswordAnowOverride');
			}else{
				var verifyAssociatePassModal = modalService.open({
					title: 'Verify Associate Pass Modal',
					templateUrl: 'partials/modal_verifyAssociatePass.html',
					scope: $scope,
					backdrop: 'static'
				});
			}
			
		}

		function showModal(name, windowClass, size) {
	      windowClass = windowClass || '';
	      size =  size || '';
	      $scope.modal = modalService.open({
	        templateUrl: '../../../partials/modal_' + name + '.html',
	        backdrop: 'static',
	        windowClass: windowClass,
	        size: size,
	        scope: $scope
	      });
	    }

	    function verifyPassWordEmployee(pass){
	        $scope.noEmpPass = false;
	        $scope.errEmpPass = false;
	      	if (pass) {
	        	var auth = $rootScope.storeNo;
	        	if (auth === pass) {
	            	void 0;
	           	 $scope.modal.dismiss();
	           	  var masterCodeModal = modalService.open({
						title: 'Master Code Modal',
						templateUrl: 'partials/modal_confirmMasterCode.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});
	       	 } else {
	         	 $scope.errEmpPass = true;
	         	 void 0;
	          }
	      	} else {
	       	 $scope.noEmpPass = true;
	      	}
      	}

		function verifyEmployeePassPhrase(val){
			if(val) {
	          showModal('Processing', 'processing-modal');
	          agreementFactory.validatePassPhrase(val, 'mastercode').then(function(response) {
	              void 0;
	              $scope.associatePassFail = false;
	              SharedDataSvc.masterCodePassphraseUsed = val;
	              $scope.empPassPhrase = "";
	              var masterCodeModal = modalService.open({
						title: 'Master Code Modal',
						templateUrl: 'partials/modal_confirmMasterCode.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});
	            }, function (response) {
	              void 0;
	              $scope.empPassPhrase = "";
	              SharedDataSvc.validateMasterCodePassPhraseAttempts = SharedDataSvc.validateMasterCodePassPhraseAttempts + 1;
	              void 0;
	              if(SharedDataSvc.validateMasterCodePassPhraseAttempts === 3) {
	                SharedDataSvc.validateMasterCodePassPhraseAttempts = 0;
	                $scope.modal.dismiss();
	                $scope.invalidPassPhrase = false;
	                $scope.associatePassFail = false;
	                $scope.empPassPhrase = "";
	              } else {
	                $scope.errorMessage = response.data.errorData.status_message;
	                $scope.invalidPassPhrase = true;
	                $scope.associatePassFail = true;
	                showModal('verifyAssociatePass');
	              }                    
	          });
	        }else {
	          $scope.associatePassFail = true;
	          $scope.invalidPassPhrase = false;
	        }
		}

		function resetPromoPayment() {
	        $scope.invalidPassPhrase = false;
	        $scope.associatePassFail = false;
	        $scope.empPassPhrase = "";
	     }

		function processVerification() {
			$scope.customerPrivate.mastercode.employeeName = '';
			$scope.customerPrivate.mastercode.employeeId = '';
			$scope.customerPrivate.mastercode.comments = '';
			$scope.customerPrivate.mastercode.masterTextCode = '';
			$scope.customerPrivate.mastercode.mastertextCodeAttemptsNumber = 0;
			$scope.customerPrivate.mastercode.associatePass = '';
			$scope.customerPrivate.mastercode.noAssociatePass = true;
			$scope.customerPrivate.mastercode.invalidAssociatePass = false;


			saveAndProcess();
			if ($scope.customerInfoForm.$valid && !$scope.phone1NumberSubmitted) {
				saveCustomerInfo().then(function () {
					generateTextCode();
				});
			}
		}

		function addSecondaryPhoneDetails() {
			$scope.showAddSecondaryPhoneDetails = true;
			$scope.formSubmitted = false;
		}

		function saveSecondaryPhoneDetails() {
			saveAndProcess();
			if ($scope.customerInfoForm.$valid && !$scope.phone1NumberSubmitted) {
				$scope.showAddSecondaryPhoneDetails = false;
			}
		}

		// Start VAN - 10736 Added for scroll up
		$scope.scrollToTop = function (toHere) {
			$('html, body').animate({
				scrollTop: $(toHere).position().top
			}, 'fast');
		}
		// End

		function generateDays(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.days = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.days.push(day);
				day++;
			}
		}

		function generateDaysdob(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.daysdob = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.daysdob.push(day);
				day++;
			}
		}

		function generateDaysNextPay(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.daysNextPay = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.daysNextPay.push(day);
				day++;
			}
		}

		function validateLeaseYear(){
			var months;
			var currentTime = new Date();
			var currentMonth = currentTime.getMonth() + 1;
			var curryear = currentTime.getFullYear();
			if(Number($scope.custAdditionalInfo.leaseEndDate.month) < currentMonth && $scope.leaseYears[0] == curryear){
				$scope.leaseYears.shift();
			}else if(Number($scope.custAdditionalInfo.leaseEndDate.month) >= currentMonth && $scope.leaseYears[0] != curryear){
				$scope.leaseYears.unshift(curryear);
			}
		}


		function setPreviousAdd(){
			var months;
			var currentTime = new Date();
			var currentMonth = currentTime.getMonth() + 1;
			var curryear = currentTime.getFullYear();
			if(Number($scope.custAdditionalInfo.moveInDate.month) > currentMonth && $scope.moveInYears[0] == curryear){
				$scope.moveInYears.shift();
			}else if(Number($scope.custAdditionalInfo.moveInDate.month) <= currentMonth && $scope.moveInYears[0] != curryear){
				$scope.moveInYears.unshift(curryear);
			}

			if($scope.custAdditionalInfo.moveInDate.month && $scope.custAdditionalInfo.moveInDate.year){
				
	        	var enteredDay = currentTime.getDate();
	        	var enteredMonth= $scope.custAdditionalInfo.moveInDate.month;
	        	var enteredYear = $scope.custAdditionalInfo.moveInDate.year;

				var enteredDateFormat = enteredYear+'/'+enteredMonth+'/'+enteredDay;
				var enDate = new Date(enteredDateFormat);

				
	    		months = (currentTime.getFullYear() - enDate.getFullYear()) * 12;
	    		months -= enDate.getMonth() + 1;
	    		months += currentTime.getMonth();
	    		if(months > 12 ){
	    			$scope.showPreviousAdd = false;
	    		}else{
	    			$scope.showPreviousAdd = true;
	    		}
			}
		}

		function generateKeys() {
			SharedDataSvc.generatedkeys = SharedDataSvc.generatekeys();
			$scope.my_keys = SharedDataSvc.generatedkeys;
			$scope.my_keys[0].replace(/(-----(\w+) RSA PRIVATE KEY-----|\r\n|\n|\r)/gm, '');
			$scope.my_keys[1].replace(/(-----(\w+) PUBLIC KEY-----|\r\n|\n|\r)/gm, '');
			SharedDataSvc.cleankeys = $scope.my_keys;
			SharedDataSvc.commonHeadersENCRYPT['Public-Key'] = toString($scope.my_keys[1]);
		}
		
		//VAN-510 - Electronic Consent moved to app flow - Starts
//		Call the Start Application flow
		function createOrderApp(personData){
			createOrder = true;
			$rootScope.createOrderFlow=true;
			if($scope.isConsentResponse != "null" && $scope.isConsentResponse != undefined && $scope.isConsentResponse == "false"){
				updateCustomerConstent(personData);
			}
			if($scope.isConsentResponse == "null" || $scope.isConsentResponse == "true"){
				retrieveApplication(personData);
			}
		}
		
		function startApplication(personData){
			$rootScope.startAppFlow = true;
			if($scope.isConsentResponse != "null" && $scope.isConsentResponse != undefined && $scope.isConsentResponse == "false"){
				updateCustomerConstent(personData);
			}
			if($scope.isConsentResponse == "null" || $scope.isConsentResponse == "true"){
				retrieveApplication(personData);}
			
			
		}

		function updateCustomerConstent(personData){
			//call to update remote signing flag update engagement call
			//VAN-3402-Re-engagement with Code - AWP - Start
			var updateData;
			if($rootScope.isAppSrch && $scope.custApp!=''){
				updateData = {
						firstName: $scope.custApp.firstName,
						lastName: $scope.custApp.lastName,
						phoneNumber: $scope.custApp.phones[0].areaCode+$scope.custApp.phones[0].number,
						isTcpaConstentFlg: $scope.personData.istcpaflag //VAN 4120-Update the disclaimer in AWP - SLI/HT originated agreement
				};
			}else{
				updateData = {
						firstName: personData.firstName,
						lastName: personData.lastName,
						phoneNumber: personData.phone,
						isTcpaConstentFlg: personData.istcpaflag //VAN 4120-Update the disclaimer in AWP - SLI/HT originated agreement
				};
			}//VAN-3402-Re-engagement with Code - AWP - End
			modalService.processing('Processing');
			applicationService.updateCustomerConsent(updateData)
			.then(function(response) {
				void 0;
				modalService.dismiss();
				if(response.status == '200' ){
					//VAN-3402-Re-engagement with Code - AWP - Start
					if($rootScope.isAppSrch){
						getApplicationSuccess($scope.custApp);
					}else{
						retrieveApplication(personData);
					}//VAN-3402-Re-engagement with Code - AWP - End
				}
			}, function(response){
				void 0;
				modalService.dismiss();
			});
		}

		//Show electronic Consent at Start Application Flow
		function newCustomerElectronicConsent(){
			void 0;
			$scope.myModalInstance = $modal.open({
				templateUrl: 'partials/modal_eAgreementNotice_StartApp.html',
				show: true,
				backdrop: 'static',

				resolve: {
					myModalInstance: function () {
						return $scope.myModalInstance;
					}
				}
			});
			$scope.modalClose = function (){
				$scope.myModalInstance.close();
			}}

		//Checkbox enable/disable for electronic consent
		$scope.eConsentAgreefnc = function () {
			if ($scope.eConsentAgree) {
				return true;
			} else {
				return false;
			}
		};
		//VAN-510 - Electronic Consent moved to app flow - Ends

		function retrieveApplication(personData) {
			void 0;

			// engagement scenario from agreement page when radius is out of region
			if (personData.hasEngagementFromAgreement) {
				var existingCustSSNModal = modalService.open({
					title: 'Customer Verification',
					templateUrl: 'partials/modal_custExistsSsnVerify.tpl.html',
					scope: $scope,
					backdrop: 'static'
				});
				existingCustSSNModal.result
					.then(function (data) {
						personData.ssn = data.ssn1 + data.ssn2 + data.ssn3;
						modalService.processing(modalTitle);
						applicationService.getApplication(personData)
							.then(callApplicationEngagement, getApplicationFail);
						return;
					});
				return;
			}

			SharedDataSvc.globalVars.engagementId = "000000"; //story 26289 code changes
			var modalTitle = (personData.ssn && !$scope.itinFlag) ? 'Verifying SSN' : 'Checking Customer';
			modalService.processing(modalTitle);
			applicationService.getApplication(personData)
				.then(checkCatApprovalSuccess, getApplicationFail);
		}


		/*function checkCatApprovalSSN(response){
			if(response.VanEngagement.applicationStatus === "InProgress" && response.VanEngagement.orderStatus.toLocaleLowerCase() === "notstarted"){
		    var selectCatApprovalSSNModal = modalService.open({
		      title: "Approval by Category",
		      templateUrl: 'partials/modal_selectCatApprovalSSN.html',
		      scope: $scope,
		      backdrop: 'static',
		      controller: ''
		    });
		    selectCatApprovalSSNModal.result.then(function() {
		    	$scope.isApprovalByCatSelected = true;
		    	formatCatApprovalList();
		    	$scope.isApprovalByCatSelected = true;
		        callApplicationEngagement(respose);   
		    });

		  }else{
		  	callApplicationEngagement(respose);
		  }
				
		}*/

		function callApplicationEngagement(response) {
			void 0;
			var customerData = response;
			var hasEngagement = _(customerData).has('VANEngagementId');
			//AC 26204 code changes starts
			if (customerData.personalReferences && customerData.personalReferences.length > 0) {
				for (var i = 0; i < customerData.personalReferences.length; i++) {										
					if (refType.indexOf(customerData.personalReferences[i].personalReferenceType) < 0) {
						customerData.personalReferences[i].personalReferenceType = "";
					}					
			}
			}
			//AC 26204 code changes ends
			if (hasEngagement) {
				handleEngagement(customerData);
			} else {
				createNewEngagement(customerData);
			}



		}

		// format Date
		function formatDate(date) {
			return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
		}

		//show expiration notification modal
		function showExpirationNotification(customerData, dateDiff, path, engagementId) {
			$scope.customerFirstName = customerData.firstName;
			$scope.approvalAmount = customerData.approvalAmount;
			$scope.approvalExpiryDays = dateDiff;
			var vanEngagementId = customerData.VANEngagementId || engagementId;
			var expirationNotify = modalService.open({
				title: 'Approval Expiration Notification',
				templateUrl: 'partials/alert_expirationNotification.tpl.html',
				backdrop: 'static',
				windowClass: 'approved-modal',
				scope: $scope
			});
			expirationNotify.result
				.then(function () {
					if (path === "dashboard") {
						navigateTo('dashboard/' + vanEngagementId);
					} else {
						navigateTo('ordersummary/' + vanEngagementId);
					}
					return;
				});
		}

		// Approval Amount Check
		function approvalAmountCheck(VANEngagementID) {
			var approvedAmountModal = modalService.open({
				title: 'Application Approved For Amount Less than $300',
				templateUrl: 'partials/alert_belowMin.tpl.html',
				backdrop: 'static',
				scope: $scope
			});
			approvedAmountModal.result.then(function (data) {
				navigateTo('dashboard/' + VANEngagementID);
			});
		}

		function createEngagementData(customerData, hasEngagement) {
			$scope.engagementDetails = {};
			var engagementProps = [
				'engagementId',
				'orderStatus',
				'deliveryStatus',
				'paymentStatus',
				'salesStatus',
				'agreementStatus',
				'applicationStatus',
				'orderOriginator'
			];
			for (var i = 0; i < engagementProps.length; i++) {
				if (hasEngagement) {
					$scope.engagementDetails[engagementProps[i]] = customerData.VanEngagement[engagementProps[i]];
				} else {
					$scope.engagementDetails[engagementProps[i]] = customerData[engagementProps[i]];
				}
			}
			if (hasEngagement) {
				$scope.engagementDetails['customerId'] = customerData.VanEngagement.customer.customerId;
				$scope.engagementDetails['customerHtID'] = customerData.VanEngagement.customerHTId;
				$scope.engagementDetails['customerHtStoreId'] = customerData.VanEngagement.customerHTStoreId;
				$scope.engagementDetails['vanStoreId'] = customerData.VanEngagement.vanStore.storeId;
			} else {
				$scope.engagementDetails['customerId'] = customerData.customerId;
				$scope.engagementDetails['customerHtID'] = customerData.customerHtID;
				$scope.engagementDetails['customerHtStoreId'] = customerData.customerHtStoreId;
				$scope.engagementDetails['vanStoreId'] = customerData.vanStoreId;
			}

			void 0;
		}

		// show out of radius modal
		function outOfRadiusModal() {
			var outOfRadius = modalService.open({
				title: 'Out of Radius',
				templateUrl: 'partials/modal_outsideRadiusAppCheck.html',
				backdrop: 'static',
				scope: $scope
			});
		}

		// show ITIN disabled modal
		function itinDisabledModal() {
			var itinDisabled = modalService.open({
				title: 'ITIN Disabled Store',
				templateUrl: 'partials/modal_itinDisabled.html',
				backdrop: 'static',
				scope: $scope
			});
		}

		// out of radius exit click handling
		function handleCustomerOutOfRadius() {
			$scope.engagementDetails.applicationStatus = 'OutOfArea';
			updateEngagementItemFactory.putData($scope.engagementDetails)
				.then(function (response) {
					$log.log('[Application.handleCustomerOutOfRadius] Successfully updated engagement');
					navigateTo('dashboard/' + $scope.engagementDetails.engagementId);
				}, function (response) {
					$log.log('[Application.handleCustomerOutOfRadius] Updating engagement failed');
					modalService.dismiss();
				});
		}

		// out of radius update application handling
		function navigateToApplication() {
			void 0;
			$log.log($scope.engagementDetails);
			customerDataFactory.findByEngagementId($scope.engagementDetails.engagementId)
				.then(function (response) {
					$log.log('[Application.getCustomer] customer', response);
					var person = {
						firstName: response.customer.fName,
						lastName: response.customer.lName,
						phone: response.customer.phNum,
						ssn: $scope.ssnNumber
					};
					applicationService.getApplication(person)
						.then(callApplicationEngagement, getApplicationFail);

				}).catch(function (response) {
					$log.log('[Application.getCustomer] could not get customer data', response);
				});
		}

		//fetch and validate zip code
		function fetchAndValidateZipCode(customerData) {
			var zipCode = "";
			resetZipData();
			angular.forEach(customerData.address, function (address) {
				if (address.zipCode && address.zipCode !== "") {
					zipCode = address.zipCode;
				}
			});
			zipCode = zipCode.substring(0,5);//Defect 26474 code change
			if(zipCode) {
				zipValidation(zipCode);
			}
		}

		function showSameDayAgreementModal() {
			void 0;
			var processingModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_sameDayAgree.html',
				backdrop: 'static'
			});
		}

		//show associate review modal
		function showAssociateReviewModal(engagementId, modalName) {
			var assocrvwModalUnmanned = modalService.open({
				templateUrl: 'partials/alert_' + modalName + '.tpl.html',
				windowClass: 'modal-small',
				backdrop: 'static',
				scope: $scope
			});
			assocrvwModalUnmanned.result.then(function () {
				navigateTo('dashboard/' + engagementId);
			});
		}

		//story 25996 changes starts
		//show decline agreement modal
		function showAgreementDeclineModal(engagement) {
			var customerData;
			customerDataFactory.findByEngagementId(engagement.engagementId)
				.then(function (response) {
					customerData = response;
					$scope.customerEngagementInfo = {};
					void 0;
					var engagementProps = [
						'engagementId',
						'orderStatus',
						'deliveryStatus',
						'paymentStatus',
						'salesStatus',
						'agreementStatus',
						'applicationStatus',
						'orderOriginator'
					];
					// cherry pick the needed properties for a proper engagement
					for (var i = 0; i < engagementProps.length; i++) {
						$scope.customerEngagementInfo[engagementProps[i]] = response[engagementProps[i]];
					}
					$scope.customerEngagementInfo['customerId'] = response.customer.customerId;
					$scope.customerEngagementInfo['customerHtID'] = response.customerHTId;
					$scope.customerEngagementInfo['customerHtStoreId'] = response.customerHTStoreId;
					$scope.customerEngagementInfo['vanStoreId'] = response.vanStore.storeId;
					var declineModal = modalService.open({
						templateUrl: 'partials/modal_agreementDeclineAndReEditOrder.html',
						scope: $scope,
						backdrop: 'static'//AC 25996 updated code changes
					});

				}).catch(function (response) {
					void 0;
				});


		}
		//Story 29237 code changes
		function declineAgreement() {
			showProcessingModal("Decline Agreement");
			var engagement = $scope.customerEngagementInfo;
			engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(engagement)
				.then(function (response) {
					void 0;
					modalService.dismiss();
					navigateTo('ordersummary/' + $scope.customerEngagementInfo.engagementId);
				}, function (response) {
					void 0;
					modalService.dismiss();
				});
		}
		//Story 29237 code changes ends
		function cancelAgreementDecline() {
			navigateTo('dashboard/' + $scope.customerEngagementInfo.engagementId);
		}
		//story 25996 changes ends

		//Story 30445 -AWP, Kiosk: Add email verification edit box - start	
		function compareEmailVerify(event, newvalue) {
			if ($scope.customerInfoForm.emailAddressVerify.$invalid || ($scope.newform.newCustomerCheckForm && $scope.newform.newCustomerCheckForm.emailAddressVerify && $scope.newform.newCustomerCheckForm.emailAddressVerify.$invalid)) {
				$scope.verifyEmail = true;
				$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
			}
			if ($scope.customerInfoForm.emailAddress.$valid && $scope.customerInfoForm.emailAddressVerify.$valid) {
				if ($scope.customerInfo.email) {
					//$scope.verifyEmail = newvalue === $scope.customerInfo.email ? true : false;
					if (newvalue.toUpperCase() === $scope.customerInfo.email.toUpperCase()) {
						$scope.verifyEmail = true;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
					} else {
						$scope.verifyEmail = false;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', false);
					}
				}
			} else if ($scope.newform.newCustomerCheckForm && $scope.newform.newCustomerCheckForm.emailAddressVerify && $scope.newform.newCustomerCheckForm.emailAddressVerify.$valid) {
				if ($scope.customerInfoModal.emailAddress) {
					$scope.verifyEmail = newvalue.toUpperCase() === $scope.customerInfoModal.emailAddress.toUpperCase() ? true : false;
				}
			}
		}
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end

		function checkCatApprovalSuccess(response){
			var skipCatSelect = false
			if(typeof response.status_code != 'undefined'){
				if(response.status_code === 'ENG-E100-303'){
					skipCatSelect = true
				}
			} 

			if(typeof response.overallApprovalStatus != 'undefined'){
				if(response.overallApprovalStatus === "ACCEPT"){
					skipCatSelect = true;
				}
			}


			if($scope.storeInfo.isProductTypeSelection && !skipCatSelect && typeof response.VanEngagement != 'undefined'){
				if(response.VanEngagement.applicationStatus === "InProgress" && response.VanEngagement.orderStatus.toLocaleLowerCase() === "notstarted"){
					$scope.isApprovalByCatSelected = true;
						if($scope.approvalByCatList.length > 1){
							var selectCatApprovalAppSuccessModal = modalService.open({
						      title: "Approval by Category",
						      templateUrl: 'partials/modal_selectCatApprovalAppSuccess.html',
						      scope: $scope,
						      backdrop: 'static',
						      controller: ''
						    });
						    selectCatApprovalAppSuccessModal.result.then(function() {
						    	formatCatApprovalList();
						    	//$scope.categoryCollected = true;
						        getApplicationSuccess(response);    
						    });
						}else{
							getApplicationSuccess(response);
						}
			  	}else{
			  	getApplicationSuccess(response);
			  	}
			}else{
				getApplicationSuccess(response);
			}
				
		}

		

		function getApplicationSuccess(response) {
			void 0;
			//var zipCode = "";
			SharedDataSvc.globalVars.engagementId = response.VANEngagementId //Story 26289 code changes
			var customerData = response;
			var hasEngagement = _(customerData).has('VANEngagementId');
			var expirationDate = formatDate(customerData.approvalExpiryDate);
			var todayDate = formatDate(datesFactory.getCurrentDate());
			var dateDiff = moment(expirationDate).diff(todayDate, 'days');
			//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Starts
			var agreementData = _(customerData).has('pifAgreement');
			if(agreementData){
			var inactiveDate = formatDate(customerData.pifAgreement.inactiveDate);}
			//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Ends
			
			//AC22760  code changes starts
			if (customerData.personalReferences && customerData.personalReferences.length > 0) {
				for (var i = 0; i < customerData.personalReferences.length; i++) {
					if (refType.indexOf(customerData.personalReferences[i].personalReferenceType) < 0) {
						customerData.personalReferences[i].personalReferenceType = "";
					}
				}
			}
			//AC22760 code changes ends	

			// Customer intends to create an order
			if (response.status_code === 'ENG-E100-303') {
				if (response.itinFlag && !$rootScope.itinAllowed) {
					$scope.storePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
					void 0;
					itinDisabledModal();
					return;
				}
				provideSSN();
				$scope.itinFlag = response.itinFlag;
				if (hasEngagement) {
					resetZipData();
					//Defect 26474 code changes
					if(!$scope.isSpanishStore) {
						var zipCode = (customerData.VanEngagement && customerData.VanEngagement.customer.addressZipcode) ? customerData.VanEngagement.customer.addressZipcode.substring(0,5):"";
						if(zipCode) {
							zipValidation(zipCode);
						}
					}
					createEngagementData(customerData, hasEngagement);					
				}
				return;
			}

			if ($scope.storeInfo.achVerification && response.VanEngagement && response.VanEngagement.applicationStatus === "InProgress") {
				var customerInfo = {
					"customerId": response.VANCustomerID,
					"engagementId": response.VANEngagementId,
					"customerFirstName": response.firstName,
					"customerLastName": response.lastName,
					"customerPrimaryPhone": response.phones[0].areaCode + response.phones[0].number
				}
				//provideBankingInfoForACH(customerInfo);
			}

			/* Doing out of radius check before missing info check
			 * because customer overall status should be updated here itself to be out of radius.
			 * Otherwise, it will show its previous status on the out of radius store.
			 */
			if (hasEngagement) {
				//for SLI customer out of radius issue - AC 26765
				if(customerData.VanEngagement && customerData.VanEngagement.applicationStatus == 'Declined' && customerData.overallApprovalStatus == "DECLINE"){
					$scope.declinedMessage = 'We are not able to approve your application at this time. An explanatory letter has been emailed to the customer.';
					var appDeclinedModal = modalService.open({
						title: 'Application Declined',
						templateUrl: 'partials/alert_applicationDeclined.tpl.html',
						backdrop: 'static',
						scope: $scope
					});
					appDeclinedModal.result.then(function () {
						$location.path('dashboard/' + customerData.VANEngagementId);
					});
					return;
				} 
				// out of radius check
				if (!$scope.customerInRadius && $scope.isZipValid) {
					outOfRadiusModal(customerData);
					return;
				}
			}/* else if(customerData.overallApprovalStatus == "DECLINE"){
				$scope.declinedMessage = 'We are not able to approve your application at this time. An explanatory letter has been emailed to the customer.';
				var appDeclinedModal = modalService.open({
					title: 'Application Declined',
					templateUrl: 'partials/alert_applicationDeclined.tpl.html',
					backdrop: 'static',
					scope: $scope
				});
				appDeclinedModal.result.then(function () {
					$location.path('dashboard');
				});
				return;
			}*/
			if($rootScope.xpartnerLogin)//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
				{
			//VAN-3209-Use customers zip code on agreement creation - Starts
			if(customerData.address && customerData.address.length > 0){
				if(customerData.address[0].state !=  $scope.storeInfo.state){
					if(customerData.VanEngagement != undefined){
						if(customerData.VanEngagement.applicationStatus == 'InProgress'){
							$scope.isStateValid = false;
						}else if(customerData.VanEngagement && customerData.VanEngagement.applicationStatus != 'Declined'){
							$scope.invalidState = modalService.open({
								title: 'State is invalid',
								templateUrl: 'partials/alert_invalidStateError.tpl.html',
								backdrop: 'static',
								size: 'sm',
								scope: $scope
							});
							return;
						}
					}else{
						$scope.invalidState = modalService.open({
							title: 'State is invalid',
							templateUrl: 'partials/alert_invalidStateError.tpl.html',
							backdrop: 'static',
							size: 'sm',
							scope: $scope
						});
						return;
					}
				}
			}
			//VAN-3209-Use customers zip code on agreement creation - Ends
				}//VAN-3228-AWP - same state rule apply to only XPARTNER and not XTEAM
			SharedDataSvc.checkMissingInfo(response, function (isDataGood) {

				void 0;

				if (isDataGood) {
					//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Starts
					if(createOrder){
						if(customerData.agreement !== null && customerData.agreement !== undefined && customerData.agreement.agreementStatus === 'ACTIVE'){
							void 0;
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_activeAgreementErrorAtOrder.tpl.html',
								backdrop: 'static'         
							});
							navigateTo('dashboard/');
							return;
						}
					}
					//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Ends

					if (hasEngagement) {
						// minimum approval amount check
						if (parseInt(customerData.approvalAmount) < parseInt(customerData.minimumApprovalAmount) && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted"
							&& customerData.pifAgreement !== undefined && customerData.pifAgreement.inactiveCode != 'CHARGEOFF' && inactiveDate != todayDate){//VAN-3541-PRB0043608 - PIF customer not getting the correct approval amount when reengaging on same day
							approvalAmountCheck(customerData.VANEngagementId);
							return;
						}

						// approval expiration notification
						if (customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted" && customerData.VanEngagement.orderStatus.toLocaleLowerCase() === "notstarted" && dateDiff < 8) {
							if (createOrder) {
								showExpirationNotification(customerData, dateDiff, "order");
							} else {
								showExpirationNotification(customerData, dateDiff, "dashboard");
							}
							return;
						}
						// showModal for unmanned store associate review call assistance details
						if (!$scope.storeInfo.isManned && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "assocrvw") {
							$scope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
							showAssociateReviewModal(customerData.VANEngagementId, "appAssociateReviewUnmanned");
							return;
						}
						// showModal for manned store to find associate to review application
						if ($scope.storeInfo.isManned && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "assocrvw") {
							showAssociateReviewModal(customerData.VANEngagementId, "appAssociateReviewManned");
							return;
						}

						// HT approved customer re-engagement in PR store - redirection to agreement for signing agreements
						if(customerData.VanEngagement !== undefined && (customerData.VanEngagement.applicationStatus && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted") && (customerData.VanEngagement.orderStatus && customerData.VanEngagement.orderStatus.toLocaleLowerCase() === "confirmed") && (customerData.VanEngagement.agreementStatus && customerData.VanEngagement.agreementStatus.toLocaleLowerCase() === "pending") && (customerData.VanEngagement.agreementOriginator && customerData.VanEngagement.agreementOriginator.toLocaleLowerCase() === "hightouch")) {
								/**if($scope.storeInfo.isDigitalSigningEnabled) {
									$location.path('digitalAgreement/' + customerData.VANEngagementId);
								} else {
									$location.path('agreement/' + customerData.VANEngagementId);
								}**/
								$location.path('dashboard/' + customerData.VANEngagementId);
								return;
						}

					}

					if (createOrder) {
						if (hasEngagement) {
							//story 25996 changes starts
							if (customerData.VanEngagement && ((customerData.VanEngagement.agreementStatus !== 'Declined' && customerData.VanEngagement.agreementStatus !== 'Voided') && customerData.VanEngagement.orderStatus === 'Confirmed' && (customerData.VanEngagement.agreementHTId != null && customerData.VanEngagement.agreementHTId != undefined) && (customerData.VanEngagement.applicationStatus.toLocaleLowerCase() !== 'declined')) && (customerData.VanEngagement.applicationStatus !== 'InProgress')) {//AC 25996 & AC 14458 updated code changes
								void 0;
								showAgreementDeclineModal(customerData.VanEngagement);
								return;
							} else {
								navigateTo('ordersummary/' + customerData.VANEngagementId);
							}
							//story 25996 changes ends
						} else {
							// HT approved customer re-engagement in PR store - redirection to agreement for signing agreements
							if(customerData.VanEngagement !== undefined && (customerData.VanEngagement.applicationStatus && customerData.VanEngagement.applicationStatus.toLocaleLowerCase() === "accepted") && (customerData.VanEngagement.orderStatus && customerData.VanEngagement.orderStatus.toLocaleLowerCase() === "confirmed") && (customerData.VanEngagement.agreementStatus && customerData.VanEngagement.agreementStatus.toLocaleLowerCase() === "pending") && (customerData.VanEngagement.agreementOriginator && customerData.VanEngagement.agreementOriginator.toLocaleLowerCase() === "hightouch")) {
									/**if($scope.storeInfo.isDigitalSigningEnabled) {
										$location.path('digitalAgreement/' + customerData.VANEngagementId);
									} else {
										$location.path('agreement/' + customerData.VANEngagementId);
									}**/
									$location.path('dashboard/' + customerData.VANEngagementId);
									return;
							}
							if(!$scope.isSpanishStore) {
								fetchAndValidateZipCode(customerData);
							}
							/*if(response.social){
								$scope.customerInfoModal.social = response.social;
							}*/

							/* Added condition here to go with full data of customer in case of re-engagement 
							 If you get ssn then it was engagement created earlier with complete application*/
							if (customerData.social) {
								createFullEngagement(customerData, function (returnData) {
									void 0;
									var VANEngagementId = returnData.VANEngagementId;
									updateEngagementItemFactory.getData(VANEngagementId).then(function (response) {
										var engagementItemData = response;
										void 0;
										createEngagementData(engagementItemData, hasEngagement);
										// minimum approval amount check
										if (parseInt(customerData.approvalAmount) < parseInt(customerData.minimumApprovalAmount) && engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted") {
											approvalAmountCheck(VANEngagementId);
											return;
										}
										// out of radius check
										else if (!$scope.customerInRadius && $scope.isZipValid) {
											outOfRadiusModal();
											return;
										}
										// approval expiration notification
										else if (engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted" && engagementItemData.orderStatus.toLocaleLowerCase() === "notstarted" && dateDiff < 8) {
											showExpirationNotification(customerData, dateDiff, "order", VANEngagementId);
											return;
										}
										// showModal for unmanned store associate review call assistance details
										else if (!$scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
											$scope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes  
											showAssociateReviewModal(VANEngagementId, "appAssociateReviewUnmanned");
											return;
										}
										// showModal for manned store to find associate to review application
										else if ($scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
											showAssociateReviewModal(VANEngagementId, "appAssociateReviewManned");
											return;
										}
										else {
											//story 25996 changes starts
											if (customerData.VanEngagement && ((customerData.VanEngagement.agreementStatus !== 'Declined' && customerData.VanEngagement.agreementStatus !== 'Voided') && customerData.VanEngagement.orderStatus === 'Confirmed' && (customerData.VanEngagement.agreementHTId != null && customerData.VanEngagement.agreementHTId != undefined) && (engagementItemData.applicationStatus.toLocaleLowerCase() !== 'declined'))) {//AC 25996 updated code changes
												void 0;
												//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Starts
												if(customerData.agreement !== null && customerData.agreement !== undefined && customerData.agreement.agreementStatus === 'ACTIVE'){
													void 0;
													var wrongvendor = modalService.open({
														show: true,
														templateUrl: 'partials/alert_activeAgreementErrorAtOrder.tpl.html',
														backdrop: 'static'         
													});
													navigateTo('dashboard/' + customerData.VanEngagement.engagementId);
												}
												else
												{
													showAgreementDeclineModal(customerData.VanEngagement);
												}
												//VAN-736 - PRB0042956 - AWP- New agreement is getting created next day after declining the order for an active agreement in HT - Ends
												return;
											} else {
												navigateTo('ordersummary/' + engagementItemData.engagementId);
											}
											//story 25996 changes ends
										}
									}, function (response) {
										// error
										void 0;
									});
								});
							} else {
								createNewEngagement(customerData, function (engagementData) {
									SharedDataSvc.globalVars.engagementId = engagementData.VANEngagementId; //story 26289 code changes reviewed by Theenmathi
									navigateTo('ordersummary/' + engagementData.VANEngagementId);
								});
							}
						}
						return;
					}

					// Customer has existing approval amount
					if (customerData.approvalAmount > 0 || customerData.overallApprovalStatus == 'DECLINE') { //Story 29310 Changes
						$scope.declinedMessage = 'We are not able to approve your application at this time. An explanatory letter has been emailed to the customer.';
						if (hasEngagement) {
							//Story 29310 Changes Start	
							if (customerData.overallApprovalStatus == 'DECLINE') {
								var appDeclinedModal = modalService.open({
									title: 'Application Declined',
									templateUrl: 'partials/alert_applicationDeclined.tpl.html',
									backdrop: 'static',
									scope: $scope
								});
								appDeclinedModal.result
									.then(function () {
										$location.path('dashboard/' + customerData.VANEngagementId);
									});
							} else {
								navigateTo('dashboard/' + customerData.VANEngagementId);
							}
							//Story 29310 Changes End
						} 
						//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Starts
						else if(agreementData && customerData.pifAgreement !== undefined && customerData.pifAgreement.agreementStatus == 'INACTIVE' && customerData.pifAgreement.inactiveCode == 'CHARGEOFF' /*&& inactiveDate == todayDate*/)//Commented as part of VAN-2606-Hit DE for any recent PIF, paid in full
							{
								createNewEngagement(customerData);
								return;
							}
						//VAN-297 - AWP/KIOSK Same day PIF customers not getting approval - Ends
						else {
							if(!$scope.isSpanishStore){
								fetchAndValidateZipCode(customerData);
							}
							createFullEngagement(customerData, function (response) {
								void 0;
								var VANEngagementId = response.VANEngagementId;
								updateEngagementItemFactory.getData(VANEngagementId).then(function (response) {
									var engagementItemData = response;
									void 0;
									createEngagementData(engagementItemData, hasEngagement);
									// minimum approval amount check
									if (parseInt(customerData.approvalAmount) < parseInt(customerData.minimumApprovalAmount) && engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted") {
										approvalAmountCheck(VANEngagementId);
										return;
									}
									// out of radius check
									else if (!$scope.customerInRadius && $scope.isZipValid) {
										outOfRadiusModal();
										return;
									}
									// approval expiration notification
									else if (engagementItemData.applicationStatus.toLocaleLowerCase() === "accepted" && engagementItemData.orderStatus.toLocaleLowerCase() === "notstarted" && dateDiff < 8) {
										showExpirationNotification(customerData, dateDiff, "dashboard", VANEngagementId);
										return;
									}
									// showModal for unmanned store associate review call assistance details
									else if (!$scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
										$scope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
										showAssociateReviewModal(VANEngagementId, "appAssociateReviewUnmanned");
										return;
									}
									// showModal for manned store to find associate to review application
									else if ($scope.storeInfo.isManned && engagementItemData.applicationStatus.toLocaleLowerCase() === "assocrvw") {
										showAssociateReviewModal(VANEngagementId, "appAssociateReviewManned");
										return;
									}
									else {
										//Story 29310 Changes Start
										//navigateTo('dashboard/' + engagementItemData.VANEngagementId);
										if (customerData.overallApprovalStatus == 'DECLINE') {
											var appDeclinedModal = modalService.open({
												title: 'Application Declined',
												templateUrl: 'partials/alert_applicationDeclined.tpl.html',
												backdrop: 'static',
												scope: $scope
											});
											appDeclinedModal.result
												.then(function () {
													$location.path('dashboard/' + VANEngagementId);
												});
										} else {
											navigateTo('dashboard/' + VANEngagementId);
										}//Story 29310 Changes End
									}
								}, function (response) {
									// error
									void 0;
								});
							});
						}
						return;
					}

					if (hasEngagement) {
						handleEngagement(customerData);
					} else {
						createNewEngagement(customerData);
					}

				} else {
//					$rootScope.kountSessionId = null;//VAN-3128-KOUNT implementation VA/AWP
					modalService.dismiss();
					var person = {
						firstName: customerData.firstName,
						lastName: customerData.lastName,
						phone: customerData.phones[0].areaCode + customerData.phones[0].number,
						ssn: customerData.social
					};
					var personData = window.btoa(JSON.stringify(person));
					$location.path('getmissinginfo/').search({ data: personData });
				}


			});

		}

		function getApplicationFail(response, personData) {
			void 0;
			// Customer with SSN entered
			if (response.status === 303) {
				provideSSN();
			}
			
			if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
				showSameDayAgreementModal();
			}
			// should remove the 500 check once session manager is fixed
			if (response.status === 404 || response.status === 500) {
				if (response.data.errorData.status_details === 'No Customers Match Search Criteria') {
					modalService.dismiss();
					$scope.newCustomer = true;
					// console.log($scope.customerInfoModal);
					if (createOrder) {
						createNewEngagement($scope.customerInfoModal, function (engagementData) {
							SharedDataSvc.globalVars.engagementId = engagementData.VANEngagementId; //story 26289 code changes reviewed by Theenmathi
							//Story 33452 changes starts
							var person = {
									firstName: $scope.customerInfoModal.firstName,
									lastName: $scope.customerInfoModal.lastName,
									phone: $scope.customerInfoModal.primaryPhone,
									disableHT: true
								};
							applicationService.getApplication(person)
							.then(function(response){
								SharedDataSvc.globalVars.order.currentEngagement = response;
								if(response.VanEngagement && response.VanEngagement.applicationStatus == 'Accepted'){
									$scope.custCreateData = person;
									$scope.custIdNotAvail = modalService.open({
										scope: $scope,
										templateUrl: 'partials/alert_custHtIdNotAvailable.tpl.html',
										backdrop: 'static'
									});
								} else {
									navigateTo('ordersummary/' + engagementData.VANEngagementId);
								}
							},function(response){
								void 0;
							});
							//Story 33452 changes ends
//							navigateTo('ordersummary/' + engagementData.VANEngagementId);
						});
						return;
					}

					if($scope.approvalByCatList.length > 1 && $scope.storeInfo.isProductTypeSelection){
						showModal("selectCategoryApproval");
					}else{
						$scope.isApprovalByCatSelected = true;
						createNewEngagement($scope.customerInfoModal);
					}

					
				}
				if (response.data.errorData.status_details === "SSN Supplied Not Found") {
					$scope.ssnInvalid = true;
					provideSSN();
				}
			}
		}

		function updateAutoFields(){
			if($scope.custAdditionalInfo.auto.noCar == true){
				$scope.custAdditionalInfo.auto.make = "";
				$scope.custAdditionalInfo.auto.model = "";
				$scope.custAdditionalInfo.auto.tag = "";
				$scope.custAdditionalInfo.auto.color = "";
				$scope.custAdditionalInfo.auto.amount = "";
			}
		}

		function updateApprovalByCatList(){
			for(var z=0; z < $scope.approvalByCatList.length ; z++){
				var count = false;
					if($scope.approvalByCatList[z].isSelected == true){
						count = true;
						break;
					}	
			}
			if(count){
				$scope.disableCatContinue = false;
			}else{
				$scope.disableCatContinue = true;
			}

		}

		function formatCatApprovalList(){
			for(var z=0; z < $scope.approvalByCatList.length ; z++){
					if($scope.approvalByCatList[z].isSelected == true){
						$scope.approvalByCatList[z].isSelected = true;
					}else{
						$scope.approvalByCatList[z].isSelected = false;
					}
			}

			$scope.customerInfo.approvalByCatList = $scope.approvalByCatList;
		}

		function newEngagementWithCat(){
			//formatCatApprovalList();
			$scope.isApprovalByCatSelected = true;
			createNewEngagement($scope.customerInfoModal);

		}
		
		//Story 33452 changes starts
		function applicationCheck(){
			modalService.dismiss();
			var customerData = $scope.custCreateData;
			customerData.phone1 = {};
			customerData.phone1.number = $scope.custCreateData.phone;
			fillInitalApplicationData(customerData);
		}
		
		function fillInitalApplicationData(custData){
			$scope.customerInfo.firstName = custData.firstName;
			$scope.customerInfo.lastName = custData.lastName;
			$scope.customerInfo.phone1 = {};
			$scope.customerInfo.phone1.number = custData.phone1.number;
			$scope.customerInfo.phone1.phoneType = $scope.customerInfo.primaryPhoneType;
			if($scope.customerInfo.emailAddress){
				$scope.customerInfo.email=$scope.customerInfo.emailAddress;
			}
			$scope.verifyEmailInOrder = true;
			SharedDataSvc.customerInfo = $scope.customerInfo;
		}
		//Story 33452 changes ends

		function handleEngagement(customerData) {
			// application | order | agreements | payment | delivery
			var engagementOrderContent;
			var engagement = customerData.VanEngagement;
			var engagementStatus = [];
			var statusDictionary = {
				null: 0,
				'NotStarted': 0,
				'Pending': 0,
				'InProgress': 0,
				'Accepted': 1,
				'Confirmed': 1,
				'Complete': 1,
				'Declined': 2
			};

			engagementStatus = [
				statusDictionary[engagement.applicationStatus],
				statusDictionary[engagement.orderStatus],
				statusDictionary[engagement.agreementStatus],
				statusDictionary[engagement.paymentStatus],
				statusDictionary[engagement.deliveryStatus]
			];
			engagementStatus = engagementStatus.join('');

			if (engagement.orderContent && engagement.orderContent.length > 0) {
				engagementOrderContent = JSON.parse(engagement.orderContent);
				_.forEach(engagementOrderContent.orderItems, function (orderItem) {
					existingOrderTotal += orderItem.itemPrice;
				});
				void 0;
			}


			if (engagementStatus = '00000') {
				modalService.dismiss();
				SharedDataSvc.globalVars.order.currentEngagement = customerData;
				$rootScope.$broadcast('applicationDataUpdated', customerData);
				return;
			}
			$location.path('dashboard');
			/* Code for handling redirection after re-engagment
			switch (engagementStatus) {
				// Application not complete
				case '00000':
					modalService.dismiss();
					SharedDataSvc.globalVars.order.currentEngagement = response.data;
					$rootScope.$broadcast('applicationDataUpdated', response.data);
					break;
				// Application complete & order not complete
				case '10000':
					$location.path('/ordersummary/'+engagement.engagementId);
					break;
				// Application & Order Complete
				case '11000':
					$location.path('/agreement/'+engagement.engagementId);
					break;
				// App, Order & Agreements Complete
				case '11100':
					$location.path('/epay/'+engagement.engagementId);
				default:
					break;
			}
			// Application,
			*/
		}

		function showProcessingModal(title) {
			$scope.viewLoading = true;
			$scope.modalTitle = title || 'Processing';
			$scope.processingModal = modalService.open({
				scope: $scope,
				windowClass: 'processing-modal',
				templateUrl: 'partials/modal_Processing.html',
				size: 'sm',
				backdrop: 'static'
			});
		}

		function hideProcessingModal() {
			$scope.processingModal.dismiss();
			$scope.modalTitle = undefined;
			$scope.viewLoading = false;
		}

		function createFullEngagement(customerData, callback) {
			void 0;

			callback = callback || function () { };
			var fullCreateData = {};
			var parameters = [
				'firstName',
				'lastName',
				'middleInitial',
				'email',
				'itinFlag',
				'social',
				'languagePreference',
				'customerType',
				'address',
				'driversLicense',
				'incomeSources',
				'personalReferences'
			];
			// Copy all the properties listed above
			for (var i = 0; i < parameters.length; i++) {
				fullCreateData[parameters[i]] = _.clone(customerData[parameters[i]], true);
			}
			// remove address type, in case it's been entered before somehow
			for (var i = 0; i < fullCreateData.address.length; i++) {
				delete fullCreateData.address[i].addressType;
			}
			fullCreateData.phones = [];
			for (var i = 0; i < customerData.phones.length; i++) {
				if (customerData.phones[i].areaCode !== '000') {
					fullCreateData.phones[i] = _.clone(customerData.phones[i], true);
				}
				// console.log(fullCreateData.phones[i].areaCode);
			}

			if (fullCreateData.phones) {
				if (fullCreateData.phones[0]) {
					fullCreateData.phones[0].phoneType = fullCreateData.phones[0].phoneType || null;
				}

				if (fullCreateData.phones[1]) {
					fullCreateData.phones[1].phoneType = fullCreateData.phones[1].phoneType || null;
				}
				//VAN-849 - AWP- Not able to reengage customer created in HT (store A)to different store(storeB ) in AWP -- Start
				if (fullCreateData.phones[2]) {
					fullCreateData.phones[2].phoneType = fullCreateData.phones[2].phoneType || null;
				}
				//VAN-849 - AWP- Not able to reengage customer created in HT (store A)to different store(storeB ) in AWP -- End
			}


			fullCreateData.primaryPhoneNumber = fullCreateData.phones[0].areaCode + fullCreateData.phones[0].number;
			fullCreateData.customerId = customerData.VANCustomerID;
			fullCreateData.orderOriginator = "AWP";
			//fullCreateData.suppressDbCreate="true;
			if (customerData.personalReferences && customerData.personalReferences.length > 0) {
				for (var i = 0; i < customerData.personalReferences.length; i++) {
					fullCreateData.personalReferences[i].firstName = customerData.personalReferences[i].firstName === '' ? null : customerData.personalReferences[i].firstName;
					fullCreateData.personalReferences[i].middleInitial = customerData.personalReferences[i].middleInitial === '' ? null : customerData.personalReferences[i].middleInitial;
					fullCreateData.personalReferences[i].lastName = customerData.personalReferences[i].lastName === '' ? null : customerData.personalReferences[i].lastName;
					fullCreateData.personalReferences[i].personalReferenceType = customerData.personalReferences[i].personalReferenceType === '' ? null : customerData.personalReferences[i].personalReferenceType;
					fullCreateData.personalReferences[i].phone = customerData.personalReferences[i].phone.areaCode + customerData.personalReferences[i].phone.number === '' ? null : customerData.personalReferences[i].phone;
				}
			}

			void 0;

			getSpecificApplication.searchHT(fullCreateData)
				.then(
				callback,
				function (response) {
					void 0;
					if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
						showSameDayAgreementModal();
					}
				}
				);
		}

		function createNewEngagement(customerData, callback) {
			var customerPhone;
			void 0;

			/* This script below will populate the application page fields for
			 * save drafted customer re-engaging on other store.
			  It reaches here because
			 * on another store there is no engagement present for it.
			 */
			//PRB0042346 - Application stuck inprocess due to different phone number - Starts
			if($rootScope.applicationLink == true && customerData.firstName == undefined){
				void 0;
				var wrongvendor = modalService.open({
					show: true,
					templateUrl: 'partials/alert_openNewexistingCustomer.tpl.html',
					backdrop: 'static'         
				});    
				navigateTo('dashboard');
			}
			else {//PRB0042346 - Application stuck inprocess due to different phone number - Ends
				$rootScope.$broadcast('applicationDataUpdated', customerData);

				if(!$scope.isSpanishStore){
					fetchAndValidateZipCode(customerData);
				}
				// out of radius check
				if (!$scope.customerInRadius && $scope.isZipValid) {
					outOfRadiusModal(customerData);
					return;
				}

				showProcessingModal();
				if (_.isArray(customerData.phones)) {
					customerPhone = customerData.phones[0];
				} else {
					customerPhone = {
						areaCode: customerData.primaryPhone.substr(0, 3),
						number: customerData.primaryPhone.substr(3, 10),
						phoneType: customerData.primaryPhoneType || null
					};
				}

				if (customerData.social) {
					var createData = {
						phones: [customerPhone],
						firstName: customerData.firstName,
						lastName: customerData.lastName,
						middleInitial: customerData.middleInitial,
						email: customerData.email || customerData.emailAddress,
						primaryPhoneNumber: customerPhone.areaCode + customerPhone.number,
						languagePreference: "ENGLISH",
						orderOriginator: "AWP",
						social: customerData.social

					};

				} else {
					var createData = {
						phones: [customerPhone],
						firstName: customerData.firstName,
						lastName: customerData.lastName,
						middleInitial: customerData.middleInitial,
						email: customerData.email || customerData.emailAddress,
						primaryPhoneNumber: customerPhone.areaCode + customerPhone.number,
						languagePreference: "ENGLISH",
						orderOriginator: "AWP",
						isConsentAgreed : $scope.isConsentResponse == "null" ? true : "",//VAN-510 - Electronic Consent moved to app flow 	
						isTcpaConstentFlg : $scope.isConsentResponse == "null" ? $scope.personData.istcpaflag : "" //VAN 4119-Update the disclaimer in AWP
					};
				}



				callback = callback || function (data) {
					modalService.dismiss();
					void 0;
					$scope.customerId = data.customerId;
					SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID = data.customerId;
					SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId = data.VANEngagementId;

					$scope.customerInfo.firstName = createData.firstName;
					$scope.customerInfo.middleName = createData.middleInitial;
					$scope.customerInfo.lastName = createData.lastName;
					$scope.customerInfo.email = createData.email;
					$scope.customerInfo.emailVerify = createData.email;//Story 30445 -AWP, Kiosk: Add email verification edit box
					$scope.customerInfo.phone1 = {
						number: createData.primaryPhoneNumber,
						phoneType: createData.phones[0].phoneType || null
					};
					if (createData.phones[0].phoneType === "HOME" && $scope.allowTextVerificationFlag) {
						$scope.customerInfo.phone2 = {
							phoneType: "CELL"
						}
					}
					/*
					if ($scope.storeInfo.achVerification) {
						var customerInfo = {
							"customerId": data.customerId,
							"engagementId": data.VANEngagementId,
							"customerFirstName": $scope.customerInfo.firstName,
							"customerLastName": $scope.customerInfo.lastName,
							"customerPrimaryPhone": createData.primaryPhoneNumber
						}
						provideBankingInfoForACH(customerInfo);
					}*/

				};

				getSpecificApplication.searchHT(createData)
					.then(
					callback,
					function (data) {
						void 0;
					}
					);
			}//PRB0042346 - Application stuck inprocess due to different phone number
		}

		function cancelCustomerEntry() {
			var cancelCustSSNModal;
			// open modal only once
			cancelCustSSNModal = modalService.open({
				title: 'Existing Customer Verification',
				templateUrl: 'partials/confirm_discardAppChanges.tpl.html',
				backdrop: 'static',
				scope: $scope
			});
		}

		function updateCoIncome(){
			if($scope.custAdditionalInfo.noCocustomer){
				$scope.custAdditionalInfo.noCocustomerIncome = true;
				$scope.coCustomerInfo.firstName = "";
				$scope.coCustomerInfo.lastName = "";
				$scope.coCustomerInfo.middleName =  "";
				$scope.coCustomerInfo.relation = "";
				$scope.coCustomerInfo.phone1.number = ""; 
				$scope.coCustomerInfo.phone1.phoneType = "";	
				$scope.coCustomerInfo.driversLicense.id = "";
				$scope.coCustomerInfo.driversLicense.issuer = "";
				$scope.coCustomerInfo.driversLicense.idType = "";
				$scope.coCustomerInfo.incomeSources.income = "";
			}else{
				$scope.custAdditionalInfo.noCocustomerIncome = false;
			}
		}

		function updateCocustomerIncome() {
			if($scope.custAdditionalInfo.noCocustomerIncome){
				$scope.coCustomerInfo.incomeSources.income = "";
			}
		}

		function saveCustomerInfo() {
			var deferred = $q.defer();

			modalService.processing('Saving Application');

			var employerAreaCode = '000',
				employerPhone = '0000000',
				monthlyIncome = '',
				incomeSourceName = '',
				bestCallTime = '',
				customerId = SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID || SharedDataSvc.globalVars.order.currentEngagement.customerId,
				engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || $routeParams.engagementId,
				ccEmployerAreaCode = '000',
				ccEmployerPhone = '0000000',
				ccMonthlyIncome = '',
				ccIncomeSourceName = '',
				cctakeHomePay = '';

			SharedDataSvc.customerInfo = $scope.customerInfo;
			SharedDataSvc.customerPrivate = $scope.customerPrivate;

			if (typeof $scope.customerInfo.incomeSources.sourcePhone !== "undefined") {
				employerAreaCode = $scope.customerInfo.incomeSources.sourcePhone.number.substr(0, 3);
				employerPhone = $scope.customerInfo.incomeSources.sourcePhone.number.substr(3, 10);
			}

			

			if ($scope.customerInfo.incomeSources.income) {
				switch ($scope.customerInfo.incomeSources.income) {
					case 'EMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					case 'SELFEMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					default:
						incomeSourceName = $scope.customerInfo.incomeSources.income;
				}
			}

		


			if ($scope.customerInfo.incomeSources.monthlyIncome) {
				monthlyIncome = $scope.customerInfo.incomeSources.monthlyIncome.replace('$', '');
				void 0;
			}


			if ($scope.coCustomerInfo.incomeSources.takeHomePay) {
				cctakeHomePay = $scope.coCustomerInfo.incomeSources.takeHomePay.replace('$', '');
				void 0;
			}

			/*if (_($scope.customerInfo.phone1).has('bestCallTime')) {
				if ($scope.customerInfo.phone1.bestCallTime.Morning === 'Yes') {
					bestCallTime += 'M';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Afternoon === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'A';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Evening === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'E';
				}
			}*/

			var incType = $scope.customerInfo.incomeSources.income;

			
			// Check if listed keys exist in form; for saving form properly
			if(!$scope.custAdditionalInfo.noCocustomer){
				if ($scope.coCustomerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.coCustomerPrivate.ssn))) {
					var SSN = $scope.coCustomerPrivate.ssn;
					if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4 ) {
						var ccSocial = $scope.coCustomerPrivate.ssn.ssn1 + $scope.coCustomerPrivate.ssn.ssn2 + $scope.coCustomerPrivate.ssn.ssn3;
					}
				}
			}else{
				var ccSocial = "";
			}
			

				var mortgageName,mortgagePhone,avgUtilities,leaseEnd, leaseTypeRent,apartmentComplex, makeandmodel,custPayment,homeType;
				if($scope.isSpanishStore){
						var currYear = (new Date()).getFullYear();
									// co customer income information number
						if (typeof $scope.coCustomerInfo.incomeSources.sourcePhone !== "undefined") {
							ccEmployerAreaCode = $scope.coCustomerInfo.incomeSources.sourcePhone.number.substr(0, 3);
							ccEmployerPhone = $scope.coCustomerInfo.incomeSources.sourcePhone.number.substr(3, 10);
						}

						// co customer income information income type
						if ($scope.coCustomerInfo.incomeSources.income) {
							switch ($scope.coCustomerInfo.incomeSources.income) {
								case 'EMPLOYED':
									ccIncomeSourceName = $scope.coCustomerInfo.incomeSources.sourceName || '';
									break;
								case 'SELFEMPLOYED':
									ccIncomeSourceName = $scope.coCustomerInfo.incomeSources.sourceName || '';
									break;
								default:
									ccIncomeSourceName = $scope.coCustomerInfo.incomeSources.income;
							}
						}

						if($scope.customerInfo.address != undefined){
							homeType = $scope.customerInfo.address.homeType;
						}

						if(!$scope.custAdditionalInfo.noCocustomer){
							if ($scope.coCustomerInfo.dob && _.every(['month', 'day', 'year'], _.partial(_.has, $scope.coCustomerInfo.dob))) {
								var CCdob = $scope.coCustomerInfo.dob.year + $scope.coCustomerInfo.dob.month + $scope.coCustomerInfo.dob.day;
							}
						}else{
							var CCdob = "";
						}
						

						if ($scope.coCustomerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.coCustomerInfo.incomeSources.nextPayDate))) {
							var CCnextPayDate =  currYear + $scope.coCustomerInfo.incomeSources.nextPayDate.month + $scope.coCustomerInfo.incomeSources.nextPayDate.day;
						}

						if ($scope.customerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.customerInfo.incomeSources.nextPayDate))) {
							var nextPayDate = currYear +  $scope.customerInfo.incomeSources.nextPayDate.month + $scope.customerInfo.incomeSources.nextPayDate.day;
						}

						var ccMoveInDate =  $scope.custAdditionalInfo.moveInDate.year + $scope.custAdditionalInfo.moveInDate.month + "01" ;

					if($scope.custAdditionalInfo.leaseEndDate.month && $scope.custAdditionalInfo.leaseEndDate.year){
						leaseEnd =  $scope.custAdditionalInfo.leaseEndDate.year + $scope.custAdditionalInfo.leaseEndDate.month + "01";
					}

					if($scope.custAdditionalInfo.auto.make && $scope.custAdditionalInfo.auto.model){
						makeandmodel = $scope.custAdditionalInfo.auto.make + "-" + $scope.custAdditionalInfo.auto.model;
					}else{
						makeandmodel = "";
					}
					

					if($scope.customerInfo.address){
						if($scope.customerInfo.address.homeType === 'RENT'){
							leaseTypeRent = $scope.custAdditionalInfo.rent.leaseTypeRent;
							apartmentComplex = $scope.custAdditionalInfo.rent.apmtComplex;

							mortgageName = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyRent;
							mortgagePhone = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumRent;
							avgUtilities = $scope.custAdditionalInfo.ownRentInfo.avgUtilitiesRent;
							custPayment = $scope.custAdditionalInfo.ownRentInfo.custPaymentAmountRent;
						}

						if($scope.customerInfo.address.homeType === 'OWN'){
							mortgageName = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyOwn;
							mortgagePhone = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumOwn;
							avgUtilities = $scope.custAdditionalInfo.ownRentInfo.avgUtilitiesOwn;
							custPayment = $scope.custAdditionalInfo.ownRentInfo.custPaymentAmountOwn;
							
						}
					}
					
			}
			

			var mData = {
				lastName: $scope.customerInfo.lastName,
				firstName: $scope.customerInfo.firstName,
				middleInitial: $scope.customerInfo.middleName,
				customerId: customerId,
				engagementId: engagementId,
				email: $scope.customerInfo.email,
				orderOriginator: "AWP",
				referer: "",
				//residenceType: $scope.customerInfo.address.homeType,
				residenceType: "OWN",
				primaryPhoneNumber: $scope.customerInfo.phone1.number,
				phones: [{
					areaCode: $scope.customerInfo.phone1.number.substr(0, 3),
					number: $scope.customerInfo.phone1.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone1.phoneType,
					bestCallTime: bestCallTime,
					contactName: '',
					phoneSet: "CUSTPHN1"
				}],
				languagePreference: "ENGLISH",
				address: [{
					addrLine1: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.addrLine1 : "",
					//Changes added for AC13229
					addrLine2: "",
					unit: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.addrLine2 : "",
					city: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.city : "",
					state: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.state : "",
					zipCode: $scope.customerInfo.address !== undefined ? $scope.customerInfo.address.zipCode : "",			
					

				}],
				driversLicense: {
					id: $scope.customerInfo.driversLicense !== undefined ? $scope.customerInfo.driversLicense.id : "",
					issuer: $scope.customerInfo.driversLicense !== undefined && ($scope.customerInfo.driversLicense.idType == 1 || $scope.customerInfo.driversLicense.idType == 2) ? $scope.customerInfo.driversLicense.issuer : "",
					idType: $scope.customerInfo.driversLicense !== undefined ? $scope.customerInfo.driversLicense.idType : "1"
				},
				incomeSources: [{
					incomeSourceSet: "INCSRC1",
					sourcePhone: {
						phoneType: 'HOME',
						areaCode: employerAreaCode,
						number: employerPhone,
						extension: '',
						bestCallTime: 'xxx'
					},
					sourceStartDate: '',
					incomeType: incType,
					sourceName: incomeSourceName,
					monthlyIncome: monthlyIncome,
					sourceAddress : {
						addrLine1 : $scope.customerInfo.incomeSources.employerAddress
					},
					paydayFrequency : $scope.customerInfo.incomeSources.payFrequency,
					sourceDepartment : $scope.customerInfo.incomeSources.deptTitle,
					workHours : $scope.customerInfo.incomeSources.workHours,
					supervisorName :$scope.customerInfo.incomeSources.supervisorName,
					bestPayDate : $scope.customerInfo.incomeSources.bestPayDate,
					nextPayday : nextPayDate !== undefined ? nextPayDate : ""
				}]		
			};

			$scope.customerName = mData.firstName + ' ' + mData.lastName;

		 	if($scope.isSpanishStore){
		 		mData.customerType = "REFERENCE";
		 		mData.additionalInfo = {
		 			isCoCustomerIncomeSourceProvided : $scope.custAdditionalInfo.noCocustomerIncome !== undefined ? !$scope.custAdditionalInfo.noCocustomerIncome : "",
					previousAddress : [{
						addrLine1 : $scope.custAdditionalInfo.previousAddress.addrLine1 !== undefined ? $scope.custAdditionalInfo.previousAddress.addrLine1 : "",
						landLordName : $scope.custAdditionalInfo.previousAddress.mortgageName !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgageName : "",
						landLordNumber : $scope.custAdditionalInfo.previousAddress.mortgagePhone !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgagePhone : ""
					}],
					autoMakeAndModel : makeandmodel !== undefined ? makeandmodel :"",
					tag : $scope.custAdditionalInfo.auto.tag !== undefined ? $scope.custAdditionalInfo.auto.tag : "",
					color : $scope.custAdditionalInfo.auto.color !== undefined ? $scope.custAdditionalInfo.auto.color : "",
					carPaymentAmount : $scope.custAdditionalInfo.auto.amount !== undefined ? $scope.custAdditionalInfo.auto.amount : "",
					averageMonthlyUtilities : avgUtilities !== undefined ? avgUtilities :"",
					isCarDetailsProvided: !$scope.custAdditionalInfo.auto.noCar,
					customerPaymentAmount : custPayment !== undefined ? custPayment : "",
					
				}

				mData.coCustomerInfo = {
					firstName : $scope.coCustomerInfo.firstName !== undefined ? $scope.coCustomerInfo.firstName : "",
					lastName : $scope.coCustomerInfo.lastName !== undefined ? $scope.coCustomerInfo.lastName : "",
					//middleInitial : $scope.coCustomerInfo.middleName !== undefined ? $scope.coCustomerInfo.middleName: "",
					birthDate : CCdob !== undefined ? CCdob : "",
					//custInfoType : 'NAME2',
					relationshipType : $scope.coCustomerInfo.relation !== undefined ? $scope.coCustomerInfo.relation: "",
					phoneNumber : $scope.coCustomerInfo.phone1.number !== undefined ? $scope.coCustomerInfo.phone1.number : "", 
					phoneType : $scope.coCustomerInfo.phone1.phoneType !== undefined ? $scope.coCustomerInfo.phone1.phoneType : "",
					//social : ccSocial
					//driversLicense: {
						//id: $scope.coCustomerInfo.driversLicense !== undefined ? $scope.coCustomerInfo.driversLicense.id : "",
						//issuer: $scope.coCustomerInfo.driversLicense !== undefined ? $scope.coCustomerInfo.driversLicense.issuer : "",
						//idType: $scope.coCustomerInfo.driversLicense !== undefined ? $scope.coCustomerInfo.driversLicense.idType : "1"
					//}
				}

				if($scope.customerInfo){
				 	if($scope.customerInfo.coCustomerInfo) {
						if(!$scope.customerInfo.coCustomerInfo.social){
							mData.coCustomerInfo.social = ccSocial;
						} else if($scope.customerInfo.coCustomerInfo.social !== $scope.coCustomerPrivate.ssn.ssn3) {
							mData.coCustomerInfo.social = ccSocial;
						}
					} else {
						mData.coCustomerInfo.social = ccSocial;
					}
				}
				

				mData.isCoCustomerInfoProvided = $scope.custAdditionalInfo.noCocustomer !== undefined ? !$scope.custAdditionalInfo.noCocustomer : "";
				
		 		
		 		mData.address[0].leaseType =  leaseTypeRent !== undefined ? leaseTypeRent : "" ;
				mData.address[0].landLordName =  mortgageName !== undefined ? mortgageName : "";
				mData.address[0].landLordNumber =  mortgagePhone !== undefined ? mortgagePhone : "";
				mData.address[0].apartmentComplex =  apartmentComplex !== undefined ? apartmentComplex :"";
				mData.address[0].moveInDate =  ccMoveInDate;
				mData.address[0].leaseEndDate =  leaseEnd !== undefined ? leaseEnd : "";
				mData.address[0].isRenewingLease =  homeType !== undefined ? homeType: "";
				mData.address[0].addressType = "HOME";
		 		//mData.additionalInfo.customerPaymentAmount = $scope.customerInfo.ownRentInfo.custPaymentAmount !== undefined ? $scope.customerInfo.ownRentInfo.custPaymentAmount : "";
		 	
		 		if(!$scope.custAdditionalInfo.noCocustomerIncome && $scope.coCustomerInfo.incomeSources.income){
		 			mData.additionalInfo.coCustomerIncomeSource = [{
		 				incomeType : $scope.coCustomerInfo.incomeSources.income,
						sourceName : ccIncomeSourceName,
						monthlyIncome : cctakeHomePay !== undefined ? cctakeHomePay : "",
						paydayFrequency :$scope.coCustomerInfo.incomeSources.payFrequency !== undefined ? $scope.coCustomerInfo.incomeSources.payFrequency: "",
						sourcePhone : null,
						sourceAddress : null,
						sourceDepartment : null,
						supervisorName : null,
						workHours : null,
						nextPayday : null,
						sourceStartDate : ''
		 			}];
		 		}
		 		if($scope.coCustomerInfo.incomeSources.income === $scope.incomeEnums[1].type && !$scope.custAdditionalInfo.noCocustomerIncome){
						
						if($scope.coCustomerInfo.incomeSources && $scope.coCustomerInfo.incomeSources.length > 0){
							$scope.coCustomerInfo.incomeSources[0].sourceStartDate = '';
						} else {
							$scope.coCustomerInfo.incomeSources = [];
							$scope.coCustomerInfo.incomeSources[0] = {
								sourceStartDate : ''
							};
						}
						
						mData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate = '';
				} 
				if (!$scope.custAdditionalInfo.noCocustomerIncome && $scope.coCustomerInfo.incomeSources.sourceStartDate !== '') {
						mData.additionalInfo.coCustomerIncomeSource = [{
							sourcePhone: {
								phoneType: 'HOME',
								areaCode: ccEmployerAreaCode,
								number: ccEmployerPhone,
									extension: '',
									bestCallTime: 'xxx'
							},
							sourceAddress : {
								addrLine1 : $scope.coCustomerInfo.incomeSources.employerAddress !== undefined ? $scope.coCustomerInfo.incomeSources.employerAddress : "",
							},
							incomeType : $scope.coCustomerInfo.incomeSources.income,
							sourceName : ccIncomeSourceName,
							monthlyIncome : cctakeHomePay !== undefined ? cctakeHomePay : "",
							sourceDepartment : $scope.coCustomerInfo.incomeSources.deptTitle !== undefined ? $scope.coCustomerInfo.incomeSources.deptTitle: "",
							supervisorName : $scope.coCustomerInfo.incomeSources.supervisorName !== undefined ? $scope.coCustomerInfo.incomeSources.supervisorName : "",
							workHours : $scope.customerInfo.incomeSources.workHours !== undefined ? $scope.customerInfo.incomeSources.workHours: ""	,
							nextPayday : CCnextPayDate	!== undefined ? CCnextPayDate : "",
							paydayFrequency :$scope.coCustomerInfo.incomeSources.payFrequency !== undefined ? $scope.coCustomerInfo.incomeSources.payFrequency: "",
							sourceStartDate : moment().subtract('months', $scope.customerInfo.incomeSources.sourceStartDate).format("YYYYMMDD")
						}];
				}

		 	}

			// Check if listed keys exist in form; for saving form properly
			if ($scope.customerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.customerPrivate.ssn))) {
				var SSN = $scope.customerPrivate.ssn;
				if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4) {
					mData.social = $scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3;
				}
			}

			if ($scope.customerPrivate.ssn.ssnType == 'ITIN') {
				mData.itinFlag = true;		
				if ($scope.customerPrivate.itin && _.every(['itin1', 'itin2', 'itin3'], _.partial(_.has, $scope.customerPrivate.itin))) {
					var itin = $scope.customerPrivate.itin;
					if (itin.itin1.length === 3 && itin.itin2.length === 2 && itin.itin3.length === 4) {
						mData.social = $scope.customerPrivate.itin.itin1 + $scope.customerPrivate.itin.itin2 + $scope.customerPrivate.itin.itin3;
					}
				}		
			} else {
				mData.itinFlag = false;
			}

			if ($scope.customerPrivate.dob && _.every(['month', 'day', 'year'], _.partial(_.has, $scope.customerPrivate.dob))) {
				mData.driversLicense.birthDate = $scope.customerPrivate.dob.year + $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day;
			}
			
			//if($scope.storeInfo.isReferenceRequired === true) {
				if($scope.isSpanishStore){
					var referenceCount = 4;
				}else{
					var referenceCount = 3;
				}
			//}

			var referencesArray = [];
			for (var i = 0; i < referenceCount; i++) {
				var formReference = $scope['ref' + (i + 1)],reference,set;
				if(formReference){
						if(i == 3 && !formReference.number){
						formReference.number = $scope.customerInfoForm.newRefs.ref4Number.$modelValue;
					}
					//refNum = $scope.refPhones[i].number;
					set = "PERREF" + (i + 1);
					if (_(formReference).has('personalReferenceType') && formReference.personalReferenceType !== '') {
						reference = {
							lastName: formReference.lastName,
							firstName: formReference.firstName,
							personalReferenceType: formReference.personalReferenceType,
							address: [{
								addrLine1: formReference.address,
								addressType: "HOME"
							}],
							personalReferenceSet : set,
							phone: {
								areaCode: formReference.number.substr(0, 3),
								number: formReference.number.substr(3, 10),
								//areaCode: refNum.substr(0,3),
								//number : refNum.substr(3,10),
								phoneType: formReference.phoneType,
								bestCallTime: ''
							}
						};
						referencesArray.push(reference);
					}
				}
			}
			mData.personalReferences = referencesArray;

			if (_($scope.customerInfo).has('phone2')
				&& _($scope.customerInfo.phone2).has('number')
				&& $scope.customerInfo.phone2.number.length > 9) {
				var phone2 = {
					areaCode: $scope.customerInfo.phone2.number.substr(0, 3),
					number: $scope.customerInfo.phone2.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone2.phoneType,
					contactName: '',
					phoneSet: "CUSTPHN2"
				};
				mData.phones.push(phone2);
			}

			if($scope.customerInfo.incomeSources.income == $scope.incomeEnums[1].type){
				$scope.customerInfo.incomeSources.sourceStartDate =  '';
				mData.incomeSources[0].sourceStartDate = '';
			} 
			if ($scope.customerInfo.incomeSources.sourceStartDate !== '') {
				mData.incomeSources[0].sourceStartDate = moment().subtract('months', $scope.customerInfo.incomeSources.sourceStartDate).format("YYYYMMDD");
			} 

			
			void 0;

			// save to /customer
			applicationService.saveApplication(mData)
				.then(
				function (response) {
					deferred.resolve('success');
				},
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					if (error.data.errorData.status_message === 'SSN invalid') {
						modalService.error(error.data.errorData.status_message);
					}
					deferred.reject(error);
				}
				);

			return deferred.promise;
		}

		function processCustomerApp() {
			showProcessingModal('Processing Application');
			SharedDataSvc.hasPAmodalRun = true;
			var incomeSources;
			//generateKeys();

			if($scope.storeInfo.isReferenceRequired !== false) {
				// re-map referenceInfo
				// ref1, ref2, ref3
				var custRefsScope = angular.element(document.getElementById('newCustReferences')).scope();
				var phone1ex1 = custRefsScope.ref1.number.replace(/\D+/g, "");
				var phone2ex1 = custRefsScope.ref2.number.replace(/\D+/g, "");
				var phone3ex1 = custRefsScope.ref3.number.replace(/\D+/g, "");

				$scope.customerInfo.personalReferences = [{
					firstName: custRefsScope.ref1.firstName, //"John",
					lastName: custRefsScope.ref1.lastName, //"Smith",
					phone: phone1ex1, //"513-123-5432",
					relationship: custRefsScope.ref1.personalReferenceType //"PARENT"
				}, {
					firstName: custRefsScope.ref2.firstName, //"John",
					lastName: custRefsScope.ref2.lastName, //"Smith",
					phone: phone2ex1, //"513-123-5432",
					relationship: custRefsScope.ref2.personalReferenceType //"PARENT"
				}, {
					firstName: custRefsScope.ref3.firstName, //"John",
					lastName: custRefsScope.ref3.lastName, //"Smith",
					phone: phone3ex1, //"513-123-5432",
					relationship: custRefsScope.ref3.personalReferenceType //"PARENT"
				}];
			}

			//re-map incomeSources
			var newCustEmpInfoScope = angular.element(document.getElementById('iSourcesNew')).scope();

			var incomeSourceNumber = $scope.customerInfo.incomeSources.sourcePhone ? $scope.customerInfo.incomeSources.sourcePhone.number : "",
				incomeSourceName = $scope.customerInfo.incomeSources.sourceName || "",
				monthlyIncome = $scope.customerInfo.incomeSources.monthlyIncome || 0;

			var incType = $scope.customerInfo.incomeSources.income;
			//AC 26654 code changes
			if ($scope.customerInfo.incomeSources.income) {
				switch ($scope.customerInfo.incomeSources.income) {
					case 'EMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					case 'SELFEMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					default:
						incomeSourceName = '';
						incomeSourceNumber = '';
				}
			}

			var incomeSources = [{
				incomeType: incType,
				employer: incomeSourceName, // "Deloitte",
				employerAddress: "",
				employerCity: "",
				employerState: "",
				employerZip: "",
				employerDept: "",
				monthlySalary: monthlyIncome,
				employerPhone: incomeSourceNumber.replace(/\D+/g, "")
			}];
			void 0;

			// get manned/unmanned status
			var appFlowType = '';
			if (SharedDataSvc.globalVars.storeSetup.isManned === false) {
				appFlowType = 'UNMANNED';
			} else {
				appFlowType = 'MANNED';
			}


			// map all the disparate data into customerApp
			var customerId = SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID || SharedDataSvc.globalVars.order.currentEngagement.customerId;
			var engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || SharedDataSvc.globalVars.order.currentEngagement.engagementId;


			SharedDataSvc.globalVars.ApplicationSave = {
				sessionId: '123456',
				salesResourceId: '000001', // salesResoureceId changed to salesResourceId as per Service team requirement
				customerId: customerId,
				engagementId: engagementId,
				orderOriginator: "AWP",
				approvalFlowType: appFlowType,
				bankingInfo: {
					routingNumber: "",
					accountNumber: ""
				},
				idInfo: {
					idIssuingState: $scope.customerInfo.driversLicense.issuer,
					idNumber: $scope.customerInfo.driversLicense.id,
					idType: $scope.customerInfo.driversLicense.idType,
					//Defect 28124 - AWP - ITIN is not getting passed for AWP 
					//idSSN: $scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3 || 
					//$scope.customerPrivate.itin.itin1 + $scope.customerPrivate.itin.itin2 + $scope.customerPrivate.itin.itin3, 
					idSSN: $scope.customerPrivate.ssn.ssnType == 'SSN'? ($scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3):($scope.customerPrivate.itin.itin1 + $scope.customerPrivate.itin.itin2 + $scope.customerPrivate.itin.itin3),
					//					idDOB: $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day + $scope.customerPrivate.dob.year,
					idDOB: $scope.customerPrivate.dob.year + $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day,
					idLastName: $scope.customerInfo.lastName,
					idFirstName: $scope.customerInfo.firstName,
					// leaving out 'middle name/initial'
					idMiddleName: $scope.customerInfo.middleName,
					idPhone: $scope.customerInfo.phone1.number.replace(/\D+/g, ""),
					//AC24574  Code changes starts
					idPrimaryPhoneType: $scope.customerInfo.phone1.phoneType,
					idSecondaryPhone: $scope.customerInfo.phone2 && $scope.customerInfo.phone2.number ? $scope.customerInfo.phone2.number.replace(/\D+/g, "") : "",
					idSecondaryPhoneType: $scope.customerInfo.phone2 && $scope.customerInfo.phone2.phoneType ? $scope.customerInfo.phone2.phoneType : "",
					//AC24574  Code change ends
					// idPhone: "310-9030945" ,
					idAddress1: $scope.customerInfo.address.addrLine1,
					idAddress2: $scope.customerInfo.address.addrLine2,
					idCity: $scope.customerInfo.address.city,
					idState: $scope.customerInfo.address.state,
					idZip: $scope.customerInfo.address.zipCode.replace(/\D+/g, ""),
					idEmail: $scope.customerInfo.email
				},
				employmentInfo: incomeSources[0],
//				kountSessionId: $rootScope.kountSessionId,//VAN-3128-KOUNT implementation VA/AWP
				ipAddress:$scope.ipaddress//VAN-2844-Capture clients IPAddress for Virtual approvals and AWP
			};

			if($scope.storeInfo.isReferenceRequired !== false) {
				SharedDataSvc.globalVars.ApplicationSave.referenceInfo = [{
					firstName: $scope.customerInfo.personalReferences[0].firstName, //"John",
					lastName: $scope.customerInfo.personalReferences[0].lastName, //"Smith",
					phone: $scope.customerInfo.personalReferences[0].phone, //"513-123-5432",
					relationship: $scope.customerInfo.personalReferences[0].relationship //"PARENT"
				}, {
					firstName: $scope.customerInfo.personalReferences[1].firstName, //"John",
					lastName: $scope.customerInfo.personalReferences[1].lastName, //"Smith",
					phone: $scope.customerInfo.personalReferences[1].phone, //"513-123-5432",
					relationship: $scope.customerInfo.personalReferences[1].relationship
				}, {
					firstName: $scope.customerInfo.personalReferences[2].firstName, //"John",
					lastName: $scope.customerInfo.personalReferences[2].lastName, //"Smith",
					phone: $scope.customerInfo.personalReferences[2].phone, //"513-123-5432",
					relationship: $scope.customerInfo.personalReferences[2].relationship
				}]
			};


			//sending validated ACH info
			/*if ($scope.storeInfo.achVerification && $scope.customerInfoForACH) {
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.routingNumber = $scope.customerInfoForACH.bankingInfo.routingNumber;
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.accountNumber = $scope.customerInfoForACH.bankingInfo.accountNumber;
			}*/

			if($scope.storeInfo.achVerification && $scope.bankingInfoFormForACH) {
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.routingNumber = $scope.bankingInfoData.routingNumber;
				SharedDataSvc.globalVars.ApplicationSave.bankingInfo.accountNumber = $scope.bankingInfoData.accountNumber;
				$scope.challenge.routingNumber = $scope.bankingInfoData.routingNumber;
				$scope.challenge.accountNumber = $scope.bankingInfoData.accountNumber;
			}

			if($scope.isApprovalByCatSelected){
				var catList = []
				
				if($scope.approvalByCatList.length > 1){
					for(var z=0; z < $scope.approvalByCatList.length ; z++){
						var tempObj = {
							"isSelected": $scope.approvalByCatList[z].isSelected,
							"category": $scope.approvalByCatList[z].category
						}
						catList.push(tempObj);	
					}
				}else{
					var tempObj = {
						"isSelected": true,
						"category": $scope.approvalByCatList[0].category
					}
					catList.push(tempObj);
				}
				

				SharedDataSvc.globalVars.ApplicationSave.productCategories = catList;
			}

			// Sending data as object instead of JSON
			//var mDataObject = angular.toJson(SharedDataSvc.globalVars.ApplicationSave);
			//console.log(mDataObject);
			var mData = SharedDataSvc.globalVars.ApplicationSave;


			// POST into HT

			applicationService.processApp(mData)
				.then(
				function (response) {
					applicationResultAction(response);
				},
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					void 0;
				}
				);

		}

		function provideSSN() {
			var existingCustSSNModal = modalService.open({
				title: 'Customer Verification',
				templateUrl: 'partials/modal_custExistsSsnVerify.tpl.html',
				scope: $scope,
				backdrop: 'static'
			});
			existingCustSSNModal.result
				.then(function (data) {
					var person = $scope.personData;
					$scope.ssnNumber = data.ssn1 + data.ssn2 + data.ssn3;
					person.ssn = data.ssn1 + data.ssn2 + data.ssn3;
					retrieveApplication(person);
				});
		}

		function confirmEmail() {
			var confirmEmailModal = modalService.open({
				title: 'Confirm Email',
				templateUrl: 'partials/modal_confirmEmail.tpl.html',
				scope: $scope,
				backdrop: 'static'
			});
			confirmEmailModal.result
				.then(function (data) {
					$scope.customerPrivate.textCodeGenerateAttemptsNumber = 0;
					generateTextCode();
				});
		}

		function generateTextCode() {
			if ($scope.customerPrivate.textCodeGenerateAttemptsNumber >= 3) {
				var errorTextCodeModal = modalService.open({
					title: 'Error Confirm Email',
					templateUrl: 'partials/modal_confirmEmailError.html',
					scope: $scope,
					backdrop: 'static'
				});
				return;
			}

			$scope.customerPrivate.textCode = '';
			var mData = {
				"toNumber": [],
				"languagePreference": "ENGLISH",
				"type": '',
				"emailId": ''
			}

			if ($scope.customerInfo.phone1 && $scope.customerInfo.phone1.number != undefined &&
				($scope.customerPrivate.verificationType == 'PText' ||
					$scope.customerPrivate.verificationType == 'PVoice')) {
				mData.toNumber.push($scope.customerInfo.phone1.number);
			} else if ($scope.customerInfo.phone2 && $scope.customerInfo.phone2.number != undefined &&
				($scope.customerPrivate.verificationType == 'SText' ||
					$scope.customerPrivate.verificationType == 'SVoice')) {
				mData.toNumber.push($scope.customerInfo.phone2.number);
			}

			if ($scope.customerPrivate.verificationType == 'PText' || $scope.customerPrivate.verificationType == 'SText') {
				mData.type = 'TEXT';
			}
			else if ($scope.customerPrivate.verificationType == 'PVoice' || $scope.customerPrivate.verificationType == 'SVoice') {
				mData.type = 'VOICE';
			} else {
				mData.type = 'EMAIL';
			}

			mData.emailId = $scope.customerInfo.email;


			applicationService.validatePhone(mData).then(
				function (response) {
					$scope.textCode = response;
					$scope.customerPrivate.textCodeGenerateAttemptsNumber += 1;
					var submitTextCodeModal = modalService.open({
						title: 'Confirm Email',
						templateUrl: 'partials/modal_confirmEmailTextCode.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});
				},
				// Error
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					void 0;
				});
		}

		function publishOverrideData(data){
			var Data = {
				"customerId": data.customerId,
				"engagementId": data.engagementId,
				"storeId": data.storeId,
				"overrideType": data.overrideType,
				"associateName": data.associateName,
				"associateId": data.associateId,
				"associatePassword": data.associatePassword,
				"comments": data.comments,
				"agreementNumber": data.agreementNumber
			}

			var obj = {};
			obj.message = {};
			obj.message.override = [];
			obj.message.override[0] = Data;
			
			applicationService.publishOverrideDataMasterCode(obj).then(
				function (response) {
					void 0;
				},
				// Error
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					void 0;
				});
		}

		function cancelTextCode() {
			$scope.showVerficationWarning = true;
		}

		function submitTextCode() {
			$scope.customerPrivate.textCodeError = false;
			$scope.customerPrivate.textCodeAttemptsNumber += 1;
			if ($scope.textCode == $scope.customerPrivate.textCode && $scope.customerPrivate.textCodeAttemptsNumber <= 3) {
				modalService.dismiss();
				processCustomerApp();
			} else if ($scope.customerPrivate.textCodeAttemptsNumber == 3) {
				$scope.customerPrivate.textCodeAttemptsNumber = 0;
				$scope.customerPrivate.textCode = '';
				if ($scope.customerPrivate.textCodeGenerateAttemptsNumber >= 3) {
					var errorTextCodeModal = modalService.open({
						title: 'Error Confirm Email',
						templateUrl: 'partials/modal_confirmEmailError.html',
						scope: $scope,
						backdrop: 'static'
					});
				} else {
					modalService.dismiss();
					$scope.showVerficationWarning = true;
					/*var confirmEmailModalTooManyPinAttempts = modalService.open({
						title: 'Confirm Email',
						templateUrl: 'partials/modal_confirmEmailTooManyPinAttempts.tpl.html',
						scope: $scope,
						backdrop: 'static'
					});*/
				}
			}
			else {
				$scope.customerPrivate.textCodeError = true;
				$scope.customerPrivate.textCode = '';
			}
		}

		function submitMasterTextCode(){
				var mData = {
					"customerId": SharedDataSvc.cutomerInfoForMasterCode.customerId,
					"engagementId": SharedDataSvc.cutomerInfoForMasterCode.VANEngagementId,
					"storeId": $scope.storeNo,
					"overrideType": 1,
					"associateName": $scope.customerPrivate.masterCode.employeeName,
					"associateId": $scope.customerPrivate.masterCode.employeeId,
					"associatePassword": SharedDataSvc.masterCodePassphraseUsed,
					"comments": $scope.customerPrivate.masterCode.comments,
					"agreementNumber": ''
				}

				publishOverrideData(mData);
				modalService.dismiss();
				processCustomerApp();
		}

		function provideBankingInfo() {
			if (SharedDataSvc.hasBImodalRun === false) {
				$scope.bankingAppModal = modalService.open({
					title: 'Provide Banking Info',
					templateUrl: 'partials/modal_appBankingInfo.tpl.html',
					backdrop: 'static',
					scope: $scope
				});
				$scope.bankingAppModal.result.then(submitBankingInfo);
				SharedDataSvc.hasBImodalRun = true;
			}
		}

		function handleSaveAndExit() {
			var handleSaveAndExitModal = modalService.open({
				title: 'Provide Banking Info',
				templateUrl: 'partials/modal_saveAndExit.html',
				backdrop: 'static',
				scope: $scope,
				windowClass: 'save-exit-modal'
			});

			handleSaveAndExitModal.result.then(function() {
				void 0;
				SharedDataSvc.searchValue = $scope.customerName;
				navigateTo('dashboard');
			});
		}

		function submitBankingInfo(bankingInfo) {
			modalService.processing('Checking Banking Information');
			// grab both numbers and throw into existing ApplicationSave
			SharedDataSvc.globalVars.ApplicationSave.bankingInfo.routingNumber = bankingInfo.routingNumber;
			SharedDataSvc.globalVars.ApplicationSave.bankingInfo.accountNumber = bankingInfo.accountNumber;

			// Sending data as object instead of JSON
			//var sData = angular.toJson(SharedDataSvc.globalVars.ApplicationSave);
			var sData = SharedDataSvc.globalVars.ApplicationSave;
			// return;


			applicationService.processApp(sData).then(
				// Success
				function (response) {
					applicationResultAction(response);
				},
				// Error
				function (error) {
					// sending error to Raygun
					SharedDataSvc.reportRaygun(error);
					void 0;
				}
			);
		}

		// How to react based on response from the application
		function applicationResultAction(responseData) {
			$scope.errorInValidateBI = false;
			$scope.errorInValidateBIMsg = undefined;
			var resultString = responseData.response;
			hideProcessingModal();
			void 0;
			// test for 'retry' response
			// if 'retry', then re-submit WITH banking info
			if (resultString === 'BANKINGRETRY' || resultString === 'RETRY') {
				// RETRY scenario
				SharedDataSvc.hasBImodalRun = false;
				SharedDataSvc.hasPAmodalRun = false;
				$scope.errorInValidateBI = true;
				$scope.errorInValidateBIMsg = responseData.decisionDetails;
				provideBankingInfo();
			} else if (resultString === 'APPROVE' || resultString === 'Approve' || resultString === 'APPROVE_BANK') {//VAN-3284-Prequalify customer for Charge After
				// APPROVE scenario
				$scope.challenge.approvedAmt = parseInt(responseData.amount);
				$scope.challenge.originalApprovedAmt = parseInt(responseData.originalApprovalAmount);
				$scope.challenge.outstandingAmt = parseInt(responseData.outstandingBalance);
				SharedDataSvc.globalVars.order.currentEngagement.approvalAmount = parseInt(responseData.amount);


				SharedDataSvc.globalVars.order.currentEngagement.applicationStatus = 'Accepted';
				if (typeof $scope.bankingAppModal !== "undefined") {
					$scope.bankingAppModal.dismiss();
				}


				$scope.engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || SharedDataSvc.globalVars.order.currentEngagement.engagementId;


				if ((parseInt(responseData.amount) < parseInt($scope.agreementAmount)) && !$scope.isSpanishStore) {
					$scope.storePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes
					modalService.open({
						title: 'Approval amount is less than minimum agreement amount',
						templateUrl: 'partials/modal_approvedBelowMin.tpl.html',
						backdrop: 'static',
						windowClass: 'approved-modal',
						scope: $scope
					});
				} else {
					// fire-off the APPROVED modal and fill it with info
					var approvedAmountModal = modalService.open({
						title: 'Application Approved',
						templateUrl: 'partials/alert_approvedForAmount.tpl.html',
						backdrop: 'static',
						windowClass: 'approved-modal',
						scope: $scope
					});
					approvedAmountModal.result
						.then(function () {
							//Story 33452 - AWP take customer to missing info at agreement starts
							if($rootScope.isSLICustomer){
								navigateTo('ordersummary/' + SharedDataSvc.globalVars.currentSLICustEngId);
							} else {
								$location.path('dashboard');
							}
							//Story 33452 - AWP take customer to missing info at agreement ends
						});
				}
				/*  Removed this check to fix defect 25955
				if( parseInt(responseData.amount) >= parseInt(responseData.minimumApprovalAmount) ) {
					
				} */
			} else if (resultString === 'DECLINE') {
				// DECLINE
				if (typeof $scope.bankingAppModal !== "undefined") {
					$scope.bankingAppModal.dismiss();
				}
				$scope.declinedMessage = "We are not able to approve your application at this time. An explanatory letter will be sent to your e-mail address within 7 to 10 business days.";//Story 29310 Changes
				var appDeclinedModal = modalService.open({
					title: 'Application Declined',
					templateUrl: 'partials/alert_applicationDeclined.tpl.html',
					backdrop: 'static',
					scope: $scope
				});
				appDeclinedModal.result
					.then(function () {
						$location.path('dashboard');
					});

			} else if (resultString === 'ERROR') {
				// ERROR
				void 0;
			} else if (resultString === 'MANUAL_INTERVENTION') {
				// MANUAL_INTERVENTION
				if (responseData.decisionDetails === "Unable to retrieve telecheck score.") {
					$scope.storePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
					modalService.open({
						templateUrl: 'partials/alert_appManualIntervention.tpl.html',
						backdrop: 'static',
						scope: $scope
					});
				} else {
					modalService.open({
						templateUrl: 'partials/alert_appAssociateReview.tpl.html',
						windowClass: 'modal-small',
						backdrop: 'static',
						scope: $scope
					});
				}
			}
		}

		function fillApplicationData(event, data) {
			$scope.customerInfo = jQuery.extend(true, {}, data);
			//$scope.customerInfo = data;
			//SharedDataSvc.customerInfo = data;
			var engagementData, customer;
			resetZipData();//Defect 26474 code change

			if ($routeParams.engagementId) {
				void 0;
				engagementData = data.VanEngagement;
				customer = engagementData.customer;

				var newCustomerInfo = {
					firstName: customer.fName,
					lastName: customer.lName,
					middleName: data.middleInitial,
					phone1: {
						number: customer.phNum
					},
					address: {
						state: customer.state,
						addrLine1: customer.addressLine1,
						//addrLine2: customer.addressLine2,
						addrLine2: customer.unit,
						city: customer.addressCity,
						zipCode: (customer.addressZipcode && customer.addressZipcode !== "00000") ? customer.addressZipcode.substring(0,5) : "",//Defect 26474 code change
					},
					email: customer.email,
					emailVerify: customer.email//Story 30445 -AWP, Kiosk: Add email verification edit box
				};
				$scope.customerInfo = _.merge($scope.customerInfo, newCustomerInfo);
				SharedDataSvc.customerInfo = $scope.customerInfo;
			}

			//filling field inputs
			$scope.customerInfoForm.firstName.$pristine = false;
			$scope.customerInfoForm.lastName.$pristine = false;

			//Story 30445 code changes starts
			if (data.email) {
				$scope.customerInfo.emailVerify = data.email;
			}
			//Story 30445 code changes ends
			if (data.address && data.address[0]) {

				$scope.customerInfo.address = {
					addrLine1: data.address[0].addrLine1,
					addrLine2: data.address[0].addrLine2,
					city: data.address[0].city,
					state: data.address[0].state,
					zipCode: (data.address[0].zipCode && data.address[0].zipCode !== "00000") ? data.address[0].zipCode.substring(0,5) : "" // Defect 26474 code change

				};

			}

			if (data.incomeSources && data.incomeSources.income) {
				$scope.customerInfo.monthlyIncome = data.incomeSources.income;
			}
			if (!$scope.customerInfo.monthlyIncome) {
				$scope.customerInfoForm.iSources.monthlyIncome.$pristine = true;
			} else {
				$scope.customerInfoForm.iSources.monthlyIncome.$pristine = false;
			}

			// dob
			if (data.driversLicense) {
				if (data.driversLicense.birthdate !== '') {
					var birthdate = data.driversLicense.birthDate;
					var dobYear = birthdate.substr(0, 4);
					var dobMonth = birthdate.substr(4, 2);
					var dobDay = birthdate.substr(6, 2);
					$scope.customerPrivate.dob = {
						month: dobMonth,
						day: dobDay,
						year: dobYear
					};
				}
			} else {
				$scope.customerInfo.driversLicense = {
					idType : "1"
				};
			}

			// SSN splicing
			if (data.social) {
				var fullSSN = data.social;

				if(data.itinFlag) {
					$scope.customerPrivate.ssn.ssnType = 'ITIN';
					$scope.customerPrivate.ssn.ssnTypeDisable = true;

					$scope.customerPrivate.itin = {};
					$scope.customerPrivate.itin.itin1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.itin.itin2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.itin.itin3 = fullSSN.slice(5, 9);

					$scope.customerPrivate.verifyitin = {};
					$scope.customerPrivate.verifyitin.itin1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.verifyitin.itin2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.verifyitin.itin3 = fullSSN.slice(5, 9);
				} else {
					$scope.customerPrivate.ssn.ssnType = 'SSN';
					$scope.customerPrivate.ssn.ssnTypeDisable = true;
					
					$scope.customerPrivate.ssn.ssn1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.ssn.ssn2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.ssn.ssn3 = fullSSN.slice(5, 9);

					$scope.customerPrivate.verifyssn.ssn1 = fullSSN.slice(0, 3);
					$scope.customerPrivate.verifyssn.ssn2 = fullSSN.slice(3, 5);
					$scope.customerPrivate.verifyssn.ssn3 = fullSSN.slice(5, 9);
				}
			} else if(data.itinFlag && !data.social){
				$scope.customerPrivate.ssn.ssnType = 'ITIN';
			}

			if ($scope.storeInfo.isReferenceRequired !== false && data.personalReferences && data.personalReferences.length > 0) {
				var refLength;
				if($scope.isSpanishStore){
					refLength = 4;
				}else{
					refLength = 3;
				}
				for (var i = 0; i < refLength; i++) {
					var refNum = i + 1;
					var reference = {};
					reference.firstName = data.personalReferences[i].firstName;
					reference.lastName = data.personalReferences[i].lastName;
					reference.personalReferenceType = data.personalReferences[i].personalReferenceType;
					reference.address = data.personalReferences[i].address[0].addrLine1;
					reference.number = data.personalReferences[i].phone.areaCode + data.personalReferences[i].phone.number;
					reference.phoneType = data.personalReferences[i].phone.phoneType;
					$scope['ref' + refNum] = reference;
					$scope.customerInfoForm.newRefs['ref' + refNum + 'FirstName'].$pristine = false;
					$scope.customerInfoForm.newRefs['ref' + refNum + 'LastName'].$pristine = false;
					$scope['ref' + refNum + 'NumberInvalid'] = false;
				}
			}


			if (data.phones) {
				if (data.phones[0].number.length > 0) {
					$scope.customerInfo.phone1 = {
						number: data.phones[0].areaCode + data.phones[0].number,
						phoneType: data.phones[0].phoneType || null,
						bestCallTime: {
							Morning: false,
							Afternoon: false,
							Evening: false
						}
					};

					/*if($scope.customerInfo.phone1.phoneType == 'CELL' || $scope.customerInfo.phone1.phoneType == 'WORK'){
						$timeout(function(){
							document.getElementById('workPhone').checked =true;
						},1000);
					} else if($scope.customerInfo.phone1.phoneType == 'HOME') {
						$timeout(function(){
							document.getElementById('homePhone').checked =true;
						},1000);
					}
					/*if (data.phones[0].bestCallTime) {
						var times = data.phones[0].bestCallTime.split(",");
						console.log("[Best call Times]", times);
						for (var x = 0; x < times.length; x++) {
							times[x] = times[x].trim();

							console.log(times[x]);

							if (times[x] == "M") {
								$scope.customerInfo.phone1.bestCallTime.Morning = "Yes";
							} else if (times[x] == "A") {
								$scope.customerInfo.phone1.bestCallTime.Afternoon = "Yes";
							} else if (times[x] == "E") {
								$scope.customerInfo.phone1.bestCallTime.Evening = "Yes";
							}
						}
					}*/
				}
				if (data.phones.length > 1 && data.phones[1].areaCode !== "000") {
					$scope.customerInfo.phone2 = {
						number: data.phones[1].areaCode + data.phones[1].number,
						phoneType: data.phones[1].phoneType || null
					};
				} else if (data.phones.length > 1 && data.phones[1].areaCode === "000" && data.phones[0].phoneType.toUpperCase() === "HOME" && $scope.allowTextVerificationFlag) {
					$scope.customerInfo.phone2 = {
						phoneType: "CELL"
					};
				}
			}

			if (data.incomeSources && data.incomeSources[0].incomeType !== null) {
				var incomeSource = data.incomeSources[0];
				$scope.customerInfo.incomeSources = {
					income: incomeSource.incomeType,
					monthlyIncome: incomeSource.monthlyIncome !== "000000" ? incomeSource.monthlyIncome : "",
					sourceName: incomeSource.sourceName !== "n/a" ? incomeSource.sourceName : "",
					sourcePhone: {
						number: (incomeSource.sourcePhone.areaCode !== "000" ? incomeSource.sourcePhone.areaCode : "") + (incomeSource.sourcePhone.number !== "0000000" ? incomeSource.sourcePhone.number : "")
					}
				};
				if (incomeSource.sourceStartDate !== "") {
					var startDateSelection;
					var monthThresholds = ['5', '11', '23', '30'];
					var startDate = moment(incomeSource.sourceStartDate, 'YYYYMMDD');
					var monthsSinceStart = moment().diff(startDate, 'months');
					for (var i = 0; i < monthThresholds.length; i++) {
						if (monthsSinceStart <= monthThresholds[i] && monthsSinceStart) {
							startDateSelection = monthThresholds[i];
							break;
						}
					}
					if (monthsSinceStart >= monthThresholds[monthThresholds.length - 1]) {
						startDateSelection = monthThresholds[monthThresholds.length - 1];
					}
					$scope.customerInfo.incomeSources.sourceStartDate = startDateSelection;
				}
			} else {
				void 0;
				$scope.customerInfo.incomeSources = {};
				$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
				//$scope.coCustomerInfo.incomeSources.income = $scope.incomeEnums[0].type;
				//$scope.customerInfo.phone2 = {};
			}

			//PR fields
			if($scope.isSpanishStore){
				if( data.isCoCustomerInfoProvided === undefined || data.isCoCustomerInfoProvided === true || data.isCoCustomerInfoProvided === null ){
					$scope.custAdditionalInfo.noCocustomer = false;
					//$scope.custAdditionalInfo.noCocustomerIncome = true;
				}else {
					$scope.custAdditionalInfo.noCocustomer = true;
					//$scope.custAdditionalInfo.noCocustomerIncome = true;
				}
				

				if(data.additionalInfo){
					if(data.additionalInfo.isCoCustomerIncomeSourceProvided === undefined || data.additionalInfo.isCoCustomerIncomeSourceProvided === true || data.additionalInfo.isCoCustomerIncomeSourceProvided === null) {
						$scope.custAdditionalInfo.noCocustomerIncome = false;
					} else {
						$scope.custAdditionalInfo.noCocustomerIncome = true;
					}
				
					if(data.additionalInfo.isCarDetailsProvided){
						if(data.additionalInfo.autoMakeAndModel){
							var model = data.additionalInfo.autoMakeAndModel.split('-');
							$scope.custAdditionalInfo.auto.make = model[0];
							$scope.custAdditionalInfo.auto.model = model[1];
						}
						if(data.additionalInfo.tag){
							$scope.custAdditionalInfo.auto.tag = data.additionalInfo.tag;
						}
						if(data.additionalInfo.carPaymentAmount){
							$scope.custAdditionalInfo.auto.amount = data.additionalInfo.carPaymentAmount;
						}
						if(data.additionalInfo.color){
							$scope.custAdditionalInfo.auto.color = data.additionalInfo.color;
						}
					}else{
						$scope.custAdditionalInfo.auto.noCar = true;
					}
					
					
					if(data.additionalInfo.previousAddress.addrLine1){
						$scope.custAdditionalInfo.previousAddress.addrLine1 = data.additionalInfo.previousAddress.addrLine1;
					}
					if(data.additionalInfo.previousAddress.landLordName){
						$scope.custAdditionalInfo.previousAddress.mortgageName = data.additionalInfo.previousAddress.landLordName;
					}

					if(data.additionalInfo.previousAddress.landLordNumber){
						$scope.custAdditionalInfo.previousAddress.mortgagePhone = data.additionalInfo.previousAddress.landLordNumber;
					}

					if(data.additionalInfo.previousAddress){
						$scope.showPreviousAdd = true;
						if(data.additionalInfo.previousAddress[0].addrLine1){
							$scope.custAdditionalInfo.previousAddress.addrLine1	 = data.additionalInfo.previousAddress[0].addrLine1;
						}
					
						if(data.additionalInfo.previousAddress[0].landLordName){
							$scope.custAdditionalInfo.previousAddress.mortgageName = data.additionalInfo.previousAddress[0].landLordName;
						}

						if(data.additionalInfo.previousAddress[0].landLordNumber){
							$scope.custAdditionalInfo.previousAddress.mortgagePhone = data.additionalInfo.previousAddress[0].landLordNumber;
						}
					}

					

					if(data.additionalInfo.coCustomerIncomeSource){
						//$scope.custAdditionalInfo.noCocustomerIncome =  false;
						var coCustIncomeInfo = data.additionalInfo.coCustomerIncomeSource[0];
						if(coCustIncomeInfo.sourcePhone){
							if (coCustIncomeInfo.sourcePhone.number > 1 && coCustIncomeInfo.sourcePhone.areaCode !== "000") {
								$scope.coCustomerInfo.incomeSources.sourcePhone = {
									number: coCustIncomeInfo.sourcePhone.areaCode + coCustIncomeInfo.sourcePhone.number
								}
								//$scope.coCustomerInfo.incomeSources.sourcePhone.number = coCustIncomeInfo.sourcePhone.areaCode + coCustIncomeInfo.sourcePhone.number;
							} 		
						}

						if (coCustIncomeInfo.sourceStartDate !== "") {
							var startDateSelection;
							var monthThresholds = ['5', '11', '23', '30'];
							var startDate = moment(coCustIncomeInfo.sourceStartDate, 'YYYYMMDD');
							var monthsSinceStart = moment().diff(startDate, 'months');
							for (var i = 0; i < monthThresholds.length; i++) {
								if (monthsSinceStart <= monthThresholds[i] && monthsSinceStart) {
									startDateSelection = monthThresholds[i];
									break;
								}
							}
							if (monthsSinceStart >= monthThresholds[monthThresholds.length - 1]) {
								startDateSelection = monthThresholds[monthThresholds.length - 1];
							}
							$scope.coCustomerInfo.incomeSources.sourceStartDate = startDateSelection;
						}

						if(coCustIncomeInfo.sourceAddress){
							$scope.coCustomerInfo.incomeSources.employerAddress  = coCustIncomeInfo.sourceAddress.addrLine1;
						}
						if(coCustIncomeInfo.sourceName){
							$scope.coCustomerInfo.incomeSources.sourceName = coCustIncomeInfo.sourceName;
						}

						//if(coCustIncomeInfo.incomeType && !$scope.custAdditionalInfo.noCocustomerIncome){
							$scope.coCustomerInfo.incomeSources.income  = coCustIncomeInfo.incomeType;
						//}

						if(coCustIncomeInfo.monthlyIncome){
							$scope.coCustomerInfo.incomeSources.takeHomePay = coCustIncomeInfo.monthlyIncome;
						}
						if(coCustIncomeInfo.sourceDepartment){
							$scope.coCustomerInfo.incomeSources.deptTitle = coCustIncomeInfo.sourceDepartment;
						}
						if(coCustIncomeInfo.supervisorName){
							$scope.coCustomerInfo.incomeSources.supervisorName = coCustIncomeInfo.supervisorName;
						}
						if(coCustIncomeInfo.workHours){
							$scope.coCustomerInfo.incomeSources.workHours = coCustIncomeInfo.workHours;
						}

						/*if(coCustIncomeInfo.bestPayDate){
							$scope.coCustomerInfo.incomeSources.bestPayDate = coCustIncomeInfo.bestPayDate;
						}*/

						if(coCustIncomeInfo.nextPayday){
							var npd = coCustIncomeInfo.nextPayday;
							var ndpYear = npd.substr(0, 4);
							var ndpMonth = npd.substr(4, 2);
							var ndpDay = npd.substr(6,2);
							$scope.coCustomerInfo.incomeSources.nextPayDate = {
								month : ndpMonth,
								day : ndpDay
							}
						}
						if(coCustIncomeInfo.paydayFrequency){
							$scope.coCustomerInfo.incomeSources.payFrequency = coCustIncomeInfo.paydayFrequency; 
						}


					}

				}
					/* income info customer */
					if(data.incomeSources){
						if(data.incomeSources[0].sourceAddress){
							$scope.customerInfo.incomeSources.employerAddress = data.incomeSources[0].sourceAddress.addrLine1;
						}
		
						if(data.incomeSources[0].department){					
								$scope.customerInfo.incomeSources.deptTitle = data.incomeSources[0].department;
						}
						if(data.incomeSources[0].supervisorName){
							$scope.customerInfo.incomeSources.supervisorName = data.incomeSources[0].supervisorName;
						}
						if(data.incomeSources[0].workHours){
							$scope.customerInfo.incomeSources.workHours = data.incomeSources[0].workHours;
						}
						if(data.incomeSources[0].bestPayDate){
							$scope.customerInfo.incomeSources.bestPayDate = data.incomeSources[0].bestPayDate;
						}

						if(data.incomeSources[0].nextPayday){
							var npd = data.incomeSources[0].nextPayday;
							var ndpYear = npd.substr(0, 4);
							var ndpMonth = npd.substr(4, 2);
							var ndpDay = npd.substr(6,2);
							$scope.customerInfo.incomeSources.nextPayDate = {
								month : ndpMonth,
								day : ndpDay
							}
						}
						if(data.incomeSources[0].paydayFrequency){
							$scope.customerInfo.incomeSources.payFrequency = data.incomeSources[0].paydayFrequency; 
						}
					}
					



				if(data.address){
					if(data.address[0].moveInDate){
							var mid = data.address[0].moveInDate;
							var moveInYear = mid.substr(0, 4);
							var moveInMonth = mid.substr(4, 2);
							$scope.custAdditionalInfo.moveInDate.year = moveInYear;
							$scope.custAdditionalInfo.moveInDate.month = moveInMonth;
							$scope.setPreviousAdd();
					}
				}
					
				if(data.address){
					if(data.address[0].isRenewingLease){
							$scope.customerInfo.address.homeType = data.address[0].isRenewingLease;
					}

					if(data.address[0].isRenewingLease == "RENT"){
						if(data.address[0].landLordName){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyRent = data.address[0].landLordName;
						}
						if(data.address[0].landLordNumber){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumRent = data.address[0].landLordNumber;
						}
						
						if(data.additionalInfo.customerPaymentAmount){
							$scope.custAdditionalInfo.ownRentInfo.custPaymentAmountRent = data.additionalInfo.customerPaymentAmount;
						}

						if(data.additionalInfo.averageMonthlyUtilities){
							$scope.custAdditionalInfo.ownRentInfo.avgUtilitiesRent = data.additionalInfo.averageMonthlyUtilities;
						}

					}

					if(data.address[0].isRenewingLease == "OWN"){
						if(data.address[0].landLordName){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyOwn = data.address[0].landLordName;
						}
						if(data.address[0].landLordNumber){
							$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNumOwn = data.address[0].landLordNumber;
						}
						if(data.additionalInfo.customerPaymentAmount){
							$scope.custAdditionalInfo.ownRentInfo.custPaymentAmountOwn = data.additionalInfo.customerPaymentAmount;
						}

						if(data.additionalInfo.averageMonthlyUtilities){
							$scope.custAdditionalInfo.ownRentInfo.avgUtilitiesOwn = data.additionalInfo.averageMonthlyUtilities;
						}
					}
					
					if(data.address[0].leaseType){
						$scope.custAdditionalInfo.rent.leaseTypeRent = data.address[0].leaseType;
					}
					if(data.address[0].apartmentComplex){
						$scope.custAdditionalInfo.rent.apmtComplex = data.address[0].apartmentComplex;
					}
					

					if(data.address[0].leaseEndDate){
							var led = data.address[0].leaseEndDate;
							var leaseEndYear = led.substr(0, 4);
							var leaseEndMonth = led.substr(4, 2);
							$scope.custAdditionalInfo.leaseEndDate.year = leaseEndYear;
							$scope.custAdditionalInfo.leaseEndDate.month = leaseEndMonth;
					}
				
				}
				/* co customer 	info*/
				if(data.coCustomerInfo){
						if(data.coCustomerInfo.relationshipType){
						$scope.coCustomerInfo.relation = data.coCustomerInfo.relationshipType;
					}
					if(data.coCustomerInfo.firstName){
						$scope.coCustomerInfo.firstName = data.coCustomerInfo.firstName;
					}
					if(data.coCustomerInfo.lastName){
						$scope.coCustomerInfo.lastName = data.coCustomerInfo.lastName;
					}
					if(data.coCustomerInfo.middleInitial){
						$scope.coCustomerInfo.middleName = data.coCustomerInfo.middleInitial;
					}

					if (data.coCustomerInfo.birthDate) {
						var bdate = data.coCustomerInfo.birthDate;
						var dYear = bdate.substr(0, 4);
						var dMonth = bdate.substr(4, 2);
						var dDay = bdate.substr(6, 2);
						$scope.coCustomerInfo.dob = {
							month: dMonth,
							day: dDay,
							year: dYear
						};
					}

					if(data.coCustomerInfo.phoneNumber){
						$scope.coCustomerInfo.phone1.number = data.coCustomerInfo.phoneNumber;
					}

					if(data.coCustomerInfo.phoneType){
						$scope.coCustomerInfo.phone1.phoneType = data.coCustomerInfo.phoneType;
					}

					if(data.coCustomerInfo.social){
						$scope.coCustomerPrivate.ssn.ssn1 = "***";
						$scope.coCustomerPrivate.ssn.ssn2 = "**";
						$scope.coCustomerPrivate.ssn.ssn3 = data.coCustomerInfo.social;
					}
				}
				


				/* co customer 	info*/
				
			}
			// income length date-calculations
			// var incomeLength = $scope.customerInfo.incomeSources.sourceStartDate;
			// incomeLength = incomeLength[2]+incomeLength[1]+incomeLength[0];
			// var currDate = new Date();
			// $scope.then = moment(incomeLength,'YYYYMMDD');
			// $scope.now = moment();
			// var diff = $scope.then.from($scope.now);
			//console.log('diff='+diff);
		}
		
		function provideBankingInfoForACH(customerInfo) {
			$scope.tryAgainCounter = 0;
			$scope.tryAgainMsg = false;
			SharedDataSvc.customerInfoForACH = customerInfo;
			$scope.provideBankingInfoForACHModal = modalService.open({
				title: 'Customer Verification',
				templateUrl: 'partials/modal_appBankingInfoForACH.tpl.html',
				scope: $scope,
				backdrop: 'static'
			});
		}

		function submitBankingInfoForACH(data) {
			$scope.tryAgainMsg = false;
			SharedDataSvc.customerInfoForACH.bankingInfo = {
				routingNumber: data.routingNumber,
				accountNumber: data.accountNumber,
				accountType: data.accountType ? 'Savings' : 'Checking'
			};
			SharedDataSvc.customerInfoForACH.billToaddress = {
				stateProvince: data.address.state,
				address1: data.address.addrLine1,
				//addrLine2: data.address.addrLine2 ? data.address.addrLine2 : "",
				city: data.address.city,
				postalCode: data.address.zipCode !== "00000" ? data.address.zipCode : ""
			};
			applicationService.validateBankingInfo(SharedDataSvc.customerInfoForACH).then(
				function (response) {
					$scope.provideBankingInfoForACHModal.dismiss();
					modalService.processing('Checking Banking Information');
					$timeout(function () {
						modalService.dismiss();
					}, 1200);
					void 0;
					$scope.customerInfoForACH = SharedDataSvc.customerInfoForACH;

					if ($scope.customerInfo.address) {
						/*$scope.customerInfo.address.addrLine1 = SharedDataSvc.customerInfoForACH.billToaddress.address1;
						$scope.customerInfo.address.state = SharedDataSvc.customerInfoForACH.billToaddress.stateProvince;
						$scope.customerInfo.address.city = SharedDataSvc.customerInfoForACH.billToaddress.city;
						$scope.customerInfo.address.zipCode = SharedDataSvc.customerInfoForACH.billToaddress.postalCode;*/
					} else {
						$scope.customerInfo.address = {
							addrLine1: SharedDataSvc.customerInfoForACH.billToaddress.address1,
							state: SharedDataSvc.customerInfoForACH.billToaddress.stateProvince,
							city: SharedDataSvc.customerInfoForACH.billToaddress.city,
							zipCode: SharedDataSvc.customerInfoForACH.billToaddress.postalCode
						};
					}

				},
				function (error) {
					void 0;
					if (error.data.errorData.status_code == "ENG-A1012-400" && $scope.tryAgainCounter < 3) {
						$scope.tryAgainCounter++;
						$scope.tryAgainMsg = true;
						return false;
					} else {
						$scope.provideBankingInfoForACHModal.dismiss();
						navigateTo('dashboard');
					}
				}
			);
		}
		//VAN-3209-Use customers zip code on agreement creation - Starts
		function stateValidation(state){
			if(state == $scope.storeInfo.state){
				$scope.isStateValid = true;
			}else{
				$scope.isStateValid = false;
			}
		}
		//VAN-3209-Use customers zip code on agreement creation - Ends
		
		////////////////////////////////////////////////
		//  WATCHERS  //////////////////////
		////////////////////////////////////////////////

		$scope.$watch('customerPrivate.dob.month', generateDays);
		$scope.$watch('coCustomerInfo.dob.month', generateDaysdob);

		$scope.$watch('customerInfo.incomeSources.nextPayDate.month', generateDaysNextPay);

		////////////////////////////////////////////////
		//  BROADCAST LISTENERS   //////////////////////
		////////////////////////////////////////////////

		$scope.$on("applicationDataUpdated", fillApplicationData);
		//VAN-3128-KOUNT implementation VA/AWP - Starts
		/*var kountUrl = null;
		$rootScope.kountSessionId = null;
		if(window.location.hostname !== undefined && kountUrl == null){
			if(window.location.hostname === 'awp.acceptancenow.com'){
				kountUrl = "https://ssl.kaptcha.com/collect/sdk?m=171110"; 
			}
			else{
				kountUrl = "https://tst.kaptcha.com/collect/sdk?m=171110";  
			}
		}
		console.log("kountUrl - "+kountUrl);
		var body = document.createElement('body');
		body.setAttribute('class', 'kaxsdc');
		body.setAttribute('data-event', 'load');
		document.body.appendChild(body);
		var script = document.createElement('script');
		script.src = kountUrl;
		document.head.appendChild(script);
		script.onload = function() {
			try{
				var client = new ka.ClientSDK();
				client.setupCallback(
						{
							'collect-end':
								function(params) {
								console.log("Fires when collection has ended");
								if(params != null &&  Object.keys(params).length>1){
									var MercSessId = params['MercSessId'];
									console.log("MercSessId- "+MercSessId);
									if(MercSessId && MercSessId != null){
										console.log("kountSessionId Generated");
										$rootScope.kountSessionId = MercSessId;
									}
								}
							},

							'collect-begin':
								function(params) {
								console.log("Fires when Collection has started.");
							}
						}
				);
				client.autoLoadEvents(); 
			} catch (e) {
				if (e instanceof ReferenceError) {
					console.log("Error: "+e.name+" "+ e.message);
					$rootScope.kountSessionId = 'Error: '+e.message;

				} else {
					console.log("Error: "+e.name+" "+ e.message);
					$rootScope.kountSessionId = 'Error: '+e.message;
				}
			}

		};

		script.onerror = function() {
			console.log("Error at OnLoad");
			$rootScope.kountSessionId = 'Error: On load of Kount Service';
		};*/
		//VAN-3128-KOUNT implementation VA/AWP - End
		
		//VAN-3402-Re-engagement with Code - AWP - Start
		function validateSelectedSrchOpt(value){
			if(value=='Id' || value=='Name'){
				$scope.showSubmit = true;
			}else{
				$scope.showSubmit = false;
			}
		}

		function findCustApplication(){
			var findCustById = modalService.open({
				title: 'Search By ID',
				templateUrl: 'partials/modal_searchApplicationByApprovalId.tpl.html',
				size: 'sm',
				backdrop: 'static',
				windowClass: 'modal_searchApplicationByApprovalId',
				scope: $scope
			});
			findCustById.result.then(function (result) {
				void 0;
				modalService.processing('Processing');
				applicationService.searchApplicationByID(result.approvalId)
				.then(function (response) {
					$log.log('Found the customer successfully.');
					modalService.dismiss();
					if(response!=null){
						$rootScope.isAppSrch=true;
						$scope.custApp=response;
						if(response.VanEngagement!=null && response.VanEngagement.customer!=null && response.VanEngagement.customer.isConsentAgreed==null){
							openConsentModal($scope.custApp);
						}else{
							getApplicationSuccess($scope.custApp);
						}
					}
				},function (response) {
					$log.log('Failed while searching application by approvalId');
					modalService.dismiss();
					navigateTo('dashboard/');
				});
			});
		}

		function searchSelection(){
			var selectSearchOption = modalService.open({
				title: 'Select Search Option',
				templateUrl: 'partials/modal_selectSearchOption.tpl.html',
				size: 'sm',
				backdrop: 'static',
				windowClass: 'modal_selectSearchOption',
				scope: $scope
			});
			selectSearchOption.result.then(function (result) {
				if(result!=null && result!=undefined){
					if(result.selectedOption.value == 'Id'){
						findCustApplication();
					}else if(result.selectedOption.value == 'Name'){
						$scope.closeConsent();
					}
				}
			});
		}

		function validateApprovalId(value){
			if(!$scope.idValidation.test(value)){
				$scope.showApprovalError = true;
				$scope.enableSearch = true;
			}else{
				$scope.showApprovalError = false;
				$scope.enableSearch = false;
			}
		}

		function resetValidation(){
			$scope.searchSelectionForm.selectedOption='';
			$scope.showSubmit = false;
			$scope.showApprovalError = false;
			$scope.enableSearch = true;
			$rootScope.isAppSrch=false;
			$scope.searchByIdForm.approvalId = '';
		}

		function openConsentModal(response){
			$scope.eAgreement = $modal.open({
				templateUrl: 'partials/modal_eAgreementNotice_StartApp.html',
				show: true,
				backdrop: 'static',
				scope: $scope
			});
		} 
		//VAN-3402-Re-engagement with Code - AWP - End
	}
	Application.$inject = ["$scope", "$rootScope", "$http", "$cookies", "$log", "$timeout", "$modal", "$location", "$anchorScroll", "$routeParams", "$q", "$idle", "$translate", "getSpecificApplication", "SharedDataSvc", "modalService", "authentication", "applicationService", "agreementFactory", "base64", "getStoreInfoFactory", "stateRestrictFactory", "datesFactory", "updateEngagementItemFactory", "customerDataFactory", "productCategoriesFactory"]; // end Application controller
})();

// The EPay controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('EPay', ePay);

  ePay.$inject = ["$scope", "$log", "$rootScope", "$routeParams", "$q", "$translate", "$location", "$cookies", "modalService", "getSpecificApplication", "paymentFactory", "SharedDataSvc", "base64", "getStoreInfoFactory", "applicationService", "updateEngagementItemFactory", "customerDataFactory", "agreementFactory", "datesFactory"];

  function ePay($scope,
                $log,
                $rootScope,
                $routeParams,
                $q,
                $translate,
                $location,
                $cookies,
                modalService,
                getSpecificApplication,
                paymentFactory,
                SharedDataSvc,
                base64,
                getStoreInfoFactory,
                applicationService,
                updateEngagementItemFactory,
                customerDataFactory,
                agreementFactory,
                datesFactory
               ) {
    /*jshint validthis: true */
    var vm = this;

    // set scope variable for processing modal
    $scope.viewLoading = false;
    $scope.modalTitle = 'Processing';

    $scope.initialPaymentOCHSubmitted = false;
    // Variables
    vm.benefits;
    vm.account = {
        username:"",
        password:"",
        name: null,
        passwordVerify: "",
        loggedIntoEpay: /*false,*/true,//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		newOrExistingAcct: /*true,*/false,//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        paymentCredit: 'credit',
        /* Commented these changes as part of VAN-670 - Consent Verbiage*/
       /*VAN-616 - VAN - Low Down Initial Payment - Starts
        paymentInitialValSelected: 'AutoPayRequired',
        VAN-616 - VAN - Low Down Initial Payment - Ends*/
        paymentBankAccount: false,
        securityQuestion:[
            {
              name: {
                en : en.epay_label_securityQstnOpt1,
                es : es.epay_label_securityQstnOpt1
              },
              value: en.epay_label_securityQstnOpt1
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt2,
                es : es.epay_label_securityQstnOpt2
              },
              value: en.epay_label_securityQstnOpt2
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt3,
                es : es.epay_label_securityQstnOpt3
              },
              value: en.epay_label_securityQstnOpt3
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt4,
                es : es.epay_label_securityQstnOpt4
              },
              value: en.epay_label_securityQstnOpt4
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt5,
                es : es.epay_label_securityQstnOpt5
              },
              value: en.epay_label_securityQstnOpt5
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt6,
                es : es.epay_label_securityQstnOpt6
              },
              value: en.epay_label_securityQstnOpt6
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt7,
                es : es.epay_label_securityQstnOpt7
              },
              value: en.epay_label_securityQstnOpt7
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt8,
                es : es.epay_label_securityQstnOpt8
              },
              value: en.epay_label_securityQstnOpt8
            }
        ],
        securityAnswer: null,
        initialPayment:{
            routingNumber:"",
            accountNumber:"",
            accountType: undefined,
            creditCard:{
                cc1: null,
                cc2: null,
                cc3: null,
                cc4: null,
                cardType:"",
                nameOnCard: null,
                expDate: "", //'10/2017'
                securityCode: null, // 127
                billingAddress1: '',
                billingAddress2: '',
                billingCity: null,
                billingState: null,
                billingZipCode: null,
                billingEmail: null,
                billingPhone:  null
            }
        },
        autoPayment: {
            useSameAcctAsInitial: false,
            existingBankOrCredit: true,
            routingNumber:"",
            accountNumber:"",
            accountType: undefined,
            existingAcctNumber:"",
            nameOnAccount:"",
            skipAutopay: false,
            creditCard:{
                cc1: "",
                cc2: "",
                cc3: "",
                cc4: "",
                cardType: "",
                nameOnCard:"",
                expDate:"",
                securityCode:"",
                billingAddress1: "",
                billingAddress2: "",
                billingCity: "",
                billingState: "",
                billingZipCode: "",
                billingEmail: "",
                billingPhone: ""
            }
        },
        epayStatus: "In Progress",
        epayStatusId: 1,
        possibleStatuses: [
          {
            id:0,
            title: "Not Started",
            cssClass: "process-standby",
            editable: true
          },
          {
            id:1,
            title: "In Progress",
            cssClass: "process-yellow",
            editable: true
          },
          {
            id:2,
            title: "Completed",
            cssClass: "process-complete",
            editable: false
          }
        ]
    };
    vm.login = {
      customerUsername: null,
      customerPassword: null,
      engagementId: null
    };
    vm.order;
    vm.engagementId = $routeParams.engagementId;
    vm.modal;
    vm.alertClass = '';
    vm.viewLoading = false;
    vm.statusMessage = undefined;
    vm.message = undefined;
    vm.message2 = undefined;
    vm.error = undefined;
    vm.initialPaymentPaid = false;
    vm.customer;
//	  VAN-2372 Initial Payment Breakdown details starts
    vm.diffDays;
    vm.showBreakDown;
    vm.rentalFee;
    vm.liabilityWaiverAmt;
    vm.benefitsPlusAmt;
    vm.totalInitialAmt;
//	  VAN-2372 Initial Payment Breakdown details ends
    vm.engagement;
    vm.storeInfo;
    vm.userNamePattern = /^[a-z0-9]+$/i;
    vm.userPassPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7}$/;
    vm.validUsername = true;
    vm.validPass = true;
    vm.verifyPass = true;
    vm.initialPayment = '19077';
    vm.formattedInitialPayment;
    vm.bankAccountNoLastFourDigit;
    vm.changeDue = 0;
    vm.change;
    vm.cashPaymentToLow = false;
    vm.securityQuestion = $rootScope.selectedLanguage == 'es' ? vm.account.securityQuestion[0].name.es : vm.account.securityQuestion[0].name.en;
    vm.paymentMessageSuccess = $rootScope.selectedLanguage == 'es' ? es.epay_label_success_epayCreate : en.epay_label_success_epayCreate;
    vm.paymentMessageFailure = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_paymentFailed : en.epay_label_error_paymentFailed;
    vm.invalidBankDetailsType = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_invalidBankDtlDesc : en.epay_label_error_invalidBankDtlDesc;
    vm.invalidBankDetail = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_invalidBankDtl : en.epay_label_error_invalidBankDtl;
    vm.promoNotAvailable = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_promo : en.epay_label_error_promo;
    //PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment    
    vm.internalRecordLocked = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_recordLocked : en.epay_label_error_recordLocked;
    vm.noTransactionProcess = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_transaction : en.epay_label_error_transaction;
    vm.transactionDesc1 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc1 : en.epay_label_transactionDesc1;
    vm.transactionDesc2 =  $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc2 : en.epay_label_transactionDesc2;
    vm.transactionDesc3 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc3 : en.epay_label_transactionDesc3;
    vm.transactionDesc4 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc4 : en.epay_label_transactionDesc4;
    vm.transactionDesc5 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc5 : en.epay_label_transactionDesc5;
    vm.transactionDesc6 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc6 : en.epay_label_transactionDesc6;
    vm.transactionDesc7 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc7 : en.epay_label_transactionDesc7;
    vm.transactionDesc8 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc8 : en.epay_label_transactionDesc8;
    vm.transactionDesc9 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc9 : en.epay_label_transactionDesc9;
    vm.transactionDesc10 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc10 : en.epay_label_transactionDesc10;
    vm.transactionDesc11 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc11 : en.epay_label_transactionDesc11;
    vm.errorEpayCreate = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_epayCreate : en.epay_label_error_epayCreate;
    vm.paymentDesc1 = $rootScope.selectedLanguage == 'es' ? es.epay_label_paymentDesc1 : en.epay_label_paymentDesc1;
    vm.paymentDesc8 = $rootScope.selectedLanguage == 'es' ? es.epay_label_paymentDesc8 : en.epay_label_paymentDesc8;
    vm.paymentDesc9 = $rootScope.selectedLanguage == 'es' ? es.epay_label_paymentDesc9 : en.epay_label_paymentDesc9;
    vm.epaySuccess = $rootScope.selectedLanguage == 'es' ? es.epay_label_epaySuccess : en.epay_label_epaySuccess;
    /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
    vm.valAutoPay = false;
    vm.cashPaymentSuccess = false;
    /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
    
    vm.employeeId;
    vm.epayAccount = {
      customerPassword: vm.account.password,
      customerUsername: vm.account.username,
      customerFirstName: null,
      customerLastName: null,
      customerPassCreatQuestion: vm.account.password,
      customerPassCreatQuestionAnswer: vm.securityQuestion,
      customerEmailAddress: "",
      addToEmailList: "0"
    };
    vm.customerFind;
    vm.invalidEpayCredit = false;
    vm.invalidAutoCredit = false;
    vm.cashPaymentAmount;
    vm.noCashPaymentAmount = false;
    vm.initialPaymentStatus = false;
    vm.initialPaymentMessage;
    vm.paymentErrorCode;
    vm.initialPaymentErrorClass = "";
    vm.autoPaySuccess=false;
    vm.autoPayStatus;
    vm.autoPayMessage;
    vm.autoPayClass;
    vm.copyPaymentInfo;
    vm.pinAttempts = 0;
    vm.employeePinError;
    vm.changePaymentType = false;
    vm.unmannedAutoPayDo = false;
    vm.initialPayWithAutoPay = false;
    vm.autoPayDate = "";
    vm.changeAutoPaymentMode = false;
    vm.invalidBankDetailCount = 0;
    vm.showPromoPayment = false;
    vm.promoPaymentCheckboxValue = false;
    vm.selectedPromoOption = undefined;
    vm.promoOptions = [];
    vm.associatePassFail = false;
    vm.invalidPassPhrase = false;
    vm.invalidPromoPayment = false;
    vm.empPassPhrase;
    vm.errorMessage = '';
    vm.showPromoOptions = false;
    vm.promoSelected;
    vm.promoPaymentOfferSelected = false;
    vm.promoValue = [];
    vm.promoMinValue;
    vm.zeroDollarPromoInitialPayment = false;
    vm.initialPaymentSetUp = false;
    
    //VAN-616 - VAN - Low Down Initial Payment
    vm.promoCode;
	vm.promoDesc;
	vm.promoAmount;
	
    // <!-- Start VAN-3023 -->
    vm.resetAccountDetails = resetAccountDetails;
    // <!-- Start VAN-3023 -->

      // Controller functions
    vm.showModal = showModal;
    vm.navigateTo = navigateTo;
    vm.validateUsername = validateUsername;
    vm.validatePassword = validatePassword;
    vm.findCardTypeInitialPayment = findCardTypeInitialPayment;
    vm.findCardTypeEpayAutoCredit = findCardTypeEpayAutoCredit;
    vm.verifyPassword = verifyPassword;
    vm.createEpayAccount = createEpayAccount;
    vm.epayLogin = epayLogin;
    vm.calculateChange = calculateChange;
    vm.submitPayment = submitPayment;
    vm.processCashPayment = processCashPayment;
    vm.setEmployeeId = setEmployeeId;
    vm.processCreditPayment = processCreditPayment;
    vm.getCustomerData = getCustomerData;
    vm.addDecimalToInitialPayment = addDecimalToInitialPayment;
    vm.submitAutoPay = submitAutoPay;
    vm.validateEpayForm = validateEpayForm;
    vm.validateEpayCredit = validateEpayCredit;
    vm.validateEpayBankingAccount = validateEpayBankingAccount;
    vm.validateAutoCredit = validateAutoCredit;
    vm.validateAutoPay = validateAutoPay;
    vm.validateBankAccount = validateBankAccount;
    vm.validateAutoCreditDtl = validateAutoCreditDtl;
    vm.validateInitialPayment = validateInitialPayment;
    vm.validateBillingInfo = validateBillingInfo;
    vm.resetAutopayValidation = resetAutopayValidation;
    vm.resetInitialPayValidation = resetInitialPayValidation;
    vm.getCreditCardDate = getCreditCardDate;
    vm.sameCardAsInitial = sameCardAsInitial;
    vm.checkCashPaymentAmount = checkCashPaymentAmount;
    vm.ccDateAddSlash = ccDateAddSlash;
    vm.ccDateKeyDown = ccDateKeyDown;
    vm.validateAssociatePasswordCash = validateAssociatePasswordCash;
    vm.isStoreManned = SharedDataSvc.globalVars.storeSetup.isManned;
    vm.setDirectAutoPayCreditCard = setDirectAutoPayCreditCard;
    vm.getDirectAutoPayCreditCardDate = getDirectAutoPayCreditCardDate;
    vm.submitSkipAutoPay = submitSkipAutoPay;
    vm.initialBankAccount = initialBankAccount;
    vm.promoPaymentSelectOperation = promoPaymentSelectOperation;
    vm.verifyEmployeePassPhrase = verifyEmployeePassPhrase;
    vm.verifyEmployeePassWord = verifyEmployeePassWord;
    vm.updateInitialPayment = updateInitialPayment;
    vm.resetPromoPayment = resetPromoPayment;
    vm.getCardTypeDtl = getCardTypeDtl;
    vm.clearPinData = clearPinData;
    vm.setUpInitialPaymentAmount = setUpInitialPaymentAmount;
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    vm.autopayWithEcomplish = false;
    $scope.newProcess = false;
    vm.processAutopay = processAutopay;
    vm.saveAutopay = saveAutopay;
    vm.autopayForCash = vm.autopayForCash;
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
    vm.findData = findData;//VAN-877-Submit payment button is not showing on AWP
    /* Commented these changes as part of VAN-670 - Consent Verbiage*/
    /*VAN-616 - VAN - Low Down Initial Payment - Starts
    vm.paymentInitial = paymentInitial;
    VAN-616 - VAN - Low Down Initial Payment - Ends*/
    vm.selectedPromo;//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
    $scope.navigateTo = navigateTo;
    //Commented as part of VAN-3330
    //VAN-1395-eComplish iFrame implementation - Starts
	/*vm.tokenizedPAN = "";
	vm.data = "";
	vm.enableBank = "";*/
	vm.lastFourDigit = "";
	/*vm.tokenGenerated = false;
	vm.eventInitialize = false;
	vm.ecomplishRequest = ecomplishRequest;
	vm.invokeEcomIframe = invokeEcomIframe;
	vm.postToPayPage = postToPayPage;
	vm.resHandler = resHandler;
	vm.payPageCreditCard = "";
	vm.payPageBankAcc = "";*/
	var iFrame = "";
	/*var ecomSrcURL = "";
	vm.htStoreID = "";*/
	vm.copyPayPageInfo = false;
	vm.autopayIframe = false;
	/*vm.resHandlerInitialPay = resHandlerInitialPay;
	vm.resHandlerAutopay = resHandlerAutopay;*/
	vm.autoPayToken = autoPayToken;
	//VAN-1395-eComplish iFrame implementation - Ends
    
    //VAN-2012-VAN-AutoPay Issue - Starts
    vm.processCashAndAutoPay = processCashAndAutoPay;
    vm.processCash = processCash;
    //VAN-2012-VAN-AutoPay Issue - Ends
    
    vm.zeroPromo = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days

    //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    vm.configIframe = configIframe;
    vm.invokeVantivIframe = invokeVantivIframe;
    vm.getCardType = getCardType;
    vm.payPageRegID;
    vm.showIframe = true;
    vm.paymentId = "";
    vm.isPayRegIdGenerated = false;
    vm.props = "";
    var payframeClient =  "";
    var iFrame = "";
    var payPageID = "";
    var merchantID = "";
    vm.cardType = "";
    //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
	
    init();

    function init() {
      $rootScope.hideDashboardButton = true;
      $rootScope.hideDashboardAgreementButton = false;
      $rootScope.hideTrainingLibButton = false;
      $rootScope.isHybrid = $cookies.getObject('isHybrid');
      $rootScope.isMannedMode = $cookies.getObject('isMannedMode');
      $scope.isSpanishStore = $cookies.getObject('isSpanishStore');

      $log.log('[EPay.init] Init epay controller');
      $log.log('[EPay.init] initialPaymentPaid', vm.initialPaymentPaid);

      // Remove after auto is fully setup
      // vm.account.loggedIntoEpay = true;
      // vm.initialPaymentPaid = true;

      getCardTypeDtl();

      
      getStoreInfoFactory.getData()
        .then(function(response) {
          $log.log('[EPay.init] STORE INFO', response);
          vm.storeInfo = response;
          $rootScope.invoiceMethod = response.invoiceMethod;
          vm.initialPaymentSetUp = response.lowInitialPaymentEnabled;
          enableSurvey();
        });

      
      updateEngagementItemFactory.getData(vm.engagementId)
        .then(function (response) {
          var order,
              findData = { 'firstName': null, 'lastName': null, 'phone': null };

          vm.engagement = response;

          if(vm.engagement.orderContent) {
            order = getOrder();
          }

          $log.log('[EPay.init] engagement', vm.engagement);
          $log.log('[EPay.init] order', order);
          
          showModal('Processing', 'processing-modal');
          
          customerDataFactory.findByEngagementId(vm.engagementId)
            .then(function(response) {
              $log.log('[EPay.init] customerDataFactory.findByEngagementId response: ', response);
              vm.customer = response.customer;
              vm.diffDays;
              vm.engagementPromo = JSON.parse(response.promoSelectedAtAgreement);//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP 
//        	  VAN-2372 Initial Payment Breakdown details show starts
        	  if(response.payToDate != null) {
    			  var currentdate = moment(new Date());
    			  var nextDuedate = moment(response.payToDate);
    			  vm.diffDays = nextDuedate.diff(currentdate, 'days')+1;
    			  void 0
    			  if(vm.diffDays > 15) {
    				  vm.showBreakDown = true;
    			  }else {
    				  vm.showBreakDown = false;
    			  }
    		  }
//        	  VAN-2372 Initial Payment Breakdown details show ends
//    		  VAN-2372 Initial Payment Breakdown details starts
               if(vm.diffDays > 15 || response.totalDueAmount === null || vm.engagementPromo.desc === '') {//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
//         		  VAN-2372 Initial Payment Breakdown details ends
            	  if(response.payToDate != null){
            		  var selectedDate = moment(response.payToDate).format('YYYYMMDD');}
            	  else{
            		  selectedDate = moment(response.nextPaymentDate).format('YYYYMMDD');
            	  }

            	  var calcPaymentData= {
            			  "id": response.customerHTStoreId,
            			  "hoCustId": response.customerHTId,
            			  "paymentToDate": selectedDate,
            			  "clubSwitch": response.clubSelection,
            			  "agreementNumber": response.agreementHTId,
            			  "waiverSwitch": response.waiverSelection,
            			  "customerId": response.customer.customerId ,
            			  "customerEngagementId": response.engagementId
            	  };
            	  paymentFactory.calculatePayment(calcPaymentData)
            	  .then(function(response) {
            		  $log.log('[EPay.init] paymentFactory.calculatePayment response: ', response);
//            		  VAN-2372 Initial Payment Breakdown details starts
            		 if (vm.diffDays > 15 || vm.engagementPromo.desc === '') {//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
            		  vm.rentalFee = addDecimalToInitialPayment(response.raAmtNoLdwInclTax);
            		  vm.liabilityWaiverAmt = addDecimalToInitialPayment(response.ldwAmtInclTax);
            		  vm.benefitsPlusAmt = addDecimalToInitialPayment(response.clubAmtInclTax);
            		  vm.totalInitialAmt = addDecimalToInitialPayment(response.totalPmtAmtInclTax);
            		 }
//            		  VAN-2372 Initial Payment Breakdown details ends
            		  vm.findData();
            	  }).catch(function(response) {
            		  $log.log('[EPay.init] could not get the data from calculatePayment call', response);
            	  });
              	}
              else{
            	  vm.findData();
              }
              //VAN-877-Submit payment button is not showing on AWP - Ends
            }).catch(function(response) {
              $log.log('[EPay.init] could not get customer data', response);
            });
        }).catch(function(response) {
          $log.log('[EPay.init] could not get customer data', response);
        });
   }
    
    //VAN-877-Submit payment button is not showing on AWP - Starts
    function findData(){
    	var findData = { 'firstName': null, 'lastName': null, 'phone': null };
    	// Set customer values
        vm.account.name = vm.customer.fName + ' ' + vm.customer.lName;
        vm.account.initialPayment.creditCard.billingEmail = vm.customer.email;
        vm.account.initialPayment.creditCard.billingPhone = vm.customer.phNum;

        vm.customer.customerFirstName = findData.firstName = vm.customer.fName;
        vm.customer.customerLastName = findData.lastName = vm.customer.lName;
        vm.customer.customerEmailAddress = vm.customer.email;
        findData.phone = vm.customer.phNum;

        applicationService.getApplication(findData)
          .then(function (response) {
            modalService.dismiss();
            void 0;

            if (response.VanEngagement) {
              vm.customerFind = response;
              vm.initialPayment = checkForInitialDecimal(response.VanEngagement.totalDueAmount);
              if(!vm.storeInfo.lowInitialPaymentEnabled){//VAN-670 - Consent Verbiage
              SharedDataSvc.initialPaymentValue = vm.initialPayment;}
              //Start - VAN-616 - Low Down Initial Payment
//              vm.formattedInitialPayment = addDecimalToInitialPayment(vm.initialPayment);
              if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
              	/* Commented as part of VAN-670 - Consent Verbiage*/
              	//vm.formattedInitialPayment = addDecimalToInitialPayment(vm.storeInfo.noCalcPromos[0].payment);
              	//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
              	if(vm.customerFind.VanEngagement != undefined && vm.customerFind.VanEngagement.promoSelectedAtAgreement != null)
              		vm.selectedPromo = JSON.parse(vm.customerFind.VanEngagement.promoSelectedAtAgreement);//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
//              	vm.formattedInitialPayment = vm.selectedPromo.payment;//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
              	vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
              	/*if(vm.formattedInitialPayment == '49'){*/
                //if(vm.selectedPromo.desc.includes('AutoPay Required')){//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
              	if(vm.selectedPromo.desc.indexOf('AutoPay Required')!=-1){ //VAN-3732 : VAN - Unable to login in ANOW application in  IE browser
              		vm.account.paymentInitialValSelected ='AutoPayRequired';
              	}//VAN-2841-AWP-Value City $1 Promo
              	//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
              	//else if(vm.selectedPromo.desc.includes('without AutoPay')  || vm.selectedPromo.code === 'Q' || vm.selectedPromo.desc === ""){//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
              	else if(vm.selectedPromo.desc.indexOf('without AutoPay')!=-1  || vm.selectedPromo.code === 'Q' || vm.selectedPromo.desc === ""){//VAN-3732 : VAN - Unable to login in ANOW application in  IE browser
              		/*if(vm.formattedInitialPayment == '99'){*/
              			vm.account.paymentInitialValSelected ='WithoutAutoPay';
              	}
              	//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
              	//else if(vm.selectedPromo.desc.includes('$0 START PIF') || vm.selectedPromo.code === 'D'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
              	else if(vm.selectedPromo.desc.indexOf('$0 START PIF')!=-1 || vm.selectedPromo.code === 'D'){//VAN-3732 : VAN - Unable to login in ANOW application in  IE browser
              		vm.account.paymentInitialValSelected = 'PIFPromo';
              		if(vm.formattedInitialPayment === "0.0" || vm.customerFind.VanEngagement.totalDueAmount === "0"){
              			vm.zeroPromo = true;
              			vm.account.paymentCredit = 'cash';
              			vm.cashPaymentAmount = '0';
              			vm.changeDue = 0;
              			SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
              			vm.zeroDollarPromoInitialPayment = true;   
              		}
              	}//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
              	//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
              	if(vm.selectedPromo.desc === ''){
              		vm.showBreakDown = true;
              	}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
              	vm.promoPaymentOfferSelected=true;
              }
              else{
                  vm.formattedInitialPayment = addDecimalToInitialPayment(vm.initialPayment);
              }
              //End - VAN-616 - Low Down Initial Payment
              SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
              var orderContent = getOrder();
              //VAN-672 - Low Down Initial Payment should be displayed on RA
              if(SharedDataSvc.initialPaymentAmount != undefined && !vm.storeInfo.lowInitialPaymentEnabled){//VAN-670 - Consent Verbiage
              var initialPaymentAmount = Number(addDecimalToInitialPayment(SharedDataSvc.initialPaymentValue));}
              vm.autoPayDate = response.VanEngagement.nextPaymentDate.substring(6);
              void 0;
              //Storing eligible promos in sharedData services
              SharedDataSvc.eligiblePromos = JSON.parse(response.VanEngagement.eligiblePromos);
              if(SharedDataSvc.eligiblePromos !== null && SharedDataSvc.eligiblePromos.length > 0 && $rootScope.isMannedMode) {
                vm.showPromoPayment = true;
                _.forEach(SharedDataSvc.eligiblePromos, function(obj, index) {
                  vm.promoOptions[index] = {
                    id: index,
                    options: obj.code + " - " + obj.desc
                  };
                });
                void 0;
              } else {
                vm.showPromoPayment = false;
              }

              //VAN-1395-eComplish iFrame implementation - Starts
              if(vm.account.paymentCredit === 'credit') {
//            	  vm.enableBank = "N";
//            	  ecomplishRequest();
            	  configIframe();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
            	  
              }
              //VAN-1395-eComplish iFrame implementation - Ends
            }
          }, function(response) {
            modalService.dismiss();
            void 0;

            if (response.status == 303) {
              vm.customerFind = response.data.errorData;
              vm.initialPayment = checkForInitialDecimal(vm.customerFind.VanEngagement.totalDueAmount);
              vm.formattedInitialPayment = addDecimalToInitialPayment(vm.initialPayment);
              SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
            }
          });

        $log.log('[EPay.init] customer', vm.customer);
        // getCustomerData();
}
    //VAN-877-Submit payment button is not showing on AWP - Ends
    
    function getCardTypeVal(val){
    	void 0;
    	var cardNumEntered = val.cc1+val.cc2+val.cc3+val.cc4;
    	void 0;
    	var cardType = SharedDataSvc.detectCardType(cardNumEntered);
    	void 0;	
    	
    	if(cardType){
    		var cardValues = {
    				mastercard:"1",
    				visa:"2",
    				amex:"3",
    				discover:"4",
    				diners:"5"	
    		};

    		void 0;
    		
    		return cardValues[cardType];
    	}
    	else{
    		return false;
    	}
    }
    
    function findCardTypeInitialPayment(val){
    	vm.account.initialPayment.creditCard.cardType = getCardTypeVal(val)?getCardTypeVal(val):"";
    }
    
    function findCardTypeEpayAutoCredit(val){
    	vm.account.autoPayment.creditCard.cardType = getCardTypeVal(val)?getCardTypeVal(val):"";
    }

    function navigateTo(target) {
      modalService.dismiss();
      $location.path(target);
    };
    /* Start VAN-3023 */
    function resetAccountDetails()
    {
        vm.account.username = '';
        vm.account.password = '';
        vm.account.passwordVerify='';
        vm.securityQuestion='';
        vm.account.securityAnswer='';
        vm.resetAccountDetail = false;
        $scope.ePayFormSubmitted = false;
        vm.message = '';
        vm.message2 = '';
        vm.statusMessage = false;
        vm.securityQuestion = $rootScope.selectedLanguage == 'es' ? vm.account.securityQuestion[0].name.es : vm.account.securityQuestion[0].name.en;
    }
    /* End VAN-3023 */
    function validateUsername(userName) {
      if(userName) {
    	  vm.statusMessage = false;//VAN-858 - Quad Weekly Payment Frequency
        if(userName.match(vm.userNamePattern)) {
          vm.validUsername = true;
          $scope.ePayLogin.ePayUser.$setValidity('ePayUser', true);
        } else {
          vm.validUsername = false;
          $scope.ePayLogin.ePayUser.$setValidity('ePayUser', false);
        }
      }
    }
    
    /* Validation for credit/debit card expiry date. It should not be a past year or month */
    function isPastMonthYear(date){
	    var dt = new Date();
      	var crrMonth = dt.getMonth()+1;
      	var crrYear = dt.getFullYear().toString().substring(2);
      	var inputMonth = date.substring(0,2);
      	var inputYear = date.substring(2,4);
      	
      	if( ((inputMonth < crrMonth) && (inputYear <= crrYear)) || (inputYear < crrYear) ){
      		return false;
      	}else{
      		return true;
      	}	
    }

    function ccDateKeyDown(event, name) {
      //console.log('KEY DOWN', event);
      var date = vm.account.initialPayment.creditCard.expDate;
      var rep = vm.account.initialPayment.creditCard.expDate.slice(0, 2);
     
      if(name === 'autoPayment') {
        date = vm.account.autoPayment.creditCard.expDate;
        rep = vm.account.autoPayment.creditCard.expDate.slice(0, 2);
     
        if(date.length == 4){
        	var result = isPastMonthYear(date);
        	$scope.ePayAutoCredit.ccExperation.$setValidity('ccExperation', result);
        } 
      }else{
      	if(date.length == 4){
      		var result = isPastMonthYear(date);
      		$scope.ePayCreditCard.ccExperation.$setValidity('ccExperation', result);
      	}
      }

      // if(date.length === 2 && event.keyCode !== 8 || date.length === 2 && event.keyCode !== 46) {
      //   console.log('Add slash');
      //   if(date.slice(-1) !== '/') {
      //     if(name === 'autoPayment') {
      //       vm.account.autoPayment.creditCard.expDate = date + '/';
      //     } else {
      //       vm.account.initialPayment.creditCard.expDate = date + '/';
      //     }
      //   }
      // }

      if(event.keyCode === 8 || event.keyCode === 46) {
        vm.account.initialPayment.creditCard.expDate = date.replace('/', '');
        vm.account.autoPayment.creditCard.expDate = date.replace('/', '');
        if(date.indexOf('/') < 0 && date.length === 3) {
          if(date.slice(-1) !== '/') {
            if(name === 'autoPayment') {
              // vm.account.autoPayment.creditCard.expDate = date + '/';
              vm.account.autoPayment.creditCard.expDate = vm.account.autoPayment.creditCard.expDate.replace(rep, rep+'/');
            } else {
              // vm.account.initialPayment.creditCard.expDate = date + '/';
              vm.account.initialPayment.creditCard.expDate = vm.account.initialPayment.creditCard.expDate.replace(rep, rep+'/');
            }
          }
        }
      } else {
        if(date.indexOf('/') < 0 && date.length >= 2) {
          if(date.slice(-1) !== '/') {
            if(name === 'autoPayment') {
              // vm.account.autoPayment.creditCard.expDate = date + '/';
              vm.account.autoPayment.creditCard.expDate = vm.account.autoPayment.creditCard.expDate.replace(rep, rep+'/');
            } else {
              // vm.account.initialPayment.creditCard.expDate = date + '/';
              vm.account.initialPayment.creditCard.expDate = vm.account.initialPayment.creditCard.expDate.replace(rep, rep+'/');
            }
          }
        }
      }
    }

    function ccDateAddSlash(val, name) {
      // var date = vm.account.initialPayment.creditCard.expDate;

      // if(name === 'autoPayment') {
      //   date = vm.account.autoPayment.creditCard.expDate;
      // }

      // console.log('CC DATE', date);
      // if(date.indexOf('/') < 0) {
      //   console.log('CC DATE len', date.length);
      //   if(date.length === 2) {
      //     console.log('Add slash');
      //     vm.account.initialPayment.creditCard.expDate = date + '/';
      //   }
      // }
    }

    function validateCCDate(date) {
      if(date.indexOf('/') < 0) {
        void 0;
        if(date.length === 4) {
          void 0;
        } else {
          void 0;
        }
      } else {
        if(date.length === 5) {
          void 0;
        } else {
          void 0;
        }
      }

    }

    function validatePassword(password) {
      if(password) {
        /* Check for length, one uppercase char and one lowercase char to pass /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7}$/
           */


        if(password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/)) {
        	if( password.match(/^[a-z0-9]+$/i) ){
        		vm.validPass = true;
        		$scope.ePayLogin.ePayPass.$setValidity('ePayPass', true);
        	}else{
        		vm.validPass = false;
        		$scope.ePayLogin.ePayPass.$setValidity('ePayPass', false);
        	}


        } else {
          vm.validPass = false;
          $scope.ePayLogin.ePayPass.$setValidity('ePayPass', false);
        }
      }
    }

    function verifyPassword(password) {
      vm.verifyPass = password === vm.account.password ? true : false;
      void 0;
      if(vm.verifyPass) {
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', true);
      } else {
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', false);
      }
    }

    function getCustomerData() {
      var areaCode = vm.customer.customerPhoneNum.substring(0, 3);
      var phone = vm.customer.customerPhoneNum.substring(3, 10);
      var data = { 'firstName' : vm.customer.customerFirstName,
                   'lastName' : vm.customer.customerLastName,
                   'phoneNumber': phone,
                   'areaCode': areaCode
                 };
      void 0;
      getSpecificApplication.findByNameAndPhone(data)
        .then(function(response) {
          $log.log('Successfully returned customer data', response);
        }, function(response){
          $log.log('Error getting customer data', response);
        });
    }

    function showModal(name, windowClass, size) {
      windowClass = windowClass || '';
      size =  size || '';
      vm.modal = modalService.open({
        templateUrl: '../../../partials/modal_' + name + '.html',
        backdrop: 'static',
        windowClass: windowClass,
        size: size,
        scope: $scope
      });
    }

    function setEpayAccount() {
      vm.epayAccount.customerPassword = vm.account.password;
      vm.epayAccount.customerUsername = vm.account.username;
      vm.epayAccount.customerFirstName = vm.customer.customerFirstName;
      vm.epayAccount.customerLastName = vm.customer.customerLastName;
      vm.epayAccount.customerPassCreatQuestion = vm.securityQuestion;
      vm.epayAccount.customerPassCreatQuestionAnswer = vm.account.securityAnswer;
      vm.epayAccount.customerEmailAddress = vm.customer.customerEmailAddress;
    }

    function setEpayLogin() {
      vm.login.customerUsername = vm.account.username;
      vm.login.customerPassword = vm.account.password;
      vm.login.engagementId = vm.engagementId;
    }

    function getOrder() {
      var order = vm.engagement.orderContent ? vm.engagement.orderContent : null;
      return JSON.parse(SharedDataSvc.base64.decode(order));
    }

    function validateCredit() {
      var ccn = vm.account.initialPayment.creditCard.cc1 + vm.account.initialPayment.creditCard.cc2 + vm.account.initialPayment.creditCard.cc3 + vm.account.initialPayment.creditCard.cc4;
      if(!ccn || ccn.length < 16) {
        $scope.ePayCreditCard.cc1.$setValidity('cc1', false);
        $scope.ePayCreditCard.cc2.$setValidity('cc2', false);
        $scope.ePayCreditCard.cc3.$setValidity('cc3', false);
        $scope.ePayCreditCard.cc4.$setValidity('cc4', false);
        return false;
      } else {
        return true;
      }
    }

    function validateAutoCreditDtl() {
      var ccn = vm.account.autoPayment.creditCard.cc1 + vm.account.autoPayment.creditCard.cc2 + vm.account.autoPayment.creditCard.cc3 + vm.account.autoPayment.creditCard.cc4;
      if(!ccn || ccn.length < 16) {
        $scope.ePayAutoCredit.cc1.$setValidity('cc1', false);
        $scope.ePayAutoCredit.cc2.$setValidity('cc2', false);
        $scope.ePayAutoCredit.cc3.$setValidity('cc3', false);
        $scope.ePayAutoCredit.cc4.$setValidity('cc4', false);
        return false;
      } else if(ccn!= '' && ccn.length == 16 && ($scope.ePayAutoCredit.cc1.$invalid || 
        $scope.ePayAutoCredit.cc2.$invalid || $scope.ePayAutoCredit.cc3.$invalid || 
        $scope.ePayAutoCredit.cc4.$invalid)){
          vm.invalidAutoCredit = false;
          $scope.ePayAutoCredit.cc1.$setValidity('cc1', true);
          $scope.ePayAutoCredit.cc2.$setValidity('cc2', true);
          $scope.ePayAutoCredit.cc3.$setValidity('cc3', true);
          $scope.ePayAutoCredit.cc4.$setValidity('cc4', true);
        return false;
      } else {
        return true;
      }
    }

    function validateBillingInfo() {
      void 0;
      $scope.submittedBillingInfo = true;

      if($scope.billingInfo.$valid) {
        void 0;
      } else {
        void 0;
      }
    }

    function validateEpayForm() {
    /* Start VAN-3023 */
      vm.resetAccountDetail = true;
    /* End VAN-3023 */
        $scope.ePayFormSubmitted = true;

      if(vm.account.password !== vm.account.passwordVerify) {
        // console.log('passwords do not match');
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', false);
      }

      if(vm.account.newOrExistingAcct) {
        if($scope.ePayLogin.$valid) {
          vm.createEpayAccount(vm.epayAccount);
        }
      } else {
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', true);
        $scope.ePayLogin.securityAnswer.$setValidity('securityAnswer', true);
      }

      if(!vm.account.newOrExistingAcct && vm.account.username.$valid && vm.account.password.$valid) {
        epayLogin(vm.login);
      }
    }

    function validateEpayCredit() {
      $scope.ePayCreditCard.cc1.$setValidity('cc1', true);
      $scope.ePayCreditCard.cc2.$setValidity('cc2', true);
      $scope.ePayCreditCard.cc3.$setValidity('cc3', true);
      $scope.ePayCreditCard.cc4.$setValidity('cc4', true);

      if($scope.ePayCreditCard.$valid) {
        submitPayment();
      } else {
        if(!validateCredit()) {
          vm.invalidEpayCredit = true;
        }
      }
    }

    function validateEpayBankingAccount () {
      if($scope.ePayCreditCard.$valid) {
    	  //submitPayment();//Commented as part of VAN-1395
//    	  postToPayPage();//VAN-1395-eComplish iFrame implementation
    	//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
			if(vm.account.paymentCredit === 'credit'){
				invokeVantivIframe.getPaypageRegId();
			}else if(vm.account.paymentCredit === 'bank'){
				vm.isPayRegIdGenerated = false;
				vm.paymentId =  "";
				SharedDataSvc.autoPaymentData.paymentTokenId = "";
				submitPayment();
			}
		}//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
      }
    
    /* Commented these changes as part of VAN-670 - Consent Verbiage*/
    /*  VAN-616 - VAN - Low Down Initial Payment - Starts
    function paymentInitial(){
    	showModal('paymentInitial');
    }
    VAN-616 - VAN - Low Down Initial Payment - Ends*/
    
    function validateInitialPayment() {
      vm.invalidEpayCredit = false;
      $scope.initialPaymentOCHSubmitted = true;
      $scope.epayCreditSubmitted = true;
      //Commented as part of VAN-1395
      /*if(vm.account.paymentCredit === 'credit') {
        validateEpayCredit();
      } else if(vm.account.paymentCredit === 'bank') {
        validateEpayBankingAccount();
      }*/ 
      //Commented as part of VAN-1395
      //VAN-1395-eComplish iFrame implementation - Starts
      if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
    	  validateEpayBankingAccount();
      }//VAN-1395-eComplish iFrame implementation - Ends
      else {
    	  /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
    	  /*VAN 762 - AWP - Submit payment is not working when 49 - cash and ANOW promo is selected*/
    	  if(vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && !vm.promoPaymentCheckboxValue && !vm.valAutoPay){
    		  vm.validateAutoPay();
    	  }else if(vm.cashPaymentAmount) {
    		  if(!vm.noCashPaymentAmount && !vm.cashPaymentToLow) {
    			  submitPayment();
    		  }
    	  } else {
    		  vm.noCashPaymentAmount = true;
    	  }
      }
      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
    }

    function resetInitialPayValidation() {
      $scope.ePayCreditCard.$setPristine();
      vm.invalidEpayCredit = false;
      $scope.initialPaymentOCHSubmitted = false;
      $scope.epayCreditSubmitted = false;
      vm.cashPaymentToLow = false;
      vm.noCashPaymentAmount = false;
      vm.account.initialPayment.accountType = undefined;
      vm.showIframe = false;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }

    function resetAutopayValidation() {
      $scope.ePayAutoCredit.$setPristine();
      $scope.bankAccount.$setPristine();
      $scope.billingInfo.$setPristine();
      $scope.bankAccountSubmitted = false;
      $scope.autoCreditSubmitted = false;
      $scope.submittedBillingInfo = false;
      vm.invalidAutoCredit = false;
      //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
      vm.autoPayStatus = false;
      vm.account.autoPayment.routingNumber = "";
      vm.account.autoPayment.accountNumber = "";
      vm.account.autoPayment.accountType = "";
      if(iFrame.parentNode != null){
    	  var iframeVal = iFrame;
    	  iframeVal.parentNode.removeChild(iframeVal);
      }
      if(vm.account.paymentInitialValSelected != 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.initialPaymentPaid == true){
    	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';
      }
      if(vm.account.autoPayment.existingBankOrCredit){
//  	  vm.enableBank = "N";
//  	  ecomplishRequest();
    	  vm.showIframe = true;
    	  configIframe();
      }else{
//  	  vm.enableBank = "Y";
//  	  ecomplishRequest();
    	  vm.isPayRegIdGenerated = false;
    	  vm.paymentId =  "";
    	  SharedDataSvc.autoPaymentData.paymentTokenId = "";
    	  vm.showIframe = false;
      }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
      //VAN-1395-eComplish iFrame implementation - Ends  
    }

    function validateAutoBank() {
      if($scope.ePayAutoCredit.$valid) {
        void 0;
      }
    }

    function validateAutoCredit() {
      $scope.autoCreditSubmitted = true;
      validateBillingInfo();
      //Commented as part of VAN-1395
      /*if($scope.ePayAutoCredit.$valid && $scope.billingInfo.$valid) {
        console.log('Auto credit passes');
        submitAutoPay(); 
      }*///Commented as part of VAN-1395
      //VAN-1395-eComplish iFrame implementation - Starts
      if($scope.billingInfo.$valid) {
//    	  postToPayPage();
    	  invokeVantivIframe.getPaypageRegId();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      }//VAN-1395-eComplish iFrame implementation - Ends
      else{
    	  if(!validateAutoCreditDtl()) {
          vm.invalidAutoCredit = true;
        }
        void 0;
      }
    }

    function validateBankAccount() {
      $scope.bankAccountSubmitted = true;
      validateBillingInfo();
      void 0;
      if(vm.account.autoPayment.accountType !== undefined) {
        vm.noAccountType = true;
      }else{
        vm.noAccountType = false;
      }
      //Commented as part of VAN-1395 - Starts
      /*if($scope.bankAccount.$valid && vm.noAccountType && $scope.billingInfo.$valid) {
       submitAutoPay();
      }*///Commented as part of VAN-1395 - Ends
      //VAN-1395-eComplish iFrame implementation - Starts
      if( $scope.billingInfo.$valid && $scope.bankAccount.$valid && vm.noAccountType) {//VAN-3482-AWP - Application throwing 700 error if we do not select any Account Type option in Bank Auto Pay section
//    	  postToPayPage();
    	  submitAutoPay();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      }//VAN-1395-eComplish iFrame implementation - Ends
    }

    function validateAutoPay(val) {
    	if(val) {
        vm.changeAutoPaymentMode = true;
      }
      $scope.autoCreditSubmitted = true;
      //VAN-1395-eComplish iFrame implementation - Starts
      if(vm.copyPayPageInfo){
    	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'Y';//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    	  submitAutoPay();
      }
      else{//VAN-1395-eComplish iFrame implementation - Ends
    	  if(vm.account.autoPayment.existingBankOrCredit) {
    		  vm.enableBank = "N";//VAN-1395-eComplish iFrame implementation
    		  vm.validateAutoCredit();
    	  } else {
    		  vm.enableBank = "Y";//VAN-1395-eComplish iFrame implementation
    		  vm.validateBankAccount();
    	  }
      } 
    }

    function validateAssociatePasswordCash(pass) {
      var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
      if(auth === pass) {
        vm.employeePinError = false;
        vm.employeeId = '';
        showModal('cashPaymentEmployeePin');
      } else {
        vm.invalidAssociatePass = true;
      }
    }

    function createEpayAccount(epayData) {
      setEpayAccount();
      // console.log('LOGIN FORM', $scope.ePayLogin);
      if($scope.ePayLogin.$valid) {
        $scope.viewLoading = true;
        showModal('Processing', 'processing-modal');
        paymentFactory.createEpayAccount(epayData)
          .then(function(response){
            void 0;
            // Handle 400 error response
            if(response.status === 400) {
              $scope.viewLoading = false;
              vm.modal.close();
              vm.statusMessage = true;
              vm.alertClass = 'alert-danger';
              vm.message = response.data.status_details;
              vm.message2 = '';
            } else {
              $scope.viewLoading = false;
              modalService.dismiss();
              vm.statusMessage = true;
              vm.alertClass = 'alert-success';
              vm.message = 'epay_label_epaySuccess';
              vm.message2 = '';
              vm.account.newOrExistingAcct = false;
              vm.account.loggedIntoEpay = true;
              $log.log('[EPay.createEpayAccount] EPAY CREATED', response);
            }
          }, function(response){
            $scope.viewLoading = false;
            modalService.dismiss();
            // vm.message = response.data;
            // vm.showThis = response.data;
            vm.alertClass = 'alert-danger';
            vm.statusMessage = true;
            $log.log('[EPay.createEpayAccount] UNABLE TO CREATE EPAY ACCOUNT', response);
            if(response.data.errorData.status_code.indexOf('ENG-E2002-400') > -1) {
              vm.message = "epay_label_paymentDesc8";
              vm.message2 =  "epay_label_paymentDesc9";
              // vm.account.username + $translate.instant("");
            } else {message2
              vm.message = "epay_label_error_epayCreate";
              vm.message2 = '';
            }

          });
      }
    }

    function checkCashPaymentAmount() {
      if(vm.change || vm.change === 0) {
        if(vm.change < 0) {
          vm.cashPaymentToLow = true;
        } else {
          vm.cashPaymentToLow = false;
        }
      }
    }

    function calculateChange(paymentAmount) {
      vm.noCashPaymentAmount = false;
      if(paymentAmount.indexOf('.') < 0) {
        paymentAmount = paymentAmount + '.00';
      }
      var change = (Number(paymentAmount) - Number(vm.formattedInitialPayment));
      vm.change = change;
      vm.changeDue = change > 0 ? change.toFixed(2) : 0;
    }

    function setCashInitialPayment() {
      SharedDataSvc.cashInitialPayment.id = vm.engagement.customerHtStoreId;
      SharedDataSvc.cashInitialPayment.paymentToDate = vm.customerFind.VanEngagement.payToDate;
      SharedDataSvc.cashInitialPayment.agreementNumber = vm.engagement.agreementHtId;
      SharedDataSvc.cashInitialPayment.amtOwed = formatCash(vm.formattedInitialPayment);
      SharedDataSvc.cashInitialPayment.amtTendered = formatCash(vm.cashPaymentAmount);
      SharedDataSvc.cashInitialPayment.changeAmt = formatCash(vm.changeDue);
      SharedDataSvc.cashInitialPayment.customerEngagementId = vm.engagementId;
      //Commented as part of VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
      //Start: VAN-616 - Low Down Initial Payment
      /*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
    	  if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
	    	  vm.promoCode = vm.storeInfo.noCalcPromos[0].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[0].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[0].payment;
    	  }else{
    		  vm.promoCode = vm.storeInfo.noCalcPromos[1].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[1].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[1].payment;
    	  }
      } */
      //End: VAN-616 - Low Down Initial Payment
      if(vm.promoPaymentOfferSelected) {
        SharedDataSvc.cashInitialPayment.promotionDetails = {
          "agreemnentNumber": vm.engagement.agreementHtId,
          "customerId": vm.engagement.customerId.toString(),
          "engagementId": vm.engagementId,
          "promoCode": vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoDesc": vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoAmount": vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "totalDue": SharedDataSvc.initialPaymentValue, 
          "passPhrase": SharedDataSvc.paymentPassphraseUsed,
          "promoAmtUsed": formatCash(vm.formattedInitialPayment)
        }  
      }else {
      	if(SharedDataSvc.cashInitialPayment.promotionDetails !== undefined)
      		delete  SharedDataSvc.cashInitialPayment.promotionDetails;
      }

      //VAN-2012-VAN-AutoPay Issue - Starts
      SharedDataSvc.cashInitialPayment.customerLogin = vm.account.username;
      SharedDataSvc.cashInitialPayment.lastFourDigit =  vm.lastFourDigit;
      SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[0].agreementType = "Agreement";
      SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[0].monthlyAmount = getAgreementsMonthly();

      var clubStatus = typeof vm.customerFind.VanEngagement.clubStatus === 'number' ? vm.customerFind.VanEngagement.clubStatus : Number(vm.customerFind.VanEngagement.clubStatus);
      if(clubStatus === 1) {
    	  if(!SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1]) {
    		  var benefits = {'agreementNumber': undefined, 'agreementType': 'Benefits', 'monthlyAmount': undefined};
    		  benefits.agreementNumber = vm.customerFind.agreement.ClubMemberId;
    		  benefits.agreementType = "Benefits";
    		  benefits.monthlyAmount = "0.0";
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo.push(benefits);
    	  }else {
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1].agreementNumber = vm.customerFind.agreement.ClubMemberId;
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1].agreementType = "Benefits";
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1].monthlyAmount = "0.0";
    	  }
      }

      if(clubStatus !== 1) {
    	  if(SharedDataSvc.cashInitialPayment.autoPayAgreementInfo.length > 1) {
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo.pop();
    	  }
      }
      //VAN-2012-VAN-AutoPay Issue - Ends
      
      void 0;
    }

    function setDirectAutoPayCreditCard(){
      void 0;
      vm.unmannedAutoPayDo = "true";
      var clubStatus = typeof vm.customerFind.VanEngagement.clubStatus === 'number' ? vm.customerFind.VanEngagement.clubStatus : Number(vm.customerFind.VanEngagement.clubStatus);
      SharedDataSvc.autoPaymentData.engagementId = typeof vm.engagementId !== 'number' ? Number(vm.engagementId) : vm.engagementId;
      SharedDataSvc.autoPaymentData.customerPassword = vm.account.password;
      SharedDataSvc.autoPaymentData.customerLastName = vm.customerFind.VanEngagement.customer.lName;
      SharedDataSvc.autoPaymentData.customerFirstName = vm.customerFind.VanEngagement.customer.fName;
      SharedDataSvc.autoPaymentData.customerLogin = vm.account.username;
      SharedDataSvc.autoPaymentData.emailAddress = vm.customerFind.VanEngagement.customer.email;

      SharedDataSvc.autoPaymentData.customerPhone = vm.customerFind.VanEngagement.customer.phNum;
      SharedDataSvc.autoPaymentData.customerAddress = vm.customerFind.VanEngagement.customer.addressLine1;
      SharedDataSvc.autoPaymentData.customerState =  vm.customerFind.VanEngagement.customer.state;
      SharedDataSvc.autoPaymentData.customerZip =  vm.customerFind.VanEngagement.customer.addressZipcode;
      SharedDataSvc.autoPaymentData.customerCity =  vm.customerFind.VanEngagement.customer.addressCity;
      SharedDataSvc.autoPaymentData.paymentType = getAutoPayType();

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      if(getAutoPayType() === 'Credit') {
        SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = SharedDataSvc.creditInitialPayment.payment.creditCardNumber;
        SharedDataSvc.autoPaymentData.creditCardInfo.cardType = SharedDataSvc.creditInitialPayment.payment.cardType; 
//        if(SharedDataSvc.creditInitialPayment.payment.expirationDate != ""){//VAN-1395-eComplish iFrame implementation
        if(vm.account.initialPayment.creditCard.expDate!=""){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        SharedDataSvc.creditInitialPayment.payment.expirationDate = formatCreditCardDate();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      	SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).month;
      	SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).year;}
        SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = SharedDataSvc.creditInitialPayment.payment.cvv;
        SharedDataSvc.autoPaymentData.creditCardInfo.cardType = SharedDataSvc.creditInitialPayment.payment.cardType;//VAN-2012-VAN-AutoPay Issue
      }

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      void 0;
        if(clubStatus === 1) {
            if(!SharedDataSvc.autoPaymentData.agreementList[1]) {
                var benefits = {'agreementNumber': undefined, 'agreementType': 'Benefits', 'monthlyAmount': undefined};
                benefits.agreementNumber = vm.customerFind.agreement.ClubMemberId;
                benefits.agreementType = "Benefits";
                benefits.monthlyAmount = "0.0";
                SharedDataSvc.autoPaymentData.agreementList.push(benefits);
            }else {
                SharedDataSvc.autoPaymentData.agreementList[1].agreementNumber = vm.customerFind.agreement.ClubMemberId;
                SharedDataSvc.autoPaymentData.agreementList[1].agreementType = "Benefits";
                SharedDataSvc.autoPaymentData.agreementList[1].monthlyAmount = "0.0";
            }
        }

      if(clubStatus !== 1) {
        if(SharedDataSvc.autoPaymentData.agreementList.length > 1) {
          SharedDataSvc.autoPaymentData.agreementList.pop();
        }
      }
		
      if(getAutoPayType() === 'ACH') {
        SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankRoutingNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountType;
        SharedDataSvc.autoPaymentData.lastFourDigit = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber.substr(-4);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		vm.lastFourDigit = SharedDataSvc.autoPaymentData.lastFourDigit;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        SharedDataSvc.autoPaymentData.creditCardInfo = {};
      }

      //VAN-1395-eComplish iFrame implementation - Starts
//      if(vm.tokenizedPAN != ""){
//    	  SharedDataSvc.autoPaymentData.tokenizedPAN = vm.tokenizedPAN;
      if(vm.isPayRegIdGenerated){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    	  SharedDataSvc.autoPaymentData.paymentTokenId = vm.paymentId;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    	  SharedDataSvc.autoPaymentData.lastFourDigit = vm.lastFourDigit;
    	  if(getAutoPayType() === 'ACH'){
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = '';
    	  }
    	  else{
    		  SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = '';
    		  //Commented as part of VAN-3330
    		 /* SharedDataSvc.autoPaymentData.creditCardInfo.cardType = ''; 
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = '';*/
    		  SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = '';
    	  }
      }
      //VAN-1395-eComplish iFrame implementation - Ends
      
      //VAN-2012-VAN-AutoPay Issue - Starts
      if(vm.promoPaymentOfferSelected) {
    	SharedDataSvc.autoPaymentData.payRequest.promoCode = vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,
    	SharedDataSvc.autoPaymentData.payRequest.promoDesc = vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,
    	SharedDataSvc.autoPaymentData.payRequest.promoAmount = vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),
    	SharedDataSvc.autoPaymentData.payRequest.promoAmtUsed = formatCash(vm.formattedInitialPayment),
    	SharedDataSvc.autoPaymentData.payRequest.totalDue = SharedDataSvc.initialPaymentValue, 
    	SharedDataSvc.autoPaymentData.payRequest.passPhrase = SharedDataSvc.paymentPassphraseUsed
      }
      
      if(getAutoPayType() === 'ACH') {
      SharedDataSvc.autoPaymentData.payRequest.hoCustName = SharedDataSvc.bankPaymentInitialPayment.agreement.engagement.hoCustName;
      SharedDataSvc.autoPaymentData.payRequest.billingAddressLine = SharedDataSvc.bankPaymentInitialPayment.payment.billingAddressLine;
      SharedDataSvc.autoPaymentData.payRequest.billingState = SharedDataSvc.bankPaymentInitialPayment.payment.billingState;
      SharedDataSvc.autoPaymentData.payRequest.billingCity = SharedDataSvc.bankPaymentInitialPayment.payment.billingCity;
      SharedDataSvc.autoPaymentData.payRequest.billingZipCode = SharedDataSvc.bankPaymentInitialPayment.payment.billingZipCode;
      SharedDataSvc.autoPaymentData.payRequest.paymentAmountInCents = SharedDataSvc.bankPaymentInitialPayment.payment.paymentAmountInCents;
      }else{
    	  SharedDataSvc.autoPaymentData.payRequest.hoCustName = SharedDataSvc.creditInitialPayment.agreement.engagement.hoCustName;
          SharedDataSvc.autoPaymentData.payRequest.billingAddressLine = SharedDataSvc.creditInitialPayment.payment.billingAddressLine;
          SharedDataSvc.autoPaymentData.payRequest.billingState = SharedDataSvc.creditInitialPayment.payment.billingState;
          SharedDataSvc.autoPaymentData.payRequest.billingCity = SharedDataSvc.creditInitialPayment.payment.billingCity;
          SharedDataSvc.autoPaymentData.payRequest.billingZipCode = SharedDataSvc.creditInitialPayment.payment.billingZipCode;
          SharedDataSvc.autoPaymentData.payRequest.paymentAmountInCents =  SharedDataSvc.creditInitialPayment.payment.paymentAmountInCents;
      }
      //VAN-2012-VAN-AutoPay Issue - Ends 
      
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
      if(!$scope.newProcess/*vm.storeInfo.lowInitialPaymentEnabled*/){
    	  vm.submitAutoPay();
      }
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

    }

    function setCreditInitialPayment() {
      void 0;
      void 0;
      var creditCardNumber = vm.account.initialPayment.creditCard.cc1 + vm.account.initialPayment.creditCard.cc2 + vm.account.initialPayment.creditCard.cc3 + vm.account.initialPayment.creditCard.cc4;

      SharedDataSvc.creditInitialPayment.agreement.id = vm.engagement.agreementHtId;
      SharedDataSvc.creditInitialPayment.agreement.customer.lastName = vm.customer.customerLastName;
      SharedDataSvc.creditInitialPayment.agreement.customer.firstName = vm.customer.customerFirstName;
      SharedDataSvc.creditInitialPayment.agreement.customer.login = vm.account.username;
      SharedDataSvc.creditInitialPayment.agreement.customer.email = vm.customer.customerEmailAddress;
      SharedDataSvc.creditInitialPayment.agreement.customer.phoneNumber = vm.customer.phNum;

      SharedDataSvc.creditInitialPayment.agreement.engagement.id = vm.engagement.engagementId;
      SharedDataSvc.creditInitialPayment.agreement.engagement.hoCustName = vm.customer.customerFirstName + ' ' + vm.customer.customerLastName;

      SharedDataSvc.creditInitialPayment.payment.billingCity = vm.account.initialPayment.creditCard.billingCity;
      SharedDataSvc.creditInitialPayment.payment.billingZipCode = vm.account.initialPayment.creditCard.billingZipCode;
      SharedDataSvc.creditInitialPayment.payment.cvv = vm.account.initialPayment.creditCard.securityCode;
      SharedDataSvc.creditInitialPayment.payment.creditCardNumber = creditCardNumber;
      SharedDataSvc.creditInitialPayment.payment.cardType = /*vm.account.initialPayment.creditCard.cardType;*/getCardType(vm.cardType);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      SharedDataSvc.creditInitialPayment.payment.billingLastName = vm.customer.customerLastName;
      SharedDataSvc.creditInitialPayment.payment.expirationDate = /*formatCreditCardDate(vm.account.expDate);*/vm.expDate; //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      SharedDataSvc.creditInitialPayment.payment.billingState = vm.account.initialPayment.creditCard.billingState;
      SharedDataSvc.creditInitialPayment.payment.billingAddressLine = vm.account.initialPayment.creditCard.billingAddress1 + ' ' + vm.account.initialPayment.creditCard.billingAddress2;
      SharedDataSvc.creditInitialPayment.payment.paymentAmountInCents = formatCash(vm.formattedInitialPayment);
      SharedDataSvc.creditInitialPayment.payment.billingFirstName = vm.customer.customerFirstName;
      SharedDataSvc.creditInitialPayment.paymentType = "Credit"
    //Commented as part of VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT     
	  //Start: VAN-616 - Low Down Initial Payment
	  /*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
    	  if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
	    	  vm.promoCode = vm.storeInfo.noCalcPromos[0].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[0].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[0].payment;
    	  }else{
    		  vm.promoCode = vm.storeInfo.noCalcPromos[1].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[1].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[1].payment;
    	  }
      } */
      //End: VAN-616 - Low Down Initial Payment
            
      if(vm.promoPaymentOfferSelected) {
        SharedDataSvc.creditInitialPayment.promotionDetails = {
          "agreemnentNumber": vm.engagement.agreementHtId,
          "customerId": vm.engagement.customerId.toString(),
          "engagementId": vm.engagementId,
          "promoCode": vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoDesc": vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoAmount": vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "totalDue": SharedDataSvc.initialPaymentValue, 
          "passPhrase": SharedDataSvc.paymentPassphraseUsed,
          "promoAmtUsed": formatCash(vm.formattedInitialPayment)
        }
      }else {
      	if(SharedDataSvc.creditInitialPayment.promotionDetails !== undefined)
      		delete  SharedDataSvc.creditInitialPayment.promotionDetails;
      }

      //VAN-1395-eComplish iFrame implementation - Starts
      /*if(vm.tokenizedPAN != ""){
		SharedDataSvc.creditInitialPayment.tokenizedPAN = vm.tokenizedPAN;*/
      if(vm.isPayRegIdGenerated){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    	  SharedDataSvc.creditInitialPayment.paymentId = vm.paymentId;
    	  SharedDataSvc.creditInitialPayment.lastFour = vm.lastFourDigit;
    	  //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
    	  SharedDataSvc.creditInitialPayment.payment.creditCardNumber = '';
//  	  SharedDataSvc.creditInitialPayment.payment.expirationDate = '';//Commented as part of VAN-3330
    	  SharedDataSvc.creditInitialPayment.payment.cvv = '';
      }
      //VAN-1395-eComplish iFrame implementation - Ends

      void 0;
    }



    function setBankInitialPayment() {
      void 0;
      void 0;

      SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankRoutingNumber = vm.account.initialPayment.routingNumber;
      SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber = vm.account.initialPayment.accountNumber;
      SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountType = vm.account.initialPayment.accountType;
      SharedDataSvc.bankPaymentInitialPayment.lastFour = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber.substr(-4);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      vm.lastFourDigit = SharedDataSvc.bankPaymentInitialPayment.lastFour;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      SharedDataSvc.bankPaymentInitialPayment.paymentType = "ACH";

      SharedDataSvc.bankPaymentInitialPayment.agreement.id = vm.engagement.agreementHtId;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.lastName = vm.customer.customerLastName;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.firstName = vm.customer.customerFirstName;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.login = vm.account.username;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.email = vm.customer.customerEmailAddress;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.phoneNumber = vm.customer.phNum;

      SharedDataSvc.bankPaymentInitialPayment.agreement.engagement.id = vm.engagement.engagementId;
      SharedDataSvc.bankPaymentInitialPayment.agreement.engagement.hoCustName = vm.customer.customerFirstName + ' ' + vm.customer.customerLastName;

      SharedDataSvc.bankPaymentInitialPayment.payment.billingCity = vm.account.initialPayment.creditCard.billingCity;
      SharedDataSvc.bankPaymentInitialPayment.payment.billingZipCode = vm.account.initialPayment.creditCard.billingZipCode;
      SharedDataSvc.bankPaymentInitialPayment.payment.cvv = null;
      SharedDataSvc.bankPaymentInitialPayment.payment.creditCardNumber = null;
      SharedDataSvc.bankPaymentInitialPayment.payment.expirationDate = null;
      SharedDataSvc.bankPaymentInitialPayment.payment.billingLastName = vm.customer.customerLastName;
      
      SharedDataSvc.bankPaymentInitialPayment.payment.billingState = vm.account.initialPayment.creditCard.billingState;
      SharedDataSvc.bankPaymentInitialPayment.payment.billingAddressLine = vm.account.initialPayment.creditCard.billingAddress1 + ' ' + vm.account.initialPayment.creditCard.billingAddress2;
      SharedDataSvc.bankPaymentInitialPayment.payment.paymentAmountInCents = formatCash(vm.formattedInitialPayment);
      SharedDataSvc.bankPaymentInitialPayment.payment.billingFirstName = vm.customer.customerFirstName;
      //Commented as part of VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
      //Start: VAN-616 - Low Down Initial Payment
      /*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
    	  if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
	    	  vm.promoCode = vm.storeInfo.noCalcPromos[0].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[0].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[0].payment;
    	  }else{
    		  vm.promoCode = vm.storeInfo.noCalcPromos[1].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[1].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[1].payment;
    	  }
      } */
      //End: VAN-616 - Low Down Initial Payment
            
      if(vm.promoPaymentOfferSelected) {
        SharedDataSvc.bankPaymentInitialPayment.promotionDetails = {
          "agreemnentNumber": vm.engagement.agreementHtId,
          "customerId": vm.engagement.customerId.toString(),
          "engagementId": vm.engagementId,
          "promoCode": vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoDesc": vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoAmount": vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "totalDue": SharedDataSvc.initialPaymentValue, 
          "passPhrase": SharedDataSvc.paymentPassphraseUsed,
          "promoAmtUsed": formatCash(vm.formattedInitialPayment)
        }
      }else {
      	if(SharedDataSvc.bankPaymentInitialPayment.promotionDetails !== undefined)
      		delete  SharedDataSvc.bankPaymentInitialPayment.promotionDetails;
      }
      ////Commented as part of VAN-3330
      //VAN-1395-eComplish iFrame implementation - Starts
      /*if(vm.tokenizedPAN != ""){
    	  SharedDataSvc.bankPaymentInitialPayment.tokenizedPAN = vm.tokenizedPAN;
    	  SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankRoutingNumber = '';
    	  SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber = '';
    	  SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountType = '';
      }*/
      //VAN-1395-eComplish iFrame implementation - Ends
      
      void 0;
    }

    function submitPayment() {
    	if(vm.account.paymentCredit === "credit") {
    		$log.log('credit payment');
    		setCreditInitialPayment();
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    		vm.account.autoPayment.existingBankOrCredit = true;
    		if(!vm.storeInfo.isManned || vm.storeInfo.operationMode ==='HYBRID' || (vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired') && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1424-AWP- Skip autopay section getting skipped while applying promo- $49 payment
    			$scope.newProcess = true;
    			setDirectAutoPayCreditCard();
//    			processAutopay(SharedDataSvc.autoPaymentData);
    			processInitialAndAutoPay(SharedDataSvc.autoPaymentData);//VAN-2012-VAN-AutoPay Issue
    		}
    		else{
    			processCreditPayment(SharedDataSvc.creditInitialPayment);
    		}
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
    	} else if(vm.account.paymentCredit === "bank") {
    		$log.log('OCH payment');
    		setBankInitialPayment();
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    		vm.account.autoPayment.existingBankOrCredit = false;
    		if(!vm.storeInfo.isManned ||  vm.storeInfo.operationMode ==='HYBRID' || (vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired') && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1424-AWP- Skip autopay section getting skipped while applying promo- $49 payment
    			$scope.newProcess = true;
    			setDirectAutoPayCreditCard();
//    			processAutopay();
    			processInitialAndAutoPay(SharedDataSvc.autoPaymentData);//VAN-2012-VAN-AutoPay Issue
    		}
    		else{
    			processBankAccountPayment(SharedDataSvc.bankPaymentInitialPayment);
    		}
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

    	} else {
    		$log.log('cash payment');
    		setCashInitialPayment();
    		showModal('cashPaymentEmployeePin');
    	}
    }

    function formatCreditCardDate() {
      var date = vm.account.initialPayment.creditCard.expDate;
      if(date.indexOf('/') > -1) {
        date = date.split('/');
        return date[0] + '20' + date[1];
      } else {
        if(date.length === 4) {
          return date;
        }
      }
    }

    function formatCash(amount) {
      var cashString = amount.toString();
      if(cashString.indexOf('.') < 0) {
        cashString = cashString + '.00';
      }
      cashString = cashString.replace('.', '');
      return cashString;
    }

    function setEmployeeId(employeeId) {
      SharedDataSvc.cashInitialPayment.empin = employeeId;
    }

    function processCashPayment() {
      $log.log('process cash payment', SharedDataSvc.cashInitialPayment);
      if(!vm.cashPaymentToLow) {
        $scope.viewLoading = true;
        showModal('Processing', 'processing-modal');
        paymentFactory.processCashPayment(SharedDataSvc.cashInitialPayment)
          .then(function(response){
            $log.log('Successfully processed cash payment', response);
            vm.modal.dismiss();
            if(response.status === 200) {
              vm.modal.dismiss();
              vm.paymentErrorCode = '';
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-success';
              vm.initialPaymentMessage = vm.paymentMessageSuccess;
              /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
              vm.cashPaymentSuccess = true;
              /*VAN 762 - AWP - Submit payment is not working when 49 - cash and ANOW promo is selected*/
              if(vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && !vm.promoPaymentCheckboxValue){
            	  vm.initialPaymentPaid = false;
                 // processCashAutoPayment();
            	  saveAutopay();//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
              }else{
            	  vm.initialPaymentPaid = true;
              }
                
             
        	/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
              //VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
              if(vm.zeroPromo){
            	  vm.autopayIframe = true;
//            	  vm.enableBank = "N";
//            	  ecomplishRequest();
            	  configIframe();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
              }
              //VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
              else{//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
            	  vm.autopayIframe = true;
            	  vm.showIframe = true;
            	  configIframe();
              }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
            }
            if(response.status === 400) {
              vm.modal.dismiss();
              vm.paymentErrorCode = '';
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-danger';
              vm.initialPaymentMessage = vm.paymentMessageFailure;
              vm.initialPaymentPaid = false;
              vm.employeeId = '';
            }
          }, function(response){
            $log.log('Error processing cash payment', response);
            vm.modal.dismiss();
            vm.employeeId = '';
            if(response.data.errorData.status_code === "ENG-114-400") {
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-danger';
              vm.initialPaymentMessage = vm.promoNotAvailable;
              vm.initialPaymentPaid = false;
            }else if(response.data.errorData.status_code === "ENG-T1600-400"){
              //PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment
	          vm.initialPaymentStatus = true;
	          vm.initialPaymentErrorClass = 'alert-danger';
	          vm.initialPaymentMessage = vm.internalRecordLocked;
	          vm.initialPaymentPaid = false;
            }else {
              var status_message = JSON.parse(response.data.errorData.status_message);
              void 0;
              void 0;
              if(status_message.processingErrors[0].errorCode.indexOf('T2202') > -1) {
                vm.pinAttempts = vm.pinAttempts + 1;
                vm.initialPaymentStatus = false;
                vm.initialPaymentErrorClass = '';
                vm.employeeId = '';
                void 0;
                if(vm.pinAttempts === 3) {
                  vm.pinAttempts = 0;
                  showModal('tooManyPinAttempts');
                } else {
                  vm.employeePinError = true;
                  showModal('cashPaymentEmployeePin');
                }
              } else {
                vm.initialPaymentStatus = true;
                vm.initialPaymentErrorClass = 'alert-danger';
                vm.initialPaymentMessage = vm.paymentMessageFailure;
              }
            }
          });
      }
    }

    function addDecimalToInitialPayment(amount) {
      var len = amount.length,
          decIndex = len - 2,
          array = amount.split('');

	  //Start - low down feature
      if(decIndex>0){
    	  array[decIndex] = '.' + array[decIndex];
    	  amount = array.join('');
      }else{
//    	  amount = Number(amount).toFixed(2);
    	  amount = '0.'+ amount;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
      }
      //End - low down feature
      return amount;
    }

    function checkForInitialDecimal(payment) {
      if(payment.indexOf('.') > -1) {
        payment = payment.replace('.', '');
      }
      return payment;
    }

    function getDirectAutoPayCreditCardDate(date){
    	var date = date;
    	if(date && date.indexOf('20') > -1) {
        	var month = date.substring(0, 2);
        	var year = date.substring(2);
        	return { 'month' : month, 'year' : year };
      	}
    }

    function getCreditCardDate() {
      var date = vm.account.autoPayment.creditCard.expDate;

      $log.log('CC DATE', date);

      if(date && date.indexOf('/') > -1) {
        date = date.split('/');
        return { 'month' : date[0], 'year' : '20' + date[1] };
      }
    }

    function getAutoPayType() {
      if(vm.account.autoPayment.existingBankOrCredit === 'skip') {
        return 'skip';
      } else if(!vm.account.autoPayment.existingBankOrCredit) {
        return 'ACH';
      } else {
        return 'Credit';
      }
    }

    function sameCardAsInitial() {
      if(vm.copyPaymentInfo) {
        if(vm.account.paymentCredit === 'credit') {
          vm.account.autoPayment.creditCard.cc1 = vm.account.initialPayment.creditCard.cc1;
          vm.account.autoPayment.creditCard.cc2 = vm.account.initialPayment.creditCard.cc2;
          vm.account.autoPayment.creditCard.cc3 = vm.account.initialPayment.creditCard.cc3;
          vm.account.autoPayment.creditCard.cc4 = vm.account.initialPayment.creditCard.cc4;
          vm.account.autoPayment.creditCard.cardType = vm.account.initialPayment.creditCard.cardType;
          vm.account.autoPayment.creditCard.nameOnCard = vm.account.initialPayment.creditCard.nameOnCard;
          vm.account.autoPayment.creditCard.nameOnACard = vm.account.initialPayment.creditCard.nameOnCard;
          vm.account.autoPayment.creditCard.expDate = vm.account.initialPayment.creditCard.expDate;
          vm.account.autoPayment.securityCode = vm.account.initialPayment.creditCard.securityCode; 

          vm.invalidAutoCredit = false;
          /*Defect - 28717 -Starts*/
          /*$scope.ePayAutoCredit.cc1.$setViewValue(vm.account.initialPayment.creditCard.cc1);
          $scope.ePayAutoCredit.cc2.$setViewValue(vm.account.initialPayment.creditCard.cc2);
          $scope.ePayAutoCredit.cc3.$setViewValue(vm.account.initialPayment.creditCard.cc3);
          $scope.ePayAutoCredit.cc4.$setViewValue(vm.account.initialPayment.creditCard.cc4);*/
          /*Defect - 28717 -Ends*/
                   
          $scope.ePayAutoCredit.cc1.$setValidity('cc1', true);
          $scope.ePayAutoCredit.cc2.$setValidity('cc2', true);
          $scope.ePayAutoCredit.cc3.$setValidity('cc3', true);
          $scope.ePayAutoCredit.cc4.$setValidity('cc4', true);
       }

        if(vm.account.paymentCredit === 'bank') {
          vm.account.autoPayment.routingNumber = vm.account.initialPayment.routingNumber;
          vm.account.autoPayment.accountNumber = vm.account.initialPayment.accountNumber;
          vm.account.autoPayment.accountType = vm.account.initialPayment.accountType;
        }

        vm.account.autoPayment.creditCard.billingAddress1 = vm.account.initialPayment.creditCard.billingAddress1;
        vm.account.autoPayment.creditCard.billingAddress2 = vm.account.initialPayment.creditCard.billingAddress2;
        vm.account.autoPayment.creditCard.billingCity = vm.account.initialPayment.creditCard.billingCity;
        vm.account.autoPayment.creditCard.billingState = vm.account.initialPayment.creditCard.billingState;
        vm.account.autoPayment.creditCard.billingZipCode = vm.account.initialPayment.creditCard.billingZipCode;
        vm.account.autoPayment.creditCard.billingEmail = vm.account.initialPayment.creditCard.billingEmail;
        vm.account.autoPayment.creditCard.billingPhone = vm.account.initialPayment.creditCard.billingPhone;
      } else {
        vm.account.autoPayment.creditCard.cc1 = '';
        vm.account.autoPayment.creditCard.cc2 = '';
        vm.account.autoPayment.creditCard.cc3 = '';
        vm.account.autoPayment.creditCard.cc4 = '';
        vm.account.autoPayment.creditCard.cardType = '';
        vm.account.autoPayment.creditCard.nameOnCard = '';
        vm.account.autoPayment.creditCard.nameOnACard = '';
        vm.account.autoPayment.creditCard.expDate = '';
        vm.account.autoPayment.securityCode = '';
        vm.account.autoPayment.creditCard.billingAddress1 = '';
        vm.account.autoPayment.creditCard.billingAddress2 = '';
        vm.account.autoPayment.creditCard.billingCity = '';
        vm.account.autoPayment.creditCard.billingState = '';
        vm.account.autoPayment.creditCard.billingZipCode = '';
        vm.account.autoPayment.creditCard.billingEmail = '';
        vm.account.autoPayment.creditCard.billingPhone = '';
        vm.account.autoPayment.routingNumber = '';
        vm.account.autoPayment.accountNumber = '';
        vm.account.autoPayment.accountType = undefined;
      }
    }

    function getAgreementsMonthly() {
      return addDecimalToInitialPayment(String(vm.customerFind.agreement.monthlyRate));
    }

    function processBankAccountPayment() {
      $log.log('process bank account payment', SharedDataSvc.bankPaymentInitialPayment);
      $scope.viewLoading = true;
      showModal('Processing', 'processing-modal');
      paymentFactory.processCreditPayment(SharedDataSvc.bankPaymentInitialPayment)
        .then(function(response) {
          $log.log('Successfully processed bank account payment', response);
          vm.bankAccountNoLastFourDigit = vm.account.initialPayment.accountNumber.substr(-4);

          if(response.status === 200) {
        	$scope.viewLoading = false;//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
            modalService.dismiss();
            vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-success';
            vm.initialPaymentMessage = vm.paymentMessageSuccess;
            vm.initialPaymentPaid = true;
            vm.copyPayPageInfo = true;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
            if( vm.autopayWithEcomplish && vm.initialPaymentPaid){
            	saveAutopay();
            }
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

            //Commented as part of VAN-815
            //low down payment feature
            /*
            	if(!vm.storeInfo.isManned || $rootScope.isHybrid || (vm.storeInfo.isManned && vm.storeInfo.lowInitialPaymentEnabled &&
            			vm.initialPaymentSetUp && vm.account.paymentInitialValSelected === 'AutoPayRequired')){
            		vm.account.autoPayment.existingBankOrCredit = false;
            		vm.setDirectAutoPayCreditCard();
            	} *///Commented as part of VAN-3330
            //VAN-1395-eComplish iFrame implementation - Starts
            /*vm.copyPayPageInfo = true;
            var iframeVal = iFrame;
            iframeVal.parentNode.removeChild(iframeVal);*/
            //VAN-1395-eComplish iFrame implementation - Ends
          
            if(response.status === 400) {
              modalService.dismiss();
              vm.paymentErrorCode = '';
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-danger';
              vm.initialPaymentMessage = vm.paymentMessageFailure;
              vm.initialPaymentPaid = false;
            }

          }//Commented as part of VAN-815
          //modalService.dismiss();
        }, function(response) {
          $log.log('Error processing bank account payment', response);
          modalService.dismiss();
          vm.initialPaymentStatus = true;
          vm.initialPaymentPaid = false;
          vm.initialPaymentErrorClass = 'alert-danger';
          
          if(response.data.errorData.status_code == "ENG-A1012-400") {
          	vm.invalidBankDetailCount ++;
          	if(vm.invalidBankDetailCount == 2){
          		vm.initialPaymentMessage = vm.invalidBankDetailsType;
          		vm.storeInfo.allowAch = false;
          		vm.initialBankAccount("credit");
          		vm.account.paymentCredit = "credit";
          	}else{
          		vm.initialPaymentMessage = vm.invalidBankDetail;
          	}
    
          } else if(response.data.errorData.status_code == "ENG-114-400") {
          	vm.initialPaymentMessage = vm.promoNotAvailable;
          } else if(response.data.errorData.status_code == "ENG-T1600-400"){
        	//PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment
        	vm.initialPaymentMessage = vm.internalRecordLocked;
          } else{
          	vm.initialPaymentMessage = vm.noTransactionProcess;
          }
        });

    }

    function processCreditPayment() {
      $log.log('process credit payment', SharedDataSvc.creditInitialPayment);
      $scope.viewLoading = true;
      showModal('Processing', 'processing-modal');
      paymentFactory.processCreditPayment(SharedDataSvc.creditInitialPayment)
        .then(function(response) {
          $log.log('Successfully processed credit payment', response);
          
          //vm.copyPaymentInfo = true;
          //sameCardAsInitial();

          if(response.status === 200) {
            modalService.dismiss();
            $scope.viewLoading = false;//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
            vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-success';
            vm.initialPaymentMessage = vm.paymentMessageSuccess;
            vm.initialPaymentPaid = true;
            vm.copyPayPageInfo = true;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
            if( vm.autopayWithEcomplish && vm.initialPaymentPaid){
            	saveAutopay();
            }
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

            //Commented as part of VAN-815
            //low down payment feature
            /*if(!vm.storeInfo.isManned || $rootScope.isHybrid || (vm.storeInfo.isManned && vm.storeInfo.lowInitialPaymentEnabled &&
            		vm.initialPaymentSetUp && vm.account.paymentInitialValSelected === 'AutoPayRequired') ){
            	vm.setDirectAutoPayCreditCard();
            }*/
            //VAN-1395-eComplish iFrame implementation - Starts
            vm.copyPayPageInfo = true;
            var iframeVal = iFrame;
            iframeVal.parentNode.removeChild(iframeVal);
            //VAN-1395-eComplish iFrame implementation - Ends
          }

          if(response.status === 400) {
            modalService.dismiss();
            vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.paymentMessageFailure;
            vm.initialPaymentPaid = false;
          }
          //Commented as part of VAN-815
          //modalService.dismiss();
        }, function(response) {
          $log.log('Error processing credit payment', response);
          modalService.dismiss();
          void 0;
          if(response.data.errorData.status_code === 'ENG-E3004-500') {
            void 0;
            vm.paymentErrorCode = '87';
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentStatus = true;
            if(vm.storeInfo.isManned) {
              vm.initialPaymentMessage = vm.transactionDesc1 + SharedDataSvc.globalVars.storeSetup.vipPhoneNumber + vm.transactionDesc2;//AC-28614 Changes
            } else {
              vm.initialPaymentMessage = vm.transactionDesc3 + SharedDataSvc.globalVars.storeSetup.vipPhoneNumber + vm.transactionDesc2;//AC-28614 Changes


            }
          } else if(response.data.errorData.status_code === 'ENG-S5001-500' || response.data.errorData.status_code === 'ENG-S4001-500') {
            vm.paymentErrorCode = '88';
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentStatus = true;
            if(vm.storeInfo.isManned) {
              vm.initialPaymentMessage = vm.transactionDesc4 ;
            } else {
              vm.initialPaymentMessage = vm.transactionDesc5 + SharedDataSvc.globalVars.storeSetup.vipPhoneNumber + vm.transactionDesc6;//AC-28614 Changes
            }
          } else if(response.data.errorData.status_code === "ENG-A1010-500") {
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.transactionDesc7;
          } else if(response.data.errorData.status_code === "ENG-114-400") {
          	vm.paymentErrorCode = '';
          	vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.promoNotAvailable;
          } else if(response.data.errorData.status_code === "ENG-T1600-400") {
        	//PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment
        	vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.internalRecordLocked;
          } else {
             // vm.showModal('creditCardDeclined');
             vm.initialPaymentErrorClass = 'alert-danger';
             vm.initialPaymentStatus = true;
             vm.paymentErrorCode = '757';
             vm.initialPaymentMessage = vm.transactionDesc8;
          }

          // vm.statusMessage = true;
          // vm.alertClass = 'alert-danger';
          // vm.message = 'Payment was not successful';
          // $scope.viewLoading = false;
        });



    }

    function epayLogin(epayData) {
      setEpayLogin();
      void 0;
      if(vm.account.username && vm.account.password) {
        void 0;
        $scope.viewLoading = true;
        showModal('Processing', 'processing-modal');
        paymentFactory.epayLogin(epayData)
          .then(function(response) {
            // Handle 400 error response
            if(response.status !== 200) {
              $scope.viewLoading = false;
              modalService.dismiss();
              vm.statusMessage = true;
              vm.alertClass = 'alert-danger';
              vm.message = "epay_label_transactionDesc9";
              vm.message2 = '';
            }
            if(response.status === 200) {
              $log.log('SUCCESSFUL EPAY LOGIN', response);
              modalService.dismiss();
              vm.statusMessage = true;
              vm.alertClass = 'alert-success';
              vm.message = "epay_label_transactionDesc10"; /* Successfully logged in to ePay account  */
              vm.message2 = '';
              vm.account.newOrExistingAcct = false;
              vm.account.loggedIntoEpay = true;
            }
          }, function(response) {
            $log.log('UNABLE TO EPAY LOGIN', response);
            vm.statusMessage = true;
            vm.alertClass = 'alert-danger';
            vm.message = "epay_label_transactionDesc9";
            vm.message2 = '';
            modalService.dismiss();
          });
      }
    }

    function setAutoPayData() {
      var clubStatus = typeof vm.customerFind.VanEngagement.clubStatus === 'number' ? vm.customerFind.VanEngagement.clubStatus : Number(vm.customerFind.VanEngagement.clubStatus);
      SharedDataSvc.autoPaymentData.engagementId = typeof vm.engagementId !== 'number' ? Number(vm.engagementId) : vm.engagementId;
      SharedDataSvc.autoPaymentData.customerPassword = vm.account.password;
      SharedDataSvc.autoPaymentData.customerLastName = vm.customerFind.VanEngagement.customer.lName;
      SharedDataSvc.autoPaymentData.customerFirstName = vm.customerFind.VanEngagement.customer.fName;
      SharedDataSvc.autoPaymentData.customerLogin = vm.account.username;
      SharedDataSvc.autoPaymentData.emailAddress = vm.customerFind.VanEngagement.customer.email;

      SharedDataSvc.autoPaymentData.customerPhone = vm.customerFind.VanEngagement.customer.phNum;
      SharedDataSvc.autoPaymentData.customerAddress = vm.customerFind.VanEngagement.customer.addressLine1;
      SharedDataSvc.autoPaymentData.customerState = vm.customerFind.VanEngagement.customer.state;
      SharedDataSvc.autoPaymentData.customerZip = vm.customerFind.VanEngagement.customer.addressZipcode;
      SharedDataSvc.autoPaymentData.customerCity = vm.customerFind.VanEngagement.customer.addressCity;
      SharedDataSvc.autoPaymentData.paymentType = getAutoPayType();

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      if(getAutoPayType() === 'Credit') {
        SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = '';/*vm.account.autoPayment.creditCard.cc1 + vm.account.autoPayment.creditCard.cc2 + vm.account.autoPayment.creditCard.cc3 + vm.account.autoPayment.creditCard.cc4;*///VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        /*if(vm.account.autoPayment.creditCard.expDate != ""){//VAN-1395-eComplish iFrame implementation
        SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = vm.getCreditCardDate().month;
        SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = vm.getCreditCardDate().year;}*/
        SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = /*vm.account.autoPayment.securityCode;*/'';
        //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
        SharedDataSvc.autoPaymentData.creditCardInfo.cardType = getCardType(vm.cardType);
        if(vm.account.initialPayment.creditCard.expDate!=""){
        	SharedDataSvc.creditInitialPayment.payment.expirationDate = formatCreditCardDate();
        	SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).month;
        	SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).year;
        }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
      }

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      void 0;
      if(clubStatus === 1) {
          if(!SharedDataSvc.autoPaymentData.agreementList[1]) {
              var benefits = {'agreementNumber': undefined, 'agreementType': 'Benefits', 'monthlyAmount': undefined};
              benefits.agreementNumber = vm.customerFind.agreement.ClubMemberId;
              benefits.agreementType = "Benefits";
              benefits.monthlyAmount = "0.0";

              SharedDataSvc.autoPaymentData.agreementList.push(benefits);
          }else {
              SharedDataSvc.autoPaymentData.agreementList[1].agreementNumber = vm.customerFind.agreement.ClubMemberId;
              SharedDataSvc.autoPaymentData.agreementList[1].agreementType = "Benefits";
              SharedDataSvc.autoPaymentData.agreementList[1].monthlyAmount = "0.0";
          }
      }

      if(clubStatus !== 1) {
        if(SharedDataSvc.autoPaymentData.agreementList.length > 1) {
          SharedDataSvc.autoPaymentData.agreementList.pop();
        }
      }

      if(getAutoPayType() === 'ACH') {
        SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = vm.account.autoPayment.routingNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = vm.account.autoPayment.accountNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = vm.account.autoPayment.accountType;
        SharedDataSvc.autoPaymentData.lastFourDigit = vm.account.autoPayment.accountNumber.substr(-4);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
//		vm.lastFourDigit = SharedDataSvc.autoPaymentData.lastFourDigit;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        SharedDataSvc.autoPaymentData.creditCardInfo = {};
      }
      
      //VAN-1395-eComplish iFrame implementation - Starts
//      if(vm.tokenizedPAN != ""){
//		SharedDataSvc.autoPaymentData.tokenizedPAN = vm.tokenizedPAN;
		if(vm.isPayRegIdGenerated){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		SharedDataSvc.autoPaymentData.paymentTokenId = vm.paymentId;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		SharedDataSvc.autoPaymentData.lastFourDigit = vm.lastFourDigit;
    	  /*if(getAutoPayType() === 'ACH'){
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = '';
    	  }
    	  else{
    		  SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.cardType = ''; 
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = '';
    	  }*/
      }
      //VAN-1395-eComplish iFrame implementation - Ends
    }

    function deliveryModalOptions(engagement, deliveryPart){
        deliveryPart = deliveryPart || '';
        return {
            templateUrl: 'partials/modal_invoiceAndDelivery' + deliveryPart + '.html',
            backdrop: 'static',
            controller: 'delivery',
            controllerAs: 'delivery',
            scope: $scope
        }
    }

    //address confirm modal for schedule delivery
    function showDeliveryConfirmation(engagement){
        var deliveryConfirmModal = modalService.open(deliveryModalOptions(engagement, 'Confirm'));
        deliveryConfirmModal.result.then(function(result){
            if(result && result.status === 'confirm'){
                showModalDeliveryPass('scheduleDeliveryPass');
                void 0;
            }
        },
            //dismiss
            function(result){
                if(result && result.status === 'declined'){
                    modalService.open({
                       templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html',
                        scope: $scope
                    });
                }
            }
        )
    }

      //show schedule delivery associate pass check modal
      function showModalDeliveryPass(name, windowClass){
          windowClass = windowClass || '';
          SharedDataSvc.globalVars.navigateToDashboard = true;
          vm.myDeliveryConfirmPassModal = modalService.open({
              templateUrl: '/partials/modal_' + name + '.html',
              backdrop: 'static',
              windowClass: windowClass,
              controller: 'delivery',
              controllerAs: 'delivery',
              scope: $scope
          });
          vm.myDeliveryConfirmPassModal
              .result.then(function (result) {
              navigateTo('dashboard');
          });
      }
      
      //skip autopay click event function
      function submitSkipAutoPay(){
    	  if(vm.storeInfo.immediateInvoicing || (vm.storeInfo.immediateInvoicing === undefined)) {
            if($scope.isSpanishStore && (vm.engagement.verifyInfoAssociateName === undefined || vm.engagement.verifyInfoAssociateName.length <= 0)) {
              var showVerifyInfoAlertModal = modalService.open({
                templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
                backdrop: 'static',
                show:true,
                scope: $scope
              });
              showVerifyInfoAlertModal.result.then(function(){
                navigateTo('dashboard');
              });
            } else {
                vm.myDeliveryModal = modalService.open(deliveryModalOptions((vm.engagement)));
                vm.myDeliveryModal.result.then(function(result){
                    if(result && result.status === 'confirm'){
                        showDeliveryConfirmation(result.data);
                        void 0;
                    } else if(result && result.status === 'pickup'){
                        showModalDeliveryPass('scheduleDeliveryPass');
                        void 0;
                    }
                }, function(result){
                    void 0;
                    updateEngagement(SharedDataSvc.currentEngagementData,"updateDeliveryStatus");
                });
              }
          } else {
            $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
            $rootScope.initialAmountPaid = vm.formattedInitialPayment;
            $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
            var vccDetails = modalService.open({
              templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
              backdrop: 'static',
              controller: 'delivery',
              controllerAs: 'delivery',
              show:true
            });
            vccDetails.result.then(function(){
              updateEngagement(SharedDataSvc.currentEngagementData);
            });
          }
      }

      function updateEngagement(engagement, status){
        showModal('Processing', 'processing-modal');
        engagement.salesStatus = "Complete";
        if(status === "updateDeliveryStatus") {
          engagement.deliveryStatus = "Complete";
        }
        updateEngagementItemFactory.putData(engagement)
        .then(function(response) {
          navigateTo('dashboard');
          void 0;
         }, function(error){
          void 0;
        });
      }

      function submitAutoPay() {
           $log.log('submit auto payment');
           if( vm.unmannedAutoPayDo !== 'true') {
               void 0;
               setAutoPayData();
           }
          showModal('Processing', 'processing-modal');
          //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
          if(vm.account.paymentCredit === 'cash' && vm.account.paymentInitialValSelected === 'AutoPayRequired' && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1438 AWP- AUTOPAYSTATUS set as null for customers where $0 promo is applied and CASH selected as payment method.($49 option)
        	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        	  SharedDataSvc.autoPaymentData.noHTEnrollAutoPay = 'Y';//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
//        	  autopayForCash(); 
          }/*else{//Commented as part of VAN-3330
        	  VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts
        	  processCashAutoPayment();
        	  VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends
          }*///VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
          else if(vm.account.paymentCredit === 'cash' && vm.account.paymentInitialValSelected != 'AutoPayRequired'){
        	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';
          }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP  - End
          autopayForCash(); //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      }
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
      function processCashAutoPayment(){
    	  if(vm.storeInfo.lowInitialPaymentEnabled && 
        		  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && !vm.cashPaymentSuccess  && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1440 AWP- Getting Error 789 while setting up Autopay for Cash payment
        	  SharedDataSvc.autoPaymentData.enableLDPAutopay = "LDP_Ecomplish";
          }else if(vm.storeInfo.lowInitialPaymentEnabled && 
        		  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.cashPaymentSuccess && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1440 AWP- Getting Error 789 while setting up Autopay for Cash payment
        	  SharedDataSvc.autoPaymentData.enableLDPAutopay = "LDP_Autopay";
          }else{
        	  SharedDataSvc.autoPaymentData.enableLDPAutopay="Autopay";
          }
    	  
    	  $log.log('data for autopay', SharedDataSvc.autoPaymentData);
    	  paymentFactory.processAutoPayment(SharedDataSvc.autoPaymentData)
              .then(function(response) {
                  void 0;
                  vm.autoPayStatus = false;//VAN-769 - AWP - The error message for invalid bank/CC details appears on the screen even after successful Autopay setup and initial payment
                  vm.autoPayClass = "";//VAN-769 - AWP - The error message for invalid bank/CC details appears on the screen even after successful Autopay setup and initial payment
                  /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
                  if(vm.storeInfo.lowInitialPaymentEnabled && 
                		  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash'){
                  vm.valAutoPay = true;
                  }
                  //VAN-616 - VAN - Low Down Initial Payment
                  if((vm.storeInfo.isManned && !$rootScope.isHybrid && 
                		  (!vm.storeInfo.lowInitialPaymentEnabled ||  (vm.storeInfo.lowInitialPaymentEnabled && (vm.promoSelected || vm.account.paymentInitialValSelected === 'WithoutAutoPay' 
                			  || (vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.cashPaymentSuccess))) ))
                			|| (vm.storeInfo.isManned && $rootScope.isHybrid && vm.changeAutoPaymentMode)
                        			|| (!vm.storeInfo.isManned && vm.unmannedAutoPayDo != 'true')){
                  /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
                      if(vm.storeInfo.immediateInvoicing  || (vm.storeInfo.immediateInvoicing === undefined)) {
                        if($scope.isSpanishStore && (vm.engagement.verifyInfoAssociateName === undefined || vm.engagement.verifyInfoAssociateName.length <= 0)) {
                          var showVerifyInfoAlertModal = modalService.open({
                            templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
                            backdrop: 'static',
                            show:true,
                            scope: $scope
                          });
                          showVerifyInfoAlertModal.result.then(function(){
                            navigateTo('dashboard');
                          });
                        } else {
                        SharedDataSvc.deliveryTempId = vm.engagement.engagementId;
                        SharedDataSvc.invoiceDeliveryData.engagement = vm.engagement;
                        //showModal('invoiceAndDelivery');
                        vm.myDeliveryModal = modalService.open(deliveryModalOptions(vm.engagement));
                        vm.myDeliveryModal
                            .result.then(function (result) {
                            //console.log('[Delivery Modal complete]', result);
                              if (result && result.status === 'confirm') {
                                  showDeliveryConfirmation(result.data);
                                  void 0;
                              } else if(result && result.status === 'pickup') {
                                  showModalDeliveryPass('scheduleDeliveryPass');
                                  void 0;
                              }
                        },
                            function (result) {
                                void 0;
                                updateEngagement(SharedDataSvc.currentEngagementData,"updateDeliveryStatus");
                                //navigateTo('dashboard');
                            })
                          }
                      } else {
                          $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
                          $rootScope.initialAmountPaid = vm.formattedInitialPayment;
                          $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
                          var vccDetails = modalService.open({
                            templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
                            backdrop: 'static',
                            controller: 'delivery',
                            controllerAs: 'delivery',
                            show:true
                          });
                          vccDetails.result.then(function(){
                            updateEngagement(SharedDataSvc.currentEngagementData);
                          });
                      }
                  }else {
                      if (vm.unmannedAutoPayDo == 'true') {
                          vm.autoPaySuccess = true;
                          vm.unmannedAutoPayDo = false;
                          vm.modal.dismiss();
                      }
                      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
                      else if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired' &&
                    		  vm.account.paymentCredit === 'cash' && !vm.cashPaymentSuccess){
                    	  vm.autoPaySuccess = true;
                    	  vm.modal.dismiss();
                      } 
                      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
                      else {
                          vm.autoPaySuccess = true;
                          vm.modal.dismiss();
                          navigateTo('dashboard');
                      }
                  }
              }, function(response) {
                  void 0;
                  modalService.dismiss();
                  if(response.data.errorData.status_code === "ENG-A1010-500") {
                    vm.autoPayStatus = true;
                    vm.autoPayMessage = vm.transactionDesc7;
                    vm.autoPayClass = 'alert-danger';
                  } else {
                    vm.autoPayStatus = true;
                    vm.autoPayMessage = "There was a problem validating your payment method. Please review the information and try again or tap Exit to skip AutoPay setup. [758]";
                    vm.autoPayClass = 'alert-danger';
                  }
              });
      }
      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
      
      function initialBankAccount(paymentMode) {
			void 0;
			resetInitialPayValidation();
			resetPromoPayment();
			if(paymentMode === "credit") {
//				vm.enableBank = "N";//VAN-1395-eComplish iFrame implementation
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Starts*/	
				vm.account.initialPayment.routingNumber = '';
				vm.account.initialPayment.accountNumber = '';
				vm.account.initialPayment.accountType = '';
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Ends*/
				vm.account.paymentBankAccount = false;
				vm.showIframe = true;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
			} else{
//				vm.enableBank = "Y";//VAN-1395-eComplish iFrame implementation
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Starts*/
				vm.account.initialPayment.creditCard.cc1 = '';
				vm.account.initialPayment.creditCard.cc2 = '';
				vm.account.initialPayment.creditCard.cc3 = '';
				vm.account.initialPayment.creditCard.cc4 = '';
				vm.account.initialPayment.creditCard.cardType = '';
				vm.account.initialPayment.creditCard.nameOnCard = '';
				vm.account.initialPayment.creditCard.expDate = '';
				vm.account.initialPayment.creditCard.securityCode = '';
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Ends*/
				vm.account.paymentBankAccount = true; 
				vm.showIframe = false;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
			}
			//VAN-1395-eComplish iFrame implementation - Starts      
			vm.initialPaymentStatus = false;
			vm.autopayIframe = false;
			vm.autoPaystatus = false;
			vm.account.autoPayment.existingBankOrCredit = true;
//			ecomplishRequest();
			//VAN-1395-eComplish iFrame implementation - Ends
			//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
			if(iFrame!=null && iFrame.parentNode != null){
				var iframeVal = iFrame;
				iframeVal.parentNode.removeChild(iframeVal);
			}
			if(paymentMode === "credit"){
				configIframe();
			}//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		}
      
      function setUpInitialPaymentAmount(paymentOptionChosen){
    	  void 0;
    	  vm.initialPaymentSetUp = true;
          resetInitialPayValidation();
          resetPromoPayment();
          vm.account.paymentCredit = 'credit';
          //VAN 633-AWP - Bank Account fields are showing when user selects Credit Card payment options
          vm.account.paymentBankAccount = false;
          //VAN-670 - Consent Verbiage - Starts
          if(paymentOptionChosen === 'AutoPayRequired'){
        	  showModal('paymentInitial');
          }
          //VAN-670 - Consent Verbiage - Ends
      }

      function resetPromoPayment() {
			$scope.epayCreditSubmitted = false;
			vm.zeroDollarPromoInitialPayment = false;
			vm.promoPaymentCheckboxValue = false;
			vm.promoPaymentOfferSelected = false;
			vm.invalidPromoPayment = false;
			vm.showPromoOptions = false;
			vm.selectedPromoOption = undefined;
			vm.promoSelected = "";
			//Start - VAN-616 - Low Down Initial Payment
			/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
			if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.storeInfo.noCalcPromos){
				/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
//				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.storeInfo.noCalcPromos[0].payment);
				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
				vm.promoPaymentOfferSelected = true;
				/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
			}else if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'WithoutAutoPay' && vm.storeInfo.noCalcPromos){
				/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
//				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.storeInfo.noCalcPromos[1].payment);
				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
				vm.promoPaymentOfferSelected = true;
			}else if(vm.account.paymentInitialValSelected === 'PIFPromo'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);
				vm.promoPaymentOfferSelected = true;
			}else{//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
				if(SharedDataSvc.initialPaymentValue != undefined){//VAN-670 - Consent Verbiage
					vm.formattedInitialPayment = addDecimalToInitialPayment(SharedDataSvc.initialPaymentValue)}
				/*ANOW Promo amount is retained when swiching between credit/Cash/bank  - Starts*/
				else{
					vm.formattedInitialPayment='';
				}
				/*ANOW Promo amount is retained when swiching between credit/Cash/bank  - Ends*/
			}
			//End - VAN-616 - Low Down Initial Payment

			SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
			vm.cashPaymentAmount = "";
			vm.changeDue = 0;
			vm.empPassPhrase = '';
			vm.errorMessage = '';
			//VAN-1395-eComplish iFrame implementation - Starts
			if(vm.account.paymentCredit === 'cash'){
				vm.autopayIframe = true;
//				vm.enableBank = "N";
				if(iFrame!=null && iFrame.parentNode != null){//VAN-3330- replace eComplish iframe with VANTIV iframe - AWP
				var iframeVal = iFrame;
				iframeVal.parentNode.removeChild(iframeVal);}
//				ecomplishRequest();
				//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
				if(vm.account.paymentInitialValSelected == 'AutoPayRequired'){
				vm.showIframe = true;
				configIframe();
				}//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
			}//VAN-1395-eComplish iFrame implementation - Ends
		}

      function updateInitialPayment(val, index) {
        void 0;
        if(val !== undefined) {
          vm.promoPaymentOfferSelected = true;
          vm.zeroDollarPromoInitialPayment = false;
          vm.initialPaymentSetUp = false;
          vm.invalidPromoPayment = false;
          vm.cashPaymentAmount = "";
          vm.changeDue = 0;
          void 0;
          vm.promoSelected = SharedDataSvc.eligiblePromos[val.id];
          vm.formattedInitialPayment = (Number(SharedDataSvc.eligiblePromos[val.id].payment)).toFixed(2);
          SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
          if(Number(vm.promoSelected.payment) === 0) {
            vm.zeroDollarPromoInitialPayment = true;
            vm.account.paymentCredit = 'cash';
            vm.cashPaymentAmount = '0';
          }
        } else {
          vm.invalidPromoPayment = true;
        }

      }
      

      function promoPaymentSelectOperation(val) {
        void 0;
        if(val === "true") {
          if(vm.storeInfo.isManned && !vm.storeInfo.hybridEnabled){
           showModal('confirmPasswordePay');
          }else{
            showModal('validatePassphrase');
          }
        } else{
          resetPromoPayment();
        }
      }

      function verifyEmployeePassWord(pass){
        $scope.noEmpPass = false;
        $scope.errEmpPass = false;
      if (pass) {
        var auth = vm.storeInfo.vanStoreId;
        if (auth === pass) {
            void 0;
            vm.modal.dismiss();
            vm.showPromoOptions = true;
        } else {
          $scope.errEmpPass = true;
          void 0;
        }
      } else {
        $scope.noEmpPass = true;
      }
      }

      function verifyEmployeePassPhrase(val) {
        if(val) {
          showModal('Processing', 'processing-modal');
          agreementFactory.validatePassPhrase(val, 'epay').then(function(response) {
              void 0;
              vm.associatePassFail = false;
              vm.showPromoOptions = true;
              SharedDataSvc.paymentPassphraseUsed = vm.empPassPhrase;
              vm.empPassPhrase = "";
              vm.cashPaymentAmount = "";
              vm.changeDue = 0;
              vm.modal.dismiss();
            }, function (response) {
              void 0;
              vm.empPassPhrase = "";
              SharedDataSvc.validatePassPhraseAttempts = SharedDataSvc.validatePassPhraseAttempts + 1;
              void 0;
              if(SharedDataSvc.validatePassPhraseAttempts === 3) {
                SharedDataSvc.validatePassPhraseAttempts = 0;
                vm.modal.dismiss();
                vm.showPromoOptions = false;
                vm.invalidPassPhrase = false;
                vm.associatePassFail = false;
                vm.promoPaymentCheckboxValue = false;
                vm.empPassPhrase = "";
              } else {
                vm.errorMessage = response.data.errorData.status_message;
                vm.invalidPassPhrase = true;
                vm.associatePassFail = true;
                vm.showPromoOptions = false;
                showModal('validatePassphrase');
              }                    
          });
        }else {
          vm.associatePassFail = true;
          vm.invalidPassPhrase = false;
        }
      }

      function getCardTypeDtl() {
        paymentFactory.getCardTypeDtl()
        .then(function(response) {
          void 0;
          SharedDataSvc.regex = response.regex;
        }, function(error){
          void 0;
        });
      }

      function clearPinData() {
        vm.employeeId = '';
      }

      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
      function processAutopay(){
    	  $log.log('Process Autopay With Ecomplish', SharedDataSvc.autoPaymentData);
    	  $scope.viewLoading = true;
    	  showModal('Processing', 'processing-modal');
    	  paymentFactory.enrollAutopayWithEcomplish(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  $log.log('Successfully processed credit payment', response);
    		  if(response.status === 200) {
    			  $scope.viewLoading = false;
    			  modalService.dismiss();
    			  vm.autopayWithEcomplish = true;
    			  if(vm.account.paymentCredit === "credit"){//credit payment
    				  $log.log('Payment mode - ', vm.account.paymentCredit)
    				  processCreditPayment();
    			  }
    			  if(vm.account.paymentCredit === "bank"){//banking payment
    				  $log.log('Payment mode - ', vm.account.paymentCredit)
    				  processBankAccountPayment();
    			  }

    		  }
    	  }, function(response) {
    		  $log.log('Error processing Autopay with Ecomplish', response);
    		  $scope.viewLoading = false;
    		  modalService.dismiss();
    		  void 0;
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  vm.initialPaymentStatus = true;
    			  vm.paymentErrorCode = '757';
    			  vm.initialPaymentMessage = vm.transactionDesc8;
    		  }
    	  });
      }

      function saveAutopay() {
    	  $log.log('Post Autopay to HighTouch', SharedDataSvc.autoPaymentData);
    	  $scope.viewLoading = true;
    	  showModal('Processing', 'processing-modal');
    	  paymentFactory.postAutopayToHT(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  if(response.status == 200){
    			  $log.log('Successfully posted the Autopayment to HighTouch', response);
    			  $scope.viewLoading = false;
    			  modalService.dismiss();
    			  submitSkipAutoPay();
    		  }
    	  }, function(response) {
    		  $log.log('Error posting the Autopay to HighTouch', response);
    		  $scope.viewLoading = false;
    		  modalService.dismiss();
    		  void 0;
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  vm.initialPaymentStatus = true;
    			  vm.paymentErrorCode = '757';
    			  vm.initialPaymentMessage = vm.transactionDesc8;
    		  }
    		  navigateTo('dashboard');
    	  });
      }
      
      //Enroll Autopay With Ecomplish for $49 promo cash payment
      function autopayForCash(){
    	  $log.log('Enroll Autopay With Ecomplish for $49 promo cash payment', SharedDataSvc.autoPaymentData);
    	  paymentFactory.enrollAutopayWithEcomplish(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  void 0;
    		  vm.autoPayStatus = false;
    		  vm.autoPayClass = "";
    		  if(vm.storeInfo.lowInitialPaymentEnabled && 
    				  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash'){
    			  vm.valAutoPay = true;
    		  }
    		  if((vm.storeInfo.isManned && !$rootScope.isHybrid && 
    				  (!vm.storeInfo.lowInitialPaymentEnabled ||  (vm.storeInfo.lowInitialPaymentEnabled && (vm.promoSelected || vm.account.paymentInitialValSelected === 'WithoutAutoPay' 
    					  || (vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.cashPaymentSuccess))) ))
    					  || (vm.storeInfo.isManned && $rootScope.isHybrid && vm.changeAutoPaymentMode)
    					  || (!vm.storeInfo.isManned && vm.unmannedAutoPayDo != 'true')){
    			  if(vm.storeInfo.immediateInvoicing  || (vm.storeInfo.immediateInvoicing === undefined)) {
    				  if($scope.isSpanishStore && (vm.engagement.verifyInfoAssociateName === undefined || vm.engagement.verifyInfoAssociateName.length <= 0)) {
    					  var showVerifyInfoAlertModal = modalService.open({
    						  templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
    						  backdrop: 'static',
    						  show:true,
    						  scope: $scope
    					  });
    					  showVerifyInfoAlertModal.result.then(function(){
    						  navigateTo('dashboard');
    					  });
    				  } else {
    					  SharedDataSvc.deliveryTempId = vm.engagement.engagementId;
    					  SharedDataSvc.invoiceDeliveryData.engagement = vm.engagement;
    					  //showModal('invoiceAndDelivery');
    					  vm.myDeliveryModal = modalService.open(deliveryModalOptions(vm.engagement));
    					  vm.myDeliveryModal
    					  .result.then(function (result) {
    						  //console.log('[Delivery Modal complete]', result);
    						  if (result && result.status === 'confirm') {
    							  showDeliveryConfirmation(result.data);
    							  void 0;
    						  } else if(result && result.status === 'pickup') {
    							  showModalDeliveryPass('scheduleDeliveryPass');
    							  void 0;
    						  }
    					  },
    					  function (result) {
    						  void 0;
    						  updateEngagement(SharedDataSvc.currentEngagementData,"updateDeliveryStatus");
    						  //navigateTo('dashboard');
    					  })
    				  }
    			  } else {
    				  $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
    				  $rootScope.initialAmountPaid = vm.formattedInitialPayment;
    				  $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
    				  var vccDetails = modalService.open({
    					  templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
    					  backdrop: 'static',
    					  controller: 'delivery',
    					  controllerAs: 'delivery',
    					  show:true
    				  });
    				  vccDetails.result.then(function(){
    					  updateEngagement(SharedDataSvc.currentEngagementData);
    				  });
    			  }
    		  }else {
    			  if (vm.unmannedAutoPayDo == 'true') {
    				  vm.autoPaySuccess = true;
    				  vm.unmannedAutoPayDo = false;
    				  vm.modal.dismiss();
    			  }
    			  else if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired' &&
    					  vm.account.paymentCredit === 'cash' && !vm.cashPaymentSuccess){
    				  vm.autoPaySuccess = true;
    				  vm.modal.dismiss();
    			  } 
    			  else {
    				  vm.autoPaySuccess = true;
    				  vm.modal.dismiss();
    				  navigateTo('dashboard');
    			  }
    		  }
    	  }, function(response) {
    		  void 0;
    		  modalService.dismiss();
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = "There was a problem validating your payment method. Please review the information and try again or tap Exit to skip AutoPay setup. [758]";
    			  vm.autoPayClass = 'alert-danger';
    		  }
    	  });
      }
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
  
      //VAN-1395-eComplish iFrame implementation - Starts
      /*function  ecomplishRequest(){
    	  var date = new Date();
    	  var payPageID = date.getTime().toString();
    	  var data = {
    			  "payPageID": payPageID,
    			  "div": "cardInputFrame",
    			  "widthOfFrame": "430",
    			  "heightOfFrame": "130",
    			  "allowACH": vm.enableBank,
    			  "language": $rootScope.selectedLanguage
    	  };
    	  $log.log("Input Data: "+data);
    	  if(vm.autopayIframe){
    		  data.div = "autopayIframe";
    	  }
    	  invokeEcomIframe(data);
      }

      function invokeEcomIframe(data){
    	  $log.log("Loading Ecomplish PayPage.");
    	  var payPageID = data.payPageID;
    	  var divFrameToUse = data.div;
    	  var heightOfFrame = data.heightOfFrame;
    	  var widthOfFrame  = data.widthOfFrame;
    	  var allowACH = data.allowACH;
    	  var language = data.language;
    	  if(window.location.hostname !== undefined && ecomSrcURL === ""){
    	  if(window.location.hostname === 'awp.acceptancenow.com'){
    			  ecomSrcURL = "https://www.secured-server.biz"; 
    		  }
    		  else{
    			  ecomSrcURL = "https://dev.secured-server.biz";  
    		  }
    	  }
    	  if(vm.customerFind != undefined && vm.customerFind.VanEngagement != undefined && vm.customerFind.VanEngagement.vanStore != undefined){
    		  vm.htStoreID = vm.customerFind.VanEngagement.vanStore.storeInfoLawson.htStoreId;
    	  } else if(vm.storeInfo != undefined && vm.storeInfo.vanStoreId != undefined){
    		  vm.htStoreID =  vm.storeInfo.vanStoreId.length == 4 ? "0"+vm.storeInfo.vanStoreId : vm.storeInfo.vanStoreId;
    	  }
    	  jQuery("#" + divFrameToUse).html("<iframe id='ecomp-payframe' name='ecomp-payframe' frameborder='0' src='"+ecomSrcURL+"/RentACenter/TokenizerAPI/?pageID="+payPageID+"&allowACH="+allowACH+"&lang="+language+"&customerNumber="+vm.customerFind.VanEngagement.customerHTId+"&storeNumber="+vm.htStoreID+"' height='" + heightOfFrame + "' width='" + widthOfFrame +"'></iframe>");
    	  iFrame = document.getElementById('ecomp-payframe');
    	 
    	  function getPageRegID(reqValues) {
    		  $log.log("Posting message to Ecomplish PayPage.");
    		  vm.eventInitialize= true;
    		  if (window.addEventListener) {
    			  window.addEventListener("message", eventHandler, false)
    		  } else {
    			  if (window.attachEvent) {
    				  window.attachEvent("onmessage", eventHandler)
    			  }
    		  }
    		  reqValues.action = "getRegID";
    		  reqValues.pageID = payPageID;
//  		  reqValues.allowACH = allowACH;
    		  var j = document.getElementById("ecomp-payframe").contentWindow;
    		  j.postMessage(JSON.stringify(reqValues), "*");
    	  }
    	  invokeEcomIframe.getPageRegID = getPageRegID;
      }
      var eventHandler = function (event) {
    	  var response = null;
    	  try {
    		  response = JSON.parse(event.data)
    		  console.log("Response from Ecomplish PayPage: "+response);  
    		  resHandler(response);
    		  window.removeEventListener("message", eventHandler, false);//VAN 1998 - AWP : Payment Page Freezed and Unable to Proceed with Payment Submission
    	  } catch (c) {
    		  console.log("Exception while invoking PayPage: "+response.responseText);
    		  vm.paymentErrorCode = '';
    		  vm.initialPaymentStatus = true;
    		  vm.initialPaymentErrorClass = 'alert-danger';
    		  vm.initialPaymentMessage = "Invalid data sent back from Ecomplish service";
    		  vm.initialPaymentPaid = false;
    	  }
      }

      function postToPayPage(){
    	  var data = {
    			  "action": "",
    			  "pageID": "",
    			  "allowACH": vm.enableBank,
    			  "language": $rootScope.selectedLanguage,
    			  "customerNumber": vm.customerFind.VanEngagement.customerHTId,
    			  "storeNumber": vm.customerFind.VanEngagement.vanStore.storeInfoLawson.htStoreId
    	  };
    	  invokeEcomIframe.getPageRegID(data);
      }

      function resHandler(response){
    	  if(!vm.autopayIframe){
    		  resHandlerInitialPay(response);
    	  }else {
    		  resHandlerAutopay(response);
    	  }
      }

      function resHandlerInitialPay(response){
    	  if(response.responseCode == '1'){
    		  $log.log("Sucess response: "+response.responseText);
    		  if(response.tokenizedPAN != ""){
    			  vm.tokenizedPAN = response.tokenizedPAN;
    			  vm.lastFourDigit = response.lastFour;
    			  vm.tokenGenerated = true;
    		  }
    		  submitPayment();
    	  } else{
    		  vm.paymentErrorCode = '';
    		  vm.initialPaymentStatus = true;
    		  vm.initialPaymentErrorClass = 'alert-danger';
    		  vm.initialPaymentMessage = response.responseText;
    		  vm.initialPaymentPaid = false;
    	  }
      }

      function resHandlerAutopay(response){
    	  if(response.responseCode == '1'){
    		  $log.log("Sucess response: "+response.responseText);
    		  if(response.tokenizedPAN != ""){
    			  vm.tokenizedPAN = response.tokenizedPAN;
    			  vm.lastFourDigit = response.lastFour;
    			  vm.tokenGenerated = true;
    		  }
    		  submitAutoPay();
    	  } else{
    		  vm.autoPayStatus = true;
    		  vm.autoPayMessage = response.responseText;
    		  vm.autoPayClass = 'alert-danger';
    	  }
      }*/

      function autoPayToken(value){
    	  vm.autoPayStatus = false;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    	  if(value === 'sameCard'){
    		  void 0;
    		  vm.autopayIframe = false;
    		  vm.copyPayPageInfo = true;
    		  //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    		  if(iFrame.parentNode != null){
    			  var iframeVal = iFrame;
    			  iframeVal.parentNode.removeChild(iframeVal);
    		  }
    		  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'Y';
    		//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
    	  }
    	  else{
    		  if(value === 'newCard'){
    			  void 0;
    			  vm.autopayIframe = true;
    			  vm.copyPayPageInfo = false;
    			  vm.account.autoPayment.existingBankOrCredit = true;
    			//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
//  			  vm.enableBank = "N";
//  			  ecomplishRequest();
    			  vm.showIframe = true;
    			  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';
    			  configIframe();
    			  //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
    		  }
    	  }
      }
      //VAN-1395-eComplish iFrame implementation - Ends  
      
      //VAN-2012-VAN-AutoPay Issue - Starts
      function processInitialAndAutoPay(){
    	  $log.log('Process Autopay With Ecomplish', SharedDataSvc.autoPaymentData);
    	  $scope.viewLoading = true;
    	  showModal('Processing', 'processing-modal');
    	  paymentFactory.initialPayAndAutoPay(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  $log.log('Successfully processed credit payment', response);
    		  if(response.status === 200) {
    			  $scope.viewLoading = false;
    			  modalService.dismiss();
    			  vm.autopayWithEcomplish = true;
    			  vm.initialPaymentStatus = true;
    			  vm.initialPaymentPaid = true; 
    			  if(SharedDataSvc.autoPaymentData.paymentType === 'Credit'){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP 
    			  vm.copyPayPageInfo = true;
    			  var iframeVal = iFrame;
    			  iframeVal.parentNode.removeChild(iframeVal);
    			  }
    			  submitSkipAutoPay();
    		  }
    	  }, function(response) {
    		  $log.log('Error processing Autopay with Ecomplish', response);
    		  $scope.viewLoading = false;
    		  modalService.dismiss();
    		  void 0;
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  vm.initialPaymentStatus = true;
    			  vm.paymentErrorCode = '757';
    			  vm.initialPaymentMessage = vm.transactionDesc8;
    		  }
    	  });
      }
      
      function processCash(){
    	  if(vm.account.paymentInitialValSelected === 'AutoPayRequired'){
    		  processCashAndAutoPay();
    	  }else{
    		  processCashPayment();
    	  }
      }

      function processCashAndAutoPay(){
    	  $log.log('process cash payment and autopayment', SharedDataSvc.cashInitialPayment);
    	  if(!vm.cashPaymentToLow) {
    		  $scope.viewLoading = true;
    		  showModal('Processing', 'processing-modal');
    		  paymentFactory.postCashAndAutoPay(SharedDataSvc.cashInitialPayment)
    		  .then(function(response){
    			  $log.log('Successfully processed cash payment and autopayment', response);
    			  vm.modal.dismiss();
    			  if(response.status === 200) {
    				  vm.modal.dismiss();
    				  vm.paymentErrorCode = '';
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-success';
    				  vm.initialPaymentMessage = vm.paymentMessageSuccess;
    				  vm.cashPaymentSuccess = true;
    				  vm.initialPaymentPaid = false;
    				  submitSkipAutoPay();
    			  }
    			  if(response.status === 400) {
    				  vm.modal.dismiss();
    				  vm.paymentErrorCode = '';
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-danger';
    				  vm.initialPaymentMessage = vm.paymentMessageFailure;
    				  vm.initialPaymentPaid = false;
    				  vm.employeeId = '';
    			  }
    		  }, function(response){
    			  $log.log('Error processing cash payment', response);
    			  vm.modal.dismiss();
    			  vm.employeeId = '';
    			  if(response.data.errorData.status_code === "ENG-114-400") {
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-danger';
    				  vm.initialPaymentMessage = vm.promoNotAvailable;
    				  vm.initialPaymentPaid = false;
    			  }else if(response.data.errorData.status_code === "ENG-T1600-400"){
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-danger';
    				  vm.initialPaymentMessage = vm.internalRecordLocked;
    				  vm.initialPaymentPaid = false;
    			  }else {
    				  var status_message = JSON.parse(response.data.errorData.status_message);
    				  void 0;
    				  void 0;
    				  if(status_message.processingErrors[0].errorCode.indexOf('T2202') > -1) {
    					  vm.pinAttempts = vm.pinAttempts + 1;
    					  vm.initialPaymentStatus = false;
    					  vm.initialPaymentErrorClass = '';
    					  vm.employeeId = '';
    					  void 0;
    					  if(vm.pinAttempts === 3) {
    						  vm.pinAttempts = 0;
    						  showModal('tooManyPinAttempts');
    					  } else {
    						  vm.employeePinError = true;
    						  showModal('cashPaymentEmployeePin');
    					  }
    				  } else {
    					  vm.initialPaymentStatus = true;
    					  vm.initialPaymentErrorClass = 'alert-danger';
    					  vm.initialPaymentMessage = vm.paymentMessageFailure;
    				  }
    			  }
    		  });
    	  }
      }
      //VAN-2012-VAN-AutoPay Issue - Ends

      //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
      function configIframe(){
    	  if(payPageID == ""){
    		  if(window.location.hostname !== undefined && payPageID === "" && merchantID ===""){
    			  if(window.location.hostname === 'awp.acceptancenow.com'){
    				  payPageID = "ppexn7YVS475JywH"; 
    				  merchantID = "4445026664342";
    			  }
    			  else{
    				  payPageID = "DKa7HK5j5f4H4hxw";  
    				  merchantID = "1273451";
    			  }
    		  }
    	  }
    	  if(vm.props == ""){ 
    		  vm.props = {
    				  "paypageId" : payPageID,
    				  "style": $rootScope.selectedLanguage == 'en'?"iframeen":"iframeesv2",
    						  "height" : "200px",
    						  "width" : "200px",
    						  "reportGroup" : "ANOW-CP",
    						  "timeout" : "15000",
    						  "div" : !vm.autopayIframe?"payframe":"payframe_auto",
    								  "callback" : payframeClientCallback,
    								  "showCvv" : true,
    								  "months" : {
    									  "0": "Select",
    									  "1" : "January",
    									  "2" : "February",
    									  "3" : "March",
    									  "4" : "April",
    									  "5" : "May",
    									  "6" : "June",
    									  "7" : "July",
    									  "8" : "August",
    									  "9" : "September",
    									  "10": "October",
    									  "11" : "November",
    									  "12" : "December"
    								  },
    								  "numYears" : 50,"tabIndex" : {
    									  "cvv" : 1,
    									  "accountNumber" : 2,
    									  "expMonth" : 3,
    									  "expYear" : 4
    								  },
    								  "placeholderText" : {
    									  "cvv" : "CVV",
    									  "accountNumber" : "Number on Card",
    									  "expMonth" : "MM",
    									  "expYear" : "YYYY"  
    								  },
    								  "enhancedUxFeatures" : {
    									  "inlineFieldValidations": true,
    									  "numericInputsOnly": true
    								  }
    		  }
    	  }else{
    		  if(vm.props.div == "payframe" && vm.autopayIframe){
    			  vm.props.div ="payframe_auto";
    		  }else if(vm.props.div == "payframe_auto" && !vm.autopayIframe){
    			  vm.props.div ="payframe";
    		  }
    	  }
    	  invokeVantivIframe(vm.props);
      }
      function invokeVantivIframe(data){
    	  var startTime;

    	  if (typeof EprotectIframeClient === 'undefined') {
    		  void 0;
    	  }
    	  payframeClient = new EprotectIframeClient(data);
    	  iFrame = document.getElementById("eProtect-iframe");
    	  function getPaypageRegId(){
    		  var message = {
    				  "id" : merchantID!=""?merchantID:"",
    				  "orderId" : vm.engagementId
    		  };
    		  startTime = new Date().getTime();
    		  payframeClient.getPaypageRegistrationId(message);
    		  return false;
    	  }
    	  invokeVantivIframe.getPaypageRegId = getPaypageRegId;
      }

      var payframeClientCallback = function (response) {
    	  if (response.response === '870') {
    		  void 0;
    		  if(response.paypageRegistrationId!=""){
    			  vm.isPayRegIdGenerated = true;
    			  vm.paymentId =  response.paypageRegistrationId;
    			  vm.lastFourDigit = response.lastFour;
    			  vm.cardType = response.type;
    			  vm.account.initialPayment.creditCard.expDate = response.expMonth+"/"+response.expYear;
    			  vm.expDate = response.expMonth+response.expYear;
    			  vm.configure = "";
    			  if(!vm.autopayIframe){
    				  submitPayment();
    			  }else{
    				  submitAutoPay();
    			  }
    		  }
    	  } else {
    		  if(!vm.autopayIframe){
    			  vm.paymentErrorCode = '';
    			  vm.initialPaymentStatus = true;
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  if(response.message!=undefined){
    				  vm.initialPaymentMessage = response.message;
    			  }else{
    				  vm.initialPaymentMessage = "The server encountered an internal error or misconfiguration and was unable to complete your request";
    			  }
    			  vm.initialPaymentPaid = false;
    		  }else{
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = response.message;
    			  vm.autoPayClass = 'alert-danger';
    		  }
    		  vm.isPayRegIdGenerated = false;
    		  void 0;
    	  }	
      }

      function getCardType(type){
    	  if(type){
    		  var cardValues = {
    				  MC:"1",
    				  VI:"2",
    				  AX:"3",
    				  DI:"4"
    		  };
    		  return (cardValues[type]);	
    	  }
      }
      //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End

      function enableSurvey() {
        void 0
        if (vm.storeInfo && vm.storeInfo.state == 'PR') {
          var scriptTag1 = document.createElement('script');
          scriptTag1.type = 'text/javascript';
          scriptTag1.src = 'https://script.crazyegg.com/pages/scripts/0074/6210.js';
          scriptTag1.async = true;
          document.head.appendChild(scriptTag1);
  
          var scriptTag2 = document.createElement('script');
          scriptTag2.type = 'text/javascript';
          scriptTag2.text = '(window.CE_API || (window.CE_API=[])).push(function(){ CE2.showSurvey("4375f353-36ee-4ad4-85c8-4de0f67d5a42"); });';
          document.head.appendChild(scriptTag2);

          setTimeout(function () {
            if (!!CE2) {
              void 0;
              var crazyEggSurveyTag = document.querySelector('crazyegg-survey');
              if (!crazyEggSurveyTag) {
                CE2.ipBlocked = false;
                CE2.showSurvey("4375f353-36ee-4ad4-85c8-4de0f67d5a42");
              }
            }
          }, 2000);
        }
      }
  }

})();

///////////////////////////////////////////
//////////////////////////////////////////
///   ORDER SUMMARY CONTROLLER   ////////
////////////////////////////////////////
///////////////////////////////////////
(function() {

	'use strict';

	angular
		.module('app.order')
		.controller('OrderSummary', OrderSummary);

	function OrderSummary(
		$scope,
		$rootScope,
		$http,
		$parse,
		$timeout,
		$compile,
		$routeParams,
		$location,
		$cookies,
		modalService,
		filterFilter,
		orderService,
		SharedDataSvc,
		authentication,
		updateEngagementItemFactory,
		orderEstimatePutFactory,
		datesFactory,
		stateRestrictFactory,
		getStoreInfoFactory,
		customerDataFactory,
		
		//Feature 9500 - VAN - Business Demo Changes - Starts
		productCategoriesFactory,
		//Feature 9500 - VAN - Business Demo Changes - Ends
		agreementFactory,
		productFactory
	) {
		/*jshint validthis: true */
		var orderSummary = this;

		orderSummary.editOrderMode = false;
		orderSummary.globalVars = {};
		orderSummary.globalVars.order = {
			currentEngagement: {},
			currentEngagementOrder: {}
		};
		$rootScope.hideMenuButtons = false;
		orderSummary.routeId = $routeParams.engagementId;
		orderSummary.editItemForm = {};
		orderSummary.addItemForm = [];
		orderSummary.SharedDataSvc = SharedDataSvc;
		orderSummary.globalmessage = SharedDataSvc.globalmessage;
		orderSummary.globalmessageShow = SharedDataSvc.globalmessageShow;
		orderSummary.invoiceReductionPayment = SharedDataSvc.invoiceReductionPayment; 
		orderSummary.invoiceAmountCalculate;
		orderSummary.hasItems = null;
		orderSummary.checkingOrder = true;
		orderSummary.allowExtendedWarranty = false;
		orderSummary.restrictions;
		orderSummary.processingFee;
		orderSummary.storeInfo;
		orderSummary.modal;
		orderSummary.associatePassFail = false;
		orderSummary.noEmpPass = false;
		orderSummary.approvalAmount;
		orderSummary.hasApproval = false;
		orderSummary.disableOrderButtons = false;
		orderSummary.disableOrderButtonsOnSetAbsent = false;
		orderSummary.disableReviewEstimate = false;
		orderSummary.submitInvoiceAndSalesDetails = submitInvoiceAndSalesDetails;
		orderSummary.invoiceNumber = '';
		orderSummary.associate = '';
		orderSummary.errors = {
			invoiceNumber: false,
			associate: false
		};
		orderSummary.prodCat;
		orderSummary.isProdCatValLoaded = false;
		// PUBLIC METHODS
		orderSummary.calcTotals = calcTotals;
		orderSummary.navigateTo = navigateTo;
		orderSummary.openOrderBuilder = openOrderBuilder;
		orderSummary.openSetMode = openSetMode;
		orderSummary.removeItemConfirm = removeItemConfirm;
		orderSummary.removeItemConfirmConfirm = removeItemConfirmConfirm;
		orderSummary.confirmOrder = confirmOrder;
		orderSummary.editModalRun = editModalRun;
		orderSummary.editOrderItem = editOrderItem;
		orderSummary.getOrderEstimate = getOrderEstimate;
		orderSummary.proceedOrderSave = proceedOrderSave;
		orderSummary.done = done;
		orderSummary.saveOrder = saveOrder;
		orderSummary.confirmId = confirmId;
		orderSummary.showModal = showModal;
		orderSummary.showDependencyNote = showDependencyNote;
		orderSummary.feeCheck = feeCheck;//AC 25341 code change
		$scope.submitEmployeePass = submitEmployeePass;
		$scope.submitEmployeePassWithOutSaveOrder = submitEmployeePassWithOutSaveOrder;
		$scope.closeSetItem = closeSetItem; //AC 26202 changes
		$scope.name;
		$scope.empPass;

		var existingData = false;

		//save Data before navigating to AP dashboard or training library
		$scope.$on("saveAppData",function(event, path) {
			void 0;
			//check if secondary number is filled then secondary phone type is valid

			if (!checkMinMax()) return;
			modalService.processing('Saving Order');
			if(path.indexOf("training") > -1) {
				orderService.save(SharedDataSvc.globalVars.order, 'InProgress');
				$rootScope.loginTrainingLibrary();
			}else {
				orderService.save(SharedDataSvc.globalVars.order, 'InProgress', '/dashboardAgreement');
			}



		/*	saveCustomerInfo().then(function() {
				if(path.indexOf("training") > -1) {
					$rootScope.loginTrainingLibrary();
				}else {
					$location.path("/dashboardAgreement");
				}
				return true;
			}); */
		});

		function init() {
			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			SharedDataSvc.globalVars.order.currentOrderSubTotal = 0.00;	//Story 28508 Changes
			// Check if an engagement is loaded in from the dashboard to shared data service
			if (SharedDataSvc.globalVars.order.currentEngagement.engagementId !== null
			&& SharedDataSvc.globalVars.order.currentEngagement.engagementId === Number(orderSummary.routeId)) {
				var sharedDataEngagement = SharedDataSvc.globalVars.order.currentEngagement;
				orderSummary.globalVars.order.currentEngagement = sharedDataEngagement;
				void 0;
				orderSummary.checkingOrder = false;
				orderSummary.showName = true;
				existingData = true;

				if (sharedDataEngagement.orderContent) {
					processOrderContent(sharedDataEngagement.orderContent);
				} else {
					orderSummary.hasItems = false;
					startNewOrder(sharedDataEngagement);
				}
				if (sharedDataEngagement.approvalAmount && sharedDataEngagement.applicationStatus !== "InProgress") {
					orderSummary.approvalAmount = parseFloat(sharedDataEngagement.approvalAmount);
				}
			}

			// Get state/store restrictions
			getStoreInfoFactory.getData()
				.then(function(storeInfo) {
					
					//Feature 9500 - VAN - Business Demo Changes - Starts
					productCategoriesFactory.getData().then(function(response) {
						void 0;
						var orderAWPitemgroupmessage;
						for(var i=0;i<response.itemGroupVendorSet.length;i++){
							if(response.itemGroupVendorSet[i].vendor.indexOf("|" + storeInfo.storeCode +"|")>=0){
								orderAWPitemgroupmessage = response.itemGroupVendorSet[i].awpItemGroupMessage;
							}
						}
			//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes-Start

					/*	if(orderAWPitemgroupmessage!=undefined){
							var openAlert = modalService.open({
								template: orderAWPitemgroupmessage,
								backdrop: 'static'
							});
						}*/
			//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes-Ends
					});
					//Feature 9500 - VAN - Business Demo Changes - Ends
					orderSummary.storeInfo = storeInfo;
				//VAN-2808-AWP-Displaying error messages if we give ESV and DF values between 41 to 99 in both Manned and Unmanned stores-starts	
					orderSummary.storeInfo.maximumDeliveryfee = parseFloat(orderSummary.storeInfo.maximumDeliveryfee + '.00');//VAN-1393- $400 Max ESV and Delivery fee
					orderSummary.storeInfo.maximumEsvfee = parseFloat(orderSummary.storeInfo.maximumEsvfee + '.00');//VAN-1393- $400 Max ESV and Delivery fee
					//VAN-2808-AWP-Displaying error messages if we give ESV and DF values between 41 to 99 in both Manned and Unmanned stores-Ends
					$rootScope.invoiceMethod = storeInfo.invoiceMethod;
					$scope.agreementAmount = storeInfo.minAgreementAmount;
					stateRestrictFactory.getAllStoreRestrictions(storeInfo.state)
					.then(function(response) {
						orderSummary.restrictions = response;
						void 0;
					});
			});

			// check routeParam for incoming order number
			if (typeof orderSummary.routeId !== 'undefined') {
				SharedDataSvc.globalVars.order.currentEngagementId = orderSummary.routeId;

				// load EXISTING record from routeParam
				customerDataFactory.findByEngagementId(orderSummary.routeId, !existingData)
				.then(
					handleEngagementData,
					function (response) {
						if (response.status === 303) {
							handleEngagementData(response.data.errorData);
						}
					}
				);
			} else {
				startNewOrder();
			}
			getStoreInfoFactory.getData()
				.then(
					// Success
					function(response) {
						if (response.allowExtendedWarranty === false) {
							orderSummary.allowExtendedWarranty = false;
						} else {
							orderSummary.allowExtendedWarranty = true;
						}
							// also check against state restrictions
					},
					// Error
					function(error) {

					}
				);

			orderEstimatePutFactory.getProcessingFee()
				.then(function(processingFee) {
					orderSummary.processingFee = processingFee;
				});

			//get product category list
			productFactory.getProductCategories().then(function(response) {
				orderSummary.prodCat = response.productCategories;
				orderSummary.isProdCatValLoaded = true;
			});
		}
		init();

		$scope.$on('orderUpdated', function() {
			orderSummary.hasItems = (SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length > 0) ? true : false;
			if (angular.isDefined(orderSummary.globalVars.order.currentEngagementOrder.orderContent)) {
				orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems;
				orderSummary.globalVars.order.currentEngagement = SharedDataSvc.globalVars.order.currentEngagement;
			} else {
				orderSummary.globalVars.order.currentEngagement = SharedDataSvc.globalVars.order.currentEngagement;
				orderSummary.globalVars.order.currentEngagementOrder = SharedDataSvc.globalVars.order.currentEngagementOrder;
			}
			orderSummary.calcTotals();
		});

		$rootScope.$on('ABC', function(){
			checkMinMax();
		});

		function showDependencyNote(index){
			void 0;
			if(index == orderSummary.dependentItem[0]){
				return true;
			}else{
				return false;
			}
		}

		function handleEngagementData(data) {
			void 0;
			orderSummary.checkingOrder = false;

			if (typeof data !== "object") {
				data = JSON.parse(data);
			}

			orderSummary.approvalAmount = data.approvalAmount || orderSummary.approvalAmount;
			SharedDataSvc.globalVars.order.approvalAmount = orderSummary.approvalAmount; //Story 28508 Changes
			// switch to VanEngagement for further data if it exists
			if (data.VanEngagement) {
				data = data.VanEngagement;
			}
			if (orderSummary.approvalAmount > 0 && data.applicationStatus === 'Accepted') {
				orderSummary.hasApproval = true;
			}
			if (existingData) {
				data = _.merge(SharedDataSvc.globalVars.order.currentEngagement, data);
				void 0;
				updateEngagementData();
			}

			orderSummary.globalVars.order.currentEngagement = data;
			SharedDataSvc.globalVars.order.currentEngagement = data;

			if (!existingData) {
				orderSummary.globalVars.order.currentEngagement.customerFirstName = data.customer.fName;
				orderSummary.globalVars.order.currentEngagement.customerLastName = data.customer.lName;
				orderSummary.globalVars.order.currentEngagement.customerPhoneNum = data.customer.phNum;
				orderSummary.globalVars.order.currentEngagement.approvalAmount = SharedDataSvc.manualOverrideData.approvalAmount;//data.approvalAmount;
				orderSummary.showName = true;
				$scope.name = data.customer.fName + ' ' + data.customer.lName;
				updateEngagementData();
			}
			if (!data.orderContent) {
				orderSummary.hasItems = false;
				startNewOrder(data);
			} else {
				processOrderContent(data.orderContent);
			}
		}

		function processOrderContent(orderContent) {
			// parse the order-items into decodable content
			var decodedOrderString;
			try {
				decodedOrderString = JSON.parse(window.atob(orderContent));
			} catch (e) {
				decodedOrderString = JSON.parse(orderContent);
			}
			void 0;
			// re-write order-items portion with decoded var
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent = decodedOrderString;
			orderSummary.globalVars.order.currentEngagementOrder.orderContent = decodedOrderString;
			if (decodedOrderString.orderItems.length === 0) {
				orderSummary.hasItems = false;
			} else {
				orderSummary.hasItems = true;
			}

			// calc totals
			orderSummary.calcTotals();
			if ($routeParams.confirm) {
				// confirmOrder();
			}
		}

		function openOrderBuilder() {
			var addItemModal = modalService.open(builderModal());
			addItemModal.result.then(function(result) {
				void 0;
				var index = orderService.addToOrder(result.item);
				orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];
				orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];
				//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Starts
				if ($rootScope.setModeForGroup == false)
					{
					result.isSetMode = false;
					}
				//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Ends
				if (result.isSetMode) {
					openSetMode(index);
				}
				//SharedDataSvc.editOrder = true;
			});
		}

		// Start order builder in set mode
		function openSetMode(itemIndex) {
			// retrieve builder modal with set mode on
			var addSetItemModal = modalService.open(builderModal({isSetMode:true, itemIndex: itemIndex}));
			orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];

			addSetItemModal.result.then(function(result){
				orderService.addToSet(result.item, result.itemIndex);

				if (result.isSetMode) {
					openSetMode(result.itemIndex);
				}
				orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];
				orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];

			});
		}

		function showModal(name, windowClass, title) {
		  windowClass = windowClass || '';
		  $rootScope.modalTitle = title || '';
		  orderSummary.modal = modalService.open({
			templateUrl: '../../../partials/modal_' + name + '.html',
			backdrop: 'static',
			windowClass: windowClass,
			scope: $scope
		  });
		}

		function confirmId() {
			void 0;
			SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 1;
			if(orderSummary.storeInfo.invoiceAtOrderconfirmation == 1){
				// set ID confirm to 0
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
					backdrop: 'static'
				});
			}
			else {
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					backdrop: 'static'
				});
			}
		}

		function submitEmployeePass(pass) {

			if(pass) {
				$scope.noEmpPass = false;
				// showModal('Processing', 'processing-modal');
				var auth = authentication.storePass;

				if(auth === pass) {
					void 0;
					saveOrder('Confirmed', '/dashboard');
				} else {
					// showModal('associatePassFail');
					orderSummary.associatePassFail = true;
					showModal('employeePass');
					void 0;
				}
			} else {
				$scope.noEmpPass = true;
				void 0;
			}
		}
//		AC 26202 code changes starts
		function closeSetItem(itemIndex, setMode) {
			void 0;
			if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length > 0 && SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex].setItems &&//AC 26528 code change 
					SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex].setItems.length > 0 ){
				return;
			} else {
				if(setMode){
					void 0;
					SharedDataSvc.itemDeleteTempIdx = itemIndex;
					orderSummary.removeItemConfirmConfirm();
				}
				
			}
		}
		//		AC 26202 code changes ends

		function submitEmployeePassWithOutSaveOrder(pass) {
			if(pass) {
				$scope.noEmpPass = false;
				// showModal('Processing', 'processing-modal');
				var auth = authentication.storePass;
				if(auth === pass) {
					void 0;
					//modalService.dismiss();
					var modal_invoiceAndSales = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/modal_invoiceAndDeliveryDetails.html',
						backdrop: 'static'
					});
				} else {
					// showModal('associatePassFail');
					orderSummary.associatePassFail = true;
					showModal('employeePassWithoutSave');
					void 0;
				}
			} else {
				$scope.noEmpPass = true;
				void 0;
			}
		}

		function submitInvoiceAndSalesDetails(){
			void 0;
			orderSummary.errors.invoiceNumber = false;
			orderSummary.errors.associate = false;
			if(orderSummary.invoiceNumber == ''){
				orderSummary.errors.invoiceNumber = true;
			}
			if(orderSummary.associate == ''){
				orderSummary.errors.associate = true;
			}
			if(!orderSummary.errors.invoiceNumber && !orderSummary.errors.associate){
				orderSummary.globalVars.order.currentEngagement.invoiceNum = orderSummary.invoiceNumber;
				orderSummary.globalVars.order.currentEngagement.salesAssociateID = orderSummary.associate;
				saveOrder('Confirmed', '/dashboard');
			}
		}

		function editModalRun(input) {
			SharedDataSvc.hasEditItemModalRun = input;
		};

		function editOrderItem(itemIndex, childIndex) {
			childIndex = isNaN(childIndex) ? null : childIndex;
			// set to array of index values if child index is set
			itemIndex = (childIndex !== null) ? [itemIndex, childIndex] : itemIndex;

			var editItemModal = modalService.open(builderModal({
				isEditMode:true,
				itemIndex: itemIndex
			}));
			editItemModal.result
				.then(
					// Success
					function(result) {
						orderService.addEditedItem(result.item, itemIndex);
						calcTotals();
						//AC13472 code changes start
						orderService.setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
						orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];

						orderService.evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
						orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];
						//AC13472 code changes end
					}
				);
		};


		// Set up the modal options for order builder/set mode
		function builderModal(modalData) {
			/*VAN 273,275 - VAN - PR Change Hecho to continue on order screen - Starts*/
			$scope.showDoneToContinue = false;
			if($rootScope.state=="PR"){
				$scope.showDoneToContinue = true;
			}
			/*VAN 273,275 - VAN - PR Change Hecho to continue on order screen - Ends*/
			
			var modalTemplateUrl = 'partials/modal_addOrderItem.html';
			var options = {
				isSetMode: false,
				isEditMode: false,
				itemIndex: 0
			};
			modalData = _.merge(options, modalData);

			if (modalData.isEditMode) modalTemplateUrl = 'partials/modal_editOrderItem.html';

			return {
				title: 'Add Item',
				size: 'sm',
				controller: 'OrderBuilder',
				controllerAs: 'builder',
				templateUrl: modalTemplateUrl,
				backdrop: 'static',
				scope: $scope, //AC 26202 code changes
				resolve: {
					// Data to be sent to the OrderBuilder controller
					modalData: function() {
						return modalData;
					}
				}
			};
		}

		function updateEngagementData() {
			SharedDataSvc.globalVars.order.currentEngagement = orderSummary.globalVars.order.currentEngagement;
		}

		function calcTotals() {
			var eachPrice = [0];
			if (angular.isDefined(orderSummary.globalVars.order.currentEngagementOrder.orderContent)) {
				if (orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems.length > 0) {
					eachPrice = _.pluck(orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems, 'itemPrice');
				}

				var orderSubTotal = 0;
				for (var i = 0, len = eachPrice.length; i < len; i++) {
					if (eachPrice < 0.01) {
						orderSubTotal = 0;
						SharedDataSvc.globalVars.order.currentOrderSubTotal = orderSummary.orderSubtotal;//Story 28508 Changes
						return
					} else {
						orderSubTotal += Number(eachPrice[i]);
					}
				};

				orderSummary.orderSubtotal = parseFloat(orderSubTotal, 2);
			} else {
				orderSummary.orderSubtotal = 0;
			}
			SharedDataSvc.globalVars.order.currentOrderSubTotal = orderSummary.orderSubtotal;//Story 28508 Changes
		};

		function startNewOrder(engagementData) {
			orderSummary.globalVars.order = {
				customerId: '',
				deliveryFee: 0,
				languagePreference: 'ENGLISH',
				calculatedTax:0,
				orderSubtotal:0,
				initialPayment:0,
				monthlyPayment:0,
				deliveryDate: '',
				currentEngagementId: engagementData.engagementId,
				currentEngagement: engagementData,
				currentEngagementOrder: {
					customerId: 0,
					dateStamp: '',
					modifiedDateStamp: '',
					engagementId: null,
					agreementHtId: null,
					vanStoreId: '',
					orderStatus: '',
					deliveryStatus: '',
					paymentStatus: '',
					salesStatus: '',
					agreementStatus: '',
					reconcilationStatus: null,
					orderContent: {
						extendedServiceAmount: 0.00,
						confirmed: 0,
						otherFee: 0,
						installationFee: 0,
						deliveryFee: 0,
						orderIdentifier: '',
						orderItems: []
					},
					applicationStatus: '',
					customerHtID: '', // HT2110000276
					customerHtStoreId: null,
					estimatedDeliveryDate: null,
					orderId: null,
					orderOriginator: 'AWP'
				}
			};
			SharedDataSvc.globalVars.order.currentEngagementOrder = orderSummary.globalVars.order.currentEngagementOrder;
		};

		function navigateTo(target) {
			$location.path(target);
		};

		function removeItemConfirm(index, childIndex) {
			childIndex = isNaN(childIndex) ? null : childIndex;
			var deletionIndexes = [index];
			if (childIndex !== null) {
				deletionIndexes = [index, childIndex];
			}
			SharedDataSvc.itemDeleteTempIdx = deletionIndexes;

			var confirmItemDeleteModal = modalService.open({
				title: 'Confirm Item Delete',
				scope: $scope,
				templateUrl: 'partials/confirm_confirmItemDeleteModal.tpl.html'
			});

			/*confirmItemDeleteModal.result.then(function(){
				SharedDataSvc.editOrder = true;
			});*/
		};

		function removeItemConfirmConfirm() {
			var removalIndex = SharedDataSvc.itemDeleteTempIdx;
			var orderItems = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems;
			var setItems;

			if (removalIndex.length > 1) {
				setItems = orderItems[removalIndex[0]].setItems;
				setItems = _.without(setItems, setItems[removalIndex[1]]);
//				AC 26202 code changes starts
//				orderItems[removalIndex[0]].setItems = setItems.length > 0 ? setItems : 1;
				if(setItems.length > 0){
					orderItems[removalIndex[0]].setItems = setItems;
				} else {
					orderItems = _.without(orderItems, orderItems[removalIndex[0]]);
				}
				//		AC 26202 code changes ends
			} else {
				orderItems = _.without(orderItems, orderItems[removalIndex]);
			}
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems = orderItems;

			if (orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems.length < 1) {
				orderSummary.hasItems = false;
				orderSummary.orderSubtotal = 0.00;
			} else {
				orderSummary.calcTotals();
			}
			orderService.setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];

			orderService.evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];

			$scope.$broadcast('orderUpdated');
		};

		function confirmOrder() {
			//if (orderSummary.orderSubtotal > 3000 || orderSummary.orderSubtotal > SharedDataSvc.globalVars.order.currentEngagement.approvalAmount) {
			if (!checkMinMax()) return;

			if (orderSummary.hasItems === false) {
				// alert 'you need to complete order'
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_confirmOrderModal.tpl.html',
					backdrop: 'static'
				});
			} else {
				
				// confirm 'are you sure you want to confirm?'
				if(!orderSummary.storeInfo.isManned || $cookies.getObject("isHybrid")) {
					var confirmOrderModal = modalService.open({
						title: 'Confirm ID',
						content: 'this is the content',
						show: true,
						scope: $scope,
						// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
						templateUrl: 'partials/modal_checkCustomerId.html',
						backdrop: 'static'
					});
				} else {
					if(orderSummary.storeInfo.invoiceAtOrderconfirmation == 1){
						// set ID confirm to 0
						SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
							backdrop: 'static'
						});
					}
					else {
						// set ID confirm to 0
						SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							backdrop: 'static'
						});
					}
				}
			}
		};

		function done() {
			//if (!checkMinMax()) return;
			modalService.processing('Saving Order');
			orderService.save(SharedDataSvc.globalVars.order, 'InProgress', '/dashboard');
		}

		function getOrderEstimate() {
			orderSummary.disableReviewEstimate = true;
			void 0;			
			SharedDataSvc.globalVars.estimateFlag = true;//AC21459 code changes

			if(orderSummary.approvalAmount && orderSummary.storeInfo.approvalAmtCheck){
				var orderAmountPayload = angular.copy(orderSummary.globalVars.order.currentEngagementOrder.orderContent);
				orderAmountPayload.customerId = orderSummary.globalVars.order.currentEngagement.customer.customerId;
				orderAmountPayload.inventoryList = [];
				_.forEach(orderAmountPayload.orderItems, function(item, index) {
					var orderItem = {
						"racBrand" : "ACP", // 'ESV' for extended
						"itemModelNumber" : "", // 'ExtSvc' for extended
						"racModelNumber" : productFactory.getRacModelNumber(orderSummary.prodCat, item, $rootScope.selectedLanguage),//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
						"depreciableLifeInMonths" : 24,
						"itemOptions" : productFactory.buildItemOptions(item),
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(item.itemPrice)
					};
					orderAmountPayload.inventoryList.push(orderItem);
				});
				delete orderAmountPayload.orderItems;

				orderEstimatePutFactory.checkApprovalAmt(orderAmountPayload).then(function(result){			
					if(SharedDataSvc.invoiceReductionPayment) {
						if (!checkMinMax()) return;
						orderSummary.proceedOrderSave(result);
						return;
					}
					if(result.responseCode == "AAC001"){
						if($rootScope.selectedLanguage == 'es'){
							$scope.checkApprovalAmtError = 'El monto total del pedido y de los servicios extendidos no puede exceder de $' 
														+ orderSummary.approvalAmount + '.'; //result.message;
						} else {
							$scope.checkApprovalAmtError = 'The total order and extended services amount can not exceed $' 
														+ orderSummary.approvalAmount + '.'; //result.message;
						}						
						var checkApprovalAmtErrorModal = modalService.open({
							scope: $scope,
							templateUrl: 'partials/alert_totalOrderAmountError.html',
							backdrop: 'static'
						});
						checkApprovalAmtErrorModal.result.then(function(){
							orderSummary.disableReviewEstimate = false;
						});
					} else if(result.responseCode == "AAC002"){
						if (!checkMinMax()) return;
						orderSummary.proceedOrderSave(result);
					}
				}, function(error){
					SharedDataSvc.reportRaygun(error);				
					orderSummary.disableReviewEstimate = false;
				});
				return false;
			} else {
				if (!checkMinMax()) return;
				orderSummary.proceedOrderSave(result);
			}			
		}

		function proceedOrderSave(result) {
			/*VAN-4036 -AWP - order screen invoice reduction payment changes 
			Payload is added with the extra value of inv red pay amt*/
			
			if(SharedDataSvc.invoiceReductionPayment) {
				if (result.responseCode == "AAC001") {
					void 0;
					if (result.message.split('-')[1].trim() == "INV,ESV,DEL") {
						if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
					else if (result.message.split('-')[1].trim() == "INV, ESV") {
						if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =
							Number(orderSummary.orderSubtotal) +
							Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount)
							- Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
					else if (result.message.split('-')[1].trim() == "INV, DEL") {
						if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =
							Number(orderSummary.orderSubtotal) +
							Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee)
							- Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
					else if (result.message.split('-')[1].trim() == "INV") {
						if(Number(orderSummary.orderSubtotal) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =
							Number(orderSummary.orderSubtotal) +
							- Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
				}
				else if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > Number(orderSummary.approvalAmount)) {
					if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > 1.4*(Number(orderSummary.approvalAmount))){
						var wrongvendor = modalService.open({
							show: true,
							templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
							backdrop: 'static'         
						});
						orderSummary.disableReviewEstimate = false;
						return;
					}
					orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =  Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
					void 0;
					SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
				}
				else {
					orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = ''; //VAN-4201 VAN generates IRP agreement pdf during docusign for zero invoiceReductionPayment amount
				}
			}
			void 0;
			orderService.save(orderSummary.globalVars.order, 'Pending');
			
			SharedDataSvc.globalVars.orderSummary = orderSummary.globalVars.order;
			var delFeePaymentOptionFlagValue = orderSummary.storeInfo.deliveryFeePaymentOption;
			var esvdelFeePaymentOptionFlagValue = orderSummary.storeInfo.esvTermOption;
			
			if(orderSummary.globalVars.order.currentEngagementOrder.orderContent.deliveryFee > 0 && (orderSummary.storeInfo.esvTermOption && orderSummary.storeInfo.deliveryFeePaymentOption)) {
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/modal_deliveryFeeOptions.html',
					backdrop: 'static'
				});
				confirmOrderModal.result.then(function(action) {
					showModal('Processing', 'processing-modal', 'Processing');
					SharedDataSvc.globalVars.estimateDeliveryOption = action;

					var orderData = orderEstimatePutFactory.compileData(orderSummary.globalVars.order, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, orderSummary.prodCat, action, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
					SharedDataSvc.globalVars.orderEstimatePut = orderData;

					// to calculate quotes on review estimate
					var currentDate = datesFactory.getCurrentDate();
					var quoteDates = datesFactory.setQuoteDates(currentDate);

					orderSummary.orderDataArray = [_.clone(orderData),
						_.clone(orderData),
						_.clone(orderData)];

					_.forEach(quoteDates, function(paymentDate, i) {
						orderSummary.orderDataArray[i].requestDte = currentDate;
						orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
					});

					SharedDataSvc.globalVars.disabledEstimateOrderConfirmButton = orderSummary.checkingOrder ||
							!orderSummary.hasItems || !orderSummary.hasApproval || (orderSummary.disableOrderButtons) ||
							(orderSummary.disableOrderButtonsOnSetAbsent == 'true');

					SharedDataSvc.globalVars.orderSummary = orderSummary;

					SharedDataSvc.globalVars.orderSummary.order = _.clone(SharedDataSvc.globalVars.order);

					SharedDataSvc.globalVars.orderSummary.order.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
					SharedDataSvc.globalVars.orderSummary.order.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

					SharedDataSvc.globalVars.orderSummary.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
					SharedDataSvc.globalVars.orderSummary.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

					_.forEach(quoteDates, function(paymentDate, i) {
						orderSummary.orderDataArray[i].requestDte = currentDate;
						orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
					});

					agreementFactory.setQuotes(orderSummary.orderDataArray)
							.then(function () {
								orderSummary.disableReviewEstimate = false;
								orderSummary.quotes = agreementFactory.quotes;
								_.forEach(quoteDates, function (paymentDate, i) {
									orderSummary.quotes[i].paymentDueDate = moment(paymentDate.date);
								});
								var target = '/estimate/' + $routeParams.engagementId;
								SharedDataSvc.globalVars.estimateReturn = orderSummary.quotes[0];
								SharedDataSvc.globalVars.estimateReturnAll = orderSummary.quotes;

								orderSummary.navigateTo(target);
							}, function (response) {
								// sending error to Raygun
								SharedDataSvc.reportRaygun(response);
								orderSummary.navigateTo('/dashboard');
							});

				});
			}
			else {
				showModal('Processing', 'processing-modal', 'Processing');
				var orderData = orderEstimatePutFactory.compileData(orderSummary.globalVars.order, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, orderSummary.prodCat, undefined, $rootScope.selectedLanguage);//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
				SharedDataSvc.globalVars.orderEstimatePut = orderData;

				// to calculate quotes on review estimate
				var currentDate = datesFactory.getCurrentDate();
				var quoteDates = datesFactory.setQuoteDates(currentDate);

				orderSummary.orderDataArray = [_.clone(orderData),
					_.clone(orderData),
					_.clone(orderData)];

				_.forEach(quoteDates, function(paymentDate, i) {
					orderSummary.orderDataArray[i].requestDte = currentDate;
					orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
				});

				SharedDataSvc.globalVars.disabledEstimateOrderConfirmButton = orderSummary.checkingOrder ||
						!orderSummary.hasItems || !orderSummary.hasApproval || (orderSummary.disableOrderButtons) ||
						(orderSummary.disableOrderButtonsOnSetAbsent == 'true');

				SharedDataSvc.globalVars.orderSummary = orderSummary;

				SharedDataSvc.globalVars.orderSummary.order = _.clone(SharedDataSvc.globalVars.order);

				SharedDataSvc.globalVars.orderSummary.order.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
				SharedDataSvc.globalVars.orderSummary.order.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

				SharedDataSvc.globalVars.orderSummary.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

				_.forEach(quoteDates, function(paymentDate, i) {
					orderSummary.orderDataArray[i].requestDte = currentDate;
					orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
				});
				/*VAN 774 - Biweekly renewal payments Long Term - Starts*/	
				if(!orderSummary.storeInfo.lowInitialPaymentEnabled){
					agreementFactory.setQuotes(orderSummary.orderDataArray)
					.then(function () {
						orderSummary.disableReviewEstimate = false;
						orderSummary.quotes = agreementFactory.quotes;
						_.forEach(quoteDates, function (paymentDate, i) {
							orderSummary.quotes[i].paymentDueDate = moment(paymentDate.date);
						});
						var target = '/estimate/' + $routeParams.engagementId;
						SharedDataSvc.globalVars.estimateReturn = orderSummary.quotes[0];
						SharedDataSvc.globalVars.estimateReturnAll = orderSummary.quotes;

						orderSummary.navigateTo(target);
					}, function (response) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(response);
						orderSummary.navigateTo('/dashboard');
					});
				/*VAN-858 - Quad Weekly Payment Frequency - Starts*/	
				}else if(orderSummary.storeInfo.lowInitialPaymentEnabled && orderSummary.storeInfo.paymentModeEnabled!=undefined){
				/*VAN-858 - Quad Weekly Payment Frequency - Ends*/

					/*VAN-4074 - AWP_Include the HT object in payload of Quotev2 */

					if (SharedDataSvc.invoiceReductionPayment && SharedDataSvc.invoiceReductionPaymentAmount > 0) {
						var orderItem = {
							"racBrand": "IVR",
							"itemModelNumber": "",
							"racModelNumber": "HTIVR-DEPAMT",
							"depreciableLifeInMonths": 24,
							"itemOptions": "1",
							"itemOriginalCost": (SharedDataSvc.invoiceReductionPaymentAmount * 100)
						}
						orderItem.itemModelNumber = "";
						orderItem.racBrand = "IVR";
						orderItem.racModelNumber = "HTIVR-DEPAMT"
						orderItem.itemOptions = "1";
						orderItem.itemOriginalCost = (SharedDataSvc.invoiceReductionPaymentAmount * 100);
						orderItem.depreciableLifeInMonths = 24;
						void 0;
						orderSummary.orderDataArray[0].inventoryList.push(orderItem);
					}
					orderSummary.orderDataArray[0].paymentMode = 'Monthly';
					orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
					.then(function (monthlyResponse) {
						void 0;
						orderSummary.disableReviewEstimate = false;
						orderSummary.quotesMonthly = monthlyResponse;
						/*orderSummary.quotesMonthly.paymentDueDate = moment(paymentDate.date);*/
						/*_.forEach(quoteDates, function (paymentDate, i) {
							orderSummary.quotesMonthly[i].paymentDueDate = moment(paymentDate.date);
						});*/
						orderSummary.quotesMonthly.paymentDueDate  = quoteDates[0].date;
						orderSummary.orderDataArray[0].paymentMode = 'Biweekly';
						orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
						.then(function (biweeklyResponse) {
							void 0;
							orderSummary.disableReviewEstimate = false;
							orderSummary.quotesBiweekly= biweeklyResponse;
							/*orderSummary.quotesMonthly.paymentDueDate = moment(paymentDate.date);*/
							/*_.forEach(quoteDates, function (paymentDate, i) {
								orderSummary.quotesMonthly[i].paymentDueDate = moment(paymentDate.date);
							});*/
							orderSummary.quotesBiweekly.paymentDueDate  = quoteDates[0].date;
							/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
							orderSummary.orderDataArray[0].paymentMode = 'FOURWEEK';
							orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
							.then(function (quarterlyResponse) {
								void 0;
								orderSummary.disableReviewEstimate = false;
								orderSummary.quotesQuarterly= quarterlyResponse;
								
								orderSummary.quotesQuarterly.paymentDueDate  = quoteDates[0].date;
								var target = '/estimate/' + $routeParams.engagementId;
								SharedDataSvc.globalVars.estimateReturnMonthly = orderSummary.quotesMonthly;
								SharedDataSvc.globalVars.estimateReturnBiweekly = orderSummary.quotesBiweekly;
								SharedDataSvc.globalVars.estimateReturnQuarterly = orderSummary.quotesQuarterly;
								orderSummary.navigateTo(target);
							},function (quarterlyResponse) {
								SharedDataSvc.reportRaygun(quarterlyResponse);
								orderSummary.navigateTo('/dashboard');
							});
							/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
						}, function (biweeklyResponse) {
							SharedDataSvc.reportRaygun(biweeklyResponse);
							orderSummary.navigateTo('/dashboard');
						});
					}, function (monthlyResponse) {
						SharedDataSvc.reportRaygun(monthlyResponse);
						orderSummary.navigateTo('/dashboard');
					});
				}else{
					/*VAN-4074 - AWP_Include the HT object in payload of Quotev2 */

					if (SharedDataSvc.invoiceReductionPayment && SharedDataSvc.invoiceReductionPaymentAmount > 0) {
						var orderItem = {
							"racBrand": "IVR",
							"itemModelNumber": "",
							"racModelNumber": "HTIVR-DEPAMT",
							"depreciableLifeInMonths": 24,
							"itemOptions": "1",
							"itemOriginalCost": (SharedDataSvc.invoiceReductionPaymentAmount * 100)
						}
						orderItem.itemModelNumber = "";
						orderItem.racBrand = "IVR";
						orderItem.racModelNumber = "HTIVR-DEPAMT"
						orderItem.itemOptions = "1";
						orderItem.itemOriginalCost = (SharedDataSvc.invoiceReductionPaymentAmount * 100);
						orderItem.depreciableLifeInMonths = 24;
						void 0;
						orderSummary.orderDataArray[0].inventoryList.push(orderItem);
					}
					orderSummary.orderDataArray[0].paymentMode = '';
					orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
					.then(function (response) {
						orderSummary.disableReviewEstimate = false;
						SharedDataSvc.globalVars.estimateReturn = response;
						/*orderSummary.quotesMonthly.paymentDueDate = moment(paymentDate.date);*/
						/*_.forEach(quoteDates, function (paymentDate, i) {
							orderSummary.quotesMonthly[i].paymentDueDate = moment(paymentDate.date);
						});*/
						/*orderSummary.quotesBiweekly.paymentDueDate  = quoteDates[0].date;*/
						var target = '/estimate/' + $routeParams.engagementId;
						orderSummary.navigateTo(target);
					}, function (response) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(response);
						orderSummary.navigateTo('/dashboard');
					});
				}
				/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/	
			}
		}
		
		function checkMinMax() {
			void 0;
			void 0;
			
			//AC-21473 changes
			/*if (SharedDataSvc.globalVars.order.currentEngagement.applicationStatus != 'Accepted') {
				$scope.approvalAmount = orderSummary.approvalAmount;
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_manualOrderConfirmError.tpl.html',
					backdrop: 'static'
				});
				confirmOrderModal.result.then(function(action){
					if(action){
						saveOrder('InProgress', '/dashboard');
					}
				});
				return false;
			}*/

			if (!orderSummary.storeInfo.approvalAmtCheck && (orderSummary.orderSubtotal > orderSummary.approvalAmount)) {				
					if(orderSummary.approvalAmount > 0){
						$scope.approvalAmount = orderSummary.approvalAmount;
					}else{
						$scope.approvalAmount = 0;
					}
					/*VAN-4036 -AWP - order screen invoice reduction payment changes 
					Hide the max order alert when the invoice reduction payment flag is on*/
					
					if( !SharedDataSvc.invoiceReductionPayment) {
						var confirmOrderModal = modalService.open({
							show: true,
							scope: $scope,
							controllerAs: 'orderSummary',
							templateUrl: 'partials/alert_maxOrderModal.tpl.html',
							backdrop: 'static'
						});
					}
					orderSummary.disableReviewEstimate = false;
					return false;
			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - Start
//			if (orderSummary.orderSubtotal > 3000) {
//				$scope.approvalAmount = 3000;
//				var confirmOrderModal = modalService.open({
//					show: true,
//					scope: $scope,
//					controllerAs: 'orderSummary',
//					templateUrl: 'partials/alert_maxOrderModal.tpl.html',
//					backdrop: 'static'
//				});
//				orderSummary.disableReviewEstimate = false;
//				return false;
//			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - End

			if (!orderSummary.orderSubtotal || orderSummary.orderSubtotal < $scope.agreementAmount) {
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_minOrderModal.tpl.html',
					backdrop: 'static'
				});
				orderSummary.disableReviewEstimate = false;
				return false;
			}

			return true;
		}

		// TODO - remove this later, use saveOrder in service
		function saveOrder(orderStatus, navigateTo) {
			// check for unneeded param
			$location.search('confirm', null);
			// Start VAN 3066
			if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee == ''){
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee = 0;
			}
			if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount == ''){
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount = 0;
			}
			/*VAN-4036 -AWP - order screen invoice reduction payment changes 
			passing the amount in request payload to save order service*/			
			if(SharedDataSvc.invoiceReductionPayment) {
				if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > Number(orderSummary.approvalAmount)) {
					orderSummary.invoiceAmountCalculate =  Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = orderSummary.invoiceAmountCalculate.toFixed(2);
				}
				else {
					void 0;
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount  = 0;
				}
			}
			// End
			orderService.save(SharedDataSvc.globalVars.order, orderStatus, navigateTo);
		};
		
		//AC 25341 Code changes starts		
		function feeCheck(value, name) {
			if (value && String(value).indexOf('.') > -1) {
				var values = String(value).split('.');
				if (values[1].length > 2) { 
					if(name == 'extendedServiceAmount') {
						orderSummary.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					} else if (name == 'deliveryFee'){
						orderSummary.globalVars.order.currentEngagementOrder.orderContent.deliveryFee = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					}			
				}
			}
		}
		//AC 25341 Code changes ends

		$scope.$on('$locationChangeStart', function() {
				//SharedDataSvc.globalVars.order.currentEngagement = {};
				//SharedDataSvc.globalVars.order.currentEngagementOrder = {};
				void 0;
		});
	}
	OrderSummary.$inject = ["$scope", "$rootScope", "$http", "$parse", "$timeout", "$compile", "$routeParams", "$location", "$cookies", "modalService", "filterFilter", "orderService", "SharedDataSvc", "authentication", "updateEngagementItemFactory", "orderEstimatePutFactory", "datesFactory", "stateRestrictFactory", "getStoreInfoFactory", "customerDataFactory", "productCategoriesFactory", "agreementFactory", "productFactory"];

})();

///////////////////////////////////////////
//////////////////////////////////////////
///   DROPDOWN CONTROLLER   /////////////
////////////////////////////////////////
///////////////////////////////////////
(function() {

	'use strict';

	angular
		.module('app.order')
		.controller('OrderBuilder', OrderBuilder);

	function OrderBuilder(
		$scope,
		$rootScope,
		$http,
		$filter,
		$timeout,
		filterFilter,
		SharedDataSvc,
		authentication,
		productCategoriesFactory,
		getStoreInfoFactory,
		// below comes from resolve when creating the modal from a controller
		modalData,
		orderService
	) {
		/*jshint validthis: true */
		var builder = this;

		$rootScope.hideMenuButtons = false;

		// function to submit the form after all validation has occurred
		builder.inventoryInfo = [];
		builder.inventoryInfo.productCategories = [];
		builder.addItemReturn = {
			quoteItem: {
				itemId: 0,
				unitType: '',
				itemDesc: '',
				qty: 0,
				discountID: '',
				price: 0,
				subType: ''
			}
		};
		builder.addItemForm = {};
		// builder.addItemForm.customAttributes = [];
		builder.editItemForm = {};

		builder.isSetMode = modalData.isSetMode;
		builder.isEditMode = modalData.isEditMode;
		builder.itemIndex = modalData.itemIndex;
		builder.isSetItem = false;

		builder.editOrderMode = false;
		//Feature 9634 	VAN - Technology and Music New Categories and Subtypes - Starts
		builder.showDepartment = true;
		builder.singletonDepartment = false;
		//Feature 9634 	VAN - Technology and Music New Categories and Subtypes - Ends
		builder.showItems = false;
		builder.showBrand = false;
		builder.showColors = false;
		builder.showOther = false;
		builder.showAccessories = false;
		builder.showQuantity = false;
		builder.showMaterials = false;
		builder.orderSub = [{
			deliveryAmt: 0,
			otherAmt: 0,
			sessionId: 0,
			taxRate: 6.5,
			extendService: 0.00,
			customer: {
				firstName: "",
				lastName: ""
			},
			quoteItem: [],
			orderSpecifics: {
				engagementId: 0,
				orderSubtotal: 0,
				calculatedTax: 0
			}
		}];
		builder.sharedDataSvc = SharedDataSvc;
		builder.noItemsMessage = true;
		builder.globalmessage = SharedDataSvc.globalmessage;
		builder.globalmessageShow = SharedDataSvc.globalmessageShow;
		builder.invoiceReductionPayment = SharedDataSvc.invoiceReductionPayment;
		builder.shownItems = [];
		builder.currentSelectionObject = {};
//		builder.itemMaximumPrice = 3000; //Story 27936 Changes
		builder.itemMaximumPrice; //Story 27936 Changes
		builder.itemMinimumPrice = 0;
		builder.stepField = 2;
		builder.minQtyField = 4;
		builder.maxQtyField = 14;
		// PUBLIC METHODS
		builder.resetFields = resetFields;
		// builder.addToOrder = addToOrder;
		builder.enterSetMode = enterSetMode;
		builder.enterEditMode = enterEditMode;
		builder.addQuantityValue = addQuantityValue;
		builder.editQuantityValue = editQuantityValue;
		builder.itemSize = []; // AC-13373
		builder.validateTotals = validateTotals;// Story 28508 Changes
		builder.resetFlag = resetFlag;// Story 28508 Changes
		builder.itemPriceCheck = itemPriceCheck;//Defect 25256 code change
		builder.isSpanishStore;
		builder.validSetItem = false;
		builder.lastItemPrice;
		builder.maxLimit = false;
		var currentSelection = [];
		var currentSelectionObjects = [];
		var visibleFields = [];
		var quantityItems = [
			"Nightstand",
			"Filing Cabinet",
			"Bookcase",
			"Chair",
			"End Table",
			"Recliner"
		];
		var excludedCategories = [];
		//Story 32271 code chnages starts
		var excludedItems = [];
		var excludedSetItems = [];
		//Story 32271 code chnages ends
		
		/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Starts*/
		var itemPrice = [];
		/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Starts*/
		
		////////////////////////////////////////////////
		///  DATA SERVICES   ///////////////////////////
		////////////////////////////////////////////////
		init();

		function init() {
			getStoreInfoFactory.getData()
				.then(
				// Success
				function(response){
					if (response.excludedCategories) {
						excludedCategories = response.excludedCategories;
					}
					//Story 32271 code chnages starts
					if(response.excludedItems){
						excludedItems = response.excludedItems;
					} 					
					if(response.excludedSetItems){
						excludedSetItems = response.excludedSetItems;
					}
					
					/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Starts*/
					if(response.itemPrice){
						itemPrice = response.itemPrice;
					}
					/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Ends*/
					
					//Story 32271 code chnages ends
					//Story 27936 Changes starts
					builder.storeMaxPrice = response.maxItemPrice;
					$scope.minItemAmount = response.minItemAmount;
					SharedDataSvc.globalVars.storeCode = response.storeCode;//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements
					$scope.storeCode = response.storeCode;//Story 32270 code change
					/* Commenting the line below to fix 25443 uat defect */
				//	builder.itemMinimumPrice = $scope.minItemAmount;
                    builder.itemMaximumPrice = response.maxItemPrice;//this is needed because Edit screen reads the initial value of builder.itemMaximumPrice and we have set it to undefined.
                    //Story 27936 Changes ends
                    builder.mattressMaxPrice = response.mattressMaxPrice;// Story 28573 code changes
					prepareProductData();
					if($rootScope.selectedLanguage == 'es'){
						builder.isSpanishStore = true;
					}else{
						builder.isSpanishStore = false;	
					}
					
				},
				// Fail
				function(response){}
			);

		}

		function prepareProductData() {
			// add the data, map out the dropdowns
			productCategoriesFactory.getData().then(function(response) {
				void 0;
				builder.inventoryInfo = response;
				void 0;
				var productCategories = _.clone(builder.inventoryInfo.productCategories);
				// APPEND Children to their parent objects; basically re-map the JSON data
				_(productCategories).forEachRight(function(obj, index, collection) {
					if (obj.parent) {
						// find the parent object
						var parent = _.find(collection, {
							labelEn: obj.parent
						});
						// check if it already has a collection started
						if (!parent.items) {
							parent.items = [obj];
						} else {
							parent.items.push(obj);
						}
						// remove object from main collection, for organization
						_.pull(collection, obj);
					}
					if (excludedCategories.indexOf(obj.labelEn) > -1) {
						_.pull(collection, obj);
					}
					
					
				});
				


				/* Filtering the ProductCategories items as well i.e. on more level deep to filter out excluded categories present there */
				var productCat = productCategories;
				void 0;
				for(var z=0; z < productCategories.length; z++){

					if( _.isArray(productCategories[z].items) ){
						
						var filteredItem = [];
						for(var x = 0; x < productCategories[z].items.length; x++){
							
							if( excludedCategories.indexOf(productCategories[z].items[x].labelEn) == -1 ){
								filteredItem.push(productCategories[z].items[x]);
							}	
						}
						productCat[z].items = filteredItem;	
					}	
				}
				
				void 0;
				
				
				// builder.inventoryInfo = data;
				builder.productList = productCat;
				// builder.productDepartments = _.where(builder.productsList, {parent:undefined});
				builder.brandSets = builder.inventoryInfo.brandSets;
				builder.colorSets = builder.inventoryInfo.colorSets;
				builder.materialSets = builder.inventoryInfo.materialSets;
				SharedDataSvc.globalVars.itemGroupVendorSet = builder.inventoryInfo.itemGroupVendorSet;//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements
				builder.minimumPriceToDeduct = builder.inventoryInfo.minimumPriceToDeduct.priceToDeduct;//Feature 8384 - AWP, Kiosk, Services: Each item must have a maximum $ amount, VENDOR level setting
				if (builder.isSetMode) {
					enterSetMode(modalData.itemIndex);
				}
				if (builder.isEditMode) {
					void 0;
					enterEditMode(modalData.itemIndex);
				}
				//Feature 9634 	VAN - Technology and Music New Categories and Subtypes - Starts
				if(productCat.length == 1 && productCat[0].items.length == 1)
				{
					builder.showDepartment = false;
					builder.singletonDepartment = true;
					showField('showItems');
					hideField(['showCategory', 'showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
					var items = [];
					for(var x=0; x<productCat[0].items[0].items.length; x++){
						items.push(productCat[0].items[0].items[x]);
					}
					builder.items = items;
				}
				//Feature 9634 	VAN - AWP/Kiosk Technology and Music New Categories and Subtypes - Ends
			}); 
			
				
		}
		
		////////////////////////////////////////////////
		///  WATCHERS   ////////////////////////////////
		////////////////////////////////////////////////
		$scope.$watch("builder.currentSelectionObject", function(newValue, oldValue) {
			// console.log("Changed", currentSelection, newValue);
		});

		// SELECTION - DEPARTMENT
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedDepartmentItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				//AC 15028 code changes starts
				hideField(['showCategory', 'showItems', 'showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				builder.currentSelectionObject = {};
				//AC 15028 code changes ends
				return;
			}
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if (newValue.dependencyAlertMessageEn) {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}
			else{
				builder.productMessage = '';
			}*/
			builder.productMessage = '';
			//VAN-2881-Remove ALL Item Dependencies -Ends
			if (newValue.generalCategoryAlertMessageEn) {
				builder.generalCategoryMessage = newValue.generalCategoryAlertMessageEn;
			}
			else{
				builder.generalCategoryMessage = '';
			}
			
			setSelections('selectedDepartmentItem', newValue);
			showField('showCategory');
			hideField(['showItems', 'showBrands', 'showMaterials', 'showCustomAttributes']);
		});

		// SELECTION - CATEGORY
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedCategoryItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				hideField(['showItems', 'showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				return;
			}
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if (newValue.dependencyAlertMessageEn) {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}else{
				builder.productMessage = '';
			}*/
			builder.productMessage = '';
			//VAN-2881-Remove ALL Item Dependencies -Ends
			if (newValue.generalCategoryAlertMessageEn) {
				builder.generalCategoryMessage = newValue.generalCategoryAlertMessageEn;
			}
			else{
				builder.generalCategoryMessage = '';
			}

			setSelections('selectedCategoryItem', newValue);
			if (newValue.items && newValue.items != '') {
				showField('showItems');
				hideField(['showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				//Story 32271 code chnages starts
				if( _.isArray(newValue.items) && !builder.isSetMode ){
					var filteredItem = [];
					for(var y=0; y<newValue.items.length; y++){
						if( excludedItems.indexOf(newValue.items[y].labelEn) == -1 ){
							filteredItem.push(newValue.items[y]);
						}	
					}
					newValue.items = filteredItem;	
				}
				//Story 32271 code chnages ends
			} else if (newValue.brandSet) {
				builder.itemBrands = _.find(builder.brandSets, {
					name: newValue.brandSet
				}).brands;
				showField('showBrands');
				hideField(['showItems', 'showMaterials', 'showCustomAttributes', 'showColors']);
			} else if (newValue.materialSet) {
				builder.itemMaterials = _.find(builder.materialSets, {
					name: newValue.materialSet
				}).materials;
				showField('showMaterials');
				hideField(['showItems', 'showBrands', 'showCustomAttributes', 'showColors']);
			}
		});

		// SELECTION - CATEGORY ITEM
		// ===============================================================================
		$scope.$watchCollection("builder.addItemForm.selectedItemItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				hideField(['showBrands', 'showMaterials', 'showAccessories', 'showCustomAttributes', 'showColors']);
				return;
			}
			void 0;

			setSelections('selectedItemItem', newValue);

			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if (newValue.dependencyAlertMessageEn && $rootScope.selectedLanguage=="en") {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}else if(newValue.dependencyAlertMessageEs && $rootScope.selectedLanguage=="es" ){
				builder.productMessage = newValue.dependencyAlertMessageEs;
			}
			else{
				builder.productMessage = '';
			}*/
			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/ 
			//checkDependencyItems(newValue,SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems); // VAN-2502 Changes
			builder.productMessage = '';
			//VAN-2881-Remove ALL Item Dependencies -Ends
			// VAN 2535 Changes Start
			if(newValue.qtyFieldDefault){
				builder.addItemForm.quantity = newValue.qtyFieldDefault;
			}

			// Looks for a sub-level mentioning "Accessories" in the selected item
			var hasAccessories = newValue.labelEn.indexOf("Accessories") > 0;
			if (hasAccessories) {
				// show them if it exists
				if(newValue.items){
					builder.itemAccessories = newValue.items;
					showField('showAccessories');
					hideField(['showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				}else{
					hideField(['showBrands', 'showAccessories', 'showMaterials', 'showCustomAttributes', 'showColors']);
				}
			} else if (newValue.brandSet) {
				// If the item has brands, show them
				showField('showBrands');
				builder.itemBrands = _.find(builder.brandSets, {
					name: newValue.brandSet
				}).brands;
				hideField(['showAccessories', 'showMaterials', 'showCustomAttributes', 'showColors']);
			} else if (newValue.materialSet) {
				builder.itemMaterials = _.find(builder.materialSets, {
					name: newValue.materialSet
				}).materials;
				showField('showMaterials');
			} else {
				hideField(['showBrands', 'showAccessories', 'showMaterials', 'showCustomAttributes', 'showColors']);
			}

			hideField('showQuantity');
			if (quantityItems.indexOf(builder.addItemForm.selectedItemItem.labelEn) > -1) {
				showField('showQuantity');
			}
			if (builder.addItemForm.selectedItemItem.customAttributeGroups.length > 0) {
				builder.customAttributeGroups = builder.addItemForm.selectedItemItem.customAttributeGroups;
				showField('showCustomAttributes');
			}else {//AC 15028 code changes starts
				builder.customAttributeGroups = [];
				hideField('showCustomAttributes');
			}//AC 15028 code changes ends
		});

		// VAN-2502 Changes Start
		function checkDependencyItems(newValue,orderItems)
		{
			var isDependantAlreadyExists = false;
			for(var i=0;i<orderItems.length;i++)
			{
				var setItem = orderItems[i];
				if(setItem.setItems.length != 'undefined')
				{
					for(var j=0;j<setItem.setItems.length;j++)
					{
						var eachItem = setItem.setItems[j];
						if(newValue.dependencies && newValue.dependencies.length > 0){ //AC 13512 code changes
							for(var k=0;k<newValue.dependencies.length;k++)
							{
								if(eachItem.category === (newValue.dependencies[k].dependentProduct))
								{
									isDependantAlreadyExists=true;
								}
							}
						}//AC 13512 code changes
					}
				}
			}
			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
			if (!isDependantAlreadyExists && $rootScope.selectedLanguage=="en" ) {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}else if(!isDependantAlreadyExists && $rootScope.selectedLanguage=="es"){
				builder.productMessage = newValue.dependencyAlertMessageEs;
			}else {
				builder.productMessage = '';
			}
			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
		}
		// VAN-2502 Changes End

		// SELECTION - BRAND
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedBrandItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				return;
			}
			setSelections('selectedBrandItem', newValue);
			builder.showOther = false;
			builder.showColors = false;

			checkColorSet(builder.addItemForm.selectedCategoryItem);

			// Check if an item level is selected, then look for custom attributes
			if (typeof builder.addItemForm.selectedItemItem != "undefined") {
				if (builder.addItemForm.selectedItemItem.customAttributeGroups.length > 0) {
					builder.customAttributeGroups = builder.addItemForm.selectedItemItem.customAttributeGroups;
					showField('showCustomAttributes');
				}
				checkColorSet(builder.addItemForm.selectedItemItem);
			} else if (builder.addItemForm.selectedCategoryItem.customAttributeGroups.length > 0) {
				builder.customAttributeGroups = builder.addItemForm.selectedCategoryItem.customAttributeGroups;
				showField('showCustomAttributes');
			} else {
				//AC 15028 code changes starts
				builder.customAttributeGroups = [];
				hideField('showCustomAttributes');
				//AC 15028 code changes ends
			}
		});

		// SELECTION - MATERIAL
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedMaterialsItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			if (newValue === null) {
				builder.showColors = false;
				return;
			}
			setSelections('selectedMaterialsItem', newValue);
			builder.showColors = false; //VAN-2501

			checkColorSet(newValue);
		});
		$scope.$watch("builder.editItemForm.selectedMaterialsItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			checkColorSet(newValue);
		});

		// SELECTION - COLOR
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedColorItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			// Added for VAN-2501
			if (newValue === null) {
				return;
			}
			// End

			setSelections('selectedColorItem', newValue);
			// $scope.currentSelectionObject = newValue;

			hideField('showOther');
			if (newValue.labelEn === 'Other') {
				showField('showOther');
			};
		});

		// SELECTION - ACCESSORY
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedAccessoryItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			if (newValue === null) {
				builder.showAccessoryBrand = false;
				return;
			}
			setSelections('selectedAccessoryItem', newValue);

			// Set the accessory brand set
			if (newValue.brandSet) {
				// Use the accessory's brandset if it has one
				builder.accessoryBrandLabel = newValue.labelEn;
				builder.accessoryBrands = _.find(builder.brandSets, {
					name: newValue.brandSet
				}).brands;
				showField('showAccessoryBrand');
			} else {
				hideField("showAccessoryBrand");
				// Otherwise assume the accessory's parent has a brand set
				/*builder.accessoryBrandLabel = "Brand";
				builder.accessoryBrands = _.find(builder.brandSets, {
					name: builder.addItemForm.selectedItemItem.brandSet
				}).brands; */
			}
			
		});

		$scope.$watch("builder.addItemForm.selectedBedSizeItem", function(newValue, oldValue) {
			if (newValue === oldValue) return;
			setSelections('selectedBedSizeItem', newValue);
		});

		/////////////////////////////////////////////////////
		/////   METHODS  ////////////////////////////////////
		/////////////////////////////////////////////////////

		function setSelections(modelName, newObject) {
			if(newObject){ //AC 15028 code changes
				builder.currentSelectionObject = newObject;
			}
			if (currentSelection.indexOf(modelName) < 0) {
				currentSelection.push(modelName);
				currentSelectionObjects.push(newObject);
			} else {
				var i = currentSelection.length - 1;
				var modelIndex = currentSelection.indexOf(modelName);
				if (i > modelIndex) { //Story 27936 code Changes - when changing different level dropdown
					while(i>modelIndex){
					builder.addItemForm[currentSelection[i]] = null;
					currentSelection.pop();
					currentSelectionObjects.pop();
					i--;
					}
				}
				if(i == modelIndex){ //Story 27936 code Changes starts - when changing same level dropdown. replace old val with new value. 
					currentSelectionObjects.pop();
					currentSelectionObjects.push(newObject)
				}
				//Story 27936 code Changes ends
			}

			//Story 27936 Changes starts
			//builder.itemMaximumPrice = 3000; 
			builder.itemMinimumPrice = $scope.minItemAmount;
			builder.isMaxPriceSet = false;
			if((modelName == "selectedItemItem" || modelName == "selectedCategoryItem" || modelName == "selectedDepartmentItem") && builder.addItemForm && addItemForm.itemPrice && addItemForm.itemPrice.value){
				builder.addItemForm.itemPrice = '';
				builder.invalidItemPrice=false;// Story 28508 Changes
			}
			//Story 27936 Changes ends
			_.forEachRight(currentSelectionObjects, function(obj) {
				/*//Story 28573 code changes starts
				if(obj && obj.iconURL == "MattressBoxSpring"){
					builder.itemMaximumPrice = builder.mattressMaxPrice;
					return;
				}//Story 28573 code changes ends
				if (_(obj).has('maximumPrice')) {
				builder.itemMaximumPrice = obj.maximumPrice;
				builder.isMaxPriceSet = true;//Story 27936 code changes
				return;}*/
				
				/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - starts*/
					if(itemPrice.length!=0 && itemPrice.length!=undefined){
						for(var k=0;k<itemPrice.length;k++){
							if(obj.labelEn == itemPrice[k].item){
								builder.itemMaximumPrice =  itemPrice[k].maxPrice;
								builder.isMaxPriceSet = true;
							}
						}
					}
				/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Ends*/
					
				//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Starts
				/*if(_(obj).has('maximumPriceVendorSet')){
					_.forEachRight(obj.maximumPriceVendorSet, function(vendorLst){
						if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
							builder.itemMaximumPrice = vendorLst.maximumPrice;
							builder.isMaxPriceSet = true;
						}
					});
				} */
				if(_(obj).has('maximumPrice') && !builder.isMaxPriceSet){
					//Story 32270 code changes ends
					builder.itemMaximumPrice = obj.maximumPrice;
					builder.isMaxPriceSet = true;
						}
				//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Ends
				else{//Story 27936 code changes starts
					if(!builder.isMaxPriceSet && !(_(obj).has('items')))
					{
						builder.itemMaximumPrice = builder.storeMaxPrice;
						return;
					}
				}
				//Story 27936 code changes ends
			});
			_.forEachRight(currentSelectionObjects, function(obj) {
			//Story 32270 code changes starts
				var isMinPriceSet = false;
				if(_(obj).has('minimumPriceVendorSet')){
					_.forEachRight(obj.minimumPriceVendorSet, function(vendorLst){
						if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
							builder.itemMinimumPrice = vendorLst.minimumPrice;
							isMinPriceSet = true;
						}
					});
				} 
				if(_(obj).has('minimumPrice') && !isMinPriceSet){
			//Story 32270 code changes ends
					builder.itemMinimumPrice = obj.minimumPrice;
					return;
				}
			});
		}

		function showField(field) {
			builder[field] = true;
			if (visibleFields.indexOf(field) < 0) {
				visibleFields.push(field);
			}
		}

		function hideField(fields) {
			if (_.isArray(fields)) {
				_.forEach(fields, function(field) {
					builder[field] = false;
				});
			} else {
				builder[fields] = false;
			}
		}

		function flattenItems(itemArray) {
			var items = [];
			_(itemArray).forEach(function(obj) {
				_(obj.items).forEach(function(item) {
					items.push(item)
				});
			});
			return items;
		}

		function resetFields(selectionLevel) {
			var resetFields = [
				builder.showCategory,
				builder.showBrand,
				builder.showAccessories
			];
			switch (selectionLevel) {
				case 'category':
					resetFields = _.without(resetFields, 'showCategory');
					break;
				case 'items':
					resetFields = _.without(resetFields, 'showCategory', 'showItems');
			}
			for (var i = 0; i < resetFields.length; i++) {
				resetFields[i] = false;
			}
		}

		function addQuantityValue(increaseValue){
			if(builder.addItemForm.quantity == undefined || builder.addItemForm.quantity == null){
				builder.addItemForm.quantity = builder.addItemForm.selectedItemItem.qtyFieldDefault;
				return;
			}
			if(increaseValue){
				builder.addItemForm.quantity += builder.addItemForm.selectedItemItem.qtyFieldIncrement;
			}
			else{
				builder.addItemForm.quantity -= builder.addItemForm.selectedItemItem.qtyFieldIncrement;
			}
		}
		//Story 28508 Changes Start
		function resetFlag(){
			builder.invalidItemPrice=false;
		}
		
		//Defect 25256 Code changes start
		function itemPriceCheck(itemPrice) {
			if (itemPrice && String(itemPrice).indexOf('.') > -1) {
				var itemPrices = String(itemPrice).split('.');
				if (itemPrices[1].length > 2) { 
					if(builder.editItemForm.itemPrice) {
						builder.editItemForm.itemPrice = Number(itemPrice.toString().match(/^\d+(?:\.\d{0,2})?/));
					} else {
						builder.addItemForm.itemPrice = Number(itemPrice.toString().match(/^\d+(?:\.\d{0,2})?/));
					}		
			
				}
			}
		}
		//Defect 25256 Code changes end
		
		function validateTotals(isSetMode,formName,isEditMode,index) {	
			savedEditedItemprice = 0.00;
			builder.orderSubtotal = 0.00;
			if(SharedDataSvc.globalVars.order.currentOrderSubTotal){
				builder.orderSubtotal = SharedDataSvc.globalVars.order.currentOrderSubTotal;
			}
			if(SharedDataSvc.globalVars.order && SharedDataSvc.globalVars.order.approvalAmount){
				builder.approvalamount=SharedDataSvc.globalVars.order.approvalAmount;
			}
			if(builder.approvalamount < 0){
				builder.remainingApprovalAmount=0;
				builder.invalidItemPrice=true;
				builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_validateTotalError : en.orderBuilder_label_validateTotalError;
				return; // Defect 23235 changes
			}
			var savedItemPrice, savedEditedItemprice =0;
			if(isEditMode){
				savedItemPrice = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index].itemPrice;
				savedEditedItemprice = savedItemPrice;
				builder.remainingApprovalAmount = ((Math.round((builder.approvalamount)*100)/100)-((Math.round((builder.orderSubtotal)* 100 ) / 100)-(Math.round((savedItemPrice)* 100 ) / 100)));
				if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length>1)
					{
						builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_RAAmtError : en.orderBuilder_label_RAAmtError;
					}
				else
					{
						builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_AAmtError : en.orderBuilder_label_AAmtError;
					}
			}else{
				builder.remainingApprovalAmount = (Math.round((builder.approvalamount)*100)/100)-(Math.round((builder.orderSubtotal)* 100 ) / 100);
				if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length>0){
					builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_RAAmtError : en.orderBuilder_label_RAAmtError;
				}
				else{
					builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_AAmtError : en.orderBuilder_label_AAmtError;
				}
			}
			builder.remainingApprovalAmount = builder.remainingApprovalAmount.toFixed(2);
			
			if(builder.remainingApprovalAmount<0 || builder.remainingApprovalAmount < builder[formName].itemPrice){
				builder.remainingApprovalAmount=builder.remainingApprovalAmount<0?0:builder.remainingApprovalAmount;
				builder.errormessage += builder.remainingApprovalAmount;				
				builder.invalidItemPrice=true;
				if(builder.lastItemPrice == builder[formName].itemPrice && builder.validSetItem) {
					if(isSetMode.isSetMode === false){
						$scope.$close({item: builder[formName]});
					}
					else{
						$scope.$close({item: builder[formName], isSetMode: true});
					}
					builder.invalidItemPrice=false;
					return;
				}
				/*VAN-4036 -AWP - order screen invoice reduction payment changes 
				add the text in case of order amount is greater than approval amount*/				
				if(SharedDataSvc.invoiceReductionPayment) {
					if(( Number(builder.orderSubtotal) - Number(savedEditedItemprice)) + Number(builder[formName].itemPrice) > 1.4*(Number(builder.approvalamount))){
						builder.maxLimit = true;
						builder.errormessage = "Your order amount exceeds the IRP amount.";
						builder.validSetItem = true;
					}
					else {
						builder.errormessage = "Price exceeds the approval amount. Adjust the price or the customer will need to make a larger initial payment.";
						builder.lastItemPrice = builder[formName].itemPrice;
						builder.validSetItem = true;
					}
				}
				return;
			}else{
				if(isSetMode.isSetMode === false){
					$scope.$close({item: builder[formName]});
				}
				else{
					$scope.$close({item: builder[formName], isSetMode: true});
				}
				builder.invalidItemPrice=false;
				return;
			}
		};
		//Story 28508 Changes End
		function editQuantityValue(increaseValue){
			if(builder.editItemForm.quantity == undefined || builder.editItemForm.quantity == null){
				builder.editItemForm.quantity = builder.editItemForm.selectedCategoryItem.qtyFieldDefault;
				return;
			}
			if(increaseValue){
				builder.editItemForm.quantity += builder.editItemForm.selectedCategoryItem.qtyFieldIncrement;
			}
			else{
				builder.editItemForm.quantity -= builder.editItemForm.selectedCategoryItem.qtyFieldIncrement;
			}
		}

		function checkColorSet(selection) {
			if (selection && selection.colorSet) { //AC 13870 code changes
				builder.itemColors = _.find(builder.colorSets, {
					name: selection.colorSet
				}).colors;
				showField('showColors');
				return;
			}
			
			// no color set, in edit mode
			if (builder.isEditMode) {
				builder.editItem.color = selection.colorSet; //AC 13870 code changes
				builder.editItemForm.selectedColorItem = { labelEn: ''};
			}
		}

		// function
		function enterSetMode(setIndex) {
			void 0;
			builder.addItemForm.selectedCategoryItem = {};
			builder.isSetItem = true;
			var set = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[setIndex];
			if (!setIndex) {
				// return;
			}
			// get category name string
			
			/*VAN 488 - AWP- In Spanish Flow for PR stores, Not able to add set items - Starts*/
			var setCategory;
			if($rootScope.selectedLanguage == 'en'){
				setCategory = _.find(builder.inventoryInfo.productCategories, { labelEn: set.category }).parent;
			}if($rootScope.selectedLanguage == 'es'){
				setCategory = _.find(builder.inventoryInfo.productCategories, { labelEs: set.category }).parent;
			}
			/*VAN 488 - AWP- In Spanish Flow for PR stores, Not able to add set items - Ends*/
			
			var selectedCategoryItem = _.find(builder.inventoryInfo.productCategories, {labelEn: setCategory});
			//Story 32271 code chnages starts
			if( _.isArray(selectedCategoryItem.items) ){
				var filteredSetItem = [];
				for(var x=0; x<selectedCategoryItem.items.length; x++){
					if( excludedSetItems.indexOf(selectedCategoryItem.items[x].labelEn) == -1 ){
						filteredSetItem.push(selectedCategoryItem.items[x]);
					}	
				}
				selectedCategoryItem.items = filteredSetItem;	
			}
			//Story 32271 code chnages ends
			builder.addItemForm.selectedCategoryItem = selectedCategoryItem;
		}

		function enterEditMode(itemIndex) {
			void 0;
			var orderItem;
			var products = builder.inventoryInfo.productCategories;

			if (itemIndex.length > 1) {
				orderItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex[0]].setItems[itemIndex[1]];
				builder.isSetMode = true;//AC Defect 26760 - VAN-Web-Unable to edit set item
				builder.isSetItem = true;
			} else {
				orderItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex];
			}
			void 0;
			/*
			 Start with the base product tier of the item, can be retrieved from the iconURL
			 A few items have the same iconURL, so get the last one found as it's deepest level
			 that needs to be found
			 */
			/* VAN - 2552 patch fix for bed category*/
			var itemBaseTier;
			if(orderItem.iconURL == 'Bed'){
				itemBaseTier = _(products).where({iconURL: orderItem.iconURL}).first(); // AC-13373 Changes
			}else{
				itemBaseTier = _(products).where({iconURL: orderItem.iconURL}).last();
			}
			builder.editItem = orderItem;


			if (orderItem.brand) {
				// Set the brand set from base
				var itemBrands = _.find(builder.brandSets, {
					name: itemBaseTier.brandSet
				}).brands;
				var selectedBrand = _.find(itemBrands, {
					label: orderItem.brand
				});

				builder.itemBrands = itemBrands;
				builder.editItemForm.selectedBrandItem = selectedBrand;
			}

			if (orderItem.material) {
				// Set editable materials using base item set
				var itemMaterials = _.find(builder.materialSets, {
					name: itemBaseTier.materialSet
				}).materials;
				var selectedMaterial = _.find(itemMaterials, {
					labelEn: orderItem.material
				});

				builder.itemMaterials = itemMaterials;
				builder.editItemForm.selectedMaterialsItem = selectedMaterial;
			}

			if (orderItem.color) {
				// determine where the color set is, either main item or materials
				var colorSetName = _(itemBaseTier).has('colorSet') ? itemBaseTier.colorSet : builder.editItemForm.selectedMaterialsItem.colorSet;
				if((colorSetName != null) && (colorSetName != undefined)){//AC 13870 code changes
					var itemColors = _.find(builder.colorSets, {
						name: colorSetName
					}).colors;
					// console.log(builder.itemColors);
					var selectedColor = _.find(itemColors, {
						labelEn: orderItem.color
					});
					builder.itemColors = itemColors;
					builder.editItemForm.selectedColorItem = selectedColor;
				}//AC 13870 code changes
			}
			//AC-13373 Changes Start
			if (orderItem.size) {
				if((itemBaseTier.items) && (itemBaseTier.items.length>0)){
					var itemSize = [];
					for(var i =0; i < itemBaseTier.items.length; i++){
						itemSize.push(itemBaseTier.items[i]);
					}
					builder.itemSize = itemSize;
					for(var i=0;i<builder.itemSize.length;i++){
						if(builder.itemSize[i].labelEn == orderItem.size){	
							builder.editItemForm.selectedBedSizeItem = builder.itemSize[i];
							break;
						}
					}
				}
			}			
			//AC-13373 Changes End
			//Story 32270 code changes starts
			var isMinPriceSetForEdit = false;
			if(itemBaseTier.minimumPriceVendorSet){
				_.forEachRight(itemBaseTier.minimumPriceVendorSet, function(vendorLst){
					if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
						builder.itemMinimumPrice = vendorLst.minimumPrice;
						isMinPriceSetForEdit = true;
					}
				});
			}
			if(itemBaseTier.minimumPrice && !isMinPriceSetForEdit){
			//Story 32270 code changes ends
				builder.itemMinimumPrice = itemBaseTier.minimumPrice;
			}else if(!isMinPriceSetForEdit){//AC26729 code changes
				/* Added this else condition to fix 25443 uat defect */
				builder.itemMinimumPrice = $scope.minItemAmount;
			}
			
			/*//AC 23123 code changes starts
			if(itemBaseTier && itemBaseTier.iconURL == "MattressBoxSpring"){
				builder.itemMaximumPrice = builder.mattressMaxPrice;
				//AC 23123 code changes ends
			} else if(itemBaseTier.maximumPrice){
				builder.itemMaximumPrice = itemBaseTier.maximumPrice;
			}*/
			
			/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - starts*/
			var isMaxPriceSetAtVendor = false;
			if(itemPrice.length!=0 && itemPrice.length!=undefined){
				for(var k=0;k<itemPrice.length;k++){
					if(itemBaseTier.labelEn == itemPrice[k].item){
						builder.itemMaximumPrice = itemPrice[k].maxPrice;
						isMaxPriceSetAtVendor = true;
					}
				}
			}
			/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Ends*/
			
			//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Starts
			/*if(itemBaseTier.maximumPriceVendorSet){
				_.forEachRight(itemBaseTier.maximumPriceVendorSet, function(vendorLst){
					if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
						builder.itemMaximumPrice = vendorLst.maximumPrice;
						isMaxPriceSetAtVendor = true;
					}
				});
			}*/
			if(itemBaseTier.maximumPrice && !isMaxPriceSetAtVendor){
			builder.itemMaximumPrice = itemBaseTier.maximumPrice;
			isMaxPriceSetAtVendor = true;
			}
			else if(!isMaxPriceSetAtVendor){
				builder.itemMaximumPrice = builder.storeMaxPrice;
			}
			//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Ends
			builder.editItemForm.selectedCategoryItem = itemBaseTier;
			
			//AC-13373 Changes Start
			if (builder.editItemForm.selectedCategoryItem.customAttributeGroups.length > 0) {
				builder.editItemForm.customAttributes = builder.editItemForm.selectedCategoryItem.customAttributeGroups;
				for(var i=0, k = 0;i<builder.editItemForm.customAttributes.length;i++){
					for(var j = 0; j < builder.editItemForm.customAttributes[i].customAttributes.length; j++, k++){
						if(builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "radio"){
							for(var u=0; u<builder.editItemForm.customAttributes[i].customAttributes[j].customAttributeOptions.length; u++){
								builder.editItemForm.customAttributes[i].customAttributes[j].customAttributeOptions[u].selectedAttribute = orderItem.attributes[i].value;
							}
						} else if(builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "integer" || builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "quantity"){
							builder.editItemForm.customAttributes[i].customAttributes[j].selectedTextAttribute = parseInt(orderItem.attributes[k].value);
						}else if(builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "text"){
							builder.editItemForm.customAttributes[i].customAttributes[j].selectedTextAttribute = orderItem.attributes[k].value;
						}
						else{
							builder.editItemForm.customAttributes[i].customAttributes[j].selectedAttribute = orderItem.attributes[k].value;
						}
						
					}
				}
				showField('showCustomAttributes');
			}//AC-13373 Changes End
			
			builder.editItemForm.itemPrice = orderItem.itemPrice;
			builder.editItemForm.quantity = orderItem.quantity;
			// VAN - 2430 Changes Start
			builder.editItemForm.itemModelNumber=orderItem.model;
			// VAN - 2430 Changes End
			/* VAN - 2552 patch fix bed description got changed so we are setting it again.*/
			if(orderItem.iconURL == 'Bed') {
				builder.editItemForm.selectedCategoryItem.labelEn = orderItem.itemDescription;
			}
		}

		function findColorSet(){}
	}
	OrderBuilder.$inject = ["$scope", "$rootScope", "$http", "$filter", "$timeout", "filterFilter", "SharedDataSvc", "authentication", "productCategoriesFactory", "getStoreInfoFactory", "modalData", "orderService"]; // end .controller defs

})();
// This controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('manualOverride', ManualOverride);

  ManualOverride.$inject = ["$scope", "$log", "$routeParams", "$q", "$modal", "$route", "$location", "$cookies", "SharedDataSvc", "base64", "manualOverrideFactory", "modalService", "applicationService", "authentication", "updateEngagementItemFactory", "productCategoriesFactory", "$translate", "$rootScope"];

  function ManualOverride($scope,
                     $log,
                     $routeParams,
                     $q,
                     $modal,
                     $route,
                     $location,
                     $cookies,
                     SharedDataSvc,
                     base64,
                     manualOverrideFactory,
                     modalService,
                     applicationService,
                     authentication,
                     updateEngagementItemFactory,
                     productCategoriesFactory,
                     $translate,
                     $rootScope
                    ) {
    /*jshint validthis: true */
    var vm = this;

    // set scope variable for processing modal
    $scope.title = 'Processing';

    // Variables
    vm.errors = manualOverrideFactory.errorModel;
    vm.modal;
    vm.item = SharedDataSvc.manualOverrideData;
    vm.storeInfo = SharedDataSvc.globalVars.storeSetup;
    vm.currentAmount = formatApprovalAmount(SharedDataSvc.manualOverrideData.approvalAmount);
    vm.productCat;
    vm.overrideOptions;
    vm.engagement;
    vm.review = manualOverrideFactory.associateReview;
    vm.overrideState = SharedDataSvc.manualOverrideData.state;
    vm.engagementId = SharedDataSvc.manualOverrideData.engagementId;
    vm.approval = manualOverrideFactory.approvalModel;
    vm.message = SharedDataSvc.manualOverrideMessage;
    vm.customer;
    vm.overrideSpec = {
      customerId: null, // <Mandatory Field>
      customMessage: "",
      overrideCode : 1, // <Mandatory Field>
      overrideAmount : vm.approval.requestedAmount, // [Amount that approval was increased]
      overrideStatus : "APPROVE", // [New Approval Status]
      employeeId : "Test"
    };
    vm.noEmpPass = false;
    vm.passCheck = false;

    // Controller Functions
    vm.navigateTo = navigateTo;
    vm.showModal = showModal;
    vm.calculateApprovalAmount = calculateApprovalAmount;
    vm.submitApprovalOverride = submitApprovalOverride;
    vm.validateApprovalAmount = validateApprovalAmount;
    vm.approvalPreSubmit = approvalPreSubmit;
    vm.instantApproval = instantApproval;
    //vm.submitApprovalOverride = submitApprovalOverride;
    vm.validateWorksheets = validateWorksheets;
    vm.declineApproval = declineApproval;
    vm.declineApprovalVerify = declineApprovalVerify;
    vm.submitVerifyAddress = submitVerifyAddress;
    vm.validateAddressVerify = validateAddressVerify;
    vm.validateIncome = validateIncome;
    //AC23434 code changes start
    vm.validateOutstandingBalance = validateOutstandingBalance;
    vm.showAccountResolved = showAccountResolved;
    vm.outstandingReview = {};
    vm.isOutstandingVerified = isOutstandingVerified;
    //AC23434 code changes end
    vm.validateSkipStolen = validateSkipStolen;
    vm.confirmVerfication = confirmVerfication;
    vm.backToVerification = backToVerification;
    vm.validateConditionalApproval = validateConditionalApproval;
    vm.isSkipStolenOverrideVerified = isSkipStolenOverrideVerified;
    /*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
    vm.isSkipStolenOverrideVerifiedDecReason = isSkipStolenOverrideVerifiedDecReason;
    vm.isSkipStolenOverrideVerifiedDecReasonList = isSkipStolenOverrideVerifiedDecReasonList;
    vm.validateConditionalApprovalAssociateReview = validateConditionalApprovalAssociateReview;
    /*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Starts*/
    vm.validateAssocReviewDecline = validateAssocReviewDecline;
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Ends*/
    vm.validateEmployeePass = validateEmployeePass;
    vm.setOverrideAmount = setOverrideAmount;
    vm.init = init;
    $scope.submitEmployeePassReview = verifyAssociatePassReview;
    $scope.submitEmployeePass = verifyAssociatePassOverride;
    $scope.verifyAssociatePassCheck = verifyAssociatePassCheck;
    //start: Feature 8367 code changes
    vm.validateDecline = validateDecline; 
    vm.overrideCheck = false;
    vm.declineCheck = false;
    //end: Feature 8367 code changes

    vm.associatePassFail = false;
    
    vm.error = {
      lease: false,
      noEmpName: false
    };

    // Initialize Controller
    init();

    function init() {
      var findData = { 'firstName': null, 'lastName': null, 'phone': null };
      void 0;
      void 0;
      void 0;
      void 0;
      void 0;

      // RESET ERRORS
      vm.errors.error = false;
      vm.errors.alertClass = '';
      vm.errors.message = '';
      //Feature 8367 code changes
      vm.errors.declineMessage = '';
      vm.error.isDeclineOtherMessage = false;
      vm.approval.declineReason = null;
      vm.approval.otherReason = null;
      var status = SharedDataSvc.manualOverrideData.applicationStatus;

      if(status === 'AssocRvw') {
        $scope.submitEmployeePassReview = verifyAssociatePassReview;
        $scope.submitEmployeePass = verifyAssociatePassReview;
      } else {
        $scope.submitEmployeePassReview = verifyAssociatePassOverride;
        $scope.submitEmployeePass = verifyAssociatePassOverride;
      }
      
      $scope.isSpanishStore = $cookies.getObject('isSpanishStore');

      void 0;

      updateEngagementItemFactory.getData(SharedDataSvc.manualOverrideData.engagementId)
        .then(function (response) {
          $log.log('[manualOverride.init] response', response);
          vm.engagement = response;
          $log.log('[manualOverride.init] engagement', SharedDataSvc.manualOverrideData);
        });
    }

    function navigateTo(target) {
      modalService.dismiss();
      if(target === 'dashboardReload') {
        $route.reload();
        return;
      }
      $location.path(target);
    };

    function showModal(name, windowClass) {
      $log.log('MODAL - show modal', name);
      windowClass = windowClass || '';
      vm.modal = modalService.open({
        templateUrl: '/partials/modal_' + name + '.html',
        backdrop: 'static',
        windowClass: windowClass,
        controller: 'manualOverride',
        controllerAs: 'override',
        // scope: $scope,
        show: true
      });
    }

    function setOverrideAmount() {
      vm.overrideSpec.overrideAmount = vm.approval.requestedAmount;
    }

    function formatApprovalAmount(currentAmount) {
      if(currentAmount) {
        currentAmount = currentAmount.typeof !== 'string' ? String(currentAmount) : currentAmount;
        var amount = currentAmount.indexOf('.') > -1 ? currentAmount.split('.') : currentAmount;
        void 0;
        if(_.isArray(amount)) {
          amount = amount[0] + '.00';
          return amount;
        } else {
          return currentAmount + '.00';
        }
      }
    }

    function calculateApprovalAmount() {
      var requestedAmount = vm.approval.requestedAmount,
          requestedAmount = typeof requestedAmount !== 'number' ? Number(requestedAmount) : requestedAmount,
          currentAmount = typeof vm.currentAmount !== 'number' ? Number(vm.currentAmount) : vm.currentAmount;

      vm.approval.difference = (requestedAmount - currentAmount);

      return vm.approval.difference;
    }

    function validateApprovalAmount() {
    	$log.log('validate amount');
    
      productCategoriesFactory.getData()
        .then(function(response) {
        	
          vm.productCat = response;
          $log.log('CTRL products', vm.productCat);
          
          //var tierObj = getOverrideTier(),
         var max = Number(vm.storeInfo.maximumOverideAmount);

	      //vm.overrideOptions = tierObj.tier;
	      vm.approval.tierIndex = 1;
	
	      // Reset errors
	      vm.errors.error = true;
	      vm.errors.alertClass = '';
	      vm.errors.message = undefined;
	      // Start VAN-2951 -->
	      var pattern = /^\${0,1}[1-9][0-9]{0,7}$/;
	      if(!(pattern.test(vm.approval.requestedAmount))) {
          vm.errors.message = $translate.instant("modal_verifyInitialAmt_error3");
	        //vm.errors.message = 'Please enter a valid Approval Amount';
	      }
	      // End VAN-2951 -->
	      var maxapprovalamount = Number(vm.storeInfo.maximumApprovalAmount);//VAN-2391-Increase in approval amount from DE to $4,000
	      if(Number(vm.approval.requestedAmount) > maxapprovalamount) {
          vm.errors.message = $translate.instant("modal_verifyInitialAmt_error4");
	        //vm.errors.message = 'Request amount exceeds maximum increase limit';
	      } else {
	        //max = Number(vm.storeInfo.maximumOverideAmount);
	
	        calculateApprovalAmount();
	        /*VAN-498 - PR store not allowing to perform multiple overrides- Starts*/ 
	        var currentAmount = typeof vm.currentAmount !== 'number' ? Number(vm.currentAmount) : vm.currentAmount;
	
	        if(currentAmount===0 ||(vm.approval.difference > 0 && vm.approval.difference <= max)) {
	        /*VAN-498 - PR store not allowing to perform multiple overrides- Ends*/ 
	          instantApproval();
            //approvalPreSubmit();
	        } else {
	          approvalOutOfRange(vm.approval.difference, max);
	        }
	      }
          
          
      });
      
    }

    function approvalOutOfRange(difference, max) {
      //var max3 = Number(vm.overrideOptions.increaseMaximum_3),
        var maxTotal = Number(vm.currentAmount) + Number(max);

          void 0;
          void 0;

      if(vm.approval.requestedAmount <= Number(vm.currentAmount)) {
        vm.errors.error = true;
        vm.errors.message = $translate.instant("modal_verifyInitialAmt_error1");
        void 0;
      } 
      if(difference > max) {
        vm.errors.error = true;
        vm.errors.message = $translate.instant("modal_verifyInitialAmt_error2") + maxTotal;
        //vm.errors.message = 'Increase amount cannot be more than $' + maxTotal;
        void 0;
      }
      // if(vm.approval.requestedAmount > maxTotal) {
      //   vm.errors.error = true;
      //   vm.errors.alertClass = 'alert-danger'; // alert-success
      //   vm.errors.message = 'Increase amount is too high';
      //   console.log('Amount requested is too high');
      // }
    }

    function approvalPreSubmit() {
      var max1 = Number(vm.overrideOptions.increaseMaximum_1);
      void 0;
      if(vm.approval.difference <= max1) {
        void 0;
        instantApproval();
      } else {
        void 0;
        handleHigherApproval();
      }
    }

    function handleHigherApproval() {
      $log.log('high amount');
      var max2 = Number(vm.overrideOptions.increaseMaximum_2);

      vm.approval.needsRightSelling = true;

      if(vm.approval.difference > max2) {
        vm.approval.needsLeaseOrder = true;
      } 
      if(manualOverrideFactory.associateReview && manualOverrideFactory.associateReview.customMessage &&
    		  manualOverrideFactory.associateReview.customMessage.indexOf('Verified AccountStatus') > -1){
    	  showModal('employeePass', 'employee-password-modal in');
      }else{
    	  showVerifyModal();
      }
    }

    function setApprovalData() {
      var newAmount = typeof vm.approval.requestedAmount === 'number' ? vm.approval.requestedAmount : Number(vm.approval.requestedAmount);
      void 0;
      vm.overrideSpec.customerId = SharedDataSvc.manualOverrideData.customerId;
      void 0;
      if(vm.overrideSpec.employeeId) {
        delete vm.overrideSpec.employeeId;
      }
      if(vm.overrideSpec.overrideStatus === 'APPROVE') {
        vm.overrideSpec.customMessage = 'Approved by - ' + manualOverrideFactory.approvalModel.employeeName;
      }
       if(vm.overrideSpec.overrideStatus === 'DECLINE') {
        //vm.overrideSpec.customMessage = 'Declined';
//    	   Feature 8367 code changes
    	   if(vm.overrideSpec.overrideAmount){
    		   vm.overrideSpec.overrideAmount=null;
    	   }
    	   vm.overrideSpec.customMessage = vm.approval.declineReason;
    	   if(vm.approval.otherReason){
    		   vm.overrideSpec.customMessage =  vm.overrideSpec.customMessage + ' ' + vm.approval.otherReason;
    	   }
    	   
    	   /*Defect - VAN 326 - Decline reason is not getting updated - Starts*/
    	   if(!vm.overrideSpec.customMessage){
    		   /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Starts*/
    		   vm.overrideSpec.customMessage = 'Declined by - ' + vm.review.employeeName;
    		   /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Ends*/
    	   }
    	   /*Defect - VAN 326 - Decline reason is not getting updated - Ends*/
      }
      void 0;
    }

    function instantApproval() {
      $rootScope.isSubmittedForOverride = true;
      showModal('employeePass');
      void 0;
      // submitApprovalOverride();
    }

    function getOverrideTier() {
      void 0;
      var tier = manualOverrideFactory.getOverrideTier(vm.productCat.overrideOptions, vm.approval.requestedAmount, vm.currentAmount);
      return tier;
    }

    function validateAddressVerify(status) {
      void 0;
      vm.skipCheck = true;
      if(vm.review.confirmDl || vm.review.calledLandlord  || vm.review.other) {   // Have removed  "|| vm.review.confirmUtility" from here as per new changes.
         vm.review.address.noReason = false;
         vm.review.address.nothingSelected = false;

        if(vm.review.other && !vm.review.verifyExplain) {
          vm.review.address.noReason = true;
          vm.errors.error = true;
          vm.errors.alertClass = 'alert-danger';
        } else {
          submitVerifyAddress(status);
        }
      } else {
        vm.review.address.noReason = false;
        vm.review.address.nothingSelected = true;
        vm.errors.error = true;
        vm.errors.alertClass = 'alert-danger';
      }
    }

    function validateIncome(status) {
      vm.skipCheck = true;
      if(vm.review.verifyIncome) {
        void 0;
        submitVerifyIncome(status);
      } else {
        vm.review.income.notCompleted = true;
        vm.errors.error = true;
        vm.errors.alertClass = 'alert-danger';
        void 0;
      }
    }
    
    //AC23434 code changes start
    function validateOutstandingBalance(status) {
        vm.skipCheck = true;
        
        if(!vm.outstandingReview.verifySkipStolen || !vm.outstandingReview.verifyAccountStatus || !vm.outstandingReview.verifyAccountResolved || !vm.outstandingReview.verifyRightSelling || !vm.outstandingReview.verifyException) {
            vm.outstandingReview.notVerified = true;
          }
        
        if(vm.outstandingReview.employeeName && vm.outstandingReview.verifySkipStolen && vm.outstandingReview.verifyAccountStatus && vm.outstandingReview.verifyAccountResolved && vm.outstandingReview.verifyRightSelling && vm.outstandingReview.verifyException) {
          void 0;
          var verified = [
                          [vm.outstandingReview.verifySkipStolen, 'Verified SkipStolen.'],
                          [vm.outstandingReview.verifyAccountStatus, 'Verified AccountStatus.'],
                          [vm.outstandingReview.verifyAccountResolved, 'Verified AccountResolved.'],
                          [vm.outstandingReview.verifyRightSelling, 'Verified RightSelling.'],
                          [vm.outstandingReview.verifyException, 'Verified Exception Lease Order.'],                          
                          [vm.outstandingReview.employeeName, vm.outstandingReview.employeeName]
                        ];
          manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);
          submitVerifyOutstandingBalance(status);
        } 
      }
    
    function submitVerifyOutstandingBalance(status) {
        vm.overrideSpec.overrideStatus = status;
        showModal('employeePassReview', 'employee-password-modal in');
      }
    
    function showAccountResolved() {
    	vm.accountResolvedModal = modalService.openNew({
			templateUrl : 'partials/modal_accountResolved.html',
			backdrop : 'static',
			scope : $scope
		});
    }
    function isOutstandingVerified() {
    	 vm.outstandingReview.notVerified = true;
        if(vm.outstandingReview.verifySkipStolen && vm.outstandingReview.verifyAccountStatus && vm.outstandingReview.verifyAccountResolved && vm.outstandingReview.verifyRightSelling && vm.outstandingReview.verifyException) {
          vm.outstandingReview.notVerified = false;
          return true;
        } 
      }
    //AC23434 code changes end

    function validateEmployeePass() {
      void 0;
      vm.passCheck = true;
      if(!vm.approval.employeePass || !vm.review.employeePass) {
        vm.noEmpPass = true;
        void 0;
      }
    }

    function showVerifyModal() {
      $log.log('verify index for modal: ', vm.approval.tierIndex);
      if(vm.approval.tierIndex === 2 || vm.approval.tierIndex === 1) {
        $log.log('show right selling');
        // SharedDataSvc.manualOverrideData.state = 'right';
        showModal('overrideRightSelling', 'override-verify');
      }

      if(vm.approval.tierIndex === 3) {
        $log.log('show lease order');
        // SharedDataSvc.manualOverrideData.state = 'lease';
        showModal('overrideLeaseOrder', 'override-verify');
      }
    }
//    Feature 8367 code changes starts
    function validateDecline(type){
    	if(vm.approval.declineReason){
    		var declinereason = vm.approval.declineReason;
    		if(declinereason == 'Other'){
    			if(vm.approval.otherReason){
    				validateWorksheets(type);
    			} else {
    				vm.errors.isDeclineOtherMessage = true;
            vm.errors.declineMessage = $translate.instant("modal_decline_error1");
    				//vm.errors.declineMessage = 'Please enter a valid decline reason';
    			}
    		} else {
    			validateWorksheets(type);
    		}
    	} else {
    		vm.errors.declineMessage = $translate.instant("modal_decline_error1");
        //vm.errors.declineMessage = 'Please enter a valid decline reason';
    	}
    }
//    Feature 8367 code changes ends

    function validateWorksheets(type) {
      $log.log('type', type);
      $log.log('tier index', vm.approval.tierIndex);
      $log.log('right selling', vm.approval.rightSelling);
      $log.log('lease order', vm.approval.leaseOrder);
      $log.log('emp name', vm.approval.employeeName);


      if(vm.approval.tierIndex === 3 && type === 'lease') {
        if(vm.approval.rightSelling && vm.approval.employeeName && vm.approval.verifyLease) {
          $log.log('passes lease order validation');
          showModal('employeePass', 'employee-password-modal in');
        } else {
          showWorksheetErrors('lease');
        }
      } else if (vm.approval.tierIndex === 2 && type === 'right' || vm.approval.tierIndex === 1 && type === 'right') {
        if(vm.approval.rightSelling && vm.approval.employeeName) {
          $log.log('passes right selling validation');
          showModal('employeePass', 'employee-password-modal in');
        } else {
          showWorksheetErrors('right');
        }
      } else {
        void 0;
      }

      if(type === 'decline') {
        void 0;
        vm.overrideSpec.overrideStatus = 'DECLINE';
        showModal('overrideDecline');
      }
    }

    function showWorksheetErrors(type) {

      if( $rootScope.selectedLanguage == 'es' ) {
        var messageErrors = ['Se requiere el nombre y la verificación que el formulario esté completo', 
                           'Se requiere el nombre',
                           'Se requiere la verificación que el formulario esté completo'];
      } else {
        var messageErrors = ['Name and verification of form completion is required', 
                           'Name is required',
                           'Verification of form completion is required'];
      }
      

      // vm.errors.error = false;
      // vm.errors.alertClass = ''; 
      // vm.errors.message = undefined;
      void 0;

      if(type === 'right') {
        if(!vm.approval.employeeName && !vm.approval.rightSelling) {
          $log.log('right selling name and form error');
          // vm.error = true;
          // vm.alertClass = 'alert-danger';
          // vm.message = messageErrors[0];

          vm.errors.error = true;
          vm.errors.alertClass = 'alert-danger'; // alert-success
          vm.errors.message = messageErrors[0];
          void 0;
          void 0;
        } else if(!vm.approval.employeeName && vm.approval.rightSelling) {
          $log.log('right selling name error');
          vm.errors.message = messageErrors[1];
          vm.errors.error = true;
          vm.alertClass = 'alert-danger';
        } else {
          $log.log('right selling form error');
          vm.errors.error = true;
          vm.alertClass = 'alert-danger';
          vm.errors.message = messageErrors[2];
        }
      } else {
        void 0;
        vm.error.lease = false;

        if(!vm.approval.employeeName || !vm.approval.rightSelling || !vm.approval.verifyLease) {
          $log.log('name error');
          vm.error.lease = true;
          vm.errors.error = true;
        } 
      }
    }

    function verifyCompletedWorksheets() {
      if(vm.approval.tierIndex === 2) {
        void 0;
        void 0;
        showVerifyModal();
        // submitApprovalOverride();
      } else if (vm.approval.tierIndex === 1) {
        void 0;
        void 0;
        showVerifyModal();
        // submitApprovalOverride();
      } else {
        void 0;
      }
      // submitApprovalOverride();
    }

    function validateSkipStolen() {
      var valid = true;
      vm.skipCheck = true;
      if(!vm.review.employeeName) {
        valid = false;
      }

      if(!vm.review.verifyExplain) {
        valid = false;
      }

      if(!vm.review.verifyEmployment || !vm.review.verifyReference || !vm.review.verifyAddress) {
        valid = false;
        vm.review.notVerified = true;
      }

      if(valid) {
        var verified = [
              [vm.review.verifyEmployment, 'Verified Employment.'],
              [vm.review.verifyReference, 'Verified References.'],
              [vm.review.verifyAddress, 'Verified Address.'],
              [vm.review.employeeName, vm.review.employeeName + ' -'],
              [vm.review.verifyExplain, vm.review.verifyExplain]
            ];

        manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);

        $scope.submitEmployeePass = submitAssociatePass;
        showModal('employeePassSkip', 'employee-password-modal');
      }
    }

    function verifyInfoSubmit() {
      vm.verifyData = {};
      vm.verifyData.engagementId = vm.item.engagementId;
      vm.verifyData.verifyInfoAssociateName = vm.review.employeeName;
      void 0;
      updateEngagementItemFactory.putData(vm.verifyData)
        .then(function(response) {
          void 0;
          modalService.dismiss();
          $route.reload();
        }, function(response) {
          void 0;
          modalService.dismiss();
        });
    }

    /*VAN 253 - VAN- AWP Dashboard View Button Code changes*/
    function confirmVerfication(){
        verifyInfoSubmit();
        showModal('Processing', 'processing-modal');
    }

    function backToVerification(){
      showModal('conditionalApproval', 'associate-review-modal');
    }
    
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Starts*/
    function validateAssocReviewDecline(){
    	 var valid = true;
    	 vm.skipCheck = true;
    	 if(!vm.review.employeeName){
    		 valid = false;
    	 }
    	 if(valid){
    		showModal('declineAssociateReview','decline-associate-review');
    	 }
    }
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Ends*/

    /*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
    function validateConditionalApprovalAssociateReview(verifiedChkList){
        var valid = true;
        vm.skipCheck = true;
        if(!vm.review.employeeName) {
          valid = false;
        }

        if($scope.showCustAddress){
        	if(!vm.review.verifyAddress){
        		valid = false;
        		vm.review.notVerified = true;
        	}
        }if($scope.showCustReferences){
        	if(!vm.review.verifyReference){
        		valid = false;
        		vm.review.notVerified = true;
        	}
        }

        if(!verifiedChkList){
        	valid = false;
        	vm.review.notVerified = true;
        }
        if(valid) {
          var verified = [
            [vm.review.employeeName, "Approved by"+" - "+vm.review.employeeName]
          ];
          
          manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);
/*//        Comment - Change PR logic similar to US - Start
          if($scope.isSpanishStore) {
            showModal('confirmVerification', 'smallModals');
          } else {*///        Comment - Change PR logic similar to US - End
            $scope.submitEmployeePass = submitAssociatePass;
            showModal('employeePassConditionalApproval', 'employee-password-modal');
//        }
      }
    }
    /*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
    
    function validateConditionalApproval(){
        var valid = true;
        vm.skipCheck = true;
        if(!vm.review.employeeName) {
          valid = false;
        }

        if(!vm.review.verifyExplain && !$scope.isSpanishStore) {
          valid = false;
        }

        if(!vm.review.verifyEmployment || !vm.review.verifyReference || !vm.review.verifyAddress) {
          valid = false;
          vm.review.notVerified = true;
        }

        if(valid) {
          var verified = [
            [vm.review.verifyEmployment, 'Verified Employment.'],
            [vm.review.verifyReference, 'Verified References.'],
            [vm.review.verifyAddress, 'Verified Address.'],
            [vm.review.employeeName, vm.review.employeeName + ' -'],
            [vm.review.verifyExplain, vm.review.verifyExplain]
          ];

          manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);
//        Comment - Change PR logic similar to US - Start
         /* if($scope.isSpanishStore) {
            //verifyInfoSubmit();
            //showModal('Processing', 'processing-modal');
            showModal('confirmVerification', 'smallModals');
          } else {*/
//        Comment - Change PR logic similar to US - End
            $scope.submitEmployeePass = submitAssociatePass;
            showModal('employeePassConditionalApproval', 'employee-password-modal');
//        }
      }
    }

    /*VAN 253 - VAN- AWP Dashboard View Button End*/
    
    /*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
    function isSkipStolenOverrideVerifiedDecReason() {
      if($scope.showCustAddress){
      	if(vm.review.verifyAddress){
      		vm.review.notVerified = false;
      		if($scope.declineReasonsList.length==0){
      			$scope.verifiedChkList = true;
      		}
            return true;
      	}
      }if($scope.showCustReferences){
        	if(vm.review.verifyReference){
          		vm.review.notVerified = false;
          		if($scope.declineReasonsList.length==0){
          			$scope.verifiedChkList = true;
          		}
                return true;
          	}
          }
    }
    
    var tempList =[];
    function isSkipStolenOverrideVerifiedDecReasonList(verifyReason,declineReasonsList) {
    	$scope.verifiedChkList = false;
    		if(verifyReason.isSelected == true){
    			tempList.push(verifyReason.reason);
    		}if(verifyReason.isSelected == false){
    			tempList.splice(verifyReason.reason,1);
    		}
    		if(declineReasonsList.length == tempList.length){
    			vm.review.notVerified = false;
    			$scope.verifiedChkList = true;
    			return true;
    		}
      }
    /*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
    
    function isSkipStolenOverrideVerified() {
        if(vm.review.verifyEmployment && vm.review.verifyReference && vm.review.verifyAddress) {
          vm.review.notVerified = false;
          return true;
        } 
    }
    
    function declineApproval() {
      $log.log('decline approval');
      var overrideSpec = _.clone(vm.overrideSpec);
      $log.log('override data', overrideSpec);
      // delete overrideSpec.overrideAmount;
      vm.overrideSpec.customerId = vm.item.customerId;
      vm.overrideSpec.overrideCode = 0;
      vm.overrideSpec.overrideStatus = 'DECLINE';
      vm.overrideSpec.overrideStatus = 'DECLINE';
      vm.item.isCoWorkerDeclined = true;
      vm.item.applicationStatus = 'Declined';
      // vm.item.applicationStatus = 'Declined';
      submitApprovalOverride(overrideSpec);
    }

    function declineApprovalVerify(pass) {
      if(pass) {
        $scope.noEmpPass = false;
        $scope.incorrectPass = false;
        var auth = authentication.storePass;

        if(auth === pass) {
          declineApproval();
        }
        else {
          $scope.incorrectPass = true;
          void 0;
        }
      }
      else {
        $scope.noEmpPass = true;
        void 0;
      }
    }

    function setAddressData() {
      var overrideSpec = _.clone(vm.overrideSpec);
      // delete overrideSpec.overrideAmount;
      overrideSpec.overrideCode = 0;
      overrideSpec.customerId = SharedDataSvc.manualOverrideData.customerId;
      if(vm.review.other) {
        overrideSpec.customMessage = vm.review.summary;
      } else {
        overrideSpec.customMessage = null;
      }
      return overrideSpec;
    }

    function setAddressDataReview() {
      var overrideSpec = _.clone(vm.overrideSpec);
      overrideSpec.overrideCode = 0;
      overrideSpec.customerId = SharedDataSvc.manualOverrideData.customerId;
      overrideSpec.customMessage = manualOverrideFactory.associateReview.customMessage;
      return overrideSpec;
    }

    function submitAssociateReview() {
      void 0;
      var overrideData = setAddressDataReview();
      showModal('Processing', 'processing-modal');
      overrideData.customerId = vm.item.customerId;
      delete overrideData.employeeId;
      if(overrideData.overrideCode === 0) {
        delete overrideData.overrideAmount;
      }
      manualOverrideFactory.submitOverride(overrideData)
        .then(function(response) {
          modalService.dismiss();
          void 0;
          if(vm.overrideSpec.overrideStatus === 'APPROVE') {
            SharedDataSvc.manualOverrideData.applicationStatus = 'Accepted';
            //AC23434 code changes start
            if(manualOverrideFactory.associateReview.customMessage.indexOf('Verified AccountStatus') > -1){
            	showModal('overrideInitial');
            } else { 
            	navigateTo('dashboard');
            }  
            //AC23434 code changes end
          } else {
            SharedDataSvc.manualOverrideData.applicationStatus = 'Declined';
            navigateTo('dashboard');
          }
        }, function(response) {
          modalService.dismiss();
          void 0;
          SharedDataSvc.manualOverrideMessage = response.status_details;
        });
    }

    function buildCustomMessage(array) {
      var message = '';
      _.forEach(array, function(child) {
        if(child[0]) {
          if(message === '') {
            message += child[1];
          } else {
            message += ' ' + child[1];
          }
        }
      });
      void 0;
      return message;
    }

    function submitVerifyAddress(status) {
      var verified = [
        [vm.review.confirmDl, 'Checked Driver\'s license.'],
        [vm.review.calledLandlord, 'Called Landlord.'],
      //  [vm.review.confirmUtility, 'Checked Utility Bill.'],
        [vm.review.other, 'Other -'],
        [vm.review.verifyExplain, vm.review.verifyExplain]
      ];

      manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);

      vm.overrideSpec.overrideStatus = status;
      void 0;
      if(SharedDataSvc.manualOverrideData.overallApprovalReason && SharedDataSvc.manualOverrideData.overallApprovalReason.indexOf('Reported income outside of allowable range') > -1) {
        void 0;
        showModal('reviewIncome', 'associate-review-modal');
        vm.errors.alertClass = 'alert-danger';
      } else {
        void 0;
        var overrideData = setAddressData();
        modalService.dismiss();
        showModal('employeePassReview', 'employee-password-modal in');
        // submitAssociateReview(overrideData);
      }
    }

    function submitVerifyIncome(status) {
      vm.overrideSpec.overrideStatus = status;
      showModal('employeePassReview', 'employee-password-modal in');
    }

    function verifyAssociatePassReview(pass) {
      void 0;
      if(pass) {
        $scope.noEmpPass = false;

        var auth = authentication.storePass;

        if(auth === pass) {
          submitAssociateReview();
        } else {
          manualOverrideFactory.associateReview.employeePassSubmitted = true;
          showModal('employeePass');
        }
      } else {
        $scope.noEmpPass = true;
        void 0;
      }
    }

    function verifyAssociatePassCheck(pass) {
      //console.log('associate pass review', pass);
      
      if(pass) {
        $scope.noEmpPass = false;
        var auth = authentication.storePass;

        if(auth === pass) {
          declineApproval();
        } else {
          //manualOverrideFactory.associateReview.employeePassSubmitted = true;
          vm.associatePassFail = true;
          /*VAN - 782 - VAN - On clicking decline button on manual intervention and on entering the wrong password, application is getting approved - Starts*/ 
          manualOverrideFactory.associateReview.employeePassSubmitted = true;
          showModal('employeePassDecline');
          /*showModal('employeePass');*/
          /*VAN - 782 - VAN - On clicking decline button on manual intervention and on entering the wrong password, application is getting approved -  Ends*/ 
          void 0;
        }
      } else {
        $scope.noEmpPass = true;
        void 0;
      }
    }

    function verifyAssociatePassOverride(pass) {
      void 0;
      // manualOverrideFactory.associateReview.employeePassSubmitted = false;
      var auth = authentication.storePass;

      if(auth === pass) {
        void 0;
        if($rootScope.isSubmittedForOverride){
          submitApprovalOverride();
          $rootScope.isSubmittedForOverride = false;
        } else {
          declineApproval();
        }       
        
      } else {
        // showModal('associatePassFail');
        manualOverrideFactory.associateReview.employeePassSubmitted = true;
        showModal('employeePass');
        void 0;
      }
    }

    function submitAssociatePass(pass) {
      var overrideData = setAddressData();

      if(pass) {
        overrideData.employeeId = pass;
        if(overrideData.verifyExplain !== "") {
          if(vm.review.employeeName) {
            overrideData.customMessage = vm.review.verifyExplain + " - " + vm.review.employeeName;
          } else {
            overrideData.customMessage = vm.review.verifyExplain + " - " + vm.approval.employeeName;
          }
        } 
        // submitAssociateReview(overrideData);
      } else {
        void 0;
      }
    }

    function submitApprovalOverride() {
      // console.log('submit approval override');
      showModal('Processing', 'processing-modal');
      setApprovalData();
      $log.log('override spec', vm.overrideSpec);
      vm.overrideSpec.engagementId = vm.engagementId;
      manualOverrideFactory.submitOverride(vm.overrideSpec)
        .then(function(response) {
          modalService.dismiss();
          vm.item.approvalAmount = response.OverrideApprovalAmount;
          if(String(vm.item.approvalAmount).indexOf('.') > -1) {
            var formatAmount = String(vm.item.approvalAmount).split('.');
            if(formatAmount[1].length === 1) {
              vm.item.approvalAmount = formatAmount[0] + '.' + formatAmount[1] + '0';
            }
          }
          if(vm.overrideSpec.overrideCode === 1) {
            showModal('overrideSuccess');
          } else {
            modalService.dismiss();
            $route.reload();
          }
        }, function(response) {
          void 0;
          SharedDataSvc.manualOverrideMessage = response.status_details;
          if(response.data.errorData.status_code === 'ENG-K1004-500'){
            showModal('overrideAlreadyIncreased', 'override-initial-modal');
          } else if(response.data.errorData.status_code === 'ENG-K1005-500' || response.data.errorData.status_code === 'ENG-K1006-500'){
            showModal('overrideAlreadyDeclined', 'override-initial-modal');
          } else {
            showModal('overrideUnsuccess', 'override-initial-modal');
          }
        });
    }
  }

})();

// This controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('delivery', Delivery);

  Delivery.$inject = ["$scope", "$rootScope", "$log", "$routeParams", "$q", "$sce", "$modal", "$location", "$cookies", "SharedDataSvc", "base64", "modalService", "getStoreInfoFactory", "updateEngagementItemFactory", "customerDataFactory", "dashboardServices", "agreementFactory"];

  function Delivery($scope,
                    $rootScope,
                     $log,
                     $routeParams,
                     $q,
                     $sce,
                     $modal,
                     $location,
                     $cookies,
                     SharedDataSvc,
                     base64,
                     modalService,
                     getStoreInfoFactory,
                     updateEngagementItemFactory,
                     customerDataFactory,
                     dashboardServices,
                     agreementFactory//AC 32101 code changes
                    ) {
    /*jshint validthis: true */
    var vm = this;

    // set scope variable for processing modal
    $scope.title = 'Processing';
    $scope.modalTitle = 'Processing';

    $scope.isBarcodeEnabled = false;

    // Variables
    vm.customerData = SharedDataSvc.globalVars.order.currentEngagement;
    vm.engagement;
    vm.modal;
    vm.type;
    vm.invoiceNumber = "";
    vm.associate = "";
    vm.storeInfo;
    vm.dt;
    vm.engageSpec = SharedDataSvc.deliveryData;
    vm.errors = {
      date: false,
      name: false,
      type: false,
      invoice: false
    };
    vm.empPass;
    vm.noAssociatePass = false;
    vm.invalidAssociatePass = false;

    vm.empPin;
    vm.noAssociatePin = false;
    vm.invalidAssociatePin = false;
    vm.immediateInvoicing = true;
    vm.noInvoiceAndName = false;

    vm.processingModal;

    // Controller Functions
    vm.navigateTo = navigateTo;
    vm.showModal = showModal;
    vm.validate = validate;
    vm.updateEngagement = updateEngagement;
    vm.updateEngagementFromInvoiceDashoard = updateEngagementFromInvoiceDashoard;
    vm.setDeliveryType = setDeliveryType;
    vm.scheduleDeliveryPassCheck = scheduleDeliveryPassCheck;
    vm.scheduleDeliveryPinCheck = scheduleDeliveryPinCheck;
    vm.generateVccDetails = generateVccDetails;

    // Initialize Controller
    init();

    function init() {
      // console.log('errors', vm.errors);
      void 0;
      void 0;

      //AC 31704
      $rootScope.englishInstructions = SharedDataSvc.globalVars.storeSetup.checkOutInstructions ? SharedDataSvc.globalVars.storeSetup.checkOutInstructions.englishInstructions : '';

//AC 32101 code changes
      if(vm.customerData.engagementId){
    	  
      updateEngagementItemFactory.getData(vm.customerData.engagementId)
        .then(function (response) {
          vm.engagement = response;
          SharedDataSvc.currentEngagementData = response;
          delete vm.engagement.status;
          $log.log('[delivery.init] engagement', vm.engagement);
          
          if((vm.engagement.invoiceNum) && (vm.engagement.salesAssociateID)){
          	if( (vm.engagement.invoiceNum != "") && (vm.engagement.salesAssociateID != "")){
	          	vm.invoiceNumber = vm.engagement.invoiceNum;
	          	vm.associate = vm.engagement.salesAssociateID;
	        }
          }
          
            getStoreInfoFactory.getData()
                .then(function(response) {
                  void 0;
                  vm.storeInfo = response;
                  $rootScope.vccPrint = response.vccPrintAWP;
                  vm.immediateInvoicing = response.immediateInvoicing;
                  $scope.isBarcodeEnabled = response.isBarcodeEnabled;
                  if(vm.storeInfo.invoiceAtOrderconfirmation == 1){
                    vm.invoiceNumber = vm.engagement.invoiceNum;
                    vm.associate = vm.engagement.salesAssociateID;
                  }
                });
        }, function(response) {
          void 0;
        });
      } else {//AC 32101 code changes starts
    	  if(SharedDataSvc.globalVars.invoiceAndDeliveryModal){
    		  showProcessingModal();
    	  }
    	  vm.engagement = SharedDataSvc.globalVars.order.currentEngagement;
    	  agreementFactory.searchAgreement('agreementNumber', vm.customerData.agreementNumber)
			.then(function(data) {
				if(vm.processingModal)
				vm.processingModal.dismiss();
				var response = JSON.parse(data);
				 if((response[0].invoiceId) && (response[0].salesPerson)){
			          	if( (response[0].invoiceId != "") && (response[0].salesPerson != "")){
				          	vm.invoiceNumber = response[0].invoiceId;
				          	vm.associate = response[0].salesPerson;
				        }
			          }
				 
				 vm.engagement.invoiceNum = vm.invoiceNumber;
	              vm.engagement.salesAssociateID = vm.associate;
		    	  getStoreInfoFactory.getData()
		          .then(function(response) {
		            void 0;
		            vm.storeInfo = response;
		            vm.immediateInvoicing = response.immediateInvoicing;
		            $scope.isBarcodeEnabled = response.isBarcodeEnabled;
		            if(vm.storeInfo.invoiceAtOrderconfirmation == 1){
		              vm.invoiceNumber = vm.engagement.invoiceNum;
		              vm.associate = vm.engagement.salesAssociateID;
		            }
		            
		          });
		    	  SharedDataSvc.globalVars.invoiceAndDeliveryModal = false;
			});
    	 
      }//AC 32101 code changes ends
    }

    function navigateTo(target) {
      //vm.modal.hide();
      $location.path(target);
    };

    function showModal(name, windowClass) {
      windowClass = windowClass || '';
      vm.modal = modalService.open({
        templateUrl: '/partials/modal_' + name + '.html',
        backdrop: 'static',
        windowClass: windowClass,
        controller: 'delivery',
        controllerAs: 'delivery',
        scope: $scope
      }).result.then(function(result){ 
        void 0 
      }, function(result){ 
        if (result === 'Failure') {
          modalService.open({
            templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html'
          });
        }
      });
    }

    // schedule delivery associate password check
    function scheduleDeliveryPassCheck(pass) {
      if (pass) {
        vm.noAssociatePass = false;
        var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
        if(auth === pass) {
          vm.empPass = '';
          vm.invalidAssociatePass = false;
          vm.noAssociatePass = false;
          updateEngagement();
        } else {
          void 0;
          vm.invalidAssociatePass = true;
        }
      }else{
        void 0;
        vm.invalidAssociatePass = false;
        vm.noAssociatePass = true;
      }
    }

    // schedule delivery associate password check
    function scheduleDeliveryPinCheck(pin) {
      if (pin) {
        vm.noAssociatePin = false;
        var auth = SharedDataSvc.base64.decode($cookies.getObject('store_first_name'));
        if(auth === pin) {
          vm.empPass = '';
          vm.invalidAssociatePin = false;
          vm.noAssociatePin = false;
          updateEngagementFromInvoiceDashoard();
        } else {
          void 0;
          vm.invalidAssociatePin = true;
        }
      }else{
        void 0;
        vm.invalidAssociatePin = false;
        vm.noAssociatePin = true;
      }
    }

    function validate(context) {
      var errors;

      // Reset Errors
      vm.errors.invoice = false;
      vm.errors.name = false;
      vm.errors.date = false;
      vm.errors.type = false;

      vm.noInvoiceAndName = false;

      vm.engageSpec.applicationStatus = vm.engagement.applicationStatus;
      vm.engageSpec.customerId = vm.engagement.customerId;
      vm.engageSpec.orderStatus = vm.engagement.orderStatus;
      vm.engageSpec.salesStatus = vm.engagement.salesStatus;
      vm.engageSpec.paymentStatus = vm.engagement.paymentStatus;
      vm.engageSpec.agreementStatus = vm.engagement.agreementStatus;
      vm.engageSpec.vanStoreId = SharedDataSvc.vanStoreId;
      vm.engageSpec.customerHtID = vm.engagement.customerHtID;
      vm.engageSpec.engagementId = vm.engagement.engagementId;
      vm.engageSpec.orderContent = vm.engagement.orderContent;

      if(context == "noInvoiceAndName") {
        vm.noInvoiceAndName = true;
      }

      if(vm.storeInfo.invoiceAtOrderconfirmation != 1) {
        vm.errors.invoice = false;
        vm.errors.name = false;
        if(context != "noInvoiceAndName"){
          if(context == "invoiceDashboard"){
            if(!vm.engagement.invoiceNum){
              vm.errors.invoice = true;
            }
            if(!vm.engagement.salesAssociateID){
              vm.errors.name = true;
            }
          } else {
            if(!vm.invoiceNumber){
              vm.errors.invoice = true;
            }
            if(!vm.associate){
              vm.errors.name = true;
            }
          }
        }       
      }
      
      if(!SharedDataSvc.invoiceDeliveryData.date) {
        vm.errors.date = true;
      }
      if(!vm.type) {
        vm.errors.type = true;
      }

      errors = _.contains(vm.errors, true);

      if(!errors) {
        void 0;
        // SharedDataSvc.invoiceDeliveryData.date = vm.dt;
        if(vm.invoiceNumber && (context != "invoiceDashboard")){
          SharedDataSvc.invoiceDeliveryData.invoiceNum = vm.invoiceNumber;
        }else{
          SharedDataSvc.invoiceDeliveryData.invoiceNum = vm.engagement.invoiceNum;
        }

        if(vm.associate && (context != "invoiceDashboard")){
          SharedDataSvc.invoiceDeliveryData.associateId = vm.associate;
        }else{
          SharedDataSvc.invoiceDeliveryData.associateId = vm.engagement.salesAssociateID;
        }

        submitDelivery(vm.noInvoiceAndName);
      }
    }

    function setDeliveryType() {
      void 0;
      SharedDataSvc.invoiceDeliveryData.type = vm.type;
    }

    function addDecimalToInitialPayment(amount) {
      var len = amount.length,
          decIndex = len - 2,
          array = amount.split('');

      array[decIndex] = '.' + array[decIndex];
      amount = array.join('');
      return amount;
    }

    function setDeliveryInfo() {
      vm.engagement.estimatedDeliveryDate = moment(SharedDataSvc.invoiceDeliveryData.date).format('YYYY-MM-DD') + ' 00:00';
      SharedDataSvc.invoiceDeliveryData.deliveryDate = vm.engagement.estimatedDeliveryDate;
      void 0;
      vm.engagement.deliveryStatus = "Scheduled";
      SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
      if(SharedDataSvc.invoiceDeliveryData.type === 'pickup') {
        void 0;
        vm.engagement.deliveryStatus = "Pickup";
        SharedDataSvc.invoiceDeliveryData.deliveryType = "Pickup";
      } else {
        vm.engagement.deliveryStatus = "Scheduled";
        SharedDataSvc.invoiceDeliveryData.deliveryType = "Scheduled";
      }
      if(vm.immediateInvoicing || !vm.noInvoiceAndName) {
        vm.engagement.salesStatus = "Submitted";
        vm.engagement.invoiceNum = SharedDataSvc.invoiceDeliveryData.invoiceNum;
        vm.engagement.salesAssociateID = SharedDataSvc.invoiceDeliveryData.associateId;  
      }  
    }

    function showProcessingModal () {
        $rootScope.modalTitle = 'Processing';
        vm.processingModal = modalService.open({
          templateUrl: '/partials/modal_Processing.html',
          title: $scope.title,
          backdrop: 'static',
          windowClass: 'processing-modal',
          show: true
        });
    }

    function generateVccDetails(engagementID) {
      void 0;
      showProcessingModal();
      dashboardServices.getCreditCardDetails(engagementID)
        .then(function(response){
          void 0;

          var pdfBlob = SharedDataSvc.createPdfBlob(response.vccInvoicePdf);
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(pdfBlob);
            vm.processingModal.dismiss();
            navigateTo('dashboard');
          } else {
            var objectURL = URL.createObjectURL(pdfBlob);
            $rootScope.securePdf = $sce.trustAsResourceUrl(objectURL);
          }
          if($rootScope.securePdf) {
            //vm.processingModal.dismiss();
            var vccPrintDetailModal = modalService.open({
              templateUrl: '/partials/modal_vccPrintReceipt.html',
              backdrop: 'static',
              controller: 'delivery',
              controllerAs: 'delivery',
              show:true
            });
            vccPrintDetailModal.result.then(function(){
              SharedDataSvc.clearPrintSection();
              SharedDataSvc.globalVars.navigateToDashboard = false;
              navigateTo('dashboard');
            });
          }

        },function(response){
          void 0;
          if(response.data.errorData.status_code === 'ENG-V116-404') {
            //vm.processingModal.dismiss();
            $rootScope.modalMessage = response.data.errorData.status_message;
            var errorModal = modalService.open({
              templateUrl: '/partials/modal_vccPrintReceiptError.html',
              backdrop: 'static',
              controller: 'delivery',
              controllerAs: 'delivery',
              show:true
            });
            errorModal.result.then(function(){
              navigateTo('dashboard');
              SharedDataSvc.globalVars.navigateToDashboard = false;
            });          
          } else if(response.data.errorData.status_code === 'ENG-V101-500') {
              SharedDataSvc.vccBarcodeGenerateAttempts = SharedDataSvc.vccBarcodeGenerateAttempts + 1;
              void 0;
              if(SharedDataSvc.vccBarcodeGenerateAttempts === 3) {
                SharedDataSvc.vccBarcodeGenerateAttempts = 0;
                navigateTo('dashboard');
              } else {
                $rootScope.modalMessage = response.data.errorData.status_message;
                var retryBarcodeGeneration = modalService.open({
                  templateUrl: '/partials/modal_vccPrintReceiptError.html',
                  backdrop: 'static',
                  controller: 'delivery',
                  controllerAs: 'delivery',
                  show:true
                });
                retryBarcodeGeneration.result.then(function(){
                  // close modal
                  var vccDetails = modalService.open({
                    templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
                    backdrop: 'static',
                    controller: 'delivery',
                    controllerAs: 'delivery',
                    show:true
                  });
                });
              }
          }
        });
    }

    function updateEngagement() { 
      showProcessingModal();
      setDeliveryInfo();
      void 0;
      delete vm.engagement.status;
      delete vm.engagement['status'];
      if(vm.engagement.engagementId){//AC 32101 code changes
    	  updateEngagementItemFactory.putData(vm.engagement)
          .then(function(response) {
            void 0;
            SharedDataSvc.globalVars.order.currentEngagement.deliveryStatus = SharedDataSvc.invoiceDeliveryData.deliveryType;
            if(vm.immediateInvoicing) {
              SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
            }
            if(response.estimatedDeliveryDate) {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
            }
            //AC 32805 code changes starts
            if(response.invoiceNum){
            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceNum;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
            }
            //AC 32805 code changes ends
            vm.processingModal.dismiss();
            if(SharedDataSvc.globalVars.navigateToDashboard) {
              //vm.processingModal.dismiss();
              $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
              $rootScope.initialAmountPaid = SharedDataSvc.initialPaymentAmount;
              $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
              var vccDetails = modalService.open({
                templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
                backdrop: 'static',
                controller: 'delivery',
                controllerAs: 'delivery',
                show:true
              });
              vccDetails.result.then(function() {
                navigateTo('dashboard');
              });
            } else if(!vm.immediateInvoicing && vm.noInvoiceAndName) {
              navigateTo('dashboard');
            }
          }, function(response) {
            showModal(failedModal, 'delivery-modal');
            void 0;
          });
      } else {//AC 32101 code changes starts
    	  var oldEngagement = new Object();
          oldEngagement.deliveryStatus = vm.engagement.deliveryStatus;
          oldEngagement.invoiceId = vm.engagement.invoiceNum;
          oldEngagement.estimatedDeliveryDate = vm.engagement.estimatedDeliveryDate;
          oldEngagement.salesPerson = vm.engagement.salesAssociateID;
          oldEngagement.agreementId = vm.customerData.agreementId;
          
          
    	  agreementFactory.updateAgreement(oldEngagement)
			.then(function(respone){
				if(vm.immediateInvoicing) {
		              SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
		            }
		            if(response.estimatedDeliveryDate) {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
		            }
		            //AC 32805 code changes starts
		            if(response.invoiceId){
		            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceId;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
		            }
		            //AC 32805 code changes ends
		            vm.processingModal.dismiss();
		            if(SharedDataSvc.globalVars.navigateToDashboard){
			              $location.path('dashboard');
			            }
			}, function(response) {
	            showModal(failedModal, 'delivery-modal');
	            void 0;
	          });
      }//AC 32101 code changes ends
      
    }

    function updateEngagementFromInvoiceDashoard() {
      showModal('Processing', 'processing-modal');
      setDeliveryInfo();
      void 0;
      delete vm.engagement.status;
      delete vm.engagement['status'];

      var oldEngagement = new Object();
      oldEngagement.deliveryStatus = vm.engagement.deliveryStatus;
      oldEngagement.customerId = vm.engagement.customerId;
      oldEngagement.engagementId = vm.engagement.engagementId;
      oldEngagement.invoiceNum = vm.engagement.invoiceNum;
      oldEngagement.estimatedDeliveryDate = vm.engagement.estimatedDeliveryDate;
      oldEngagement.salesAssociateID = vm.engagement.salesAssociateID;
      oldEngagement.salesStatus = vm.engagement.salesStatus;
      oldEngagement.vanStoreId = vm.engagement.vanStoreId;

      if(vm.engagement.engagementId){//AC 32101 code changes
    	  updateEngagementItemFactory.putData(oldEngagement)
          .then(function(response) {
            void 0;
            SharedDataSvc.globalVars.order.currentEngagement.deliveryStatus = SharedDataSvc.invoiceDeliveryData.deliveryType;
            SharedDataSvc.globalVars.order.currentEngagement.salesStatus = "Submitted";
            if(response.estimatedDeliveryDate) {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
            }
            //AC 32805 code changes starts
            if(response.invoiceNum){
            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceNum;
            } else {
              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
            }
            //AC 32805 code changes ends
            modalService.dismiss();
            if(SharedDataSvc.globalVars.navigateToDashboard){
              $location.path('dashboard');
            }
          }, function(response) {
            showModal(failedModal, 'delivery-modal');
            void 0;
          });  
      }else{//AC 32101 code changes starts
    	  var oldEngagement = new Object();
          oldEngagement.deliveryStatus = vm.engagement.deliveryStatus;
          oldEngagement.invoiceId = vm.engagement.invoiceNum;
          oldEngagement.estimatedDeliveryDate =moment(vm.engagement.estimatedDeliveryDate).format('YYYY-MM-DD'); 
          oldEngagement.salesPerson = vm.engagement.salesAssociateID;
          oldEngagement.agreementId = vm.customerData.agreementId;
          
    	  agreementFactory.updateAgreement(oldEngagement)
			.then(function(response){
				//vm.processingModal.dismiss();
				modalService.dismiss();
				if(response.estimatedDeliveryDate) {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = response.estimatedDeliveryDate;
		            } else {
		              SharedDataSvc.globalVars.order.currentEngagement.estimatedDeliveryDate = SharedDataSvc.invoiceDeliveryData.deliveryDate;
		            }
				//AC 32805 code changes starts
				if(response.invoiceId){
	            	SharedDataSvc.globalVars.order.currentEngagement.invoiceId = response.invoiceId;
	            } else {
	              SharedDataSvc.globalVars.order.currentEngagement.invoiceId = SharedDataSvc.invoiceDeliveryData.invoiceNum;
	            }
	            //AC 32805 code changes ends
		            
		            if(SharedDataSvc.globalVars.navigateToDashboard){
		              $location.path('dashboard');
		            }
			}, function(response) {
	            showModal(failedModal, 'delivery-modal');
	            void 0;
	          });
      }
     //AC 32101 code changes ends
    }

    function deliveryModalOptions(engagement, deliveryPart) {
      deliveryPart = deliveryPart || '';
      return {
        templateUrl: 'partials/modal_invoiceAndDelivery' + deliveryPart + '.html',
        backdrop: 'static',
        controller: 'delivery',
        controllerAs: 'delivery',
        show: true
      }
    }

    function showDeliveryConfirmation() {      
      var deliveryConfirmModal = modalService.open(deliveryModalOptions(vm.customerData, 'Confirm'));
      deliveryConfirmModal.result.then(function (result) {
        if (result && result.status === 'confirm') {
          updateEngagement();
          void 0;
        }
      },
        //dismiss
        function (result) {
          if (result && result.status === 'declined') {
            var deliveryConfirmModalError = modalService.open({
              templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html'
            });
            deliveryConfirmModalError.result.then(function(result) {
              //close
              navigateTo('dashboard');
            });
          }
        });
    }

    function submitDelivery(noInvoice) {
      if(vm.type == 'pickup') {
        void 0;
        if(!vm.immediateInvoicing && noInvoice) {
          updateEngagement();
        }        
        $scope.$close({ status: 'pickup'});
      } else {
        void 0;
        	//$scope.$close({ status: 'confirm', data: vm.customerData });
        	if(!vm.immediateInvoicing && noInvoice) {
        		showDeliveryConfirmation();
        	}
        	/*VAN-421 - PR-AWP: Address info is missing on Invoice and Delivery Popup - starts*/
        	if(vm.customerData!=undefined && vm.customerData!=null){
        		if(vm.customerData.customerAddress1!=null && vm.customerData.customerCity!=null
        				&& vm.customerData.customerState!=null && vm.customerData.customerZipCode!=null){
        			$scope.$close({ status: 'confirm', data: vm.customerData });
        		}
        		else{
        			var scheduleDelivery = modalService.open({
        				templateUrl: '/partials/modal_scheduleDeliveryPass.html',
        				backdrop: 'static',
        				controller: 'delivery',
        				controllerAs: 'delivery',
        				show:true
        			});
        		}
        	}
        	/*VAN-421 - PR-AWP: Address info is missing on Invoice and Delivery Popup - Ends*/
      }
    }
  }

})();



///////////////////////////////////////////
//////////////////////////////////////////
///   TRAINING CONTROLLER   ////////////
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
	.module('app.training')
	.controller('Training', Training);

	/* @ngInject */
	function Training(
		$scope,
		$rootScope,
		$location,
		$idle,
		$routeParams,
		$log,
		$sce,
		$cookies,
		modalService,
		authentication,
		SharedDataSvc,
		getTrainingLibraryFactory,
		getStoreInfoFactory,
		API_DOMAIN
		) {

		/*jshint validthis: true */
		var training = this;
		training.documentSelected = documentSelected;
		training.documentIcon = documentIcon;
		training.hideShowBanner = hideShowBanner;
		training.showModal = showModal;
		training.formatDate = formatDate;
		training.cleanText = cleanText;
		training.showTrainingItem = showTrainingItem;

		training.modal;
		training.videoTitle;
		training.videoUrl;
		training.storeInfo;
		
		init();
		function init()
		{
			$rootScope.hideDashboardButton = false;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = true;

			getStoreInfoFactory.getData()
			.then(function(response) {
				training.storeInfo = response;
				$rootScope.invoiceMethod = response.invoiceMethod;
				void 0;
			}, function(response) {
				$log.log('error getting storeInfo', response);
			});

			SharedDataSvc.trainLibraryDocs = JSON.parse(sessionStorage.getItem('trainLibraryDocs'));
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Starts*/			
			//$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? SharedDataSvc.trainLibraryDocs.documents_PR : SharedDataSvc.trainLibraryDocs.documents;
			var documentList_Spa =[];
			var documentList_Eng =[];
			if($rootScope.state=="PR"){
				if(SharedDataSvc.trainLibraryDocs.documents_PR){
					for(var j=0;j<SharedDataSvc.trainLibraryDocs.documents_PR.length;j++){
						if(SharedDataSvc.trainLibraryDocs.documents_PR[j].language=="SPA"){
							documentList_Spa.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}else{
							documentList_Eng.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}
					}
					$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? documentList_Spa: documentList_Eng;
				}
			}
			else{
				$rootScope.documentList = SharedDataSvc.trainLibraryDocs.documents;
			}
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Ends*/
			
			if(_.size($rootScope.documentList) == 0)
			{
				var item = {
					en : {
						title : "There are no training documents to display at this time.",
						summary : "",
						contentType : ""
					}, es: {
						title : "No hay documentos de formación para mostrar en este momento.",
						summary : "",
						contentType : ""
					}
				};
				$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? [item.es] : [item.en];
			}			
		}

		function showModal(name, windowClass, title) {
			windowClass = windowClass || '';
			$rootScope.modalTitle = title || 'Processing';

			training.modal = modalService.open({
				templateUrl: '../../../partials/modal_' + name + '.html',
				backdrop: 'static',
				windowClass: windowClass,
				// controller: 'Training',
				controllerAs: 'training',
				scope: $scope
			});
		}

		function showTrainingItem(item) {
			if(item.manned && training.storeInfo.isManned) {
				return true;
			} 
			if(item.unmanned && !training.storeInfo.isManned) {
				return true;
			}
		}

		function documentIcon(item)
		{
			var itemType = item.contentType;
			if (itemType == 'VID') {
				return 'training-vid';
			}
			else if (itemType == 'URL') {
				return 'training-web';
			}
			else if (itemType == 'PDF') {
				return 'training-pdf';
			}
			else
			{	
				return '';
			}

		}

		function formatDate(date) {
			var year = String(date).substring(0, 4),
			month = String(date).substring(4, 6),
			day = String(date).substring(6, 8),
			formattedDate = month + '-' + day + '-' + year;

			return moment(formattedDate, 'MM-DD-YYYY').format('MM/DD/YYYY');
		}

		function documentSelected (item) {
			void 0;
			if(item.contentType === 'PDF') {
				showModal('Processing', 'processing-modal');
				/*getTrainingLibraryFactory.getPdfData(item.contentURL)
				.then(
					// Success
					function(response) {
						modalService.dismiss();
						var pdfBlob = SharedDataSvc.createPdfBlob(response);
						console.log('[Training Library: Get PDF Data]');
						if (navigator.msSaveOrOpenBlob) {
							navigator.msSaveOrOpenBlob(pdfBlob);
						} else {
							var objectURL = window.URL.createObjectURL(pdfBlob);
							window.open(objectURL);
						}
					},
					// Error
					function(error) {
						
					}
				);*/
				
//				Feature 8407 - Move training library docs to S3 bucket
				getTrainingLibraryFactory.getPdfDataFromS3(item.s3ContentURL)
				.then(
					function(response) {
		            var pdfFile = new Blob([ response.data ], {
		                type : 'application/pdf'
		            });
		            modalService.dismiss();
		            
		            if (navigator.msSaveOrOpenBlob) {
						navigator.msSaveOrOpenBlob(pdfFile);
					} else {
						var pdfUrl = window.URL.createObjectURL(pdfFile);
						window.open(pdfUrl);
					}
		          
		        },
		        function(response) {
		        	modalService.dismiss();
		        });
				// showModal('trainingViewPdf');
			} else if(item.contentType === 'VID') {
				showModal('trainingVideo', 'training-video-modal');
				training.videoTitle = item.title;
				training.videoUrl = $sce.trustAsResourceUrl(item.contentURL);
			} else {
				window.open(item.contentURL);
			}
		}

		// Clean bad characters returned from service
		function cleanText(text) {
			return text.replace(/[^\u0000-\u007F]/g, "'");
		}

		//TODO: method for hide/show isNew banner
		function hideShowBanner(item)
		{
			if(item.isNew)
				return true;
			else
				return false;
		} 
	}
	Training.$inject = ["$scope", "$rootScope", "$location", "$idle", "$routeParams", "$log", "$sce", "$cookies", "modalService", "authentication", "SharedDataSvc", "getTrainingLibraryFactory", "getStoreInfoFactory", "API_DOMAIN"];
})();

///////////////////////////////////////////
//////////////////////////////////////////
///   MISSING INFO CONTROLLER   //
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
	.module('app.missingInfo')
	.controller('MissingInfo', MissingInfo);

	/* @ngInject */
	function MissingInfo(
		$scope,
		$rootScope,
		$location,
		$route,
		$idle,
		$routeParams,
		$q,
		$log,
		$sce,
		$cookies,
		$translate,
		modalService,
		authentication,
		applicationService,
		stateRestrictFactory,
		SharedDataSvc,
		agreementFactory,
		getStoreInfoFactory,
		getTrainingLibraryFactory,
		getInitialDashboard
		){

		/*jshint validthis: true */
		var MissingInfo = this;

		/* Object declarations goes below */
		$scope.customerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {
				bestCallTime:{
					Morning:'',
					Afternoon:'',
					Evening:''
				}
			},
			phone2: {
				
			},
			incomeSources: {
				sourcePhone:{
					number:""
				},
				sourceName: "",
				income:"",
				//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				nextPayDate:{
					month:'',
					day:''
				}//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			},
			personalReferences: []
		};
		// CUSTOMER INFORMATION
		$scope.coCustomerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {},
			phone2: {},
			incomeSources: {
				nextPayDate: {},
				sourcePhone:{}//VAN-2401-PR stores to use SLI- AWP re-engagement
			},
			personalReferences: [],
			dob: {}
		};

		$scope.coCustomerPrivate = {
			dob: {},
			ssn: {
				ssnType: 'SSN'
			},
			verifyssn: {
				ssnType: 'SSN'
			}
		};

		$scope.custAdditionalInfo = {
			previousAddress : {},
			moveInDate : {},
			auto: {},
			rent: {},
			own: {},
			leaseEndDate: {},
			ownRentInfo: {},
			noCocustomer: false
		}

		$scope.custAdditionalInfo.auto.noCar = false;
		$scope.customerPrivate = {
			dob: {},
			ssn: {
				ssnType : 'SSN',
				ssnTypeDisable:true
			}
		};
		$scope.ref1 = {};
		$scope.ref2 = {};
		$scope.ref3 = {};
		$scope.ref4 = {};
		$scope.leaseYears = [];
		$scope.moveInYears = [];
			
		/* Customer zip and store radius properties */
		$scope.isZipInStoreRadius = isZipInStoreRadius;
		$scope.resetZipData = resetZipData;
		$scope.zipValidation = zipValidation;
		$scope.zipSubmitFalse = zipSubmitFalse;
		$scope.customerInRadius = true;
		$scope.isZipValid = true;
		$scope.allowTextVerificationFlag = false;
		$scope.storeInfo;
		$scope.storeLatLng;
		$scope.customerDistance;
		$scope.storeRadius;
		$scope.restrictions;
		$scope.stateRestrictions;
		$scope.engagementDetails;
		$scope.ssnNumber;
		$scope.isSpanishStore;
		
		/* Methods goes below */
		$scope.validateDOB = validateDOB;
		$scope.checkState = checkState;
		$scope.navigateTo = navigateTo;
		//$scope.saveDraft = saveDraft;
		$scope.saveAndProcess = saveAndProcess;
		$scope.cancelCustomerEntry = cancelCustomerEntry;
		$scope.validateReferenceName = validateReferenceName;
		$scope.validateReferencePhone = validateReferencePhone;
		$scope.validateSecondaryPhone = validateSecondaryPhone;
		$scope.clearSSN = clearSSN;
		//$scope.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		//PR flow new additions
		$scope.setPreviousAdd = setPreviousAdd;
		$scope.showPreviousAdd = false;
		$scope.updateAutoFields = updateAutoFields;
		
		/* Object initializations goes below*/
		$scope.incomeEnums = [
			{
				type: 'EMPLOYED',
				label: {
	                en : en.application_select_empOpt1,
	                es : es.application_select_empOpt1
	            }
			},
			{
				type: 'SELFEMPLOYED',
				label: {
	                en : en.application_select_empOpt2,
	                es : es.application_select_empOpt2
	            }
			},
			{
				type: 'SOCIAL',
				label: {
	                en : en.application_select_empOpt3,
	                es : es.application_select_empOpt3
	            }
			},
			{
				type: 'RETIRED',
				label: {
	                en : en.application_select_empOpt4,
	                es : es.application_select_empOpt4
	            }
			},
			{
				type: 'PENSION',
				label: {
	                en : en.application_select_empOpt5,
	                es : es.application_select_empOpt5
	            }
			},
			{
				type: 'DISABILITY',
				label: {
	                en : en.application_select_empOpt6,
	                es : es.application_select_empOpt6
	            }
			},
		];

		$scope.payFrequencyEnums = [
			{
				type: 'MONTHLY',
				label: {
					en : en.application_select_payFreq1,
					es : es.application_select_payFreq1
				}
			},
			{
				type: 'TWICE-MONTHLY',
				label: {
					en : en.application_select_payFreq2,
					es : es.application_select_payFreq2
				}
			},
			{
				type: 'BI-WEEKLY',
				label: {
					en : en.application_select_payFreq3,
					es : es.application_select_payFreq3
				}
			},
			{
				type: 'WEEKLY',
				label: {
					en : en.application_select_payFreq4,
					es : es.application_select_payFreq4
				}
			}
		];
		
		$scope.disablingFlag ={
			firstName:true,
			lastName:true,
			middleName:true,
			address:{
				addrLine1:true,
				addrLine2:true,
				city:true,
				state:true,
				zipCode:true,
				isRenewingLease:true,
				landlordName:true,
				landLordNumber:true,
				custPaymentAmount:true,
				leaseTypeRent:true,
				apmtComplex:true,
				leaseEndDate:true
			},
			ssn:{
				ssn1:true,
				ssn2:true,
				ssn3:true
			},
			driversLicense:{
				id:true,
				issuer:true,
				idType: true
			},
			dob:{
				day:true,
				month:true,
				year:true
			},
			phone1:{
				number:true,
				phoneType:true,
				bestCallTime:{
					morning:true,
					afternoon:true,
					evening:true
				}
			},
			phone2:{
				number:true,
				phoneType:true	
			},
			email:true,
			emailVerify:true,//Story 30445 -AWP, Kiosk: Add email verification edit box
			incomeSources:{
				sourcePhone:{
					number:false
				},
				sourceName: false,
				income:false,
				monthlyIncome:false,
				employerAddress:false,
				deptTitle:false,
				supervisorName:false,
				workHours:false,
				bestPayDate:false

			},
			ref1:{
				firstName:true,
				address : true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			ref2:{
				firstName:true,
				address:true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			ref3:{
				firstName:true,
				address:true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			ref4:{
				firstName:true,
				address:true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			additionalInfo: {
				avgUtilities: false
			},
			moveInDate: true,
			previousAddress : {
				address: false,
				landlordName: false,
				landlordNumber: false,
			},
			homeType: true,
			rentOwn: {
				landlordName: true,
				landlordNumber: true,
			},
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			coCustomerInfo: {
				firstName:true,
				lastName:true,
				middleName:true,
				relationshipType:true,
				ssn:{
					ssn1:false,
					ssn2:false,
					ssn3:false
				},
				phone1:{
					number:true,
					phoneType:true,
					bestCallTime:{
						morning:true,
						afternoon:true,
						evening:true
					}
				},
				dob:{
					day:true,
					month:true,
					year:true
				},
				incomeSources:{
					sourcePhone:{
						number:false
					},
					sourceName: false,
					income:false,
					monthlyIncome:false,
					employerAddress:false,
					deptTitle:false,
					supervisorName:false,
					workHours:false,
					bestPayDate:false,
					payFrequency:false,
					takeHomePay:false
				},
				auto:{
					makeAndModel:false,
					tag:false,
					color:false,
					amount:false
				}
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
		};
		
		// set default income source
		//$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
		
		// BOOLS goes below
		$scope.customerExistsInHT = false; // true
		$scope.ssnSubmitted = false;
		$rootScope.hideMenuButtons = false;
		$scope.formSubmitted = false;
		$scope.draftSaved = false;
		$scope.amountBelowOrderTotal = false;
		$scope.secondaryNoTypeRequired = false;
		
		// VALIDATION REGEXES
		$scope.onlyNumbers = /^\d+$/;
		$scope.ssnValidation1 = /^(?!666|000|9\d{2})\d{3}$/;
		$scope.ssnValidation2 = /^(?!00)\d{2}$/;
		$scope.ssnValidation3 = /^(?!0{4})\d{4}$/;
		$scope.ccssnValidation1 = /^(?!666|000|9\d{2})\d{3}|\*\*\*$/;
		$scope.ccssnValidation2 = /^(?!00)\d{2}|\*\*$/;
		$scope.itinValidation1 = /(9\d{2})$/;
		$scope.itinValidation2 = /(d{2})$/;
		$scope.itinValidation3 = /(d{4})$/;
		$scope.phoneValidation = /^\(\d{3}\)[-.\s]?\d{3}[-.]?\d{4}$/;
		//$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[BbOoXx])[-a-zA-Z\d .,\/]*$/;
		$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[Bb]\.? ?[Oo]\.? ?[Xx]\.?)[-a-zA-Z\d\\\/ .,#\/]*$/;//VAN-913 Added to allow escape sequence character
		$scope.nameValidation = /^[a-zA-Z\s\-\']+$/; // Added for name validation 2542 & 2543. It will allows only letters
		$scope.lastNameValidation = /^\d*[a-zA-Z0-9\d\s\-\']*$/; // Added for name validation 2542 & 2543. It will allow letters, dashes, and apostrophes.

		$scope.incomeValidation = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/;
		$scope.routingValidation = /^([0][1-9]|[1][0-2]|[2][1-9]|[3][0-2]|[6][1-9]|[7][0-2])([0-9]{7})$/;
		$scope.refType  =  ["PARENT","SBLING","GRANDP","COUSIN","ANTUNC","NIENEP","FRIEND","COWORK","OTHER"];   //AC23127 code changes 
		//Story 30445 -AWP, Kiosk: Add email verification edit box - start
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.salaryValidation = /[1-9]\d*/; // AC13186 code changes 
		$scope.compareEmailVerify = compareEmailVerify;
		$scope.verifyEmail = true;
		$scope.validEmailPattern = false;
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end
		
		
		
		// DATE OF BIRTH INFO
		$scope.dobvalid = {
			day: moment().date(),
			month: moment().month() + 1,
			year: moment().year() - 18
		};
		$scope.birthYears = [];
		
		// Start VAN - 10736 Added for scroll up
		$scope.scrollToTop = function(toHere) {
			$('html, body').animate({
				scrollTop: $(toHere).position().top
			}, 'fast');
		};
		// End

		$scope.updateCoIncome = updateCoIncome;//VAN-2401-PR stores to use SLI- AWP re-engagement 
		$scope.updateCocustomerIncome = updateCocustomerIncome;//VAN-2401-PR stores to use SLI- AWP re-engagement
		
		// PUBLIC FUNCTIONS
		init();
		function init()
		{
			addYears();
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');
			if ($routeParams.data) {
				var decoded, personData;
				decoded = decodeURIComponent($routeParams.data);
				decoded = window.atob(decoded);
				decoded = JSON.parse(decoded);
				//retrieveApplication($scope.personData);

				applicationService.getApplication(decoded).then(function(successData){
					void 0;
					successData = SharedDataSvc.convertZerosToBlank(successData);
					var zipCode = successData.address[0].zipCode?successData.address[0].zipCode.substring(0,5):"";//Defect 26474 code changes
					$rootScope.findData = successData;//VAN-2401-PR stores to use SLI- AWP re-engagement
					if(!$scope.isSpanishStore)
						zipValidation(zipCode);//AC23079 code change
					fillDataInForm(successData);
				}, function(failData){
					void 0;
				});
				
			}
			
			getStoreInfoFactory.getData()
				.then(function(response) {
				  $scope.storeInfo = response;
				 // $rootScope.invoiceMethod = response.invoiceMethod;
				  $scope.storeLatLng = { 'lat': $scope.storeInfo.latitude, 'lng': $scope.storeInfo.longitude };
				  $scope.storeRadius = response.allowedRentalRadiusMiles;
				  $scope.allowTextVerificationFlag = response.allowTextVerification;
				  $scope.isReferenceRequired = response.isReferenceRequired;

				  $scope.itinAllowed = response.itinAllowed;
				  stateRestrictFactory.getStateRestrictions()
					  .then(function(response) {
						$scope.stateRestrictions = response.stateRestrictions[0];
					});

				  stateRestrictFactory.getAllStoreRestrictions($scope.storeInfo.state)
					  .then(function(response) {
						$scope.restrictions = response;
					});
			});
			
			//fillExistingFields();
			

		}


		function updateAutoFields(){
			if($scope.custAdditionalInfo.auto.noCar == true){
				$scope.custAdditionalInfo.auto.make = "";
				$scope.custAdditionalInfo.auto.model = "";
				$scope.custAdditionalInfo.auto.tag = "";
				$scope.custAdditionalInfo.auto.color = "";
				$scope.custAdditionalInfo.auto.amount = "";
			}else{//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				if($rootScope.findData.additionalInfo != null || $rootScope.findData.additionalInfo != undefined){
					if($rootScope.findData.additionalInfo.autoMakeAndModel != ''){
						var autoModel = $rootScope.findData.additionalInfo.autoMakeAndModel.split('-');
						$scope.custAdditionalInfo.auto.make = autoModel[0];
						$scope.custAdditionalInfo.auto.model = autoModel[1];
					}
					$scope.custAdditionalInfo.auto.make = $scope.custAdditionalInfo.auto.make;
					$scope.custAdditionalInfo.auto.model = $scope.custAdditionalInfo.auto.model;
					$scope.custAdditionalInfo.auto.tag = $rootScope.findData.additionalInfo.tag;
					$scope.custAdditionalInfo.auto.color = $rootScope.findData.additionalInfo.color;
					$scope.custAdditionalInfo.auto.amount = $rootScope.findData.additionalInfo.carPaymentAmount;
				}
			}//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
		}

		function fillDataInForm(successData){
			
			// Test code successData.driversLicense.birthDate = "";
			// Test code successData.personalReferences[0].firstName = "";
			
			
			
			$scope.customerInfo.firstName = successData.firstName;
			if(SharedDataSvc.isBlankOrNull(successData.firstName)){
				$scope.disablingFlag.firstName = false;
			}else{
				$scope.disablingFlag.firstName = true;
			}
			
			$scope.customerInfo.lastName =  successData.lastName;
			if(SharedDataSvc.isBlankOrNull(successData.lastName)){
				$scope.disablingFlag.lastName = false;
			}else{
				$scope.disablingFlag.lastName = true;
			}
			
			$scope.customerInfo.middleName = successData.middleInitial;
			if(SharedDataSvc.isBlankOrNull(successData.middleInitial)){
				$scope.disablingFlag.middleName = false;  /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.middleName = true;
			}
			
			$scope.customerInfo.address.addrLine1 = successData.address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].addrLine1)){
				$scope.disablingFlag.address.addrLine1 = false;
			}else{
				$scope.disablingFlag.address.addrLine1 = true;
			}
			
			$scope.customerInfo.address.addrLine2 = successData.address[0].addrLine2;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].addrLine2)){
				$scope.disablingFlag.address.addrLine2 = false;  /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.address.addrLine2 = true;
			}
			
			$scope.customerInfo.address.city = successData.address[0].city;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].city)){
				$scope.disablingFlag.address.city = false;
			}else{
				$scope.disablingFlag.address.city = true;
			}
			
			$scope.customerInfo.address.state = successData.address[0].state;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].state)){
				$scope.disablingFlag.address.state = false;
			}else{
				$scope.disablingFlag.address.state = true;
			}
			
			$scope.customerInfo.address.zipCode = successData.address[0].zipCode?successData.address[0].zipCode.substring(0,5):"";//Defect 26474 code change
			if(SharedDataSvc.isBlankOrNull(successData.address[0].zipCode)){
				$scope.disablingFlag.address.zipCode = false;
			}else{
				$scope.disablingFlag.address.zipCode = true;
			}

				
			if(SharedDataSvc.isBlankOrNull(successData.address[0].moveInDate)){
				$scope.disablingFlag.moveInDate = false;
			}else{
				$scope.disablingFlag.moveInDate = true;
				$scope.custAdditionalInfo.moveInDate.month = successData.address[0].moveInDate.substr(4, 2);
				$scope.custAdditionalInfo.moveInDate.year = successData.address[0].moveInDate.substr(0, 4);
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].isRenewingLease)){
				$scope.disablingFlag.address.isRenewingLease = false;
			}else{
				$scope.disablingFlag.address.isRenewingLease = true;
				$scope.customerInfo.address.homeType = successData.address[0].isRenewingLease;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].landLordName)){
				$scope.disablingFlag.address.landlordName = false;
			}else{
				$scope.disablingFlag.address.landlordName = true;
				$scope.custAdditionalInfo.ownRentInfo.mortgageCompany = successData.address[0].landLordName;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].landLordNumber)){
				$scope.disablingFlag.address.landLordNumber = false;
			}else{
				$scope.disablingFlag.address.landLordNumber = true;
				$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNum = successData.address[0].landLordNumber;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			if(successData.additionalInfo != null){
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.averageMonthlyUtilities)){
					//$scope.disablingFlag.address.avgUtilities = false;
					$scope.disablingFlag.additionalInfo.avgUtilities = false;
				}else{
					//$scope.disablingFlag.address.avgUtilities = true;
					$scope.disablingFlag.additionalInfo.avgUtilities = true;
					$scope.custAdditionalInfo.ownRentInfo.avgUtilities = successData.additionalInfo.averageMonthlyUtilities;
				}
			}else{
				$scope.disablingFlag.additionalInfo.avgUtilities = false;
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
		
			//Commented as part of VAN-2401-PR stores to use SLI- AWP re-engagement
			/*if(SharedDataSvc.isBlankOrNull(successData.address[0].custPayment)){
				$scope.disablingFlag.address.custPaymentAmount = false;
			}else{
				$scope.disablingFlag.address.custPaymentAmount = true;
				$scope.custAdditionalInfo.ownRentInfo.custPaymentAmount = successData.address[0].custPayment;
			}*/

			if(SharedDataSvc.isBlankOrNull(successData.address[0].leaseType)){
				$scope.disablingFlag.address.leaseTypeRent = false;
			}else{
				$scope.disablingFlag.address.leaseTypeRent = true;
				$scope.custAdditionalInfo.rent.leaseTypeRent = successData.address[0].leaseType;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].apartmentComplex)){
				$scope.disablingFlag.address.apmtComplex = false;
			}else{
				$scope.disablingFlag.address.apmtComplex = true;
				$scope.custAdditionalInfo.rent.apmtComplex = successData.address[0].apartmentComplex;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].leaseEndDate)){
				$scope.disablingFlag.address.leaseEndDate = false;
			}else{
				$scope.disablingFlag.address.leaseEndDate = true;
				$scope.custAdditionalInfo.leaseEndDate.month = successData.address[0].leaseEndDate.substr(4, 2);
				$scope.custAdditionalInfo.leaseEndDate.year = successData.address[0].leaseEndDate.substr(0, 4);
			}



			
			if(successData.additionalInfo){
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.averageMonthlyUtilities)){
					$scope.disablingFlag.additionalInfo.avgUtilities = false;
				}else{
					$scope.disablingFlag.additionalInfo.avgUtilities = true;
					$scope.disablingFlag.additionalInfo.avgUtilities = successData.additionalInfo.averageMonthlyUtilities;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.previousAddress[0].addrLine1)){
					$scope.disablingFlag.previousAddress.address = false;
				}else{
					$scope.disablingFlag.previousAddress.address = true;
					$scope.custAdditionalInfo.previousAddress.addrLine1 = successData.additionalInfo.previousAddress[0].addrLine1;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.previousAddress[0].landLordName)){
					$scope.disablingFlag.previousAddress.landlordName = false;
				}else{
					$scope.disablingFlag.previousAddress.landlordName = true;
					$scope.custAdditionalInfo.previousAddress.mortgageName = successData.additionalInfo.previousAddress[0].landLordName;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.previousAddress[0].landLordNumber)){
					$scope.disablingFlag.previousAddress.landlordNumber = false;
				}else{
					$scope.disablingFlag.previousAddress.landlordNumber = true;
					$scope.custAdditionalInfo.previousAddress.mortgagePhone = successData.additionalInfo.previousAddress[0].landLordNumber;
				}

				//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.customerPaymentAmount)){
					$scope.disablingFlag.address.custPaymentAmount = false;
				}else{
					$scope.disablingFlag.address.custPaymentAmount = true;
					$scope.custAdditionalInfo.ownRentInfo.custPaymentAmount = successData.additionalInfo.customerPaymentAmount;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourceAddress.addrLine1)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.employerAddress = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.employerAddress = true;
					$scope.coCustomerInfo.incomeSources.employerAddress = successData.additionalInfo.coCustomerIncomeSource[0].sourceAddress.addrLine1;
				}

				$scope.coCustomerInfo.incomeSources.deptTitle =  successData.additionalInfo.coCustomerIncomeSource[0].department !=null ? successData.additionalInfo.coCustomerIncomeSource[0].department : successData.additionalInfo.coCustomerIncomeSource[0].sourceDepartment;
				if(SharedDataSvc.isBlankOrNull($scope.coCustomerInfo.incomeSources.deptTitle)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.deptTitle = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.deptTitle = true;
					$scope.coCustomerInfo.incomeSources.deptTitle = successData.additionalInfo.coCustomerIncomeSource[0].department != null?successData.additionalInfo.coCustomerIncomeSource[0].department :successData.additionalInfo.coCustomerIncomeSource[0].sourceDepartment ;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].supervisorName)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.supervisorName = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.supervisorName = true;
					$scope.coCustomerInfo.incomeSources.supervisorName = successData.additionalInfo.coCustomerIncomeSource[0].supervisorName;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].workHours)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.workHours = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.workHours = true;
					$scope.coCustomerInfo.incomeSources.workHours = successData.additionalInfo.coCustomerIncomeSource[0].workHours;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].bestPayDate)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.bestPayDate = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.bestPayDate = true;
					$scope.coCustomerInfo.incomeSources.bestPayDate = successData.additionalInfo.coCustomerIncomeSource[0].bestPayDate;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].paydayFrequency)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.payFrequency = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.payFrequency = true;
					$scope.coCustomerInfo.incomeSources.payFrequency = successData.additionalInfo.coCustomerIncomeSource[0].paydayFrequency;
				}

				$scope.coCustomerInfo.incomeSources.income = successData.additionalInfo.coCustomerIncomeSource[0].incomeType;
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].incomeType)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.income = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.income = true;
				}

				if(successData.additionalInfo.coCustomerIncomeSource[0].sourceName != '0'){
					$scope.coCustomerInfo.incomeSources.sourceName = successData.additionalInfo.coCustomerIncomeSource[0].sourceName;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourceName)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceName = false;
				} else if(successData.incomeSources[0].sourceName == "0"){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceName = false;
				} else {
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceName = true;
				}

				if(successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone !== null && successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone !== '0000000'){
					$scope.coCustomerInfo.incomeSources.sourcePhone.number = successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone.areaCode + successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone.number;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourcePhone.number = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourcePhone.number = true;
				}

				var coCustStartDateSelection;
				var coCustMonthThresholds = ['11', '23', '48', '60'];
				var coCustStartDate = moment(successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate, 'YYYYMMDD');
				var coCustMonthsSinceStart = moment().diff(coCustStartDate, 'months');
				for (var i = 0; i < coCustMonthThresholds.length; i++) {
					if(coCustMonthsSinceStart <= coCustMonthThresholds[i] && coCustMonthsSinceStart ) {
						coCustStartDateSelection = coCustMonthThresholds[i];
						break;
					}
				}
				if (coCustMonthsSinceStart >= coCustMonthThresholds[coCustMonthThresholds.length-1]) {
					coCustStartDateSelection = coCustMonthThresholds[coCustMonthThresholds.length-1];
				}
				if(successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate == ""){
					$scope.coCustomerInfo.incomeSources.sourceStartDate = successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate;
				} else {
					$scope.coCustomerInfo.incomeSources.sourceStartDate = coCustStartDateSelection;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceStartDate = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceStartDate = true;
				}

				$scope.coCustomerInfo.incomeSources.takeHomePay = successData.additionalInfo.coCustomerIncomeSource[0].takeHome != null?successData.additionalInfo.coCustomerIncomeSource[0].takeHome : successData.additionalInfo.coCustomerIncomeSource[0].monthlyIncome;
				void 0;
				if(SharedDataSvc.isBlankOrNull($scope.coCustomerInfo.incomeSources.takeHomePay)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.takeHomePay = false; 
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.takeHomePay = true;
				}


				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].nextPayday)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.nextPayday = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.nextPayday = true;
					$scope.coCustomerInfo.incomeSources.nextPayDate.month = successData.additionalInfo.coCustomerIncomeSource[0].nextPayday.substr(4, 2);
					$scope.coCustomerInfo.incomeSources.nextPayDate.day = successData.additionalInfo.coCustomerIncomeSource[0].nextPayday.substr(6, 2);
				}


				if(successData.additionalInfo.isCarDetailsProvided){
					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.autoMakeAndModel)){
						$scope.disablingFlag.coCustomerInfo.auto.makeAndModel = false;
					}else{
						var model = successData.additionalInfo.autoMakeAndModel.split('-');
						$scope.custAdditionalInfo.auto.make = model[0];
						$scope.custAdditionalInfo.auto.model = model[1];
						$scope.disablingFlag.coCustomerInfo.auto.makeAndModel = true;
					}

					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.tag)){
						$scope.disablingFlag.coCustomerInfo.auto.tag = false;
					}else{
						$scope.custAdditionalInfo.auto.tag = successData.additionalInfo.tag;
						$scope.disablingFlag.coCustomerInfo.auto.tag = true;
					}

					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.color)){
						$scope.disablingFlag.coCustomerInfo.auto.color = false;
					}else{
						$scope.custAdditionalInfo.auto.color = successData.additionalInfo.color;
						$scope.disablingFlag.coCustomerInfo.auto.color = true;
					}

					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.carPaymentAmount)){
						$scope.disablingFlag.coCustomerInfo.auto.carPaymentAmount = false;
					}else{
						$scope.custAdditionalInfo.auto.amount = successData.additionalInfo.carPaymentAmount;
						$scope.disablingFlag.coCustomerInfo.auto.carPaymentAmount = true;
					}
				}

			}else{
				$scope.disablingFlag.address.custPaymentAmount = false;
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourceAddress.addrLine1)){
				$scope.disablingFlag.incomeSources.employerAddress = false;
			}else{
				$scope.disablingFlag.incomeSources.employerAddress = true;
				$scope.customerInfo.incomeSources.employerAddress = successData.incomeSources[0].sourceAddress.addrLine1;
			}

			$scope.customerInfo.incomeSources.deptTitle =  successData.incomeSources[0].department !=null ? successData.incomeSources[0].department : successData.incomeSources[0].sourceDepartment;//VAN-2401-PR stores to use SLI- AWP re-engagement
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].department)){
				$scope.disablingFlag.incomeSources.deptTitle = false;
			}else{
				$scope.disablingFlag.incomeSources.deptTitle = true;
				$scope.customerInfo.incomeSources.deptTitle = successData.incomeSources[0].department;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].supervisorName)){
				$scope.disablingFlag.incomeSources.supervisorName = false;
			}else{
				$scope.disablingFlag.incomeSources.supervisorName = true;
				$scope.customerInfo.incomeSources.supervisorName = successData.incomeSources[0].supervisorName;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].workHours)){
				$scope.disablingFlag.incomeSources.workHours = false;
			}else{
				$scope.disablingFlag.incomeSources.workHours = true;
				$scope.customerInfo.incomeSources.workHours = successData.incomeSources[0].workHours;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].bestPayDate)){
				$scope.disablingFlag.incomeSources.bestPayDate = false;
			}else{
				$scope.disablingFlag.incomeSources.bestPayDate = true;
				$scope.customerInfo.incomeSources.bestPayDate = successData.incomeSources[0].bestPayDate;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].nextPayday)){
				$scope.disablingFlag.incomeSources.nextPayday = false;
			}else{
				$scope.disablingFlag.incomeSources.nextPayday = true;
				$scope.customerInfo.incomeSources.nextPayDate.month = successData.incomeSources[0].nextPayday.substr(4, 2);
				$scope.customerInfo.incomeSources.nextPayDate.day = successData.incomeSources[0].nextPayday.substr(6, 2);
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].paydayFrequency)){
				$scope.disablingFlag.incomeSources.payFrequency = false;
			}else{
				$scope.disablingFlag.incomeSources.payFrequency = true;
				$scope.customerInfo.incomeSources.payFrequency = successData.incomeSources[0].paydayFrequency;//VAN-2401-PR stores to use SLI- AWP re-engagement
				
			}

			
			
					
			if(successData.itinFlag) {
				$scope.customerPrivate.ssn.ssnType = 'ITIN';
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.social)){
				$scope.disablingFlag.ssn.ssn1 = false;
			}else{
				$scope.customerPrivate.ssn.ssn1 = successData.social.substring(0,3);
				$scope.disablingFlag.ssn.ssn1 = true;
			}
			
			
			if(SharedDataSvc.isBlankOrNull(successData.social)){
				$scope.disablingFlag.ssn.ssn2 = false;
			}else{
				$scope.customerPrivate.ssn.ssn2 = successData.social.substring(3,5);
				$scope.disablingFlag.ssn.ssn2 = true;
			}
			
			
			if(SharedDataSvc.isBlankOrNull(successData.social)){
				$scope.disablingFlag.ssn.ssn3 = false;
			}else{
				$scope.customerPrivate.ssn.ssn3 = successData.social.substring(5,9);
				$scope.disablingFlag.ssn.ssn3 = true;
			}
			
			$scope.customerInfo.driversLicense.id = successData.driversLicense.id;
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.id)){
				$scope.disablingFlag.driversLicense.id = false;
			}else{
				$scope.disablingFlag.driversLicense.id = true;
			}
			
			$scope.customerInfo.driversLicense.issuer = successData.driversLicense.issuer;
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.issuer)){
				$scope.disablingFlag.driversLicense.issuer = false;
			}else{
				$scope.disablingFlag.driversLicense.issuer = true;
			}
			
			$scope.customerInfo.driversLicense.idType = successData.driversLicense.idType;
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.idType)){
				$scope.disablingFlag.driversLicense.idType = false;
			}else{
				$scope.disablingFlag.driversLicense.idType = true;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.birthDate)){
				$scope.disablingFlag.dob.day = false;
			}else{
				$scope.customerPrivate.dob.day = successData.driversLicense.birthDate.substring(6,8);
				$scope.disablingFlag.dob.day = true;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.birthDate)){
				$scope.disablingFlag.dob.month = false;
			}else{
				$scope.customerPrivate.dob.month = successData.driversLicense.birthDate.substring(4,6);
				$scope.disablingFlag.dob.month = true;
			}
			
			
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.birthDate)){
				$scope.disablingFlag.dob.year = false;
			}else{
				$scope.customerPrivate.dob.year = successData.driversLicense.birthDate.substring(0,4);
				$scope.disablingFlag.dob.year = true;
			}
			
			$scope.customerInfo.phone1.number = successData.phones[0].areaCode+successData.phones[0].number;
			if(SharedDataSvc.isBlankOrNull(successData.phones[0].areaCode+successData.phones[0].number)){
				$scope.disablingFlag.phone1.number = false;
			}else{
				$scope.disablingFlag.phone1.number = true;
			}
			
			/* We need to send phoneType as null always. Whether it is
			 *  case of blank or null otherwise service will break.
			 *  */
			if(SharedDataSvc.isBlankOrNull(successData.phones[0].phoneType)){
				$scope.disablingFlag.phone1.phoneType = false;
				$scope.customerInfo.phone1.phoneType = null;
			}else{
				$scope.disablingFlag.phone1.phoneType = true;
				$scope.customerInfo.phone1.phoneType = successData.phones[0].phoneType;
			}
			
			/*
			if( successData.phones[0].bestCallTime == "M" ){  
							$scope.customerInfo.phone1.bestCallTime.Morning = "Yes";
							$scope.customerInfo.phone1.bestCallTime.Afternoon = "No";
							$scope.customerInfo.phone1.bestCallTime.Evening = "No";
						}else if( successData.phones[0].bestCallTime == "A" ){
							$scope.customerInfo.phone1.bestCallTime.Morning = "No";
							$scope.customerInfo.phone1.bestCallTime.Afternoon = "Yes";
							$scope.customerInfo.phone1.bestCallTime.Evening = "No";
						}else{
							$scope.customerInfo.phone1.bestCallTime.Morning = "No";
							$scope.customerInfo.phone1.bestCallTime.Afternoon = "No";
							$scope.customerInfo.phone1.bestCallTime.Evening = "Yes";
						}*/
			
			if( successData.phones[0].bestCallTime){ 
				var times = successData.phones[0].bestCallTime.split(",");
				void 0;
				for(var x=0; x< times.length; x++){
					times[x] = times[x].trim();
					if(times[x] == "M"){
						$scope.customerInfo.phone1.bestCallTime.Morning = "Yes";
					}else if(times[x] == "A"){
						$scope.customerInfo.phone1.bestCallTime.Afternoon = "Yes";
					}else if(times[x] == "E"){
						$scope.customerInfo.phone1.bestCallTime.Evening = "Yes";
					}else{
						$scope.disablingFlag.phone1.bestCallTime.morning = false;
						$scope.disablingFlag.phone1.bestCallTime.afternoon = false;
						$scope.disablingFlag.phone1.bestCallTime.evening = false;
					}
				}
			} else if(successData.phones[0].bestCallTime == ""){
				$scope.disablingFlag.phone1.bestCallTime.morning = false;
				$scope.disablingFlag.phone1.bestCallTime.afternoon = false;
				$scope.disablingFlag.phone1.bestCallTime.evening = false;
			}
			
			
			$scope.customerInfo.phone2.number = successData.phones[1].areaCode+successData.phones[1].number;
			if(SharedDataSvc.isBlankOrNull(successData.phones[1].areaCode+successData.phones[1].number)){
				$scope.disablingFlag.phone2.number = false; /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.phone2.number = true;
			}
			
			
			/* We need to send phoneType as null always. Whether it is
			 *  case of blank or null otherwise service will break.
			 *  */
			if(SharedDataSvc.isBlankOrNull(successData.phones[1].phoneType)){
				if(successData.phones[0].phoneType === "HOME" && $scope.allowTextVerificationFlag) {
					$scope.customerInfo.phone2.phoneType = "CELL";
					$scope.disablingFlag.phone2.phoneType = false;
				}else {
					$scope.disablingFlag.phone2.phoneType = false; /* Keeping this field always disabled as it is optional. */
					$scope.customerInfo.phone2.phoneType = null;	
				}				
			}else{
				$scope.disablingFlag.phone2.phoneType = true;
				$scope.customerInfo.phone2.phoneType = successData.phones[1].phoneType;
			}
			
			$scope.customerInfo.email = successData.email;
			//Story 30445 -AWP, Kiosk: Add email verification edit box - start
			if($scope.emailValidation.test(successData.email)) {
				$scope.validEmailPattern = true;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.email) || (!$scope.validEmailPattern)){
			//Story 30445 -AWP, Kiosk: Add email verification edit box - end
				$scope.disablingFlag.email = false;
			}else{
				$scope.disablingFlag.email = true;
			}
			
			//Story 30445 -AWP, Kiosk: Add email verification edit box - start
			$scope.customerInfo.emailVerify = successData.email;
			if(SharedDataSvc.isBlankOrNull(successData.email) || (!$scope.validEmailPattern)){
				$scope.disablingFlag.emailVerify = false;
			}else{
				$scope.disablingFlag.emailVerify = true;
			}
			//Story 30445 -AWP, Kiosk: Add email verification edit box - end
			$scope.customerInfo.incomeSources.income = successData.incomeSources[0].incomeType;
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].incomeType)){
				// set default income source if null
				$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
				//$scope.disablingFlag.incomeSources.income = false;
			}else{
				//$scope.disablingFlag.incomeSources.income = true;
			}
			
			if(successData.incomeSources[0].sourceName != '0'){
				$scope.customerInfo.incomeSources.sourceName = successData.incomeSources[0].sourceName;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourceName)){
				$scope.disablingFlag.incomeSources.sourceName = false;
			} else if(successData.incomeSources[0].sourceName == "0"){
				//$scope.disablingFlag.incomeSources.sourceName = false;
			} else {
				//$scope.disablingFlag.incomeSources.sourceName = true;
			}

			//$scope.customerInfo.incomeSources.monthlyIncome = successData.incomeSources[0].monthlyIncome;
			
			if(successData.incomeSources[0].sourcePhone.number !== '0000000'){
				$scope.customerInfo.incomeSources.sourcePhone.number = successData.incomeSources[0].sourcePhone.areaCode + successData.incomeSources[0].sourcePhone.number;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourcePhone.areaCode+successData.incomeSources[0].sourcePhone.number)){
				
				//$scope.disablingFlag.incomeSources.sourcePhone.number = false;
			}else{
				//$scope.disablingFlag.incomeSources.sourcePhone.number = true;
			}
			
			
			
			var startDateSelection;
			var monthThresholds = ['11', '23', '48', '60'];
			var startDate = moment(successData.incomeSources[0].sourceStartDate, 'YYYYMMDD');
			var monthsSinceStart = moment().diff(startDate, 'months');
			for (var i = 0; i < monthThresholds.length; i++) {
				if(monthsSinceStart <= monthThresholds[i] && monthsSinceStart ) {
					startDateSelection = monthThresholds[i];
					break;
				}
			}
			if (monthsSinceStart >= monthThresholds[monthThresholds.length-1]) {
				startDateSelection = monthThresholds[monthThresholds.length-1];
			}
			if(successData.incomeSources[0].sourceStartDate == ""){
				$scope.customerInfo.incomeSources.sourceStartDate = successData.incomeSources[0].sourceStartDate;
			} else {
				$scope.customerInfo.incomeSources.sourceStartDate = startDateSelection;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourceStartDate)){
				//$scope.disablingFlag.incomeSources.sourceStartDate = false;
			}else{
				//$scope.disablingFlag.incomeSources.sourceStartDate = true;
			}
			
			
			
			$scope.customerInfo.incomeSources.monthlyIncome = successData.incomeSources[0].monthlyIncome;
			void 0;
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].monthlyIncome)){
				$scope.disablingFlag.incomeSources.monthlyIncome = true; /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.incomeSources.monthlyIncome = true;
			}
			
			//AC23127  code changes starts
			if (successData.personalReferences && successData.personalReferences.length > 0) {
				for (var i = 0; i < successData.personalReferences.length; i++) {										
					if ($scope.refType.indexOf(successData.personalReferences[i].personalReferenceType) < 0) {
						successData.personalReferences[i].personalReferenceType = "";
					}					
			}
			}
			//AC23127 code changes ends	
			
			
			/* Reference 1  */
			$scope.ref1.firstName = successData.personalReferences[0].firstName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].firstName)){
				$scope.disablingFlag.ref1.firstName = false;
			}else{
				$scope.disablingFlag.ref1.firstName = true;
			}
			
			$scope.ref1.lastName = successData.personalReferences[0].lastName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].lastName)){
				$scope.disablingFlag.ref1.lastName = false;
			}else{
				$scope.disablingFlag.ref1.lastName = true;
			}
			
			$scope.ref1.number = successData.personalReferences[0].phone.areaCode+successData.personalReferences[0].phone.number;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].phone.areaCode+successData.personalReferences[0].phone.number)){
				$scope.disablingFlag.ref1.number = false;
			}else{
				$scope.disablingFlag.ref1.number = true;
			}
			
			$scope.ref1.personalReferenceType = successData.personalReferences[0].personalReferenceType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].personalReferenceType)){
				$scope.disablingFlag.ref1.personalReferenceType = false;
			}else{
				$scope.disablingFlag.ref1.personalReferenceType = true;
			}

			$scope.ref1.address = successData.personalReferences[0].address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].address[0].addrLine1)){
				$scope.disablingFlag.ref1.address = false;
			}else{
				$scope.disablingFlag.ref1.address = true;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement
			$scope.ref1.phoneType = successData.personalReferences[0].phone.phoneType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].phone.phoneType)){
				$scope.disablingFlag.ref1.numberType = false;
			}else{
				$scope.disablingFlag.ref1.numberType = true;
			}

			/* Reference 2 */
			$scope.ref2.firstName = successData.personalReferences[1].firstName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].firstName)){
				$scope.disablingFlag.ref2.firstName = false;
			}else{
				$scope.disablingFlag.ref2.firstName = true;
			}
			
			$scope.ref2.lastName = successData.personalReferences[1].lastName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].lastName)){
				$scope.disablingFlag.ref2.lastName = false;
			}else{
				$scope.disablingFlag.ref2.lastName = true;
			}
			
			$scope.ref2.number = successData.personalReferences[1].phone.areaCode+successData.personalReferences[1].phone.number;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].phone.areaCode+successData.personalReferences[1].phone.number)){
				$scope.disablingFlag.ref2.number = false;
			}else{
				$scope.disablingFlag.ref2.number = true;
			}
			
			$scope.ref2.personalReferenceType = successData.personalReferences[1].personalReferenceType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].personalReferenceType)){
				$scope.disablingFlag.ref2.personalReferenceType = false;
			}else{
				$scope.disablingFlag.ref2.personalReferenceType = true;
			}

			$scope.ref2.address = successData.personalReferences[1].address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].address[0].addrLine1)){
				$scope.disablingFlag.ref2.address = false;
			}else{
				$scope.disablingFlag.ref2.address = true;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement
			$scope.ref2.phoneType = successData.personalReferences[1].phone.phoneType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].numberType)){
				$scope.disablingFlag.ref2.numberType = false;
			}else{
				$scope.disablingFlag.ref2.numberType = true;
			}
			

			/*  Reference 3  */
			
			$scope.ref3.firstName = successData.personalReferences[2].firstName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].firstName)){
				$scope.disablingFlag.ref3.firstName = false;
			}else{
				$scope.disablingFlag.ref3.firstName = true;
			}
			
			$scope.ref3.lastName = successData.personalReferences[2].lastName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].lastName)){
				$scope.disablingFlag.ref3.lastName = false;
			}else{
				$scope.disablingFlag.ref3.lastName = true;
			}
			
			$scope.ref3.number = successData.personalReferences[2].phone.areaCode+successData.personalReferences[2].phone.number;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].phone.areaCode+successData.personalReferences[2].phone.number)){
				$scope.disablingFlag.ref3.number = false;
			}else{
				$scope.disablingFlag.ref3.number = true;
			}
			
			$scope.ref3.personalReferenceType = successData.personalReferences[2].personalReferenceType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].personalReferenceType)){
				$scope.disablingFlag.ref3.personalReferenceType = false;
			}else{
				$scope.disablingFlag.ref3.personalReferenceType = true;
			}

			$scope.ref3.address = successData.personalReferences[2].address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].address[0].addrLine1)){
				$scope.disablingFlag.ref3.address = false;
			}else{
				$scope.disablingFlag.ref3.address = true;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement
			$scope.ref3.phoneType = successData.personalReferences[2].phone.phoneType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].phone.phoneType)){
				$scope.disablingFlag.ref3.numberType = false;
			}else{
				$scope.disablingFlag.ref3.numberType = true;
			}


			/*  Reference 4  */
			if($scope.isSpanishStore) {
				$scope.ref4.firstName = successData.personalReferences[3].firstName;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].firstName)){
					$scope.disablingFlag.ref4.firstName = false;
				}else{
					$scope.disablingFlag.ref4.firstName = true;
				}
				
				$scope.ref4.lastName = successData.personalReferences[3].lastName;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].lastName)){
					$scope.disablingFlag.ref4.lastName = false;
				}else{
					$scope.disablingFlag.ref4.lastName = true;
				}
				
				$scope.ref4.number = successData.personalReferences[3].phone.areaCode+successData.personalReferences[3].phone.number;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].phone.areaCode+successData.personalReferences[3].phone.number)){
					$scope.disablingFlag.ref4.number = false;
				}else{
					$scope.disablingFlag.ref4.number = true;
				}
				
				$scope.ref4.personalReferenceType = successData.personalReferences[3].personalReferenceType;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].personalReferenceType)){
					$scope.disablingFlag.ref4.personalReferenceType = false;
				}else{
					$scope.disablingFlag.ref4.personalReferenceType = true;
				}

				$scope.ref4.address = successData.personalReferences[3].address[0].addrLine1;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].address[0].addrLine1)){
					$scope.disablingFlag.ref4.address = false;
				}else{
					$scope.disablingFlag.ref4.address = true;
				}
				
				//VAN-2401-PR stores to use SLI- AWP re-engagement
				$scope.ref4.phoneType = successData.personalReferences[3].phone.phoneType;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].phone.phoneType)){
					$scope.disablingFlag.ref4.numberType = false;
				}else{
					$scope.disablingFlag.ref4.numberType = true;
				}	
			}

			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			//CoCustomer Info validation
			if($scope.isSpanishStore) {
				$scope.coCustomerInfo.firstName = successData.coCustomerInfo.firstName;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.firstName)){
					$scope.disablingFlag.coCustomerInfo.firstName = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.firstName = true;
				}

				$scope.coCustomerInfo.lastName =  successData.coCustomerInfo.lastName;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.lastName)){
					$scope.disablingFlag.coCustomerInfo.lastName = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.lastName = true;
				}

				$scope.coCustomerInfo.middleName = successData.coCustomerInfo.middleInitial;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.middleInitial)){
					$scope.disablingFlag.coCustomerInfo.middleName = false;  
				}else{
					$scope.disablingFlag.coCustomerInfo.middleName = true;
				}

				$scope.coCustomerInfo.relationshipType = successData.coCustomerInfo.relationshipType;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.relationshipType)){
					$scope.disablingFlag.coCustomerInfo.relationshipType = false;  
				}else{
					$scope.disablingFlag.coCustomerInfo.relationshipType = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.social)){
					$scope.disablingFlag.coCustomerInfo.ssn.ssn1 = false;
				}else{
					$scope.coCustomerPrivate.ssn.ssn1 = successData.coCustomerInfo.social.substring(0,3);
//					$scope.disablingFlag.coCustomerInfo.ssn.ssn1 = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.social)){
					$scope.disablingFlag.coCustomerInfo.ssn.ssn2 = false;
				}else{
					$scope.coCustomerPrivate.ssn.ssn2 = successData.coCustomerInfo.social.substring(3,5);
//					$scope.disablingFlag.coCustomerInfo.ssn.ssn2 = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.social)){
					$scope.disablingFlag.coCustomerInfo.ssn.ssn3 = false;
				}else{
					$scope.coCustomerPrivate.ssn.ssn3 = successData.coCustomerInfo.social.substring(5,9);
//					$scope.disablingFlag.coCustomerInfo.ssn.ssn3 = true;
				}

				$scope.coCustomerInfo.phone1.number = successData.coCustomerInfo.phoneNumber;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.phoneNumber)){
					$scope.disablingFlag.coCustomerInfo.phone1.number = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.phone1.number = true;
				}


				$scope.coCustomerInfo.phone1.phoneType = successData.coCustomerInfo.phoneType;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.phoneType)){
					$scope.disablingFlag.coCustomerInfo.phone1.phoneType  = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.phone1.phoneType  = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.birthDate)){
					$scope.disablingFlag.coCustomerInfo.dob.day = false;
				}else{
					$scope.coCustomerPrivate.dob.day = successData.coCustomerInfo.birthDate.substring(6,8);
					$scope.disablingFlag.coCustomerInfo.dob.day = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.birthDate)){
					$scope.disablingFlag.coCustomerInfo.dob.month = false;
				}else{
					$scope.coCustomerPrivate.dob.month = successData.coCustomerInfo.birthDate.substring(4,6);
					$scope.disablingFlag.coCustomerInfo.dob.month = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.birthDate)){
					$scope.disablingFlag.coCustomerInfo.dob.year = false;
				}else{
					$scope.coCustomerPrivate.dob.year = successData.coCustomerInfo.birthDate.substring(0,4);
					$scope.disablingFlag.coCustomerInfo.dob.year = true;
				}
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
			//Defect 28266 - VAN: References getting wiped off in HT - Starts
			void 0;
			if(successData.VanEngagement && successData.VanEngagement.customer.customerOriginator && 
					(successData.VanEngagement.customer.customerOriginator == "SLI" || successData.VanEngagement.customer.customerOriginator == "HighTouch") //VAN-813 New originator for HighTouch for HT originated approvals 
					&&  $scope.isReferenceRequired)
						{
					$scope.disablingFlag.email = false;
					$scope.disablingFlag.emailVerify = false;
					$scope.disablingFlag.incomeSources.income = false;
					$scope.disablingFlag.incomeSources.sourceName = false;
					$scope.disablingFlag.incomeSources.sourcePhone.number = false;
					$scope.disablingFlag.incomeSources.sourceStartDate = false;
					$scope.disablingFlag.incomeSources.monthlyIncome = false;
					$scope.disablingFlag.ref1.firstName = false;
					$scope.disablingFlag.ref1.lastName = false;
					$scope.disablingFlag.ref1.number = false;
					$scope.disablingFlag.ref1.personalReferenceType = false;
					$scope.disablingFlag.ref2.firstName = false;
					$scope.disablingFlag.ref2.lastName = false;
					$scope.disablingFlag.ref2.number = false;
					$scope.disablingFlag.ref2.personalReferenceType = false;
					$scope.disablingFlag.ref3.firstName = false;
					$scope.disablingFlag.ref3.lastName = false;
					$scope.disablingFlag.ref3.number = false;
					$scope.disablingFlag.ref3.personalReferenceType = false;
					//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
					$scope.disablingFlag.ref4.firstName = false;
					$scope.disablingFlag.ref4.lastName = false;
					$scope.disablingFlag.ref4.number = false;
					$scope.disablingFlag.ref4.personalReferenceType = false;
					$scope.disablingFlag.ref4.address = false;
					$scope.disablingFlag.ref4.numberType = false;
					$scope.disablingFlag.ref3.numberType = false;
					$scope.disablingFlag.ref2.numberType = false;
					$scope.disablingFlag.ref1.numberType = false;
					$scope.disablingFlag.ref3.address = false;
					$scope.disablingFlag.ref2.address = false;
					$scope.disablingFlag.ref1.address = false;
					//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
							}
			//Defect 28266 - VAN: References getting wiped off in HT - Ends
	
			$scope.$watch('customerPrivate.dob.month', generateDays);
			//$scope.$watch('customerPrivate.dob.year', addYears);
		}
		
		
		function generateDays(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.days = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.days.push(day);
				day++;
			}
		}

		function setPreviousAdd(){
			if($scope.custAdditionalInfo.moveInDate.month && $scope.custAdditionalInfo.moveInDate.year){
				var currentTime = new Date();
	        	var enteredDay = currentTime.getDate();
	        	var enteredMonth= $scope.custAdditionalInfo.moveInDate.month;
	        	var enteredYear = $scope.custAdditionalInfo.moveInDate.year;

				var enteredDateFormat = enteredYear+'/'+enteredMonth+'/'+enteredDay;
				var enDate = new Date(enteredDateFormat);


				var months;
	    		months = (currentTime.getFullYear() - enDate.getFullYear()) * 12;
	    		months -= enDate.getMonth() + 1;
	    		months += currentTime.getMonth();
	    		if(months > 12 ){
	    			$scope.showPreviousAdd = false;
	    		}else{
	    			$scope.showPreviousAdd = true;
	    		}
			}
		}
		
		function addYears() {
			for (var i = 18; i < 100; i++) {
				$scope.birthYears.push(moment().year() - i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.leaseYears.push(moment().year() + i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.moveInYears.push(moment().year() - i);
			};
		}

		function getFullName(first, last) {
			if(first && last) {
				return first.toLowerCase() + ' ' + last.toLowerCase();
			}
			return undefined;
		}

		function getReferenceErrors(index) {
			return ['ref' + index + 'FirstName', 'ref' + index + 'LastName'];
		}

		function referenceNameSameAsCustomer(refName) {
			var custName = $scope.customerInfo.firstName + ' ' + $scope.customerInfo.lastName;
			if(refName && custName) {
				return refName.toLowerCase() === custName.toLowerCase() ? true : false;
			}
		}

		function referencePhoneSameAsCustomer(refPhone) {
			var custPhone = $scope.customerInfo.phone1.number;
			if(refPhone) {
				return refPhone === custPhone ? true : false;
			}
		}

		function clearSSN(){
			$scope.customerPrivate.ssn.ssn1 = '';
			$scope.customerPrivate.ssn.ssn2 = '';
			$scope.customerPrivate.ssn.ssn3 = '';
		}

		function validateReferenceName(index) {
			$scope.ref = [{
				first: $scope.customerInfoForm.newRefs.ref1FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref1LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref1FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref1LastName.$modelValue)
			}, 
			{
				first: $scope.customerInfoForm.newRefs.ref2FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref2LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref2FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref2LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref3FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref3LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref3FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref4FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref4LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref4FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
			}];

			var currentRef = _.at($scope.ref, index);
			var fullName = getFullName($scope.customerInfoForm.newRefs['ref' + index + 'FirstName'].$modelValue, $scope.customerInfoForm.newRefs['ref' + index + 'LastName'].$modelValue);
			var matches = _.pluck(_.where($scope.ref, {'full': fullName}), 'full');
			var errorProp = 'ref'+index+'NameSubmitted';
			var propNames = getReferenceErrors(index);
			var first = propNames[0];
			var last = propNames[1];
			
			matches = _.filter(matches, function(item){
				if(!_.isUndefined(item)) return item;
			});

			var propFirstName = 'ref' + index + 'FirstName';
			var propLastName = 'ref' + index + 'LastName';

			if(matches.length > 1 || referenceNameSameAsCustomer(fullName)) {
				$scope.newRefs[first].$setValidity(first, false);
				$scope.newRefs[last].$setValidity(last, false);
				$scope[errorProp] = true;
			} else {
				$scope.newRefs[first].$setValidity(first, true);
				$scope.newRefs[last].$setValidity(last, true);
				$scope[errorProp] = false;
			}
		}

		// secondary phone number duplicate check
		function validateSecondaryPhone(index) {
			$scope.phoneNumbers = [
													{ 'number' : $scope.customerInfoForm.phoneSecondary.$modelValue},
													{ 'number' : $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref3Number.$modelValue }
													
												 ];		


			var currentNum = $scope.phoneNumbers[index-1].number;
			var matches = _.pluck(_.where($scope.phoneNumbers, { 'number' : currentNum }), 'number');
			var errorProp = 'phone' + index + 'NumberSubmitted';

			if(currentNum !== undefined) {
				if(currentNum.length === 10){
					$scope.secondaryNoTypeRequired = true;
				} else {
					$scope.secondaryNoTypeRequired = false;
				}			
			} 

			matches = _.filter(matches, function(item) {
				if(!_.isUndefined(item)) return item;
			});

			void 0;

			if(matches.length > 1 || referencePhoneSameAsCustomer(currentNum)) {
				$scope[errorProp] = true;
			} else {
				$scope[errorProp] = false;
			}
		}

		function validateReferencePhone(index) {
			$scope.refPhones = [
													{ 'number' : $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref3Number.$modelValue },
													{ 'number' : ($scope.customerInfoForm.newRefs.ref4Number ? $scope.customerInfoForm.newRefs.ref4Number.$modelValue : '')}
												 ];

			var currentRef = $scope.refPhones[index-1].number;
			var propName = 'ref' + index + 'Number';
			var matches = _.pluck(_.where($scope.refPhones, { 'number' : currentRef }), 'number');
			var errorProp = 'ref' + index + 'NumberSubmitted';

			matches = _.filter(matches, function(item) {
				if(!_.isUndefined(item)) return item;
			});

			void 0;
			void 0;
			if(matches.length > 1 || referencePhoneSameAsCustomer(currentRef)) {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, false);
				$scope[errorProp] = true;
			} else {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, true);
				$scope[errorProp] = false;
			}
		}

		function validateDOB(dateOfBirth) {
			void 0;
			if (dateOfBirth.year && dateOfBirth.month && dateOfBirth.day) {
				var isValid;
				var isDateValidOnLeapYear; // VAN-2842 Changes
				if ((dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month > $scope.dobvalid.month) || (dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month == $scope.dobvalid.month && dateOfBirth.day > $scope.dobvalid.day)) {
					isValid = false;
				} else {
					isValid = true;
				}
				// VAN-2842 Changes Starts
				if (moment(dateOfBirth.month+"/"+dateOfBirth.day+"/"+dateOfBirth.year+"",'MM/DD/YYYY', true).isValid()){
					isDateValidOnLeapYear = true;
				} else {
					isDateValidOnLeapYear = false;
				}
				$scope.customerInfoForm.$setValidity('isDateValidOnLeapYear',isDateValidOnLeapYear );
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				// VAN-2842 Changes Ends
				$scope.customerInfoForm.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('validAge', isValid);
			}
		}

		function zipValidation(zip) {
			var pattern = /^([0-9]{5})$/;//Defect 26474 code change
			if(pattern.test(zip)) {
				isZipInStoreRadius(zip);
			} else {
				void 0;
				$scope.isZipValid = false;
				void 0;
				$scope.customerInfoForm.zip.$setValidity('zip', false);
			}
		}

		function resetZipData() {
			$scope.customerInRadius = true;
			$scope.isZipValid = true;
			$scope.customerInfoForm.zip.$setValidity('zip', true);
		}

		function zipSubmitFalse(event) {
			if(event.keyCode === 13) {
				event.preventDefault();
				return false;
			}
		}

		function isZipInStoreRadius(zip, getFn) {
			getFn = getFn || function(){};
			getStoreInfoFactory.getLatLngFromZip(zip)
					.then(function(results) {
						void 0;
			// Google API Implementation - Starts
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
									distance = getStoreInfoFactory.getCustomerDistance($scope.storeLatLng, customer),
									isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius($scope.storeInfo.allowedRentalRadiusMiles, distance),
									isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

							void 0;
							void 0;
							void 0;
							$scope.customerDistance = Math.round(distance);

							if(isCustomerInUS && isInAllowedRadius) {
								$scope.customerInRadius = true;
								$scope.customerInfoForm.zip.$setValidity('zip', true);
								getFn("true");
							} else {
								$scope.customerInRadius = false;
								$scope.customerInfoForm.zip.$setValidity('zip', false);
								//AC23079 code change starts
								$scope.disablingFlag.address.addrLine1 = false;
								$scope.disablingFlag.address.addrLine2 = false;
								$scope.disablingFlag.address.city = false;
								$scope.disablingFlag.address.state = false;
								$scope.disablingFlag.address.zipCode = false;
								//AC23079 code change ends
								getFn("false");
							}

						} else {
							void 0;
							$scope.isZipValid = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
							//Defect 26474 code changes starts
							$scope.disablingFlag.address.addrLine1 = false;
							$scope.disablingFlag.address.addrLine2 = false;
							$scope.disablingFlag.address.city = false;
							$scope.disablingFlag.address.state = false;
							$scope.disablingFlag.address.zipCode = false;
							//Defect 26474 code changes ends
							getFn("invalidZip");
						}
					}).
			catch(function(response){
				// sending error to Raygun
				SharedDataSvc.reportRaygun(response);
			});
		}

		function checkState(state) {
			// console.log('test', state);
			var unsupportedStates = $scope.stateRestrictions.unsupportedStates.split(",") || '';
			if (unsupportedStates.indexOf(state) > -1) {
				$scope.invalidState = modalService.open({
					title: 'State Not Supported',
					templateUrl: 'partials/alert_stateUnsupported.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
		}
		
		//Story 30445 -AWP, Kiosk: Add email verification edit box - start		
		function compareEmailVerify(event,newvalue) {
			if($scope.customerInfoForm.emailAddressVerify.$invalid) {
				$scope.verifyEmail = true;
				$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
			}
			if($scope.customerInfoForm.emailAddress.$valid && $scope.customerInfoForm.emailAddressVerify.$valid) {
				if($scope.customerInfo.email) {
					if(newvalue.toUpperCase() === $scope.customerInfo.email.toUpperCase()){
						$scope.verifyEmail = true;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
					} else {
						$scope.verifyEmail = false;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', false);
					}
				}
			} 			
		}
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		}
		
		function cancelCustomerEntry() {
			var cancelCustSSNModal;
			// open modal only once
			cancelCustSSNModal = modalService.open({
				title: 'Existing Customer Verification',
				templateUrl: 'partials/confirm_discardAppChanges.tpl.html',
				backdrop: 'static',
				scope: $scope
			});
		}
		
		function saveAndProcess() {
			$scope.formSubmitted = true;
			$scope.draftSaved = false;
			
			$scope.customerInfoForm.firstName.$pristine = false;
			$scope.customerInfoForm.lastName.$pristine = false;
			
			$scope.customerInfoForm.newRefs.ref1FirstName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref1LastName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref1address.$pristine = false;//VAN-2401-PR stores to use SLI- AWP re-engagement
			
			$scope.customerInfoForm.newRefs.ref2FirstName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref2LastName.$pristine = false;
			
			$scope.customerInfoForm.newRefs.ref3FirstName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref3LastName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref3address.$pristine = false;//VAN-2401-PR stores to use SLI- AWP re-engagement
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			if($scope.isSpanishStore){
				$scope.customerInfoForm.newRefs.ref4FirstName.$pristine = false;
				$scope.customerInfoForm.newRefs.ref4LastName.$pristine = false;
				$scope.customerInfoForm.newRefs.ref4address.$pristine = false;}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
			compareEmailVerify(event,$scope.customerInfo.emailVerify);//Story 30445 -AWP, Kiosk: Add email verification edit box
			
			
			var zipCode = $scope.customerInfo.address.zipCode?$scope.customerInfo.address.zipCode.substring(0,5):"";//Defect 26474 code changes
			if(!$scope.isSpanishStore) {
				isZipInStoreRadius(zipCode, function(status) {
					void 0;
					if(status === "true") {
						$scope.customerInRadius = true;
						$scope.isZipValid = true;
						saveAndProcessAppData();
					} else{
						if(status === "false"){
							$scope.customerInRadius = false;
							$scope.isZipValid = true;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						}else {
							$scope.customerInRadius = true;
							$scope.isZipValid = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						}
					}
				});
			} else {
				saveAndProcessAppData();
			}
			
		}
		
		function saveAndProcessAppData() {
			if ($scope.customerInfoForm.$valid) {
				saveCustomerInfo().then( function(success){
					//Story 33452 changes starts
					if($rootScope.isSLICustomer){
						navigateTo('ordersummary/' + SharedDataSvc.globalVars.currentSLICustEngId);
					} 
					//Defect 28266 - VAN: References getting wiped off in HT - Starts
					else if($rootScope.createOrderFlow && $rootScope.VANEngagementId!=undefined){
						navigateTo('ordersummary/' + $rootScope.VANEngagementId);
						
					} 
					//Defect 28266 - VAN: References getting wiped off in HT - Ends
					else {
						$location.path('dashboard');
					}
					//Story 33452 changes ends
				}, function(error){
					modalService.dismiss();
					void 0;
				});
			} else {
				void 0;
				var error = $scope.customerInfoForm.$error;
				var invalidFields = [];
				angular.forEach(error.required, function(field) {
					if (field.$invalid) {
						var fieldName = field.$name;
						invalidFields.push(fieldName);
					}
				});
				$scope.scrollToTop("body"); // Added for scroll up VAN-10736
				void 0;
				void 0;

			}
		}
		
		function saveCustomerInfo() {
			var deferred = $q.defer();

			modalService.processing('Saving Application');

			var employerAreaCode = '000',
				employerPhone = '0000000',
				monthlyIncome = '',
				incomeSourceName = '',
				bestCallTime = '',
				customerId = SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID || SharedDataSvc.globalVars.order.currentEngagement.customerId,
				engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || $routeParams.engagementId;
				var currYear = (new Date()).getFullYear();

			var mortgageName,mortgagePhone,avgUtilities,leaseEnd, leaseTypeRent,apartmentComplex,custPayment,homeType;

			SharedDataSvc.customerInfo = $scope.customerInfo;
			SharedDataSvc.customerPrivate = $scope.customerPrivate;

			if (typeof $scope.customerInfo.incomeSources.sourcePhone !== "undefined") {
				employerAreaCode = $scope.customerInfo.incomeSources.sourcePhone.number.substr(0, 3);
				employerPhone = $scope.customerInfo.incomeSources.sourcePhone.number.substr(3, 10);
			}
			if ($scope.customerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.customerInfo.incomeSources.nextPayDate))) {
				var nextPayDate = currYear +  $scope.customerInfo.incomeSources.nextPayDate.month + $scope.customerInfo.incomeSources.nextPayDate.day;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			if ($scope.coCustomerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.coCustomerInfo.incomeSources.nextPayDate))) {
				var coCustNextPayDate = currYear +  $scope.coCustomerInfo.incomeSources.nextPayDate.month + $scope.coCustomerInfo.incomeSources.nextPayDate.day;
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
			if ($scope.customerInfo.incomeSources.income) {
				switch ($scope.customerInfo.incomeSources.income) {
					case 'EMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					case 'SELFEMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					default:
						incomeSourceName = $scope.customerInfo.incomeSources.income;
				}
			}

			if ($scope.customerInfo.incomeSources.monthlyIncome) {
				monthlyIncome = $scope.customerInfo.incomeSources.monthlyIncome.replace('$', '');
			} 

			if (_($scope.customerInfo.phone1).has('bestCallTime')) {
				if ($scope.customerInfo.phone1.bestCallTime.Morning === 'Yes') {
					bestCallTime += 'M';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Afternoon === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'A';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Evening === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'E';
				}
			}

			if($scope.customerInfo.address){
				if($scope.customerInfo.address.homeType === 'RENT'){
					leaseTypeRent = $scope.custAdditionalInfo.rent.leaseTypeRent;
					apartmentComplex = $scope.custAdditionalInfo.rent.apmtComplex;
				}

				if($scope.customerInfo.address.homeType === 'RENT' || $scope.customerInfo.address.homeType === 'OWN'){
					mortgageName = $scope.custAdditionalInfo.ownRentInfo.mortgageCompany;
					mortgagePhone = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNum;
					avgUtilities = $scope.custAdditionalInfo.ownRentInfo.avgUtilities;
					custPayment = $scope.custAdditionalInfo.ownRentInfo.custPaymentAmount;
				}
			}

				var ccMoveInDate =  $scope.custAdditionalInfo.moveInDate.year + $scope.custAdditionalInfo.moveInDate.month + "01" ;

					if($scope.custAdditionalInfo.leaseEndDate.month && $scope.custAdditionalInfo.leaseEndDate.year){
						leaseEnd =  $scope.custAdditionalInfo.leaseEndDate.year + $scope.custAdditionalInfo.leaseEndDate.month + "01";
					}

			var incType = $scope.customerInfo.incomeSources.income;

			var mData = {
				lastName: $scope.customerInfo.lastName,
				firstName: $scope.customerInfo.firstName,
				middleInitial: $scope.customerInfo.middleName,
				customerId: customerId,
				engagementId: engagementId,
				email: $scope.customerInfo.email,
				orderOriginator: "AWP",
				referer: "",
				primaryPhoneNumber: $scope.customerInfo.phone1.number,
				phones: [{
					areaCode: $scope.customerInfo.phone1.number.substr(0, 3),
					number: $scope.customerInfo.phone1.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone1.phoneType,
					bestCallTime: bestCallTime,
					contactName: '',
					phoneSet: "CUSTPHN1"
				}],
				languagePreference: "ENGLISH",
				address: [{
					addrLine1: $scope.customerInfo.address.addrLine1,
					//Changes added for AC13229
					addrLine2: "",
					unit: $scope.customerInfo.address.addrLine2,
					city: $scope.customerInfo.address.city,
					state: $scope.customerInfo.address.state,
					zipCode: $scope.customerInfo.address.zipCode
				}],
				driversLicense: {
					id: $scope.customerInfo.driversLicense.id,
					issuer: $scope.customerInfo.driversLicense !== undefined && ($scope.customerInfo.driversLicense.idType == 1 || $scope.customerInfo.driversLicense.idType == 2) ? $scope.customerInfo.driversLicense.issuer : "",
					idType: $scope.customerInfo.driversLicense.idType
				},
				incomeSources: [{
					incomeSourceSet: "INCSRC1",
					sourcePhone: {
						phoneType: 'HOME',
						areaCode: employerAreaCode,
						number: employerPhone,
						extension: '',
						bestCallTime: 'xxx'
					},
					sourceStartDate: '',
					incomeType : incType,
					sourceName: incomeSourceName,
					monthlyIncome: monthlyIncome,
					paydayFrequency : $scope.customerInfo.incomeSources.payFrequency,
					department : $scope.customerInfo.incomeSources.deptTitle !==undefined ? $scope.customerInfo.incomeSources.deptTitle : "",
					workHours : $scope.customerInfo.incomeSources.workHours !== undefined ? $scope.customerInfo.incomeSources.workHours: "",
					supervisorName :$scope.customerInfo.incomeSources.supervisorName !== undefined ? $scope.customerInfo.incomeSources.supervisorName: "",
					bestPayDate : $scope.customerInfo.incomeSources.bestPayDate,
					nextPayDate : nextPayDate !== undefined ? nextPayDate : "",
					sourceDepartment : $scope.customerInfo.incomeSources.deptTitle !==undefined ? $scope.customerInfo.incomeSources.deptTitle : ""//VAN-2401-PR stores to use SLI- AWP re-engagement 
				}]
			};

			if($scope.isSpanishStore){
				mData.address[0].leaseType =  leaseTypeRent !== undefined ? leaseTypeRent : "" ;
				mData.address[0].landLordName =  mortgageName !== undefined ? mortgageName : "";
				mData.address[0].landLordNumber =  mortgagePhone !== undefined ? mortgagePhone : "";
				mData.address[0].apartmentComplex =  apartmentComplex !== undefined ? apartmentComplex :"";
				mData.address[0].moveInDate =  ccMoveInDate;
				mData.address[0].leaseEndDate =  leaseEnd !== undefined ? leaseEnd : "";
				mData.address[0].isRenewingLease =  $scope.customerInfo.address.homeType !== undefined ? $scope.customerInfo.address.homeType: "";

				
				//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				if ($scope.coCustomerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.coCustomerPrivate.ssn))) {
					var SSN = $scope.coCustomerPrivate.ssn;
					if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4) {
						$scope.coCustSocial = $scope.coCustomerPrivate.ssn.ssn1 + $scope.coCustomerPrivate.ssn.ssn2 + $scope.coCustomerPrivate.ssn.ssn3;
					}
				}
				$scope.makeAndModel = $scope.custAdditionalInfo.auto.make + "-" + $scope.custAdditionalInfo.auto.model;
				//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
				mData.additionalInfo = {
					previousAddress : [{
						addrLine1 : $scope.custAdditionalInfo.previousAddress.addrLine1 !== undefined ? $scope.custAdditionalInfo.previousAddress.addrLine1 : "",
						landLordName : $scope.custAdditionalInfo.previousAddress.mortgageName !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgageName : "",
						landLordNumber : $scope.custAdditionalInfo.previousAddress.mortgagePhone !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgagePhone : ""
					}],
					//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
					autoMakeAndModel :  $scope.makeAndModel !== undefined ? $scope.makeAndModel :"",
					tag : $scope.custAdditionalInfo.auto.tag !== undefined ? $scope.custAdditionalInfo.auto.tag : "",
					color : $scope.custAdditionalInfo.auto.color !== undefined ? $scope.custAdditionalInfo.auto.color : "",
					carPaymentAmount : $scope.custAdditionalInfo.auto.amount !== undefined ? $scope.custAdditionalInfo.auto.amount : "",
					averageMonthlyUtilities : avgUtilities !== undefined ? avgUtilities :"",
					isCarDetailsProvided: !$scope.custAdditionalInfo.auto.noCar,
					customerPaymentAmount : custPayment !== undefined ? custPayment : "",
									
					coCustomerIncomeSource : [{
						sourceName : $scope.coCustomerInfo.incomeSources.sourceName  !== undefined ? $scope.coCustomerInfo.incomeSources.sourceName  : "",
						department : $scope.coCustomerInfo.incomeSources.deptTitle  !== undefined ? $scope.coCustomerInfo.incomeSources.deptTitle  : "",
						supervisorName : $scope.coCustomerInfo.incomeSources.supervisorName !== undefined ? $scope.coCustomerInfo.incomeSources.supervisorName  : "",
						workHours : $scope.coCustomerInfo.incomeSources.workHours  !== undefined ? $scope.coCustomerInfo.incomeSources.workHours : "",
						bestPayDate : $scope.coCustomerInfo.incomeSources.bestPayDate  !== undefined ? $scope.coCustomerInfo.incomeSources.bestPayDate  : "",
						paydayFrequency : $scope.coCustomerInfo.incomeSources.payFrequency  !== undefined ? $scope.coCustomerInfo.incomeSources.payFrequency  : "",	
						sourceStartDate : $scope.coCustomerInfo.incomeSources.sourceStartDate  !== undefined ? $scope.coCustomerInfo.incomeSources.sourceStartDate  : "",
						sourcePhone : {
							areACode: $scope.coCustomerInfo.incomeSources.sourcePhone.number !== undefined ? $scope.coCustomerInfo.incomeSources.sourcePhone.number.substring(0,2) : "",
							number: $scope.coCustomerInfo.incomeSources.sourcePhone.number !== undefined ? $scope.coCustomerInfo.incomeSources.sourcePhone.number.substring(3,9)  : "",
							phoneType: $scope.coCustomerInfo.phone1.phoneType !== undefined ? $scope.coCustomerInfo.phone1.phoneType : "HOME"
						},
						sourceDepartment : $scope.coCustomerInfo.incomeSources.deptTitle  !== undefined ? $scope.coCustomerInfo.incomeSources.deptTitle  : "",
						nextPayday : coCustNextPayDate  !== undefined ? coCustNextPayDate : "",
						sourceAddress : {
							addrLine1: $scope.coCustomerInfo.incomeSources.employerAddress  !== undefined ? $scope.coCustomerInfo.incomeSources.employerAddress  : ""
						},
						monthlyIncome : $scope.coCustomerInfo.incomeSources.takeHomePay !== undefined ? $scope.coCustomerInfo.incomeSources.takeHomePay  : "",
						incomeType : $scope.coCustomerInfo.incomeSources.income  !== undefined ? $scope.coCustomerInfo.incomeSources.income  : "",
						incomeSourceSet : $scope.coCustomerInfo.incomeSources.incomeSourceSet  !== undefined ? $scope.coCustomerInfo.incomeSources.incomeSourceSet  : "",
						takeHome : $scope.coCustomerInfo.incomeSources.takeHomePay  !== undefined ? $scope.coCustomerInfo.incomeSources.takeHomePay  : "",
						lengthOfEmployment : $scope.coCustomerInfo.incomeSources.sourceStartDate  !== undefined ? $scope.coCustomerInfo.incomeSources.sourceStartDate  : "",
						coCustomerMonIncome : $scope.coCustomerInfo.incomeSources.coCustomerMonIncome  !== undefined ? $scope.coCustomerInfo.incomeSources.coCustomerMonIncome  : ""
					}]
				//	}],
				//	averageMonthlyUtilities : avgUtilities !== undefined ? avgUtilities :""
				},
				mData.coCustomerInfo= {
					lastName : $scope.coCustomerInfo.lastName !== undefined ? $scope.coCustomerInfo.lastName  : "",
					firstName : $scope.coCustomerInfo.firstName !== undefined ? $scope.coCustomerInfo.firstName  : "",
					middleInitial : $scope.coCustomerInfo.middleInitial !== undefined ? $scope.coCustomerInfo.middleInitial  : "",
					birthDate : $scope.coCustomerInfo.dob !== undefined ? $scope.coCustomerPrivate.dob.year +$scope.coCustomerPrivate.dob.month +$scope.coCustomerPrivate.dob.day  : "",
					social : $scope.coCustSocial !== undefined ? $scope.coCustSocial  : "",
//					custInfoType : $scope.coCustomerInfo.custInfoType !== undefined ? $scope.coCustomerInfo.custInfoType  : "",
					relationshipType : $scope.coCustomerInfo.relationshipType !== undefined ? $scope.coCustomerInfo.relationshipType  : "",
					phoneNumber : $scope.coCustomerInfo.phone1.number !== undefined ? $scope.coCustomerInfo.phone1.number  : "",
					phoneType : $scope.coCustomerInfo.phone1.phoneType !== undefined ? $scope.coCustomerInfo.phone1.phoneType  : ""
				
				}
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends

			// Check if listed keys exist in form; for saving form properly
			if ($scope.customerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.customerPrivate.ssn))) {
				var SSN = $scope.customerPrivate.ssn;
				if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4) {
					mData.social = $scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3;
				}
			}

			if($scope.customerPrivate.ssn.ssnType == 'ITIN'){
				mData.itinFlag = true;
			}else{
				mData.itinFlag = false;
			}

			if ($scope.customerPrivate.dob && _.every(['month', 'day', 'year'], _.partial(_.has, $scope.customerPrivate.dob))) {
				mData.driversLicense.birthDate = $scope.customerPrivate.dob.year + $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day;
			}

			if($scope.isSpanishStore){
					var referenceCount = 4;
				}else{
					var referenceCount = 3;
			}

			var referencesArray = [];
			if($scope.storeInfo.isReferenceRequired !== false) {
				for (var i = 0; i < referenceCount; i++) {
					var formReference = $scope['ref' + (i + 1)],reference,set;
					set = "PERREF" + (i + 1);
					if(_(formReference).has('personalReferenceType') && formReference.personalReferenceType !== '') {
						reference = {
							lastName: formReference.lastName,
							firstName: formReference.firstName,
							personalReferenceType: formReference.personalReferenceType,
							address: [{
								addrLine1: formReference.address
							}],
							personalReferenceSet : set,
							phone: {
								areaCode: formReference.number.substr(0, 3),
								number: formReference.number.substr(3, 10),
								phoneType: formReference.phoneType,
								bestCallTime: ''
							}
						};
						referencesArray.push(reference);
					}
				}	
			}
			mData.personalReferences = referencesArray;

			if (_($scope.customerInfo).has('phone2')
			&& _($scope.customerInfo.phone2).has('number')
			&& $scope.customerInfo.phone2.number.length > 9) {
				var phone2 = {
					areaCode: $scope.customerInfo.phone2.number.substr(0, 3),
					number: $scope.customerInfo.phone2.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone2.phoneType,
					contactName: '',
					phoneSet: "CUSTPHN2"
				};
				mData.phones.push(phone2);
			}

			if ($scope.customerInfo.incomeSources.sourceStartDate !== '') {
				mData.incomeSources[0].sourceStartDate = moment().subtract('months', $scope.customerInfo.incomeSources.sourceStartDate).format("YYYYMMDD");
			}
			mData.isMissingInfo = true;//Story 30647 code change
			// save to /customer
			applicationService.saveApplication(mData)
				.then(
					function(response) {
						deferred.resolve('success');
					},
					function(error) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(error);
						if(error.data.errorData.status_message === 'SSN invalid'){
							modalService.error(error.data.errorData.status_message);
						}
						deferred.reject(error);
					}
				);

			return deferred.promise;
		}
		
		//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
		function updateCoIncome(){
			if($scope.custAdditionalInfo.noCocustomer){
				$scope.custAdditionalInfo.noCocustomerIncome = true;
				$scope.coCustomerInfo.firstName = "";
				$scope.coCustomerInfo.lastName = "";
				$scope.coCustomerInfo.middleName =  "";
				$scope.coCustomerInfo.relation = "";
				$scope.coCustomerInfo.phone1.number = ""; 
				$scope.coCustomerInfo.phone1.phoneType = null;	
				$scope.coCustomerInfo.driversLicense.id = "";
				$scope.coCustomerInfo.driversLicense.issuer = "";
				$scope.coCustomerInfo.driversLicense.idType = "";
				$scope.coCustomerInfo.incomeSources.income = null;
			}else{
				$scope.custAdditionalInfo.noCocustomerIncome = false;
				$scope.coCustomerInfo.firstName = $rootScope.findData.coCustomerInfo.firstName;
				$scope.coCustomerInfo.lastName =  $rootScope.findData.coCustomerInfo.lastName;
				$scope.coCustomerInfo.middleName =  $rootScope.findData.coCustomerInfo.middleInitial;
				$scope.coCustomerInfo.relation = $rootScope.findData.coCustomerInfo.relationshipType;
				$scope.coCustomerInfo.phone1.number = $rootScope.findData.coCustomerInfo.phoneNumber; 
				$scope.coCustomerInfo.phone1.phoneType = $rootScope.findData.coCustomerInfo.phoneType != '' || $rootScope.findData.coCustomerInfo.phoneType != null?$rootScope.findData.coCustomerInfo.phoneType:null;	
				$scope.coCustomerInfo.driversLicense.id = $rootScope.findData.coCustomerInfo.driversLicense.id;
				$scope.coCustomerInfo.driversLicense.issuer = $rootScope.findData.coCustomerInfo.driversLicense.issuer;
				$scope.coCustomerInfo.driversLicense.idType = $rootScope.findData.coCustomerInfo.driversLicense.idType;
				$scope.coCustomerInfo.incomeSources.income = $rootScope.findData.additionalInfo != undefined || $rootScope.findData.additionalInfo != null?$rootScope.findData.additionalInfo.coCustomerIncomeSource[0].incomeType :null;
			}	
		}

		function updateCocustomerIncome() {
			if($scope.custAdditionalInfo.noCocustomerIncome){
				$scope.coCustomerInfo.incomeSources.income = null;
			}else{
				$scope.coCustomerInfo.incomeSources.income = $rootScope.findData.additionalInfo != undefined || $rootScope.findData.additionalInfo != null?$rootScope.findData.additionalInfo.coCustomerIncomeSource[0].incomeType :null;
			}
		}
		//VAN-2401-PR stores to use SLI- AWP re-engagement  - Ends
		
		
	}
	MissingInfo.$inject = ["$scope", "$rootScope", "$location", "$route", "$idle", "$routeParams", "$q", "$log", "$sce", "$cookies", "$translate", "modalService", "authentication", "applicationService", "stateRestrictFactory", "SharedDataSvc", "agreementFactory", "getStoreInfoFactory", "getTrainingLibraryFactory", "getInitialDashboard"];
	
})();

(function() {
	'use strict';

	angular.module('myApp').controller('customFooterController', customFooterController);

	function customFooterController($scope, $rootScope, $location, $timeout, getStoreInfoFactory, SharedDataSvc, applicationService, $cookies) {

		$scope.checkStore = function(){
			if($cookies.getObject('isHybridMode')){
				$rootScope.isHybridMode = $cookies.getObject('isHybridMode');
			}
			if($cookies.getObject('isMannedMode')){
      			$rootScope.isMannedMode = $cookies.getObject('isMannedMode');
      		}
		};
	}
	customFooterController.$inject = ["$scope", "$rootScope", "$location", "$timeout", "getStoreInfoFactory", "SharedDataSvc", "applicationService", "$cookies"];; 
})();

(function(){

	'use strict';

	angular
	.module('myApp')
	.controller('confirmAndEditEmailCtrl', confirmAndEditEmailCtrl);

	function confirmAndEditEmailCtrl($scope, $q) {
		$scope.compareEmail = compareEmail;
		$scope.editEmail = editEmail;
		$scope.resetEmail = resetEmail;
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.verifyEmail = true;

		$scope.resetEmail();
        $scope.editEmailModeOn = false;
        $scope.isVerified = true;

		function compareEmail(el) {
			$scope.isVerified = false;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			if($scope.editEmailModeOff && !$scope.emailAddress){
				$scope.invalidEmailAddress = true;
				return false;
			} 
			if($scope.editEmailModeOff && !$scope.emailVerify){
				if(el == 'emailAddress'){
					$scope.isVerified = false;
					return false;
				} else {
					$scope.invalidEmailVerify = true;
					return false;					
				}				
			} 
			if($scope.emailAddress && $scope.emailVerify && ($scope.emailAddress.toUpperCase() != $scope.emailVerify.toUpperCase())){
				$scope.verifyEmail = false;
				return false;
			} else {
				$scope.verifyEmail = true;
				$scope.isVerified = true;
			}
		}

		function resetEmail() {	
			$scope.emailVerify = undefined;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			$scope.verifyEmail = true;
			$scope.isVerified = false;
			$scope.editEmailModeOff = false;
		}

		function editEmail(){
			$scope.editEmailModeOn = true;
			$scope.isVerified = false;
			$scope.editEmailModeOff = true;
		}
		
	}
	confirmAndEditEmailCtrl.$inject = ["$scope", "$q"];
})();
// The Agreement controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function() {
	'use strict';

	angular.module('myApp').controller('digitalAgreement', digitalAgreement);

	digitalAgreement.$inject = ["$scope", "$log", "$rootScope", "$routeParams", "$q", "$timeout", "$translate", "modalService", "$window", "$location", "agreementFactory", "getStoreInfoFactory", "customerDataFactory", "orderEstimatePutFactory", "datesFactory", "paymentFactory", "updateEngagementItemFactory", "productFactory", "SharedDataSvc", "base64", "stateRestrictFactory", "applicationService", "$cookies", "$modal"];//VAN-510 - Electronic Consent moved to app flow
	function digitalAgreement($scope, $log, $rootScope, $routeParams, $q,$timeout, $translate, modalService, $window, $location, agreementFactory, getStoreInfoFactory, customerDataFactory, orderEstimatePutFactory, datesFactory, paymentFactory, updateEngagementItemFactory, productFactory, SharedDataSvc, base64, stateRestrictFactory, applicationService, $cookies, $modal) {//VAN-510 - Electronic Consent moved to app flow
		/*jshint validthis: true */
		var vm = this,
		    keys;
		//AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		// set scope variable for processing modal
		$scope.modalTitle = 'Processing';

		// Models
		vm.customer = {};
		vm.engagement;
		vm.agreementData;
		vm.storeInfo;
		vm.stateRestrictions;
		vm.restrictions;
		vm.agreements = [{
			name : {
				en : en.agreement_header_RPA,
				es : es.agreement_header_RPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			// type: 'Kw7N2pTLkRsh202k2wKUdeAbHsosOoIcruqDUqo3idnw2YBrw2mFAAJ2dWQVGef4Y'
			type : 'RA',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_SA,
				es : es.agreement_header_SA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : undefined,
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_RAA,
				es : es.agreement_header_RAA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ACK',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_EPOA,
				es : es.agreement_header_EPOA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'EPO90',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_LDW,
				es : es.agreement_header_LDW
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'LDW',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_BPA,
				es : es.agreement_header_BPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'CLB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_AA,
				es : es.agreement_header_AA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ARB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_WA,
				es : es.agreement_header_WA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'FEE',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_DFA,
				es : es.agreement_header_DFA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'dlf',
			eSign : false,
			signature : null
		}];

		vm.agreementsSpanish = [{
			name : "Acuerdo de Alquiler con opción a compra",

		}, {
			name : "Acuerdo suplementario (s)",
		}, {
			name : "Reconocimiento del contrato de alquiler",

		}, {
			name : "Enmienda de opción de compra temprana",

		}, {
			name : "Acuerdo de exención de daños de responsabilidad",

		}, {
			name : "Acuerdo de Benefits Plus",

		}, {
			name : "Acuerdo de Arbitraje",

		}, {
			name : "Contrato de garantía",
		}, {
			name : "Acuerdo de entrega",
		}];

		vm.suppAgreementsName = {

			"CAM" : "Camcorder HD Supplement acknowledgement",
			"COP" : "Computer Rental Supplement acknowledgement",
			"CRB" : "Crib Waiver acknowledgement",
			"CYC" : "E-Cycle acknowledgement",
			"GAS" : "Gas Dryer Waiver acknowledgement",
			"LCD" : "Large Screen TV Supplement acknowledgement",
			"RAN" : "Range Waiver acknowledgement",
			"SOC" : "Summary of Cost acknowledgement",
			"VID" : "Electronic Device Rental Supplement acknowledgement"

		};

		vm.suppAgreementsSpanishName = {

			"CAM" : "Videocámara HD suplementaria",
			"COP" : "Arrendamiento de computadora suplementario",
			"CRB" : "Crib Waiver",
			"CYC" : "Reconocimiento  de  E-Cycle",
			"GAS" : "Reconocimiento  de Secadora de gas",
			"LCD" : "Reconocimiento  de TV de pantalla grande",
			"RAN" : "Reconocimiento  de renuncia  de Cocina económica",
			"SOC" : "Reconocimiento  de Resumen de Costo",
			"VID" : "Reconocimiento  de Dispositivo electronico"

		};

		vm.orderData = [_.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder)];

		vm.orderDataForAdjustablePayment = _.clone(SharedDataSvc.globalVars.agreementOrder);

		$scope.consent= { istcpaflag : false };

		// Variables
		vm.quote;
		vm.firstPayment = 0;
		vm.showPaymentOptionsModal = false;
		vm.paymentInfo = [];
		vm.showConsentAgree = false;
		vm.quoteDates = datesFactory.quoteDates;
		vm.quotes = agreementFactory.quotes;

		vm.engagementId = $routeParams.engagementId;
		$cookies.putObject('engagementId', vm.engagementId);

		vm.pdfLinks = [];
		vm.unsignedAgreement;
		vm.activeAgreementIndex = 0;
		vm.activeAgreement = vm.agreements[vm.activeAgreementIndex];
		vm.modal;
		vm.orderContent;
		vm.orderArray = [];
		vm.declineBpModalShown = false;
		vm.languagePreference = true;
		vm.hideBp;
		vm.customerInRadius = true;
		vm.isZipValid = true;
		vm.storeLatLng;
		vm.customerDistance;
		vm.storeRadius;
		vm.customerInStoreRadius;
		vm.term1 = false;
		vm.term2 = false;
		vm.term3 = false;
		vm.term4 = false;
		vm.term5 = false;
		vm.term6 = false;
		vm.term7 = false;
		vm.isAnyUnchecked = true;
		vm.inputInitials = "";
		vm.employeeId;
		vm.employeePinError;
		vm.pinAttempts = 0;
		vm.initialPaymentStatus = false;
		vm.initialPaymentErrorClass = "";
		vm.noEmpPin = false;
		vm.processingFee;
		vm.isEPOAmendmentEnabled = false;
		vm.suppCheck = [];
		vm.isAllSuppChecked = false;
		vm.showSupp = false;
		vm.showWarrantyAgreement = false;
		vm.showDelWarrantyAgreement = false;
		vm.adjustableDateSelection = "";
		vm.ipMinDate = "";
		vm.ipMaxDate = "";
		vm.arrAdjustablePayment = "";
		vm.isAdjustablePayment = false;
		vm.quoteGotAsPerSelection = "";
		/*VAN-774 - Biweekly renewal payments Long Term*/
		vm.quoteGotAsPerSelectionBiweekly = "";
		/*VAN-858 - Quad Weekly Payment Frequency*/
		vm.quoteGotAsPerSelectionQuarterly = "";
		
		vm.prodCat;
		//VAN-510 - Electronic Consent moved to app flow - Starts
		$rootScope.showConsent = false;
		$rootScope.showConsentForHT = false;
		vm.checkSignerDetails = checkSignerDetails;
		vm.newCustomerElectronicConsent = newCustomerElectronicConsent;
		vm.updateCustomerConstent = updateCustomerConstent;
		vm.exitHTConsent = exitHTConsent;
		$rootScope.languageOption = $rootScope.selectedLanguage == 'en' ? true : false;
		//VAN-510 - Electronic Consent moved to app flow - Ends
		
		// Controller Functions
		vm.submitAgreements = submitAgreements;
		vm.createAgreement = createAgreement;
		vm.signAgreement = signAgreement;
		vm.declineAgreement = declineAgreement;
		vm.navigateTo = navigateTo;
		vm.signLdw = signLdw;
		vm.showModal = showModal;
		vm.setFirstPaymentDate = setFirstPaymentDate;
		vm.handleLdwDecline = handleLdwDecline;
		vm.handleBpDecline = handleBpDecline;
		vm.handleSkipBp = handleSkipBp;
		vm.getFirstPaymentOptions = getFirstPaymentOptions;
		vm.setLang = setLang;
		vm.declineRental = declineRental;
		vm.setAgreementSignature = setAgreementSignature;
		vm.declineConsent = declineConsent;
		vm.hideBPWhenLDWisDeclined = hideBPWhenLDWisDeclined;
		vm.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		vm.navigateToApplication = navigateToApplication;
		vm.declineBp = declineBp;
		vm.declineBpNew = declineBpNew;
		vm.handleInitialsStateCheck = handleInitialsStateCheck;
		vm.setEmployeeId = setEmployeeId;
		vm.checkStore = checkStore;
		vm.validateAssociatePasswordCash = validateAssociatePasswordCash;
		vm.checkAssociatePin = checkAssociatePin;
		vm.createInventoryList = createInventoryList;
		vm.isSkipButtonEnable = false;
		vm.agreementHTID = "";
		//AC 25996 code changes
		//AC13303 code changes starts
		vm.voidAgreement = voidAgreement;
		vm.agreementStatus = "";
		//AC13303 code changes ends
		vm.dateSelectionDone = dateSelectionDone;
		vm.setOrderDateAsPerSelection = setOrderDateAsPerSelection;
		vm.openfirstPaymentSelectionCalendar = openfirstPaymentSelectionCalendar;
		/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
		vm.modifyPaymetMode = modifyPaymetMode;
		vm.showInitialPayment = showInitialPayment;
		/*VAN-774 - Biweekly renewal payments Long Term - Ends*/
		//AC24649 Code changes starts
		vm.agreementCodeVerify = agreementCodeVerify;
		vm.reselectNextPaymentError = reselectNextPaymentError;
		$scope.showVerification = true;
		//AC24649 Code changes ends
		//7573 feature
		$scope.verifyAgreement = {};
		$scope.verifyAgreement.textCode = '';
		$scope.verifyAgreement.textCodeAttemptsNumber = 0;
		$scope.verifyAgreement.textCodeError = false;
		$scope.verifyAgreement.agrVerificationNumber = '';
		$scope.submitTextCode = submitTextCode;

		vm.getRAInitials = null;
		vm.beforeSigning = beforeSigning;
		vm.afterSigning = afterSigning;
		vm.notYetSigning = notYetSigning;
		vm.checkAndNavigate = checkAndNavigate;
		vm.enableSigning = enableSigning;
		vm.checkSigner = checkSigner;
		vm.afterSigningConsent = afterSigningConsent;
		vm.askForSigningConsent = askForSigningConsent;
		vm.cancelAndNavigate = cancelAndNavigate;
		vm.showLowDownPaymentOptions = showLowDownPaymentOptions;
		vm.signer = {
			customer : false,
			coCustomer : false
		};
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		$scope.isPromoSelected= false;
		vm.selectedPromo;
		vm.showPaymentInitial = showPaymentInitial;
		vm.showInitialPaymentOptions = showInitialPaymentOptions;
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends

		//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts
		vm.verifySignerDetails = verifySignerDetails;
		//Commented code
		/*vm.enableBP = false;*/
		//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
		vm.diffDays = "";
		vm.promoOneAdditionalAmt = "";
		vm.promoTwoAdditionalAmt = "";
		vm.pifPromoAdditionalAmt = "";
		vm.promoAddAmt = "";
		vm.showAddAmt = false;
		vm.showPIFPromoOption = false;
		vm.promoPayment = "";
		vm.changePaymentMode = changePaymentMode;
		vm.calculateDiffDays = calculateDiffDays;
		vm.promoPaymentSelectOperation = promoPaymentSelectOperation;
		vm.verifyEmployeePassWord = verifyEmployeePassWord;
		$rootScope.langSelectedEn = true;
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
		$scope.navigateTo = navigateTo;
		vm.unSignedEmailModal = unSignedEmailModal;//VAN-2398-Prompt for CA stores about unsigned documents 
		vm.unSignedEmailSameDay = unSignedEmailSameDay;//VAN-2398-Prompt for CA stores about unsigned documents
		vm.vcPromoAdditionalAmt = "";//VAN-2841-AWP-Value City $1 Promo
		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
		vm.quoteCalculator = quoteCalculator;
		vm.getAlternateAmount = getAlternateAmount;
		vm.buildQuoteForVarPay = buildQuoteForVarPay;
		vm.enableVariablePay = false;
		$rootScope.showCalforVarPay = false;
		vm.newPaymentMode = false;
		vm.variablePay = {};
		vm.buildCalendarForVarPay = buildCalendarForVarPay;
		vm.variablePayCheck = variablePayCheck;
		vm.invoiceReductionPaymentAmount;
		vm.invoiceReductionPayment = SharedDataSvc.invoiceReductionPayment;

		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
		
		init();

		function init() {

			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');

			//setting processing fee
			//vm.processingFee = SharedDataSvc.globalVars.estimateReturn.processingFee;

			var currentDate = datesFactory.getCurrentDate(),
			    quoteDates = datesFactory.setQuoteDates(currentDate),
			    findData = {
				'firstName' : null,
				'lastName' : null,
				'phone' : null
			},
			    engagement;
			void 0;
			vm.quoteDates = quoteDates;
			setOrderDates(quoteDates);

			// Default language to English
			SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';

			// reset inventory
			SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];

			void 0;

			showModal('Processing', 'processing-modal');

			getStoreInfoFactory.getData().then(function(response) {
				$log.log('[Agreement.init] STORE INFO', response);
				vm.storeInfo = response;
				SharedDataSvc.globalVars.storeSetup = response;
				if(response.vanStoreId) {
					$rootScope.storeNo = response.vanStoreId;
 					$rootScope.state = response.state;
 				}
				$rootScope.invoiceMethod = response.invoiceMethod;
				setSkipButtonVisibility(vm.storeInfo.state);
				vm.isEPOAmendmentEnabled = vm.storeInfo.isEPOAmendmentEnabled;

				vm.arrAdjustablePayment = vm.storeInfo.adjustableInitPayment.split(",");
				vm.isAdjustablePayment = parseInt(vm.arrAdjustablePayment[0]);

				stateRestrictFactory.getStateRestrictions().then(function (response) {
					vm.stateRestrictions = response.stateRestrictions[0];
					vm.getRAInitials = _.find(vm.stateRestrictions.allowRAInitials, {'state': vm.storeInfo.state}); 
				});

				stateRestrictFactory.getAllStoreRestrictions(vm.storeInfo.state).then(function(response) {
					vm.restrictions = response;
					vm.storeLatLng = {
						'lat' : vm.storeInfo.latitude,
						'lng' : vm.storeInfo.longitude
					};
					vm.storeRadius = response.allowedRentalRadiusMiles;
					storeExclusions();
					$log.log('[Agreement.init] STORE RESTRICTIONS', response);
				});
			});
			
			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				/*VAN-774 - Biweekly renewal payments Long Term*/
				vm.paymentSelected  = response.paymentMode;
				void 0;
				/*VAN-4050 AWP - show initial payment details before agreement creation  - order screen invoice reduction payment changes */
				if(vm.invoiceReductionPayment) {
					vm.invoiceReductionPaymentAmount = response.invoiceReductionPaymentAmount;
					void 0;
				}

				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				if(vm.storeInfo.paymentModeEnabled != undefined && vm.paymentSelected == undefined){
					vm.paymentSelected = "Monthly";
				}
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				if(response.paymentMode == "Fourweek" || response.paymentMode == "FOURWEEK"){//VAN-979-AWP: Flexible payment option details were not populated for the customer created from KIOSK
					vm.paymentSelected ="4 Weeks";
				}
				/*VAN-963-Renewal Payment Amount & Number of Payment not appearing - Starts*/
				if(vm.paymentSelected != undefined && vm.paymentSelected != null && vm.paymentSelected == "MONTHLY"){
					vm.paymentSelected = "Monthly";
				}
				/*VAN-963-Renewal Payment Amount & Number of Payment not appearing - Ends*/
				/*VAN-979-AWP: Flexible payment option details were not populated for the customer created from KIOSK - Starts*/
				if(vm.paymentSelected != undefined && vm.paymentSelected != null && vm.paymentSelected == "BIWEEKLY"){
					vm.paymentSelected = "Biweekly";
				}
				/*VAN-979-AWP: Flexible payment option details were not populated for the customer created from KIOSK - Ends*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				//commented code
				/*vm.agreementStatus = response.agreementStatus;
				if(response.agreementHTId!=null && response.agreementHTId!=undefined){
					vm.agreementHTId = response.agreementHTId;
				}	*/	
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				
				if(response.agreementOriginator && response.agreementOriginator.toLowerCase() == "hightouch"){
					vm.isHTCustomer = true;
				}

				$log.log('[Agreement.init] customerDataFactory.findByEngagementId response: ', response);
				vm.orderContent = (response.orderContent !== null && response.orderContent !== '') ? JSON.parse(response.orderContent) : null;
				vm.engagement = response;
				$log.log('[Agreement.init] engagement Order', vm.orderContent);
				$log.log('[Agreement.init] engagement', vm.engagement);

				vm.customer.customerFirstName = findData.firstName = vm.engagement.customer.fName;
				vm.customer.customerLastName = findData.lastName = vm.engagement.customer.lName;
				findData.phone = vm.engagement.customer.phNum;
				findData.engagementId = vm.engagement.engagementId;//AC14937 code changes added

				vm.engagement = {};
				var engagementProps = ['engagementId', 'orderStatus', 'deliveryStatus', 'paymentStatus', 'salesStatus', 'agreementStatus', 'applicationStatus', 'orderOriginator'];
				// cherry pick the needed properties for a proper engagement
				for (var i = 0; i < engagementProps.length; i++) {
					vm.engagement[engagementProps[i]] = response[engagementProps[i]];
				}
				
				vm.engagement['customerId'] = response.customer.customerId;
				vm.engagement['customerHtID'] = response.customerHTId;
				vm.engagement['customerHtStoreId'] = response.customerHTStoreId;
				vm.engagement['vanStoreId'] = response.vanStore.storeId;
				void 0;	
				applicationService.getApplication(findData).then(function(response) {
					void 0;
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show*/
					/*vm.custClubStatus = response.custClubStatus;*///commented code
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Starts*/
					$scope.stepsListEn = [
					          			'Application and Order (either can be first)',
					          			'Order Confirmation',
					          			'Agreements',
					          			'Payment',
					          			'Invoice & Delivery or Generate Credit Card (either can be first)'
					          		];
					          		$scope.stepsListEs = [
					          			"Aplicación y orden (Cualquiera puede ser primero)",
					          			"Confirmación del pedido",
					          			"Acuerdos",
					          			"Pago",
					          			"Factura y entrega o genere la tarjeta de crédito (Cualquiera puede ser primero)"	
					          		];
					if(response.VanEngagement.agreementStatus === 'Voided' && response.VanEngagement.orderStatus === 'NotStarted'){
						showModal('enforceOrderVoidedAgreement');
					}else{
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Ends*/
					vm.customerFind = ( typeof response !== "object") ? JSON.parse(response) : response;
					var signerStatus = vm.customerFind.VanEngagement.signerStatus ? 
						JSON.parse(vm.customerFind.VanEngagement.signerStatus).signerStatus : '';
					var isAgreementPending = _.filter(signerStatus, {signingStatus :"Pending"});
					$scope.isAgreementPending = isAgreementPending;//VAN-2398-Prompt for CA stores about unsigned documents 
					//VAN-672 - Low Down Initial Payment should be displayed on RA
					vm.promoSelectedAtAgreement = (vm.customerFind.VanEngagement.promoSelectedAtAgreement !== null && vm.customerFind.VanEngagement.promoSelectedAtAgreement !== '') ? JSON.parse(vm.customerFind.VanEngagement.promoSelectedAtAgreement) : null;
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
					if(vm.promoSelectedAtAgreement != undefined){
					//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
					/*if(vm.promoSelectedAtAgreement.code === "H"){
							vm.promoPayment = vm.storeInfo.noCalcPromos[0].payment;
						}else if(vm.promoSelectedAtAgreement.code === "J"){
							vm.promoPayment = vm.storeInfo.noCalcPromos[1].payment;
						}else if(vm.promoSelectedAtAgreement.code === "D"){
							vm.promoPayment = vm.promoSelectedAtAgreement.payment;
						}//VAN-2841-AWP-Value City $1 Promo - Starts
						else if(vm.promoSelectedAtAgreement.code === "Q"){
							vm.promoPayment = vm.storeInfo.noCalcPromos[0].payment;
						}*///VAN-2841-AWP-Value City $1 Promo - Ends
						//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
						
						for(var i in vm.storeInfo.noCalcPromos)
						{
						if(vm.promoSelectedAtAgreement.code === vm.storeInfo.noCalcPromos[i].code){
							vm.promoPayment = vm.storeInfo.noCalcPromos[i].payment;
						}
						}
						if(vm.promoSelectedAtAgreement.code === "D"){
							vm.promoPayment = vm.promoSelectedAtAgreement.payment;
						}
					//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
						else if(vm.promoSelectedAtAgreement.desc === ""){
							vm.promoPayment = vm.promoSelectedAtAgreement.payment;
							vm.enableVariablePay = true;
						}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
					}
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
						
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
					} else {
						vm.languagePreference = false;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
					}
					
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/ //LDW should be hidden after selecting finish later
					vm.showLDWSigned = false;
					if(vm.customerFind.agreement){
						if(!vm.customerFind.agreement.UnsignedLDW){
							vm.showLDWSigned = true;
						} 
					} else {
						vm.showLDWSigned = true;
					}
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
					
					/*VAN-333 : AWP - Multiengagement IN shows BP in the second time when associate finishes later and then signs the agreement.*/
					vm.showBPSigned = false;
					if(vm.customerFind.agreement){
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show*/ //Bp should not visible after selcting finish later
						//Commented Code
						/*if(vm.customerFind.agreement.CLB && vm.custClubStatus !== 'MEMBER'){*///if(vm.customerFind.VanEngagement.clubStatus == -1){
						 if(vm.customerFind.agreement.CLB && $cookies.getObject('clubActivate')){//if(vm.customerFind.VanEngagement.clubStatus == -1){
							vm.showBPSigned = true;
						} 
					} else {
						vm.showBPSigned = true;
					}
					
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*///Next Day scenario LDW & BP should not blank page
					if(vm.customerFind.agreement && vm.customerFind.agreement.agreementStatus){
						if((vm.customerFind.agreement.agreementStatus === 'PENDING' || vm.customerFind.agreement.agreementStatus === 'INACTIVE')
								&& !isSameDayAgreement()){
							vm.showLDWSigned = true;
							vm.showBPSigned = true;
						}
					}
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
					
					/* VAN-333 Ends */
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/ /*Next Day Scenario*/
					//commented code 
					/*if(response.VanEngagement.agreementHTId==null && response.VanEngagement.agreementStatus === 'Voided'){
						vm.showLDWSigned = true;
						vm.showBPSigned = true;
					}*/
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
					
					$rootScope.agreementloaderText = (SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == 'SPANISH') ? es.common_label_processing : en.common_label_processing;
					//VAN-510 - Electronic Consent moved to app flow - Starts
					if(vm.customerFind.isCoCustomerInfoProvided){
						vm.hasCoCustomer = true;
					}
					if(vm.customerFind.VanEngagement.agreementOriginator !== null && vm.customerFind.VanEngagement.agreementOriginator.toLowerCase() === "hightouch"
						&& vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
						$rootScope.htConsent = true;
					}
					//VAN-510 - Electronic Consent moved to app flow - Ends
					if(vm.customerFind.VanEngagement.agreementHTId && vm.customerFind.VanEngagement.agreementStatus.toLowerCase() == 'pending') {
						if(isAgreementPending.length > 0){
							if(isAgreementPending.length > 1 && vm.customerFind.isCoCustomerInfoProvided){ //&& $cookies.getObject('isCoCustomerSignerPending') 
								vm.isCoCustomerSignerPending = true;
							} else if(isAgreementPending[0].customerType.toLowerCase() == 'coapplicant'){
								vm.signingPerson = {
									firstName : vm.customerFind.coCustomerInfo.firstName,
									lastName : vm.customerFind.coCustomerInfo.lastName
								};
								vm.signersPresent = 'COAPPLICANT';
							} else {
								vm.signingPerson = {
									firstName : vm.customer.customerFirstName,
									lastName : vm.customer.customerLastName
								};
								vm.signersPresent = 'APPLICANT';
							}
							//VAN-2398-Prompt for CA stores about unsigned documents - Starts
							$scope.coCustSigner = $cookies.getObject('isCoCustomerSignerPending');
							if($rootScope.state === 'CA' && vm.customerFind.VanEngagement.agreementHTId != null && isSameDayAgreement() && ($scope.coCustSigner != null)){
								var unSignedEmailModal = modalService.open({
									scope: $scope,
									windowClass: '',
									templateUrl: 'partials/modal_unSignedEmail.html',
									backdrop: 'static'
								});
								unSignedEmailModal.result.then(function(){
									vm.unSignedEmailSameDay();
								});
								return false;
							}else{//VAN-2398-Prompt for CA stores about unsigned documents - Ends
							if($cookies.getObject('isCoCustomerSignerPending')){
								if(vm.customerFind.isCoCustomerInfoProvided == true){
								//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Same day when corenter give finish later 
									if(isAgreementPending[0].customerType.toLowerCase() == 'applicant'){
										vm.afterSigningConsent();
									}
									else{
									vm.afterSigning();
									}
									//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Same day when coapplicant give finish later
									}
								//VAN-510 - Electronic Consent moved to app flow
								else{
									$rootScope.showConsent = true;
									vm.askForSigningConsent();}
								$cookies.putObject('isCoCustomerSignerPending', null);
								return false;
							} else if(vm.customerFind.isCoCustomerInfoProvided && isAgreementPending.length == 1 && vm.customerFind.VanEngagement.agreementOriginator !== null && vm.customerFind.VanEngagement.agreementOriginator.toLowerCase() === "hightouch"){
								//VAN-510 - Electronic Consent moved to app flow
								if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
									$rootScope.showConsentForHT = true;
								}
								vm.afterSigningConsent();
								return false;
							}
						}
					} 
				} 

					if(vm.customerFind.isCoCustomerInfoProvided){
						vm.hasCoCustomer = true;
					}

					if(vm.isHTCustomer || ((vm.hideBp && vm.restrictions.skipLdw) && vm.customerFind.VanEngagement.agreementHTId)){
						//VAN-2398-Prompt for CA stores about unsigned documents - Starts
						if($rootScope.state === 'CA' && vm.customerFind.VanEngagement.agreementHTId != null && isSameDayAgreement()){
							var unSignedEmailModal = modalService.open({
								scope: $scope,
								windowClass: '',
								templateUrl: 'partials/modal_unSignedEmail.html',
								backdrop: 'static'
							});
							unSignedEmailModal.result.then(function(){
								vm.beforeSigning();
							});
						}else{//VAN-2398-Prompt for CA stores about unsigned documents - Ends
						vm.beforeSigning();}
						return false;
					}

					if (!response.VanEngagement) {
						void 0;
						showModal('customerNotFound');
					} else {
						void 0;
						/* Setting the agreement HTID from findCustomer response */
						vm.agreementHTID = response.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*//*Completed till payment on same day and docusign on next day*/
						//Commented Code
						/*if(!isSameDayAgreement() && vm.customerFind.agreement.agreementStatus=='ACTIVE'){
							vm.showLDWSigned = false;
							vm.showBPSigned = false;
							if(vm.isHTCustomer){
								vm.submitAgreements();
							}else{
								beforeSigningModal();								
							}
						}*/
						/*VAN - If BP not selected show $49, if BP select do not show - Ends*/
		
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if(zip && (zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								void 0;
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Starts*/
					}
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Ends*/
				}
				}, function(response) {
					//modalService.dismiss();
					vm.customerFind = response.data.errorData;

					if (!response.data.errorData.VanEngagement) {
						void 0;
						if(response.data.errorData.status_code == "ENG-A4001-400"){
							showModal('agreementAlreadyExist');
						}else{
							showModal('customerNotFound');
						}
					} else {
						void 0;
						vm.agreementHTID = response.data.errorData.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if((zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					}
				});
			}).catch(function(response) {
				$log.log('[Agreement.init] could not get customer data', response);
			});

			//get processing fee
			orderEstimatePutFactory.getProcessingFee().then(function(processingFee) {
				vm.processingFee = processingFee;
			});

			//get product category list
			productFactory.getProductCategories().then(function(response) {
				vm.prodCat = response.productCategories;
			});
		}

		function setLang(currentLang) {
			if (!currentLang) {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
				$rootScope.langSelectedEn = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			} else {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
			}
			void 0;
		}

		function storeExclusions() {
			var bp = _.where(vm.agreements, {
				type : 'CLB'
			}),
			    ldw = _.where(vm.agreements, {
				type : 'LDW'
			});

			// console.log('skipBp', vm.restrictions.skipBp);
			// console.log('skipldw', vm.restrictions.skipLdw);

			if (vm.restrictions.skipBp) {
				delete vm.agreements[5];
				//Have changed here
				vm.hideBp = true;
			}

			if (vm.restrictions.skipLdw) {
				delete vm.agreements[4];
				//Have changed here
			}

			if (!vm.isEPOAmendmentEnabled) {
				delete vm.agreements[3];
				//Have changed here
			}

			void 0;
		}

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		};
		
		function handleCustomerOutOfRadius() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.engagement.applicationStatus = 'OutOfArea';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Successfully updated engagement');
				navigateTo('dashboard');
			}, function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Updating engagement failed');
				modalService.dismiss();
			});
		}

		function navigateToApplication() {
			$log.log(vm.engagement);
			customerDataFactory.findByEngagementId(vm.engagement.engagementId).then(function(response) {
				$log.log('[Agreement.getCustomer] customer', response);
				var person = {
					firstName : response.customer.fName,
					lastName : response.customer.lName,
					phone : response.customer.phNum,
					hasEngagementFromAgreement : true
				};
				var personData = window.btoa(JSON.stringify(person));
				$location.path('application/' + vm.engagement.engagementId).search({
					data : personData
				});
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function dateSelectionDone() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.setOrderDateAsPerSelection(vm.adjustableDateSelection);

			/*var paymentModal = modalService.open({ //changes for 9619, VAN-365:VAN: Same payment option for all on same agreement put on hold
				scope: $scope,
				templateUrl: 'partials/modal_paymentOptions.html',
				backdrop: 'static',
				windowClass: 'paymentOptionsModal'
			});
			paymentModal.result.then(function(){
				vm.orderDataForAdjustablePayment.paymentOption = vm.paymentOption;*/
			/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
			
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;}
//			VAN-2841-AWP-Value City $1 Promo
			if(vm.storeInfo.noCalcPromos[0].code === 'H' || vm.storeInfo.noCalcPromos[0].code === 'J'){
				vm.orderDataForAdjustablePayment.promotionCode = "H";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoOneResponse) {
					void 0;
					vm.promoOneQuote = promoOneResponse;
					if(vm.showAddAmt){
//						vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);}
						//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
						if(vm.restrictions.skipBp){
							vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);
						}else{
							vm.promoOneAdditionalAmt = ((vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial) - 49).toFixed(2);
						}}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
					vm.orderDataForAdjustablePayment.promotionCode = "J";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoTwoResponse) {
						void 0;
						vm.promoTwoQuote = promoTwoResponse;
						if(vm.showAddAmt){
//							vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);}
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
							if(vm.restrictions.skipBp){
								vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);
							}else{
								vm.promoTwoAdditionalAmt = ((vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial) - 99).toFixed(2);
							}}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						vm.orderDataForAdjustablePayment.promotionCode = "D";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
							void 0;
							vm.pifPromoQuote = pifPromoResponse;
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							if(vm.showAddAmt){
//								vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal - 0).toFixed(2);}
								//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								if(vm.restrictions.skipBp){
									vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
								}else{
									vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
								}}
							vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						},function(pifPromoResponse) {
							void 0;
							modalService.dismiss();
						});	
					},function(promoTwoResponse) {
						void 0;
						modalService.dismiss();
					});	
				},function(promoOneResponse) {
					void 0;
					modalService.dismiss();
				});
//				VAN-2841-AWP-Value City $1 Promo - Starts
			}else if(vm.storeInfo.noCalcPromos[0].code === 'Q'){
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					void 0;
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}

					vm.orderDataForAdjustablePayment.promotionCode = "Q";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
						void 0;
						vm.vcPromoQuote = vcPromoresponse;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
							}else{
								vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
							}}
						vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					},function(vcPromoresponse) {
						void 0;
						modalService.dismiss();
					});
				},function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
				});	
			}
			//VAN-3623-Promocode Configurable
			else if(vm.storeInfo.noCalcPromos[0].code != 'H' && vm.storeInfo.noCalcPromos[0].code != 'J' && vm.storeInfo.noCalcPromos[0].code != 'Q'){
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					void 0;
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}

					vm.orderDataForAdjustablePayment.promotionCode = vm.storeInfo.noCalcPromos[0].code;
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
						void 0;
						vm.vcPromoQuote = vcPromoresponse;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - vm.storeInfo.noCalcPromos[0].payment).toFixed(2);
							}else{
								vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) -  vm.storeInfo.noCalcPromos[0].payment).toFixed(2);
							}}
						vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					},function(vcPromoresponse) {
						void 0;
						modalService.dismiss();
					});
				},function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
				});	
			}
			//VAN-3623-Promocode Configurable
//			VAN-2841-AWP-Value City $1 Promo - Ends
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
//				Commented as part of VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
				/*vm.orderDataForAdjustablePayment.paymentMode = 'Monthly';
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(monthlyResponse) {
				 console.log('[agreementFactory.setQuotesAsPerSelection] Monthly success', monthlyResponse);
				 vm.quoteGotAsPerSelection = monthlyResponse;
					vm.orderDataForAdjustablePayment.paymentMode = 'Biweekly';
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(biweeklyResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] Biweekly success', biweeklyResponse);
					vm.quoteGotAsPerSelectionBiweekly = biweeklyResponse;
					VAN-858 - Quad Weekly Payment Frequency - Starts
						vm.orderDataForAdjustablePayment.paymentMode = 'FOURWEEK';
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(quarterlyResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] Quarterly success', quarterlyResponse);
						vm.quoteGotAsPerSelectionQuarterly = quarterlyResponse;
						VAN-858 - Quad Weekly Payment Frequency - Ends
					$scope.paymentSelected = SharedDataSvc.globalVars.paymentModeSelected;	
					vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					VAN-858 - Quad Weekly Payment Frequency - Starts
					}, function(quarterlyResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] Quarterly fail', quarterlyResponse);
						modalService.dismiss();
					}); 
					VAN-858 - Quad Weekly Payment Frequency - Ends
				}, function(biweeklyResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] Biweekly fail', biweeklyResponse);
					modalService.dismiss();
				}); 
				},function(monthlyResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] Monthly fail', monthlyResponse);
					modalService.dismiss();
				});*/
			/*VAN-774 - Biweekly renewal payments Long Term - Ends*/
			/*});	*/		
		}

		function getFirstPaymentOptions() {

			void 0;

			if (vm.orderContent.orderItems.length > 0) {
				$rootScope.agreementloaderText = (SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == 'SPANISH') ? es.common_label_processing : en.common_label_processing;
				showModal('ProcessingAgreement', 'processing-modal');

				_.forEach(vm.orderContent.orderItems, function(order, i) {
					productFactory.getProductInfo(vm.orderContent.orderItems[i]).then(function(response) {
						$log.log('product info ' + i, response);
						vm.orderArray.push(response);

						if (i === vm.orderContent.orderItems.length - 1) {

							if (vm.isAdjustablePayment) {
								setOrderDataForAdjustablePayment();
								if (vm.orderDataForAdjustablePayment.feeArray && vm.orderDataForAdjustablePayment.feeArray.length > 1) {
									if (vm.orderContent.deliveryFeeOneTime) {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = false;
									} else {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = true;
									}
								}
							} else {
								setOrderData();
								_.forEach(vm.orderData, function(orderData) {
									if (orderData.feeArray && orderData.feeArray.length > 1) {
										if (vm.orderContent.deliveryFeeOneTime) {
											orderData.feeArray[1].feeAcrossTerm = false;
										} else {
											orderData.feeArray[1].feeAcrossTerm = true;
										}

									}
								});
							}

							if (vm.isAdjustablePayment) {
								var start = parseInt(vm.arrAdjustablePayment[1]);
								var end = parseInt(vm.arrAdjustablePayment[2]);
								void 0;
								//VAN-1401 - Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
								if(end === 15 && vm.storeInfo.overrideAdjustableInitPaymentDays != null){
									end = parseInt(vm.storeInfo.overrideAdjustableInitPaymentDays);
								}//VAN-1401 - Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
								
								datesFactory.getDateByDaysFromNow(start).then(function(response) {
									vm.ipMinDate = response;
									vm.adjustableDateSelection = response;
									datesFactory.getDateByDaysFromNow(end).then(function(responseData) {
										vm.ipMaxDate = responseData;
										vm.openfirstPaymentSelectionCalendar();
									});
								});

							} else {
								agreementFactory.setQuotes(vm.orderData).then(function() {
									vm.quotes = agreementFactory.quotes;
									vm.showModal('firstPayment', 'first-payment-options-modal');

									// $log.log('Quotes', agreementFactory.quotes);
									// $log.log('quote dates', qouteDates);
									$log.log('[Agreement.getFirstPaymentOptions] store info', vm.storeInfo);
								});
							}
						}
					});
				});

			} else {
				void 0;
			}
		}

		/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
		function modifyPaymetMode(){
			if(vm.paymentSelected!=undefined){
				vm.modifyPayment = vm.paymentSelected;
			}else{
				vm.modifyPayment = "Monthly";
			}
			/*vm.numberOfPayments = vm.quoteGotAsPerSelection.numberOfPayments;
			vm.monthlyPayment = vm.quoteGotAsPerSelection.monthlyPaymentWTax;*/
			changePaymentMode();//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
//			showModal('modifyInitialPayment');//Commented as part of VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
		}
		
		function showInitialPayment(modifyPaymentMode){
//			showModal('Processing', 'processing-modal');//VAN-3066-AWP_Application not navigating to further screen if we change Flexible Payment Option from Initial payment Details screen.
			vm.paymentSelected = modifyPaymentMode;
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;}
			/*VAN-2843----Comment*/
//			VAN-2841-AWP-Value City $1 Promo
			/*if(vm.storeInfo.noCalcPromos[0].code === 'H' || vm.storeInfo.noCalcPromos[0].code === 'J'){
				vm.orderDataForAdjustablePayment.promotionCode = "H";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoOneResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] promoOne success', promoOneResponse);
					vm.promoOneQuote = promoOneResponse;
					if(vm.showAddAmt){
						//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
						if(vm.restrictions.skipBp){
							vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);
						}else{
							vm.promoOneAdditionalAmt = ((vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial) - 49).toFixed(2);}
					}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
					vm.orderDataForAdjustablePayment.promotionCode = "J";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoTwoResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] promoTwo success', promoTwoResponse);
						vm.promoTwoQuote = promoTwoResponse;
						if(vm.showAddAmt){
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
							if(vm.restrictions.skipBp){
								vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);
							}else{
								vm.promoTwoAdditionalAmt = ((vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial) - 99).toFixed(2);}
						}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						vm.orderDataForAdjustablePayment.promotionCode = "D";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
							console.log('[agreementFactory.setQuotesAsPerSelection] PIF Promor success', pifPromoResponse);
							vm.pifPromoQuote = pifPromoResponse;
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
							if(vm.restrictions.skipBp){
								vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
								}else{
									vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);}
							}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							modalService.dismiss();
							vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
						},function(pifPromoResponse) {
							console.log('[agreementFactory.setQuotesAsPerSelection] pifPromo fail', pifPromoResponse);
							modalService.dismiss();
						});	
					},function(promoTwoResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] promoTwo fail', promoTwoResponse);
						modalService.dismiss();
					});	
				},function(promoOneResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] promoOne fail', promoOneResponse);
					modalService.dismiss();
				});
				//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
//				VAN-2841-AWP-Value City $1 Promo - Starts
			}else if(vm.storeInfo.noCalcPromos[0].code === 'Q'){
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] PIF Promo success', pifPromoResponse);
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}

					vm.orderDataForAdjustablePayment.promotionCode = "Q";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo success', vcPromoresponse);
						vm.vcPromoQuote = vcPromoresponse;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
							}else{
								vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
							}
						}
						modalService.dismiss();
						vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					},function(vcPromoresponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo fail', vcPromoresponse);
						modalService.dismiss();
					});
				},function(pifPromoResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] pifPromo fail', pifPromoResponse);
					modalService.dismiss();
				});	
			}
*///			VAN-2841-AWP-Value City $1 Promo - Ends
			/*VAN-2843----Comment*/
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			if(vm.enableVariablePay){
				vm.ipMinDate = "";
				vm.ipMaxDate = "";
				vm.newPaymentMode = true;
				if(vm.orderDataForAdjustablePayment.paymentMode == 'Monthly'){
					vm.adjustableDateSelection = moment(vm.quoteGotAsPerSelection.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
					vm.expiryDate = vm.quoteGotAsPerSelection.expirationDate;
				}else if(vm.orderDataForAdjustablePayment.paymentMode == 'Biweekly'){
					vm.adjustableDateSelection = moment(vm.quoteGotAsPerSelectionBiweekly.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
					vm.expiryDate = vm.quoteGotAsPerSelectionBiweekly.expirationDate;
				} else if(vm.orderDataForAdjustablePayment.paymentMode == 'Fourweek'){
					vm.adjustableDateSelection = moment(vm.quoteGotAsPerSelectionQuarterly.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
					vm.expiryDate = vm.quoteGotAsPerSelectionQuarterly.expirationDate;
				}
			}
			quoteCalculator();
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
		}
		/*VAN-774 - Biweekly renewal payments Long Term - Ends*/

		function openfirstPaymentSelectionCalendar() {
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
			vm.showPIFPromoOption = false;
			//vm.selectedPromo = null;//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
			vm.showModal('adjustablePayment', 'first-adjustable-payment-modal');
		}

		function handleLdwDecline(index) {
			vm.agreements[index].eSign = false;
			showModal('declineLdw');
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
			//Commented Code
			/*if(vm.custClubStatus == "MEMBER"){
				showModal('declineLdwWhenBpActive');
			}
			else{
				showModal('declineLdw');
			}*/
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
		}

		function handleSkipBp() {
			vm.declineBpNew();
		}

		function handleBpDecline() {
			vm.declineBpNew();
		}

		function handleInitialsStateCheck() {
			if($cookies.getObject('isCoCustomerSignerPending')){
				vm.afterSigning();
			} else {
				vm.afterSigningConsent();
			}
		}

		function setSkipButtonVisibility(state) {
			if (state == "FL" || state == "CA") {
				vm.isSkipButtonEnable = true;
			}
		}

		function showModal(name, windowClass) {
			windowClass = windowClass || '';
			modalService.open({
				templateUrl : '../../../partials/modal_' + name + '.html',
				backdrop : 'static',
				windowClass : windowClass,
				scope : $scope
			});
		}

		function signLdw() {
			if (!vm.agreements[4].agree) {//Have changed me
				showModal('declineLdw', 'decline-ldw-modal');
			}
		}

		function showCorrectOrder(activeAgreementIndex) {
			if (vm.agreements[5] && vm.agreements[4]) {//Have changed here
				if (vm.activeAgreementIndex === 5 && !vm.agreements[4].agree && !vm.bpNotContingentOnLDW) {//Have changed here
					// console.log('LDW has been declined');
					vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
					//Have changed here
					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else if (vm.activeAgreementIndex === 6 && !vm.agreements[5].agree && !vm.bpNotContingentOnLDW && vm.agreements[4].agree !== 'DECLINED') {//Have changed here
					// console.log('BP is not signed');
					if (!vm.agreements[4].agree) {//Have changed here
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
						//Have changed here
					} else {
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[5].name.es : vm.agreements[5].name.en;
						//Have changed here
					}

					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}

		}

		function signAgreement(index, status) {
			void 0;
			
			if (showCorrectOrder(index)) {
				setAgreementViewed(index);	
				
				/*if(SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == "ENGLISH"){
					var sign = 'Signed via click-through AcceptanceNOW web portal.\n';
					sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
					sign += datesFactory.getCurrentDate();
				}else{
					var sign = 'Firmado vía virtual a través del sitio web AcceptanceNow.\n';
					sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
					sign += datesFactory.getCurrentDate();
				}*/
				vm.agreements[index].agree = status;
				//vm.agreements[index].signature = sign;

				//Commented as part of VAN-943
				/*if (vm.agreements[5] && vm.agreements[5].pdf !== undefined && vm.agreements[5].pdf !== null) {
					hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
				}*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				//Commented Code 
				/*if(vm.custClubStatus != "MEMBER"){
					hideBPWhenLDWisDeclined(vm.activeAgreementIndex)
				}
				if(vm.agreements[4].agree === 'SIGNED'){
					vm.enableBP = true;
				}*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				
				/*VAN-943-AWP-System not enabling BP option - Starts*/
				if(vm.agreements[4].agree != null && vm.agreements[4].agree === 'SIGNED'){
					hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
				}
				/*VAN-943-AWP-System not enabling BP option - Ends*/
			}

		}

		function hideBPWhenLDWisDeclined(index) {
			void 0;
			if (!vm.restrictions.bpNotContingentOnLdw && index === 4) {//Have changed here
				if (vm.agreements[4].agree === 'DECLINED') {//Have changed here
					void 0;
					vm.hideBp = true;
				} else {
					void 0;
					vm.hideBp = false;
					/*Commented Code*/
					/*vm.enableBP = true;*//*VAN-802 - VAN - If BP not selected show $49, if BP select do not show*/
				}
			}
			//Commented Code
			/*else{
				vm.enableBP = true;VAN-802 - VAN - If BP not selected show $49, if BP select do not show
			}*/
		}

		function declineRental() {
			//Story 29237 code changes starts
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.agreementStatus = 'Declined';
			updateEngagementItemFactory.putData(vm.engagement)
		          .then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement failed');
				modalService.dismiss();
			});
		}
		
		//Start - AC-13303 - to update agreementStatus in VAN
		function voidAgreement() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement with agreement status voided');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement for agreement status voided failed');
				modalService.dismiss();
			});
		}

		//End - AC-13303 - to update agreementStatus in VAN

		function declineBpNew() {
			if (vm.storeInfo.state == "FL") {
				showModal('declineBenefitsPlus');
			} else {
				showModal('benefitPlusDeclineConfirm');
			}
		}

		function declineBp(index) {
			void 0;
			if (vm.restrictions.dontSignDeclineBp) {
				showModal('declineBenefitsPlus');
			} else {
				/* The line below will reset the esign pop up check box always to none */
				vm.agreements[vm.activeAgreementIndex].eSign = false;
				showModal('eDeclineAgreement');
			}
		}

		function declineAgreement(index) {
			var sign = $translate.instant('agreement_label_declineDesc') +'\n';
			sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
			sign += datesFactory.getCurrentDate();

			vm.agreements[index].signature = sign;

			// console.log('decline', index);
			if (vm.agreements[index].agree === 'DECLINE') {
				if (vm.agreements[index].agree !== 1) {
					vm.agreements[index].agree = 'DECLINED';
					// setAgreementSignature('DECLINE');
				} else {
					showModal('declineLdw');
				}
				// Unsign BP if LDW is declined
				if (vm.agreements[index] === 4) {//Have changed here
					declineAgreement(5);
					//Have changed here
				}
			} else {
				vm.agreements[index].agree = 'DECLINED';
				// setAgreementSignature('DECLINE');
			}
		}

		function getCustomer(engagement) {
			$log.log('[Agreement.getCustomer] engagement: ', engagement);
			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				customer = response;
				vm.customer = response;
				$log.log('[Agreement.getCustomer] customer', customer);
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function setOrderDates(paymentDates) {
			var requestDate = datesFactory.getCurrentDate();
			void 0;
			_.forEach(paymentDates, function(paymentDate, i) {
				vm.orderData[i].requestDte = requestDate;
				vm.orderData[i].nextRenewDte = paymentDate.date;
			});
		}

		function setOrderDateAsPerSelection(selectedDate) {
			var requestDate = datesFactory.getCurrentDate();
			void 0;

			vm.orderDataForAdjustablePayment.requestDte = requestDate;
			vm.orderDataForAdjustablePayment.nextRenewDte = moment(selectedDate).format('YYYY-MM-DD HH:MM:SS');
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
			vm.orderDataForAdjustablePayment.promotionNextDueDate = vm.orderDataForAdjustablePayment.nextRenewDte;
			vm.promotionNextDueDate = moment(vm.orderDataForAdjustablePayment.promotionNextDueDate).format('MM/DD');
			if(vm.promotionNextDueDate === 'Invalid date'){//VAN-2379-AWP-Getting invalid date in initial payment popup screen - Starts
				vm.promotionNextDueDate = moment(selectedDate).format('MM/DD');
			}//VAN-2379-AWP-Getting invalid date in initial payment popup screen - Ends
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			if(vm.newPaymentMode){
				vm.showAddAmt = false;
			}else{
				calculateDiffDays(selectedDate);}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
		}
		
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
		function calculateDiffDays(selectedDate){
			var nextDuedate = moment(selectedDate);
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			if(vm.enableVariablePay){
				vm.diffDays = nextDuedate.diff(vm.ipMinDate, 'days')+1; 
			}else{
				var currentdate = moment(new Date());
				vm.diffDays = nextDuedate.diff(currentdate, 'days')+1; 
			}
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			if(vm.diffDays > 15 && !vm.enableVariablePay){
//				vm.additionalDays = vm.diffDays - 15;
				vm.showAddAmt = true;
			}else if(vm.diffDays > 0 && vm.enableVariablePay){//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
				vm.showAddAmt = true;
			}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			else{
				vm.showAddAmt = false;
			}
		}
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends

		function formatItemPrice(price) {
			var formattedPrice;
			if (String(price).indexOf('.') > -1) {
				price = String(price).split('.');
				if (price[1].length === 2) {
					formattedPrice = Number(price[0] + price[1]);
				} else {
					formattedPrice = Number(price[0] + price[1] + '0');
				}
			} else {
				formattedPrice = Number(price + '00');
			}
			return formattedPrice;
		}

		//checking if Associate password in case of max associate pin attempts
		function validateAssociatePasswordCash(pass) {
			var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
			if (auth === pass) {
				vm.employeePinError = false;
				vm.employeeId = '';
				showModal('agreementEmployeePin');
			} else {
				vm.invalidAssociatePass = true;
			}
		}

		//asking for Associate PIN if it manned store
		function checkStore() {
			/*VAN - 555 - Remove EMP PIN while create agreements for manned locations - Starts*/
			/*if (vm.storeInfo.isManned == true && (!$cookies.getObject('isHybrid'))) {
				showModal('agreementEmployeePin');
			} else {*/
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
			/*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos && vm.selectedPromo === 'AutoPayRequired' & vm.autopayAgree == 'Agreed' ) {
				showPaymentInitial(vm.selectedPromo);
			}
			else{*/
				setFirstPaymentDate();
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				//createAgreement();
				if(vm.restrictions.skipLdw && vm.restrictions.skipBp ){  /*vm.storeInfo.state == "CA" || vm.storeInfo.state == "WI"*/
					createAgreement(); //'CA' & 'WI' state direct create agr call even for van customer
				}
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
			/*}*/
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
			/*}*/
			/*VAN - 555 - Remove EMP PIN while create agreements for manned locations - Ends*/
		}
		
		//AC24649 Code changes starts
		function agreementCodeVerify() {
			if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
					($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
			void 0;
			showModal('ProcessingAgreement', 'processing-modal');
			$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
			agreementFactory.agreementVerification(vm.engagementId).then(
					function (response) {
						modalService.dismiss();
						$scope.textCode = response;
						var submitTextCodeModal = modalService.open({
							title: 'Confirm Email',
							templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
							scope: $scope,
							backdrop: 'static'
						});
					},
					// Error
					function (error) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(error);
						void 0;
					});
			}
			//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Same day-starts
			else if (!vm.isHTCustomer && (vm.customerFind.agreement.agreementStatus=='PENDING'|| vm.customerFind.agreement.agreementStatus=='ACTIVE') &&  
								vm.customerFind.VanEngagement.agreementStatus.toLowerCase() == 'pending' && vm.customerFind.isCoCustomerInfoProvided){
							
					vm.afterSigningConsent();
					
						}
			//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-same day-ends
			//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts
			else{
				verifySignerDetails();
				//beforeSigningModal();
			}

		}

		function verifySignerDetails(){
			if(vm.signer.customer == 'true' && vm.signer.coCustomer == 'true') {
				vm.signersPresent = 'BOTH';
				vm.signingPerson = {
						firstName : vm.customer.customerFirstName,
						lastName : vm.customer.customerLastName
				};
			} else if (vm.signer.coCustomer == 'true'){
				vm.signersPresent = 'COAPPLICANT';
				vm.signingPerson = {
						firstName : vm.customerFind.coCustomerInfo.firstName,
						lastName : vm.customerFind.coCustomerInfo.lastName
				};
			} else {
				vm.signersPresent = 'APPLICANT';
				vm.signingPerson = {
						firstName : vm.customer.customerFirstName,
						lastName : vm.customer.customerLastName
				};
			}
			beforeSigningModal();
		}
		//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends
		
		function reselectNextPaymentError() {
			modalService.open({
				templateUrl : 'partials/modal_confirmInitialPaymentInfo.html',
				backdrop : 'static',
				scope : $scope
			});
		}
		//AC24649 Code changes ends
		
		//checking for Associate PIN if entered or not
		function checkAssociatePin(pin) {
			if (pin) {
				setFirstPaymentDate();
				createAgreement();
			} else {
				$scope.noEmpPin = true;
				void 0;
			}
		}

		function setFirstPaymentDate() {
			if (vm.isAdjustablePayment) {
				var selectedDate = moment(vm.adjustableDateSelection).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				void 0;
			} else {
				var selectedDate = moment(vm.quoteDates[vm.firstPayment].date).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				void 0;
			}
		}

		//sending Associate PIN through post
		function setEmployeeId(employeeId) {
			SharedDataSvc.globalVars.agreementPrePut.empin = employeeId;
		}

		function processAgreementData() {
			if(vm.isHTCustomer){
				modalService.dismiss();
				return false;
			}

			if(vm.hideBp && vm.restrictions.skipLdw && vm.customerFind.VanEngagement.agreementHTId){
				vm.beforeSigning();
				return false;
			}

			//modalService.dismiss();
			if (vm.isAdjustablePayment) {
				setCustomerOrderDataForAdjustablePayment(vm.customerFind);
			} else {
				setCustomerOrderData(vm.customerFind);
			}

			//AC 25996 code changes			
		 if (isSameDayAgreement() && (vm.agreementHTID !== undefined && vm.agreementHTID !== null)) {
				void 0;
				handleSameDayAgreements();
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Starts
			else if (!isSameDayAgreement() && vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && vm.customerFind.agreement.agreementStatus === 'ACTIVE' && vm.customerFind.VanEngagement.agreementStatus === 'Pending'){
				void 0;
				//modalService.dismiss();
				handleSameDayAgreements();//VAN-1427-AWP - Should not create the new agreement on next day.
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Ends 
			else {
				//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
				setOrderDataForAdjustablePayment();
				vm.orderDataForAdjustablePayment.requestDte = datesFactory.getCurrentDate();
				vm.orderDataForAdjustablePayment.nextRenewDte = datesFactory.getCurrentDate();
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
					void 0;
					vm.cashPrice = response.cashPrice;
					showModal('langOption', 'modal-lang-option');
				},function(response) {
					void 0;
					modalService.dismiss();
				});
				//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			}
		}
		
		function showSameDayAgreementModal(){
			void 0;		
			var processingModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_sameDayAgree.html',
				backdrop: 'static'
			});
		}

		function createAgreement() {
			void 0;
			showModal('ProcessingAgreement', 'processing-modal');
			var agreementData = SharedDataSvc.globalVars.agreementPrePut,
			    prodCat,
			    extendedWarranty = {
				"racBrand" : "ACP",
				"itemModelNumber" : "ExtSvc",
				"racModelNumber" : "ESVPLAN-1",
				"depreciableLifeInMonths" : 24,
				"racRateCode" : "ESV396",
				"itemOriginalCost" : formatItemPrice(vm.orderContent.extendedServiceAmount)//AC21269 code change
			};

			// Set agreement data to send before creating the agreement
			void 0;
			agreementData.customer['id'] = vm.engagement.customerId;
			agreementData.customer.engagementId = Number(vm.engagementId);
			agreementData.customer.languagePreference = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference;
			agreementData.deliveryFee = formatItemPrice(vm.orderContent.deliveryFee);//AC23321 code change 
			agreementData.processingFee = (vm.processingFee * 100);
			agreementData.emailUnsignedAgreements = false;

			void 0;

			productFactory.getProductCategories().then(function(response) {
				prodCat = response.productCategories;

				void 0;
				_.forEach(vm.orderContent.orderItems, function(item, index) {
					var orderItem = {
						"racBrand" : "ACP", // 'ESV' for extended
						"itemModelNumber" : "", // 'ExtSvc' for extended
						"racModelNumber" : "",
						"depreciableLifeInMonths" : 24,
						"itemOptions" : "",
						"itemOriginalCost" : 0
					};
		
					orderItem.depreciableLifeInMonths = 24;
					orderItem.itemModelNumber = "";
					orderItem.itemOptions = productFactory.buildItemOptions(item);
					orderItem.itemOriginalCost = formatItemPrice(item.itemPrice);
					orderItem.racBrand = "ACP";
					orderItem.racModelNumber = productFactory.getRacModelNumber(prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
					void 0;
					agreementData.inventoryList.push(orderItem);
				});

				/*VAN-4050 AWP - show initial payment details before agreement creation  - order screen invoice reduction payment changes */

				if (vm.invoiceReductionPayment && vm.invoiceReductionPaymentAmount > 0) {
					var orderItem = {
						"racBrand": "IVR",
						"itemModelNumber": "",
						"racModelNumber": "HTIVR-DEPAMT",
						"depreciableLifeInMonths": 24,
						"itemOptions": "1",
						"itemOriginalCost": (vm.invoiceReductionPaymentAmount * 100)
					}
					orderItem.itemModelNumber = "";
					orderItem.racBrand = "IVR";
					orderItem.racModelNumber = "HTIVR-DEPAMT"
					orderItem.itemOptions = "1";
					orderItem.itemOriginalCost = (vm.invoiceReductionPaymentAmount * 100);
					orderItem.depreciableLifeInMonths = 24;
					void 0;
					agreementData.inventoryList.push(orderItem);
				}

				if (vm.orderContent.extendedServiceAmount > 0) {
					agreementData.inventoryList.push(extendedWarranty);
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
				if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos) {
					//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					for(var promo in vm.storeInfo.noCalcPromos)
					{
						if(vm.selectedPromo===vm.storeInfo.noCalcPromos[promo].code)
						{
					//if(vm.selectedPromo === 'AutoPayRequired'){
					if(vm.selectedPromo === 'H'){
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
						if(vm.showAddAmt){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.promoOneQuote.initialRaPaymentTotal).toFixed(2);
							}else{//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							agreementData.selectedPromoOptions.payment = (vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial).toFixed(2);}
						}else{
							agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
						}
					//	}else if(vm.selectedPromo === 'WithoutAutoPay'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
					}else if(vm.selectedPromo === 'J'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
						if(vm.showAddAmt){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.promoTwoQuote.initialRaPaymentTotal).toFixed(2);
							}else{//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							agreementData.selectedPromoOptions.payment = (vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial).toFixed(2);}
							//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
							}else{
								agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
							}
						}else if(vm.selectedPromo === 'Q'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
								agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
								if(vm.showAddAmt){
									if(vm.restrictions.skipBp){
										agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal).toFixed(2);
									}else{
										agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial).toFixed(2);}
								}else{
									agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
								}
							}
						//VAN-3623-AWP - set promocode configurable-Starts
						else if(vm.selectedPromo != 'Pif' && vm.selectedPromo != 'payAdditionalAmount'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
							agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal).toFixed(2);
								}else{
									agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial).toFixed(2);}
							}else{
								agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
							}
						}
						//VAN-3623-AWP - set promocode configurable-Ends
						//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
						}
					}
			//}else if(vm.selectedPromo === 'Pif'){
			  if(vm.selectedPromo === 'Pif'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
						var pifPromo = {
								"code": "D",
								"desc": "$0 START PIF",
								"payment": "0"
						};
						agreementData.selectedPromoOptions = pifPromo;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
						}else{
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
						}
					}//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
//					VAN-2841-AWP-Value City $1 Promo - Starts
				//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
				/*	else if(vm.selectedPromo === 'vcPromo'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[0];
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial).toFixed(2);}
						}else{
							agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[0].payment;
						}
					}*/
				//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
//					VAN-2841-AWP-Value City $1 Promo - Ends
					//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
					else if(vm.selectedPromo === 'payAdditionalAmount'){
						var promoInfo = {
								"code": "",
								"desc": "",
								"payment": $scope.amount
						};
						agreementData.varInitPmtFunc = null;
						agreementData.varInitPmtNextDueDate = SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate;
						$scope.varAmt = null;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								agreementData.varInitPmtAmt = (vm.variablePayQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								agreementData.varInitPmtAmt = (vm.variablePayQuote.initialRaPaymentTotal + vm.variablePayQuote.bplusAmountInitial + vm.variablePayQuote.bplusTaxInitial).toFixed(2);
							}
							promoInfo.payment = agreementData.varInitPmtAmt;
							agreementData.varInitPmtAmt = agreementData.varInitPmtAmt.replace('.','');
						}else{
							agreementData.varInitPmtAmt = 	$scope.amount.replace('.','');
						}
						agreementData.selectedPromoOptions = promoInfo;
					}
					//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
				$log.log('[Agreement.createAgreement] PRE CREATE AGREEMENT', agreementData);
				
				/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
				 /* VAN-858 - Quad Weekly Payment Frequency*/ 
				if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.paymentModeEnabled!=undefined){
					if(vm.paymentSelected == "4 Weeks"){
						agreementData.paymentMode = "Fourweek";
					}
					else{
					agreementData.paymentMode = vm.paymentSelected;
					}
				}else{
					agreementData.paymentMode = "MONTHLY";
				}
				/*VAN-774 - Biweekly renewal payments Long Term - Ends*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				if(vm.agreements[4]){
					if(vm.agreements[4].agree === 'SIGNED'){
						agreementData.waiverStatus = 'ACCEPTED'; //waiverStatus - needs to be changed
					}else{
						agreementData.waiverStatus = 'DECLINED';
					}
				}else if(vm.agreements[4]==undefined){
					agreementData.waiverStatus = 'DECLINED';
				}
				
				if(vm.agreements[5]){
					if(vm.agreements[5].agree === 'SIGNED'){
						agreementData.clubActivate = true;
						$cookies.getObject('clubActivate');
						/*$rootScope.hideBPOnReenagage = true; *///should not show BP on second time
					}else{
						agreementData.clubActivate = false;
						$cookies.getObject('clubActivate');
						/*$rootScope.hideBPOnReenagage = false;*/
					}
				}else if(vm.agreements[5]==undefined || vm.restrictions.skipBp){
					agreementData.clubActivate = false;
					$cookies.getObject('clubActivate');
				}
				
				if($cookies.getObject('clubActivate')){
					if(agreementData.clubActivate==true){
						$cookies.putObject('clubActivate',true);
					}else{
						$cookies.putObject('clubActivate',false);
					}
				}
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				agreementData.agreementOriginator = 'AWP';//VAN-2643-AWP/Kiosk-Update Agreement Originator
				/*VAN-4050 AWP - show initial payment details before agreement creation  - order screen invoice reduction payment changes */
								
				if(vm.invoiceReductionPayment) {
					agreementData.invoiceReductionPaymentAmount = (vm.invoiceReductionPaymentAmount*100);
				}
				void 0;
				agreementFactory.createAgreement(agreementData, keys).then(function(response) {
					response = JSON.parse(response);
					$log.log('[Agreement.createAgreement] AGREEMENT DATA', response);
					vm.agreementData = response;
					if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
							($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
						void 0;
						$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
						agreementFactory.agreementVerification(vm.engagementId).then(
								function (response) {
									modalService.dismiss();
									$scope.textCode = response;
									var submitTextCodeModal = modalService.open({
										title: 'Confirm Email',
										templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
										scope: $scope,
										backdrop: 'static'
									});
								},
								// Error
								function (error) {
									// sending error to Raygun
									SharedDataSvc.reportRaygun(error);
									void 0;
								});
					}else{
						modalService.dismiss();
						//VAN-2398-Prompt for CA stores about unsigned documents - Starts
						if($rootScope.state === 'CA'){
							vm.unSignedEmailModal();
						}else{//VAN-2398-Prompt for CA stores about unsigned documents - Ends
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
						if(vm.hasCoCustomer){ //PR-State
							var coCustomerSigningModal = modalService.open({
								scope: $scope,
								windowClass: '',
								templateUrl: 'partials/modal_coCustomerSigning.html',
								backdrop: 'static'
							});
							coCustomerSigningModal.result.then(function(){
								vm.checkSigner();
							});
						}else{
							if(!vm.hideBp || !vm.restrictions.skipLdw){
								beforeSigningModal();
							}
						}
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
						if(vm.hideBp && vm.restrictions.skipLdw){
							vm.beforeSigning();
							return false;
						}
						//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
					}
				}//VAN-2398-Prompt for CA stores about unsigned documents
					setPdfData(response.documentsLst);
				}).catch(function(response) {
					modalService.dismiss();
					// reset inventory
					SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];
					
					/* Same day agreement scenario check */
					if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
						showSameDayAgreementModal();
						return;
					}

					//time out scenario, record Locked scenario 409 and brand model not found scenario
					if (response.status === 502 || response.data.errorData.status_code === "ENG-A4001-409" || response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {

						/* Showing modal only for one scenario. Rest all are handled in service-agent.js */
						if (response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {
							showModal('agreementError');
						}
						$log.log('[Agreement.createAgreement] could not create agreements', response);
					}else {
						$log.log('Error processing create agreement', response);
						modalService.dismiss();
						//var status_details = JSON.parse(response.data.errorData.status_details);
						void 0;
						//console.log('STATUS', status_details);
						//error handling when PIN is incorrect
						if (response.data.errorData.status_message.indexOf('Employee pin is not valid') > -1) {
							vm.pinAttempts = vm.pinAttempts + 1;
							vm.initialPaymentStatus = false;
							vm.initialPaymentErrorClass = '';
							vm.employeeId = '';
							void 0;
							if (vm.pinAttempts === 3) {
								vm.pinAttempts = 0;
								vm.invalidAssociatePass = false;
								showModal('tooManyPinAttemptsAgreement');
							} else {
								vm.employeePinError = true;
								showModal('agreementEmployeePin');
							}
						} else {
							vm.initialPaymentStatus = true;
							vm.initialPaymentErrorClass = 'alert-danger';
							vm.initialPaymentMessage = 'Payment Failed';
						}
					}
				});
			});
		}

		function submitTextCode(){
			$scope.verifyAgreement.textCodeError = false;
			$scope.verifyAgreement.textCodeAttemptsNumber += 1;
			if($scope.textCode == $scope.verifyAgreement.textCode && $scope.verifyAgreement.textCodeAttemptsNumber <= 3){
				modalService.dismiss();
				//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
			}else if($scope.verifyAgreement.textCodeAttemptsNumber == 3){
				$scope.verifyAgreement.textCodeAttemptsNumber = 0;
				$scope.verifyAgreement.textCode = '';
				navigateTo('dashboard');
			}
			else{
				$scope.verifyAgreement.textCodeError = true;
				$scope.verifyAgreement.textCode = '';
			}
		}

		function handleBpForAllAgreements() {
			if (vm.agreementData && vm.agreementData.documentsLst[6]) {
				$log.log('new agreements');
				vm.hideBp = false;
			} else {
				$log.log('old agreements');
				vm.hideBp = true;
			}
		}

		function isAllAgreementsSigned() {
			if(vm.isHTCustomer){
				return true;
			}
			//If LDW and BP is there
			if (vm.agreements[4] && vm.agreements[5]) {

				//If LDW is signed
				 /*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/ //Active Agr's with BP agreed should not show BP again for another agr
				//Commented Code
				/*if(vm.agreements[4].agree === "SIGNED" && vm.agreements[5].agree === undefined){
					return true;
				}*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				if (vm.agreements[4].agree === "SIGNED") {

					//If BP is signed or declined
					if (vm.agreements[5].agree === "SIGNED" || vm.agreements[5].agree === "DECLINED") {
						return true;
					} else {
						return false;
					}

				} else {

					//If LDW is declined
					if (vm.agreements[4].agree === "DECLINED") {
						return true;
					} else {
						return false;
					}

				}
			} else {

				//If LDW and BP is not there
				if (!vm.agreements[4] && !vm.agreements[5]) {
					return true;
				} else {

					// If LDW is there and BP is not there
					if (vm.agreements[4] && !vm.agreements[5]) {

						//If LDW is either signed or declined
						if (vm.agreements[4].agree == 'SIGNED' || vm.agreements[4].agree == 'DECLINED') {
							return true;
						} else {
							return false;
						}

					} else {

						//If BP is either signed or declined
						if (vm.agreements[5].agree == 'SIGNED' || vm.agreements[5].agree == 'DECLINED') {
							return true;
						} else {
							return false;
						}

					}
				}
			}
		}

		//VAN-510 - Electronic Consent moved to app flow - Starts
		//Checkbox enable/disable for electronic consent
		$scope.eConsentAgreefnc = function () {
			if ($scope.eConsentAgree) {
				return true;
			} else {
				return false;
			}
		};

		//Show electronic Consent at Start Application Flow
		function newCustomerElectronicConsent(){
			void 0;
			/*VAN 719 Make consent form required to read -  Starts*/
			var myModalInstance = modalService.open({
				templateUrl: 'partials/modal_eAgreementNotice.html',
				/*show: true,*/
				backdrop: 'static',
				scope: $scope,
			});
			/*VAN 719 Make consent form required to read -  Ends*/
		}

		function beforeSigning(){
			if (isAllAgreementsSigned()) {
				if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "Yes" && !vm.customerFind.isCoCustomerInfoProvided){
					vm.checkSigner();
				}
				else if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT" && !vm.customerFind.isCoCustomerInfoProvided)
				{
					$rootScope.showConsent = true;
					vm.askForSigningConsent();
				}
				else if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT" && vm.customerFind.isCoCustomerInfoProvided){
					$rootScope.showConsentForHT = true;
					vm.afterSigningConsent();}
				else if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "Yes" && vm.customerFind.isCoCustomerInfoProvided){
					vm.afterSigningConsent();
					// vm.checkSigner(); //PR-State
				}
				else{
					vm.checkSigner();
				}
			//VAN-510 - Electronic Consent moved to app flow - Ends
			} else {
				$log.log('unsigned agreements');
				modalService.dismiss();

				showModal('unsignedAgreements', 'unsigned-agreements-modal');
			}
		}

		function askForSigningConsent(){
			showModal('eAgreementNotice');
			return false;
		}

		function afterSigningConsent(){
			
			if(vm.hasCoCustomer){
				vm.signer= {
					customer : false,
					coCustomer : false
				};
				//VAN-1106 Customer with correnter unable to create docusign when agreement created from HT (HT originator)
			//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Next day-Starts
				var signerStatus = vm.customerFind.VanEngagement.signerStatus ? 
						JSON.parse(vm.customerFind.VanEngagement.signerStatus).signerStatus : '';
					var isAgreementPending = _.filter(signerStatus, {signingStatus :"Pending"});
					//VAN-1428-AWP - Should not create the new agreement on next day.
				if(isSameDayAgreement()){
					if(isAgreementPending.length > 0){
					if (!vm.isHTCustomer && vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && (vm.customerFind.agreement.agreementStatus === 'ACTIVE'|| vm.customerFind.agreement.agreementStatus === 'PENDING') && vm.customerFind.VanEngagement.agreementStatus === 'Pending' &&  isAgreementPending[0].customerType.toLowerCase() == 'coapplicant' )
					{
						vm.afterSigning(); 
						}
					else{
						var coCustomerSigningModal = modalService.open({
						scope: $scope,
						windowClass: '',
						templateUrl: 'partials/modal_coCustomerSigning.html',
						backdrop: 'static'
					});
					coCustomerSigningModal.result.then(function(){
						vm.checkSigner();
					});
				}
				}
				//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-next day-ends
				else if(!vm.isHTCustomer && vm.customerFind.agreement == undefined)
					{
						createAgreement();
					}
				else{
					var coCustomerSigningModal = modalService.open({
					scope: $scope,
					windowClass: '',
					templateUrl: 'partials/modal_coCustomerSigning.html',
					backdrop: 'static'
				});
				coCustomerSigningModal.result.then(function(){
					vm.checkSigner();
				});
			}
				 }
				
				else if(!isSameDayAgreement())
					{
					if (!vm.isHTCustomer && vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && vm.customerFind.agreement.agreementStatus === 'ACTIVE'  && vm.customerFind.VanEngagement.agreementStatus === 'Pending' &&  isAgreementPending[0].customerType.toLowerCase() == 'coapplicant' )
					{
						vm.afterSigning(); 
						}
				//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-next day-ends
				else if(!vm.isHTCustomer)
					{
						createAgreement();
					}
				else{
					var coCustomerSigningModal = modalService.open({
					scope: $scope,
					windowClass: '',
					templateUrl: 'partials/modal_coCustomerSigning.html',
					backdrop: 'static'
				});
				coCustomerSigningModal.result.then(function(){
					vm.checkSigner();
				});
			} }
			}	//VAN-1428-AWP - Should not create the new agreement on next day.	
			 else if(vm.signersPresent && vm.signingPerson) {
				vm.afterSigning();
			}else {
				vm.signer.customer = true;
				vm.checkSigner();
			}
		}

		function afterSigning(){
			var afterSigningModal = modalService.open({
				scope: $scope,
				windowClass: 'smallModals',
				templateUrl: 'partials/modal_afterDocusignSigning.html',
				backdrop: 'static'
			});
			afterSigningModal.result.then(function(){
				vm.submitAgreements();
			});			
		}

		function checkAndNavigate(){
			modalService.dismiss();
			if(vm.isHTCustomer){
				vm.navigateTo('dashboard');
			} 
		}

		function notYetSigning(){
			var notYetSigningModal = modalService.open({
				scope: $scope,
				windowClass: 'smallModals',
				templateUrl: 'partials/modal_notYetSigning.html',
				backdrop: 'static'
			});
		}

		function checkSigner(){
			if(vm.signer.customer == 'true' && vm.signer.coCustomer == 'true') {
				vm.signersPresent = 'BOTH';
				vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
				};
			} else if (vm.signer.coCustomer == 'true'){
				vm.signersPresent = 'COAPPLICANT';
				vm.signingPerson = {
					firstName : vm.customerFind.coCustomerInfo.firstName,
					lastName : vm.customerFind.coCustomerInfo.lastName
				};
			} else {
				vm.signersPresent = 'APPLICANT';
				vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
				};
			}
			//VAN-1427-AWP - Getting Error 700 while submitting agreement on next day.
			if (!vm.isHTCustomer && vm.customerFind.agreement != undefined && vm.customerFind.agreement.agreementStatus=='ACTIVE' &&  vm.customerFind.VanEngagement.agreementStatus.toLowerCase() == 'pending' && vm.customerFind.isCoCustomerInfoProvided == false && !isSameDayAgreement() && !vm.restrictions.skipLdw && !vm.restrictions.skipBp){
								verifySignerDetails(); }		
			//VAN-1427-AWP - Getting Error 700 while submitting agreement on next day.
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
			else if(!vm.isHTCustomer && !vm.restrictions.skipLdw && !vm.restrictions.skipBp){
				createAgreement(); 
			}else if(!vm.isHTCustomer && vm.restrictions.skipLdw && vm.restrictions.skipBp){
				beforeSigningModal();
			}else{
				vm.submitAgreements();//HT Customers, Docusign URl should be called directly
			}
				/*var beforeSigningModal = modalService.open({
					scope: $scope,
					windowClass: 'smallModals',
					templateUrl: 'partials/modal_beforeDocusignSigning.html',
					backdrop: 'static'
				});
				beforeSigningModal.result.then(function(){
					vm.submitAgreements();
				});*/
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
		}
		
		/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/	
		function beforeSigningModal(){
			var beforeSigningModal = modalService.open({
				scope: $scope,
				windowClass: 'smallModals',
				templateUrl: 'partials/modal_beforeDocusignSigning.html',
				backdrop: 'static'
			});
			beforeSigningModal.result.then(function(){
				vm.submitAgreements();
			});
		}
		/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
		
	//VAN-510 - Electronic Consent moved to app flow - Starts
	function checkSignerDetails(){
		if(vm.signer.customer == 'true' && vm.signer.coCustomer == 'true') {
			vm.signersPresent = 'BOTH';
			vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
			};
		} else if (vm.signer.coCustomer == 'true'){
			vm.signersPresent = 'COAPPLICANT';
			vm.signingPerson = {
					firstName : vm.customerFind.coCustomerInfo.firstName,
					lastName : vm.customerFind.coCustomerInfo.lastName
			};
		} else {
			vm.signersPresent = 'APPLICANT';
			vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
			};
		}
		if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
			vm.updateCustomerConstent();
		}else{
			vm.submitAgreements();
		}
	}
	
	function exitHTConsent(){
		void 0;
		var emailTextModal = $modal.open({
			templateUrl: 'partials/modal_exitHTConsent.tpl.html',
			scope: $scope,
			backdrop: 'static'
		});
		
	}
	//VAN-510 - Electronic Consent moved to app flow - Ends
	
	function enableSigning(){
			$timeout(function(){
				if((!vm.signer.customer || !vm.signer.customer=='false') 
					&& (!vm.signer.coCustomer || !vm.signer.coCustomer=='false')){
					vm.enableSigningflag = false;
				} else {
					vm.enableSigningflag = true;
				}
			},1000);
		}
	
	//Start - VAN-510 - Electronic Consent moved to app flow
	function updateCustomerConstent(){
		if(vm.customerFind.VanEngagement.customer){
			var customerData = {
					firstName: vm.customerFind.VanEngagement.customer.fName,
					lastName: vm.customerFind.VanEngagement.customer.lName,
					phoneNumber: vm.customerFind.VanEngagement.customer.phNum,
					isTcpaConstentFlg: $scope.consent.istcpaflag  //VAN-4181 HT_Consent Text is not appearing as expected
			};
			
			applicationService.updateCustomerConsent(customerData)
			.then(function(response) {
				modalService.dismiss();
				if(response.status == '200' ){
					void 0;
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer).- Starts*/ 
					if(!vm.isHTCustomer && !vm.hasCoCustomer){
						createAgreement();
					}else{
						vm.submitAgreements();
					}
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer). - Ends*/
				}
			}, function(response){
				void 0;
				modalService.dismiss();
			});
		}
	}
	//End - VAN-510 - Electronic Consent moved to app flow

		function submitAgreements() {
			var agree;
		
			showModal('ProcessingAgreement', 'processing-modal');
			var promise = setCalcPaymentSpec();
			setSignatureData();

			promise.then(function() {
				
				var storedSessionId = $cookies.getObject("okta_session_id");
				//VAN-382 PRB0042677 Ess service failing with 500 internal server error - added co-applicant details
				var payload = {
		              "customerId" : vm.engagement.customerId,     
		              "engagementId" : vm.engagementId,  
		              "signersPresent" : vm.signersPresent,
		              /*VAN- If BP not selected show $49, if BP select do not show*/
		              /*"clubSwitch" : SharedDataSvc.globalVars.paymentCalcSetup.clubSwitch,   
		              "waiverSwitch" : SharedDataSvc.globalVars.paymentCalcSetup.waiverSwitch,*/
		              "paymentToDate" : SharedDataSvc.globalVars.paymentCalcSetup.paymentToDate,
		              "coapplicantFirstName" : vm.customerFind.coCustomerInfo? vm.customerFind.coCustomerInfo.firstName:null,
		              "coapplicantLastName" : vm.customerFind.coCustomerInfo? vm.customerFind.coCustomerInfo.lastName:null
		            
		         };
				
		        if(vm.isCoCustomerSignerPending){
					delete payload.clubSwitch;
					delete payload.waiverSwitch;
					delete payload.paymentToDate;
				}

				var domain = $window.location;
				var destination = domain.hash.split('/')[1];

				payload.returnURL = domain.origin + '/#!/login' + 
									"?Session=" + storedSessionId + "&engagementId=" + vm.engagementId;
				void 0;
				
				if(vm.signersPresent == 'BOTH'){
					$cookies.putObject("isCoCustomerSignerPending", true);
				} else {
					$cookies.putObject("isCoCustomerSignerPending", null);
				}
				
				if(vm.isHTCustomer){
					delete payload.clubSwitch;
					delete payload.waiverSwitch;
					delete payload.paymentToDate;
					$cookies.putObject("isHTCustomer", true);
				}

				if(vm.hideBp){
					delete payload.clubSwitch;
				}
				
				void 0;
				$cookies.putObject("navigatingToDocusign", true);
				
				paymentFactory.docuSign(payload).then(function(response) {
					$window.location.href = response.signatureURL;
				}, function(error) {
					showModal('agreementError');
					$cookies.putObject("navigatingToDocusign", false);
					$cookies.putObject("isCoCustomerSignerPending", null);
					//$location.url('dashboard');
					void 0;
				});
			});

		}

		function setPdfData(documentsLst) {
			// console.log('set PDF data');
			/*VAN-802 If BP not selected show $49, if BP select do not show*/  //shuold not hide BP when its declined
			/*vm.hideBp = true;*/
			vm.showWarrantyAgreement = false;
			vm.showDelWarrantyAgreement = false;

			vm.agreements[1].pdf = [];
			vm.agreements[1].type = [];

			_.forEach(documentsLst, function(agreement) {
				// console.log('DOCUMENTSLIST', agreement);
				if (agreement.documentType === 'RA') {
					vm.agreements[0].pdf = agreement.data;
				} else if (agreement.documentType === 'ACK') {
					vm.agreements[2].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'EPO90') {
					vm.agreements[3].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'LDW') {
					vm.agreements[4].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'ARB') {
					vm.agreements[6].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'FEE') {
					vm.showWarrantyAgreement = true;
					vm.agreements[7].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'dlf') {
					vm.showDelWarrantyAgreement = true;
					vm.agreements[8].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CLB') {
					vm.hideBp = false;
					vm.agreements[5].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CAM' || agreement.documentType === 'COP' || agreement.documentType === 'CRB' || agreement.documentType === 'CYC' || agreement.documentType === 'GAS' || agreement.documentType === 'LCD' || agreement.documentType === 'RAN' || agreement.documentType === 'SOC' || agreement.documentType === 'VID') {
					vm.agreements[1].pdf.push(agreement.data);
					vm.agreements[1].type.push(agreement.documentType);
					vm.showSupp = true;
				}

			});

			if (vm.agreements[7].pdf == undefined) {
				delete vm.agreements[7];
			}

			if (vm.agreements[8].pdf == undefined) {
				delete vm.agreements[8];
			}

			void 0;

			if (vm.hideBp && vm.agreements[5]) {
				vm.agreements[5].agree = 'SIGNED';
				//Have changed here
				vm.agreements[5].viewed = true;
				//Have changed here
				void 0;
			}
			void 0;
		}

		function setAgreementViewed(index) {
			vm.agreements[index].viewed = true;
		}

		function setAgreementUnviewed(index) {
			vm.agreements[index].viewed = false;
		}

		function hasAgreementBeenViewed(index) {
			return vm.agreements[index].viewed;
		}

		function setAgreementSignature(status) {
			
			if(SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == "ENGLISH"){
				var sign = 'Signed via click-through AcceptanceNOW web portal.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}else{
				var sign = 'Firmado vía virtual a través del sitio web AcceptanceNow.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}
			
			

			void 0;
			void 0;
			vm.agreements[vm.activeAgreementIndex].agree = status;
			vm.agreements[vm.activeAgreementIndex].signature = sign;

			void 0;
			void 0;
			//AC13194 Code changes start
			if (vm.agreements[5] && vm.agreements[5].pdf !== undefined && vm.agreements[5].pdf !== null) {
				hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
			}
			//AC13194 Code changes end
		}

		function setSignatureData() {
			var signatureData = SharedDataSvc.globalVars.signatureTemplate;

			signatureData.customerId = vm.engagement.customerId;
			signatureData.engagementId = Number(vm.engagementId);
			signatureData.language = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference.toLowerCase();

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here

				var ldwSignObj = {
					"formSwitch" : true,
					"signature" : vm.agreements[4].signature,
					"docType" : vm.agreements[4].type
				};

			} else {

				if (vm.agreements[4]) {
					var ldwSignObj = {
						"formSwitch" : false,
						"signature" : vm.agreements[4].signature,
						"docType" : vm.agreements[4].type
					};

				}

			}
			if (ldwSignObj !== undefined) {
				signatureData.signatures.push(ldwSignObj);
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED') {//Have changed here

				var bPlusObj = {
					"formSwitch" : true,
					"frequency" : "MONTHLY",
					"signature" : "",
					"docType" : vm.agreements[5].type
				};

			} else {

				if (vm.agreements[5]) {

					var bPlusObj = {
						"formSwitch" : false,
						"frequency" : "MONTHLY",
						"signature" : "",
						"docType" : vm.agreements[5].type
					};

				}

			}

			if (!vm.hideBp && vm.agreements[5]) {

				bPlusObj.signature = vm.agreements[5].signature;

			} else {

				if (vm.agreements[5]) {

					vm.agreements[5].agree = "";
					//Have changed here
					bPlusObj = undefined;

				}

			}
			if (bPlusObj !== undefined) {
				signatureData.signatures.push(bPlusObj);
			}

			SharedDataSvc.globalVars.signatureTemplate = signatureData;

		}

		// TODO - need to add processing loading modal
		function setCalcPaymentSpec() {
			void 0;

			var deferred = $q.defer();

			var paymentSpec = SharedDataSvc.globalVars.paymentCalcSetup;

			setFirstPaymentDate();
			paymentSpec.customerEngagementId = Number(vm.engagementId);
			paymentSpec.id = vm.engagement.customerHtStoreId;

			paymentSpec.customerId = vm.engagement.customerId;
			paymentSpec.clubSwitch = 'N';
			paymentSpec.waiverSwitch = 'DECLINE';
			paymentSpec.hoCustId = vm.customer.customerHtId;

			if (vm.agreementData !== undefined) {//AC 25996 code changes
				paymentSpec.agreementNumber = vm.agreementData.agreement.raNumber;
				paymentSpec.paymentToDate = SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate;
			} else {
				paymentSpec.agreementNumber = vm.customerFind.VanEngagement.agreementHTId;
				paymentSpec.paymentToDate = vm.customerFind.VanEngagement.nextPaymentDate;
			}

			// console.log('waiver switch', vm.agreements[1].agree);
			// console.log('club switch', vm.agreements[2].agree);

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here
				paymentSpec.waiverSwitch = 'ACCEPT';
			} else {
				paymentSpec.waiverSwitch = 'DECLINE';
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED' && !vm.hideBp) {//Have changed here
				paymentSpec.clubSwitch = 'Y';
			} else if (vm.agreements[5] && vm.agreements[5].agree === 'DECLINED') {
				paymentSpec.clubSwitch = 'N';
			} else {
				paymentSpec.clubSwitch = 'N';
			}

			SharedDataSvc.globalVars.paymentCalcSetup = paymentSpec;

			if (SharedDataSvc.globalVars.paymentCalcSetup && SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate) {
				deferred.resolve("PaymentCalcSetup and initialPaymentDate has been set");
			} else {
				deferred.reject("PaymentCalcSetup or intialPaymentDate is not set yet");
			}

			return deferred.promise;

		}

		function setAgreementStatus(status) {
			vm.engagement.agreementStatus = status;
		}

		function declineConsent() {
			showModal('ProcessingAgreement', 'processing-modal');
			setAgreementStatus('Declined');
			void 0;
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
				}, function(response) {
				$log.log('Updating engagement failed');
			});
		}
		
		function updateEngagement() {
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
			}, function(response) {
				$log.log('Updating engagement failed');
			});
		}

		function formatAgreementDate(date) {
			var year = String(date).substring(0, 4),
			    month = String(date).substring(4, 6),
			    day = String(date).substring(6, 8);

			// 20140825
			void 0;
			return month + '-' + day + '-' + year;
		}

		function isSameDayAgreement() {
			var today = datesFactory.getCurrentDate(),
			    agreementsExist = vm.customerFind.agreement ? true : false,
			    agreementDate = vm.customerFind.agreement ? formatAgreementDate(vm.customerFind.agreement.agreementDate) : false,
			    isSameDay = moment(agreementDate).isSame(today, 'day') ? true : false;
			// Set line below to today to check for same day agreements
			// isSameDay = moment('4-27-2015').isSame(today, 'day') ? true : false;

			return isSameDay;
		}

		function handleSameDayAgreements() {
			vm.agreementDataForClauseUse = vm.customerFind.agreement;
			//VAN-1427-AWP - Should not create the new agreement on next day.
			if (vm.customerFind.agreement  /*&& isSameDayAgreement()*/ && (vm.agreementHTID !== undefined && vm.agreementHTId !== null)) {//AC 25996 code changes
				//Start - AC13303 - Error 700 on submit agreements
				if (vm.customerFind.agreement && (vm.customerFind.agreement.agreementStatus === "INACTIVE")) {
					vm.agreementStatus = vm.customerFind.agreement.agreementStatus;
					var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
					//AC-28614 Changes
					//Commented out the Active check since customers will be allowed to docusign after payment complete in HT
//					if (vm.customerFind.agreement.agreementStatus === "ACTIVE") {
//						$rootScope.modalMessage = $translate.instant('agreement_label_errorReceiveAgreement') + servicePhoneNumber + '. [99]';
//					} else {
						$rootScope.modalMessage = $translate.instant('agreement_label_errorDeclineAgreement') + ' [44]';
//					}
					modalService.open({
						templateUrl : 'partials/modal_agreementStatusNotinPending.html',
						backdrop : 'static',
						scope : $scope
					});
					return false;
				} else {
					//End - AC13303 - Error 700 on submit agreements
					var agreements = vm.customerFind.agreement;
					vm.agreements[1].pdf = [];
					vm.agreements[1].type = [];

					void 0;

					for (var key in agreements) {

						if (key === 'UnsignedCAM' || key === 'UnsignedCOP' || key === 'UnsignedCRB' || key === 'CYC' || key === 'UnsignedGAS' || key === 'UnsignedLCD' || key === 'UnsignedRAN' || key === 'UnsignedSOC' || key === 'UnsignedVID') {

							vm.agreements[1].pdf.push(agreements[key]);
							var keyForSigning = key.replace("Unsigned", "");
							vm.agreements[1].type.push(keyForSigning);
							vm.showSupp = true;

						}

					}
					//  vm.agreements[1].pdf = 'data:application/pdf;base64,' + escape(agreements.UnsignedSAC);
					vm.agreements[2].pdf = agreements.UnsignedRAAck;
					//Have changed here
					vm.agreements[0].pdf = agreements.UnsignedRA;

					if (agreements.EpoAmendment) {
						vm.agreements[3].pdf = agreements.EpoAmendment;
						//Have changed here
					}

					if (agreements.UnsignedLDW) {
						vm.agreements[4].pdf = agreements.UnsignedLDW;
						//Have changed here
					}

					if (agreements.CLB) {
						vm.agreements[5].pdf = agreements.CLB;
						//Have changed here
					}

					vm.agreements[6].pdf = agreements.UnsignedARB;
					//Added a new line here

					if (agreements.UnsignedFEE) {
						vm.agreements[7].pdf = agreements.UnsignedFEE;
						vm.showWarrantyAgreement = true;
					} else {
						delete vm.agreements[7];
					}

					if (agreements.UnsignedDLF) {
						vm.agreements[8].pdf = agreements.UnsignedDLF;
						vm.showDelWarrantyAgreement = true;
					} else {
						delete vm.agreements[8];
					}
				} //AC13303 - Error 700 on submit agreements
				//AC24649 Code changes starts
				if(vm.isAdjustablePayment){
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
					} else {
						vm.languagePreference = false;
					}
					var requestDate = datesFactory.getCurrentDate();				
					vm.orderDataForAdjustablePayment.requestDte = requestDate;
					vm.orderDataForAdjustablePayment.nextRenewDte = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("YYYY-MM-DD HH:mm:ss");
					//vm.orderDataForAdjustablePayment.nextRenewDte = "2016-09-16 23:59:59";
					setOrderDataForAdjustablePayment();
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
					if(vm.paymentSelected == "4 Weeks"){
						vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
					}else{
						vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;}
					vm.orderDataForAdjustablePayment.promotionCode = vm.promoSelectedAtAgreement.code;
					vm.orderDataForAdjustablePayment.promotionNextDueDate = vm.orderDataForAdjustablePayment.nextRenewDte;
					vm.promotionNextDueDate = moment(vm.orderDataForAdjustablePayment.promotionNextDueDate).format('MM/DD');
					calculateDiffDays(vm.orderDataForAdjustablePayment.nextRenewDte);
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
					if(vm.promoSelectedAtAgreement.desc != ""){//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
						void 0;
						vm.quoteGotAsPerSelection = response;
						vm.adjustableDateSelection = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("MMM DD, YYYY");
						if(vm.showAddAmt){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
							if(vm.promoSelectedAtAgreement.code === "H"){
								//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - 49).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 49).toFixed(2);}
							}else if(vm.promoSelectedAtAgreement.code === "J"){
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - 99).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 99).toFixed(2);}
							}else if(vm.promoSelectedAtAgreement.code === "D"){	
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal).toFixed(2);;
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 0).toFixed(2);}
							}else if(vm.promoSelectedAtAgreement.code === "Q"){//VAN-2841-AWP-Value City $1 Promo - Starts
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - 1).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 1).toFixed(2);}
							}
							else if(vm.promoSelectedAtAgreement.code != "H" && vm.promoSelectedAtAgreement.code != "J" && vm.promoSelectedAtAgreement.code != "Q" && vm.promoSelectedAtAgreement.code != "D"){//VAN-2841-AWP-Value City $1 Promo - Starts
								if(vm.promoSelectedAtAgreement.desc!=undefined){
									var addAmount = (vm.promoSelectedAtAgreement.desc).substring(1,3);
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - addAmount).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - addAmount).toFixed(2);
									}
								}
							}
							//VAN-2841-AWP-Value City $1 Promo - Ends
						}else{
							if(vm.promoSelectedAtAgreement.code === "D"){
								if(vm.restrictions.skipBp){
									vm.pifSamedayAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal).toFixed(2);
								}else{
									vm.pifSamedayAmt = (vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial).toFixed(2);}}
						//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						}//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
						vm.showModal('samedayInitialPaymentInfo', 'initial-payment-info-modal');
					}, function(response) {
						void 0;
					});
					}else{//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
						vm.adjustableDateSelection = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("MMM DD, YYYY");
						vm.showModal('samedayInitialPaymentInfo', 'initial-payment-info-modal');
					}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
				}else {
				
					agreementCodeVerify();
					modalService.dismiss();
				}//AC24649 Code changes ends
			}

		}

		function setOrderData() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});
			vm.orderData[0].quoteItem = orderItems;
			vm.orderData[1].quoteItem = orderItems;
			vm.orderData[2].quoteItem = orderItems;

			vm.orderData[0].inventoryList = vm.createInventoryList();
			vm.orderData[1].inventoryList = vm.createInventoryList();
			vm.orderData[2].inventoryList = vm.createInventoryList();

			vm.orderData[0].engagementId = vm.engagement.engagementId;			
			vm.orderData[1].engagementId = vm.engagement.engagementId;			
			vm.orderData[2].engagementId = vm.engagement.engagementId;

			void 0;
			// SharedDataSvc.globalVars.orderEstimatePut.quoteItem
		}

		function setOrderDataForAdjustablePayment() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});

			vm.orderDataForAdjustablePayment.quoteItem = orderItems;
			vm.orderDataForAdjustablePayment.inventoryList = vm.createInventoryList();
			vm.orderDataForAdjustablePayment.engagementId = vm.engagement.engagementId;
			void 0;

		}

		function isZipInStoreRadius(zip) {
			void 0;
			var deferred = $q.defer();
			// Google API Implementation - Starts
			getStoreInfoFactory.getLatLngFromZip(zip).then(function(results) {
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
					    distance = getStoreInfoFactory.getCustomerDistance(vm.storeLatLng, customer),
					    isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius(vm.storeInfo.allowedRentalRadiusMiles, distance),
					    isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

					void 0;
					// console.log('Is in US', isCustomerInUS);
					void 0;
					vm.customerDistance = Math.round(distance);

					if (isCustomerInUS && isInAllowedRadius) {
						vm.customerInStoreRadius = true;
						deferred.resolve(true);
					} else {
						vm.customerInStoreRadius = false;
						deferred.resolve(false);
					}
				} else {
					vm.isZipValid = false;
					deferred.resolve(false);
				}
			});
			return deferred.promise;
		}

		function setCustomerOrderData(customer) {
			vm.orderData[0].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[0].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[0].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[0].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[0].employeeName = 'AWP';
			vm.orderData[0].store = vm.storeInfo.vanStoreId;

			vm.orderData[1].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[1].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[1].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[1].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[1].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[1].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[1].employeeName = 'AWP';
			vm.orderData[1].store = vm.storeInfo.vanStoreId;

			vm.orderData[2].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[2].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[2].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[2].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[2].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[2].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[2].employeeName = 'AWP';
			vm.orderData[2].store = vm.storeInfo.vanStoreId;
		}

		function setCustomerOrderDataForAdjustablePayment(customer) {
			vm.orderDataForAdjustablePayment.customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderDataForAdjustablePayment.customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderDataForAdjustablePayment.orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderDataForAdjustablePayment.feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderDataForAdjustablePayment.employeeName = 'AWP';
			vm.orderDataForAdjustablePayment.store = vm.storeInfo.vanStoreId;
		}

		function createInventoryList() {
			var inventoryList = [];

			void 0;
			_.forEach(vm.orderContent.orderItems, function(item, index) {
				var orderItem = {
					"racBrand" : "ACP", // 'ESV' for extended
					"itemModelNumber" : "", // 'ExtSvc' for extended
					"racModelNumber" : "",
					"depreciableLifeInMonths" : 24,
					"itemOptions" : "",
					"itemOriginalCost" : 0
				};

				orderItem.racBrand = "ACP";
				orderItem.itemModelNumber = "";
				orderItem.racModelNumber = productFactory.getRacModelNumber(vm.prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				orderItem.depreciableLifeInMonths = 24;
				orderItem.itemOptions = productFactory.buildItemOptions(item);
				orderItem.itemOriginalCost = SharedDataSvc.formatItemPrice(item.itemPrice);
				void 0;
				inventoryList.push(orderItem);
			});
			/*VAN-4050 AWP - show initial payment details before agreement creation -
			adding the payload for create inventory function to make ht call 
			and get the response for the reduction amount in customer find */
			
			if(vm.invoiceReductionPayment && vm.invoiceReductionPaymentAmount> 0) {
				var orderItem = {
					        "racBrand": "IVR",
					        "itemModelNumber": "",
					        "racModelNumber": "HTIVR-DEPAMT",
					        "depreciableLifeInMonths": 24,
					        "itemOptions": "1",
					        "itemOriginalCost": (vm.invoiceReductionPaymentAmount*100)
					    }
				  orderItem.itemModelNumber = "";
				  orderItem.racBrand = "IVR";
				  orderItem.racModelNumber = "HTIVR-DEPAMT"
				  orderItem.itemOptions = "1";
				  orderItem.itemOriginalCost = (vm.invoiceReductionPaymentAmount*100);
				  orderItem.depreciableLifeInMonths = 24;
				  void 0;
				inventoryList.push(orderItem);	
			}
	
			//VAN-2377-AWP- Amount mismatch if we select multiple items and add ESV - Start
			if(vm.orderContent.extendedServiceAmount > 0) {
				var extendedWarranty = {
						"racBrand" : "ACP",
						"itemModelNumber" : "ExtSvc",
						"racModelNumber" : "ESVPLAN-1",
						"depreciableLifeInMonths" : 24,
						"racRateCode" : "ESV396",
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(vm.orderContent.extendedServiceAmount)
				};
				inventoryList.push(extendedWarranty);
			}
			//VAN-2377-AWP- Amount mismatch if we select multiple items and add ESV - End

			return inventoryList;
		}

		function cancelAndNavigate(){
			if(vm.storeInfo.isDigitalSigningEnabled && (vm.isHTCustomer || (vm.hideBp && vm.restrictions.skipLdw))) {
				navigateTo('dashboard');
			}  else {
				modalService.dismiss();
			}
		}
		
		function showLowDownPaymentOptions(item) {
			if( (item.desc.indexOf("AutoPay Required") > -1) || (!$cookies.getObject('isHybrid') && item.desc.indexOf("without AutoPay") > -1 )){
				return item.payment;//VAN-670 - Consent Verbiage
			}
		}
		
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		function showPaymentInitial(){
			$rootScope.selectedLang = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference === 'ENGLISH' ? true : false;
			/*if(vm.selectedPromo === 'AutoPayRequired'){
				$scope.autoPayRequired = modalService.open({*/
			if(vm.selectedPromo === 'H'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
				$scope.H = modalService.open({
					title: 'Open AutopayRequired popup for $49 Promo',
					templateUrl: 'partials/modal_showAutopayRequired.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
			else
			{
				//checkStore();
				getFirstPaymentOptions();//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
			}
		}
		function showInitialPaymentOptions(){
			$scope.errEmpPass = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			$scope.noEmpPass = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			vm.selectedPromo = null;
			vm.showAddAmt = false;
			vm.showPIFPromoOption = false;
			vm.enableVariablePay = false;
			$rootScope.showCalforVarPay = false;
			vm.ipMaxDate = "";
			vm.ipMinDate = "";
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
		}
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
		
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
		function changePaymentMode(paymentMode){
			showModal('Processing', 'processing-modal');
			if(vm.selectedPromo != null){
				if(vm.selectedPromo === 'Pif'){
					vm.orderDataForAdjustablePayment.promotionCode = 'D';
				//}else if(vm.selectedPromo === 'AutoPayRequired'){
				}else if(vm.selectedPromo === 'H'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = 'H';
				//}else if(vm.selectedPromo === 'WithoutAutoPay'){
				}else if(vm.selectedPromo === 'J'){ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = 'J';
					//VAN-2841-AWP-Value City $1 Promo - Starts
				//}else if(vm.selectedPromo === 'vcPromo'){
				}else if(vm.selectedPromo === 'Q'){ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = 'Q';
				//VAN-3623-AWP - set promocode configurable-Starts
				}else if(vm.selectedPromo != 'H' && vm.selectedPromo != 'J' && vm.selectedPromo != 'Q' && vm.selectedPromo != 'payAdditionalAmount'){ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = vm.selectedPromo;
				//VAN-3623-AWP - set promocode configurable-Ends
				}else if(vm.selectedPromo === 'payAdditionalAmount'){//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
					vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = null;
					vm.orderDataForAdjustablePayment.varInitPmtFunc = 'QUOTEDATE';
					vm.orderDataForAdjustablePayment.varInitPmtAmt = $scope.amount.replace('.','');
				}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			}else{
				if(vm.storeInfo.noCalcPromos[0].code === 'Q'){
					vm.orderDataForAdjustablePayment.promotionCode = 'Q';
				}else if(vm.storeInfo.noCalcPromos[0].code === 'H'){
					vm.orderDataForAdjustablePayment.promotionCode = 'H'; //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
				}
				else if(vm.storeInfo.noCalcPromos[0].code != 'H' && vm.storeInfo.noCalcPromos[0].code != 'J' && vm.storeInfo.noCalcPromos[0].code != 'Q'){
					vm.orderDataForAdjustablePayment.promotionCode = vm.storeInfo.noCalcPromos[0].code; //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
				}
				else{
					vm.orderDataForAdjustablePayment.promotionCode = "J";
				}
				//VAN-2841-AWP-Value City $1 Promo - Ends
			}
			vm.orderDataForAdjustablePayment.paymentMode = 'Monthly';
			agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(monthlyResponse) {
				void 0;
				vm.quoteGotAsPerSelection = monthlyResponse;
				vm.orderDataForAdjustablePayment.paymentMode = 'Biweekly';
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(biweeklyResponse) {
					void 0;
					vm.quoteGotAsPerSelectionBiweekly = biweeklyResponse;
					vm.orderDataForAdjustablePayment.paymentMode = 'Fourweek';
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(quarterlyResponse) {
						void 0;
						vm.quoteGotAsPerSelectionQuarterly = quarterlyResponse;
						$scope.paymentSelected = SharedDataSvc.globalVars.paymentModeSelected;	
						modalService.dismiss();
						showModal('modifyInitialPayment');
					}, function(quarterlyResponse) {
						void 0;
						modalService.dismiss();
					}); 
				}, function(biweeklyResponse) {
					void 0;
					modalService.dismiss();
				}); 
			},function(monthlyResponse) {
				void 0;
				modalService.dismiss();
			});	
		}

		function promoPaymentSelectOperation(val) {
			void 0;
			if(val === "Pif" && !vm.showPIFPromoOption) {
				if(vm.storeInfo.isManned && !vm.storeInfo.hybridEnabled){
					showModal('confirmPasswordAgreement');
				}else{
					showModal('validatePassphrase');
				}
			} 
		}

		function verifyEmployeePassWord(pass){
			$scope.noEmpPass = false;
			$scope.errEmpPass = false;
			if (pass) {
				var auth = vm.storeInfo.vanStoreId;
				if (auth === pass) {
					void 0;
					modalService.dismiss();
					vm.showPIFPromoOption = true;
//					vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					showPaymentInitial();//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
				} else {
					$scope.errEmpPass = true;
					void 0;
				}
			} else {
				$scope.noEmpPass = true;
			}
		}
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
		
		//VAN-2398-Prompt for CA stores about unsigned documents - Starts
		function unSignedEmailModal(){
			var unSignedEmailModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_unSignedEmail.html',
				backdrop: 'static'
			});
			unSignedEmailModal.result.then(function(){
				if(vm.hasCoCustomer){ //PR-State
					var coCustomerSigningModal = modalService.open({
						scope: $scope,
						windowClass: '',
						templateUrl: 'partials/modal_coCustomerSigning.html',
						backdrop: 'static'
					});
					coCustomerSigningModal.result.then(function(){
						vm.checkSigner();
					});
				}else{
					if(!vm.hideBp || !vm.restrictions.skipLdw){
						beforeSigningModal();
					}
				}
				if(vm.hideBp && vm.restrictions.skipLdw){
					vm.beforeSigning();
					return false;
				}
			});
		}
		
		function unSignedEmailSameDay(){
			if($scope.coCustSigner != null){
				if(vm.customerFind.isCoCustomerInfoProvided == true){
					if($scope.isAgreementPending[0].customerType.toLowerCase() == 'applicant'){
					vm.afterSigningConsent();
				}
				else{
					vm.afterSigning();
				}
				}
				else{
					$rootScope.showConsent = true;
					vm.askForSigningConsent();}
				$cookies.putObject('isCoCustomerSignerPending', null);
				return false;
			} else if(vm.customerFind.isCoCustomerInfoProvided && $scope.isAgreementPending.length == 1 && vm.customerFind.VanEngagement.agreementOriginator !== null && vm.customerFind.VanEngagement.agreementOriginator.toLowerCase() === "hightouch"){
				if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
					$rootScope.showConsentForHT = true;
				}
				vm.afterSigningConsent();
				return false;
			}
		}
		//VAN-2398-Prompt for CA stores about unsigned documents - Ends	
		
		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
		function quoteCalculator() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.setOrderDateAsPerSelection(vm.adjustableDateSelection);
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;
			}
			if(vm.selectedPromo != 'payAdditionalAmount'){
				vm.orderDataForAdjustablePayment.varInitPmtFunc = null;
				vm.orderDataForAdjustablePayment.varInitPmtAmt = null;
				vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = null;
			}
			for(var i in vm.storeInfo.noCalcPromos) //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
			{
				if(vm.selectedPromo===vm.storeInfo.noCalcPromos[i].code)
				{
					vm.promoPayment = vm.storeInfo.noCalcPromos[i].payment;
					//vm.addAmt=vm.storeInfo.noCalcPromos[i].desc.substring(1,3);//VAN-3623-Promocode configurable
					vm.addAmt=vm.storeInfo.noCalcPromos[i].payment//VAN-3642-AWP - Payment Values getting mismatch if we select Beyond15days date
					if(vm.selectedPromo==="H"){
						vm.orderDataForAdjustablePayment.promotionCode = "H";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoOneResponse) {
							void 0;
							modalService.dismiss();
							vm.promoOneQuote = promoOneResponse;
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);
								}else{
									vm.promoOneAdditionalAmt = ((vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial) - 49).toFixed(2);
								}}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(promoOneResponse) {
							void 0;
							modalService.dismiss();
						});
					}
					//}else if(vm.selectedPromo === "WithoutAutoPay"){
					else if(vm.selectedPromo==="J")
					{
						vm.orderDataForAdjustablePayment.promotionCode = "J";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoTwoResponse) {
							void 0;
							modalService.dismiss();
							vm.promoTwoQuote = promoTwoResponse;
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);
								}else{
									vm.promoTwoAdditionalAmt = ((vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial) - 99).toFixed(2);
								}}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(promoTwoResponse) {
							void 0;
							modalService.dismiss();
						});	
					}

					else if(vm.selectedPromo==="Q")

					{
						vm.orderDataForAdjustablePayment.promotionCode = "Q";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
							vm.vcPromoQuote = vcPromoresponse;
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
								}else{
									vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
								}}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
						});
					}
					//VAN-3623-AWP - set promocode configurable-Starts
					else if(vm.selectedPromo!="H" && vm.selectedPromo!="J" && vm.selectedPromo!="Q" && vm.selectedPromo != "Pif" && vm.selectedPromo != "payAdditionalAmount" ){
						vm.orderDataForAdjustablePayment.promotionCode = vm.selectedPromo;
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
							vm.vcPromoQuote = vcPromoresponse;
							if(vm.showAddAmt){
									var promoAmount = vm.addAmt;//VAN-3623-Promocode configurable
								if(vm.restrictions.skipBp){
									vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - promoAmount).toFixed(2);
								}else{
									vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - promoAmount).toFixed(2);
								}
								}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
						});
					}
		//VAN-3623-AWP - set promocode configurable-Ends
				}
				
			}
		 if(vm.selectedPromo === "Pif"){
				vm.showPIFPromoOption = true;
				//vm.promoPayment =""//check 
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}
					vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
				},function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
				});	
			//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
			/*}else if(vm.selectedPromo === "vcPromo"){
				vm.promoPayment = vm.storeInfo.noCalcPromos[0].payment;
				vm.orderDataForAdjustablePayment.promotionCode = "Q";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo success', vcPromoresponse);
					modalService.dismiss();
					vm.vcPromoQuote = vcPromoresponse;
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
						}else{
							vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
						}}
					vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
				},function(vcPromoresponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo fail', vcPromoresponse);
					modalService.dismiss();
				});*/ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
			}else if(vm.selectedPromo === "payAdditionalAmount"){
				vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = vm.orderDataForAdjustablePayment.nextRenewDte;
				vm.orderDataForAdjustablePayment.varInitPmtFunc = 'QUOTEAMT';
				vm.orderDataForAdjustablePayment.varInitPmtAmt = null;
				vm.orderDataForAdjustablePayment.promotionCode = null;
				vm.orderDataForAdjustablePayment.promotionNextDueDate = null;
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(variablePayResponse) {
					void 0;
					modalService.dismiss();
					vm.variablePayQuote = variablePayResponse;
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.variablePayAdditionalAmt = (vm.variablePayQuote.initialRaPaymentTotal - $scope.amount).toFixed(2);
						}else{
							vm.variablePayAdditionalAmt = (vm.variablePayQuote.initialRaPaymentTotal + vm.variablePayQuote.bplusAmountInitial + vm.variablePayQuote.bplusTaxInitial - $scope.amount).toFixed(2);
						}
					}
					vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
				},function(vcPromoresponse) {
					void 0;
					modalService.dismiss();
				});

			}
		}

		function getAlternateAmount(){
			$scope.showDoneToContinue = false;
			vm.variablePay.price = '';
			vm.variablePay.minimumPrice = 49.00;//Default	
			var alternateAmountModal = modalService.open({
				title: "Pay Alternate Amount Modal",
				templateUrl: 'partials/modal_getAlternateAmount.html',
				scope: $scope,
				backdrop: 'static'
			});
			alternateAmountModal.result.then(function(data) {
				void 0;
				vm.enableVariablePay = true;
				$rootScope.showCalforVarPay = true;
				$scope.amount = data.toFixed(2);
				vm.orderDataForAdjustablePayment.varInitPmtFunc = 'QUOTEDATE';
				vm.orderDataForAdjustablePayment.varInitPmtAmt = $scope.amount.replace('.','');
				vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = null;
				vm.orderDataForAdjustablePayment.promotionCode = null;
				vm.orderDataForAdjustablePayment.promotionNextDueDate = null;
				buildQuoteForVarPay();
			});
		}

		function variablePayCheck(price) {
			if (price && String(price).indexOf('.') > -1) {
				var itemPrices = String(price).split('.');
				if (itemPrices[1].length > 2) { 
					vm.variablePay.price = Number(price.toString().match(/^\d+(?:\.\d{0,2})?/));
				}	
			}
		}

		function buildQuoteForVarPay() {
			void 0;
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;
			}
			if (vm.orderContent.orderItems.length > 0) {
				$rootScope.agreementloaderText = (SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == 'SPANISH') ? es.common_label_processing : en.common_label_processing;
				showModal('ProcessingAgreement', 'processing-modal');
				_.forEach(vm.orderContent.orderItems, function(order, i) {
					productFactory.getProductInfo(vm.orderContent.orderItems[i]).then(function(response) {
						$log.log('product info ' + i, response);
						vm.orderArray.push(response);
						if (i === vm.orderContent.orderItems.length - 1) {
							if (vm.isAdjustablePayment) {
								setOrderDataForAdjustablePayment();
								vm.orderDataForAdjustablePayment.nextRenewDte = datesFactory.getCurrentDate();
								vm.orderDataForAdjustablePayment.requestDte = datesFactory.getCurrentDate();
								agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(variablePayResponse) {
									void 0;
									modalService.dismiss();
									vm.variablePayQuote = variablePayResponse;
									vm.expiryDate = vm.variablePayQuote.expirationDate;
									if(vm.variablePayQuote!=null && vm.variablePayQuote.expirationDate!=null){
										vm.adjustableDateSelection = moment(vm.variablePayQuote.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
										vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
									}
								},function(vcPromoresponse) {
									void 0;
									modalService.dismiss();
								});
							}else {
								setOrderData();
							}
						}
					});
				});
			}else {
				void 0;
			}
		}

		function buildCalendarForVarPay(){
			if(vm.ipMaxDate == "" && vm.ipMinDate == ""){
			vm.expDte_min = moment(vm.expiryDate, "YYYYMMDD");
			vm.expDte_max = moment(vm.expiryDate, "YYYYMMDD");
			vm.ipMaxDate = moment(vm.expDte_max.add(14, 'd')._d);
			void 0;
			vm.ipMinDate = moment(vm.expDte_min.add(1, 'd')._d);
			void 0;
			vm.newPaymentMode = false;
			}
			vm.openfirstPaymentSelectionCalendar();	
		}
		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
	}

})();

(function(){

	'use strict';

	angular
	.module('myApp')
	.controller('emailAgreementRemoteCtrl', emailAgreementRemoteCtrl);

	function emailAgreementRemoteCtrl($scope, $q) {
		$scope.validateEmail = validateEmail;
		$scope.editEmail = editEmail;
		$scope.resetEmail = resetEmail;
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.verifyEmail = true;

		$scope.resetEmail();
		$scope.editEmailModeOn = false;
		$scope.isVerified = true;

		function validateEmail(el) {
			$scope.isVerified = false;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			if($scope.editEmailModeOff && !$scope.emailAddress){
				$scope.invalidEmailAddress = true;
				$scope.verifyEmail = false;
				return false;
			} 
			else{
				if($scope.emailAddress){
					$scope.verifyEmail = true;
					$scope.isVerified = true;}
			}
		}

		function resetEmail() {	
			$scope.emailVerify = undefined;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			$scope.verifyEmail = true;
			$scope.isVerified = false;
			$scope.editEmailModeOff = false;
		}

		function editEmail(){
			$scope.editEmailModeOn = true;
			/*VAN 510 - Electronic consent moved to application Flow - Demo changes*/
			$scope.isVerified = true;
			$scope.editEmailModeOff = true;
		}
	}
	emailAgreementRemoteCtrl.$inject = ["$scope", "$q"];
})();


//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts

(function() {

    /**
     * Config
     */
    var moduleName = 'app.dirPagination';
    var DEFAULT_ID = '__default';

    /**
     * Module
     */
    angular.module(moduleName, [])
        .directive('dirPaginate', ['$compile', '$parse', 'paginationService', dirPaginateDirective])
        .directive('dirPaginateNoCompile', noCompileDirective)
        .directive('dirPaginationControls', ['paginationService', 'paginationTemplate', dirPaginationControlsDirective])
        .filter('itemsPerPage', ['paginationService', itemsPerPageFilter])
        .service('paginationService', paginationService)
        .provider('paginationTemplate', paginationTemplateProvider)
        .run(['$templateCache',dirPaginationControlsTemplateInstaller]);

    function dirPaginateDirective($compile, $parse, paginationService) {

        return  {
            terminal: true,
            multiElement: true,
            priority: 100,
            compile: dirPaginationCompileFn
        };

        function dirPaginationCompileFn(tElement, tAttrs){

            var expression = tAttrs.dirPaginate;
            // regex taken directly from https://github.com/angular/angular.js/blob/v1.4.x/src/ng/directive/ngRepeat.js#L339
            var match = expression.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+track\s+by\s+([\s\S]+?))?\s*$/);

            var filterPattern = /\|\s*itemsPerPage\s*:\s*(.*\(\s*\w*\)|([^\)]*?(?=\s+as\s+))|[^\)]*)/;
            if (match[2].match(filterPattern) === null) {
                throw 'pagination directive: the \'itemsPerPage\' filter must be set.';
            }
            var itemsPerPageFilterRemoved = match[2].replace(filterPattern, '');
            var collectionGetter = $parse(itemsPerPageFilterRemoved);

            addNoCompileAttributes(tElement);

            // If any value is specified for paginationId, we register the un-evaluated expression at this stage for the benefit of any
            // dir-pagination-controls directives that may be looking for this ID.
            var rawId = tAttrs.paginationId || DEFAULT_ID;
            paginationService.registerInstance(rawId);

            return function dirPaginationLinkFn(scope, element, attrs){

                // Now that we have access to the `scope` we can interpolate any expression given in the paginationId attribute and
                // potentially register a new ID if it evaluates to a different value than the rawId.
                var paginationId = $parse(attrs.paginationId)(scope) || attrs.paginationId || DEFAULT_ID;
                // In case rawId != paginationId we deregister using rawId for the sake of general cleanliness
                // before registering using paginationId
                paginationService.deregisterInstance(rawId);
                paginationService.registerInstance(paginationId);

                var repeatExpression = getRepeatExpression(expression, paginationId);
                addNgRepeatToElement(element, attrs, repeatExpression);

                removeTemporaryAttributes(element);
                var compiled =  $compile(element);

                var currentPageGetter = makeCurrentPageGetterFn(scope, attrs, paginationId);
                paginationService.setCurrentPageParser(paginationId, currentPageGetter, scope);

                if (typeof attrs.totalItems !== 'undefined') {
                    paginationService.setAsyncModeTrue(paginationId);
                    scope.$watch(function() {
                        return $parse(attrs.totalItems)(scope);
                    }, function (result) {
                        if (0 <= result) {
                            paginationService.setCollectionLength(paginationId, result);
                        }
                    });
                } else {
                    paginationService.setAsyncModeFalse(paginationId);
                    scope.$watchCollection(function() {
                        return collectionGetter(scope);
                    }, function(collection) {
                        if (collection) {
                            var collectionLength = (collection instanceof Array) ? collection.length : Object.keys(collection).length;
                            paginationService.setCollectionLength(paginationId, collectionLength);
                        }
                    });
                }

                // Delegate to the link function returned by the new compilation of the ng-repeat
                compiled(scope);
                    
                // When the scope is destroyed, we make sure to remove the reference to it in paginationService
                // so that it can be properly garbage collected
                scope.$on('$destroy', function destroyDirPagination() {
                    paginationService.deregisterInstance(paginationId);
                });
            };
        }

        /**
         * If a pagination id has been specified, we need to check that it is present as the second argument passed to
         * the itemsPerPage filter. If it is not there, we add it and return the modified expression.
         *
         * @param expression
         * @param paginationId
         * @returns {*}
         */
        function getRepeatExpression(expression, paginationId) {
            var repeatExpression,
                idDefinedInFilter = !!expression.match(/(\|\s*itemsPerPage\s*:[^|]*:[^|]*)/);

            if (paginationId !== DEFAULT_ID && !idDefinedInFilter) {
                repeatExpression = expression.replace(/(\|\s*itemsPerPage\s*:\s*[^|\s]*)/, "$1 : '" + paginationId + "'");
            } else {
                repeatExpression = expression;
            }

            return repeatExpression;
        }

        /**
         * Adds the ng-repeat directive to the element. In the case of multi-element (-start, -end) it adds the
         * appropriate multi-element ng-repeat to the first and last element in the range.
         * @param element
         * @param attrs
         * @param repeatExpression
         */
        function addNgRepeatToElement(element, attrs, repeatExpression) {
            if (element[0].hasAttribute('dir-paginate-start') || element[0].hasAttribute('data-dir-paginate-start')) {
                // using multiElement mode (dir-paginate-start, dir-paginate-end)
                attrs.$set('ngRepeatStart', repeatExpression);
                element.eq(element.length - 1).attr('ng-repeat-end', true);
            } else {
                attrs.$set('ngRepeat', repeatExpression);
            }
        }

        /**
         * Adds the dir-paginate-no-compile directive to each element in the tElement range.
         * @param tElement
         */
        function addNoCompileAttributes(tElement) {
            angular.forEach(tElement, function(el) {
                if (el.nodeType === 1) {
                    angular.element(el).attr('dir-paginate-no-compile', true);
                }
            });
        }

        /**
         * Removes the variations on dir-paginate (data-, -start, -end) and the dir-paginate-no-compile directives.
         * @param element
         */
        function removeTemporaryAttributes(element) {
            angular.forEach(element, function(el) {
                if (el.nodeType === 1) {
                    angular.element(el).removeAttr('dir-paginate-no-compile');
                }
            });
            element.eq(0).removeAttr('dir-paginate-start').removeAttr('dir-paginate').removeAttr('data-dir-paginate-start').removeAttr('data-dir-paginate');
            element.eq(element.length - 1).removeAttr('dir-paginate-end').removeAttr('data-dir-paginate-end');
        }

        /**
         * Creates a getter function for the current-page attribute, using the expression provided or a default value if
         * no current-page expression was specified.
         *
         * @param scope
         * @param attrs
         * @param paginationId
         * @returns {*}
         */
        function makeCurrentPageGetterFn(scope, attrs, paginationId) {
            var currentPageGetter;
            if (attrs.currentPage) {
                currentPageGetter = $parse(attrs.currentPage);
            } else {
                // If the current-page attribute was not set, we'll make our own.
                // Replace any non-alphanumeric characters which might confuse
                // the $parse service and give unexpected results.
                // See https://github.com/michaelbromley/angularUtils/issues/233
                var defaultCurrentPage = (paginationId + '__currentPage').replace(/\W/g, '_');
                scope[defaultCurrentPage] = 1;
                currentPageGetter = $parse(defaultCurrentPage);
            }
            return currentPageGetter;
        }
    }

    /**
     * This is a helper directive that allows correct compilation when in multi-element mode (ie dir-paginate-start, dir-paginate-end).
     * It is dynamically added to all elements in the dir-paginate compile function, and it prevents further compilation of
     * any inner directives. It is then removed in the link function, and all inner directives are then manually compiled.
     */
    function noCompileDirective() {
        return {
            priority: 5000,
            terminal: true
        };
    }

    function dirPaginationControlsTemplateInstaller($templateCache) {
        $templateCache.put('app.dirPagination.template', '<ul class="pagination" ng-if="1 < pages.length || !autoHide"><li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == 1 }"><a href="" ng-click="setCurrent(1)">&laquo;</a></li><li ng-if="directionLinks" ng-class="{ disabled : pagination.current == 1 }"><a href="" ng-click="setCurrent(pagination.current - 1)">&lsaquo;</a></li><li ng-repeat="pageNumber in pages track by tracker(pageNumber, $index)" ng-class="{ active : pagination.current == pageNumber, disabled : pageNumber == \'...\' || ( ! autoHide && pages.length === 1 ) }"><a href="" ng-click="setCurrent(pageNumber)">{{ pageNumber }}</a></li><li ng-if="directionLinks" ng-class="{ disabled : pagination.current == pagination.last }"><a href="" ng-click="setCurrent(pagination.current + 1)">&rsaquo;</a></li><li ng-if="boundaryLinks"  ng-class="{ disabled : pagination.current == pagination.last }"><a href="" ng-click="setCurrent(pagination.last)">&raquo;</a></li></ul>');
    }

    function dirPaginationControlsDirective(paginationService, paginationTemplate) {

        var numberRegex = /^\d+$/;

        var DDO = {
            restrict: 'AE',
            scope: {
                maxSize: '=?',
                onPageChange: '&?',
                paginationId: '=?',
                autoHide: '=?'
            },
            link: dirPaginationControlsLinkFn
        };

        // We need to check the paginationTemplate service to see whether a template path or
        // string has been specified, and add the `template` or `templateUrl` property to
        // the DDO as appropriate. The order of priority to decide which template to use is
        // (highest priority first):
        // 1. paginationTemplate.getString()
        // 2. attrs.templateUrl
        // 3. paginationTemplate.getPath()
        var templateString = paginationTemplate.getString();
        if (templateString !== undefined) {
            DDO.template = templateString;
        } else {
            DDO.templateUrl = function(elem, attrs) {
                return attrs.templateUrl || paginationTemplate.getPath();
            };
        }
        return DDO;

        function dirPaginationControlsLinkFn(scope, element, attrs) {

            // rawId is the un-interpolated value of the pagination-id attribute. This is only important when the corresponding dir-paginate directive has
            // not yet been linked (e.g. if it is inside an ng-if block), and in that case it prevents this controls directive from assuming that there is
            // no corresponding dir-paginate directive and wrongly throwing an exception.
            var rawId = attrs.paginationId ||  DEFAULT_ID;
            var paginationId = scope.paginationId || attrs.paginationId ||  DEFAULT_ID;

            if (!paginationService.isRegistered(paginationId) && !paginationService.isRegistered(rawId)) {
                var idMessage = (paginationId !== DEFAULT_ID) ? ' (id: ' + paginationId + ') ' : ' ';
                if (window.console) {
                    void 0;
                }
            }

            if (!scope.maxSize) { scope.maxSize = 9; }
            scope.autoHide = scope.autoHide === undefined ? true : scope.autoHide;
            scope.directionLinks = angular.isDefined(attrs.directionLinks) ? scope.$parent.$eval(attrs.directionLinks) : true;
            scope.boundaryLinks = angular.isDefined(attrs.boundaryLinks) ? scope.$parent.$eval(attrs.boundaryLinks) : false;

            var paginationRange = Math.max(scope.maxSize, 5);
            scope.pages = [];
            scope.pagination = {
                last: 1,
                current: 1
            };
            scope.range = {
                lower: 1,
                upper: 1,
                total: 1
            };

            scope.$watch('maxSize', function(val) {
                if (val) {
                    paginationRange = Math.max(scope.maxSize, 5);
                    generatePagination();
                }
            });

            scope.$watch(function() {
                if (paginationService.isRegistered(paginationId)) {
                    return (paginationService.getCollectionLength(paginationId) + 1) * paginationService.getItemsPerPage(paginationId);
                }
            }, function(length) {
                if (0 < length) {
                    generatePagination();
                }
            });

            scope.$watch(function() {
                if (paginationService.isRegistered(paginationId)) {
                    return (paginationService.getItemsPerPage(paginationId));
                }
            }, function(current, previous) {
                if (current != previous && typeof previous !== 'undefined') {
                    goToPage(scope.pagination.current);
                }
            });

            scope.$watch(function() {
                if (paginationService.isRegistered(paginationId)) {
                    return paginationService.getCurrentPage(paginationId);
                }
            }, function(currentPage, previousPage) {
                if (currentPage != previousPage) {
                    goToPage(currentPage);
                }
            });

            scope.setCurrent = function(num) {
                if (paginationService.isRegistered(paginationId) && isValidPageNumber(num)) {
                    num = parseInt(num, 10);
                    paginationService.setCurrentPage(paginationId, num);
                }
            };

            /**
             * Custom "track by" function which allows for duplicate "..." entries on long lists,
             * yet fixes the problem of wrongly-highlighted links which happens when using
             * "track by $index" - see https://github.com/michaelbromley/angularUtils/issues/153
             * @param id
             * @param index
             * @returns {string}
             */
            scope.tracker = function(id, index) {
                return id + '_' + index;
            };

            function goToPage(num) {
                if (paginationService.isRegistered(paginationId) && isValidPageNumber(num)) {
                    var oldPageNumber = scope.pagination.current;

                    scope.pages = generatePagesArray(num, paginationService.getCollectionLength(paginationId), paginationService.getItemsPerPage(paginationId), paginationRange);
                    scope.pagination.current = num;
                    updateRangeValues();

                    // if a callback has been set, then call it with the page number as the first argument
                    // and the previous page number as a second argument
                    if (scope.onPageChange) {
                        scope.onPageChange({
                            newPageNumber : num,
                            oldPageNumber : oldPageNumber
                        });
                    }
                }
            }

            function generatePagination() {
                if (paginationService.isRegistered(paginationId)) {
                    var page = parseInt(paginationService.getCurrentPage(paginationId)) || 1;
                    scope.pages = generatePagesArray(page, paginationService.getCollectionLength(paginationId), paginationService.getItemsPerPage(paginationId), paginationRange);
                    scope.pagination.current = page;
                    scope.pagination.last = scope.pages[scope.pages.length - 1];
                    if (scope.pagination.last < scope.pagination.current) {
                        scope.setCurrent(scope.pagination.last);
                    } else {
                        updateRangeValues();
                    }
                }
            }

            /**
             * This function updates the values (lower, upper, total) of the `scope.range` object, which can be used in the pagination
             * template to display the current page range, e.g. "showing 21 - 40 of 144 results";
             */
            function updateRangeValues() {
                if (paginationService.isRegistered(paginationId)) {
                    var currentPage = paginationService.getCurrentPage(paginationId),
                        itemsPerPage = paginationService.getItemsPerPage(paginationId),
                        totalItems = paginationService.getCollectionLength(paginationId);

                    scope.range.lower = (currentPage - 1) * itemsPerPage + 1;
                    scope.range.upper = Math.min(currentPage * itemsPerPage, totalItems);
                    scope.range.total = totalItems;
                }
            }
            function isValidPageNumber(num) {
                return (numberRegex.test(num) && (0 < num && num <= scope.pagination.last));
            }
        }

        /**
         * Generate an array of page numbers (or the '...' string) which is used in an ng-repeat to generate the
         * links used in pagination
         *
         * @param currentPage
         * @param rowsPerPage
         * @param paginationRange
         * @param collectionLength
         * @returns {Array}
         */
        function generatePagesArray(currentPage, collectionLength, rowsPerPage, paginationRange) {
            var pages = [];
            var totalPages = Math.ceil(collectionLength / rowsPerPage);
            var halfWay = Math.ceil(paginationRange / 2);
            var position;

            if (currentPage <= halfWay) {
                position = 'start';
            } else if (totalPages - halfWay < currentPage) {
                position = 'end';
            } else {
                position = 'middle';
            }

            var ellipsesNeeded = paginationRange < totalPages;
            var i = 1;
            while (i <= totalPages && i <= paginationRange) {
                var pageNumber = calculatePageNumber(i, currentPage, paginationRange, totalPages);

                var openingEllipsesNeeded = (i === 2 && (position === 'middle' || position === 'end'));
                var closingEllipsesNeeded = (i === paginationRange - 1 && (position === 'middle' || position === 'start'));
                if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                    pages.push('...');
                } else {
                    pages.push(pageNumber);
                }
                i ++;
            }
            return pages;
        }

        /**
         * Given the position in the sequence of pagination links [i], figure out what page number corresponds to that position.
         *
         * @param i
         * @param currentPage
         * @param paginationRange
         * @param totalPages
         * @returns {*}
         */
        function calculatePageNumber(i, currentPage, paginationRange, totalPages) {
            var halfWay = Math.ceil(paginationRange/2);
            if (i === paginationRange) {
                return totalPages;
            } else if (i === 1) {
                return i;
            } else if (paginationRange < totalPages) {
                if (totalPages - halfWay < currentPage) {
                    return totalPages - paginationRange + i;
                } else if (halfWay < currentPage) {
                    return currentPage - halfWay + i;
                } else {
                    return i;
                }
            } else {
                return i;
            }
        }
    }

    /**
     * This filter slices the collection into pages based on the current page number and number of items per page.
     * @param paginationService
     * @returns {Function}
     */
    function itemsPerPageFilter(paginationService) {

        return function(collection, itemsPerPage, paginationId) {
            if (typeof (paginationId) === 'undefined') {
                paginationId = DEFAULT_ID;
            }
            if (!paginationService.isRegistered(paginationId)) {
                throw 'pagination directive: the itemsPerPage id argument (id: ' + paginationId + ') does not match a registered pagination-id.';
            }
            var end;
            var start;
            if (angular.isObject(collection)) {
                itemsPerPage = parseInt(itemsPerPage) || 9999999999;
                if (paginationService.isAsyncMode(paginationId)) {
                    start = 0;
                } else {
                    start = (paginationService.getCurrentPage(paginationId) - 1) * itemsPerPage;
                }
                end = start + itemsPerPage;
                paginationService.setItemsPerPage(paginationId, itemsPerPage);

                if (collection instanceof Array) {
                    // the array just needs to be sliced
                    return collection.slice(start, end);
                } else {
                    // in the case of an object, we need to get an array of keys, slice that, then map back to
                    // the original object.
                    var slicedObject = {};
                    angular.forEach(keys(collection).slice(start, end), function(key) {
                        slicedObject[key] = collection[key];
                    });
                    return slicedObject;
                }
            } else {
                return collection;
            }
        };
    }

    /**
     * Shim for the Object.keys() method which does not exist in IE < 9
     * @param obj
     * @returns {Array}
     */
    function keys(obj) {
        if (!Object.keys) {
            var objKeys = [];
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    objKeys.push(i);
                }
            }
            return objKeys;
        } else {
            return Object.keys(obj);
        }
    }

    /**
     * This service allows the various parts of the module to communicate and stay in sync.
     */
    function paginationService() {

        var instances = {};
        var lastRegisteredInstance;

        this.registerInstance = function(instanceId) {
            if (typeof instances[instanceId] === 'undefined') {
                instances[instanceId] = {
                    asyncMode: false
                };
                lastRegisteredInstance = instanceId;
            }
        };

        this.deregisterInstance = function(instanceId) {
            delete instances[instanceId];
        };
        
        this.isRegistered = function(instanceId) {
            return (typeof instances[instanceId] !== 'undefined');
        };

        this.getLastInstanceId = function() {
            return lastRegisteredInstance;
        };

        this.setCurrentPageParser = function(instanceId, val, scope) {
            instances[instanceId].currentPageParser = val;
            instances[instanceId].context = scope;
        };
        this.setCurrentPage = function(instanceId, val) {
            instances[instanceId].currentPageParser.assign(instances[instanceId].context, val);
        };
        this.getCurrentPage = function(instanceId) {
            var parser = instances[instanceId].currentPageParser;
            return parser ? parser(instances[instanceId].context) : 1;
        };

        this.setItemsPerPage = function(instanceId, val) {
            instances[instanceId].itemsPerPage = val;
        };
        this.getItemsPerPage = function(instanceId) {
            return instances[instanceId].itemsPerPage;
        };

        this.setCollectionLength = function(instanceId, val) {
            instances[instanceId].collectionLength = val;
        };
        this.getCollectionLength = function(instanceId) {
            return instances[instanceId].collectionLength;
        };

        this.setAsyncModeTrue = function(instanceId) {
            instances[instanceId].asyncMode = true;
        };

        this.setAsyncModeFalse = function(instanceId) {
            instances[instanceId].asyncMode = false;
        };

        this.isAsyncMode = function(instanceId) {
            return instances[instanceId].asyncMode;
        };
    }

    /**
     * This provider allows global configuration of the template path used by the dir-pagination-controls directive.
     */
    function paginationTemplateProvider() {

        var templatePath = 'app.dirPagination.template';
        var templateString;

        /**
         * Set a templateUrl to be used by all instances of <dir-pagination-controls>
         * @param {String} path
         */
        this.setPath = function(path) {
            templatePath = path;
        };

        /**
         * Set a string of HTML to be used as a template by all instances
         * of <dir-pagination-controls>. If both a path *and* a string have been set,
         * the string takes precedence.
         * @param {String} str
         */
        this.setString = function(str) {
            templateString = str;
        };

        this.$get = function() {
            return {
                getPath: function() {
                    return templatePath;
                },
                getString: function() {
                    return templateString;
                }
            };
        };
    }
})();
//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends