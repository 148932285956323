myApp.factory('ServiceAgent', function ($http, $q, $cookies, $location, SharedDataSvc, authentication, $timeout, modalService,$rootScope) { //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE

  //--------------------------------------------------
  // PRIVATE PROPERTIES
  //--------------------------------------------------

  /**
   * Authorization header
   */
  var authHeader = 'Basic dGVzdDp0ZXN0';

  /**
   * This is the keys object. It holds the keys and can retrieve PEMs
   */
  var forgeKeys = null;

  /**
   * Server public key. Use for encrypting payloads sent TO the server
   */
  var serverPublicKey = '';

  /**
   * Holds active, unresolved requests
   */
  var activeRequests = [];

  /**
   * Time in seconds until service timeout modal appears
   */
  var timeoutSeconds = 120;
//AC-28614 Changes- Start
  /*var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;  
  *//**
   * Dictionary for service errors
   *//*
  var serviceErrors = {
    generic: "We're sorry, we've encountered an error and may need to close. For support please call "+servicePhoneNumber+". [700]",
    timeout: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+".  [782]",
    skipStolen: "The AcceptanceNOW approval service experienced a network problem. For support please call "+servicePhoneNumber+". [791]",
    approvalFailure: "We're sorry, we've encountered a service error and cannot continue this transaction. For support please call "+servicePhoneNumber+". [785]",
    internetFailure: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+". [780]",
   	recordLocked: "We are currently updating your customer record. Please see an AcceptanceNow sales associate. [789]" +servicePhoneNumber //14120 message change as per business. 
  };*/
  //AC-28614 Changes - End

  //--------------------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------------------

  /**
   * Status code-based error handling for request failures
   * @param status (Number) Status code passed back from the response
   * @return (null)
   */
  var checkServiceErrors = function (data, status,serviceErrors) {//AC-28614 Changes
    void 0;
    if (status === 500) {
	      // sending error to Raygun
	      SharedDataSvc.reportRaygun(data);
	      
	      if (data.status_code === 'ENG-A4001-500') {
	      	
	        modalService.error(serviceErrors.approvalFailure);
	        
	      }else if (data.status_code === 'ENG-111-500') {
	      	
	        modalService.error(serviceErrors.skipStolen);
	        
	      /* Adding all the valid scenarios identified, below so that all other unexpected codes can be gracefully handled by generic pop up. */  
	      //blocked generic modal in case of 500 response for epay payment failure, VCC error and manual override scenarios
      	  }else if (data.status_code === 'ENG-A3001-500') { // AC 12058 code changes - on credit card pay and bank acc pay
      		modalService.error(serviceErrors.recordLocked); //AC 14120 code changes
      	  }else if (data.status_code === 'ENG-E3004-500') { //AC 14134 code changes - on auto pay - credit card pay and bank acc pay
      		modalService.lockError(serviceErrors.recordLocked);  
      	  }else if(data.status_code !== 'ENG-A1004-500' && data.status_code !== 'ENG-A2001-500' && data.status_code !== 'ENG-S2001-500' 
	      		&& data.status_code !== 'ENG-E1005-500' && data.status_code !== 'ENG-E3004-500' && data.status_code !== 'ENG-E2012-500' 
	      		&& data.status_code !== 'ENG-E3005-500' && data.status_code !== 'ENG-E3003-500' && data.status_code !== 'ENG-E2012-500' 
	      		&& data.status_code !== 'ENG-S5001-500' && data.status_code !== 'ENG-S4001-500' && data.status_code !== 'ENG-V107-500' 
	      		&& data.status_code !== 'ENG-B102-500' && data.status_code !== 'ENG-V101-500' && data.status_code !== "ENG-K1004-500" 
	      		&& data.status_code !== "ENG-K1005-500" && data.status_code !== "ENG-K1006-500" && data.status_code !== "ENG-A1010-500" && data.status_code !== "ENG-S000-500") {
	      			
      		  		SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
	        		modalService.error(serviceErrors.generic);
	        
	      }
    }else
    
    /* The http status below will show a generic pop up for Bad Gateway, Service Unavailable, Gateway timeout scenarios */
    if(status == 502 || status == 503 || status == 504){
    	modalService.error(serviceErrors.timeout);
    }else
    
    /* This conditions will handle all other 400 http response/Client Error/Bad request from FE 
     other the hardcoded scenarios below */
    if(status == 400){
    	
    	/* Checking for record lock scenario while creating customer, below */
        /* Adding condition in status message to check T2202 to avoid epay pin invalid issue please look VAN-2957*/
    	/* PRB0042786	Production Defect	VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment */
    	if(data.status_message == "Internal customer number record locked" 
    		|| data.status_message.indexOf('T2238') > -1
    		|| data.status_message.indexOf('the store is updating your customer record.') > -1){ // AC 12058 code changes - on submit of cash payment
   			modalService.error(serviceErrors.recordLocked);
   		}else if((data.status_code == "ENG-115-400") && (data.status_message == "no Rental update record found")) {
        modalService.error(serviceErrors.noEngagement);
       }
       else if( (data.status_message !== "Employee pin is not valid") && (data.status_message.indexOf("A completed agreement already exists") < 0)
    			&& (data.status_message !== "Brand/Model not found on model master.") && (data.status_code!== "ENG-E2002-400")
          && (data.status_message.indexOf('T2202') < 0 ) && (data.status_code !== "ENG-A1012-400") && (data.status_code !== "ENG-E2002-400") && (data.status_code !== "ENG-114-400") && (data.status_code !== 'ENG-S011-400')) {
   			SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
    		modalService.error(serviceErrors.generic);
    	}else if(data.status_message == "Customer Record locked") { //AC 13189 code changes starts
    		modalService.lockError(serviceErrors.recordLocked);  //AC 14120 code changes
    	}//AC 13189 code changes ends
   		
    }else
    
    /* The conditions below will handle all other 404 responses/Not-Found scenarios except the hardcoded scenarios below */
   	if(status == 404){//VAN-3402-Re-engagement with Code - AWP 
   		 if(SharedDataSvc.searchApp && data.status_code === "ENG-111-404" && data.status_message === "Error while preparing response"){
   			modalService.lockError(serviceErrors.approvalError);
   		}else if(data.status_details !== "No Customers Match Search Criteria" && data.status_details !== "SSN Supplied Not Found" && data.status_code !== "ENG-V116-404"){
   			SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
   			modalService.error(serviceErrors.generic);
   		}
   	}else
   	
   	/* The condition below will handle all other 409 responses/Conflicts due to concurrent multiples calls except the hardcoded ones */ 
   	if(status == 409){
   		if(data.status_message == "Internal customer number record locked"){
   			modalService.lockError(serviceErrors.recordLocked);
   		}else{
   			SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
   			modalService.error(serviceErrors.generic);
   		}
   		
   	}else
   	
    /*The Condition below checks for network failure/ internet failure */
    if(status == 0 && !navigator.onLine){
      modalService.error(serviceErrors.internetFailure);
    }else
    
    if (status === 401) {
      void 0;
      void 0;
      
      $location.path('login');
      authentication.logoutUser();
      $timeout(function(){
        modalService.open({
          templateUrl: 'partials/alert_authTimeout.html',
          windowClass: 'modal-small'
        });
      }, 500);
    }else 
    
    /* The condition below will handle all the unexpected responses left from above other than OK/200 and 303/server redirection */
    /* Added check for status not 0 to avoid multiple service call in limited time in that case API returns no Data so status = 0 which is no Error*/
    if(status !== 200 && status !== 303 && status!== 0){
    	SharedDataSvc.globalVars.genericError = true;//AC 25967 changes
    	modalService.error(serviceErrors.generic);
    }
    
    
    
  };

  /**
   * Utility for random string creation, used in encrypt/decrypt
   * @return (String)
   */
  var randomString = function (length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
  };

  /**
   * Get the store id from service or cookie
   * @return (String)
   */
  var getStoreId = function () {
    if (SharedDataSvc.vanStoreId) {
      return SharedDataSvc.vanStoreId;
    } else if ($cookies.getObject('storeId')) {
      return $cookies.getObject('storeId');
    } else {
      void 0;
      return '0';
    }
  };

  /**
   * Get a base unencrypted header
   * @return (Object)
   */
  var getBaseUnencryptedHeader = function () {
    var baseHeaderUnencrypted = {
      'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,RAC-CORRELATION-ID', //changed uniqueid to RAC-CORRELATION-ID,
      'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
      'Encrypt-Mode': false,
      'RAC-KIOSK-LOCATION-ID': getStoreId(),
      'Content-Type': 'text/plain',
      'sessionToken' : authentication.getCurrentSessionId(),
      'RAC-CORRELATION-ID' : getRacCorrelationId(), //Story 26289 Code changes updated uniqueId to RAC-CORRELATION-ID
      'OPERATION-MODE': $cookies.getObject('operationMode'),
      'POS-TYPE': $cookies.getObject('POS-TYPE')
    };
    return baseHeaderUnencrypted;
  };

  /**
   * Get an encrypted header object
   * @return (Object)
   */
  var getBaseEncryptedHeader = function () {
    var baseHeaderEncrypted = {
      'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID', //changed uniqueId to RAC-CORRELATION-ID
      /*'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,https://dev-awp.acceptancenow.com,*',*/
      'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
      'Encrypt-Mode': true,
      'Public-Key': getPublicPEM(true),
      'RAC-KIOSK-LOCATION-ID': getStoreId(),
      'Content-Type': 'application/json',
      'sessionToken' : authentication.getCurrentSessionId(),
      'RAC-CORRELATION-ID' : getRacCorrelationId(), //Story 26289 Code changes updated uniqueID to RAC-CORRELATION-ID
      'OPERATION-MODE': $cookies.getObject('operationMode'),
      'POS-TYPE': $cookies.getObject('POS-TYPE')
	};
    return baseHeaderEncrypted;
  };
  
  //Story 26289 - Code changes start
  var getRacCorrelationId = function () {
	  var entryPoint = "AWP-"+getStoreId(),
	  randomUniqueStr = Math.random().toString(36).slice(2),
	  engagementId = SharedDataSvc.globalVars.engagementId?SharedDataSvc.globalVars.engagementId:"000000",
	  endTargetService = SharedDataSvc.endTargetService.slice(20), //AC 27054 code changes
	  racCorrelationId = entryPoint + " - " + engagementId + " - " + randomUniqueStr + " - " + endTargetService;
	  SharedDataSvc.racCorrelatioId = racCorrelationId;//Feature 7872 Integration of Logz code changes
	  return racCorrelationId;
  }
  //Story 26289 - Code changes end

  /**
   * Get the PEM from forge keys
   * @return (String)
   */
  var getPublicPEM = function (removeHeader) {
    if (!forgeKeys) {
      var keys = generateKeys();
    }
    if (removeHeader) {
      return forge.pki.publicKeyToPem(forgeKeys.publicKey).replace(/(-----(\w+) PUBLIC KEY-----|\r\n|\n|\r)/gm,'');
    } else {
      return forge.pki.publicKeyToPem(forgeKeys.publicKey);
    }
  };

  /**
   * Get the PEM from forge keys
   * @return (String)
   */
  var getPrivatePEM = function (removeHeader) {
    if (!forgeKeys) {
      var keys = generateKeys();
    }
    if (removeHeader) {
      return forge.pki.privateKeyToPem(forgeKeys.privateKey).replace(/(-----(\w+) RSA PRIVATE KEY-----|\r\n|\n|\r)/gm,'');
    } else {
      return forge.pki.privateKeyToPem(forgeKeys.privateKey);
    }
  };

  /**
   * Ported from Rafael's encrypt code. Encrypts text data using a key
   * @param plaintext (String) Text to be encrypted.
   * @return (String) Encrypted String. This is encrypted and base 64 encoded
   */
  var encrypt = function (plaintext, rsa_key) {

    // using old encryption for now
    return SharedDataSvc.encrypt(plaintext, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey);

    var string_key = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var string_iv = randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var key = CryptoJS.enc.Utf8.parse(string_key);
    var iv = CryptoJS.enc.Utf8.parse(string_iv);
    var encrypted = CryptoJS.AES.encrypt(plaintext, key, {iv: iv});
    if (!forgeKeys) {
      generateKeys();
    }

    //RSA encryption. Using JSEncrypt because this is what works server side. Forge is used for decryption
    var rsa_encryption = new JSEncrypt();
    rsa_encryption.setPublicKey(rsa_key);
    var encrypted_session_key = window.atob(rsa_encryption.encrypt(encrypted.key.toString(CryptoJS.enc.Latin1)));

    //pack all that stuff
    var encoding;
    var array_size = 4 + encrypted_session_key.length + 4 + encrypted.iv.sigBytes + encrypted.ciphertext.sigBytes;
    var ab = new ArrayBuffer(array_size);
    var dv = new jDataView(ab);
    dv.writeUint32(encrypted_session_key.length, false);
    dv.writeString(encrypted_session_key, encoding = 'binary');
    dv.writeUint32(encrypted.iv.sigBytes, false);
    dv.writeString(encrypted.iv.toString(CryptoJS.enc.Latin1), encoding = 'binary');
    dv.writeString(encrypted.ciphertext.toString(CryptoJS.enc.Latin1), encoding = 'binary');
    var byte_stream = dv.getString(array_size, 0, encoding = 'binary');
    return window.btoa(byte_stream);
  };

  /**
   * Decrypt data using a key and base 64 decoding
   * @param encoded_byte_stream (String) Encrypted data
   * @return (String) Decrypted data.
   */
  var decrypt = function (encoded_byte_stream) {
    try {
      // This fails if there is no encoding on the data. Usually means data is not encrypted
      var buffer = window.atob(encoded_byte_stream);
    } catch (e) {
      void 0;
      return encoded_byte_stream;
    }

    var dv = new jDataView(buffer);
    var key_size = dv.getUint32(0, false);
    var encoded_session_key = dv.getString(key_size, 4);
    var unencoded_session_key = encoded_session_key;
    var iv_size  = dv.getUint32(key_size + 4, false);
    var iv = CryptoJS.enc.Latin1.parse(dv.getString(iv_size, key_size + 8));
    var ciphertext = dv.getString(buffer.length - key_size - iv_size - 8, key_size + iv_size + 8);

    //unwrap the key
    var session_key = forgeKeys.privateKey.decrypt(unencoded_session_key);
    var parsed_session_key = CryptoJS.enc.Latin1.parse(session_key);

    return CryptoJS.AES.decrypt({ciphertext: CryptoJS.enc.Latin1.parse(ciphertext)}, parsed_session_key, {iv: iv}).toString(CryptoJS.enc.Utf8);
  };

  /**
   * Generate keys
   * @return (Object) The returned public and private keys in an Object
   */
  var generateKeys = function () {
    if (!forgeKeys) {
      forgeKeys = forge.pki.rsa.generateKeyPair(2048);
    }
    return forgeKeys;
  };

  /**
   * The object returned which represents this service
   */
  var serviceAgentObj = {

    //--------------------------------------------------
    // PUBLIC PROPERTIES
    //--------------------------------------------------

    activeRequests: activeRequests,

    //--------------------------------------------------
    // PUBLIC FUNCTIONS
    //--------------------------------------------------

    /**
     * Sets the public key. Should be a certain length and a string.
     * @param key (String)
     * @return (null)
     */
    setServerPublicKey : function (key) {
      if (key && key.length > 10) {
        serverPublicKey = key;
      } else {
        void 0;
      }
    },

    /**
     * Sets the raygun key. Should not be blank and a string.
     * @param key (String)
     * @return (null)
     */
    setRaygunKey : function (key) {
      if (key) {
        SharedDataSvc.raygunKey = key;
      } else {
        void 0;
      }
    },
    
  //Feature 7872 Integration of Logz code changes starts
    setLogzApiToken : function (key) {
    	if(key){
    		SharedDataSvc.logzApiKey = key;
    	} else {
    		void 0;
    	}
    },
    
    setlogzEnableFlag : function(key) {
    	if(key){
    		SharedDataSvc.isLogzEnabled
    	} else {
    		void 0;
    	}
    },
  //Feature 7872 Integration of Logz code changes ends

    setInvRedFlag : function(key) {
      if(key){
        SharedDataSvc.invoiceReductionPayment
      } else {
        void 0;
      }
    },

    setInvRedPayAmt : function(key) {
      if(key){
        SharedDataSvc.invoiceReductionPaymentAmount
      } else {
        void 0;
      }
    },

    /**
     * Encrypt some data and return it
     * @param data (String) Data to be encrypted
     * @return (String) Encrypted data
     */
    encryptData : function (data) {
      return encrypt(data, serverPublicKey);
    },

    /**
     * GET for http request
     * @param url (String) REQUIRED The url to make the request
     * @param method (String) GET, POST, PUT, DELETE, etc. Defaults to GET if unspecified
     * @param addHeaders (Object) Additional headers to add to call
     * @param body (Object) The body of data to send
     * @param doEncrypt (Object) Should the data be encrypted
     * @return (Object) Promise object
     */
    httpCall : function (url, method, addHeaders, body, doEncrypt) {
    	//AC-28614 Changes - Start
    	var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
      if($rootScope.selectedLanguage == 'es'){
           var serviceErrors = {
            generic: "Lo sentimos, hemos encontrado un error y es posible que la sesión tenga que cerrar.Para obtener asistencia, por favor llamar al "+servicePhoneNumber+". [700]",
            timeout: "En estos momentos AcceptanceNOW no puede acceder al registro del cliente. Por favor, inténtelo de nuevo y póngase en contacto con su representante de ventas para obtener asistencia. "+servicePhoneNumber+".  [782]",
            skipStolen: "TEl servicio de la aprobación de AcceptanceNOW experimentó un problema de la conexión.Por favor, póngase en contacto con su representante de ventas o llame al "+servicePhoneNumber+". [791]",
            approvalFailure: "Lo sentimos, hemos encontrado un error de servicio y no podemos continuar con esta transacción. Para obtener asistencia por favor llamar al "+servicePhoneNumber+". [785]",
            internetFailure: "En estos momentos AcceptanceNOW no puede acceder al registro del cliente. Por favor, inténtelo de nuevo y póngase en contacto con su representante de ventas para obtener asistencia. "+servicePhoneNumber+". [780]",
            recordLocked: "Actualmente estamos actualizando su registro de cliente. Consulta a un empleado de ventas AcceptanceNow.  [789]", //14120 message change as per business. 
            approvalError: "¡¡¡Lo siento!!! No podemos encontrar su ID de aprobación en nuestros registros. Por favor, introduzca un ID diferente.", //VAN-3402-Re-engagement with Code - AWP
            noEngagement: "No existe ese number de contrato de renta." //error handling message for No rental update records found  in spanish
           };
      }else{
         var serviceErrors = {
            generic: "We're sorry, we've encountered an error and may need to close. For support please call "+servicePhoneNumber+". [700]",
            timeout: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+".  [782]",
            skipStolen: "The AcceptanceNOW approval service experienced a network problem. For support please call "+servicePhoneNumber+". [791]",
            approvalFailure: "We're sorry, we've encountered a service error and cannot continue this transaction. For support please call "+servicePhoneNumber+". [785]",
            internetFailure: "There was an error connecting to the AcceptanceNOW server. For support please call "+servicePhoneNumber+". [780]",
            recordLocked: "We are currently updating your customer record. Please see an AcceptanceNow sales associate. [789]", //14120 message change as per business. 
            approvalError: "Sorry!!! We are not able to find this Id in our records. Please enter a different Id.", //VAN-3402-Re-engagement with Code - AWP
            noEngagement:"No rental update record found." //error handling message for No rental update record found in english
          };
      }
       
      //AC-28614 Changes - end
   //   var endTargetHere = addHeaders.endTarget;
    //  console.log("This header", addHeaders);
      SharedDataSvc.endTargetService = addHeaders.endTarget; //AC-26289 - Logging: Session Unique Identifier - phaseII
      var defer = $q.defer(),
      canceller = $q.defer(),
     // formatBody = JSON.stringify(body),
      formatBody = doEncrypt ? encrypt(JSON.stringify(body), serverPublicKey) : body,
      baseHeaders = doEncrypt ? getBaseEncryptedHeader() : getBaseUnencryptedHeader(),
      headers = addHeaders ? _.merge(baseHeaders, addHeaders) : baseHeaders,
      requestObj = {
        "url": url,
        "method": method || 'GET',
        "data": formatBody,
        "headers": headers,
        "timeout": canceller.promise,
        // parse JSON-like responses as JSON
        "transformResponse": [function(response) {
          var start = /^\s*(\[|\{[^\{])/;
          var end = /[\}\]]\s*$/;
          if (start.test(response) && end.test(response)) {
            return angular.fromJson(response);
          }
          return response;
        }]
      },
      // sets a service timeout that will cancel all requests after specific time and pop modal
      serviceTimeout = $timeout(function(){
        canceller.resolve();
        modalService.error(serviceErrors.timeout);
      }, timeoutSeconds*1000);
      
   /*  if(endTargetHere == "rentacentervrto/rest/pdfSignerService"){
     	formatBody = body;
     }*/

      activeRequests.push(canceller);
      $http(requestObj).
        then(function (response) {
          void 0;
          var data = response.data;
          var status = response.status;
          var returnData;
          try {
            returnData = (doEncrypt && data) ? decrypt(data) : data;//VAN 4199-Update the Spanish disclaimer in AWP
          } catch (e) {
            void 0;
            returnData = data;
          }
          try {
            returnData.status = status;
          } catch (e) {
            // error
          } 
          
          _.pull(activeRequests, canceller);
          $timeout.cancel(serviceTimeout);
          defer.resolve(returnData);
        }).
        catch(function (response) {
          var data = response.data;
          var status = response.status;
          void 0;
          void 0;
          void 0;
          _.pull(activeRequests, canceller);
          $timeout.cancel(serviceTimeout);
          defer.reject({
            'data': {
              'errorData': data
            },
            'status': status
          });

          //serviceAgentObj.reportRaygun(data);
          checkServiceErrors(data, status,serviceErrors); //AC-28614 Changes
        });

      return defer.promise;
    },

    /**
     * Cancel active page requests via canceller promises passed into http
     * @return (null)
     */
    cancelRequests: function() {
      for (var i = 0; i < activeRequests.length; i++) {
        activeRequests[i].resolve('Cancelled Request');
      }
    }
  }

  return serviceAgentObj;
});



