// The Agreement controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function() {
	'use strict';

	angular.module('myApp').controller('digitalAgreement', digitalAgreement);

	digitalAgreement.$inject = ['$scope', '$log', '$rootScope', '$routeParams', '$q','$timeout', '$translate', 'modalService', '$window', '$location', 'agreementFactory', 'getStoreInfoFactory', 'customerDataFactory', 'orderEstimatePutFactory', 'datesFactory', 'paymentFactory', 'updateEngagementItemFactory', 'productFactory', 'SharedDataSvc', 'base64', 'stateRestrictFactory', 'applicationService', '$cookies', '$modal'];//VAN-510 - Electronic Consent moved to app flow
	function digitalAgreement($scope, $log, $rootScope, $routeParams, $q,$timeout, $translate, modalService, $window, $location, agreementFactory, getStoreInfoFactory, customerDataFactory, orderEstimatePutFactory, datesFactory, paymentFactory, updateEngagementItemFactory, productFactory, SharedDataSvc, base64, stateRestrictFactory, applicationService, $cookies, $modal) {//VAN-510 - Electronic Consent moved to app flow
		/*jshint validthis: true */
		var vm = this,
		    keys;
		//AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
		// set scope variable for processing modal
		$scope.modalTitle = 'Processing';

		// Models
		vm.customer = {};
		vm.engagement;
		vm.agreementData;
		vm.storeInfo;
		vm.stateRestrictions;
		vm.restrictions;
		vm.agreements = [{
			name : {
				en : en.agreement_header_RPA,
				es : es.agreement_header_RPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			// type: 'Kw7N2pTLkRsh202k2wKUdeAbHsosOoIcruqDUqo3idnw2YBrw2mFAAJ2dWQVGef4Y'
			type : 'RA',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_SA,
				es : es.agreement_header_SA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : undefined,
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_RAA,
				es : es.agreement_header_RAA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ACK',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_EPOA,
				es : es.agreement_header_EPOA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'EPO90',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_LDW,
				es : es.agreement_header_LDW
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'LDW',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_BPA,
				es : es.agreement_header_BPA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'CLB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_AA,
				es : es.agreement_header_AA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'ARB',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_WA,
				es : es.agreement_header_WA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'FEE',
			eSign : false,
			signature : null
		}, {
			name : {
				en : en.agreement_header_DFA,
				es : es.agreement_header_DFA
			},
			hidden : false,
			agree : undefined,
			pdf : undefined,
			viewed : false,
			type : 'dlf',
			eSign : false,
			signature : null
		}];

		vm.agreementsSpanish = [{
			name : "Acuerdo de Alquiler con opción a compra",

		}, {
			name : "Acuerdo suplementario (s)",
		}, {
			name : "Reconocimiento del contrato de alquiler",

		}, {
			name : "Enmienda de opción de compra temprana",

		}, {
			name : "Acuerdo de exención de daños de responsabilidad",

		}, {
			name : "Acuerdo de Benefits Plus",

		}, {
			name : "Acuerdo de Arbitraje",

		}, {
			name : "Contrato de garantía",
		}, {
			name : "Acuerdo de entrega",
		}];

		vm.suppAgreementsName = {

			"CAM" : "Camcorder HD Supplement acknowledgement",
			"COP" : "Computer Rental Supplement acknowledgement",
			"CRB" : "Crib Waiver acknowledgement",
			"CYC" : "E-Cycle acknowledgement",
			"GAS" : "Gas Dryer Waiver acknowledgement",
			"LCD" : "Large Screen TV Supplement acknowledgement",
			"RAN" : "Range Waiver acknowledgement",
			"SOC" : "Summary of Cost acknowledgement",
			"VID" : "Electronic Device Rental Supplement acknowledgement"

		};

		vm.suppAgreementsSpanishName = {

			"CAM" : "Videocámara HD suplementaria",
			"COP" : "Arrendamiento de computadora suplementario",
			"CRB" : "Crib Waiver",
			"CYC" : "Reconocimiento  de  E-Cycle",
			"GAS" : "Reconocimiento  de Secadora de gas",
			"LCD" : "Reconocimiento  de TV de pantalla grande",
			"RAN" : "Reconocimiento  de renuncia  de Cocina económica",
			"SOC" : "Reconocimiento  de Resumen de Costo",
			"VID" : "Reconocimiento  de Dispositivo electronico"

		};

		vm.orderData = [_.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder), _.clone(SharedDataSvc.globalVars.agreementOrder)];

		vm.orderDataForAdjustablePayment = _.clone(SharedDataSvc.globalVars.agreementOrder);

		$scope.consent= { istcpaflag : false };

		// Variables
		vm.quote;
		vm.firstPayment = 0;
		vm.showPaymentOptionsModal = false;
		vm.paymentInfo = [];
		vm.showConsentAgree = false;
		vm.quoteDates = datesFactory.quoteDates;
		vm.quotes = agreementFactory.quotes;

		vm.engagementId = $routeParams.engagementId;
		$cookies.putObject('engagementId', vm.engagementId);

		vm.pdfLinks = [];
		vm.unsignedAgreement;
		vm.activeAgreementIndex = 0;
		vm.activeAgreement = vm.agreements[vm.activeAgreementIndex];
		vm.modal;
		vm.orderContent;
		vm.orderArray = [];
		vm.declineBpModalShown = false;
		vm.languagePreference = true;
		vm.hideBp;
		vm.customerInRadius = true;
		vm.isZipValid = true;
		vm.storeLatLng;
		vm.customerDistance;
		vm.storeRadius;
		vm.customerInStoreRadius;
		vm.term1 = false;
		vm.term2 = false;
		vm.term3 = false;
		vm.term4 = false;
		vm.term5 = false;
		vm.term6 = false;
		vm.term7 = false;
		vm.isAnyUnchecked = true;
		vm.inputInitials = "";
		vm.employeeId;
		vm.employeePinError;
		vm.pinAttempts = 0;
		vm.initialPaymentStatus = false;
		vm.initialPaymentErrorClass = "";
		vm.noEmpPin = false;
		vm.processingFee;
		vm.isEPOAmendmentEnabled = false;
		vm.suppCheck = [];
		vm.isAllSuppChecked = false;
		vm.showSupp = false;
		vm.showWarrantyAgreement = false;
		vm.showDelWarrantyAgreement = false;
		vm.adjustableDateSelection = "";
		vm.ipMinDate = "";
		vm.ipMaxDate = "";
		vm.arrAdjustablePayment = "";
		vm.isAdjustablePayment = false;
		vm.quoteGotAsPerSelection = "";
		/*VAN-774 - Biweekly renewal payments Long Term*/
		vm.quoteGotAsPerSelectionBiweekly = "";
		/*VAN-858 - Quad Weekly Payment Frequency*/
		vm.quoteGotAsPerSelectionQuarterly = "";
		
		vm.prodCat;
		//VAN-510 - Electronic Consent moved to app flow - Starts
		$rootScope.showConsent = false;
		$rootScope.showConsentForHT = false;
		vm.checkSignerDetails = checkSignerDetails;
		vm.newCustomerElectronicConsent = newCustomerElectronicConsent;
		vm.updateCustomerConstent = updateCustomerConstent;
		vm.exitHTConsent = exitHTConsent;
		$rootScope.languageOption = $rootScope.selectedLanguage == 'en' ? true : false;
		//VAN-510 - Electronic Consent moved to app flow - Ends
		
		// Controller Functions
		vm.submitAgreements = submitAgreements;
		vm.createAgreement = createAgreement;
		vm.signAgreement = signAgreement;
		vm.declineAgreement = declineAgreement;
		vm.navigateTo = navigateTo;
		vm.signLdw = signLdw;
		vm.showModal = showModal;
		vm.setFirstPaymentDate = setFirstPaymentDate;
		vm.handleLdwDecline = handleLdwDecline;
		vm.handleBpDecline = handleBpDecline;
		vm.handleSkipBp = handleSkipBp;
		vm.getFirstPaymentOptions = getFirstPaymentOptions;
		vm.setLang = setLang;
		vm.declineRental = declineRental;
		vm.setAgreementSignature = setAgreementSignature;
		vm.declineConsent = declineConsent;
		vm.hideBPWhenLDWisDeclined = hideBPWhenLDWisDeclined;
		vm.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		vm.navigateToApplication = navigateToApplication;
		vm.declineBp = declineBp;
		vm.declineBpNew = declineBpNew;
		vm.handleInitialsStateCheck = handleInitialsStateCheck;
		vm.setEmployeeId = setEmployeeId;
		vm.checkStore = checkStore;
		vm.validateAssociatePasswordCash = validateAssociatePasswordCash;
		vm.checkAssociatePin = checkAssociatePin;
		vm.createInventoryList = createInventoryList;
		vm.isSkipButtonEnable = false;
		vm.agreementHTID = "";
		//AC 25996 code changes
		//AC13303 code changes starts
		vm.voidAgreement = voidAgreement;
		vm.agreementStatus = "";
		//AC13303 code changes ends
		vm.dateSelectionDone = dateSelectionDone;
		vm.setOrderDateAsPerSelection = setOrderDateAsPerSelection;
		vm.openfirstPaymentSelectionCalendar = openfirstPaymentSelectionCalendar;
		/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
		vm.modifyPaymetMode = modifyPaymetMode;
		vm.showInitialPayment = showInitialPayment;
		/*VAN-774 - Biweekly renewal payments Long Term - Ends*/
		//AC24649 Code changes starts
		vm.agreementCodeVerify = agreementCodeVerify;
		vm.reselectNextPaymentError = reselectNextPaymentError;
		$scope.showVerification = true;
		//AC24649 Code changes ends
		//7573 feature
		$scope.verifyAgreement = {};
		$scope.verifyAgreement.textCode = '';
		$scope.verifyAgreement.textCodeAttemptsNumber = 0;
		$scope.verifyAgreement.textCodeError = false;
		$scope.verifyAgreement.agrVerificationNumber = '';
		$scope.submitTextCode = submitTextCode;

		vm.getRAInitials = null;
		vm.beforeSigning = beforeSigning;
		vm.afterSigning = afterSigning;
		vm.notYetSigning = notYetSigning;
		vm.checkAndNavigate = checkAndNavigate;
		vm.enableSigning = enableSigning;
		vm.checkSigner = checkSigner;
		vm.afterSigningConsent = afterSigningConsent;
		vm.askForSigningConsent = askForSigningConsent;
		vm.cancelAndNavigate = cancelAndNavigate;
		vm.showLowDownPaymentOptions = showLowDownPaymentOptions;
		vm.signer = {
			customer : false,
			coCustomer : false
		};
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		$scope.isPromoSelected= false;
		vm.selectedPromo;
		vm.showPaymentInitial = showPaymentInitial;
		vm.showInitialPaymentOptions = showInitialPaymentOptions;
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends

		//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts
		vm.verifySignerDetails = verifySignerDetails;
		//Commented code
		/*vm.enableBP = false;*/
		//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
		vm.diffDays = "";
		vm.promoOneAdditionalAmt = "";
		vm.promoTwoAdditionalAmt = "";
		vm.pifPromoAdditionalAmt = "";
		vm.promoAddAmt = "";
		vm.showAddAmt = false;
		vm.showPIFPromoOption = false;
		vm.promoPayment = "";
		vm.changePaymentMode = changePaymentMode;
		vm.calculateDiffDays = calculateDiffDays;
		vm.promoPaymentSelectOperation = promoPaymentSelectOperation;
		vm.verifyEmployeePassWord = verifyEmployeePassWord;
		$rootScope.langSelectedEn = true;
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
		$scope.navigateTo = navigateTo;
		vm.unSignedEmailModal = unSignedEmailModal;//VAN-2398-Prompt for CA stores about unsigned documents 
		vm.unSignedEmailSameDay = unSignedEmailSameDay;//VAN-2398-Prompt for CA stores about unsigned documents
		vm.vcPromoAdditionalAmt = "";//VAN-2841-AWP-Value City $1 Promo
		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
		vm.quoteCalculator = quoteCalculator;
		vm.getAlternateAmount = getAlternateAmount;
		vm.buildQuoteForVarPay = buildQuoteForVarPay;
		vm.enableVariablePay = false;
		$rootScope.showCalforVarPay = false;
		vm.newPaymentMode = false;
		vm.variablePay = {};
		vm.buildCalendarForVarPay = buildCalendarForVarPay;
		vm.variablePayCheck = variablePayCheck;
		vm.invoiceReductionPaymentAmount;
		vm.invoiceReductionPayment = SharedDataSvc.invoiceReductionPayment;

		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
		
		init();

		function init() {

			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			$scope.isSpanishStore = $cookies.getObject('isSpanishStore');

			//setting processing fee
			//vm.processingFee = SharedDataSvc.globalVars.estimateReturn.processingFee;

			var currentDate = datesFactory.getCurrentDate(),
			    quoteDates = datesFactory.setQuoteDates(currentDate),
			    findData = {
				'firstName' : null,
				'lastName' : null,
				'phone' : null
			},
			    engagement;
			void 0;
			vm.quoteDates = quoteDates;
			setOrderDates(quoteDates);

			// Default language to English
			SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';

			// reset inventory
			SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];

			void 0;

			showModal('Processing', 'processing-modal');

			getStoreInfoFactory.getData().then(function(response) {
				$log.log('[Agreement.init] STORE INFO', response);
				vm.storeInfo = response;
				SharedDataSvc.globalVars.storeSetup = response;
				if(response.vanStoreId) {
					$rootScope.storeNo = response.vanStoreId;
 					$rootScope.state = response.state;
 				}
				$rootScope.invoiceMethod = response.invoiceMethod;
				setSkipButtonVisibility(vm.storeInfo.state);
				vm.isEPOAmendmentEnabled = vm.storeInfo.isEPOAmendmentEnabled;

				vm.arrAdjustablePayment = vm.storeInfo.adjustableInitPayment.split(",");
				vm.isAdjustablePayment = parseInt(vm.arrAdjustablePayment[0]);

				stateRestrictFactory.getStateRestrictions().then(function (response) {
					vm.stateRestrictions = response.stateRestrictions[0];
					vm.getRAInitials = _.find(vm.stateRestrictions.allowRAInitials, {'state': vm.storeInfo.state}); 
				});

				stateRestrictFactory.getAllStoreRestrictions(vm.storeInfo.state).then(function(response) {
					vm.restrictions = response;
					vm.storeLatLng = {
						'lat' : vm.storeInfo.latitude,
						'lng' : vm.storeInfo.longitude
					};
					vm.storeRadius = response.allowedRentalRadiusMiles;
					storeExclusions();
					$log.log('[Agreement.init] STORE RESTRICTIONS', response);
				});
			});
			
			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				/*VAN-774 - Biweekly renewal payments Long Term*/
				vm.paymentSelected  = response.paymentMode;
				void 0;
				/*VAN-4050 AWP - show initial payment details before agreement creation  - order screen invoice reduction payment changes */
				if(vm.invoiceReductionPayment) {
					vm.invoiceReductionPaymentAmount = response.invoiceReductionPaymentAmount;
					void 0;
				}

				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				if(vm.storeInfo.paymentModeEnabled != undefined && vm.paymentSelected == undefined){
					vm.paymentSelected = "Monthly";
				}
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				if(response.paymentMode == "Fourweek" || response.paymentMode == "FOURWEEK"){//VAN-979-AWP: Flexible payment option details were not populated for the customer created from KIOSK
					vm.paymentSelected ="4 Weeks";
				}
				/*VAN-963-Renewal Payment Amount & Number of Payment not appearing - Starts*/
				if(vm.paymentSelected != undefined && vm.paymentSelected != null && vm.paymentSelected == "MONTHLY"){
					vm.paymentSelected = "Monthly";
				}
				/*VAN-963-Renewal Payment Amount & Number of Payment not appearing - Ends*/
				/*VAN-979-AWP: Flexible payment option details were not populated for the customer created from KIOSK - Starts*/
				if(vm.paymentSelected != undefined && vm.paymentSelected != null && vm.paymentSelected == "BIWEEKLY"){
					vm.paymentSelected = "Biweekly";
				}
				/*VAN-979-AWP: Flexible payment option details were not populated for the customer created from KIOSK - Ends*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				//commented code
				/*vm.agreementStatus = response.agreementStatus;
				if(response.agreementHTId!=null && response.agreementHTId!=undefined){
					vm.agreementHTId = response.agreementHTId;
				}	*/	
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				
				if(response.agreementOriginator && response.agreementOriginator.toLowerCase() == "hightouch"){
					vm.isHTCustomer = true;
				}

				$log.log('[Agreement.init] customerDataFactory.findByEngagementId response: ', response);
				vm.orderContent = (response.orderContent !== null && response.orderContent !== '') ? JSON.parse(response.orderContent) : null;
				vm.engagement = response;
				$log.log('[Agreement.init] engagement Order', vm.orderContent);
				$log.log('[Agreement.init] engagement', vm.engagement);

				vm.customer.customerFirstName = findData.firstName = vm.engagement.customer.fName;
				vm.customer.customerLastName = findData.lastName = vm.engagement.customer.lName;
				findData.phone = vm.engagement.customer.phNum;
				findData.engagementId = vm.engagement.engagementId;//AC14937 code changes added

				vm.engagement = {};
				var engagementProps = ['engagementId', 'orderStatus', 'deliveryStatus', 'paymentStatus', 'salesStatus', 'agreementStatus', 'applicationStatus', 'orderOriginator'];
				// cherry pick the needed properties for a proper engagement
				for (var i = 0; i < engagementProps.length; i++) {
					vm.engagement[engagementProps[i]] = response[engagementProps[i]];
				}
				
				vm.engagement['customerId'] = response.customer.customerId;
				vm.engagement['customerHtID'] = response.customerHTId;
				vm.engagement['customerHtStoreId'] = response.customerHTStoreId;
				vm.engagement['vanStoreId'] = response.vanStore.storeId;
				void 0;	
				applicationService.getApplication(findData).then(function(response) {
					void 0;
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show*/
					/*vm.custClubStatus = response.custClubStatus;*///commented code
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Starts*/
					$scope.stepsListEn = [
					          			'Application and Order (either can be first)',
					          			'Order Confirmation',
					          			'Agreements',
					          			'Payment',
					          			'Invoice & Delivery or Generate Credit Card (either can be first)'
					          		];
					          		$scope.stepsListEs = [
					          			"Aplicación y orden (Cualquiera puede ser primero)",
					          			"Confirmación del pedido",
					          			"Acuerdos",
					          			"Pago",
					          			"Factura y entrega o genere la tarjeta de crédito (Cualquiera puede ser primero)"	
					          		];
					if(response.VanEngagement.agreementStatus === 'Voided' && response.VanEngagement.orderStatus === 'NotStarted'){
						showModal('enforceOrderVoidedAgreement');
					}else{
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Ends*/
					vm.customerFind = ( typeof response !== "object") ? JSON.parse(response) : response;
					var signerStatus = vm.customerFind.VanEngagement.signerStatus ? 
						JSON.parse(vm.customerFind.VanEngagement.signerStatus).signerStatus : '';
					var isAgreementPending = _.filter(signerStatus, {signingStatus :"Pending"});
					$scope.isAgreementPending = isAgreementPending;//VAN-2398-Prompt for CA stores about unsigned documents 
					//VAN-672 - Low Down Initial Payment should be displayed on RA
					vm.promoSelectedAtAgreement = (vm.customerFind.VanEngagement.promoSelectedAtAgreement !== null && vm.customerFind.VanEngagement.promoSelectedAtAgreement !== '') ? JSON.parse(vm.customerFind.VanEngagement.promoSelectedAtAgreement) : null;
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
					if(vm.promoSelectedAtAgreement != undefined){
					//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
					/*if(vm.promoSelectedAtAgreement.code === "H"){
							vm.promoPayment = vm.storeInfo.noCalcPromos[0].payment;
						}else if(vm.promoSelectedAtAgreement.code === "J"){
							vm.promoPayment = vm.storeInfo.noCalcPromos[1].payment;
						}else if(vm.promoSelectedAtAgreement.code === "D"){
							vm.promoPayment = vm.promoSelectedAtAgreement.payment;
						}//VAN-2841-AWP-Value City $1 Promo - Starts
						else if(vm.promoSelectedAtAgreement.code === "Q"){
							vm.promoPayment = vm.storeInfo.noCalcPromos[0].payment;
						}*///VAN-2841-AWP-Value City $1 Promo - Ends
						//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
						
						for(var i in vm.storeInfo.noCalcPromos)
						{
						if(vm.promoSelectedAtAgreement.code === vm.storeInfo.noCalcPromos[i].code){
							vm.promoPayment = vm.storeInfo.noCalcPromos[i].payment;
						}
						}
						if(vm.promoSelectedAtAgreement.code === "D"){
							vm.promoPayment = vm.promoSelectedAtAgreement.payment;
						}
					//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
						else if(vm.promoSelectedAtAgreement.desc === ""){
							vm.promoPayment = vm.promoSelectedAtAgreement.payment;
							vm.enableVariablePay = true;
						}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
					}
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
						
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
					} else {
						vm.languagePreference = false;
						SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
					}
					
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/ //LDW should be hidden after selecting finish later
					vm.showLDWSigned = false;
					if(vm.customerFind.agreement){
						if(!vm.customerFind.agreement.UnsignedLDW){
							vm.showLDWSigned = true;
						} 
					} else {
						vm.showLDWSigned = true;
					}
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
					
					/*VAN-333 : AWP - Multiengagement IN shows BP in the second time when associate finishes later and then signs the agreement.*/
					vm.showBPSigned = false;
					if(vm.customerFind.agreement){
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show*/ //Bp should not visible after selcting finish later
						//Commented Code
						/*if(vm.customerFind.agreement.CLB && vm.custClubStatus !== 'MEMBER'){*///if(vm.customerFind.VanEngagement.clubStatus == -1){
						 if(vm.customerFind.agreement.CLB && $cookies.getObject('clubActivate')){//if(vm.customerFind.VanEngagement.clubStatus == -1){
							vm.showBPSigned = true;
						} 
					} else {
						vm.showBPSigned = true;
					}
					
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*///Next Day scenario LDW & BP should not blank page
					if(vm.customerFind.agreement && vm.customerFind.agreement.agreementStatus){
						if((vm.customerFind.agreement.agreementStatus === 'PENDING' || vm.customerFind.agreement.agreementStatus === 'INACTIVE')
								&& !isSameDayAgreement()){
							vm.showLDWSigned = true;
							vm.showBPSigned = true;
						}
					}
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
					
					/* VAN-333 Ends */
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/ /*Next Day Scenario*/
					//commented code 
					/*if(response.VanEngagement.agreementHTId==null && response.VanEngagement.agreementStatus === 'Voided'){
						vm.showLDWSigned = true;
						vm.showBPSigned = true;
					}*/
					/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
					
					$rootScope.agreementloaderText = (SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == 'SPANISH') ? es.common_label_processing : en.common_label_processing;
					//VAN-510 - Electronic Consent moved to app flow - Starts
					if(vm.customerFind.isCoCustomerInfoProvided){
						vm.hasCoCustomer = true;
					}
					if(vm.customerFind.VanEngagement.agreementOriginator !== null && vm.customerFind.VanEngagement.agreementOriginator.toLowerCase() === "hightouch"
						&& vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
						$rootScope.htConsent = true;
					}
					//VAN-510 - Electronic Consent moved to app flow - Ends
					if(vm.customerFind.VanEngagement.agreementHTId && vm.customerFind.VanEngagement.agreementStatus.toLowerCase() == 'pending') {
						if(isAgreementPending.length > 0){
							if(isAgreementPending.length > 1 && vm.customerFind.isCoCustomerInfoProvided){ //&& $cookies.getObject('isCoCustomerSignerPending') 
								vm.isCoCustomerSignerPending = true;
							} else if(isAgreementPending[0].customerType.toLowerCase() == 'coapplicant'){
								vm.signingPerson = {
									firstName : vm.customerFind.coCustomerInfo.firstName,
									lastName : vm.customerFind.coCustomerInfo.lastName
								};
								vm.signersPresent = 'COAPPLICANT';
							} else {
								vm.signingPerson = {
									firstName : vm.customer.customerFirstName,
									lastName : vm.customer.customerLastName
								};
								vm.signersPresent = 'APPLICANT';
							}
							//VAN-2398-Prompt for CA stores about unsigned documents - Starts
							$scope.coCustSigner = $cookies.getObject('isCoCustomerSignerPending');
							if($rootScope.state === 'CA' && vm.customerFind.VanEngagement.agreementHTId != null && isSameDayAgreement() && ($scope.coCustSigner != null)){
								var unSignedEmailModal = modalService.open({
									scope: $scope,
									windowClass: '',
									templateUrl: 'partials/modal_unSignedEmail.html',
									backdrop: 'static'
								});
								unSignedEmailModal.result.then(function(){
									vm.unSignedEmailSameDay();
								});
								return false;
							}else{//VAN-2398-Prompt for CA stores about unsigned documents - Ends
							if($cookies.getObject('isCoCustomerSignerPending')){
								if(vm.customerFind.isCoCustomerInfoProvided == true){
								//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Same day when corenter give finish later 
									if(isAgreementPending[0].customerType.toLowerCase() == 'applicant'){
										vm.afterSigningConsent();
									}
									else{
									vm.afterSigning();
									}
									//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Same day when coapplicant give finish later
									}
								//VAN-510 - Electronic Consent moved to app flow
								else{
									$rootScope.showConsent = true;
									vm.askForSigningConsent();}
								$cookies.putObject('isCoCustomerSignerPending', null);
								return false;
							} else if(vm.customerFind.isCoCustomerInfoProvided && isAgreementPending.length == 1 && vm.customerFind.VanEngagement.agreementOriginator !== null && vm.customerFind.VanEngagement.agreementOriginator.toLowerCase() === "hightouch"){
								//VAN-510 - Electronic Consent moved to app flow
								if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
									$rootScope.showConsentForHT = true;
								}
								vm.afterSigningConsent();
								return false;
							}
						}
					} 
				} 

					if(vm.customerFind.isCoCustomerInfoProvided){
						vm.hasCoCustomer = true;
					}

					if(vm.isHTCustomer || ((vm.hideBp && vm.restrictions.skipLdw) && vm.customerFind.VanEngagement.agreementHTId)){
						//VAN-2398-Prompt for CA stores about unsigned documents - Starts
						if($rootScope.state === 'CA' && vm.customerFind.VanEngagement.agreementHTId != null && isSameDayAgreement()){
							var unSignedEmailModal = modalService.open({
								scope: $scope,
								windowClass: '',
								templateUrl: 'partials/modal_unSignedEmail.html',
								backdrop: 'static'
							});
							unSignedEmailModal.result.then(function(){
								vm.beforeSigning();
							});
						}else{//VAN-2398-Prompt for CA stores about unsigned documents - Ends
						vm.beforeSigning();}
						return false;
					}

					if (!response.VanEngagement) {
						void 0;
						showModal('customerNotFound');
					} else {
						void 0;
						/* Setting the agreement HTID from findCustomer response */
						vm.agreementHTID = response.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*//*Completed till payment on same day and docusign on next day*/
						//Commented Code
						/*if(!isSameDayAgreement() && vm.customerFind.agreement.agreementStatus=='ACTIVE'){
							vm.showLDWSigned = false;
							vm.showBPSigned = false;
							if(vm.isHTCustomer){
								vm.submitAgreements();
							}else{
								beforeSigningModal();								
							}
						}*/
						/*VAN - If BP not selected show $49, if BP select do not show - Ends*/
		
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if(zip && (zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								void 0;
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Starts*/
					}
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer) - Ends*/
				}
				}, function(response) {
					//modalService.dismiss();
					vm.customerFind = response.data.errorData;

					if (!response.data.errorData.VanEngagement) {
						void 0;
						if(response.data.errorData.status_code == "ENG-A4001-400"){
							showModal('agreementAlreadyExist');
						}else{
							showModal('customerNotFound');
						}
					} else {
						void 0;
						vm.agreementHTID = response.data.errorData.VanEngagement.agreementHTId;
						//INCTEC0330947 code changes
						isSameDayAgreement();
						//Defect 26474 code changes starts
						var zip = vm.customerFind.VanEngagement.customer.addressZipcode;
						var zipCode = zip;
						if((zip.length > 5) && (zip.indexOf("-") < 1)) {
							zipCode = zip.substr(0,5) + '-' + zip.substr(5);
						}
						//Defect 26474 code changes ends
						if($scope.isSpanishStore) {
							processAgreementData();
						} else {
							isZipInStoreRadius(zipCode).then(function(response) {
								if (response) {
									processAgreementData();
								} else {
									showModal('outsideRadius');
								}
							});
						}
					}
				});
			}).catch(function(response) {
				$log.log('[Agreement.init] could not get customer data', response);
			});

			//get processing fee
			orderEstimatePutFactory.getProcessingFee().then(function(processingFee) {
				vm.processingFee = processingFee;
			});

			//get product category list
			productFactory.getProductCategories().then(function(response) {
				vm.prodCat = response.productCategories;
			});
		}

		function setLang(currentLang) {
			if (!currentLang) {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'SPANISH';
				$rootScope.langSelectedEn = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			} else {
				SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference = 'ENGLISH';
			}
			void 0;
		}

		function storeExclusions() {
			var bp = _.where(vm.agreements, {
				type : 'CLB'
			}),
			    ldw = _.where(vm.agreements, {
				type : 'LDW'
			});

			// console.log('skipBp', vm.restrictions.skipBp);
			// console.log('skipldw', vm.restrictions.skipLdw);

			if (vm.restrictions.skipBp) {
				delete vm.agreements[5];
				//Have changed here
				vm.hideBp = true;
			}

			if (vm.restrictions.skipLdw) {
				delete vm.agreements[4];
				//Have changed here
			}

			if (!vm.isEPOAmendmentEnabled) {
				delete vm.agreements[3];
				//Have changed here
			}

			void 0;
		}

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		};
		
		function handleCustomerOutOfRadius() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.engagement.applicationStatus = 'OutOfArea';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Successfully updated engagement');
				navigateTo('dashboard');
			}, function(response) {
				$log.log('[Agreement.handleCustomerOutOfRadius] Updating engagement failed');
				modalService.dismiss();
			});
		}

		function navigateToApplication() {
			$log.log(vm.engagement);
			customerDataFactory.findByEngagementId(vm.engagement.engagementId).then(function(response) {
				$log.log('[Agreement.getCustomer] customer', response);
				var person = {
					firstName : response.customer.fName,
					lastName : response.customer.lName,
					phone : response.customer.phNum,
					hasEngagementFromAgreement : true
				};
				var personData = window.btoa(JSON.stringify(person));
				$location.path('application/' + vm.engagement.engagementId).search({
					data : personData
				});
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function dateSelectionDone() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.setOrderDateAsPerSelection(vm.adjustableDateSelection);

			/*var paymentModal = modalService.open({ //changes for 9619, VAN-365:VAN: Same payment option for all on same agreement put on hold
				scope: $scope,
				templateUrl: 'partials/modal_paymentOptions.html',
				backdrop: 'static',
				windowClass: 'paymentOptionsModal'
			});
			paymentModal.result.then(function(){
				vm.orderDataForAdjustablePayment.paymentOption = vm.paymentOption;*/
			/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
			
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;}
//			VAN-2841-AWP-Value City $1 Promo
			if(vm.storeInfo.noCalcPromos[0].code === 'H' || vm.storeInfo.noCalcPromos[0].code === 'J'){
				vm.orderDataForAdjustablePayment.promotionCode = "H";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoOneResponse) {
					void 0;
					vm.promoOneQuote = promoOneResponse;
					if(vm.showAddAmt){
//						vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);}
						//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
						if(vm.restrictions.skipBp){
							vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);
						}else{
							vm.promoOneAdditionalAmt = ((vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial) - 49).toFixed(2);
						}}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
					vm.orderDataForAdjustablePayment.promotionCode = "J";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoTwoResponse) {
						void 0;
						vm.promoTwoQuote = promoTwoResponse;
						if(vm.showAddAmt){
//							vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);}
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
							if(vm.restrictions.skipBp){
								vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);
							}else{
								vm.promoTwoAdditionalAmt = ((vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial) - 99).toFixed(2);
							}}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						vm.orderDataForAdjustablePayment.promotionCode = "D";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
							void 0;
							vm.pifPromoQuote = pifPromoResponse;
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							if(vm.showAddAmt){
//								vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal - 0).toFixed(2);}
								//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								if(vm.restrictions.skipBp){
									vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
								}else{
									vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
								}}
							vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						},function(pifPromoResponse) {
							void 0;
							modalService.dismiss();
						});	
					},function(promoTwoResponse) {
						void 0;
						modalService.dismiss();
					});	
				},function(promoOneResponse) {
					void 0;
					modalService.dismiss();
				});
//				VAN-2841-AWP-Value City $1 Promo - Starts
			}else if(vm.storeInfo.noCalcPromos[0].code === 'Q'){
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					void 0;
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}

					vm.orderDataForAdjustablePayment.promotionCode = "Q";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
						void 0;
						vm.vcPromoQuote = vcPromoresponse;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
							}else{
								vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
							}}
						vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					},function(vcPromoresponse) {
						void 0;
						modalService.dismiss();
					});
				},function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
				});	
			}
			//VAN-3623-Promocode Configurable
			else if(vm.storeInfo.noCalcPromos[0].code != 'H' && vm.storeInfo.noCalcPromos[0].code != 'J' && vm.storeInfo.noCalcPromos[0].code != 'Q'){
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					void 0;
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}

					vm.orderDataForAdjustablePayment.promotionCode = vm.storeInfo.noCalcPromos[0].code;
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
						void 0;
						vm.vcPromoQuote = vcPromoresponse;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - vm.storeInfo.noCalcPromos[0].payment).toFixed(2);
							}else{
								vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) -  vm.storeInfo.noCalcPromos[0].payment).toFixed(2);
							}}
						vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					},function(vcPromoresponse) {
						void 0;
						modalService.dismiss();
					});
				},function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
				});	
			}
			//VAN-3623-Promocode Configurable
//			VAN-2841-AWP-Value City $1 Promo - Ends
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
//				Commented as part of VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
				/*vm.orderDataForAdjustablePayment.paymentMode = 'Monthly';
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(monthlyResponse) {
				 console.log('[agreementFactory.setQuotesAsPerSelection] Monthly success', monthlyResponse);
				 vm.quoteGotAsPerSelection = monthlyResponse;
					vm.orderDataForAdjustablePayment.paymentMode = 'Biweekly';
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(biweeklyResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] Biweekly success', biweeklyResponse);
					vm.quoteGotAsPerSelectionBiweekly = biweeklyResponse;
					VAN-858 - Quad Weekly Payment Frequency - Starts
						vm.orderDataForAdjustablePayment.paymentMode = 'FOURWEEK';
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(quarterlyResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] Quarterly success', quarterlyResponse);
						vm.quoteGotAsPerSelectionQuarterly = quarterlyResponse;
						VAN-858 - Quad Weekly Payment Frequency - Ends
					$scope.paymentSelected = SharedDataSvc.globalVars.paymentModeSelected;	
					vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					VAN-858 - Quad Weekly Payment Frequency - Starts
					}, function(quarterlyResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] Quarterly fail', quarterlyResponse);
						modalService.dismiss();
					}); 
					VAN-858 - Quad Weekly Payment Frequency - Ends
				}, function(biweeklyResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] Biweekly fail', biweeklyResponse);
					modalService.dismiss();
				}); 
				},function(monthlyResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] Monthly fail', monthlyResponse);
					modalService.dismiss();
				});*/
			/*VAN-774 - Biweekly renewal payments Long Term - Ends*/
			/*});	*/		
		}

		function getFirstPaymentOptions() {

			void 0;

			if (vm.orderContent.orderItems.length > 0) {
				$rootScope.agreementloaderText = (SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == 'SPANISH') ? es.common_label_processing : en.common_label_processing;
				showModal('ProcessingAgreement', 'processing-modal');

				_.forEach(vm.orderContent.orderItems, function(order, i) {
					productFactory.getProductInfo(vm.orderContent.orderItems[i]).then(function(response) {
						$log.log('product info ' + i, response);
						vm.orderArray.push(response);

						if (i === vm.orderContent.orderItems.length - 1) {

							if (vm.isAdjustablePayment) {
								setOrderDataForAdjustablePayment();
								if (vm.orderDataForAdjustablePayment.feeArray && vm.orderDataForAdjustablePayment.feeArray.length > 1) {
									if (vm.orderContent.deliveryFeeOneTime) {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = false;
									} else {
										vm.orderDataForAdjustablePayment.feeArray[1].feeAcrossTerm = true;
									}
								}
							} else {
								setOrderData();
								_.forEach(vm.orderData, function(orderData) {
									if (orderData.feeArray && orderData.feeArray.length > 1) {
										if (vm.orderContent.deliveryFeeOneTime) {
											orderData.feeArray[1].feeAcrossTerm = false;
										} else {
											orderData.feeArray[1].feeAcrossTerm = true;
										}

									}
								});
							}

							if (vm.isAdjustablePayment) {
								var start = parseInt(vm.arrAdjustablePayment[1]);
								var end = parseInt(vm.arrAdjustablePayment[2]);
								void 0;
								//VAN-1401 - Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
								if(end === 15 && vm.storeInfo.overrideAdjustableInitPaymentDays != null){
									end = parseInt(vm.storeInfo.overrideAdjustableInitPaymentDays);
								}//VAN-1401 - Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
								
								datesFactory.getDateByDaysFromNow(start).then(function(response) {
									vm.ipMinDate = response;
									vm.adjustableDateSelection = response;
									datesFactory.getDateByDaysFromNow(end).then(function(responseData) {
										vm.ipMaxDate = responseData;
										vm.openfirstPaymentSelectionCalendar();
									});
								});

							} else {
								agreementFactory.setQuotes(vm.orderData).then(function() {
									vm.quotes = agreementFactory.quotes;
									vm.showModal('firstPayment', 'first-payment-options-modal');

									// $log.log('Quotes', agreementFactory.quotes);
									// $log.log('quote dates', qouteDates);
									$log.log('[Agreement.getFirstPaymentOptions] store info', vm.storeInfo);
								});
							}
						}
					});
				});

			} else {
				void 0;
			}
		}

		/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
		function modifyPaymetMode(){
			if(vm.paymentSelected!=undefined){
				vm.modifyPayment = vm.paymentSelected;
			}else{
				vm.modifyPayment = "Monthly";
			}
			/*vm.numberOfPayments = vm.quoteGotAsPerSelection.numberOfPayments;
			vm.monthlyPayment = vm.quoteGotAsPerSelection.monthlyPaymentWTax;*/
			changePaymentMode();//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
//			showModal('modifyInitialPayment');//Commented as part of VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
		}
		
		function showInitialPayment(modifyPaymentMode){
//			showModal('Processing', 'processing-modal');//VAN-3066-AWP_Application not navigating to further screen if we change Flexible Payment Option from Initial payment Details screen.
			vm.paymentSelected = modifyPaymentMode;
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;}
			/*VAN-2843----Comment*/
//			VAN-2841-AWP-Value City $1 Promo
			/*if(vm.storeInfo.noCalcPromos[0].code === 'H' || vm.storeInfo.noCalcPromos[0].code === 'J'){
				vm.orderDataForAdjustablePayment.promotionCode = "H";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoOneResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] promoOne success', promoOneResponse);
					vm.promoOneQuote = promoOneResponse;
					if(vm.showAddAmt){
						//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
						if(vm.restrictions.skipBp){
							vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);
						}else{
							vm.promoOneAdditionalAmt = ((vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial) - 49).toFixed(2);}
					}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
					vm.orderDataForAdjustablePayment.promotionCode = "J";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoTwoResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] promoTwo success', promoTwoResponse);
						vm.promoTwoQuote = promoTwoResponse;
						if(vm.showAddAmt){
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
							if(vm.restrictions.skipBp){
								vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);
							}else{
								vm.promoTwoAdditionalAmt = ((vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial) - 99).toFixed(2);}
						}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						vm.orderDataForAdjustablePayment.promotionCode = "D";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
							console.log('[agreementFactory.setQuotesAsPerSelection] PIF Promor success', pifPromoResponse);
							vm.pifPromoQuote = pifPromoResponse;
							//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
							if(vm.restrictions.skipBp){
								vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
								}else{
									vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);}
							}//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							modalService.dismiss();
							vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
						},function(pifPromoResponse) {
							console.log('[agreementFactory.setQuotesAsPerSelection] pifPromo fail', pifPromoResponse);
							modalService.dismiss();
						});	
					},function(promoTwoResponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] promoTwo fail', promoTwoResponse);
						modalService.dismiss();
					});	
				},function(promoOneResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] promoOne fail', promoOneResponse);
					modalService.dismiss();
				});
				//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
//				VAN-2841-AWP-Value City $1 Promo - Starts
			}else if(vm.storeInfo.noCalcPromos[0].code === 'Q'){
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] PIF Promo success', pifPromoResponse);
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}

					vm.orderDataForAdjustablePayment.promotionCode = "Q";
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo success', vcPromoresponse);
						vm.vcPromoQuote = vcPromoresponse;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
							}else{
								vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
							}
						}
						modalService.dismiss();
						vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					},function(vcPromoresponse) {
						console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo fail', vcPromoresponse);
						modalService.dismiss();
					});
				},function(pifPromoResponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] pifPromo fail', pifPromoResponse);
					modalService.dismiss();
				});	
			}
*///			VAN-2841-AWP-Value City $1 Promo - Ends
			/*VAN-2843----Comment*/
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			if(vm.enableVariablePay){
				vm.ipMinDate = "";
				vm.ipMaxDate = "";
				vm.newPaymentMode = true;
				if(vm.orderDataForAdjustablePayment.paymentMode == 'Monthly'){
					vm.adjustableDateSelection = moment(vm.quoteGotAsPerSelection.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
					vm.expiryDate = vm.quoteGotAsPerSelection.expirationDate;
				}else if(vm.orderDataForAdjustablePayment.paymentMode == 'Biweekly'){
					vm.adjustableDateSelection = moment(vm.quoteGotAsPerSelectionBiweekly.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
					vm.expiryDate = vm.quoteGotAsPerSelectionBiweekly.expirationDate;
				} else if(vm.orderDataForAdjustablePayment.paymentMode == 'Fourweek'){
					vm.adjustableDateSelection = moment(vm.quoteGotAsPerSelectionQuarterly.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
					vm.expiryDate = vm.quoteGotAsPerSelectionQuarterly.expirationDate;
				}
			}
			quoteCalculator();
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
		}
		/*VAN-774 - Biweekly renewal payments Long Term - Ends*/

		function openfirstPaymentSelectionCalendar() {
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
			vm.showPIFPromoOption = false;
			//vm.selectedPromo = null;//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
			vm.showModal('adjustablePayment', 'first-adjustable-payment-modal');
		}

		function handleLdwDecline(index) {
			vm.agreements[index].eSign = false;
			showModal('declineLdw');
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
			//Commented Code
			/*if(vm.custClubStatus == "MEMBER"){
				showModal('declineLdwWhenBpActive');
			}
			else{
				showModal('declineLdw');
			}*/
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
		}

		function handleSkipBp() {
			vm.declineBpNew();
		}

		function handleBpDecline() {
			vm.declineBpNew();
		}

		function handleInitialsStateCheck() {
			if($cookies.getObject('isCoCustomerSignerPending')){
				vm.afterSigning();
			} else {
				vm.afterSigningConsent();
			}
		}

		function setSkipButtonVisibility(state) {
			if (state == "FL" || state == "CA") {
				vm.isSkipButtonEnable = true;
			}
		}

		function showModal(name, windowClass) {
			windowClass = windowClass || '';
			modalService.open({
				templateUrl : '../../../partials/modal_' + name + '.html',
				backdrop : 'static',
				windowClass : windowClass,
				scope : $scope
			});
		}

		function signLdw() {
			if (!vm.agreements[4].agree) {//Have changed me
				showModal('declineLdw', 'decline-ldw-modal');
			}
		}

		function showCorrectOrder(activeAgreementIndex) {
			if (vm.agreements[5] && vm.agreements[4]) {//Have changed here
				if (vm.activeAgreementIndex === 5 && !vm.agreements[4].agree && !vm.bpNotContingentOnLDW) {//Have changed here
					// console.log('LDW has been declined');
					vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
					//Have changed here
					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else if (vm.activeAgreementIndex === 6 && !vm.agreements[5].agree && !vm.bpNotContingentOnLDW && vm.agreements[4].agree !== 'DECLINED') {//Have changed here
					// console.log('BP is not signed');
					if (!vm.agreements[4].agree) {//Have changed here
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[4].name.es : vm.agreements[4].name.en;
						//Have changed here
					} else {
						vm.unsignedAgreement = $rootScope.selectedLanguage == 'es' ? vm.agreements[5].name.es : vm.agreements[5].name.en;
						//Have changed here
					}

					showModal('signedAgreementsOrder', 'unsigned-agreements-modal');
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}

		}

		function signAgreement(index, status) {
			void 0;
			
			if (showCorrectOrder(index)) {
				setAgreementViewed(index);	
				
				/*if(SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == "ENGLISH"){
					var sign = 'Signed via click-through AcceptanceNOW web portal.\n';
					sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
					sign += datesFactory.getCurrentDate();
				}else{
					var sign = 'Firmado vía virtual a través del sitio web AcceptanceNow.\n';
					sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
					sign += datesFactory.getCurrentDate();
				}*/
				vm.agreements[index].agree = status;
				//vm.agreements[index].signature = sign;

				//Commented as part of VAN-943
				/*if (vm.agreements[5] && vm.agreements[5].pdf !== undefined && vm.agreements[5].pdf !== null) {
					hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
				}*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				//Commented Code 
				/*if(vm.custClubStatus != "MEMBER"){
					hideBPWhenLDWisDeclined(vm.activeAgreementIndex)
				}
				if(vm.agreements[4].agree === 'SIGNED'){
					vm.enableBP = true;
				}*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				
				/*VAN-943-AWP-System not enabling BP option - Starts*/
				if(vm.agreements[4].agree != null && vm.agreements[4].agree === 'SIGNED'){
					hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
				}
				/*VAN-943-AWP-System not enabling BP option - Ends*/
			}

		}

		function hideBPWhenLDWisDeclined(index) {
			void 0;
			if (!vm.restrictions.bpNotContingentOnLdw && index === 4) {//Have changed here
				if (vm.agreements[4].agree === 'DECLINED') {//Have changed here
					void 0;
					vm.hideBp = true;
				} else {
					void 0;
					vm.hideBp = false;
					/*Commented Code*/
					/*vm.enableBP = true;*//*VAN-802 - VAN - If BP not selected show $49, if BP select do not show*/
				}
			}
			//Commented Code
			/*else{
				vm.enableBP = true;VAN-802 - VAN - If BP not selected show $49, if BP select do not show
			}*/
		}

		function declineRental() {
			//Story 29237 code changes starts
			showModal($translate.instant("common_label_processing"), 'processing-modal');
			vm.engagement.agreementStatus = 'Declined';
			updateEngagementItemFactory.putData(vm.engagement)
		          .then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement failed');
				modalService.dismiss();
			});
		}
		
		//Start - AC-13303 - to update agreementStatus in VAN
		function voidAgreement() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.engagement.agreementStatus = 'Voided';
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement with agreement status voided');
				modalService.dismiss();
				navigateTo('dashboard');
			}, function(response) {
				$log.log('Updating engagement for agreement status voided failed');
				modalService.dismiss();
			});
		}

		//End - AC-13303 - to update agreementStatus in VAN

		function declineBpNew() {
			if (vm.storeInfo.state == "FL") {
				showModal('declineBenefitsPlus');
			} else {
				showModal('benefitPlusDeclineConfirm');
			}
		}

		function declineBp(index) {
			void 0;
			if (vm.restrictions.dontSignDeclineBp) {
				showModal('declineBenefitsPlus');
			} else {
				/* The line below will reset the esign pop up check box always to none */
				vm.agreements[vm.activeAgreementIndex].eSign = false;
				showModal('eDeclineAgreement');
			}
		}

		function declineAgreement(index) {
			var sign = $translate.instant('agreement_label_declineDesc') +'\n';
			sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
			sign += datesFactory.getCurrentDate();

			vm.agreements[index].signature = sign;

			// console.log('decline', index);
			if (vm.agreements[index].agree === 'DECLINE') {
				if (vm.agreements[index].agree !== 1) {
					vm.agreements[index].agree = 'DECLINED';
					// setAgreementSignature('DECLINE');
				} else {
					showModal('declineLdw');
				}
				// Unsign BP if LDW is declined
				if (vm.agreements[index] === 4) {//Have changed here
					declineAgreement(5);
					//Have changed here
				}
			} else {
				vm.agreements[index].agree = 'DECLINED';
				// setAgreementSignature('DECLINE');
			}
		}

		function getCustomer(engagement) {
			$log.log('[Agreement.getCustomer] engagement: ', engagement);
			customerDataFactory.findByEngagementId(vm.engagementId).then(function(response) {
				customer = response;
				vm.customer = response;
				$log.log('[Agreement.getCustomer] customer', customer);
			}).catch(function(response) {
				$log.log('[Agreement.getCustomer] could not get customer data', response);
			});
		}

		function setOrderDates(paymentDates) {
			var requestDate = datesFactory.getCurrentDate();
			void 0;
			_.forEach(paymentDates, function(paymentDate, i) {
				vm.orderData[i].requestDte = requestDate;
				vm.orderData[i].nextRenewDte = paymentDate.date;
			});
		}

		function setOrderDateAsPerSelection(selectedDate) {
			var requestDate = datesFactory.getCurrentDate();
			void 0;

			vm.orderDataForAdjustablePayment.requestDte = requestDate;
			vm.orderDataForAdjustablePayment.nextRenewDte = moment(selectedDate).format('YYYY-MM-DD HH:MM:SS');
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
			vm.orderDataForAdjustablePayment.promotionNextDueDate = vm.orderDataForAdjustablePayment.nextRenewDte;
			vm.promotionNextDueDate = moment(vm.orderDataForAdjustablePayment.promotionNextDueDate).format('MM/DD');
			if(vm.promotionNextDueDate === 'Invalid date'){//VAN-2379-AWP-Getting invalid date in initial payment popup screen - Starts
				vm.promotionNextDueDate = moment(selectedDate).format('MM/DD');
			}//VAN-2379-AWP-Getting invalid date in initial payment popup screen - Ends
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			if(vm.newPaymentMode){
				vm.showAddAmt = false;
			}else{
				calculateDiffDays(selectedDate);}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
		}
		
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
		function calculateDiffDays(selectedDate){
			var nextDuedate = moment(selectedDate);
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			if(vm.enableVariablePay){
				vm.diffDays = nextDuedate.diff(vm.ipMinDate, 'days')+1; 
			}else{
				var currentdate = moment(new Date());
				vm.diffDays = nextDuedate.diff(currentdate, 'days')+1; 
			}
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			if(vm.diffDays > 15 && !vm.enableVariablePay){
//				vm.additionalDays = vm.diffDays - 15;
				vm.showAddAmt = true;
			}else if(vm.diffDays > 0 && vm.enableVariablePay){//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
				vm.showAddAmt = true;
			}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			else{
				vm.showAddAmt = false;
			}
		}
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends

		function formatItemPrice(price) {
			var formattedPrice;
			if (String(price).indexOf('.') > -1) {
				price = String(price).split('.');
				if (price[1].length === 2) {
					formattedPrice = Number(price[0] + price[1]);
				} else {
					formattedPrice = Number(price[0] + price[1] + '0');
				}
			} else {
				formattedPrice = Number(price + '00');
			}
			return formattedPrice;
		}

		//checking if Associate password in case of max associate pin attempts
		function validateAssociatePasswordCash(pass) {
			var auth = SharedDataSvc.base64.decode($cookies.getObject('store_nick_name'));
			if (auth === pass) {
				vm.employeePinError = false;
				vm.employeeId = '';
				showModal('agreementEmployeePin');
			} else {
				vm.invalidAssociatePass = true;
			}
		}

		//asking for Associate PIN if it manned store
		function checkStore() {
			/*VAN - 555 - Remove EMP PIN while create agreements for manned locations - Starts*/
			/*if (vm.storeInfo.isManned == true && (!$cookies.getObject('isHybrid'))) {
				showModal('agreementEmployeePin');
			} else {*/
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
			/*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos && vm.selectedPromo === 'AutoPayRequired' & vm.autopayAgree == 'Agreed' ) {
				showPaymentInitial(vm.selectedPromo);
			}
			else{*/
				setFirstPaymentDate();
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				//createAgreement();
				if(vm.restrictions.skipLdw && vm.restrictions.skipBp ){  /*vm.storeInfo.state == "CA" || vm.storeInfo.state == "WI"*/
					createAgreement(); //'CA' & 'WI' state direct create agr call even for van customer
				}
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
			/*}*/
			//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
			/*}*/
			/*VAN - 555 - Remove EMP PIN while create agreements for manned locations - Ends*/
		}
		
		//AC24649 Code changes starts
		function agreementCodeVerify() {
			if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
					($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
			void 0;
			showModal('ProcessingAgreement', 'processing-modal');
			$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
			agreementFactory.agreementVerification(vm.engagementId).then(
					function (response) {
						modalService.dismiss();
						$scope.textCode = response;
						var submitTextCodeModal = modalService.open({
							title: 'Confirm Email',
							templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
							scope: $scope,
							backdrop: 'static'
						});
					},
					// Error
					function (error) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(error);
						void 0;
					});
			}
			//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Same day-starts
			else if (!vm.isHTCustomer && (vm.customerFind.agreement.agreementStatus=='PENDING'|| vm.customerFind.agreement.agreementStatus=='ACTIVE') &&  
								vm.customerFind.VanEngagement.agreementStatus.toLowerCase() == 'pending' && vm.customerFind.isCoCustomerInfoProvided){
							
					vm.afterSigningConsent();
					
						}
			//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-same day-ends
			//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts
			else{
				verifySignerDetails();
				//beforeSigningModal();
			}

		}

		function verifySignerDetails(){
			if(vm.signer.customer == 'true' && vm.signer.coCustomer == 'true') {
				vm.signersPresent = 'BOTH';
				vm.signingPerson = {
						firstName : vm.customer.customerFirstName,
						lastName : vm.customer.customerLastName
				};
			} else if (vm.signer.coCustomer == 'true'){
				vm.signersPresent = 'COAPPLICANT';
				vm.signingPerson = {
						firstName : vm.customerFind.coCustomerInfo.firstName,
						lastName : vm.customerFind.coCustomerInfo.lastName
				};
			} else {
				vm.signersPresent = 'APPLICANT';
				vm.signingPerson = {
						firstName : vm.customer.customerFirstName,
						lastName : vm.customer.customerLastName
				};
			}
			beforeSigningModal();
		}
		//VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends
		
		function reselectNextPaymentError() {
			modalService.open({
				templateUrl : 'partials/modal_confirmInitialPaymentInfo.html',
				backdrop : 'static',
				scope : $scope
			});
		}
		//AC24649 Code changes ends
		
		//checking for Associate PIN if entered or not
		function checkAssociatePin(pin) {
			if (pin) {
				setFirstPaymentDate();
				createAgreement();
			} else {
				$scope.noEmpPin = true;
				void 0;
			}
		}

		function setFirstPaymentDate() {
			if (vm.isAdjustablePayment) {
				var selectedDate = moment(vm.adjustableDateSelection).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				void 0;
			} else {
				var selectedDate = moment(vm.quoteDates[vm.firstPayment].date).format('YYYYMMDD');
				SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate = selectedDate;
				void 0;
			}
		}

		//sending Associate PIN through post
		function setEmployeeId(employeeId) {
			SharedDataSvc.globalVars.agreementPrePut.empin = employeeId;
		}

		function processAgreementData() {
			if(vm.isHTCustomer){
				modalService.dismiss();
				return false;
			}

			if(vm.hideBp && vm.restrictions.skipLdw && vm.customerFind.VanEngagement.agreementHTId){
				vm.beforeSigning();
				return false;
			}

			//modalService.dismiss();
			if (vm.isAdjustablePayment) {
				setCustomerOrderDataForAdjustablePayment(vm.customerFind);
			} else {
				setCustomerOrderData(vm.customerFind);
			}

			//AC 25996 code changes			
		 if (isSameDayAgreement() && (vm.agreementHTID !== undefined && vm.agreementHTID !== null)) {
				void 0;
				handleSameDayAgreements();
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Starts
			else if (!isSameDayAgreement() && vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && vm.customerFind.agreement.agreementStatus === 'ACTIVE' && vm.customerFind.VanEngagement.agreementStatus === 'Pending'){
				void 0;
				//modalService.dismiss();
				handleSameDayAgreements();//VAN-1427-AWP - Should not create the new agreement on next day.
			}
			//VAN-735 - PRB0042975- AWP - Application creating new agreement for an active customer re-engaging next day - Ends 
			else {
				//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
				setOrderDataForAdjustablePayment();
				vm.orderDataForAdjustablePayment.requestDte = datesFactory.getCurrentDate();
				vm.orderDataForAdjustablePayment.nextRenewDte = datesFactory.getCurrentDate();
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
					void 0;
					vm.cashPrice = response.cashPrice;
					showModal('langOption', 'modal-lang-option');
				},function(response) {
					void 0;
					modalService.dismiss();
				});
				//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			}
		}
		
		function showSameDayAgreementModal(){
			void 0;		
			var processingModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_sameDayAgree.html',
				backdrop: 'static'
			});
		}

		function createAgreement() {
			void 0;
			showModal('ProcessingAgreement', 'processing-modal');
			var agreementData = SharedDataSvc.globalVars.agreementPrePut,
			    prodCat,
			    extendedWarranty = {
				"racBrand" : "ACP",
				"itemModelNumber" : "ExtSvc",
				"racModelNumber" : "ESVPLAN-1",
				"depreciableLifeInMonths" : 24,
				"racRateCode" : "ESV396",
				"itemOriginalCost" : formatItemPrice(vm.orderContent.extendedServiceAmount)//AC21269 code change
			};

			// Set agreement data to send before creating the agreement
			void 0;
			agreementData.customer['id'] = vm.engagement.customerId;
			agreementData.customer.engagementId = Number(vm.engagementId);
			agreementData.customer.languagePreference = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference;
			agreementData.deliveryFee = formatItemPrice(vm.orderContent.deliveryFee);//AC23321 code change 
			agreementData.processingFee = (vm.processingFee * 100);
			agreementData.emailUnsignedAgreements = false;

			void 0;

			productFactory.getProductCategories().then(function(response) {
				prodCat = response.productCategories;

				void 0;
				_.forEach(vm.orderContent.orderItems, function(item, index) {
					var orderItem = {
						"racBrand" : "ACP", // 'ESV' for extended
						"itemModelNumber" : "", // 'ExtSvc' for extended
						"racModelNumber" : "",
						"depreciableLifeInMonths" : 24,
						"itemOptions" : "",
						"itemOriginalCost" : 0
					};
		
					orderItem.depreciableLifeInMonths = 24;
					orderItem.itemModelNumber = "";
					orderItem.itemOptions = productFactory.buildItemOptions(item);
					orderItem.itemOriginalCost = formatItemPrice(item.itemPrice);
					orderItem.racBrand = "ACP";
					orderItem.racModelNumber = productFactory.getRacModelNumber(prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
					void 0;
					agreementData.inventoryList.push(orderItem);
				});

				/*VAN-4050 AWP - show initial payment details before agreement creation  - order screen invoice reduction payment changes */

				if (vm.invoiceReductionPayment && vm.invoiceReductionPaymentAmount > 0) {
					var orderItem = {
						"racBrand": "IVR",
						"itemModelNumber": "",
						"racModelNumber": "HTIVR-DEPAMT",
						"depreciableLifeInMonths": 24,
						"itemOptions": "1",
						"itemOriginalCost": (vm.invoiceReductionPaymentAmount * 100)
					}
					orderItem.itemModelNumber = "";
					orderItem.racBrand = "IVR";
					orderItem.racModelNumber = "HTIVR-DEPAMT"
					orderItem.itemOptions = "1";
					orderItem.itemOriginalCost = (vm.invoiceReductionPaymentAmount * 100);
					orderItem.depreciableLifeInMonths = 24;
					void 0;
					agreementData.inventoryList.push(orderItem);
				}

				if (vm.orderContent.extendedServiceAmount > 0) {
					agreementData.inventoryList.push(extendedWarranty);
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
				if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos) {
					//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					for(var promo in vm.storeInfo.noCalcPromos)
					{
						if(vm.selectedPromo===vm.storeInfo.noCalcPromos[promo].code)
						{
					//if(vm.selectedPromo === 'AutoPayRequired'){
					if(vm.selectedPromo === 'H'){
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
						if(vm.showAddAmt){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.promoOneQuote.initialRaPaymentTotal).toFixed(2);
							}else{//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							agreementData.selectedPromoOptions.payment = (vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial).toFixed(2);}
						}else{
							agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
						}
					//	}else if(vm.selectedPromo === 'WithoutAutoPay'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
					}else if(vm.selectedPromo === 'J'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
						if(vm.showAddAmt){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.promoTwoQuote.initialRaPaymentTotal).toFixed(2);
							}else{//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							agreementData.selectedPromoOptions.payment = (vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial).toFixed(2);}
							//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
							}else{
								agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
							}
						}else if(vm.selectedPromo === 'Q'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
								agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
								if(vm.showAddAmt){
									if(vm.restrictions.skipBp){
										agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal).toFixed(2);
									}else{
										agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial).toFixed(2);}
								}else{
									agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
								}
							}
						//VAN-3623-AWP - set promocode configurable-Starts
						else if(vm.selectedPromo != 'Pif' && vm.selectedPromo != 'payAdditionalAmount'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
							agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[promo];
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal).toFixed(2);
								}else{
									agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial).toFixed(2);}
							}else{
								agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[promo].payment;
							}
						}
						//VAN-3623-AWP - set promocode configurable-Ends
						//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
						}
					}
			//}else if(vm.selectedPromo === 'Pif'){
			  if(vm.selectedPromo === 'Pif'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
						var pifPromo = {
								"code": "D",
								"desc": "$0 START PIF",
								"payment": "0"
						};
						agreementData.selectedPromoOptions = pifPromo;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
							agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
						}else{
							if(vm.restrictions.skipBp){//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								agreementData.selectedPromoOptions.payment = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
						}
					}//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
//					VAN-2841-AWP-Value City $1 Promo - Starts
				//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
				/*	else if(vm.selectedPromo === 'vcPromo'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
						agreementData.selectedPromoOptions = vm.storeInfo.noCalcPromos[0];
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								agreementData.selectedPromoOptions.payment = (vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial).toFixed(2);}
						}else{
							agreementData.selectedPromoOptions.payment = vm.storeInfo.noCalcPromos[0].payment;
						}
					}*/
				//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
//					VAN-2841-AWP-Value City $1 Promo - Ends
					//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
					else if(vm.selectedPromo === 'payAdditionalAmount'){
						var promoInfo = {
								"code": "",
								"desc": "",
								"payment": $scope.amount
						};
						agreementData.varInitPmtFunc = null;
						agreementData.varInitPmtNextDueDate = SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate;
						$scope.varAmt = null;
						if(vm.showAddAmt){
							if(vm.restrictions.skipBp){
								agreementData.varInitPmtAmt = (vm.variablePayQuote.initialRaPaymentTotal).toFixed(2);
							}else{
								agreementData.varInitPmtAmt = (vm.variablePayQuote.initialRaPaymentTotal + vm.variablePayQuote.bplusAmountInitial + vm.variablePayQuote.bplusTaxInitial).toFixed(2);
							}
							promoInfo.payment = agreementData.varInitPmtAmt;
							agreementData.varInitPmtAmt = agreementData.varInitPmtAmt.replace('.','');
						}else{
							agreementData.varInitPmtAmt = 	$scope.amount.replace('.','');
						}
						agreementData.selectedPromoOptions = promoInfo;
					}
					//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
				}
				//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
				$log.log('[Agreement.createAgreement] PRE CREATE AGREEMENT', agreementData);
				
				/*VAN-774 - Biweekly renewal payments Long Term - Starts*/
				 /* VAN-858 - Quad Weekly Payment Frequency*/ 
				if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.paymentModeEnabled!=undefined){
					if(vm.paymentSelected == "4 Weeks"){
						agreementData.paymentMode = "Fourweek";
					}
					else{
					agreementData.paymentMode = vm.paymentSelected;
					}
				}else{
					agreementData.paymentMode = "MONTHLY";
				}
				/*VAN-774 - Biweekly renewal payments Long Term - Ends*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				if(vm.agreements[4]){
					if(vm.agreements[4].agree === 'SIGNED'){
						agreementData.waiverStatus = 'ACCEPTED'; //waiverStatus - needs to be changed
					}else{
						agreementData.waiverStatus = 'DECLINED';
					}
				}else if(vm.agreements[4]==undefined){
					agreementData.waiverStatus = 'DECLINED';
				}
				
				if(vm.agreements[5]){
					if(vm.agreements[5].agree === 'SIGNED'){
						agreementData.clubActivate = true;
						$cookies.getObject('clubActivate');
						/*$rootScope.hideBPOnReenagage = true; *///should not show BP on second time
					}else{
						agreementData.clubActivate = false;
						$cookies.getObject('clubActivate');
						/*$rootScope.hideBPOnReenagage = false;*/
					}
				}else if(vm.agreements[5]==undefined || vm.restrictions.skipBp){
					agreementData.clubActivate = false;
					$cookies.getObject('clubActivate');
				}
				
				if($cookies.getObject('clubActivate')){
					if(agreementData.clubActivate==true){
						$cookies.putObject('clubActivate',true);
					}else{
						$cookies.putObject('clubActivate',false);
					}
				}
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
				agreementData.agreementOriginator = 'AWP';//VAN-2643-AWP/Kiosk-Update Agreement Originator
				/*VAN-4050 AWP - show initial payment details before agreement creation  - order screen invoice reduction payment changes */
								
				if(vm.invoiceReductionPayment) {
					agreementData.invoiceReductionPaymentAmount = (vm.invoiceReductionPaymentAmount*100);
				}
				void 0;
				agreementFactory.createAgreement(agreementData, keys).then(function(response) {
					response = JSON.parse(response);
					$log.log('[Agreement.createAgreement] AGREEMENT DATA', response);
					vm.agreementData = response;
					if((!vm.storeInfo.isManned && vm.storeInfo.agreementVerification) ||
							($cookies.getObject('isHybrid') && vm.storeInfo.agreementVerification)){
						void 0;
						$scope.verifyAgreement.agrVerificationNumber = vm.storeInfo.agrVerificationNumber;
						agreementFactory.agreementVerification(vm.engagementId).then(
								function (response) {
									modalService.dismiss();
									$scope.textCode = response;
									var submitTextCodeModal = modalService.open({
										title: 'Confirm Email',
										templateUrl: 'partials/modal_confirmAgreementVerification.tpl.html',
										scope: $scope,
										backdrop: 'static'
									});
								},
								// Error
								function (error) {
									// sending error to Raygun
									SharedDataSvc.reportRaygun(error);
									void 0;
								});
					}else{
						modalService.dismiss();
						//VAN-2398-Prompt for CA stores about unsigned documents - Starts
						if($rootScope.state === 'CA'){
							vm.unSignedEmailModal();
						}else{//VAN-2398-Prompt for CA stores about unsigned documents - Ends
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
						if(vm.hasCoCustomer){ //PR-State
							var coCustomerSigningModal = modalService.open({
								scope: $scope,
								windowClass: '',
								templateUrl: 'partials/modal_coCustomerSigning.html',
								backdrop: 'static'
							});
							coCustomerSigningModal.result.then(function(){
								vm.checkSigner();
							});
						}else{
							if(!vm.hideBp || !vm.restrictions.skipLdw){
								beforeSigningModal();
							}
						}
						/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
						if(vm.hideBp && vm.restrictions.skipLdw){
							vm.beforeSigning();
							return false;
						}
						//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
					}
				}//VAN-2398-Prompt for CA stores about unsigned documents
					setPdfData(response.documentsLst);
				}).catch(function(response) {
					modalService.dismiss();
					// reset inventory
					SharedDataSvc.globalVars.agreementPrePut.inventoryList = [];
					
					/* Same day agreement scenario check */
					if (response.status === 400 && response.data.errorData.status_message.indexOf('A completed agreement already exists') > -1) {
						showSameDayAgreementModal();
						return;
					}

					//time out scenario, record Locked scenario 409 and brand model not found scenario
					if (response.status === 502 || response.data.errorData.status_code === "ENG-A4001-409" || response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {

						/* Showing modal only for one scenario. Rest all are handled in service-agent.js */
						if (response.data.errorData.status_message === $translate.instant("agreement_label_errorModelNotFound")) {
							showModal('agreementError');
						}
						$log.log('[Agreement.createAgreement] could not create agreements', response);
					}else {
						$log.log('Error processing create agreement', response);
						modalService.dismiss();
						//var status_details = JSON.parse(response.data.errorData.status_details);
						void 0;
						//console.log('STATUS', status_details);
						//error handling when PIN is incorrect
						if (response.data.errorData.status_message.indexOf('Employee pin is not valid') > -1) {
							vm.pinAttempts = vm.pinAttempts + 1;
							vm.initialPaymentStatus = false;
							vm.initialPaymentErrorClass = '';
							vm.employeeId = '';
							void 0;
							if (vm.pinAttempts === 3) {
								vm.pinAttempts = 0;
								vm.invalidAssociatePass = false;
								showModal('tooManyPinAttemptsAgreement');
							} else {
								vm.employeePinError = true;
								showModal('agreementEmployeePin');
							}
						} else {
							vm.initialPaymentStatus = true;
							vm.initialPaymentErrorClass = 'alert-danger';
							vm.initialPaymentMessage = 'Payment Failed';
						}
					}
				});
			});
		}

		function submitTextCode(){
			$scope.verifyAgreement.textCodeError = false;
			$scope.verifyAgreement.textCodeAttemptsNumber += 1;
			if($scope.textCode == $scope.verifyAgreement.textCode && $scope.verifyAgreement.textCodeAttemptsNumber <= 3){
				modalService.dismiss();
				//showModal('checkCustomerId', 'check-customer-modal'); Commenting for Story 29360 changes - by TCS
			}else if($scope.verifyAgreement.textCodeAttemptsNumber == 3){
				$scope.verifyAgreement.textCodeAttemptsNumber = 0;
				$scope.verifyAgreement.textCode = '';
				navigateTo('dashboard');
			}
			else{
				$scope.verifyAgreement.textCodeError = true;
				$scope.verifyAgreement.textCode = '';
			}
		}

		function handleBpForAllAgreements() {
			if (vm.agreementData && vm.agreementData.documentsLst[6]) {
				$log.log('new agreements');
				vm.hideBp = false;
			} else {
				$log.log('old agreements');
				vm.hideBp = true;
			}
		}

		function isAllAgreementsSigned() {
			if(vm.isHTCustomer){
				return true;
			}
			//If LDW and BP is there
			if (vm.agreements[4] && vm.agreements[5]) {

				//If LDW is signed
				 /*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/ //Active Agr's with BP agreed should not show BP again for another agr
				//Commented Code
				/*if(vm.agreements[4].agree === "SIGNED" && vm.agreements[5].agree === undefined){
					return true;
				}*/
				/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
				if (vm.agreements[4].agree === "SIGNED") {

					//If BP is signed or declined
					if (vm.agreements[5].agree === "SIGNED" || vm.agreements[5].agree === "DECLINED") {
						return true;
					} else {
						return false;
					}

				} else {

					//If LDW is declined
					if (vm.agreements[4].agree === "DECLINED") {
						return true;
					} else {
						return false;
					}

				}
			} else {

				//If LDW and BP is not there
				if (!vm.agreements[4] && !vm.agreements[5]) {
					return true;
				} else {

					// If LDW is there and BP is not there
					if (vm.agreements[4] && !vm.agreements[5]) {

						//If LDW is either signed or declined
						if (vm.agreements[4].agree == 'SIGNED' || vm.agreements[4].agree == 'DECLINED') {
							return true;
						} else {
							return false;
						}

					} else {

						//If BP is either signed or declined
						if (vm.agreements[5].agree == 'SIGNED' || vm.agreements[5].agree == 'DECLINED') {
							return true;
						} else {
							return false;
						}

					}
				}
			}
		}

		//VAN-510 - Electronic Consent moved to app flow - Starts
		//Checkbox enable/disable for electronic consent
		$scope.eConsentAgreefnc = function () {
			if ($scope.eConsentAgree) {
				return true;
			} else {
				return false;
			}
		};

		//Show electronic Consent at Start Application Flow
		function newCustomerElectronicConsent(){
			void 0;
			/*VAN 719 Make consent form required to read -  Starts*/
			var myModalInstance = modalService.open({
				templateUrl: 'partials/modal_eAgreementNotice.html',
				/*show: true,*/
				backdrop: 'static',
				scope: $scope,
			});
			/*VAN 719 Make consent form required to read -  Ends*/
		}

		function beforeSigning(){
			if (isAllAgreementsSigned()) {
				if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "Yes" && !vm.customerFind.isCoCustomerInfoProvided){
					vm.checkSigner();
				}
				else if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT" && !vm.customerFind.isCoCustomerInfoProvided)
				{
					$rootScope.showConsent = true;
					vm.askForSigningConsent();
				}
				else if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT" && vm.customerFind.isCoCustomerInfoProvided){
					$rootScope.showConsentForHT = true;
					vm.afterSigningConsent();}
				else if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "Yes" && vm.customerFind.isCoCustomerInfoProvided){
					vm.afterSigningConsent();
					// vm.checkSigner(); //PR-State
				}
				else{
					vm.checkSigner();
				}
			//VAN-510 - Electronic Consent moved to app flow - Ends
			} else {
				$log.log('unsigned agreements');
				modalService.dismiss();

				showModal('unsignedAgreements', 'unsigned-agreements-modal');
			}
		}

		function askForSigningConsent(){
			showModal('eAgreementNotice');
			return false;
		}

		function afterSigningConsent(){
			
			if(vm.hasCoCustomer){
				vm.signer= {
					customer : false,
					coCustomer : false
				};
				//VAN-1106 Customer with correnter unable to create docusign when agreement created from HT (HT originator)
			//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-Next day-Starts
				var signerStatus = vm.customerFind.VanEngagement.signerStatus ? 
						JSON.parse(vm.customerFind.VanEngagement.signerStatus).signerStatus : '';
					var isAgreementPending = _.filter(signerStatus, {signingStatus :"Pending"});
					//VAN-1428-AWP - Should not create the new agreement on next day.
				if(isSameDayAgreement()){
					if(isAgreementPending.length > 0){
					if (!vm.isHTCustomer && vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && (vm.customerFind.agreement.agreementStatus === 'ACTIVE'|| vm.customerFind.agreement.agreementStatus === 'PENDING') && vm.customerFind.VanEngagement.agreementStatus === 'Pending' &&  isAgreementPending[0].customerType.toLowerCase() == 'coapplicant' )
					{
						vm.afterSigning(); 
						}
					else{
						var coCustomerSigningModal = modalService.open({
						scope: $scope,
						windowClass: '',
						templateUrl: 'partials/modal_coCustomerSigning.html',
						backdrop: 'static'
					});
					coCustomerSigningModal.result.then(function(){
						vm.checkSigner();
					});
				}
				}
				//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-next day-ends
				else if(!vm.isHTCustomer && vm.customerFind.agreement == undefined)
					{
						createAgreement();
					}
				else{
					var coCustomerSigningModal = modalService.open({
					scope: $scope,
					windowClass: '',
					templateUrl: 'partials/modal_coCustomerSigning.html',
					backdrop: 'static'
				});
				coCustomerSigningModal.result.then(function(){
					vm.checkSigner();
				});
			}
				 }
				
				else if(!isSameDayAgreement())
					{
					if (!vm.isHTCustomer && vm.customerFind.VanEngagement != undefined && vm.customerFind.agreement !== undefined  && vm.agreementHTID !== undefined && vm.agreementHTID !== null && vm.customerFind.agreement.agreementStatus === 'ACTIVE'  && vm.customerFind.VanEngagement.agreementStatus === 'Pending' &&  isAgreementPending[0].customerType.toLowerCase() == 'coapplicant' )
					{
						vm.afterSigning(); 
						}
				//VAN-1387-PRB0043126 - AWP - Unable to sign the agreement if customer has correnter. it says "Sign all agreements before submitting"-next day-ends
				else if(!vm.isHTCustomer)
					{
						createAgreement();
					}
				else{
					var coCustomerSigningModal = modalService.open({
					scope: $scope,
					windowClass: '',
					templateUrl: 'partials/modal_coCustomerSigning.html',
					backdrop: 'static'
				});
				coCustomerSigningModal.result.then(function(){
					vm.checkSigner();
				});
			} }
			}	//VAN-1428-AWP - Should not create the new agreement on next day.	
			 else if(vm.signersPresent && vm.signingPerson) {
				vm.afterSigning();
			}else {
				vm.signer.customer = true;
				vm.checkSigner();
			}
		}

		function afterSigning(){
			var afterSigningModal = modalService.open({
				scope: $scope,
				windowClass: 'smallModals',
				templateUrl: 'partials/modal_afterDocusignSigning.html',
				backdrop: 'static'
			});
			afterSigningModal.result.then(function(){
				vm.submitAgreements();
			});			
		}

		function checkAndNavigate(){
			modalService.dismiss();
			if(vm.isHTCustomer){
				vm.navigateTo('dashboard');
			} 
		}

		function notYetSigning(){
			var notYetSigningModal = modalService.open({
				scope: $scope,
				windowClass: 'smallModals',
				templateUrl: 'partials/modal_notYetSigning.html',
				backdrop: 'static'
			});
		}

		function checkSigner(){
			if(vm.signer.customer == 'true' && vm.signer.coCustomer == 'true') {
				vm.signersPresent = 'BOTH';
				vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
				};
			} else if (vm.signer.coCustomer == 'true'){
				vm.signersPresent = 'COAPPLICANT';
				vm.signingPerson = {
					firstName : vm.customerFind.coCustomerInfo.firstName,
					lastName : vm.customerFind.coCustomerInfo.lastName
				};
			} else {
				vm.signersPresent = 'APPLICANT';
				vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
				};
			}
			//VAN-1427-AWP - Getting Error 700 while submitting agreement on next day.
			if (!vm.isHTCustomer && vm.customerFind.agreement != undefined && vm.customerFind.agreement.agreementStatus=='ACTIVE' &&  vm.customerFind.VanEngagement.agreementStatus.toLowerCase() == 'pending' && vm.customerFind.isCoCustomerInfoProvided == false && !isSameDayAgreement() && !vm.restrictions.skipLdw && !vm.restrictions.skipBp){
								verifySignerDetails(); }		
			//VAN-1427-AWP - Getting Error 700 while submitting agreement on next day.
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
			else if(!vm.isHTCustomer && !vm.restrictions.skipLdw && !vm.restrictions.skipBp){
				createAgreement(); 
			}else if(!vm.isHTCustomer && vm.restrictions.skipLdw && vm.restrictions.skipBp){
				beforeSigningModal();
			}else{
				vm.submitAgreements();//HT Customers, Docusign URl should be called directly
			}
				/*var beforeSigningModal = modalService.open({
					scope: $scope,
					windowClass: 'smallModals',
					templateUrl: 'partials/modal_beforeDocusignSigning.html',
					backdrop: 'static'
				});
				beforeSigningModal.result.then(function(){
					vm.submitAgreements();
				});*/
			/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
		}
		
		/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/	
		function beforeSigningModal(){
			var beforeSigningModal = modalService.open({
				scope: $scope,
				windowClass: 'smallModals',
				templateUrl: 'partials/modal_beforeDocusignSigning.html',
				backdrop: 'static'
			});
			beforeSigningModal.result.then(function(){
				vm.submitAgreements();
			});
		}
		/*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
		
	//VAN-510 - Electronic Consent moved to app flow - Starts
	function checkSignerDetails(){
		if(vm.signer.customer == 'true' && vm.signer.coCustomer == 'true') {
			vm.signersPresent = 'BOTH';
			vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
			};
		} else if (vm.signer.coCustomer == 'true'){
			vm.signersPresent = 'COAPPLICANT';
			vm.signingPerson = {
					firstName : vm.customerFind.coCustomerInfo.firstName,
					lastName : vm.customerFind.coCustomerInfo.lastName
			};
		} else {
			vm.signersPresent = 'APPLICANT';
			vm.signingPerson = {
					firstName : vm.customer.customerFirstName,
					lastName : vm.customer.customerLastName
			};
		}
		if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
			vm.updateCustomerConstent();
		}else{
			vm.submitAgreements();
		}
	}
	
	function exitHTConsent(){
		void 0;
		var emailTextModal = $modal.open({
			templateUrl: 'partials/modal_exitHTConsent.tpl.html',
			scope: $scope,
			backdrop: 'static'
		});
		
	}
	//VAN-510 - Electronic Consent moved to app flow - Ends
	
	function enableSigning(){
			$timeout(function(){
				if((!vm.signer.customer || !vm.signer.customer=='false') 
					&& (!vm.signer.coCustomer || !vm.signer.coCustomer=='false')){
					vm.enableSigningflag = false;
				} else {
					vm.enableSigningflag = true;
				}
			},1000);
		}
	
	//Start - VAN-510 - Electronic Consent moved to app flow
	function updateCustomerConstent(){
		if(vm.customerFind.VanEngagement.customer){
			var customerData = {
					firstName: vm.customerFind.VanEngagement.customer.fName,
					lastName: vm.customerFind.VanEngagement.customer.lName,
					phoneNumber: vm.customerFind.VanEngagement.customer.phNum,
					isTcpaConstentFlg: $scope.consent.istcpaflag  //VAN-4181 HT_Consent Text is not appearing as expected
			};
			
			applicationService.updateCustomerConsent(customerData)
			.then(function(response) {
				modalService.dismiss();
				if(response.status == '200' ){
					void 0;
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer).- Starts*/ 
					if(!vm.isHTCustomer && !vm.hasCoCustomer){
						createAgreement();
					}else{
						vm.submitAgreements();
					}
					/*VAN-1249 - Stuck at processing in AWP after voiding first agreement in HT(HT Customer). - Ends*/
				}
			}, function(response){
				void 0;
				modalService.dismiss();
			});
		}
	}
	//End - VAN-510 - Electronic Consent moved to app flow

		function submitAgreements() {
			var agree;
		
			showModal('ProcessingAgreement', 'processing-modal');
			var promise = setCalcPaymentSpec();
			setSignatureData();

			promise.then(function() {
				
				var storedSessionId = $cookies.getObject("okta_session_id");
				//VAN-382 PRB0042677 Ess service failing with 500 internal server error - added co-applicant details
				var payload = {
		              "customerId" : vm.engagement.customerId,     
		              "engagementId" : vm.engagementId,  
		              "signersPresent" : vm.signersPresent,
		              /*VAN- If BP not selected show $49, if BP select do not show*/
		              /*"clubSwitch" : SharedDataSvc.globalVars.paymentCalcSetup.clubSwitch,   
		              "waiverSwitch" : SharedDataSvc.globalVars.paymentCalcSetup.waiverSwitch,*/
		              "paymentToDate" : SharedDataSvc.globalVars.paymentCalcSetup.paymentToDate,
		              "coapplicantFirstName" : vm.customerFind.coCustomerInfo? vm.customerFind.coCustomerInfo.firstName:null,
		              "coapplicantLastName" : vm.customerFind.coCustomerInfo? vm.customerFind.coCustomerInfo.lastName:null
		            
		         };
				
		        if(vm.isCoCustomerSignerPending){
					delete payload.clubSwitch;
					delete payload.waiverSwitch;
					delete payload.paymentToDate;
				}

				var domain = $window.location;
				var destination = domain.hash.split('/')[1];

				payload.returnURL = domain.origin + '/#!/login' + 
									"?Session=" + storedSessionId + "&engagementId=" + vm.engagementId;
				void 0;
				
				if(vm.signersPresent == 'BOTH'){
					$cookies.putObject("isCoCustomerSignerPending", true);
				} else {
					$cookies.putObject("isCoCustomerSignerPending", null);
				}
				
				if(vm.isHTCustomer){
					delete payload.clubSwitch;
					delete payload.waiverSwitch;
					delete payload.paymentToDate;
					$cookies.putObject("isHTCustomer", true);
				}

				if(vm.hideBp){
					delete payload.clubSwitch;
				}
				
				void 0;
				$cookies.putObject("navigatingToDocusign", true);
				
				paymentFactory.docuSign(payload).then(function(response) {
					$window.location.href = response.signatureURL;
				}, function(error) {
					showModal('agreementError');
					$cookies.putObject("navigatingToDocusign", false);
					$cookies.putObject("isCoCustomerSignerPending", null);
					//$location.url('dashboard');
					void 0;
				});
			});

		}

		function setPdfData(documentsLst) {
			// console.log('set PDF data');
			/*VAN-802 If BP not selected show $49, if BP select do not show*/  //shuold not hide BP when its declined
			/*vm.hideBp = true;*/
			vm.showWarrantyAgreement = false;
			vm.showDelWarrantyAgreement = false;

			vm.agreements[1].pdf = [];
			vm.agreements[1].type = [];

			_.forEach(documentsLst, function(agreement) {
				// console.log('DOCUMENTSLIST', agreement);
				if (agreement.documentType === 'RA') {
					vm.agreements[0].pdf = agreement.data;
				} else if (agreement.documentType === 'ACK') {
					vm.agreements[2].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'EPO90') {
					vm.agreements[3].pdf = agreement.data;
					// Added a new line
				} else if (agreement.documentType === 'LDW') {
					vm.agreements[4].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'ARB') {
					vm.agreements[6].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'FEE') {
					vm.showWarrantyAgreement = true;
					vm.agreements[7].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'dlf') {
					vm.showDelWarrantyAgreement = true;
					vm.agreements[8].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CLB') {
					vm.hideBp = false;
					vm.agreements[5].pdf = agreement.data;
					//Have changed here
				} else if (agreement.documentType === 'CAM' || agreement.documentType === 'COP' || agreement.documentType === 'CRB' || agreement.documentType === 'CYC' || agreement.documentType === 'GAS' || agreement.documentType === 'LCD' || agreement.documentType === 'RAN' || agreement.documentType === 'SOC' || agreement.documentType === 'VID') {
					vm.agreements[1].pdf.push(agreement.data);
					vm.agreements[1].type.push(agreement.documentType);
					vm.showSupp = true;
				}

			});

			if (vm.agreements[7].pdf == undefined) {
				delete vm.agreements[7];
			}

			if (vm.agreements[8].pdf == undefined) {
				delete vm.agreements[8];
			}

			void 0;

			if (vm.hideBp && vm.agreements[5]) {
				vm.agreements[5].agree = 'SIGNED';
				//Have changed here
				vm.agreements[5].viewed = true;
				//Have changed here
				void 0;
			}
			void 0;
		}

		function setAgreementViewed(index) {
			vm.agreements[index].viewed = true;
		}

		function setAgreementUnviewed(index) {
			vm.agreements[index].viewed = false;
		}

		function hasAgreementBeenViewed(index) {
			return vm.agreements[index].viewed;
		}

		function setAgreementSignature(status) {
			
			if(SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == "ENGLISH"){
				var sign = 'Signed via click-through AcceptanceNOW web portal.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}else{
				var sign = 'Firmado vía virtual a través del sitio web AcceptanceNow.\n';
				sign += vm.customer.customerFirstName + ' ' + vm.customer.customerLastName + '\n';
				sign += datesFactory.getCurrentDate();
			}
			
			

			void 0;
			void 0;
			vm.agreements[vm.activeAgreementIndex].agree = status;
			vm.agreements[vm.activeAgreementIndex].signature = sign;

			void 0;
			void 0;
			//AC13194 Code changes start
			if (vm.agreements[5] && vm.agreements[5].pdf !== undefined && vm.agreements[5].pdf !== null) {
				hideBPWhenLDWisDeclined(vm.activeAgreementIndex);
			}
			//AC13194 Code changes end
		}

		function setSignatureData() {
			var signatureData = SharedDataSvc.globalVars.signatureTemplate;

			signatureData.customerId = vm.engagement.customerId;
			signatureData.engagementId = Number(vm.engagementId);
			signatureData.language = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference.toLowerCase();

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here

				var ldwSignObj = {
					"formSwitch" : true,
					"signature" : vm.agreements[4].signature,
					"docType" : vm.agreements[4].type
				};

			} else {

				if (vm.agreements[4]) {
					var ldwSignObj = {
						"formSwitch" : false,
						"signature" : vm.agreements[4].signature,
						"docType" : vm.agreements[4].type
					};

				}

			}
			if (ldwSignObj !== undefined) {
				signatureData.signatures.push(ldwSignObj);
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED') {//Have changed here

				var bPlusObj = {
					"formSwitch" : true,
					"frequency" : "MONTHLY",
					"signature" : "",
					"docType" : vm.agreements[5].type
				};

			} else {

				if (vm.agreements[5]) {

					var bPlusObj = {
						"formSwitch" : false,
						"frequency" : "MONTHLY",
						"signature" : "",
						"docType" : vm.agreements[5].type
					};

				}

			}

			if (!vm.hideBp && vm.agreements[5]) {

				bPlusObj.signature = vm.agreements[5].signature;

			} else {

				if (vm.agreements[5]) {

					vm.agreements[5].agree = "";
					//Have changed here
					bPlusObj = undefined;

				}

			}
			if (bPlusObj !== undefined) {
				signatureData.signatures.push(bPlusObj);
			}

			SharedDataSvc.globalVars.signatureTemplate = signatureData;

		}

		// TODO - need to add processing loading modal
		function setCalcPaymentSpec() {
			void 0;

			var deferred = $q.defer();

			var paymentSpec = SharedDataSvc.globalVars.paymentCalcSetup;

			setFirstPaymentDate();
			paymentSpec.customerEngagementId = Number(vm.engagementId);
			paymentSpec.id = vm.engagement.customerHtStoreId;

			paymentSpec.customerId = vm.engagement.customerId;
			paymentSpec.clubSwitch = 'N';
			paymentSpec.waiverSwitch = 'DECLINE';
			paymentSpec.hoCustId = vm.customer.customerHtId;

			if (vm.agreementData !== undefined) {//AC 25996 code changes
				paymentSpec.agreementNumber = vm.agreementData.agreement.raNumber;
				paymentSpec.paymentToDate = SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate;
			} else {
				paymentSpec.agreementNumber = vm.customerFind.VanEngagement.agreementHTId;
				paymentSpec.paymentToDate = vm.customerFind.VanEngagement.nextPaymentDate;
			}

			// console.log('waiver switch', vm.agreements[1].agree);
			// console.log('club switch', vm.agreements[2].agree);

			if (vm.agreements[4] && vm.agreements[4].agree === 'SIGNED') {//Have changed here
				paymentSpec.waiverSwitch = 'ACCEPT';
			} else {
				paymentSpec.waiverSwitch = 'DECLINE';
			}

			if (vm.agreements[5] && vm.agreements[5].agree === 'SIGNED' && !vm.hideBp) {//Have changed here
				paymentSpec.clubSwitch = 'Y';
			} else if (vm.agreements[5] && vm.agreements[5].agree === 'DECLINED') {
				paymentSpec.clubSwitch = 'N';
			} else {
				paymentSpec.clubSwitch = 'N';
			}

			SharedDataSvc.globalVars.paymentCalcSetup = paymentSpec;

			if (SharedDataSvc.globalVars.paymentCalcSetup && SharedDataSvc.globalVars.agreementPrePut.initialPaymentDate) {
				deferred.resolve("PaymentCalcSetup and initialPaymentDate has been set");
			} else {
				deferred.reject("PaymentCalcSetup or intialPaymentDate is not set yet");
			}

			return deferred.promise;

		}

		function setAgreementStatus(status) {
			vm.engagement.agreementStatus = status;
		}

		function declineConsent() {
			showModal('ProcessingAgreement', 'processing-modal');
			setAgreementStatus('Declined');
			void 0;
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
				modalService.dismiss();
				navigateTo('dashboard');
				}, function(response) {
				$log.log('Updating engagement failed');
			});
		}
		
		function updateEngagement() {
			updateEngagementItemFactory.putData(vm.engagement).then(function(response) {
				$log.log('Successfully updated engagement');
			}, function(response) {
				$log.log('Updating engagement failed');
			});
		}

		function formatAgreementDate(date) {
			var year = String(date).substring(0, 4),
			    month = String(date).substring(4, 6),
			    day = String(date).substring(6, 8);

			// 20140825
			void 0;
			return month + '-' + day + '-' + year;
		}

		function isSameDayAgreement() {
			var today = datesFactory.getCurrentDate(),
			    agreementsExist = vm.customerFind.agreement ? true : false,
			    agreementDate = vm.customerFind.agreement ? formatAgreementDate(vm.customerFind.agreement.agreementDate) : false,
			    isSameDay = moment(agreementDate).isSame(today, 'day') ? true : false;
			// Set line below to today to check for same day agreements
			// isSameDay = moment('4-27-2015').isSame(today, 'day') ? true : false;

			return isSameDay;
		}

		function handleSameDayAgreements() {
			vm.agreementDataForClauseUse = vm.customerFind.agreement;
			//VAN-1427-AWP - Should not create the new agreement on next day.
			if (vm.customerFind.agreement  /*&& isSameDayAgreement()*/ && (vm.agreementHTID !== undefined && vm.agreementHTId !== null)) {//AC 25996 code changes
				//Start - AC13303 - Error 700 on submit agreements
				if (vm.customerFind.agreement && (vm.customerFind.agreement.agreementStatus === "INACTIVE")) {
					vm.agreementStatus = vm.customerFind.agreement.agreementStatus;
					var servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
					//AC-28614 Changes
					//Commented out the Active check since customers will be allowed to docusign after payment complete in HT
//					if (vm.customerFind.agreement.agreementStatus === "ACTIVE") {
//						$rootScope.modalMessage = $translate.instant('agreement_label_errorReceiveAgreement') + servicePhoneNumber + '. [99]';
//					} else {
						$rootScope.modalMessage = $translate.instant('agreement_label_errorDeclineAgreement') + ' [44]';
//					}
					modalService.open({
						templateUrl : 'partials/modal_agreementStatusNotinPending.html',
						backdrop : 'static',
						scope : $scope
					});
					return false;
				} else {
					//End - AC13303 - Error 700 on submit agreements
					var agreements = vm.customerFind.agreement;
					vm.agreements[1].pdf = [];
					vm.agreements[1].type = [];

					void 0;

					for (var key in agreements) {

						if (key === 'UnsignedCAM' || key === 'UnsignedCOP' || key === 'UnsignedCRB' || key === 'CYC' || key === 'UnsignedGAS' || key === 'UnsignedLCD' || key === 'UnsignedRAN' || key === 'UnsignedSOC' || key === 'UnsignedVID') {

							vm.agreements[1].pdf.push(agreements[key]);
							var keyForSigning = key.replace("Unsigned", "");
							vm.agreements[1].type.push(keyForSigning);
							vm.showSupp = true;

						}

					}
					//  vm.agreements[1].pdf = 'data:application/pdf;base64,' + escape(agreements.UnsignedSAC);
					vm.agreements[2].pdf = agreements.UnsignedRAAck;
					//Have changed here
					vm.agreements[0].pdf = agreements.UnsignedRA;

					if (agreements.EpoAmendment) {
						vm.agreements[3].pdf = agreements.EpoAmendment;
						//Have changed here
					}

					if (agreements.UnsignedLDW) {
						vm.agreements[4].pdf = agreements.UnsignedLDW;
						//Have changed here
					}

					if (agreements.CLB) {
						vm.agreements[5].pdf = agreements.CLB;
						//Have changed here
					}

					vm.agreements[6].pdf = agreements.UnsignedARB;
					//Added a new line here

					if (agreements.UnsignedFEE) {
						vm.agreements[7].pdf = agreements.UnsignedFEE;
						vm.showWarrantyAgreement = true;
					} else {
						delete vm.agreements[7];
					}

					if (agreements.UnsignedDLF) {
						vm.agreements[8].pdf = agreements.UnsignedDLF;
						vm.showDelWarrantyAgreement = true;
					} else {
						delete vm.agreements[8];
					}
				} //AC13303 - Error 700 on submit agreements
				//AC24649 Code changes starts
				if(vm.isAdjustablePayment){
					if(vm.customerFind.VanEngagement.customer && vm.customerFind.VanEngagement.customer.langOption && vm.customerFind.VanEngagement.customer.langOption.trim() === "ENGLISH"){
						vm.languagePreference = true;
					} else {
						vm.languagePreference = false;
					}
					var requestDate = datesFactory.getCurrentDate();				
					vm.orderDataForAdjustablePayment.requestDte = requestDate;
					vm.orderDataForAdjustablePayment.nextRenewDte = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("YYYY-MM-DD HH:mm:ss");
					//vm.orderDataForAdjustablePayment.nextRenewDte = "2016-09-16 23:59:59";
					setOrderDataForAdjustablePayment();
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
					if(vm.paymentSelected == "4 Weeks"){
						vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
					}else{
						vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;}
					vm.orderDataForAdjustablePayment.promotionCode = vm.promoSelectedAtAgreement.code;
					vm.orderDataForAdjustablePayment.promotionNextDueDate = vm.orderDataForAdjustablePayment.nextRenewDte;
					vm.promotionNextDueDate = moment(vm.orderDataForAdjustablePayment.promotionNextDueDate).format('MM/DD');
					calculateDiffDays(vm.orderDataForAdjustablePayment.nextRenewDte);
					//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
					if(vm.promoSelectedAtAgreement.desc != ""){//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(response) {
						void 0;
						vm.quoteGotAsPerSelection = response;
						vm.adjustableDateSelection = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("MMM DD, YYYY");
						if(vm.showAddAmt){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
							if(vm.promoSelectedAtAgreement.code === "H"){
								//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Starts
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - 49).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 49).toFixed(2);}
							}else if(vm.promoSelectedAtAgreement.code === "J"){
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - 99).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 99).toFixed(2);}
							}else if(vm.promoSelectedAtAgreement.code === "D"){	
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal).toFixed(2);;
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 0).toFixed(2);}
							}else if(vm.promoSelectedAtAgreement.code === "Q"){//VAN-2841-AWP-Value City $1 Promo - Starts
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - 1).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - 1).toFixed(2);}
							}
							else if(vm.promoSelectedAtAgreement.code != "H" && vm.promoSelectedAtAgreement.code != "J" && vm.promoSelectedAtAgreement.code != "Q" && vm.promoSelectedAtAgreement.code != "D"){//VAN-2841-AWP-Value City $1 Promo - Starts
								if(vm.promoSelectedAtAgreement.desc!=undefined){
									var addAmount = (vm.promoSelectedAtAgreement.desc).substring(1,3);
								if(vm.restrictions.skipBp){
									vm.promoAddAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal - addAmount).toFixed(2);
								}else{
									vm.promoAddAmt = ((vm.quoteGotAsPerSelection.initialRaPaymentTotal + vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial) - addAmount).toFixed(2);
									}
								}
							}
							//VAN-2841-AWP-Value City $1 Promo - Ends
						}else{
							if(vm.promoSelectedAtAgreement.code === "D"){
								if(vm.restrictions.skipBp){
									vm.pifSamedayAmt = (vm.quoteGotAsPerSelection.initialRaPaymentTotal).toFixed(2);
								}else{
									vm.pifSamedayAmt = (vm.quoteGotAsPerSelection.bplusAmountInitial + vm.quoteGotAsPerSelection.bplusTaxInitial).toFixed(2);}}
						//VAN-2386-AWP/Kiosk - CA store- BP amount included in Quote call - Ends
						}//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
						vm.showModal('samedayInitialPaymentInfo', 'initial-payment-info-modal');
					}, function(response) {
						void 0;
					});
					}else{//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
						vm.adjustableDateSelection = moment(vm.customerFind.VanEngagement.nextPaymentDate,'YYYYMMDD',true).format("MMM DD, YYYY");
						vm.showModal('samedayInitialPaymentInfo', 'initial-payment-info-modal');
					}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
				}else {
				
					agreementCodeVerify();
					modalService.dismiss();
				}//AC24649 Code changes ends
			}

		}

		function setOrderData() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});
			vm.orderData[0].quoteItem = orderItems;
			vm.orderData[1].quoteItem = orderItems;
			vm.orderData[2].quoteItem = orderItems;

			vm.orderData[0].inventoryList = vm.createInventoryList();
			vm.orderData[1].inventoryList = vm.createInventoryList();
			vm.orderData[2].inventoryList = vm.createInventoryList();

			vm.orderData[0].engagementId = vm.engagement.engagementId;			
			vm.orderData[1].engagementId = vm.engagement.engagementId;			
			vm.orderData[2].engagementId = vm.engagement.engagementId;

			void 0;
			// SharedDataSvc.globalVars.orderEstimatePut.quoteItem
		}

		function setOrderDataForAdjustablePayment() {
			var orderItems = [];
			_.forEach(vm.orderContent.orderItems, function(item, i) {
				var orderItem = {};
				// orderItem.unitType = vm.orderArray[i].unitType;
				// orderItem.subType = vm.orderArray[i].subType;
				orderItem.itemId = '1';
				orderItem.price = item.itemPrice;
				orderItem.itemDesc = item.itemDescription;
				orderItem.qty = item.quantity;

				orderItems.push(orderItem);
			});

			vm.orderDataForAdjustablePayment.quoteItem = orderItems;
			vm.orderDataForAdjustablePayment.inventoryList = vm.createInventoryList();
			vm.orderDataForAdjustablePayment.engagementId = vm.engagement.engagementId;
			void 0;

		}

		function isZipInStoreRadius(zip) {
			void 0;
			var deferred = $q.defer();
			// Google API Implementation - Starts
			getStoreInfoFactory.getLatLngFromZip(zip).then(function(results) {
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
					    distance = getStoreInfoFactory.getCustomerDistance(vm.storeLatLng, customer),
					    isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius(vm.storeInfo.allowedRentalRadiusMiles, distance),
					    isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

					void 0;
					// console.log('Is in US', isCustomerInUS);
					void 0;
					vm.customerDistance = Math.round(distance);

					if (isCustomerInUS && isInAllowedRadius) {
						vm.customerInStoreRadius = true;
						deferred.resolve(true);
					} else {
						vm.customerInStoreRadius = false;
						deferred.resolve(false);
					}
				} else {
					vm.isZipValid = false;
					deferred.resolve(false);
				}
			});
			return deferred.promise;
		}

		function setCustomerOrderData(customer) {
			vm.orderData[0].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[0].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[0].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[0].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[0].employeeName = 'AWP';
			vm.orderData[0].store = vm.storeInfo.vanStoreId;

			vm.orderData[1].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[1].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[1].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[1].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[1].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[1].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[1].employeeName = 'AWP';
			vm.orderData[1].store = vm.storeInfo.vanStoreId;

			vm.orderData[2].customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderData[2].customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderData[2].orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[2].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[2].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderData[2].feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderData[2].employeeName = 'AWP';
			vm.orderData[2].store = vm.storeInfo.vanStoreId;
		}

		function setCustomerOrderDataForAdjustablePayment(customer) {
			vm.orderDataForAdjustablePayment.customer.firstName = vm.customerFind.VanEngagement.customer.fName;
			vm.orderDataForAdjustablePayment.customer.lastName = vm.customerFind.VanEngagement.customer.lName;
			vm.orderDataForAdjustablePayment.orderIdentifier = vm.orderContent.orderIdentifier;
			//vm.orderData[0].deliveryAmt = vm.orderContent.deliveryFee;
			//vm.orderData[0].extendService = vm.orderContent.extendedServiceAmount;
			vm.orderDataForAdjustablePayment.feeArray = [{
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.extendedServiceAmount,
				"feeType" : "ESV"
			}, {
				"feeAcrossTerm" : true,
				"feeAmount" : vm.orderContent.deliveryFee,
				"feeType" : "Delivery"
			}];
			vm.orderDataForAdjustablePayment.employeeName = 'AWP';
			vm.orderDataForAdjustablePayment.store = vm.storeInfo.vanStoreId;
		}

		function createInventoryList() {
			var inventoryList = [];

			void 0;
			_.forEach(vm.orderContent.orderItems, function(item, index) {
				var orderItem = {
					"racBrand" : "ACP", // 'ESV' for extended
					"itemModelNumber" : "", // 'ExtSvc' for extended
					"racModelNumber" : "",
					"depreciableLifeInMonths" : 24,
					"itemOptions" : "",
					"itemOriginalCost" : 0
				};

				orderItem.racBrand = "ACP";
				orderItem.itemModelNumber = "";
				orderItem.racModelNumber = productFactory.getRacModelNumber(vm.prodCat, item, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				orderItem.depreciableLifeInMonths = 24;
				orderItem.itemOptions = productFactory.buildItemOptions(item);
				orderItem.itemOriginalCost = SharedDataSvc.formatItemPrice(item.itemPrice);
				void 0;
				inventoryList.push(orderItem);
			});
			/*VAN-4050 AWP - show initial payment details before agreement creation -
			adding the payload for create inventory function to make ht call 
			and get the response for the reduction amount in customer find */
			
			if(vm.invoiceReductionPayment && vm.invoiceReductionPaymentAmount> 0) {
				var orderItem = {
					        "racBrand": "IVR",
					        "itemModelNumber": "",
					        "racModelNumber": "HTIVR-DEPAMT",
					        "depreciableLifeInMonths": 24,
					        "itemOptions": "1",
					        "itemOriginalCost": (vm.invoiceReductionPaymentAmount*100)
					    }
				  orderItem.itemModelNumber = "";
				  orderItem.racBrand = "IVR";
				  orderItem.racModelNumber = "HTIVR-DEPAMT"
				  orderItem.itemOptions = "1";
				  orderItem.itemOriginalCost = (vm.invoiceReductionPaymentAmount*100);
				  orderItem.depreciableLifeInMonths = 24;
				  void 0;
				inventoryList.push(orderItem);	
			}
	
			//VAN-2377-AWP- Amount mismatch if we select multiple items and add ESV - Start
			if(vm.orderContent.extendedServiceAmount > 0) {
				var extendedWarranty = {
						"racBrand" : "ACP",
						"itemModelNumber" : "ExtSvc",
						"racModelNumber" : "ESVPLAN-1",
						"depreciableLifeInMonths" : 24,
						"racRateCode" : "ESV396",
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(vm.orderContent.extendedServiceAmount)
				};
				inventoryList.push(extendedWarranty);
			}
			//VAN-2377-AWP- Amount mismatch if we select multiple items and add ESV - End

			return inventoryList;
		}

		function cancelAndNavigate(){
			if(vm.storeInfo.isDigitalSigningEnabled && (vm.isHTCustomer || (vm.hideBp && vm.restrictions.skipLdw))) {
				navigateTo('dashboard');
			}  else {
				modalService.dismiss();
			}
		}
		
		function showLowDownPaymentOptions(item) {
			if( (item.desc.indexOf("AutoPay Required") > -1) || (!$cookies.getObject('isHybrid') && item.desc.indexOf("without AutoPay") > -1 )){
				return item.payment;//VAN-670 - Consent Verbiage
			}
		}
		
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
		function showPaymentInitial(){
			$rootScope.selectedLang = SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference === 'ENGLISH' ? true : false;
			/*if(vm.selectedPromo === 'AutoPayRequired'){
				$scope.autoPayRequired = modalService.open({*/
			if(vm.selectedPromo === 'H'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
				$scope.H = modalService.open({
					title: 'Open AutopayRequired popup for $49 Promo',
					templateUrl: 'partials/modal_showAutopayRequired.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
			else
			{
				//checkStore();
				getFirstPaymentOptions();//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
			}
		}
		function showInitialPaymentOptions(){
			$scope.errEmpPass = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			$scope.noEmpPass = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
			vm.selectedPromo = null;
			vm.showAddAmt = false;
			vm.showPIFPromoOption = false;
			vm.enableVariablePay = false;
			$rootScope.showCalforVarPay = false;
			vm.ipMaxDate = "";
			vm.ipMinDate = "";
			//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
		}
		//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
		
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
		function changePaymentMode(paymentMode){
			showModal('Processing', 'processing-modal');
			if(vm.selectedPromo != null){
				if(vm.selectedPromo === 'Pif'){
					vm.orderDataForAdjustablePayment.promotionCode = 'D';
				//}else if(vm.selectedPromo === 'AutoPayRequired'){
				}else if(vm.selectedPromo === 'H'){//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = 'H';
				//}else if(vm.selectedPromo === 'WithoutAutoPay'){
				}else if(vm.selectedPromo === 'J'){ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = 'J';
					//VAN-2841-AWP-Value City $1 Promo - Starts
				//}else if(vm.selectedPromo === 'vcPromo'){
				}else if(vm.selectedPromo === 'Q'){ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = 'Q';
				//VAN-3623-AWP - set promocode configurable-Starts
				}else if(vm.selectedPromo != 'H' && vm.selectedPromo != 'J' && vm.selectedPromo != 'Q' && vm.selectedPromo != 'payAdditionalAmount'){ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
					vm.orderDataForAdjustablePayment.promotionCode = vm.selectedPromo;
				//VAN-3623-AWP - set promocode configurable-Ends
				}else if(vm.selectedPromo === 'payAdditionalAmount'){//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
					vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = null;
					vm.orderDataForAdjustablePayment.varInitPmtFunc = 'QUOTEDATE';
					vm.orderDataForAdjustablePayment.varInitPmtAmt = $scope.amount.replace('.','');
				}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
			}else{
				if(vm.storeInfo.noCalcPromos[0].code === 'Q'){
					vm.orderDataForAdjustablePayment.promotionCode = 'Q';
				}else if(vm.storeInfo.noCalcPromos[0].code === 'H'){
					vm.orderDataForAdjustablePayment.promotionCode = 'H'; //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
				}
				else if(vm.storeInfo.noCalcPromos[0].code != 'H' && vm.storeInfo.noCalcPromos[0].code != 'J' && vm.storeInfo.noCalcPromos[0].code != 'Q'){
					vm.orderDataForAdjustablePayment.promotionCode = vm.storeInfo.noCalcPromos[0].code; //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
				}
				else{
					vm.orderDataForAdjustablePayment.promotionCode = "J";
				}
				//VAN-2841-AWP-Value City $1 Promo - Ends
			}
			vm.orderDataForAdjustablePayment.paymentMode = 'Monthly';
			agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(monthlyResponse) {
				void 0;
				vm.quoteGotAsPerSelection = monthlyResponse;
				vm.orderDataForAdjustablePayment.paymentMode = 'Biweekly';
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(biweeklyResponse) {
					void 0;
					vm.quoteGotAsPerSelectionBiweekly = biweeklyResponse;
					vm.orderDataForAdjustablePayment.paymentMode = 'Fourweek';
					agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(quarterlyResponse) {
						void 0;
						vm.quoteGotAsPerSelectionQuarterly = quarterlyResponse;
						$scope.paymentSelected = SharedDataSvc.globalVars.paymentModeSelected;	
						modalService.dismiss();
						showModal('modifyInitialPayment');
					}, function(quarterlyResponse) {
						void 0;
						modalService.dismiss();
					}); 
				}, function(biweeklyResponse) {
					void 0;
					modalService.dismiss();
				}); 
			},function(monthlyResponse) {
				void 0;
				modalService.dismiss();
			});	
		}

		function promoPaymentSelectOperation(val) {
			void 0;
			if(val === "Pif" && !vm.showPIFPromoOption) {
				if(vm.storeInfo.isManned && !vm.storeInfo.hybridEnabled){
					showModal('confirmPasswordAgreement');
				}else{
					showModal('validatePassphrase');
				}
			} 
		}

		function verifyEmployeePassWord(pass){
			$scope.noEmpPass = false;
			$scope.errEmpPass = false;
			if (pass) {
				var auth = vm.storeInfo.vanStoreId;
				if (auth === pass) {
					void 0;
					modalService.dismiss();
					vm.showPIFPromoOption = true;
//					vm.showModal('showInitialPaymentInfo', 'initial-payment-info-modal');
					showPaymentInitial();//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
				} else {
					$scope.errEmpPass = true;
					void 0;
				}
			} else {
				$scope.noEmpPass = true;
			}
		}
		//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
		
		//VAN-2398-Prompt for CA stores about unsigned documents - Starts
		function unSignedEmailModal(){
			var unSignedEmailModal = modalService.open({
				scope: $scope,
				windowClass: '',
				templateUrl: 'partials/modal_unSignedEmail.html',
				backdrop: 'static'
			});
			unSignedEmailModal.result.then(function(){
				if(vm.hasCoCustomer){ //PR-State
					var coCustomerSigningModal = modalService.open({
						scope: $scope,
						windowClass: '',
						templateUrl: 'partials/modal_coCustomerSigning.html',
						backdrop: 'static'
					});
					coCustomerSigningModal.result.then(function(){
						vm.checkSigner();
					});
				}else{
					if(!vm.hideBp || !vm.restrictions.skipLdw){
						beforeSigningModal();
					}
				}
				if(vm.hideBp && vm.restrictions.skipLdw){
					vm.beforeSigning();
					return false;
				}
			});
		}
		
		function unSignedEmailSameDay(){
			if($scope.coCustSigner != null){
				if(vm.customerFind.isCoCustomerInfoProvided == true){
					if($scope.isAgreementPending[0].customerType.toLowerCase() == 'applicant'){
					vm.afterSigningConsent();
				}
				else{
					vm.afterSigning();
				}
				}
				else{
					$rootScope.showConsent = true;
					vm.askForSigningConsent();}
				$cookies.putObject('isCoCustomerSignerPending', null);
				return false;
			} else if(vm.customerFind.isCoCustomerInfoProvided && $scope.isAgreementPending.length == 1 && vm.customerFind.VanEngagement.agreementOriginator !== null && vm.customerFind.VanEngagement.agreementOriginator.toLowerCase() === "hightouch"){
				if(vm.customerFind.VanEngagement.customer.isConsentAgreed === "HT"){
					$rootScope.showConsentForHT = true;
				}
				vm.afterSigningConsent();
				return false;
			}
		}
		//VAN-2398-Prompt for CA stores about unsigned documents - Ends	
		
		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
		function quoteCalculator() {
			showModal('ProcessingAgreement', 'processing-modal');
			vm.setOrderDateAsPerSelection(vm.adjustableDateSelection);
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;
			}
			if(vm.selectedPromo != 'payAdditionalAmount'){
				vm.orderDataForAdjustablePayment.varInitPmtFunc = null;
				vm.orderDataForAdjustablePayment.varInitPmtAmt = null;
				vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = null;
			}
			for(var i in vm.storeInfo.noCalcPromos) //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration
			{
				if(vm.selectedPromo===vm.storeInfo.noCalcPromos[i].code)
				{
					vm.promoPayment = vm.storeInfo.noCalcPromos[i].payment;
					//vm.addAmt=vm.storeInfo.noCalcPromos[i].desc.substring(1,3);//VAN-3623-Promocode configurable
					vm.addAmt=vm.storeInfo.noCalcPromos[i].payment//VAN-3642-AWP - Payment Values getting mismatch if we select Beyond15days date
					if(vm.selectedPromo==="H"){
						vm.orderDataForAdjustablePayment.promotionCode = "H";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoOneResponse) {
							void 0;
							modalService.dismiss();
							vm.promoOneQuote = promoOneResponse;
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.promoOneAdditionalAmt = (vm.promoOneQuote.initialRaPaymentTotal - 49).toFixed(2);
								}else{
									vm.promoOneAdditionalAmt = ((vm.promoOneQuote.initialRaPaymentTotal + vm.promoOneQuote.bplusAmountInitial + vm.promoOneQuote.bplusTaxInitial) - 49).toFixed(2);
								}}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(promoOneResponse) {
							void 0;
							modalService.dismiss();
						});
					}
					//}else if(vm.selectedPromo === "WithoutAutoPay"){
					else if(vm.selectedPromo==="J")
					{
						vm.orderDataForAdjustablePayment.promotionCode = "J";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(promoTwoResponse) {
							void 0;
							modalService.dismiss();
							vm.promoTwoQuote = promoTwoResponse;
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.promoTwoAdditionalAmt = (vm.promoTwoQuote.initialRaPaymentTotal - 99).toFixed(2);
								}else{
									vm.promoTwoAdditionalAmt = ((vm.promoTwoQuote.initialRaPaymentTotal + vm.promoTwoQuote.bplusAmountInitial + vm.promoTwoQuote.bplusTaxInitial) - 99).toFixed(2);
								}}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(promoTwoResponse) {
							void 0;
							modalService.dismiss();
						});	
					}

					else if(vm.selectedPromo==="Q")

					{
						vm.orderDataForAdjustablePayment.promotionCode = "Q";
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
							vm.vcPromoQuote = vcPromoresponse;
							if(vm.showAddAmt){
								if(vm.restrictions.skipBp){
									vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
								}else{
									vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
								}}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
						});
					}
					//VAN-3623-AWP - set promocode configurable-Starts
					else if(vm.selectedPromo!="H" && vm.selectedPromo!="J" && vm.selectedPromo!="Q" && vm.selectedPromo != "Pif" && vm.selectedPromo != "payAdditionalAmount" ){
						vm.orderDataForAdjustablePayment.promotionCode = vm.selectedPromo;
						agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
							vm.vcPromoQuote = vcPromoresponse;
							if(vm.showAddAmt){
									var promoAmount = vm.addAmt;//VAN-3623-Promocode configurable
								if(vm.restrictions.skipBp){
									vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - promoAmount).toFixed(2);
								}else{
									vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - promoAmount).toFixed(2);
								}
								}
							vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
						},function(vcPromoresponse) {
							void 0;
							modalService.dismiss();
						});
					}
		//VAN-3623-AWP - set promocode configurable-Ends
				}
				
			}
		 if(vm.selectedPromo === "Pif"){
				vm.showPIFPromoOption = true;
				//vm.promoPayment =""//check 
				vm.orderDataForAdjustablePayment.promotionCode = "D";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
					vm.pifPromoQuote = pifPromoResponse;
					if(vm.restrictions.skipBp){
						vm.pifPromoAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
					}else{
						vm.pifPromoAmt = (vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial).toFixed(2);}
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.pifPromoAdditionalAmt = (vm.pifPromoQuote.initialRaPaymentTotal).toFixed(2);
						}else{
							vm.pifPromoAdditionalAmt = ((vm.pifPromoQuote.initialRaPaymentTotal + vm.pifPromoQuote.bplusAmountInitial + vm.pifPromoQuote.bplusTaxInitial) - 0).toFixed(2);
						}}
					vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
				},function(pifPromoResponse) {
					void 0;
					modalService.dismiss();
				});	
			//VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Starts
			/*}else if(vm.selectedPromo === "vcPromo"){
				vm.promoPayment = vm.storeInfo.noCalcPromos[0].payment;
				vm.orderDataForAdjustablePayment.promotionCode = "Q";
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(vcPromoresponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo success', vcPromoresponse);
					modalService.dismiss();
					vm.vcPromoQuote = vcPromoresponse;
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.vcPromoAdditionalAmt = (vm.vcPromoQuote.initialRaPaymentTotal - 1).toFixed(2);
						}else{
							vm.vcPromoAdditionalAmt = ((vm.vcPromoQuote.initialRaPaymentTotal + vm.vcPromoQuote.bplusAmountInitial + vm.vcPromoQuote.bplusTaxInitial) - 1).toFixed(2);
						}}
					vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
				},function(vcPromoresponse) {
					console.log('[agreementFactory.setQuotesAsPerSelection] vcPromo fail', vcPromoresponse);
					modalService.dismiss();
				});*/ //VAN-3594-AWP - show promos $1/$49/$99 based on store configuration-Ends
			}else if(vm.selectedPromo === "payAdditionalAmount"){
				vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = vm.orderDataForAdjustablePayment.nextRenewDte;
				vm.orderDataForAdjustablePayment.varInitPmtFunc = 'QUOTEAMT';
				vm.orderDataForAdjustablePayment.varInitPmtAmt = null;
				vm.orderDataForAdjustablePayment.promotionCode = null;
				vm.orderDataForAdjustablePayment.promotionNextDueDate = null;
				agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(variablePayResponse) {
					void 0;
					modalService.dismiss();
					vm.variablePayQuote = variablePayResponse;
					if(vm.showAddAmt){
						if(vm.restrictions.skipBp){
							vm.variablePayAdditionalAmt = (vm.variablePayQuote.initialRaPaymentTotal - $scope.amount).toFixed(2);
						}else{
							vm.variablePayAdditionalAmt = (vm.variablePayQuote.initialRaPaymentTotal + vm.variablePayQuote.bplusAmountInitial + vm.variablePayQuote.bplusTaxInitial - $scope.amount).toFixed(2);
						}
					}
					vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
				},function(vcPromoresponse) {
					void 0;
					modalService.dismiss();
				});

			}
		}

		function getAlternateAmount(){
			$scope.showDoneToContinue = false;
			vm.variablePay.price = '';
			vm.variablePay.minimumPrice = 49.00;//Default	
			var alternateAmountModal = modalService.open({
				title: "Pay Alternate Amount Modal",
				templateUrl: 'partials/modal_getAlternateAmount.html',
				scope: $scope,
				backdrop: 'static'
			});
			alternateAmountModal.result.then(function(data) {
				void 0;
				vm.enableVariablePay = true;
				$rootScope.showCalforVarPay = true;
				$scope.amount = data.toFixed(2);
				vm.orderDataForAdjustablePayment.varInitPmtFunc = 'QUOTEDATE';
				vm.orderDataForAdjustablePayment.varInitPmtAmt = $scope.amount.replace('.','');
				vm.orderDataForAdjustablePayment.varInitPmtNextDueDate = null;
				vm.orderDataForAdjustablePayment.promotionCode = null;
				vm.orderDataForAdjustablePayment.promotionNextDueDate = null;
				buildQuoteForVarPay();
			});
		}

		function variablePayCheck(price) {
			if (price && String(price).indexOf('.') > -1) {
				var itemPrices = String(price).split('.');
				if (itemPrices[1].length > 2) { 
					vm.variablePay.price = Number(price.toString().match(/^\d+(?:\.\d{0,2})?/));
				}	
			}
		}

		function buildQuoteForVarPay() {
			void 0;
			if(vm.paymentSelected == "4 Weeks"){
				vm.orderDataForAdjustablePayment.paymentMode = "Fourweek";
			}else{
				vm.orderDataForAdjustablePayment.paymentMode = vm.paymentSelected;
			}
			if (vm.orderContent.orderItems.length > 0) {
				$rootScope.agreementloaderText = (SharedDataSvc.globalVars.agreementPrePut.customer.languagePreference == 'SPANISH') ? es.common_label_processing : en.common_label_processing;
				showModal('ProcessingAgreement', 'processing-modal');
				_.forEach(vm.orderContent.orderItems, function(order, i) {
					productFactory.getProductInfo(vm.orderContent.orderItems[i]).then(function(response) {
						$log.log('product info ' + i, response);
						vm.orderArray.push(response);
						if (i === vm.orderContent.orderItems.length - 1) {
							if (vm.isAdjustablePayment) {
								setOrderDataForAdjustablePayment();
								vm.orderDataForAdjustablePayment.nextRenewDte = datesFactory.getCurrentDate();
								vm.orderDataForAdjustablePayment.requestDte = datesFactory.getCurrentDate();
								agreementFactory.setQuotesAsPerSelection(vm.orderDataForAdjustablePayment).then(function(variablePayResponse) {
									void 0;
									modalService.dismiss();
									vm.variablePayQuote = variablePayResponse;
									vm.expiryDate = vm.variablePayQuote.expirationDate;
									if(vm.variablePayQuote!=null && vm.variablePayQuote.expirationDate!=null){
										vm.adjustableDateSelection = moment(vm.variablePayQuote.expirationDate, 'YYYYMMDD',true).format("MMM DD, YYYY");
										vm.showModal('showSelectedPromo', 'initial-payment-info-modal');
									}
								},function(vcPromoresponse) {
									void 0;
									modalService.dismiss();
								});
							}else {
								setOrderData();
							}
						}
					});
				});
			}else {
				void 0;
			}
		}

		function buildCalendarForVarPay(){
			if(vm.ipMaxDate == "" && vm.ipMinDate == ""){
			vm.expDte_min = moment(vm.expiryDate, "YYYYMMDD");
			vm.expDte_max = moment(vm.expiryDate, "YYYYMMDD");
			vm.ipMaxDate = moment(vm.expDte_max.add(14, 'd')._d);
			void 0;
			vm.ipMinDate = moment(vm.expDte_min.add(1, 'd')._d);
			void 0;
			vm.newPaymentMode = false;
			}
			vm.openfirstPaymentSelectionCalendar();	
		}
		//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
	}

})();
