(function() {
	'use strict';

	myApp.factory('getStoreInfoFactory', getStoreInfoFactory);

	/* @ngInject */
	function getStoreInfoFactory($http, $q, $cookies, $location, $rootScope, SharedDataSvc, localStorageService, API_DOMAIN, authentication, PROXY_ACTION_URL, ServiceAgent) {
		var activeStoreId;
		var service = {
			getData : getData,
			getStoreState : getStoreState,
			/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 */
			getPRZipcodeData : getPRZipcodeData,
			getLatLngFromZip : getLatLngFromZip,
			getCustomerDistance : getCustomerDistance,
			isCustomerInRadius : isCustomerInRadius,
			isCustomerInUS : isCustomerInUS
		};
		return service;

		////////////////
		
		/*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Starts*/
		 function getPRZipcodeData() {
		      void 0;
		      var deferred = $q.defer();
		      var headers = {
		        'endTarget': 'site/json/prZipCodes.json',
		        'sessionToken' : authentication.getCurrentSessionId(),
		        'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
		      };
		      ServiceAgent.httpCall(
		        PROXY_ACTION_URL,
		        'POST',
		        headers,
		        null,
		        false
		        ).then(function (response) {
		          void 0;
		          deferred.resolve(response);
		        },
		        function (response) {
		          void 0;
		            deferred.reject(response);
		        });

		        return deferred.promise;
		    }
		 /*VAN 491 - PR-AWP: ‘Please enter a valid zip code’ error message should be displayed when try to validate the Zip code with 00000 - Ends*/ 
		 
		function getData(param) {
			var deferred = $q.defer();
			var pp = '';
			if ($cookies.getObject('storeId')) {
				activeStoreId = $cookies.getObject('storeId');
			} else {
				activeStoreId = SharedDataSvc.vanStoreId;
			}
			if (param) {
				var pp = param;
			}

			if (localStorageService.get('storeInfo') && localStorageService.get('storeInfo').vanStoreId === activeStoreId) {
				void 0;
				//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
				if($rootScope.xpartnerLogin == undefined){
					$rootScope.xpartnerLogin = localStorageService.get('xparterLogin');}
				if($rootScope.xpartnerLogin){
					localStorageService.set('xparterLogin',true);
				}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
				SharedDataSvc.raygunKey = localStorageService.get('storeInfo').raygunApiKey;
				SharedDataSvc.quoteService = localStorageService.get('storeInfo').quoteService;
				SharedDataSvc.operationMode = localStorageService.get('storeInfo').operationMode;//Added Feature 7855 code changes
				//Feature 7872 Integration of Logz code changes starts
				SharedDataSvc.logzApiKey = localStorageService.get('storeInfo').logzApiToken;
				SharedDataSvc.isLogzEnabled = localStorageService.get('storeInfo').logzEnableFlag;
				//Feature 7872 Integration of Logz code changes ends
				SharedDataSvc.invoiceReductionPayment = localStorageService.get('storeInfo').invoiceReductionPayment;
				SharedDataSvc.globalVars.storeSetup = localStorageService.get('storeInfo');
				deferred.resolve(localStorageService.get('storeInfo'));
			} else {
				
				if($cookies.getObject('OPERATION-MODE')){
					var opMode = $cookies.getObject('OPERATION-MODE');
				}else{
					var opMode = null;
				}

				var headers = {
					'RAC-KIOSK-LOCATION-ID' : activeStoreId,
					'endTarget' : 'rentacentervrto/rest/storeInfo',
					'sessionToken' : authentication.getCurrentSessionId(),
					'PASSPHRASE' : pp,
					'orderOriginator' : 'AWP',
					'OPERATION-MODE': opMode,
					'Encrypt-Mode' : false,
					'Content-Type' : 'text/plain',
					'Access-Control-Allow-Origin' : 'PASSPHRASE, endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID, orderOriginator'
				};
				void 0;
				$http({
					method : 'POST',
					url : PROXY_ACTION_URL + '?vanStoreId=' + activeStoreId,
					headers : headers,
					// send empty data to prevent CORS error
					data: {}
				}).then(function(response) {
                                        response = response.data;
					void 0;
					//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Starts
					if($rootScope.xpartnerLogin){
						response.isManned = false;
						response.operationMode = 'UNMANNED';
						response.achVerification = true;
						response.allowAch = false;
						response.isReferenceRequired = false;
					}//VAN-3201-Enable remote signing option for AWP generated agreements- xpartner for retailers - Ends
					ServiceAgent.setServerPublicKey(response.serverPublicRSAKey);
					ServiceAgent.setRaygunKey(response.raygunApiKey);
					//Feature 7872 Integration of Logz code changes starts
					ServiceAgent.setLogzApiToken(response.logzApiToken);
					ServiceAgent.setlogzEnableFlag(response.logzEnableFlag);
					ServiceAgent.setInvRedFlag(response.invoiceReductionPayment);
					//Feature 7872 Integration of Logz code changes ends
					localStorageService.set('storeInfo', response);
					SharedDataSvc.globalVars.storeSetup = response;
					deferred.resolve(response);
				}).catch(function(response) {
					void 0;
					deferred.reject(response.data);
				});
			}

			return deferred.promise;
		}

		function getStoreState(storeInfo) {
			if (storeInfo) {
				void 0;
				return storeInfo.state || '';
			}
		}

		function getLatLngFromZip(zip) {
			//console.log("Application environment -------------- ", window.location.hostname);
			/*var host = $location.absUrl();
			var googleAPIUrl ="https://maps.googleapis.com/maps/api/geocode/json?address=" + zip;
			//29561 code changes starts
			var googleApiKeyProd = SharedDataSvc.globalVars.storeSetup.googleApiProdKey;
			var googleApiKeyLle = SharedDataSvc.globalVars.storeSetup.googleApiNonProdKey;
			//29561 code changes starts
			if(host.substr(8, 3).toUpperCase() !== 'AWP'){
				googleAPIUrl = googleAPIUrl + '&key=' + googleApiKeyLle;
			}else{
				googleAPIUrl = googleAPIUrl + '&key=' + googleApiKeyProd;
			}
			return $http({
				url : googleAPIUrl,
				method : 'GET'
			}).then(function(response) {
				return response.data;
			}, function(response) {
				console.log('Error getting lat/lng from customer zip', response);
			});*/
			var deferred = $q.defer();
			var geocoder = new google.maps.Geocoder;
			/*VAN-914 - PRB0043073 - AWP-Invalid zipcode issue*/
			geocoder.geocode( {address:zip,componentRestrictions:{country:'US'}}, function(results, status) {
				results.status = status;
				deferred.resolve(results);
			});
			return deferred.promise;
		}

		function getCustomerDistance(store, customer) {
			void 0;
			void 0;
			var point1, point2, distance; //IE Fix
			point1 = new google.maps.LatLng(store.lat, store.lng);
		    point2 = new google.maps.LatLng(customer.lat, customer.lng);
		    distance = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);

			// convert to miles
			distance = distance * 0.000621371;

			return distance;
		}

		function isCustomerInRadius(storeRadius, miles) {
			if (miles <= storeRadius) {
				return true;
			} else {
				return false;
			}
		}

		function isCustomerInUS(location) {
			var obj = _.where(location, {
				'types' : ["country", "political"]
			}) || null;

			if (obj[0] && obj[0].short_name === 'US') {
				return true;
			} else {
				return false;
			}
		}

	}

})(); 
