(function() {
  'use strict';
 
  angular
    .module('myApp')
    .factory('paymentFactory', payment);

  payment.$inject = ['$q', '$log', '$http', 'SharedDataSvc', 'API_DOMAIN', 'authentication', 'ServiceAgent', 'PROXY_ACTION_URL'];

  function payment ($q, $log, $http, SharedDataSvc, API_DOMAIN, authentication, ServiceAgent, PROXY_ACTION_URL) {

    return {
      calculatePayment: calculatePayment,
      createEpayAccount: createEpayAccount,
      epayLogin: epayLogin,
      processCashPayment: processCashPayment,
      processCreditPayment: processCreditPayment,
      processAutoPayment: processAutoPayment,
      processBenefits: processBenefits,
      pdfSign: pdfSign,
      docuSign: docuSign,
      sendEmailEML: sendEmailEML,//VAN-441 - VAN Docusign - Remote signing documents on personal device
      updateSignerStatus: updateSignerStatus,
      getCardTypeDtl: getCardTypeDtl,
      enrollAutopayWithEcomplish: enrollAutopayWithEcomplish,//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
      postAutopayToHT: postAutopayToHT,//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
      initialPayAndAutoPay: initialPayAndAutoPay,//VAN-2012-VAN-AutoPay Issue
      postCashAndAutoPay: postCashAndAutoPay//VAN-2012-VAN-AutoPay Issue
    };

    function makeServiceCall (data, endTarget) {
      void 0;
      var deferred = $q.defer();
      var headers = {
        'endTarget': endTarget,
        'sessionToken' : authentication.getCurrentSessionId()
      };
      ServiceAgent.httpCall(
        PROXY_ACTION_URL,
        'POST',
        headers,
        data,
        true
        ).then(function (response) {
          void 0;
          var successData = response;
          deferred.resolve(successData);
        },
        function (response) {
          void 0;
          deferred.reject(response);
        });

        return deferred.promise;
    }

    function createEpayAccount (epayAccountData) {
      $log.log('[paymentFactory.createEpayAccount] ' + epayAccountData);
      return makeServiceCall(epayAccountData, 'rentacentervrto/rest/EpayService/createCustomerAccount');
    }

    function epayLogin (epayLogin) {
      $log.log('[paymentFactory.epayLogin] ' + epayLogin);
      return makeServiceCall(epayLogin, 'rentacentervrto/rest/EpayService/verifyAccount');
    }
    
    function pdfSign(signatureData){
    	$log.log('[paymentFactory.pdfSignerService] ' + signatureData);
    	return makeServiceCall(signatureData, 'rentacentervrto/rest/pdfSignerService');
    }

    function docuSign(signatureData, useRAUpdateService){
      $log.log('[paymentFactory.docuSign] ' + signatureData);
      var deferred = $q.defer();
      var endTarget = 'rentacentervrto/rest/digitalSigning/' +
        (useRAUpdateService ? 'getAgreementForSigning' : 'getSigningUrl');

      var headers = {
        'endTarget': endTarget,
        'sessionToken' : authentication.getCurrentSessionId(),
        'CLIENT-SOURCE-ID':'3'
      };
      var url = PROXY_ACTION_URL;
      ServiceAgent.httpCall(
        url,
        'POST',
        headers,
        signatureData,
        true
      ).then(function (response) {
          void 0;
          var successData = (typeof response !== "object") ? JSON.parse(response) : response;
          deferred.resolve(successData);
      },
      function (response) {
          void 0;
          deferred.reject(response);
      });

      return deferred.promise;
    }
    //VAN-441 - VAN Docusign - Remote signing documents on personal device - Starts
    function sendEmailEML(signatureData){
    	$log.log('[paymentFactory.docuSign] ' + signatureData);
    	var deferred = $q.defer();

    	var headers = {
    			'endTarget': 'rentacentervrto/rest/digitalSigning/emailDocusignToRemote',
    			'sessionToken' : authentication.getCurrentSessionId(),
    			'CLIENT-SOURCE-ID':'3'
    	};
    	var url = PROXY_ACTION_URL;
    	ServiceAgent.httpCall(
    			url,
    			'POST',
    			headers,
    			signatureData,
    			true
    	).then(function (response) {
    		void 0;
    		var successData = (typeof response !== "object") ? JSON.parse(response) : response;
    		deferred.resolve(successData);
    	},
    	function (response) {
    		void 0;
    		deferred.reject(response);
    	});

    	return deferred.promise;
    }
    //VAN-441 - VAN Docusign - Remote signing documents on personal device - Ends
    function updateSignerStatus(signerData){
      $log.log('[paymentFactory.updateSignerStatus] ' + signerData);
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'rentacentervrto/rest/db/updateSignerStatus',
        'sessionToken' : authentication.getCurrentSessionId()
      };
      var url = PROXY_ACTION_URL;
      ServiceAgent.httpCall(
        url,
        'POST',
        headers,
        signerData,
        false
      ).then(function (response) {
          void 0;
          var successData = (typeof response !== "object") ? JSON.parse(response) : response;
          deferred.resolve(successData);
      },
      function (response) {
          void 0;
          deferred.reject(response);
      });

      return deferred.promise;
    }

    function calculatePayment (paymentSpec) {
      $log.log('[paymentFactory.calculatePayment] ' + paymentSpec);
      return makeServiceCall(paymentSpec, 'rentacentervrto/rest/payment/calculatePayment');
    }

    function processCashPayment (cashPaymentSpec) {
      $log.log('[paymentFactory.processCashPayment] ' + cashPaymentSpec);
      return makeServiceCall(cashPaymentSpec, 'rentacentervrto/rest/payment/postInitialCashPayment');
    }

    function processCreditPayment (cashPaymentSpec) {
      $log.log('[paymentFactory.processCreditPayment] ' + cashPaymentSpec);
//      return makeServiceCall(cashPaymentSpec, 'rentacentervrto/rest/payment/initial');
      	return makeServiceCall(cashPaymentSpec, 'rentacentervrto/rest/payment/vantivInitialPayment');//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }

    function processAutoPayment (autoPaymentSpec) {
      $log.log('[paymentFactory.processAutoPayment] ' + autoPaymentSpec);
      return makeServiceCall(autoPaymentSpec, 'rentacentervrto/rest/EpayService/enrollAutopay');
    }

    function processBenefits (benefitsSpec) {
      $log.log('[paymentFactory.processBenefits] ' + benefitsSpec);
      return makeServiceCall(benefitsSpec, 'rentacentervrto/rest/benefitsPlus');
    }

    function getCardTypeDtl(){
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'site/json/globalSettings.json',
        'sessionToken' : authentication.getCurrentSessionId(),
        'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
      };
      ServiceAgent.httpCall(
      PROXY_ACTION_URL,
      'POST',
      headers,
      null,
      false
      ).then(function (response) {
        void 0;
          deferred.resolve(response);
      },
      function (error) {
          void 0;
          deferred.reject(error);
      });

      return deferred.promise;
    }
    
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    function enrollAutopayWithEcomplish (autoPaySpec) {
    	$log.log('[paymentFactory.enrollAutopayWithEcomplish] ' + autoPaySpec);
//    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/enrollAutopayWithEcomplish');
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/vantivEnrollAutopay');//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }
    function postAutopayToHT (autoPaySpec) {
    	$log.log('[paymentFactory.postAutopayToHT] ' + autoPaySpec);
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/postAutopayToHT');
    }
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
    

    //VAN-2012-VAN-AutoPay Issue - Starts
    function initialPayAndAutoPay (autoPaySpec) {
    	$log.log('[paymentFactory.initialPayAndAutoPay] ' + autoPaySpec);
//    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/initialPaymentAndSetupAutoPay');
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/EpayService/vantivInitialAutopayPayment');//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }
   
    function postCashAndAutoPay (autoPaySpec) {
    	$log.log('[paymentFactory.processCashAndAutoPay] ' + autoPaySpec);
//    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/payment/postInitialCashAndAutoPay');
    	return makeServiceCall(autoPaySpec, 'rentacentervrto/rest/payment/initialCashPaymentAutopayHT');//VAN-3330 -replace eComplish iframe with VANTIV iframe - AWP
    }
    //VAN-2012-VAN-AutoPay Issue - Ends
  }
})();