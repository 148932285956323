(function() {
	'use strict';

	myApp
		.factory('orderService', orderService);

	/* @ngInject */
	function orderService(
		$rootScope,
		$location,
		$q,
		modalService,
		SharedDataSvc,
		updateEngagementItemFactory,
		productFactory
	) {
		var service = {
			buildItemData: buildItemData,
			addToOrder: addToOrder,
			addToSet: addToSet,
			addEditedItem: addEditedItem,
			save: saveOrder,
			confirm: confirmOrder,
			setDisplayDependencyFlag:setDisplayDependencyFlag,
			evalSetItemDependencyFlag:evalSetItemDependencyFlag
		};
		return service;

		////////////////
		
		/**
		* Check here for the dependencies and set the show Message flag for each item.
		*/
		function setDisplayDependencyFlag(orderData){
			void 0;
			
			/* Check if there is some order */
			if(orderData.length > 0){
				
				/*Create an array of all the item category present to use it for compare and check the presence of dependency */
				var currentCategoryArr = [];
				//AC 11916 code changes starts
				for(var k = 0; k<orderData.length; k++){
					if((_.isArray(orderData[k].setItems)) && (orderData[k].setItems.length > 0)) {
						for (var l = 0; l < orderData[k].setItems.length; l++) {
							//PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
							//currentCategoryArr.push(orderData[k].setItems[l].category);
							currentCategoryArr.push(orderData[k].setItems[l].dependencyMapping);
						}
					} else {
						//currentCategoryArr.push(orderData[k].category);
						currentCategoryArr.push(orderData[k].dependencyMapping);
					}

				}
				//AC 11916 code changes ends
			//	console.log("currentCategory Array", currentCategoryArr);
				
				/*Loop through each order item json */
				for(var i=0; i<orderData.length; i++){
					
					/* Added this condition to avoid issue because of old data coming without dependentOn key */
					if( _.isArray(orderData[i].dependentOn) ){

						/* Creating an array to store the status of the flag for comparison on next steps */
						var dependencyMessageStatusArr = [];
						
						/* If there is any dependency there will be something in array otherwise length will be 0 */
						if( orderData[i].dependentOn.length > 0){
							
							/*Loop through each item which is a dependency and check whether any of those is present in above category Array */
							for(var j=0; j<orderData[i].dependentOn.length; j++){
								
							//	console.log("Dependent On", orderData[i].dependentOn[j].dependentProduct);
							//	console.log(currentCategoryArr.indexOf(orderData[i].dependentOn[j].dependentProduct) );
								
								/* If the dependency is not present set the showDependencyMessage to true else false */
								if( currentCategoryArr.indexOf(orderData[i].dependentOn[j].dependentProduct) < 0){
									dependencyMessageStatusArr.push("true");
		
								}else{
									dependencyMessageStatusArr.push("false");
								}
							}
							
							//	console.log("dependencyMessageStatusArr", dependencyMessageStatusArr);
							/* If the dependencyMessageStatusArr array has even a single value as false don't show message */
							if(dependencyMessageStatusArr.indexOf("false") < 0){
								orderData[i].showDependencyMessage = "true";
							}else{
								orderData[i].showDependencyMessage = "false";
							}
							//AC 11916 code changes starts
						} else if((_.isArray(orderData[i].setItems)) && (orderData[i].setItems.length > 0)) {
							for (var j = 0; j<orderData[i].setItems.length; j++) {
								if((orderData[i].setItems[j].dependentOn) && (orderData[i].setItems[j].dependentOn.length > 0)) {
									for(var k = 0; k < orderData[i].setItems[j].dependentOn.length; k++) {
										if( currentCategoryArr.indexOf(orderData[i].setItems[j].dependentOn[k].dependentProduct) < 0){
											dependencyMessageStatusArr.push("true");

										}else{
											dependencyMessageStatusArr.push("false");
										}
									}
									if(dependencyMessageStatusArr.indexOf("false") < 0){
										orderData[i].setItems[j].showDependencyMessage = "true";
									}else{
										orderData[i].setItems[j].showDependencyMessage = "false";
									}
									//AC 11916 code changes ends
								}
							}
						}
						
					}
	
				}	
			}
			
			/* Check whether to enable or disable the buttons on order Summary page */
			var showDependencyMessageArr = [];
			for( var v=0; v < orderData.length; v++ ){

				//AC 11916 code changes starts
				if((_.isArray(orderData[v].setItems)) && (orderData[v].setItems.length > 0)) {
					for (var u = 0; u < orderData[v].setItems.length; u++) {
						/* Creating the array of values of showDependency to check later if any of the dependency is not met */
						showDependencyMessageArr.push(orderData[v].setItems[u].showDependencyMessage);
					}
				} else {
					/* Creating the array of values of showDependency to check later if any of the dependency is not met */
					showDependencyMessageArr.push(orderData[v].showDependencyMessage);
				}
				//AC 11916 code changes ends
			}
			void 0;
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if( showDependencyMessageArr.indexOf("true") == -1 ){
				SharedDataSvc.globalVars.disableOrderButtons[0] = false;
			}else{
				SharedDataSvc.globalVars.disableOrderButtons[0] = true;
			}*/
			SharedDataSvc.globalVars.disableOrderButtons[0] = false;
			//VAN-2881-Remove ALL Item Dependencies -Ends
			void 0;
				
		}
		
		
		function evalSetItemDependencyFlag(val){
			
			void 0;
			void 0;
			
			if(val.length > 0){
				
				var buttonStatusArr = [];
				for( var x =0; x < val.length; x++ ){	
					
					if ( _.isArray(val[x].setItems) ) {
			
						void 0;
						if(val[x].setItems.length > 0){
							buttonStatusArr.push("true");
							//SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent = ["false"];
						}else{
							//buttonStatusArr.push("false");
						}
						
					}else{
						void 0;
						buttonStatusArr.push("false");
					}
				}
				
				if(buttonStatusArr.indexOf("false") ==  -1 ){
					SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent = ["false"];
					void 0;
				}else{
					SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent = ["true"];
					void 0;
				}
				
			}
			
			
			void 0;
		}
		
		

		/**
		* Save the order to services
		* @param {object} selectionData - Contains data objects (from product categories JSON) that will be used to build the item
		* @returns {object} orderItem - Order item built for compatibility with the services
		*/
		function buildItemData(selectionData) {
			var isAccessory = _(selectionData).has('selectedAccessoryItem'),
				isItem = _(selectionData).has('selectedItemItem'),
				isBedItem = _(selectionData).has('selectedBedSizeItem');//PRB0041531 – Agreement stuck in processing

			void 0;
			// Pick most nested item level selected, by priority
			var finalTier = selectionData.selectedCategoryItem;
			if (isItem) finalTier = selectionData.selectedItemItem;
			if (isAccessory) finalTier = selectionData.selectedAccessoryItem;
			if (isBedItem) finalTier = selectionData.selectedBedSizeItem;//PRB0041531 – Agreement stuck in processing
			void 0;

			var itemAttributes = []
			,	itemBrand = ''
			,	itemCategory = ''//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow
			, 	dependentItem=''//PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
			,	itemColor = ''
			,	itemDescription = ''
			,	itemIcon = ''
			,	itemMaterial = ''
			,	itemModel = ''
			,	itemPrice = parseFloat(selectionData.itemPrice) || 0
			,	itemPriceBuckets = []
			,	itemQuantity = 1
			,	itemSize = ''
			,	itemSubType = ''
			,	itemUnitType = ''
			,	setItems = []
			,	dependentOn = []
			,   dependencyMessage = ''
			,   showDependencyMessage = 'false'
			;
			void 0;

			// SUB TYPE CODE
			itemSubType = ''; //productFactory.getProductSubType(selectionData, finalTier.iconURL);

			// UNIT TYPE CODE
			itemUnitType =  '';// productFactory.getProductUnitType(selectionData, finalTier);

			// Set category to parent if descendant of "category" tier, otherwise use category name
			// if (!isSet && (isAccessory || isItem)) {
			// 	itemCategory = finalTier.parent;
			// }
			// CUSTOM ATTRIBUTES
			if (_(selectionData).has('customAttributes')) {
				itemAttributes = selectionData.customAttributes;
			}

			// QUANTITY - set to 1 as default
			if (_(selectionData).has('quantity')) {
				itemQuantity =  parseInt(selectionData.quantity);
			}
			
			
			// Set Items Array - set to empty array as default
			if (finalTier.set !== 0) {
				setItems = finalTier.set;
			}

			if(isItem){
				//GET DEPENDENT ON
				if (_(selectionData.selectedItemItem).has('dependencies')) {
					dependentOn = selectionData.selectedItemItem.dependencies;
					void 0;
				}
				//VAN-2881-Remove ALL Item Dependencies -Starts
				//GET DEPENDENCY MSG
	/*			if (_(selectionData.selectedItemItem).has('dependencyAlertMessageEn')) {
					//PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
					if($rootScope.selectedLanguage == 'es'){
					dependencyMessage = selectionData.selectedItemItem.dependencyAlertMessageEs;
					console.log("This msg in spanish", dependencyMessage);
					}
					else{
					dependencyMessage = selectionData.selectedItemItem.dependencyAlertMessageEn;
					console.log("This msg in English", dependencyMessage);
				}
			}*/
				//VAN-2881-Remove ALL Item Dependencies -Ends
			}
			
			// GET PRICEBUCKETS
			if (_(finalTier).has('priceBuckets')) {
				itemPriceBuckets = finalTier.priceBuckets;
			}
			
			//Item Name
			//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Starts
			//VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Starts 
			if (_(selectionData).has('selectedAccessoryItem') && selectionData.selectedAccessoryItem != null && selectionData.selectedBedSizeItem == undefined) {
				if($rootScope.selectedLanguage == 'es'){
					itemCategory = selectionData.selectedAccessoryItem.labelEs;
				}
				else{
					itemCategory = selectionData.selectedAccessoryItem.labelEn;
				}
			}//VAN-2826-PRB0043300 - Order issue - Brand/Model not found on model master for TV accessories - Ends
			else if (_(selectionData).has('selectedItemItem') && selectionData.selectedItemItem != null && selectionData.selectedBedSizeItem == undefined) {
				if($rootScope.selectedLanguage == 'es'){
					itemCategory = selectionData.selectedItemItem.labelEs;
				}
				else{
					itemCategory = selectionData.selectedItemItem.labelEn;
				}
			}
			//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Starts
			// SELECTED BED SIZE
			if (_(selectionData).has('selectedBedSizeItem') && selectionData.selectedBedSizeItem != null) {
				//itemSize = selectionData.selectedBedSizeItem.labelEn;
				//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Ends
				if($rootScope.selectedLanguage == 'es'){
					itemCategory = selectionData.selectedBedSizeItem.labelEs;
				}
				else{
					itemCategory = selectionData.selectedBedSizeItem.labelEn; //PRB0041531 – Agreement stuck in processing
				}
				//Defect 29869 - AWP-(PR)- Spanish Text not displayed properly in various section of PR location flow - Ends
			}
			
			// Selected dependent item -- //PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
			if (_(selectionData).has('selectedItemItem') && selectionData.selectedItemItem != null && selectionData.selectedBedSizeItem == undefined) {
				dependentItem = selectionData.selectedItemItem.labelEn;
				void 0;
			}

			// SELECTED BRAND
			if (_(selectionData).has('selectedBrandItem') && selectionData.selectedBrandItem != null) {
				itemBrand = selectionData.selectedBrandItem.label;
			}

			// MATERIAL
			if (_(selectionData).has('selectedMaterialsItem') && selectionData.selectedMaterialsItem != null) {
				itemMaterial = selectionData.selectedMaterialsItem.labelEn;
			}

			// COLOR
			// Start VAN-2501
			if (_(selectionData).has('selectedColorItem') && selectionData.selectedColorItem != null) {
				itemColor = selectionData.selectedColorItem.labelEn;
			}
			// End

			// ITEM MODEL NUMBER
			if (_(selectionData).has('itemModelNumber')) {
				itemModel = selectionData.itemModelNumber;
			}

			// Icon URL
			if (_(finalTier).has('iconURL')) {
				itemIcon = finalTier.iconURL;
			}
			
			var orderItem = {
				attributes: itemAttributes,
				brand: itemBrand,
				category: itemCategory,
				dependencyMapping: dependentItem, //PRB0042910-VAN-638-Spanish dependency messages are not displaying for set items and not able to proceed
				color: itemColor,
				iconURL: itemIcon,
				itemPrice: itemPrice,
				material: itemMaterial,
				model: itemModel,
				quantity: itemQuantity,
				setItems: setItems,
				size: itemSize,
				subType: itemSubType,
				unitType: itemUnitType,
				dependentOn: dependentOn,
				dependencyMessage: dependencyMessage,
				showDependencyMessage:showDependencyMessage
			};
			orderItem.itemDescription = buildDescription(orderItem);
			// remove keys with an empty string
			orderItem = _.omit(orderItem, function(val, key){
				if (key === 'model') return false;
				return val === '';
			});
			return orderItem;
		}

		/**
		 * Builds the description that will be saved with the item to services.
		 * @param {object} itemData - Order item data that will be used to build description.
		 * @return {string} description - The built description string.
		 */
		function buildDescription(itemData) {
			var description;
			// Quantity
			description = isNaN(itemData.quantity) || itemData.quantity === 1 ? '' : itemData.quantity + ' x ';
			description += itemData.brand !== '' ? itemData.brand +' ' : '';
			description += itemData.category+' ';
			description += itemData.color !== '' ? itemData.color+' ' : '';
			description += itemData.material !== '' ? itemData.material : '';
			description += itemData.size !== '' ? '- '+itemData.size : '';
			description = description.replace(/['"]+/g, '');
			// remove end-of-line whitespace
			description = description.replace(/\s+$/g, '');
			return description;
		}

		/**
		 * Adds item to the overall order.
		 * @param {object} data - Item data that will be used to build an item and add to order.
		 * @return {number} index - Index of the order item that was just added.
		 */
		function addToOrder(data) {
			var pushObj = buildItemData(data);
			var itemsLength = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length;
			
			//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Starts
			//Feature 9500 - VAN - Business Demo Changes - Starts
			var AWPitemgroupmessage;
			//Feature 9500 - VAN - Business Demo Changes - Ends
			var vendorSetEnable=false;
			if(itemsLength>=1){
				for(var i=0;i<SharedDataSvc.globalVars.itemGroupVendorSet.length;i++){
					if(SharedDataSvc.globalVars.itemGroupVendorSet[i].vendor.indexOf("|" + SharedDataSvc.globalVars.storeCode +"|")>=0){
						//Feature 9500 - VAN - Business Demo Changes - Starts
						AWPitemgroupmessage = SharedDataSvc.globalVars.itemGroupVendorSet[i].awpItemGroupMessage;
						//Feature 9500 - VAN - Business Demo Changes - Ends
						for(var j=0;j<SharedDataSvc.globalVars.itemGroupVendorSet[i].itemsList.length;j++){
							if((SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[0].iconURL)==(SharedDataSvc.globalVars.itemGroupVendorSet[i].itemsList[j].iconURL))
							{							
								var firstItemGroupName=SharedDataSvc.globalVars.itemGroupVendorSet[i].itemGroup;
								var firstItemFlagValue=SharedDataSvc.globalVars.itemGroupVendorSet[i].isAllowedWithNonGroup;								
							}
							if(data.selectedItemItem.iconURL==SharedDataSvc.globalVars.itemGroupVendorSet[i].itemsList[j].iconURL)
							{								
								var currentItemGroupName=SharedDataSvc.globalVars.itemGroupVendorSet[i].itemGroup;
								var currentItemFlagValue=SharedDataSvc.globalVars.itemGroupVendorSet[i].isAllowedWithNonGroup;								
							}
						}
					}
				}

				if(firstItemGroupName==currentItemGroupName)
				{
					vendorSetEnable=true;
				}
				else
					if(firstItemFlagValue=='Y' || currentItemFlagValue=='Y' ){
					if(firstItemGroupName==undefined || currentItemGroupName==undefined){
						vendorSetEnable=true;
					}
				}
				if(data.selectedItemItem.set>0 && vendorSetEnable == false)
				{
				$rootScope.setModeForGroup=false;
				}
		//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes

			/*	if(!vendorSetEnable && itemsLength>=1)
					{
					//Feature 9500 - VAN - Business Demo Changes - Starts
						var openAlert = modalService.open({
							template: AWPitemgroupmessage,
							backdrop: 'static'
						});
						//Feature 9500 - VAN - Business Demo Changes - Ends
						return  SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length-1;
					}*/
		//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes

				}
		//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Ends
			
			if (itemsLength >= 0) {
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.push(pushObj);
			} else {
				// nothing in order, nullify orderItems
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems = [];
				builder.orderSubtotal = 0;
			}
			$rootScope.$broadcast('orderUpdated');
			
			setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			
			// return index of added item
			return SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length-1;
		}
		
		/**
		 * Adds item to the setItems of an order item.
		 * @param {object} item - The item object to be added to specfied set.
		 * @param {number} index - Index of the order item that will receive the set item.
		 * @returns {number} index - Returns the index of changed order item.
		 */
		function addToSet(item, index) {
			var orderSetItems = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index].setItems;
			var setItem = buildItemData(item, true);

			if (!_.isArray(orderSetItems)) {
				orderSetItems = new Array(setItem);
			} else {
				orderSetItems.push(setItem);
			}
			
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index].setItems = orderSetItems;

			setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);//AC 11916 code changes
			evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			
			return index;
		}

		/**
		 * Edits the indicated order item by merging edit data onto existing order data.
		 * @param {object} editData - The item edit data that will be used for the edit.
		 * @param {number} index - Index of the order item that will be edited.
		 */
		function addEditedItem(editData, index) {
			var preEditItem,
				editedItem;
			if (index.length > 1) {
				preEditItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index[0]].setItems[index[1]];
			} else {
				preEditItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index];
			}
			// AC-13373 Start
			var selectedCustomAttributesArr = [];
			var selectedCustomAttributes = [];
			if(editData.customAttributes != undefined){				
				_.forEach(editData.customAttributes, function(customAttributeGroup){
					_.forEach(customAttributeGroup.customAttributes, function(customAttributes, index){
						if(customAttributes.fieldType == "radio"){
							selectedCustomAttributes={
									customAttribute:customAttributes.labelEn,
									value: editData.selectedRadioOption 
							};
						}else if(customAttributes.fieldType == "checkBox"){
							selectedCustomAttributes={
									customAttribute: customAttributes.labelEn,
									value: editData.selectedCheckBoxOption[index] ? '1' : editData.selectedCheckBoxOption[index] == false ? '0' : undefined
							};
						}else{
							selectedCustomAttributes={
									customAttribute: customAttributes.labelEn,
									value: customAttributes.selectedTextAttribute
							};
						}
						selectedCustomAttributesArr.push(selectedCustomAttributes);
					});
				});
			}			
			editData.customAttributes = selectedCustomAttributesArr;
			// AC-13373 End
			var editedItem = buildItemData(editData);
			if (preEditItem.setItems.length > 0) {
				editedItem.setItems = preEditItem.setItems;
			}
			editedItem = _.merge(preEditItem, editedItem);
		}

		/**
		* Save the order to services
		* @param {object} order - Built item data that will be saved to services
		* @param {string} orderStatus - (optional) Status to set the order to
		* @param {string} navigateTo - (optional) Where to navigate after successful save
		*/
		function saveOrder(order, orderStatus, navigateTo) {
			orderStatus = orderStatus || 'InProgress';
			navigateTo = navigateTo || false;
			
			var orderPut = {}
			,	engagementData = SharedDataSvc.globalVars.order.currentEngagement
			,	orderData = order.currentEngagementOrder
			,	filteredItems = []
			;
			
			if(engagementData.applicationStatus === undefined){
				engagementData = SharedDataSvc.globalVars.orderSummary.currentEngagement;
			}

			if(orderData.orderContent.extendedServiceAmount){
				orderData.orderContent.extendedServiceAmount = Number(orderData.orderContent.extendedServiceAmount);
			}

			if(orderData.orderContent.deliveryFee){
				orderData.orderContent.deliveryFee = Number(orderData.orderContent.deliveryFee);
			}

			// save order -
			//  - get SharedDataSvc.globalVars.order.currentEngagementId and prepare a PUSH url
			//  - update order status in currentEngagement
			// order.currentEngagement.orderStatus = orderStatus;

			SharedDataSvc.globalVars.order.currentEngagement.orderStatus = orderStatus;
			SharedDataSvc.globalVars.order.currentEngagementOrder = order.currentEngagementOrder;

			/*//VAN 3052: Aakash Changes: Changing Agreement Status to Pending if order is edited once agreement is submitted
			console.log("Editorder flag value:", SharedDataSvc.editOrder);
			if(engagementData.applicationStatus.toUpperCase() === "ACCEPTED" && engagementData.agreementStatus.toUpperCase() === "ACCEPTED" && SharedDataSvc.editOrder) {
				SharedDataSvc.editOrder = false;
				SharedDataSvc.globalVars.order.currentEngagement.agreementStatus = 'InProgress';
			}*/

			// map the globalVars.order version to the PUT version:
			//AC21459 code changes starts
			if(SharedDataSvc.globalVars.estimateFlag){
				orderPut.estimateFlag = true;
			}//AC21459 code changes ends
			orderPut.orderStatus = orderStatus;
			var orderDetails = [
				'agreementStatus',
				'applicationStatus',
				'customerId',
				'customerHtID',
				'deliveryStatus',
				'engagementId',
				'paymentStatus',
				'salesStatus',
				'vanStoreId',
				'isIdConfirmed',
				'invoiceNum',
				'salesAssociateID',
				'invoiceReductionPaymentAmount'
			];
			/*VAN-4036 -AWP - order screen invoice reduction payment changes
			Fetching the amount from the odersummary and adding it to the payload for the customer engagement put api call(save order) */
			
			if(SharedDataSvc.invoiceReductionPayment) {
				void 0;
				orderDetails.push(order.currentEngagement.invoiceReductionPaymentAmount);
			}
			_.forEach(orderDetails, function(detailName) {
				orderPut[detailName] = engagementData[detailName];
			});
			_.forEach(orderData.orderContent.orderItems, function(val) {
				filteredItems.push(_.omit(val, ['unitType', 'subType']));
			});

			orderData.orderContent.deliveryFeeOneTime = true;
			if(SharedDataSvc.globalVars.orderEstimatePut.feeArray && SharedDataSvc.globalVars.orderEstimatePut.feeArray[1].feeAcrossTerm){
				orderData.orderContent.deliveryFeeOneTime = false;
			}

			orderData.orderContent.orderItems = filteredItems;
			// encode the orderContent portion and PUT
			var preEncode = angular.toJson(orderData.orderContent);
			var encodedData = SharedDataSvc.base64.encodeAlt(preEncode);
			orderPut.orderContent = encodedData;

			void 0;

			var deferred = $q.defer();
			
			/*VAN 774 -  Biweekly renewal payments Long Term - Starts*/ 
			if(SharedDataSvc.globalVars.paymentModeSelected!=undefined){
				orderPut.paymentMode = SharedDataSvc.globalVars.paymentModeSelected;
			}
			/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/
			
			// return;
			updateEngagementItemFactory.putData(orderPut)
			.then(
				function(response) {
					SharedDataSvc.globalmessage = 'Order Saved.';
					SharedDataSvc.globalmessageShow = true;
					$rootScope.$broadcast('engagementUpdated');
					SharedDataSvc.globalVars.estimateFlag = false;//AC21459 code changes
					if (navigateTo) $location.path(navigateTo);
					deferred.resolve(response);
				}, function(response) {
					void 0;
					SharedDataSvc.globalmessage = 'An error has occurred.  Please retry your request.';
					SharedDataSvc.globalmessageShow = true;
					SharedDataSvc.globalVars.estimateFlag = false;//AC21459 code changes
					deferred.reject(response);
				}
			);
			
			return deferred.promise;
		}


		function confirmOrder() {
			// mark that order as confirmed
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderStatus = 'Confirmed';
			SharedDataSvc.globalmessage = 'Order Confirmed';
			SharedDataSvc.globalmessageShow = true;
			
			var orderDetails = [
				'applicationStatus',
				'orderContent',
				'engagementId',
				'customerHtID',
				'salesStatus',
				'paymentStatus',
				'customerId',
				'deliveryStatus',
				'agreementStatus',
				'vanStoreId',
				'orderStatus',
				'invoiceReductionPaymentAmount'
			];

			/*VAN-4036 -AWP - order screen invoice reduction payment changes
			Fetching the amount from the ordersummary screen and adding it to the payload for the customer engagement put api call(confirm order) */

			if(SharedDataSvc.invoiceReductionPayment) {
				if(Number(orderSummary.SharedDataSvc.globalVars.orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > Number(orderSummary.SharedDataSvc.globalVars.orderSummary.approvalAmount)) {
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =  Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
					orderDetails.push(Number(SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount).toFixed(2));
				}
				else {
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = 0;
					orderDetails.push(SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount);
				}
			}
			
			_.forEach(orderDetails, function(detailName) {
				SharedDataSvc.globalVars.orderConfirmPut[detailName] = SharedDataSvc.globalVars.order.currentEngagementOrder[detailName];
			});
			// map the globalVars.order version to the PUT version:
			SharedDataSvc.globalVars.orderConfirmPut.orderContent.extendedServiceAmount = orderSummary.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount;
			SharedDataSvc.globalVars.orderConfirmPut.orderContent.deliveryFee = orderSummary.globalVars.order.currentEngagementOrder.orderContent.deliveryFee;

			// encode the orderContent portion and PUT
			var preEncode = angular.toJson(SharedDataSvc.globalVars.orderConfirmPut.orderContent);
			var encodedData = SharedDataSvc.base64.encodeAlt(preEncode);
			SharedDataSvc.globalVars.orderConfirmPut.orderContent = encodedData;
			SharedDataSvc.globalVars.orderConfirmPut.orderStatus = 'Confirmed';
			var pushData = SharedDataSvc.globalVars.orderConfirmPut;
			void 0;
			updateEngagementItemFactory.putData(pushData).then(
				function() {
					void 0;
					$rootScope.$broadcast('engagementUpdated');
					$location.url('/dashboard');
				},
				function(statusText) {
					void 0;
					//$location.url('/dashboard');
					//$rootScope.$broadcast('engagementUpdated');
				}
			);
		}

	}
})();